import styled from "styled-components/macro"

export const CardWrapper = styled.div`
    width: 474px;
    text-align: center;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 20px 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

