/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { ScheduledSurveyInListFragmentFragmentDoc } from '../../../models/generated/ScheduledSurveyInListFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduledSurveysListFromTargetQueryVariables = Types.Exact<{
  fromId: Types.Scalars['Int'];
  order?: Types.InputMaybe<Types.Order>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ScheduledSurveysListFromTargetQuery = { scheduledSurveys: Array<{ id: number, isManualPublication: boolean, schedulingId: number, survey: { id: number, name: string, theme: { id: number, name: string, color: string, icon: string } }, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, scheduled_reminders: Array<{ remind_hours_before_end: number, is_editable: boolean }>, userGroups: Array<{ name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined }> }> };


export const ScheduledSurveysListFromTargetDocument = gql`
    query ScheduledSurveysListFromTarget($fromId: Int!, $order: Order, $limit: Int) {
  scheduledSurveys: scheduled_surveys(from_id: $fromId, order: $order, limit: $limit, skip: 1) {
    ...ScheduledSurveyInListFragment
  }
}
    ${ScheduledSurveyInListFragmentFragmentDoc}`;

/**
 * __useScheduledSurveysListFromTargetQuery__
 *
 * To run a query within a React component, call `useScheduledSurveysListFromTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledSurveysListFromTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledSurveysListFromTargetQuery({
 *   variables: {
 *      fromId: // value for 'fromId'
 *      order: // value for 'order'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useScheduledSurveysListFromTargetQuery(baseOptions: Apollo.QueryHookOptions<ScheduledSurveysListFromTargetQuery, ScheduledSurveysListFromTargetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledSurveysListFromTargetQuery, ScheduledSurveysListFromTargetQueryVariables>(ScheduledSurveysListFromTargetDocument, options);
      }
export function useScheduledSurveysListFromTargetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledSurveysListFromTargetQuery, ScheduledSurveysListFromTargetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledSurveysListFromTargetQuery, ScheduledSurveysListFromTargetQueryVariables>(ScheduledSurveysListFromTargetDocument, options);
        }
export type ScheduledSurveysListFromTargetQueryHookResult = ReturnType<typeof useScheduledSurveysListFromTargetQuery>;
export type ScheduledSurveysListFromTargetLazyQueryHookResult = ReturnType<typeof useScheduledSurveysListFromTargetLazyQuery>;
export type ScheduledSurveysListFromTargetQueryResult = Apollo.QueryResult<ScheduledSurveysListFromTargetQuery, ScheduledSurveysListFromTargetQueryVariables>;