import React from "react"

import config from "config/business"
import {
  UnprocessedRecipientComments,
  UnprocessedRecipientCommentsObject,
} from "features/Comments/UnprocessedRecipientComments"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { useScheduledSurveyWithCommentsQuery } from "graphql/queries/generated/ScheduledSurveyWithComments"
import { Processing_Status as ProcessingStatus } from "models/generated"
import { ScheduledSurvey } from "models/ScheduledSurvey"

export const UnprocessedSurveyRecipientCommentsContainer = (
  props: UnprocessedSurveyRecipientCommentsContainerProps,
) => {
  const [ pagingInfo, setPagingInfo ] = React.useState({
    currentPage: 1,
    pageSize: config.homepage.unprocessedComments.pageSizes[0],
  })
  const [ filters, setFilters ] = React.useState<ProcessingStatus[]>([])

  const {
    data: result,
    loading: isCommentsLoading,
    error: loadingErrors,
    refetch: fetchCommentsPage,
  } = useScheduledSurveyWithCommentsQuery(
    {
      variables: {
        limit: pagingInfo.pageSize,
        processingStatuses: filters,
        scheduledSurveyId: props.scheduledSurveyId,
        skip: (pagingInfo.currentPage - 1) * pagingInfo.pageSize,
      },
    },
  )

  const comments: UnprocessedRecipientCommentsObject = {
    recipientComments: result?.scheduledSurvey?.recipientComments ?? [],
    recipientCommentsAwaitingAnswerCount: result?.scheduledSurvey?.recipientCommentsAwaitingAnswerCount ?? 0,
    recipientCommentsWithCurrentFiltersCount: result?.scheduledSurvey?.recipientCommentsWithCurrentFiltersCount ?? 0,
    recipientCommentsWithDraftedAnswerCount: result?.scheduledSurvey?.recipientCommentsWithDraftedAnswerCount ?? 0,
  }

  return (
    <UnprocessedRecipientComments
      className={props.className}
      unprocessedComments={comments}
      areCommentsLoading={isCommentsLoading}
      errors={loadingErrors}
      onFiltersChange={(filters) => setFilters(filters)}
      onCommentsSaved={reloadCurrentPage}
      onPaginationChange={(currentPage, pageSize) => setPagingInfo({ currentPage, pageSize })}
      isGlobalComments={false}
    />
  )

  /**
   * Reload comments from current page
   */
  function reloadCurrentPage() {
    fetchCommentsPage({
      limit: pagingInfo.pageSize,
      processingStatuses: filters,
      skip: (pagingInfo.currentPage - 1) * pagingInfo.pageSize,
    })
  }
}

interface UnprocessedSurveyRecipientCommentsContainerProps extends BaseProps {
  scheduledSurveyId: ScheduledSurvey["id"],
}
