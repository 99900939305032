import moment from "moment"
import * as React from "react"
import "moment/locale/fr"
import { t } from "ttag"
import { ClickAwayListener } from "@material-ui/core"
import "react-day-picker/lib/style.css"

import { BaseProps, CalendarView, DropdownTrigger, PrimaryButton, SelectOptionProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ScheduledSurveyDateRangePicker.styles"

import { JsDateRange } from "models/DateTime/JsDateRange"
import { MomentRange } from "models/DateTime/MomentRange"
import { RangeValidity as DateTimeRangeValidity } from "models/DateTime/RangeValidity"
import { TimeRange } from "models/DateTime/TimeRange"
import { SCHEDULED_SURVEY_SERVICE } from "services/ScheduledSurveyService"
import { DATE_TIME_HELPER } from "./DateTimeHelper"

export const ScheduledSurveyDateRangePicker: React.FC<DateTimeRangePickerProps> = (props) => {
  const [ isPickerVisible, setPickerVisible ] = React.useState(false)
  const [ selectedDateRange, setSelectedDateRange ] = React.useState<JsDateRange>({
    from: undefined,
    to: undefined,
  })

  const [ selectedTimeRange, setSelectedTimeRange ] = React.useState<TimeRange>({
    from: { hours: 0, minutes: 0 },
    to: { hours: 0, minutes: 0 },
  })

  const selectedRangeAsMoment = selectedMomentRange()
  const availableHoursOptions = {
    beginAt: timeSelectHoursOptions(selectedRangeAsMoment, "beginAt"),
    endAt: timeSelectHoursOptions(selectedRangeAsMoment, "endAt"),
  }

  const availableMinutesOptions = {
    beginAt: timeSelectMinutesOptions(selectedRangeAsMoment, "beginAt"),
    endAt: timeSelectMinutesOptions(selectedRangeAsMoment, "endAt"),
  }

  const memoizedDismissPicker
    = React.useCallback(dismissPicker, [ setPickerVisible, setSelectedDateRange, props.value ])

  React.useEffect(() => {
    setSelectedDateRange(DATE_TIME_HELPER.jsDateRangeFromMomentRange(props.value))
    setSelectedTimeRange(DATE_TIME_HELPER.timeRangeFromMomentRange(props.value, { hours: 15, minutes: 0 }))
  }, [ props.value ])

  return (
    <Styles.Container className={props.className}>
      <DropdownTrigger
        placeholder={props.placeholder}
        value={humanFormattedDateTimeRange({ range: props.value })}
        label={props.label}
        isActive={isPickerVisible}
        onClick={showDatePicker}
        isDisabled={props.isDisabled}
        isFullWidth={props.isFullWidth}
        data-cy="DateTimeRangePicker__dropdown-trigger"
      />
      {isPickerVisible && (
        <ClickAwayListener onClickAway={memoizedDismissPicker}>
          <Styles.CalendarContainer
            onClick={capturePickerClick}
            $alignment={props.pickerAlignment}
            className={props.pickerClassName}
          >
            <CalendarView
              enableOutsideDaysClick={false}
              numberOfMonths={2}
              fromMonth={new Date()}
              onDayClick={handleDayClick}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              selectedDays={[ selectedDateRange.from, selectedDateRange ] as any}
              disabledDays={{ before: new Date() }}
              modifiers={{
                "marked": (props.markedDays && props.markedDays.length && isDayMarked) || undefined,
                "selection-end": selectedDateRange.to,
                "selection-start": selectedDateRange.from,
              }}
            />
            <Styles.Time>
              {t`Starting time`}
              <Styles.TimeSelect
                name="selectedBeginAtHours"
                onChange={handleTimeChange}
                options={availableHoursOptions.beginAt}
                value={selectedTimeRange.from.hours}
                height="small"
                placeholder="--"
              />:
              <Styles.TimeSelect
                name="selectedBeginAtMinutes"
                onChange={handleTimeChange}
                options={availableMinutesOptions.beginAt}
                value={selectedTimeRange.from.minutes}
                height="small"
                placeholder="--"
              />
              {t`Closing time`}
              <Styles.TimeSelect
                name="selectedEndAtHours"
                onChange={handleTimeChange}
                options={availableHoursOptions.endAt}
                value={selectedTimeRange.to.hours}
                height="small"
                placeholder="--"
              />:
              <Styles.TimeSelect
                name="selectedEndAtMinutes"
                onChange={handleTimeChange}
                options={availableMinutesOptions.endAt}
                value={selectedTimeRange.to.minutes}
                height="small"
                placeholder="--"
              />
            </Styles.Time>
            <Styles.PickerActions>
              <PrimaryButton isInverted onClick={memoizedDismissPicker} height="small">{t`Cancel`}</PrimaryButton>
              <PrimaryButton
                onClick={handleUserValidate}
                disabled={!canUserValidate()}
                height="small"
                data-cy="DateTimeRangePicker__validate-button"
              >
                {t`Validate`}
              </PrimaryButton>
            </Styles.PickerActions>
          </Styles.CalendarContainer>
        </ClickAwayListener>
      )}
    </Styles.Container>
  )

  /** Modifier for specifying days as marked */
  function isDayMarked(day: Date): boolean {
    return !!props.markedDays!.find(
      markedDay => markedDay.set("hours", 12).format("YYYY-MM-DDTHH:mm:ss")
            === moment(day).format("YYYY-MM-DDTHH:mm:ss"))
  }

  /** Update the selected date range */
  function handleDayClick(day: Date) {
    let tentativeDateRange: JsDateRange = Object.assign({}, selectedDateRange)

    if (!selectedDateRange.from || (selectedDateRange.from && selectedDateRange.to)) {
      tentativeDateRange = {
        from: day,
        to: undefined,
      }
    } else if (!selectedDateRange.to) {
      if (day.valueOf() > selectedDateRange.from.valueOf()) {
        tentativeDateRange.to = day
      }
      else {
        tentativeDateRange.to = selectedDateRange.from
        tentativeDateRange.from = day
      }
    }

    const tentativeMomentRange = DATE_TIME_HELPER.momentRange(tentativeDateRange, selectedTimeRange)
    const rangeValidity = SCHEDULED_SURVEY_SERVICE.participationRangeValidity(tentativeMomentRange)

    switch (rangeValidity) {
      case DateTimeRangeValidity.Valid:
        setSelectedDateRange(tentativeDateRange)
        break
      case DateTimeRangeValidity.DurationTooShort:
        const newTimeRange = {
          ...selectedTimeRange,
          to: {
            hours: tentativeMomentRange.from!.hours(),
            minutes: tentativeMomentRange.from!.minutes(),
          },
        }

        setSelectedDateRange(tentativeDateRange)
        setSelectedTimeRange(newTimeRange)
        break
      case DateTimeRangeValidity.ValidAtLaterHour:
        const futurTimeRange = {
          ...selectedTimeRange,
          from: {
            hours: moment().add(1, "hour").hours(),
            minutes: 0,
          },
        }

        setSelectedDateRange(tentativeDateRange)
        setSelectedTimeRange(futurTimeRange)
        break
    }
  }

  /** Update the selected time range */
  function handleTimeChange(
    value: SelectOptionProps | undefined,
    event?: React.ChangeEvent<{ name?: string, value: unknown }>,
  ) {
    if (value) {
      let tentativeTimeRange: TimeRange = selectedTimeRange

      switch (event && event.target.name) {
        case "selectedBeginAtHours":
          tentativeTimeRange = {
            ...selectedTimeRange,
            from: { ...selectedTimeRange.from, hours: value.value.valueOf() as number },
          }
          break
        case "selectedBeginAtMinutes":
          tentativeTimeRange = {
            ...selectedTimeRange,
            from: { ...selectedTimeRange.from, minutes: value.value.valueOf() as number },
          }
          break
        case "selectedEndAtHours":
          tentativeTimeRange = {
            ...selectedTimeRange,
            to: { ...selectedTimeRange.to, hours: value.value.valueOf() as number },
          }
          break
        case "selectedEndAtMinutes":
          tentativeTimeRange = {
            ...selectedTimeRange,
            to: { ...selectedTimeRange.to, minutes: value.value.valueOf() as number },
          }
          break
      }

      const tentativeMomentRange = DATE_TIME_HELPER.momentRange(selectedDateRange, tentativeTimeRange)
      const rangeValidity = SCHEDULED_SURVEY_SERVICE.participationRangeValidity(tentativeMomentRange)

      if (rangeValidity === DateTimeRangeValidity.ValidAtLaterHour) {
        tentativeTimeRange.from.hours = moment().add(1, "hour").hours()
        tentativeTimeRange.from.minutes = 0
      }

      setSelectedTimeRange(tentativeTimeRange)
    }
  }

  /** Fire the parent's onValidate callback with the selected date and time range */
  function handleUserValidate() {
    if (props.onValidate) {
      setPickerVisible(false)
      props.onValidate(selectedMomentRange())
    }
  }

  /** Whether the user selection is valid */
  function canUserValidate(): boolean {
    return !!(selectedDateRange && selectedDateRange.from && selectedDateRange.to)
  }

  /** Set the picker visible */
  function showDatePicker() {
    if (!props.isDisabled) {
      setPickerVisible(true)
    }
  }

  /** Hide the picker and empty the selection */
  function dismissPicker() {
    setPickerVisible(false)
    setSelectedDateRange(DATE_TIME_HELPER.jsDateRangeFromMomentRange(props.value))
  }

  function capturePickerClick(event: React.MouseEvent<HTMLDivElement>) {
    if (isPickerVisible) {
      event.stopPropagation()
    }
  }

  /** Merge the selected date and time range */
  function selectedMomentRange(): MomentRange {
    return DATE_TIME_HELPER.momentRange(selectedDateRange, selectedTimeRange)
  }

  /** Get the options of an hours ScheduledReminderSelect */
  function timeSelectHoursOptions(selectedRange: MomentRange, whichSelect: "beginAt" | "endAt"): SelectOptionProps[] {
    let firstAvailableHour = 0
    const nowTime = moment()

    if (whichSelect === "beginAt" && selectedRange.from && selectedRange.from.isSame(nowTime, "day")) {
      firstAvailableHour = nowTime.add(1, "hour").hours()
    } else if (whichSelect === "endAt" && selectedRange.from && selectedRange.to) {
      const dayAfterStartDate = moment(selectedRange.from).add(1, "days")

      if (selectedRange.to.isSame(dayAfterStartDate, "day")) {
        firstAvailableHour = selectedRange.from.hours()
      }
    }

    return Array.from(new Array(24 - firstAvailableHour), (value, index) => ({
      value: index + firstAvailableHour,
      wording: (index + firstAvailableHour).toString().padStart(2, "0"),
    }))
  }

  /** Get the options of a minutes ScheduledReminderSelect */
  function timeSelectMinutesOptions(
    selectedRange: MomentRange,
    whichSelect: "beginAt" | "endAt",
  ): SelectOptionProps[] {
    let firstAvailableMinute = 0

    if (whichSelect === "endAt" && selectedRange.from && selectedRange.to) {
      const dayAfterStartDate = moment(selectedRange.from).add(1, "days")

      if (selectedRange.to.isSame(dayAfterStartDate, "hour")) {
        firstAvailableMinute = selectedRange.from.minutes()
      }
    }

    return Array.from(new Array(12 - Math.ceil(firstAvailableMinute / 5)), (value, index) => ({
      value: index * 5 + firstAvailableMinute,
      wording: (index * 5 + firstAvailableMinute).toString().padStart(2, "0"),
    }))
  }

  /** Get a human-readable string to display the current date and time selection */
  function humanFormattedDateTimeRange(props: {range?: MomentRange}) {
    if (!props.range) {
      return null
    }

    return (
      <>
        {props.range.from && <span>{props.range.from.format("L LT")}</span>}
        {props.range.to && <span>&nbsp;- {props.range.to.format("L LT")}</span>}
      </>
    )
  }
}

export interface DateTimeRangePickerProps extends BaseProps {
  /** Label displayed alongside the control */
  label?: string,

  /** Days that should be marked in the calendar to denote an active survey */
  markedDays?: moment.Moment[],

  /** Fired when the user clicks the Validate button in the picker */
  onValidate?: (selectedRange: MomentRange) => void,

  /** Default selected range */
  value?: MomentRange,

  /** Placeholder text to display when there is no value */
  placeholder?: string,

  /** Alignment of the picker in regards to the input field */
  pickerAlignment?: "left" | "center" | "right",

  /** Take the whole available width */
  isFullWidth?: boolean,

  /** Additional classname applied to the root element */
  className?: string,

  /** Additional classname applied to the popup picker element */
  pickerClassName?: string,

  /** Disable the component */
  isDisabled?: boolean,
}

ScheduledSurveyDateRangePicker.defaultProps = {
  pickerAlignment: "center",
}
