import styled, { css } from "styled-components/macro"

import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { CSSProperties } from "react"

export const Card = styled(MetricsCard)<{ $isDataExpired?: boolean }>`
  transition: background-color, color 200s;
  
  ${({ theme, $isDataExpired }) => $isDataExpired && css`
    background-color: ${theme.colors.dangerLight};
    
    ${LatestImportDate} {
      color: ${theme.colors.danger}
    }
  `}
`
export const CardTitle = styled(Title)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const TermsOfServiceAcceptance = styled.div`
  display: flex;
  justify-content: space-evenly;
`
export const Chart = styled.div`
  height: 54px;
  width: 54px;
`
export const ChartLegend = styled.ul`
  display:flex;
  flex-direction: column;
  justify-content: center;

  margin: 0;
  padding: 0;

  list-style-type: none;
  text-align: left;
  font-size: ${({ theme }) => theme.typography.fontSizeLabelSmall};
  color: ${({ theme }) => theme.colors.blackMedium};
`

export const LegendSymbol = styled.span<{ $backgroundColor: CSSProperties["backgroundColor"] }>`
  display: inline-block;
  height: 8px;
  width: 8px;
  
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.sizes.borderRadiusRounded};
`

export const LatestImportDate = styled.div`
  color: ${({ theme }) => theme.colors.success};
  font-size: ${({ theme }) => theme.typography.fontSizeCaptionSmall};
`

export const ExpiredIcon = styled(Icon)`
  vertical-align: bottom;
`

