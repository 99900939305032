import React from "react"
import { Dialog, DialogActions, DialogContent } from "@material-ui/core"
import { t } from "ttag"

import { Icon, Label, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./AdminOverwriteConfirmationDialog.styles"

export const AdminOverwriteConfirmationDialog: React.FC<AdminOverwriteConfirmationDialogProps>
  = ({ overWrittenFields, ...props }) => (
    <Dialog open>
      <Styles.Title>
        {/* eslint-disable-next-line max-len */}
        {t`The email address you want to use for this new user is already registered on an administrator profile, but the following information does not match, Would you like to update it with the new information entered?`}
      </Styles.Title>
      <DialogContent>
        {overWrittenFields.map(overWrittenField => (
          <Styles.OverwrittenFieldContainer key={overWrittenField.label}>
            <Label text={overWrittenField.label}/>
            <Styles.OverwrittenField>
              {overWrittenField.adminValue} <Icon name="arrow_forward"/> {overWrittenField.recipientValue}
            </Styles.OverwrittenField>
          </Styles.OverwrittenFieldContainer>
        ))}
      </DialogContent>

      <DialogActions>
        <PrimaryButton onClick={props.onCancel}>{t`No`}</PrimaryButton>
        <PrimaryButton onClick={props.onSubmit}>{t`Yes`}</PrimaryButton>
      </DialogActions>
    </Dialog>
  )

export interface fieldValuesComparison {
  label: string,
  adminValue?: string,
  recipientValue?: string,
}

interface AdminOverwriteConfirmationDialogProps {
  overWrittenFields: fieldValuesComparison[],
  onCancel: () => void,
  onSubmit: () => void,
}
