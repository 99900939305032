/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveysOfThemeQueryVariables = Types.Exact<{
  themeId: Types.Scalars['Int'];
}>;


export type SurveysOfThemeQuery = { theme: { id: number, surveys: Array<{ id: number, name: string, lastUsedAt?: string | undefined }> } };


export const SurveysOfThemeDocument = gql`
    query SurveysOfTheme($themeId: Int!) {
  theme(id: $themeId) {
    id
    surveys {
      id
      name
      lastUsedAt: last_used_at
    }
  }
}
    `;

/**
 * __useSurveysOfThemeQuery__
 *
 * To run a query within a React component, call `useSurveysOfThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysOfThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysOfThemeQuery({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useSurveysOfThemeQuery(baseOptions: Apollo.QueryHookOptions<SurveysOfThemeQuery, SurveysOfThemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveysOfThemeQuery, SurveysOfThemeQueryVariables>(SurveysOfThemeDocument, options);
      }
export function useSurveysOfThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysOfThemeQuery, SurveysOfThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveysOfThemeQuery, SurveysOfThemeQueryVariables>(SurveysOfThemeDocument, options);
        }
export type SurveysOfThemeQueryHookResult = ReturnType<typeof useSurveysOfThemeQuery>;
export type SurveysOfThemeLazyQueryHookResult = ReturnType<typeof useSurveysOfThemeLazyQuery>;
export type SurveysOfThemeQueryResult = Apollo.QueryResult<SurveysOfThemeQuery, SurveysOfThemeQueryVariables>;