import React from "react"
import { useTheme } from "styled-components"

import { BaseProps, Icon, IconProps } from "@humanpredictiveintelligence/myqvt-library"
import { AnswerSatisfaction, getAnswerSatisfaction } from "features/Comments"
import { SkinnyQuestionChoice } from "models/SkinnyQuestionChoice"

/** Component is null between the worst and best choice */
export const AnswerSatisfactionIcon: React.FC<AnswerSatisfactionIconProps> = (props) => {
  const theme = useTheme()

  const answerSatisfaction = getAnswerSatisfaction(props.choice)
  const iconProps: IconProps = {
    isInverted: true,
    padding: 4,
    size: props.size,
  }

  if (answerSatisfaction === AnswerSatisfaction.Worst) {
    iconProps.name = "priority_high"
    iconProps.backgroundColor = theme.colors.danger
  }
  else if (answerSatisfaction === AnswerSatisfaction.Best) {
    iconProps.name = "thumb_up"
    iconProps.backgroundColor = theme.colors.success
  }
  else {
    return null
  }

  return <Icon className={props.className} {...iconProps} />
}

interface AnswerSatisfactionIconProps extends BaseProps {
  size: IconProps["size"],
  choice?: SkinnyQuestionChoice,
}
