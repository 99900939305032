import styled from "styled-components/macro"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const KeyFactsModules = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: minmax(120px, auto) minmax(400px, auto);
  grid-gap: 16px;
`

export const Referrers = styled(Card)`
  grid-column: 1 / 3;
  grid-row: 1 / -1;
  padding: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export interface ModuleProps {
  $span: number,
}
