import styled, { css } from "styled-components/macro"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  padding: 40px;
  min-width: 65rem;
`

export const Module = styled(Card)<ModuleProps>`
  ${({ $span, $withScroll }) => css`
    grid-column: span ${$span};
    padding: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: ${$withScroll ? "auto" : "hidden"};
  `}
`

export const DatePickerContainer = styled(Card)<ModuleProps>`
  grid-column: span ${({ $span }) => $span};
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;
`

export interface ModuleProps {
  $span: number,
  $withScroll?: boolean,
}
