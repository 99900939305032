/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGlobalLedStatisticsQueryVariables = Types.Exact<{
  psyDispositifNanoId: Types.Scalars['String'];
  dateInterval?: Types.InputMaybe<Types.DateIntervalInput>;
}>;


export type GetGlobalLedStatisticsQuery = { getPsyReportStatistics?: { psyReportsCount: number, psyRecipientsCount: number } | undefined };


export const GetGlobalLedStatisticsDocument = gql`
    query GetGlobalLedStatistics($psyDispositifNanoId: String!, $dateInterval: DateIntervalInput) {
  getPsyReportStatistics(psyDispositifNanoId: $psyDispositifNanoId, dateInterval: $dateInterval) {
    psyReportsCount
    psyRecipientsCount
  }
}
    `;

/**
 * __useGetGlobalLedStatisticsQuery__
 *
 * To run a query within a React component, call `useGetGlobalLedStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalLedStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalLedStatisticsQuery({
 *   variables: {
 *      psyDispositifNanoId: // value for 'psyDispositifNanoId'
 *      dateInterval: // value for 'dateInterval'
 *   },
 * });
 */
export function useGetGlobalLedStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GetGlobalLedStatisticsQuery, GetGlobalLedStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalLedStatisticsQuery, GetGlobalLedStatisticsQueryVariables>(GetGlobalLedStatisticsDocument, options);
      }
export function useGetGlobalLedStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalLedStatisticsQuery, GetGlobalLedStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalLedStatisticsQuery, GetGlobalLedStatisticsQueryVariables>(GetGlobalLedStatisticsDocument, options);
        }
export type GetGlobalLedStatisticsQueryHookResult = ReturnType<typeof useGetGlobalLedStatisticsQuery>;
export type GetGlobalLedStatisticsLazyQueryHookResult = ReturnType<typeof useGetGlobalLedStatisticsLazyQuery>;
export type GetGlobalLedStatisticsQueryResult = Apollo.QueryResult<GetGlobalLedStatisticsQuery, GetGlobalLedStatisticsQueryVariables>;