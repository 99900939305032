/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { UserPermissionFragmentFragmentDoc } from '../../../models/generated/UserPermissionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnableUserPermissionsMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
}>;


export type EnableUserPermissionsMutation = { set_user_permissions: Array<{ isGranted: boolean, permission: { code: Types.UserPermissionCode, wording: string, isScopable?: boolean | undefined, group: { code: string, wording: string }, parents?: Array<{ code: Types.UserPermissionCode }> | undefined } }> };


export const EnableUserPermissionsDocument = gql`
    mutation EnableUserPermissions($userId: Int!) {
  set_user_permissions(user_id: $userId, permissions: [{code: backoffice_view, isGranted: true}]) {
    ...UserPermissionFragment
  }
}
    ${UserPermissionFragmentFragmentDoc}`;

/**
 * __useEnableUserPermissionsMutation__
 *
 * To run a mutation, you first call `useEnableUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserPermissionsMutation, { data, loading, error }] = useEnableUserPermissionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEnableUserPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<EnableUserPermissionsMutation, EnableUserPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableUserPermissionsMutation, EnableUserPermissionsMutationVariables>(EnableUserPermissionsDocument, options);
      }
export type EnableUserPermissionsMutationHookResult = ReturnType<typeof useEnableUserPermissionsMutation>;
export type EnableUserPermissionsMutationResult = Apollo.MutationResult<EnableUserPermissionsMutation>;
export type EnableUserPermissionsMutationOptions = Apollo.BaseMutationOptions<EnableUserPermissionsMutation, EnableUserPermissionsMutationVariables>;