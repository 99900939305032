import { AdminsQuery } from "graphql/queries/generated/Admins"
import { SkinnyUser, SkinnyUserCharacteristic } from "models/SkinnyUser"

export interface PermissionsGroup {
  code: string,
  wording: string,
  permissions: AdminPermission[],
}

export type Admin = AdminsQuery["admins"][0]

export type AdminPermission = Admin["permissions"][0] & { isDisabled?: boolean }
export type AdminPermissionMap = Map<AdminPermission["permission"]["code"], AdminPermission>
export type PermissionsRelationsMap =
  Map<AdminPermission["permission"]["code"], AdminPermission["permission"]["code"][]>

interface UpdateAdminInput extends Partial<SkinnyUser> {
  id: number,
}

interface AddAdminInput extends Partial<SkinnyUser> {
  [SkinnyUserCharacteristic.firstname]: string,
  [SkinnyUserCharacteristic.lastname]: string,
  language: {
    code: string,
    localizedLabel: string,
    translatedLabel: string,
  },
  id: undefined,
}

export type UpsertAdminInput = UpdateAdminInput | AddAdminInput

export function isAddAdminInput(input: UpsertAdminInput): input is AddAdminInput {
  return input.id === undefined
}

export function isUpdateAdminInput(input: UpsertAdminInput): input is UpdateAdminInput {
  return input.id !== undefined
}
