import styled, { css } from "styled-components/macro"
import { Link } from "react-router-dom"

import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const ScheduledSurveyCard = styled(Card)<ScheduledSurveyCardProps>`
  ${({ theme, $isMini, $isActive, $isInteractive, $isDisabled }) => css`
    display: flex;
    overflow-x: hidden;
    justify-content: space-between;
    align-items: center;
    height: 96px;
    padding: 0 0 0 16px;
    gap: 10px;
    transition: box-shadow 200ms;

    ${$isMini && css`
      padding: 10px 0 0;
      height: 80px;
      justify-content: center;
      text-align: center;
      overflow: hidden;

      ${StatusWrapper} {
        min-width: auto;
        margin: 0;
      }
    `}

    ${$isActive && css`
      box-shadow: 0 0 0 2px ${theme.colors.primary};
    `}

    ${$isInteractive && css`
      cursor: pointer;
    `}
    
    ${$isDisabled && css`
      background-color: ${theme.colors.blackDisabled};
      cursor: initial;
      pointer-events: none;
      box-shadow: none;

      & > * {
        filter: grayscale(100%);
      }
    `}
  `}
`
export const Title = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};
  width: 100%;
  margin-bottom: 5px;
`

export const CardLink = styled(Link)`
  text-decoration: none;
`

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeCaption};
  line-height: 14px;
`

export const StatusTitle = styled.span`
  margin-bottom: 8px;
  text-align: center;
`

interface ScheduledSurveyCardProps {
  $isMini?: boolean,
  $isActive?: boolean,
  $isInteractive?: boolean,
  $isDisabled?: boolean,
}
