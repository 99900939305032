import { unitOfTime } from "moment"
import { msgid, ngettext } from "ttag"

export function reminderPresetWording(amount: number, unit: unitOfTime.Base) {
  switch (unit) {
    case "day":
    case "days":
      return ngettext(
        msgid`${amount} day before survey is ending`,
        `${amount} days before survey is ending`,
        amount,
      )
    case "hour":
    case "hours":
      return ngettext(
        msgid`${amount} hour before survey is ending`,
        `${amount} hours before survey is ending`,
        amount,
      )
    case "month":
    case "months":
      return ngettext(
        msgid`${amount} month before survey is ending`,
        `${amount} months before survey is ending`,
        amount,
      )
    default:
      return ngettext(
        msgid`${amount} day before survey is ending`,
        `${amount} days before survey is ending`,
        amount,
      )
  }
}
