import React from "react"

import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"

export const ModuleContainer: React.FC<MetricsContainerProps> = (props) => {
  if (!props.isAllowed) {
    return <>{props.disallowedComponent}</>
  }

  if (props.isErrored) {
    return <>{props.erroredComponent}</>
  }

  return <>{props.children}</>
}

interface MetricsContainerProps extends BaseProps {
  /** The component to show on error  */
  erroredComponent?: JSX.Element,

  /** The component to show when disallowed */
  disallowedComponent?: JSX.Element,

  /** Whether to show the errored component */
  isAllowed?: boolean,

  /** Whether to show the disallowed component */
  isErrored?: boolean,
}
