import { TUTORIAL_INITIAL_STATE, TutorialState } from "features/Tutorial/TutorialState"
import { TutorialReducerAction } from "features/Tutorial/TutorialReducerActions"

export const TutorialReducer = (state: TutorialState = TUTORIAL_INITIAL_STATE, action: TutorialReducerAction) => {
  switch (action.type) {
    case "START":
      return {
        ...state,
        run: true,
        ...action.startingState,
      }
    case "STOP":
      return {
        ...state,
        isClosingTutorial: false,
        run: false,
        runJoyRide: false,
        stepIndex: state.steps.length,
      }
    case "SET_STEP_DATA":
      // Not merging data previously set for the given step. Caller is responsible to do this if needed.
      return {
        ...state,
        stepData: {
          ...state.stepData,
          [action.stepId]: action.data,
        },
      }
    case "SET_CURRENT_TARGET":
      return {
        ...state,
        currentTarget: action.target,
      }
    case "RUN":
      return {
        ...state,
        runJoyRide: action.isRunning ?? true,
      }
    case "NEXT":
      return {
        ...state,
        currentTarget: undefined,
        nextTarget: action.nextTarget,
        runJoyRide: action.isRunning ?? state.runJoyRide,
        stepIndex: action.stepIndex,
      }
    case "TOGGLE_CONFIRMATION_DIALOG":
      return {
        ...state,
        isClosingTutorial: !state.isClosingTutorial,
      }
    case "TOGGLE_SKIP_STEP_CONFIRMATION_DIALOG":
      return {
        ...state,
        isSkippingStep: !state.isSkippingStep,
      }
    case "SET_STEP_TARGET":
      if (state.stepTargets[action.stepId] === action.target) { // already defined
        return state
      }

      return {
        ...state,
        stepTargets: {
          ...state.stepTargets,
          [action.stepId]: action.target,
        },
      }
    default:
      return state
  }
}
