import styled from "styled-components/macro"

export const Container = styled.div`
    width: auto;
    height: 100%;
    overflow: hidden;
    `

export const BluredDashboard = styled.img`
    object-fit: fill;
    width: 100%;
    height: 100%;
`

export const DisplayModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    overflow: hidden;
    background-color: white;
    width: 996px;
    height: 668px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
`

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 40px;
    background-color: #236fff;
    color: white;
`

export const ModalHeaderTitle = styled.p`
    font-size: 24px;
    font-weight: 700;
    margin: 0;
`

export const ModalHeaderContent = styled.p`
    font-size: 16px;
    font-weight: 400;
    margin: 0;
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const VideoContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px;
`

export const MockupImg = styled.img`
    max-width: 710px;
    max-height: 370px;
`

export const SpeakUpVideo = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -51%);
`

export const DiscoverSpeakUp = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 50px;
`

export const LeftArrow = styled.img`
    position: absolute;
    bottom: 0;
    margin-left: 370px;
    margin-bottom: 15px;
`

export const ContactButton = styled.button`
    margin-top: 30px;
    width: fit-content;
    padding: 10px 20px;
    background-color: #236fff;
    border-radius: 20px;
    font-size: 20px;
    color: white;
    cursor: pointer;
    font-weight: 600;
    border: none;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        transform: scale(1.1);
    }
`

export const PlayButtonImg = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: width 0.3s ease, box-shadow 0.3s ease;
    width: 80px;
    
    &:hover {
        width: 90px;
    }
    `
