import styled from "styled-components/macro"

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeLabelMedium};
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  text-align: center;

  margin-top: 8px;
  white-space: pre;
`
