import React from "react"
import { t } from "ttag"

import { Stepper } from "@humanpredictiveintelligence/myqvt-library"
import { muiMyQvtVariables } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./TutorialProgression.styles"

export const TutorialProgression: React.FC<TutorialProgressionProps> = (props) => {
  const [ isHidden, setHidden ] = React.useState(false)

  return (
    <Styles.Container>
      <Styles.Toggle
        tooltip={isHidden ? t`Show tutorial progression` : t`Hide tutorial progression`}
        onClick={() => setHidden(!isHidden)}
        tooltipPlacement={"bottom"}
        size={"standard"}
        icon={isHidden ? "expand_less" : "expand_more"}
        isInverted={!isHidden}
      />
      <Styles.CardContainer $isHidden={isHidden}>
        <Styles.StepperContainer>
          <Stepper
            currentStep={props.tutorialPartNumber}
            steps={props.stepsCount}
            arePreviousStepsColored
            isLabelNotColored
            currentStepColor={muiMyQvtVariables.palette.tertiary.primary}
            previousStepColor={muiMyQvtVariables.palette.tertiary.light}
            label={props.currentStepTitle}
          />
          <Styles.QuitButton onClick={props.onTutorialQuit}>{t`Quit tutorial`}</Styles.QuitButton>
        </Styles.StepperContainer>
      </Styles.CardContainer>
    </Styles.Container>
  )
}

export interface TutorialProgressionProps {
  /** True if the progression should be hidden */
  hide?: boolean,

  /** Total number of steps */
  stepsCount: number,

  /** Current step */
  tutorialPartNumber: number,

  /** Title of the current step */
  currentStepTitle: string,

  /** Fired when the user quit the tutorial */
  onTutorialQuit?: () => void,
}
