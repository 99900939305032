import React from "react"

import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import {
  UnprocessedRecipientComments,
} from "features/Comments/UnprocessedRecipientComments"
import { Processing_Status as ProcessingStatus } from "models/generated"
import config from "config/business"

import { useUnprocessedRecipientCommentsQuery,
} from "graphql/queries/generated/UnprocessedRecipientComments"

export const UnprocessedGlobalRecipientCommentsContainer = (props: UnprocessedRecipientCommentsContainerProps) => {
  const [ pagingInfo, setPagingInfo ] = React.useState({
    currentPage: 1,
    pageSize: config.homepage.unprocessedComments.pageSizes[0],
  })
  const [ filters, setFilters ] = React.useState<ProcessingStatus[]>([
    ProcessingStatus.Awaiting,
    ProcessingStatus.Draft,
  ])

  const { data: fetchedComment, loading: isCommentsLoading, error: loadingErrors,
    refetch: fetchCommentsPage } = useUnprocessedRecipientCommentsQuery(
    {
      variables: {
        limit: pagingInfo.pageSize,
        processingStatuses: filters,
        skip: (pagingInfo.currentPage - 1) * pagingInfo.pageSize,
      },
    })

  return (
    <UnprocessedRecipientComments
      className={props.className}
      unprocessedComments={fetchedComment?.global}
      areCommentsLoading={isCommentsLoading}
      errors={loadingErrors}
      onFiltersChange={(filters) => setFilters(filters)}
      onCommentsSaved={reloadCurrentPage}
      onPagedNavigationSizeChange={props.onPagedNavigationSizeChange}
      onPaginationChange={(currentPage, pageSize) => setPagingInfo({ currentPage, pageSize })}
      isGlobalComments
    />
  )

  /**
   * Reload the current page
   */
  function reloadCurrentPage() {
    fetchCommentsPage({
      limit: pagingInfo.pageSize,
      processingStatuses: filters,
      skip: (pagingInfo.currentPage - 1) * pagingInfo.pageSize,
    })
  }
}

export interface UnprocessedRecipientCommentsContainerProps extends BaseProps {
  onPagedNavigationSizeChange?: (height: number) => void,
}
