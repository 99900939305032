/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserAttributeStatisticsFragmentFragmentDoc } from '../../../models/generated/SkinnyUserAttributeStatisticsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilteredUserAttributesQueryVariables = Types.Exact<{
  scheduledSurveyId: Types.Scalars['Int'];
  filterAttributeIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  userGroupUuids?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type FilteredUserAttributesQuery = { scheduledSurvey?: { id: number, filteredStatistics: { id: string, scheduledSurveyId?: number | undefined, isClusterUnviolated: boolean, attributes: Array<{ isClusterUnviolated: boolean, usersCount: number, attribute: { name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> } }>, userGroups: Array<{ areRecipientsOverCluster: boolean, participationSubmittedNumber: number, userGroup: { name: string, uuid: string } }> } } | undefined };


export const FilteredUserAttributesDocument = gql`
    query FilteredUserAttributes($scheduledSurveyId: Int!, $filterAttributeIds: [Int!], $userGroupUuids: [String!]) {
  scheduledSurvey: scheduled_survey(scheduled_survey_id: $scheduledSurveyId) {
    id
    filteredStatistics: detailedStatistics(attribute_ids: $filterAttributeIds, userGroupUuids: $userGroupUuids) {
      id
      scheduledSurveyId: scheduled_survey_id
      isClusterUnviolated: are_participations_above_cluster
      attributes: statistics_attributes {
        ...SkinnyUserAttributeStatisticsFragment
      }
      userGroups: statisticsUserGroup {
        areRecipientsOverCluster
        participationSubmittedNumber
        userGroup {
          name
          uuid
        }
      }
    }
  }
}
    ${SkinnyUserAttributeStatisticsFragmentFragmentDoc}`;

/**
 * __useFilteredUserAttributesQuery__
 *
 * To run a query within a React component, call `useFilteredUserAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredUserAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredUserAttributesQuery({
 *   variables: {
 *      scheduledSurveyId: // value for 'scheduledSurveyId'
 *      filterAttributeIds: // value for 'filterAttributeIds'
 *      userGroupUuids: // value for 'userGroupUuids'
 *   },
 * });
 */
export function useFilteredUserAttributesQuery(baseOptions: Apollo.QueryHookOptions<FilteredUserAttributesQuery, FilteredUserAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilteredUserAttributesQuery, FilteredUserAttributesQueryVariables>(FilteredUserAttributesDocument, options);
      }
export function useFilteredUserAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilteredUserAttributesQuery, FilteredUserAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilteredUserAttributesQuery, FilteredUserAttributesQueryVariables>(FilteredUserAttributesDocument, options);
        }
export type FilteredUserAttributesQueryHookResult = ReturnType<typeof useFilteredUserAttributesQuery>;
export type FilteredUserAttributesLazyQueryHookResult = ReturnType<typeof useFilteredUserAttributesLazyQuery>;
export type FilteredUserAttributesQueryResult = Apollo.QueryResult<FilteredUserAttributesQuery, FilteredUserAttributesQueryVariables>;