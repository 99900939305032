import * as React from "react"

import { BaseProps, PrimaryButton, SkeletonWrapper, Skeletonable } from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"
import * as Styles from "./ScheduledSurveyResultSectionHeader.styles"

/** ScheduledSurvey Results Section Header to display main information of a Result Section */
export const ScheduledSurveyResultSectionHeader: React.FC<SkeletonableResultSectionHeaderProps> = props => {
  const [ isButtonLoading, setIsButtonLoading ] = React.useState<boolean>(false)
  return (
    <Styles.Container className={props.className}>
      <Styles.Symbol
        content={props.sectionNumber}
        iconName={props.icon}
        isSecondaryBackground={props.icon !== undefined}
        $withIcon={!!props.icon}
        $isEmpty={props.sectionNumber === undefined && !props.icon}
        $hasError={props.hasError}
      />
      {props.hasError ? (
        <>
          <Styles.ErrorMessage>
            <p>{t`An error has occurred trying to fetch the results of this question.`}</p>
          </Styles.ErrorMessage>
          <PrimaryButton
            isLoading={isButtonLoading}
            destructive
            onClick={() => {
              setIsButtonLoading(true)
              props.onRetry?.()
            }}
          >
            {t`Retry`}
          </PrimaryButton>
        </>
      ) : (
        <>
          <Styles.Title>
            <SkeletonWrapper isLoading={props.isLoading}>
              {props.title}
            </SkeletonWrapper>
          </Styles.Title>
          {props.progress !== undefined && (
            <Styles.Progress
              label={props.progressLabel}
              value={props.progress}
              unit="%"
            />
          )}
        </>
      )}
    </Styles.Container>
  )
}

type SkeletonableResultSectionHeaderProps = Skeletonable<ScheduledSurveyResultSectionHeaderProps>

interface ScheduledSurveyResultSectionHeaderProps extends BaseProps {
  /** Title of the Header */
  title: string | React.ReactNode,

  /** Icon of RoundSymbol */
  icon?: string,

  /** Number for RoundSymbol */
  sectionNumber?: number,

  /** Progress value of Progress bar for header */
  progress?: number,

  /** Progress label of Progress bar */
  progressLabel?: string,

  hasError?: boolean,

  onRetry?: () => void,
}
