import { SkinnyUserAttributeValue } from "./SkinnyUserAttributeValue"
import { USER_ATTRIBUTE } from "./UserAttribute"

export enum SkinnyUserCharacteristic {
  firstname = "firstname",
  lastname = "lastname",
  email = "email",
  phone = "phone",
  language = "language",
}

export interface SkinnyUser {
  id: number,
  [SkinnyUserCharacteristic.firstname]?: string,
  [SkinnyUserCharacteristic.lastname]?: string,
  [SkinnyUserCharacteristic.email]?: string,
  [SkinnyUserCharacteristic.phone]?: string,

  language: {
    code: string,
    localizedLabel: string,
    translatedLabel: string,
  },

  timezone: string,
  role?: string,
  displayName: string,
}

export interface SkinnyAttributedUser<TAttributes extends SkinnyUserAttributeValue | USER_ATTRIBUTE>
  extends SkinnyUser
{
  attributes: TAttributes[],
}
