/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type UserSettingsFragmentFragment = { id: number, comment_languages: Array<{ code: string, localizedLabel: string, translatedLabel: string }>, tutorialsProgression?: Array<{ name: string, value: string }> | undefined };

export const UserSettingsFragmentFragmentDoc = gql`
    fragment UserSettingsFragment on User_settings {
  id
  comment_languages {
    code
    localizedLabel: localized_label
    translatedLabel: translated_label
  }
  tutorialsProgression: tuto_steps {
    name
    value
  }
}
    `;