/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
import { SkinnyScheduledSurveyFragmentFragmentDoc } from './SkinnyScheduledSurveyFragment';
import { SkinnySurveyFragmentFragmentDoc } from './SkinnySurveyFragment';
import { SkinnyThemeFragmentFragmentDoc } from './SkinnyThemeFragment';
export type ScheduledSurveyInListFragmentFragment = { id: number, isManualPublication: boolean, schedulingId: number, survey: { id: number, name: string, theme: { id: number, name: string, color: string, icon: string } }, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, scheduled_reminders: Array<{ remind_hours_before_end: number, is_editable: boolean }>, userGroups: Array<{ name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined }> };

export const ScheduledSurveyInListFragmentFragmentDoc = gql`
    fragment ScheduledSurveyInListFragment on Scheduled_survey {
  ...SkinnyScheduledSurveyFragment
  survey {
    ...SkinnySurveyFragment
    theme {
      ...SkinnyThemeFragment
    }
  }
}
    ${SkinnyScheduledSurveyFragmentFragmentDoc}
${SkinnySurveyFragmentFragmentDoc}
${SkinnyThemeFragmentFragmentDoc}`;