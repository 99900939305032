/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserSettingsQuery = { languages: Array<{ code: string, localizedLabel: string, translatedLabel: string }>, me: { id: number, email?: string | undefined, firstName: string, lastName: string, displayName: string, language: { code: string, localizedLabel: string, translatedLabel: string }, settings: { id: number, comment_languages: Array<{ code: string, localizedLabel: string, translatedLabel: string }> } } };


export const UserSettingsDocument = gql`
    query UserSettings {
  languages {
    code
    localizedLabel: localized_label
    translatedLabel: translated_label
  }
  me {
    id
    email
    firstName: first_name
    lastName: last_name
    displayName: display_name
    language {
      code
      localizedLabel: localized_label
      translatedLabel: translated_label
    }
    settings {
      id
      comment_languages {
        code
        localizedLabel: localized_label
        translatedLabel: translated_label
      }
    }
  }
}
    `;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsQuery(baseOptions?: Apollo.QueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
      }
export function useUserSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSettingsQuery, UserSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(UserSettingsDocument, options);
        }
export type UserSettingsQueryHookResult = ReturnType<typeof useUserSettingsQuery>;
export type UserSettingsLazyQueryHookResult = ReturnType<typeof useUserSettingsLazyQuery>;
export type UserSettingsQueryResult = Apollo.QueryResult<UserSettingsQuery, UserSettingsQueryVariables>;