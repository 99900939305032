import styled from "styled-components"
import { DialogActions, Dialog as MuiDialog } from "@material-ui/core"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Dialog = styled(MuiDialog)`
  max-width: 700px;
  margin: 0 auto;
`

export const Actions = styled(DialogActions)`

  justify-content: space-evenly;
`

export const ActionButton = styled(PrimaryButton)`
  margin: 0
`
