import * as React from "react"
import * as Styles from "./LedNavigationTabs.styles"
import { useHistory } from "react-router-dom"
import { t } from "ttag"
import { ApplicationPath } from "../../Navigation"
import {
  SearchableSelect,
  SearchableSelectOption,
} from "@humanpredictiveintelligence/myqvt-library"
import { useMemo } from "react"
import { usePsyDispositifNanoId } from "features/Led/psyDispositifStore"
import { useSession } from "features/Session"

export const LedNavigationTabs: React.FC = (props) => {
  const { setPsyDispositifNanoId, psyDispositifNanoId } = usePsyDispositifNanoId()
  const { customer } = useSession()
  const { location } = useHistory()

  React.useEffect(() => {
    const psyDispositifs = customer.psyCompany?.psyDispositifs ?? []

    if (psyDispositifs.length >= 1 && !psyDispositifNanoId) {
      setPsyDispositifNanoId(psyDispositifs[0].nanoId)
    }
  }, [ customer.psyCompany?.psyDispositifs, psyDispositifNanoId, setPsyDispositifNanoId ])

  const customerDispositifsOptions = useMemo(() => {
    const dispositifsOptions = customer.psyCompany?.psyDispositifs ?? []

    return dispositifsOptions.map((dispositif) => ({
      label: dispositif.name,
      value: dispositif.nanoId,
    }))
  }, [ customer.psyCompany?.psyDispositifs ])

  return (
    <>
      <Styles.TabsNavigation>
        {customerDispositifsOptions.length > 1 && (
          <SearchableSelect
            options={customerDispositifsOptions}
            onChange={(value) => updateDispostifsSelect(value)}
            selectPlaceholder={t`Select a dispositif`}
            defaultValues={[ psyDispositifNanoId ]}
          />
        )}
        {customer.psyCompany && <>
          <Styles.TabLink
            to={ApplicationPath.LedStatistics}
            $isCurrent={isActiveMenuItem(ApplicationPath.LedStatistics)}
          >
            {t`Statistics`}
          </Styles.TabLink>
          <Styles.TabLink
            to={ApplicationPath.LedDiscussions}
            $isCurrent={isActiveMenuItem(ApplicationPath.LedDiscussions)}
          >
            {t`Meetings`}
          </Styles.TabLink>
          <Styles.Divider></Styles.Divider>
        </>}

        <Styles.TabLink
          to={ApplicationPath.LedDashboard}
          $isCurrent={isActiveMenuItem(ApplicationPath.LedDashboard)}
        >
          {t`Key facts`}
        </Styles.TabLink>
      </Styles.TabsNavigation>
      <Styles.ComponentContainer>
        {props.children}
      </Styles.ComponentContainer>
    </>
  )

  function isActiveMenuItem(path: string) {
    return location.pathname.includes(path)
  }

  function updateDispostifsSelect(dispositifOptions: SearchableSelectOption[]) {
    if (dispositifOptions.length > 0 && dispositifOptions[0].value !== undefined) {
      setPsyDispositifNanoId(dispositifOptions[0].value)
    }
  }
}
