import * as React from "react"
import { t } from "ttag"
import { ApolloError } from "@apollo/client"

import { AuthenticationContext } from "features/Authentication"
import { NotificationContext } from "features/Notification"
import { UserGroup } from "features/UserGroups/groups.model"
import { useUserGroups } from "features/UserGroups/UserGroupsContext"

import * as Styles from "./UserTransferActions.styles"

import { useAddUsersToGroupMutation } from "graphql/mutations/generated/AddUsersToGroup"
import { useDeleteUsersFromUserGroupMutation } from "graphql/mutations/generated/DeleteUsersFromUserGroup"

export const UserTransferActions: React.FC = () => {
  const { actions, state } = useUserGroups()
  const { getUserSession } = React.useContext(AuthenticationContext)
  const notification = React.useContext(NotificationContext)

  const [ areUsersBeingAdded, setAreUsersBeingAdded ] = React.useState(false)
  const [ areUsersBeingRemoved, setAreUsersBeingRemoved ] = React.useState(false)

  const [ addUsersToGroup ] = useAddUsersToGroupMutation()
  const [ deleteUsersFromGroup ] = useDeleteUsersFromUserGroupMutation()

  return (
    <Styles.Container>
      <Styles.ActionContainer>
        <Styles.ActionLabel>{t`Add`}</Styles.ActionLabel>
        {areUsersBeingAdded
          ? <Styles.TransferLoader isTransparent color="light" size="s" />
          : (
            <Styles.TransferButton
              icon="keyboard_double_arrow_right"
              onClick={() => onAddUsersToGroup(state.selectedUsersIds, state.selectedGroupUuid)}
              isDisabled={!state.selectedGroupUuid || !state.areUsersSelected}
            />
          )
        }
      </Styles.ActionContainer>

      <Styles.ActionContainer>
        {areUsersBeingRemoved
          ? <Styles.TransferLoader isTransparent color="light" size="s" />
          : (
            <Styles.TransferButton
              icon="keyboard_double_arrow_left"
              onClick={() => onDeleteUsersFromGroup(state.selectedUsersInGroupIds, state.selectedGroupUuid)}
              isDisabled={!state.selectedGroupUuid || !state.areUsersSelectedInGroup}
            />
          )
        }
        <Styles.ActionLabel>{t`Remove`}</Styles.ActionLabel>
      </Styles.ActionContainer>

    </Styles.Container>
  )

  async function onAddUsersToGroup(userIds: number[], userGroupUuid: UserGroup["uuid"]) {
    setAreUsersBeingAdded(true)
    try {
      await addUsersToGroup({
        variables: {
          userGroupUuid: userGroupUuid,
          userIds: userIds,
        },
      })
      actions.setShouldRefetchUsers(true)

      getUserSession()

      if (state.selectedUsersIds.length === state.displayedNumberOfUsers) {
        actions.setShouldGoToPreviousUserPage(true)
      }

      setAreUsersBeingAdded(false)
      notification.show(t`Success`, t`The user(s) has been successfully added to the selected group`)
      actions.setSelectedUsersIds([])
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
  }

  async function onDeleteUsersFromGroup(userIds: number[], userGroupUuid: UserGroup["uuid"]) {
    setAreUsersBeingRemoved(true)
    try {
      await deleteUsersFromGroup({
        variables: {
          userGroupUuid: userGroupUuid,
          userIds: userIds,
        },
      })
      actions.setShouldRefetchUsers(true)

      getUserSession()

      if (state.selectedUsersInGroupIds.length === state.displayedNumberOfUsersInGroup) {
        actions.setShouldGoToPreviousUserGroupPage(true)
      }

      actions.setSelectedUsersInGroupIds([])
      setAreUsersBeingRemoved(false)
      notification.show(t`Success`, t`The user(s) has been successfully removed to the group`)
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
  }
}
