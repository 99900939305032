import styled, { css } from "styled-components/macro"
import { ProgressBar, RoundSymbol } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 72px; // 40px for Symbol height + padding-y
  padding: 16px 46px;

  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.main};

  background: white;
  
  @media print {
    margin: 0;
    padding: 16px 24px;
    box-shadow: none;
  }
`

export const Symbol = styled(RoundSymbol)<{ $withIcon?: boolean, $isEmpty?: boolean, $hasError?: boolean }>`
  margin-right: 16px;

  ${({ theme, $withIcon, $isEmpty, $hasError }) => css`
    ${$withIcon && css`
      color: ${theme.colors.white}
    `}
    
    ${$hasError && css`
      background-color: ${theme.colors.dangerLight};
    `}
    
    ${$isEmpty && css`
      background-color: ${theme.colors.blackDisabled};
    `}
  `}
`

export const Title = styled.div`
  flex-grow: 1;

  font-size: ${({ theme }) => theme.typography.fontSizeTitleSmall};
  line-height: 24px;
`

export const Progress = styled(ProgressBar)`
  margin-left: 24px;
  
  @media print {
    display: none;
  }
`

export const ErrorMessage = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`
