/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnyQuestionChoiceFragmentFragment = { id: number, name: string, icon: string, satisfactionValue: number, maxSatisfactionValue: number };

export const SkinnyQuestionChoiceFragmentFragmentDoc = gql`
    fragment SkinnyQuestionChoiceFragment on Choice {
  id
  icon: icon_url
  name
  satisfactionValue: satisfaction_value
  maxSatisfactionValue: max_satisfaction_value
}
    `;