import React from "react"
import * as Styles from "./AdminListItem.styles"
import { Admin } from "../../models"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { useTheme } from "styled-components"

export const AdminListItem: React.FC<AdminListItemProps> = (props) => {
  const theme = useTheme()
  const emailRef = React.useRef<HTMLSpanElement>(null)
  const itemContentContainerRef = React.useRef<HTMLDivElement>(null)
  const [ isHovering, setIsHovering ] = React.useState<boolean>(false)

  const emailWidth: number = emailRef.current?.clientWidth ?? 0
  const containerWidth: number = itemContentContainerRef.current?.clientWidth ?? 0

  const isEmailOverflowing = !!emailWidth
    && !!containerWidth
    && isTextSizeLargerThanContainerSize(emailWidth, containerWidth)

  return (
    <Styles.Container
      $isSelected={props.isSelected}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => props.onAdminSelect(props.admin)}
    >
      <Styles.Content ref={itemContentContainerRef}>
        <Styles.UserName>{props.admin.lastName} {props.admin.firstName}</Styles.UserName>
        <Styles.UserEmail
          $isAnimating={isEmailOverflowing && isHovering}
          ref={emailRef}
        >
          {props.admin.email}
        </Styles.UserEmail>
      </Styles.Content>
      {props.admin.isAdmin && <Icon name="star" size={24} color={theme.colors.secondary}/>}
    </Styles.Container>
  )

  function isTextSizeLargerThanContainerSize(textSize: number, containerSize: number): boolean {
    return textSize! > containerSize!
  }
}

interface AdminListItemProps {
  admin: Admin,
  onAdminSelect: (admin: Admin) => void,
  isSelected: boolean,
}
