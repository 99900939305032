import React from "react"
import memoizeOne from "memoize-one"

import TUTORIAL_REDUCER_ACTIONS, { TutorialReducerAction } from "features/Tutorial/TutorialReducerActions"
import { SessionReducerActions, useSession } from "features/Session"
import { TutorialState } from "features/Tutorial/TutorialState"
import { Tutorial, TutorialStep } from "features/Tutorial/models"
import { TutorialEvent, TutorialTarget } from "features/Tutorial/TutorialContext"

import { useUpdateTutorialProgressionMutation } from "graphql/mutations/generated/UpdateTutorialProgression"
import { findStepIndex, getTutorialSteps } from "./utils"

/**
 * Tutorial Internal Controller Hook : Used internally by the tutorial component to control tutorial state
 * This is not meant to be used by external components directly.
 * @param dispatch
 */
const useTutorialController = (dispatch: React.Dispatch<TutorialReducerAction>) => {
  const session = useSession()

  const [ updateTutorialProgression ] = useUpdateTutorialProgressionMutation()

  /**
   * Return true if the given tutorial is active and not finished
   */
  const isTutorialActive = (tutorialState: TutorialState): boolean => {
    return tutorialState.run !== undefined
      && tutorialState.run
      && tutorialState.tutorial !== undefined
      && !isTutorialFinished(tutorialState.tutorial, tutorialState.stepIndex)
  }

  const startTutorial = (activeTutorial: Tutorial, stepId: string) => {
    activeTutorial = initializeTutorial(activeTutorial)
    const steps = getTutorialSteps(activeTutorial)
    dispatch(TUTORIAL_REDUCER_ACTIONS.start({
      id: activeTutorial.id,
      partsCount: activeTutorial.parts.length,
      stepIndex: getStartingStep(steps, findStepIndex(activeTutorial, stepId)),
      steps: steps,
      tutorial: activeTutorial,
    }))
  }

  /**
   * Calculate automatically the partIndex of the step in the tutorial (overrides the one provided)
   * Return the tutorial with the new partIndex in the steps
   * @param tutorial
   */
  const initializeTutorial = (tutorial: Tutorial): Tutorial => {
    let partIndex = 0
    tutorial.parts = tutorial.parts.map((tutorialPart: TutorialStep[]) => {
      const steps = tutorialPart.map((step: TutorialStep) => {
        step.partIndex = partIndex
        return step
      })
      partIndex++
      return steps
    })
    return tutorial
  }

  /**
   * Change the active step of the tutorial to the next step
   * @param tutorialState
   */
  const confirmSkipStep = (tutorialState: TutorialState) => {
    changeStep(tutorialState.stepIndex + 1, tutorialState)
    toggleSkipStepConfirmationDialog()
  }

  /**
   * Handle triggered events
   * - change the progression of the tutorial (forward or backward)
   * - provide `nextTarget` created at runtime if provided
   * */
  const handleEvent = (tutorialState: TutorialState, event: TutorialEvent) => {
    const step = tutorialState.steps[tutorialState.stepIndex]
    let progression

    // Prevent to trigger event when tutorialState is not loaded yet
    if (step === undefined) {
      return
    }

    switch (event.name) {
      case step.nextActionEvent:
        progression = tutorialState.stepIndex + 1
        break
      case step.prevActionEvent:
        progression = tutorialState.stepIndex - 1
        break
      default:
        return
    }
    changeStep(progression, tutorialState, event.nextTarget)
  }

  const getStepData = <S = unknown>(tutorialState: TutorialState, stepId: string): S => {
    return tutorialState.stepData[stepId]
  }

  /**
   * Called in the application by components to trigger specific events when certain actions occurs.
   * Event are only triggered if the tutorial is active.
   * */
  const triggerTutorialEvent = (tutorialState: TutorialState, event: TutorialEvent) => {
    return !isTutorialActive(tutorialState) ? null : handleEvent(tutorialState, event)
  }

  const changeStepTarget = (target: TutorialTarget): void => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.setCurrentTarget(target))
  }

  const runTutorial = (): void => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.run(true))
  }

  const pauseTutorial = (): void => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.run(false))
  }

  /**
   * Save the current tutorial progression on the backend
   * @param stepId The step index to save
   * @param tutorialId The tutorial id to save the value on
   * @default The id of the tutorial currently running
   * */
  const saveProgression = (stepId: string, tutorialId: string) => {
    updateTutorialProgression({
      variables: {
        tutorialName: tutorialId,
        value: stepId,
      },
    })
    session.dispatch(SessionReducerActions.setTutorialProgression({ name: tutorialId, value: stepId }))
  }

  const stopTutorial = (): void => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.stop())
  }

  const toggleSkipStepConfirmationDialog = (): void => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.toggleSkipStepConfirmationDialog())
  }

  const toggleQuitTutorialConfirmationDialog = (): void => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.toggleQuitConfirmationDialog())
  }

  /**
   * Define data for a given step which can be used to create custom behavior tutorial
   * @param stepId
   * @param data
   */
  const setStepData = (stepId: string, data: unknown): void => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.setStepData(stepId, data))
  }

  /**
   * Called in the application by components to define a specific target at runtime (in an imperative way)
   * for a given step (identified by its `id` property)
   * */
  const setStepTarget = (stepId: string, target: TutorialTarget): void => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.setStepTarget(stepId, target))
  }

  /**
   * Check whether or not the step must be triggered imperatively to be run
   * @param step
   */
  const isImperative = (step: TutorialStep): boolean => {
    return !!step.isImperative
  }

  /**
   * Change the active step of the tutorial to the step with given step index
   * - if next step is imperative, it will pause the joyride execution
   * - if next step must be skipped, it will jump to the next step
   * @param stepIndex
   * @param tutorialState
   * @param nextTarget
   */
  const changeStep = (stepIndex: number, tutorialState: TutorialState, nextTarget?: TutorialState["nextTarget"]) => {
    dispatch(TUTORIAL_REDUCER_ACTIONS.next(
      shouldSkipStep(stepIndex, tutorialState) ? ++stepIndex :  stepIndex,
      nextTarget ?? tutorialState.nextTarget,
      tutorialState.steps[stepIndex] ? !isImperative(tutorialState.steps[stepIndex]) : false,
    ))
  }

  const shouldSkipStep = (stepIndex: number, tutorialState: TutorialState) => {
    const step = tutorialState.steps[stepIndex]
    if (step) {
      return step.skip instanceof Function ? step.skip(getStepData(tutorialState, step.id)) : step.skip
    }
    return false
  }

  /**
   * Retrieve the starting step recursively by checking if given step has previous step dependency and so on
   * Whether this step need to go to the next step when use resume tutorial.
   * Next or previous step will be executed even if user progression is saved to the current step.
   *
   * e.g. Given the following dependencies : [A] -> B -> C -x D
   *
   * [] = current saved step
   * -> = dependency with next
   * -x = no dependency
   *
   * Tutorial will load step (C) which is the outer most next dependency for the current step (A)
   *
   * @see TutorialStep.stepDependencyWithPrevious
   * @see TutorialStep.stepDependencyWithNext
   * @param steps
   * @param stepIndex
   */
  const getStartingStep = memoizeOne((steps: TutorialStep[], stepIndex: number): number => {
    switch (true) {
      case steps[stepIndex].stepDependencyWithPrevious:
        return getStartingStep(steps, stepIndex - 1)
      case steps[stepIndex].stepDependencyWithNext:
        return getStartingStep(steps, stepIndex + 1)
      default:
        return stepIndex
    }
  })

  const isTutorialFinished = memoizeOne((tutorial: Tutorial, currentStepProgression: number): boolean => {
    return getTutorialStepsCount(tutorial) <= currentStepProgression
  })

  const getTutorialStepsCount = memoizeOne((tutorial: Tutorial): number => {
    tutorial = initializeTutorial(tutorial)
    return getTutorialSteps(tutorial).length - 1
  })

  return {
    changeStep,
    changeStepTarget,
    confirmSkipStep,
    getStepData,
    getTutorialStepsCount,
    isImperative,
    isTutorialActive,
    isTutorialFinished,
    pauseTutorial,
    runTutorial,
    saveProgression,
    setStepData,
    setStepTarget,
    startTutorial,
    stopTutorial,
    toggleQuitTutorialConfirmationDialog,
    toggleSkipStepConfirmationDialog,
    triggerTutorialEvent,
  }
}

export default useTutorialController
