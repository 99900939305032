/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendInitPasswordQueryVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
}>;


export type SendInitPasswordQuery = { send_init_password_to_user: boolean };


export const SendInitPasswordDocument = gql`
    query SendInitPassword($userId: Int!) {
  send_init_password_to_user(user_id: $userId)
}
    `;

/**
 * __useSendInitPasswordQuery__
 *
 * To run a query within a React component, call `useSendInitPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendInitPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendInitPasswordQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSendInitPasswordQuery(baseOptions: Apollo.QueryHookOptions<SendInitPasswordQuery, SendInitPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendInitPasswordQuery, SendInitPasswordQueryVariables>(SendInitPasswordDocument, options);
      }
export function useSendInitPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendInitPasswordQuery, SendInitPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendInitPasswordQuery, SendInitPasswordQueryVariables>(SendInitPasswordDocument, options);
        }
export type SendInitPasswordQueryHookResult = ReturnType<typeof useSendInitPasswordQuery>;
export type SendInitPasswordLazyQueryHookResult = ReturnType<typeof useSendInitPasswordLazyQuery>;
export type SendInitPasswordQueryResult = Apollo.QueryResult<SendInitPasswordQuery, SendInitPasswordQueryVariables>;