import styled from "styled-components/macro"
import { Dialog } from "@material-ui/core"

import { Icon } from "@humanpredictiveintelligence/myqvt-library"

export const ImportDialog = styled(Dialog)`
  & .Container {
    width: 650px;
    height: 360px;
    
    padding: 0;
    overflow: visible;
  }
  
  & .Content {
    width: 100%;
    height: 100%; 
    
    border-radius: 5px;
    overflow: hidden;
  }
`

export const FileImportSection = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  
  background-color: ${({ theme }) => theme.colors.blackUltraLight};
  border-right: 1px solid ${({ theme }) => theme.colors.blackMedium};
`

export const ProcessImportSection = styled.div`

`

export const CloseIcon = styled(Icon)`
  background: white;
  border-radius: 50px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px;
  box-shadow: ${props => props.theme.shadows.button};
  transform: translate(50%, -50%);
  z-index: 1000;
`
