import styled from "styled-components/macro"
import { InformationMessage } from "@humanpredictiveintelligence/myqvt-library"
import { MultilineInput } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  width: 100%;
  padding: 24px 64px;
`

export const Explanation = styled(InformationMessage)`
  margin-bottom: 32px;
  white-space: pre-line;
`

export const FeedbackInput = styled(MultilineInput)`
  color: blue;
  .FeedBackInput__field {
    width: 100%;
  }
`

export const Status = styled.p`
  text-align: right;
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  line-height: 20px;
`

export const Author = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const Actions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  position: relative;

  margin-top: 16px;
`

export const CrudActions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: 32px;
  }
`
