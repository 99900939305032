import moment from "moment"
import { JsDateRange } from "models/DateTime/JsDateRange"
import { MomentRange } from "models/DateTime/MomentRange"
import { TimeRange } from "models/DateTime/TimeRange"

export abstract class DATE_TIME_HELPER {
  /** Get a TimeRange from a MomentRange */
  static timeRangeFromMomentRange(
    momentRange: MomentRange | undefined,
    defaultTime = { hours: 0, minutes: 0 },
  ): TimeRange {
    const range: TimeRange = {
      from: defaultTime,
      to: defaultTime,
    }

    if (momentRange) {
      if (momentRange.from) {
        range.from = { hours: momentRange.from.get("hours"), minutes: momentRange.from.get("minutes") }
      }

      if (momentRange.to) {
        range.to = { hours: momentRange.to.get("hours"), minutes: momentRange.to.get("minutes") }
      }
    }

    return range
  }

  /** Get a JsDateRange from a MomentRange */
  static jsDateRangeFromMomentRange(momentRange: MomentRange | undefined): JsDateRange {
    const range: JsDateRange = {
      from: undefined,
      to: undefined,
    }

    if (momentRange) {
      if (momentRange.from) {
        range.from = momentRange.from.toDate()
      }

      if (momentRange.to) {
        range.to = momentRange.to.toDate()
      }
    }

    return range
  }

  /**
   * Merge the given date and time ranges into a MomentRange object
   * @param dateRange date range to merge
   * @param timeRange time range to merge
   */
  static momentRange(dateRange: JsDateRange, timeRange: TimeRange): MomentRange {
    const range: MomentRange = {
      from: undefined,
      to: undefined,
    }

    if (dateRange.from) {
      range.from = moment(dateRange.from)
        .set("hours", timeRange.from.hours)
        .set("minutes", timeRange.from.minutes)
    }

    if (dateRange.to) {
      range.to = moment(dateRange.to)
        .set("hours", timeRange.to.hours)
        .set("minutes", timeRange.to.minutes)
    }

    return range
  }
}
