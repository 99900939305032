import styled from "styled-components/macro"
import { Title as LibraryTitle } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  padding: 8px;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
`

export const ScopeManagerContainer = styled(Container)`
  width: 50%;
`

export const Title = styled(LibraryTitle)`
  margin: 0;
  padding: 0;
  border: none;
`
export const Paragraph = styled.p`
  padding: 0;
  margin: 10px 0;
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
  color: ${({ theme }) => theme.colors.blackMedium};
  white-space: pre-line;
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`
