/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLedFilesQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetLedFilesQuery = { getLedFiles?: { count: number, ledFiles: Array<{ nanoId?: string | undefined, title?: string | undefined, url?: string | undefined, contentType?: string | undefined, createdAt?: string | undefined }> } | undefined };


export const GetLedFilesDocument = gql`
    query GetLedFiles($limit: Int!, $skip: Int) {
  getLedFiles(skip: $skip, limit: $limit) {
    count
    ledFiles {
      nanoId
      title
      url
      contentType
      createdAt
    }
  }
}
    `;

/**
 * __useGetLedFilesQuery__
 *
 * To run a query within a React component, call `useGetLedFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLedFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLedFilesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetLedFilesQuery(baseOptions: Apollo.QueryHookOptions<GetLedFilesQuery, GetLedFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLedFilesQuery, GetLedFilesQueryVariables>(GetLedFilesDocument, options);
      }
export function useGetLedFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLedFilesQuery, GetLedFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLedFilesQuery, GetLedFilesQueryVariables>(GetLedFilesDocument, options);
        }
export type GetLedFilesQueryHookResult = ReturnType<typeof useGetLedFilesQuery>;
export type GetLedFilesLazyQueryHookResult = ReturnType<typeof useGetLedFilesLazyQuery>;
export type GetLedFilesQueryResult = Apollo.QueryResult<GetLedFilesQuery, GetLedFilesQueryVariables>;