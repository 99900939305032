import { ResponsivePie } from "@nivo/pie"
import moment from "moment"
import * as React from "react"
import { t } from "ttag"
import { useTheme } from "styled-components"

import { Caption } from "@humanpredictiveintelligence/myqvt-library"

import { NumberMetric } from "@humanpredictiveintelligence/myqvt-library"

import { SkeletonWrapper, Skeletonable } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./RecipientsMetrics.styles"

export const RecipientsMetrics: React.FC<Skeletonable<RecipientsMetricsProp>> = (props) => {
  const theme = useTheme()

  const chartData = [
    {
      color: theme.colors.primary,
      id: "accepted",
      label: t`${props.acceptedTermsOfServicePercentage}% accepted`,
      value: props.acceptedTermsOfServicePercentage,
    },
    {
      color: theme.colors.secondary,
      id: "waiting",
      label: t`${props.waitingTermsOfServicePercentage}% waiting`,
      value: props.waitingTermsOfServicePercentage,
    },
    {
      color: theme.colors.danger,
      id: "refused",
      label: t`${props.refusedTermsOfServicePercentage}% refused`,
      value: props.refusedTermsOfServicePercentage,
    },
  ]

  return (
    <Styles.Card
      title={title()}
      $isDataExpired={props.isDataExpired}
      className={props.className}
      hasReducedPadding
    >
      <NumberMetric isLoading={props.isLoading} value={props.recipientsCount}/>
      <Caption>{t`Registered users`}</Caption>

      <Styles.TermsOfServiceAcceptance>
        <Styles.Chart>
          <SkeletonWrapper isLoading={props.isLoading} circle={true} width={50} height={50}>
            <ResponsivePie
              colors={{ datum: "data.color" }}
              data={chartData}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              innerRadius={0.5}
              isInteractive={false}
            />
          </SkeletonWrapper>
        </Styles.Chart>
        <Styles.ChartLegend>
          {chartData.map(chart => {
            return <li key={chart.id}>
              <Styles.LegendSymbol $backgroundColor={chart.color}/>
              <SkeletonWrapper isLoading={props.isLoading} width={50}>
                <>&nbsp; {chart.label}</>
              </SkeletonWrapper>
            </li>
          })}
        </Styles.ChartLegend>
      </Styles.TermsOfServiceAcceptance>
      <Caption>{t`Terms of service acceptance`}</Caption>
    </Styles.Card>
  )

  /**
   * Get the title of the card
   */
  function title() {
    const latestImportDate = props.latestUpdateDate && moment(props.latestUpdateDate).format("L")

    return (
      <Styles.CardTitle level="metricCard">
        {t`Users database`}
        <Styles.LatestImportDate>
          {props.isDataExpired && (
            <><Styles.ExpiredIcon name="warning" size={13}/>&nbsp;</>
          )}
          {latestImportDate && t`Latest import: ${latestImportDate}`}
        </Styles.LatestImportDate>
      </Styles.CardTitle>
    )
  }
}

export interface RecipientsMetricsProp extends BaseProps {
  /** Percentage of recipients who accepted the TOS */
  acceptedTermsOfServicePercentage: number,

  /** Whether the users database is considered too old */
  isDataExpired?: boolean,

  /** Date of the latest import */
  latestUpdateDate?: string,

  /** Total number of recipients in the database */
  recipientsCount?: number,

  /** Percentage of recipients who refused the TOS */
  refusedTermsOfServicePercentage: number,

  /** Percentage of recipients who neither accepted nor refused the TOS */
  waitingTermsOfServicePercentage: number,
}
