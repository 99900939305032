import * as React from "react"
import { t } from "ttag"

import { AttributableFeedback } from "../AttributableFeedback"
import { CommentObjectType, Comment_Translation as CommentTranslation, UserPermissionCode } from "models/generated"
import { MultiLanguageFeedback } from "../MultiLanguageFeedback"
import { ScheduledSurveyResultSection } from "../ScheduledSurveyResultSection"
import { BaseProps, Tab, TabContent } from "@humanpredictiveintelligence/myqvt-library"
import { Comment } from "models/Comment"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"
import { useUserPermissions } from "features/Permissions"
import { useAddSurveyFeedbackMutation } from "graphql/mutations/generated/AddSurveyFeedback"
import { useUpdateSurveyFeedbackMutation } from "graphql/mutations/generated/UpdateSurveyFeedback"

enum FeedbackSectionTab {
  GlobalFeedback = 0,
  LocalFeedback = 1,
}

export const ScheduledSurveyFeedbackSection: React.FC<ScheduledSurveyFeedbackProps> = (props) => {
  const isUserAllowed = useUserPermissions()

  const isGlobalFeedbackTabVisible = isUserAllowed(UserPermissionCode.SurveyFeedbacksRead)
  const isLocalFeedbackTabVisible = isUserAllowed(UserPermissionCode.LocalSurveyFeedbacksRead)
      && props.firstLevelAttributes?.length > 0

  const [ activeTab, setActiveTab ] = React.useState(Number(!isGlobalFeedbackTabVisible && isLocalFeedbackTabVisible))

  const isAllowedToWriteLocalFeedback = () => isUserAllowed(UserPermissionCode.LocalSurveyFeedbacksWrite)
  const isAllowedToPublishLocalFeedback = () => isUserAllowed(UserPermissionCode.LocalSurveyFeedbacksPublish)
  const isAllowedToPublishFeedback = () => isUserAllowed(UserPermissionCode.SurveyFeedbacksPublish)
  const isAllowedToWriteFeedback = () => isUserAllowed(UserPermissionCode.SurveyFeedbacksWrite)

  const [ addComment ] = useAddSurveyFeedbackMutation()
  const [ updateComment ] = useUpdateSurveyFeedbackMutation()

  return (
    <ScheduledSurveyResultSection
      title={t`Write a global feedback to your teams`}
      icon="create"
      className={props.className}
    >
      {isGlobalFeedbackTabVisible &&
      <Tab
        label={t`Global feedback`}
        isActive={activeTab === FeedbackSectionTab.GlobalFeedback}
        onClick={() => setActiveTab(FeedbackSectionTab.GlobalFeedback)}
      />}
      {isLocalFeedbackTabVisible && (
        <Tab
          label={t`Local feedback`}
          isActive={activeTab === FeedbackSectionTab.LocalFeedback}
          onClick={() => setActiveTab(FeedbackSectionTab.LocalFeedback)}
        />
      )}
      <TabContent>
        {activeTab === FeedbackSectionTab.GlobalFeedback && (
          <MultiLanguageFeedback
            comment={props.globalComment}
            parentId={props.scheduledSurveyId}
            commentObjectType={CommentObjectType.ScheduledSurvey}
            areResultsAlreadyPublished={props.areResultsPublished}
            publishingDate={props.publishingDate}
            onSave={props.onSaved}
            isAllowedToPublish={isAllowedToPublishFeedback}
            isAllowedToWrite={isAllowedToWriteFeedback}
            add={addFeedback}
            update={updateFeedback}
          />
        )}
        {activeTab === FeedbackSectionTab.LocalFeedback && (
          <AttributableFeedback
            type={CommentObjectType.ScheduledSurvey}
            attributeValues={props.firstLevelAttributes}
            comments={props.scopedComments}
            objectId={props.scheduledSurveyId}
            areResultsAlreadyPublished={props.areResultsPublished}
            publishingDate={props.publishingDate}
            onSaved={props.onSaved}
            onDeleted={props.onDeleted}
            isAllowedToPublish={isAllowedToPublishLocalFeedback}
            isAllowedToWrite={isAllowedToWriteLocalFeedback}
          />
        )}
      </TabContent>
    </ScheduledSurveyResultSection>
  )

  async function updateFeedback(id: number, isDraft: boolean, translations: CommentTranslation[]) {
    const query = await updateComment({
      variables: {
        commentId: id,
        isDraft,
        translations: translations,
      },
    })

    return query.data?.comment
  }

  async function addFeedback(id: number, isDraft: boolean, translations: CommentTranslation[]) {
    const query = await addComment({
      variables: {
        isDraft,
        objectId: id,
        translations: translations,
      },
    })

    return query.data?.comment
  }
}

interface ScheduledSurveyFeedbackProps extends BaseProps {
  /** ID of the scheduledSurvey */
  scheduledSurveyId: number,

  /** Attribute values of first hierarchical level */
  firstLevelAttributes: USER_ATTRIBUTE_VALUE[],

  /** Unscoped comment from the admin */
  globalComment?: Comment,

  /** Scoped comments from the admin */
  scopedComments?: Comment[],

  /** If true, disable the edit feature */
  areResultsPublished?: boolean,

  /** The publishing date of the consulted survey */
  publishingDate?: string,

  /** Optional callback when the comment is saved (created or updated) */
  onSaved?: () => void,

  /** Optional callback when the comment is deleted */
  onDeleted?: () => void,
}
