import styled from "styled-components/macro"
import { motion } from "framer-motion"

export const Container = styled.div`
    width: 474px;
    text-align: center;
    overflow: hidden;
    position: relative;
  
    background-color: ${({ theme }) => theme.colors.white};
    padding: 20px 10px;
    border-radius: 10px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const AnimatePresenceContainer = styled.div`
    width: 100%;
    min-height: 100px;
`

export const ScrollingContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const Logo = styled.img` 
`
