import * as React from "react"
import { Skeletonable, Title, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps, CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"
import { ResponsivePie } from "@nivo/pie"
import AutoSizer from "react-virtualized-auto-sizer"
import { t } from "ttag"

import { PieChartLegend } from "./PieChartLegend"

import * as Styles from "./PieChartStatistic.styles"

import { GetPsyAttributeStatisticsQuery } from "graphql/queries/generated/GetPsyAttributeStatistics"

const PieChartStatisticSkeleton: React.FC<PieChartStatisticProps> = (props) => (
  <AutoSizer>
    {({ width, height }: {height: number, width: number}) => (
      <Styles.Container $width={width} $height={height}>
        <Title level="metricCard">
          {props.title}
        </Title>
        <CenteredLoader isTransparent/>
      </Styles.Container>
    )}
  </AutoSizer>
)

export const PieChartStatisticComponent: React.FC<Skeletonable<PieChartStatisticProps>> = (props) => {
  const pieChartLegendData = React.useCallback(() => {
    return props.chartData.map((chart) => {
      return <PieChartLegend chart={chart} totalValue={props.attributeStatistics.psyReportsCount}/>
    })
  }, [ props.chartData, props.attributeStatistics.psyReportsCount ])

  return (
    <AutoSizer>
      {({ width, height }: {height: number, width: number}) => (
        <Styles.Container $width={width} $height={height}>
          <Title level="metricCard">
            {props.title}
          </Title>
          <Styles.ContentContainer>
            <Styles.Chart>
              <ResponsivePie
                colors={{ datum: "data.color" }}
                data={props.chartData}
                padAngle={2}
                cornerRadius={3}
                enableArcLabels={false}
                tooltip={({ datum }) => (
                  <Styles.Tooltip shadow="button" isReducedPadding >
                    <Styles.TooltipValue>{datum.value}</Styles.TooltipValue>
                    <Styles.TooltipLabel>{datum.data.nameValue}</Styles.TooltipLabel>
                  </Styles.Tooltip>
                )}
                enableArcLinkLabels={false}
                innerRadius={props.innerRadius}
                isInteractive
                animate={false}
              />
              <Styles.Overlay>
                <Styles.TotalNumber>{props.attributeStatistics.psyRecipientsCount}</Styles.TotalNumber>
                <Styles.TotalText>{t`Recipients`}</Styles.TotalText>
              </Styles.Overlay>
            </Styles.Chart>
            <Styles.ChartContainerLegend>
              <Styles.ChartLegend>
                {pieChartLegendData()}
              </Styles.ChartLegend>
            </Styles.ChartContainerLegend>
          </Styles.ContentContainer>
        </Styles.Container>
      )}
    </AutoSizer>
  )
}

export const PieChartStatistic = withSkeleton(
  PieChartStatisticComponent,
  PieChartStatisticSkeleton,
)

PieChartStatistic.defaultProps = {
  innerRadius : 0.50,
}

export type PieChartDataField = {
  color: string,
  nameValue: string,
  value: number,
}

export interface PieChartStatisticProps extends BaseProps {
  /** Title of statistic to display */
  title: string,

  chartData: PieChartDataField[],
  attributeStatistics: GetPsyAttributeStatisticsQuery["getPsyAttributeStatistics"][0],

  /** Radius of chart, put a value between 0 and 1 */
  innerRadius?: number,
}
