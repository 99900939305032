import * as React from "react"
import { t } from "ttag"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { CustomerContact, CustomerLedType } from "models/generated"

import { useSession } from "features/Session"
import { LedNumbersCarousel } from "./LedNumbersCarousel"
import { CustomerContactCard } from "./ReferrerCard"
import { LedReports } from "./LedReports"
import { LedNavigationTabs } from "../LedNavigationTabs"

import * as Styles from "./LedKeyFactsPage.styles"
import * as Layout from "../layout.styles"

export const LedKeyFactsPage: React.FC = () => {
  const session = useSession()

  const customerContactsList = React.useMemo(() => {
    const customerContacts = session.customer.customerContacts || []
    return customerContacts.map((customerContact: CustomerContact) =>
      <CustomerContactCard key={customerContact.nanoId} customerContact={customerContact} />,
    )
  }, [ session?.customer.customerContacts ])

  const phoneNumbersOfSoutienPsy = React.useMemo(() => {
    const soutienPsyLed = session.customer.leds?.find(
      led => led.type === CustomerLedType.SoutienPsy,
    )

    return soutienPsyLed?.phoneContact.phones
  },
  [ session?.customer.leds ])

  return (
    <LedNavigationTabs>
      <Layout.Container>
        <Styles.KeyFactsModules>
          <Styles.Referrers>
            <Title level="metricCard">
              {t`My project referrers`}
            </Title>
            {customerContactsList}
          </Styles.Referrers>
          <Layout.Module $span={8}>
            <LedNumbersCarousel phoneNumbers={phoneNumbersOfSoutienPsy}/>
          </Layout.Module>
          <Layout.Module $span={8}>
            <LedReports/>
          </Layout.Module>
        </Styles.KeyFactsModules>
      </Layout.Container>
    </LedNavigationTabs>
  )
}
