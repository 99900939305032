import React from "react"
import { t } from "ttag"

import { APPLICATION_URL, ApplicationPath } from "features/Navigation"
import { PermissionsPage, useUserPermissions } from "features/Permissions"
import { RecipientsPage } from "features/Recipients"
import { UserGroupsPage } from "features/UserGroups"
import { UserPermissionCode } from "models/generated"
import { DashboardPage } from "../Dashboard"
import { ScheduledSurveysPage } from "../Surveys"

import speakupLogo from "assets/images/speakup_logo-primary.svg"
import { LedKeyFactsPage } from "../Led/LedKeyFactsPage"
import { LedDiscussionsPage } from "../Led/LedDiscussionsPage"
import { LedStatisticsPage } from "../Led/LedStatisticsPage"
import { Session, useSession } from "features/Session"

export enum FeatureWithTabs {
  Users = "users",
  SpeakUp = "speakup",
  Led = "led",
}

export interface NavigationTab {
  path: string,
  title: string,
  component: React.ComponentType,
  withMenuItem?: boolean,
  isUnauthorized?: boolean,
  permissionsSet?: UserPermissionCode[],
  icon?: string,
  isIconImage?: boolean,
  // Hide the tab in the component, for example, it allow to use all ScheduledSurveys routes
  hidden?: boolean,
  // Replaces the title with another one when it is not the same as the first tab label
  forceMenuLabel?: string,
}

export const useNavigationTabs = (feature: FeatureWithTabs): NavigationTab[] => {
  const { customer } = useSession()
  const isUserAllowed = useUserPermissions()

  const navigationTabs: Record<FeatureWithTabs, NavigationTab[]> = {
    [FeatureWithTabs.Users]: [
      {
        component: RecipientsPage,
        icon: "people",
        isUnauthorized: !isUserAllowed(UserPermissionCode.RecipientsRead),
        path: ApplicationPath.Recipients,
        title: t`Users`,
        withMenuItem: true,
      },
      {
        component: PermissionsPage,
        isUnauthorized: !isUserAllowed(UserPermissionCode.PermissionsManage),
        path: ApplicationPath.Permissions,
        title: t`Administrators`,
      },
      {
        component: UserGroupsPage,
        isUnauthorized: !isUserAllowed(UserPermissionCode.UserGroupsManage),
        path: ApplicationPath.Groups,
        title: t`Groups`,
      },
    ],
    [FeatureWithTabs.SpeakUp]: [
      {
        component: DashboardPage,
        forceMenuLabel: t`SpeakUP`,
        icon: speakupLogo,
        isIconImage: true,
        path: ApplicationPath.SpeakUpDashboard,
        title: t`Dashboard`,
        withMenuItem: true,
      },
      {
        component: ScheduledSurveysPage,
        path: APPLICATION_URL.speakupScheduledSurveys(),
        title: t`Surveys`,
      },
    ],
    [FeatureWithTabs.Led]: getLedPageForCustomer(customer),
  }

  function getLedPageForCustomer(customer: Session["customer"]): NavigationTab[] {
    const ledPages: NavigationTab[] = []

    if (customer.psyCompany) {
      ledPages.push(
        {
          component: LedStatisticsPage,
          forceMenuLabel: t`LED`,
          icon: "perm_phone_msg",
          path: ApplicationPath.LedStatistics,
          title: t`Statistics`,
          withMenuItem: true,
        },
        {
          component: LedKeyFactsPage,
          path: ApplicationPath.LedDashboard,
          title: t`Dashboard`,
        },
        {
          component: LedDiscussionsPage,
          path: ApplicationPath.LedDiscussions,
          title: t`Discussions`,
        },
      )
    }
    else {
      ledPages.push({
        component: LedKeyFactsPage,
        forceMenuLabel: t`LED`,
        icon: "perm_phone_msg",
        path: ApplicationPath.LedDashboard,
        title: t`Dashboard`,
        withMenuItem: true,
      })
    }

    return ledPages
  }

  return navigationTabs[feature]
}
