import { t } from "ttag"
import React from "react"

import * as Steps from "./Steps"
import { Tutorial } from "../../models/Tutorial"
import { APPLICATION_URL } from "features/Navigation"
import {
  ScheduledSurveyCreateCanceled,
  ScheduledSurveyCreateConfirmed,
  ScheduledSurveyCreateInProgress,
  ScheduledSurveyCreateInitialized,
  ScheduledSurveyCreatePending,
  ScheduledSurveyPageLinkClicked,
} from "features/Tutorial/contents/SurveysPage/Events"

export const scheduledSurveysTutorial = (): Tutorial => ({
  id: "surveys-page",
  parts: [
    [
      {
        // Highlight surveys page link
        content: <Steps.SurveysPageLink/>,
        disableBeacon: true,
        hideNextButton: true,
        id: "surveys-page-link",
        nextActionEvent: ScheduledSurveyPageLinkClicked,
        partIndex: 1,
        partTitle: t`Surveys part presentation`,
        placement: "right",
        spotlightClicks: true,
        spotlightPadding: 0,
        target: `[role=link][href="${APPLICATION_URL.speakupScheduledSurveys()}"]`,
      },
      {
        // Scheduled survey list
        activePage: APPLICATION_URL.speakupScheduledSurveys(),
        callToActionPosition: "end",
        content: <Steps.ScheduledSurveysList/>,
        disableBeacon: true,
        floaterProps: {
          disableFlip: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
        id: "scheduled-survey-list",
        partIndex: 1,
        partTitle: t`Surveys part presentation`,
        placement: "right",
        popupDirection: "vertical",
        spotlightPadding: 0,
        target: "[role=feed]",
      },
      {
        // Scheduled survey create link
        activePage: APPLICATION_URL.speakupScheduledSurveys(),
        content: <Steps.ScheduledSurveyCreateLink/>,
        disableBeacon: true,
        hideNextButton: true,
        id: "scheduled-survey-create-link",
        nextActionEvent: ScheduledSurveyCreateInitialized,
        partIndex: 1,
        partTitle: t`Surveys part presentation`,
        placement: "right",
        spotlightClicks: true,
        spotlightPadding: 10,
        target: `[role=link][href="${APPLICATION_URL.newScheduledSurvey()}"]`,
      },
      {
        // Scheduled survey creation fields presentation
        activePage: APPLICATION_URL.newScheduledSurvey(),
        content: <Steps.ScheduledSurveyCreationFields/>,
        disableBeacon: true,
        id: "scheduled-survey-creation-fields",
        isImperative: true,
        partIndex: 1,
        partTitle: t`Scheduling a survey`,
        placement: "bottom",
        spotlightPadding: 0,
        target: ".ScheduledSurveyForm__header",
      },
      {
        // Scheduled survey creation form
        activePage: APPLICATION_URL.newScheduledSurvey(),
        content: <Steps.EmptyStep/>,
        disableBeacon: true,
        disabledTooltip: true,
        id: "scheduled-survey-create-form",
        nextActionEvent: ScheduledSurveyCreatePending,
        partIndex: 1,
        partTitle: t`Scheduling a survey`,
        placement: "bottom",
        spotlightClicks: true,
        spotlightPadding: 0,
        target: ".ScheduledSurveyForm",
      },
      {
        // Pending confirmation for scheduled survey creation
        content: <Steps.ScheduledSurveyPendingCreate/>,
        disableBeacon: true,
        hideNextButton: true,
        id: "scheduled-survey-pending-create",
        nextActionEvent: ScheduledSurveyCreateInProgress,
        partIndex: 1,
        partTitle: t`Scheduling a survey`,
        placement: "top",
        prevActionEvent: ScheduledSurveyCreateCanceled,
        spotlightClicks: true,
        spotlightPadding: 0,
        stepDependencyWithPrevious: true,
        target: ".ScheduledSurveyForm__body",
      },
      {
        // Transition layer between add and added
        content: <Steps.EmptyStep/>,
        disableBeacon: true,
        disabledTooltip: true,
        id: "scheduled-survey-transition",
        nextActionEvent: ScheduledSurveyCreateConfirmed,
        partIndex: 1,
        partTitle: t`Scheduling a survey`,
        placement: "right",
        spotlightClicks: false,
        spotlightPadding: 0,
        stepDependencyWithNext: true,
        target: ".ScheduledSurveysPage__list",
      },
      {
        // New survey added
        content: <Steps.ScheduledSurveyCreated/>,
        disableBeacon: true,
        id: "scheduled-survey-created",
        partIndex: 1,
        partTitle: t`Scheduling a survey`,
        placement: "right",
        spotlightClicks: false,
        spotlightPadding: 10,
        stepDependencyWithNext: true,
        target: ".ScheduledSurveysPage__list",
      },
      {
        // Hubspot button highlight
        content: <Steps.HubspotHighlight/>,
        disableBeacon: true,
        disableScrolling: true,
        id: "hubspot-highlight",
        isImperative: true,
        partIndex: 1,
        partTitle: t`Scheduling a survey`,
        placement: "top-start",
        popupDirection: "horizontal",
        spotlightClicks: false,
        spotlightPadding: 0,
        target: "#hubspot-messages-iframe-container",
      },
    ],
  ],
})
