import { Card, Title } from "@humanpredictiveintelligence/myqvt-library"
import styled from "styled-components/macro"

export const Container = styled(Card)`
  display: grid;
  padding: 0;
  grid-template-columns: 25% auto;

  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    height: 30rem;
  }
`

export const UsersAndPackagesSection = styled.div`
  display: grid;
  grid-template-rows: 60px auto;
  gap: 1rem;
  border-left: 1px solid ${({ theme }) => theme.colors.borderDark};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    grid-template-rows: 60px 30% auto;
  }
`

export const Tabs = styled.div`
  display: grid;
  z-index: 2;
  box-shadow: ${({ theme }) => theme.shadows.below};
  grid-template-columns: 1fr 1fr;
`

export const Tab = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 1rem;
  gap: 1rem;
  cursor: pointer;

  &:last-child {
    border-left: 1px solid ${({ theme }) => theme.colors.borderDark};
  }
`

export const AddGroupAction = styled.div`
  display: flex;
  padding: 1rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  /** TODO: ajouter une props pour le changement de la couleur du offer management */
  box-shadow: ${({ theme }) => theme.shadows.bottom};
  gap: 1rem;
  cursor: pointer;

  label {
    font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    letter-spacing: 1.3px;
    color: ${({ theme }) => theme.colors.grey};
    text-transform: uppercase;
    cursor: pointer;
  }
`

export const GroupsListSection = styled.div`
  display: grid;
  grid-template-rows: 60px auto;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.below};
  overflow-x: hidden;
`

export const NoGroupSelectedMessage = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  box-shadow: none !important;
  
  p {
    margin: .5em;
  }
  
  img {
    width: 4rem;
    transform: translateX(-50%);
  }
`

export const ListContainer = styled.div`
  margin: 0;
  padding: 0;
  overflow: auto;
`

export const GroupsList = styled.ul`
  margin: 0;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.below};
  padding-left: 2.4rem;
`

export const HeadingTitle = styled(Title)`
  border: none;
  margin: 0;
  padding: 0;
  `

