import * as React from "react"

import { LoginForm } from "../LoginForm"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import * as Styles from "./LoginPage.styles"

export const LoginPage: React.FC = () => {
  return (
    <ContentWithSpeakupLogo>
      <Styles.CardContainer>
        <LoginForm email={process.env.REACT_APP_DEV_LOGIN_USER} password={process.env.REACT_APP_DEV_LOGIN_PASSWORD} />
      </Styles.CardContainer>
    </ContentWithSpeakupLogo>
  )
}

export interface LoginPageRouteState {
  /** Redirect route after login */
  redirectUrlAfterLogin: string,
}
