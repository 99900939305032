/* eslint-disable max-len */
import { Admin, AdminPermissionMap, PermissionsRelationsMap } from "../models"

export interface PermissionsState {
  hasChanges: boolean,
  admins?: Admin[],
  areAdminsLoading?: boolean,
  isAdminLoading?: boolean,
  selectedAdmin?: Admin,
  permissions: AdminPermissionMap,
  permissionsSaveState: AdminPermissionMap,
  permissionsRelations: PermissionsRelationsMap,
  adminsWithEmailPasswordInitSent: Array<Admin["id"]>,
}

export const initialPermissionsState = {
  adminsWithEmailPasswordInitSent: [],
  areAdminsLoading: true,
  hasChanges: false,
  isAdminLoading: false,
  permissions: new Map(),
  permissionsRelations: new Map(),
  permissionsSaveState: new Map(),
}

