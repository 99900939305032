import * as React from "react"
import { t } from "ttag"
import {
  InformationMessage, Input,
  PrimaryButton,
  Table,
  TableRowData,
} from "@humanpredictiveintelligence/myqvt-library"
import moment from "moment"

import { SkinnyUserCharacteristic } from "models/SkinnyUser"
import { UserGroup } from "models/Scheduler"
import * as Styles from "./UserGroupsDialogDetails.styles"

import {
  ScheduledSurveyWithoutStatisticsQuery,
} from "graphql/queries/generated/ScheduledSurveyWithoutStatistics"

export const UserGroupsDialogDetails: React.FC<UserGroupsDetailsProps> = (props) => {
  const [ searchInput, setSearchInput ] = React.useState<string>("")
  const [ filteredUsers, setFilteredUsers ] = React.useState(userRowsData(props.userGroup.users))

  React.useEffect(() => {
    setFilteredUsers(filterUsers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ searchInput ])

  const deletedAt = moment(props.userGroup.deletedAt).format("LL")

  return (
    <Styles.Container>
      <Styles.Dialog
        classes={{ paper: "Container" }}
        open
      >
        <Styles.Heading>
          <Styles.Title>
            {t`List of people in:`} {`${props.userGroup.name} (${props.userGroup.users.length})`}
          </Styles.Title>
          <Input
            name="search"
            placeholder={t`Search`}
            onChange={(e) => setSearchInput(e)}
          />
        </Styles.Heading>

        <Styles.UsersTableContainer>
          <Table
            dataList={filteredUsers}
            columns={[
              SkinnyUserCharacteristic.lastname,
              SkinnyUserCharacteristic.firstname,
              SkinnyUserCharacteristic.email,
            ]}
            columnDisplayNames={new Map([
              [ "lastname", t`Last Name` ],
              [ "firstname", t`First Name` ],
              [ "email", t`Contact` ],
            ])}
            selectedIds={[]}
            actionIconPosition={"start"}
            selectedIdCheckBoxStyles={{}}
          />
        </Styles.UsersTableContainer>

        <Styles.Footer>
          {props.userGroup.deletedAt
            ? <InformationMessage
              variant="warning"
              text={t`This group was deleted on ${deletedAt}`}
            />
            : <InformationMessage text={t`The user list may take some time to update`} />
          }
          <PrimaryButton onClick={() => props.onClose()}>
            {t`Close`}
          </PrimaryButton>
        </Styles.Footer>
      </Styles.Dialog>
    </Styles.Container>
  )

  /**
   * Remaining time of the scheduled survey, relative to the latest end time
   * @param usersList
   */

  function userRowsData(
    usersList: NonNullable<ScheduledSurveyWithoutStatisticsQuery["scheduledSurvey"]>["userGroups"][0]["users"],
  ): TableRowData[] {
    return usersList.map((user) => {
      const userValues = new Map<string, string>()

      userValues.set(SkinnyUserCharacteristic.lastname, user.last_name)
      userValues.set(SkinnyUserCharacteristic.firstname, user.first_name)
      if (user.email) {
        userValues.set(SkinnyUserCharacteristic.email, user.email)
      } else {
        userValues.set(SkinnyUserCharacteristic.phone, user.phone_number ?? "")
      }

      return {
        id: user.id.toString(),
        values: userValues,
      }
    })
  }

  function filterUsers(): TableRowData[] {
    return userRowsData((props.userGroup.users) ?? []).filter((user) => {
      if (user.values.get(SkinnyUserCharacteristic.firstname)?.toLowerCase().includes(searchInput.toLowerCase()) ||
        user.values.get(SkinnyUserCharacteristic.lastname)?.toLowerCase().includes(searchInput.toLowerCase()) ||
        user.values.get(SkinnyUserCharacteristic.email)?.toLowerCase().includes(searchInput.toLowerCase())) {
        return user
      }
      return null
    })
  }
}

interface UserGroupsDetailsProps {
  userGroup: UserGroup,
  onClose: () => void,
}
