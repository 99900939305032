import { Session } from "../Session"
import { TutorialProgression } from "features/Tutorial"

type SessionReducerAction =
  | { type: "SetAuthentication", authentication: Session["authentication"] }
  | { type: "SetCustomer", customer: Session["customer"] }
  | { type: "SetUser", user: NonNullable<Session["user"]> }
  | { type: "SetUsers", users: NonNullable<Session["users"]> }
  | { type: "Unauthenticate" }
  | { type: "SetLanguages", languages: Session["languages"] }
  | { type: "SetTutorialProgression", tutorialProgression: TutorialProgression }

/**
 * Actions dispatchable to the Session Reducer
 */
export const SessionReducerActions = {
  setAuthentication: (authentication: Session["authentication"]): SessionReducerAction => ({
    authentication,
    type: "SetAuthentication",
  }),

  setCustomer: (customer: Session["customer"]): SessionReducerAction => ({
    customer,
    type: "SetCustomer",
  }),

  setLanguages: (languages: Session["languages"]): SessionReducerAction => ({
    languages,
    type: "SetLanguages",
  }),

  setTutorialProgression: (tutorialProgression: TutorialProgression): SessionReducerAction => ({
    tutorialProgression,
    type: "SetTutorialProgression",
  }),

  setUser: (user: NonNullable<Session["user"]>): SessionReducerAction => ({
    type: "SetUser",
    user,
  }),

  setUsers: (users: NonNullable<Session["users"]>): SessionReducerAction => ({
    type: "SetUsers",
    users,
  }),

  unauthenticate: (): SessionReducerAction => ({
    type: "Unauthenticate",
  }),
}
