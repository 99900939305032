import React from "react"
import { useTheme } from "styled-components"

import { BaseProps, Tooltip } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./PermissionsToggle.styles"

export const PermissionsToggle: React.FC<PermissionsToggleProps> = props => {
  const theme = useTheme()

  let scopeIconColor = theme.colors.black
  if (props.isActive && !props.isDisabled) {
    scopeIconColor = theme.colors.white
  }
  else if (props.isDisabled) {
    scopeIconColor = theme.colors.blackLight
  }

  return (
    <Tooltip title={props.tooltipText ?? ""}>
      <span>
        <Styles.Button
          disabled={props.isDisabled}
          $isActive={props.isActive}
          onClick={props.onClick}
        >
          {props.children}
          {props.isScopeSensitive && (
            <Styles.ScopeIcon
              color={scopeIconColor}
              size={20}
              name="location_off"
            />
          )}
        </Styles.Button>
      </span>
    </Tooltip>
  )
}

interface PermissionsToggleProps extends BaseProps {
  /** Whether the toggle is shown active */
  isActive?: boolean,

  /** on toggle click callback */
  onClick: () => void,

  /** Whether the toggle should show the scope icon on disabled */
  isScopeSensitive?: boolean,

  /** If defined, show a tooltip with the given text */
  tooltipText?: string,

  /** Whether the toggle is show disabled */
  isDisabled?: boolean,
}
