/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddUserGroupMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type AddUserGroupMutation = { addUserGroup: { createdAt: string, name: string, uuid: string, users: Array<{ id: number }> } };


export const AddUserGroupDocument = gql`
    mutation AddUserGroup($name: String!) {
  addUserGroup(name: $name) {
    createdAt
    name
    uuid
    users {
      id
    }
  }
}
    `;

/**
 * __useAddUserGroupMutation__
 *
 * To run a mutation, you first call `useAddUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserGroupMutation, { data, loading, error }] = useAddUserGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUserGroupMutation, AddUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserGroupMutation, AddUserGroupMutationVariables>(AddUserGroupDocument, options);
      }
export type AddUserGroupMutationHookResult = ReturnType<typeof useAddUserGroupMutation>;
export type AddUserGroupMutationResult = Apollo.MutationResult<AddUserGroupMutation>;
export type AddUserGroupMutationOptions = Apollo.BaseMutationOptions<AddUserGroupMutation, AddUserGroupMutationVariables>;