import * as React from "react"
import { t } from "ttag"
import UndrawError from "assets/images/undraw_error.svg"

import * as Styles from "./Error.styles"

export const Error: React.FC<ErrorProps> = (props) => (
  <Styles.Wrapper>
    <Styles.Title>
      {t`Oops...`}
    </Styles.Title>
    <Styles.Message>
      {props.message}
    </Styles.Message>
    <Styles.UndrawError src={UndrawError} alt="" />
    <Styles.Action onClick={props.onProceed}>
      {props.proceedLabel}
    </Styles.Action>
  </Styles.Wrapper>
)

interface ErrorProps {
  message: string,
  proceedLabel: string,
  onProceed: () => void,
}
