import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"

import App from "./App"
import Providers from "Providers"
import { OutdatedBrowserDetector } from "features/OutdatedBrowser/outdatedBrowser"
import { MaintenancePage } from "features/Maintenance/MaintenancePage/MaintenancePage"
import { canUserBypassMaintenanceMode, isInMaintenanceMode } from "features/Maintenance/utils"
import { MuiMyQvtThemeProvider, MyQvtThemeProvider } from "@humanpredictiveintelligence/myqvt-library"

import "./speakup_admin.scss"

const url = window.location.href
if (url.match(".*speakup-admin.group-hpi.com.*")) {
  const redirection_url = url.replace("speakup-admin.group-hpi.com", "admin.pimeo.fr")
  window.location.replace(redirection_url)
}

const AppWithRouter = () => (
  <Router>
    <Providers>
      <App/>
    </Providers>
  </Router>
)

const MaintenanceApp = () => (
  <MuiMyQvtThemeProvider>
    <MyQvtThemeProvider>
      <MaintenancePage/>
    </MyQvtThemeProvider>
  </MuiMyQvtThemeProvider>
)

OutdatedBrowserDetector()

ReactDOM.render(
  isInMaintenanceMode() && !canUserBypassMaintenanceMode()
    ? <MaintenanceApp/>
    : <AppWithRouter/>
  ,
  document.getElementById("root"),
)
