import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import * as React from "react"
import { t } from "ttag"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const RecipientExportDialog: React.FunctionComponent<RecipientExportDialogProps> = (props) => {
  return (
    <Dialog open >
      <DialogTitle id="alert-dialog-title">
        {t`Excel export`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t`Your Excel export is being generated. You will receive an email when it is ready.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={props.onClose}>
          {t`Confirm`}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

export interface RecipientExportDialogProps {
  onClose: () => void,
}
