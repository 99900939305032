export enum RangeValidity {
  Valid,
  ValidAtLaterHour,
  Empty,
  StartInPast,
  StartInFuture,
  StartAfterEnd,
  DurationTooShort,
  SameDay,
  EndWithoutStart,
}
