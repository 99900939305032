/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { UserSettingsFragmentFragmentDoc } from '../../../models/generated/UserSettingsFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUserSettingsMutationVariables = Types.Exact<{
  settings: Array<Types.User_Setting> | Types.User_Setting;
}>;


export type SetUserSettingsMutation = { set_settings: { id: number, comment_languages: Array<{ code: string, localizedLabel: string, translatedLabel: string }>, tutorialsProgression?: Array<{ name: string, value: string }> | undefined } };


export const SetUserSettingsDocument = gql`
    mutation SetUserSettings($settings: [User_setting!]!) {
  set_settings(settings: $settings) {
    ...UserSettingsFragment
  }
}
    ${UserSettingsFragmentFragmentDoc}`;

/**
 * __useSetUserSettingsMutation__
 *
 * To run a mutation, you first call `useSetUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserSettingsMutation, { data, loading, error }] = useSetUserSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useSetUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserSettingsMutation, SetUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserSettingsMutation, SetUserSettingsMutationVariables>(SetUserSettingsDocument, options);
      }
export type SetUserSettingsMutationHookResult = ReturnType<typeof useSetUserSettingsMutation>;
export type SetUserSettingsMutationResult = Apollo.MutationResult<SetUserSettingsMutation>;
export type SetUserSettingsMutationOptions = Apollo.BaseMutationOptions<SetUserSettingsMutation, SetUserSettingsMutationVariables>;