import styled from "styled-components/macro"

import { muiMyQvtVariables } from "@humanpredictiveintelligence/myqvt-library"

export const ClusterParagraph = styled.p`
  margin: 0;
`

export const RecipientsStatus = {
  Hint: styled.span`
    font-size: 12px; 
    color: ${props => props.theme.colors.blackLight};
  `,

  Paragraph: styled.p<RecipientsStatusParagraphProps>`
    color: ${props => props.$color};
    margin: 0;
  `,

  Wrapper: styled.div`
    text-align: center;
    margin: 40px;
  `,
}

export interface RecipientsStatusParagraphProps {
  $color: string,
}

export const RecipientsTutorialPopupContent = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const Step1 = {
  List: styled.div`
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    grid-gap: 8px;
  `,
}

export const Step3 = {
  Warning: styled.div`
    color: ${muiMyQvtVariables.palette.text.light};
    display: grid;
    grid-gap: 8px;
    grid-template-columns: min-content 1fr;
    align-items: center;
  `,
}
