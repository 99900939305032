import styled from "styled-components/macro"
import { Dialog as MuiDialog } from "@material-ui/core"
import { Input } from "@humanpredictiveintelligence/myqvt-library"
import { IconButton } from "@humanpredictiveintelligence/myqvt-library"

export const Dialog = styled(MuiDialog)`
  .Container {
    width: 700px;
    height: 100%;
    
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    row-gap: 8px;
    padding: 0;
  }
`

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px 16px 0 16px;
`

export const ClearFiltersButton = styled(IconButton)`
  margin-left: auto;
`

export const Filters = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr min-content;
  align-items: flex-end;
  margin: 8px 0;
  column-gap: 8px;
`

export const NameFilter = styled(Input)`
  width: 180px;
`

export const AttributesFilters = styled.div`
  display: flex;
  
  & > * {
    flex: 1;
    margin: 0 4px;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 16px 24px 16px;

  & > * {
    margin-right: 1rem;
  }
`
