/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { UserPermissionFragmentFragmentDoc } from '../../../models/generated/UserPermissionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUserPermissionsMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  permissions: Array<Types.UserPermissionInput> | Types.UserPermissionInput;
}>;


export type SetUserPermissionsMutation = { set_user_permissions: Array<{ isGranted: boolean, permission: { code: Types.UserPermissionCode, wording: string, isScopable?: boolean | undefined, group: { code: string, wording: string }, parents?: Array<{ code: Types.UserPermissionCode }> | undefined } }> };


export const SetUserPermissionsDocument = gql`
    mutation SetUserPermissions($userId: Int!, $permissions: [UserPermissionInput!]!) {
  set_user_permissions(user_id: $userId, permissions: $permissions) {
    ...UserPermissionFragment
  }
}
    ${UserPermissionFragmentFragmentDoc}`;

/**
 * __useSetUserPermissionsMutation__
 *
 * To run a mutation, you first call `useSetUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPermissionsMutation, { data, loading, error }] = useSetUserPermissionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useSetUserPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPermissionsMutation, SetUserPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPermissionsMutation, SetUserPermissionsMutationVariables>(SetUserPermissionsDocument, options);
      }
export type SetUserPermissionsMutationHookResult = ReturnType<typeof useSetUserPermissionsMutation>;
export type SetUserPermissionsMutationResult = Apollo.MutationResult<SetUserPermissionsMutation>;
export type SetUserPermissionsMutationOptions = Apollo.BaseMutationOptions<SetUserPermissionsMutation, SetUserPermissionsMutationVariables>;