import styled from "styled-components/macro"
import { ScheduledSurveyResultSectionHeader } from "features/Surveys/ScheduledSurveyResults/ScheduledSurveyResultSectionHeader"
import { TabContent } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  margin-bottom: 42px;

  @media print {
    width:100%;
    height:100%;
    page-break-before: always;
  }
`

export const ResultHeader = styled(ScheduledSurveyResultSectionHeader)`
  margin-bottom: 24px;
`

export const ContentSkeletonContainer = styled(TabContent)`
  min-height: 450px;
  // If we don't provide any height, child element cannot use height:100%
  // https://stackoverflow.com/a/21836870/3494812
  height: 1px;
`
