import { Session } from "./Session"
import { customerFromUrl } from "utilities/helpers"

const customerName = customerFromUrl()

export const defaultSession: Session = {
  authentication: {
    hasFailed: false,
    isAuthenticated: false,
    lastFailedAttempt: undefined,
  },
  // If we have a customer in the URL, we use a dummy customer until we have loaded more info
  customer: {
    canSendSMS: false,
    clusterSize: Infinity,
    code_name: "",
    customerContacts: [],
    id: 1,
    isClusterViolated: false,
    language: {
      code: "en_US",
      localizedLabel: "English",
      translatedLabel: "English",
    },
    leds: [],
    name: customerName ?? "",
    packages: [],
    recipientsCount: 0,
    timezone: "Europe/Paris",
  },
  languages: [],
  user: undefined,
  users: undefined,
}
