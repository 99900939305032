import styled, { css } from "styled-components/macro"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div<{ $width: number, $height: number }>`
  ${({ $width, $height }) => css`
    width: ${$width ? $width + "px" : "100%"};
    height: ${$height ? $height + "px" : "100%"};
    display: flex;
    flex-direction: column;
  `}
`

export const ContentContainer = styled.div`
  display: flex;
  gap: 3rem;
  flex: 1;
  align-items: center;
`

export const Chart = styled.div`
  height: 15rem;
  position: relative;
  width: 15rem;
`
export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.fontSizeTitleBig};
  color: ${({ theme }) => theme.colors.black};
`

export const ChartContainerLegend = styled.div`
  flex: 1;
  overflow: auto;
  max-height: 400px;
`

export const ChartLegend = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
  padding: 0;

  list-style-type: none;
  font-size: ${({ theme }) => theme.typography.fontSizeLabelSmall};
  color: ${({ theme }) => theme.colors.blackMedium};
`

export const TotalText = styled.span`
  display: flex;
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
`

export const TotalNumber = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeTitleMedium};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: .3rem;
`

export const Tooltip = styled(Card)`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  `

export const TooltipLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.fontSizeLabelSmall};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  margin: 0;
`

export const TooltipValue = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeTitleSmall};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0 0.5rem 0;
`

