/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
import { SkinnyUserAttributeValueFragmentFragmentDoc } from './SkinnyUserAttributeValueFragment';
export type SkinnyUserFragmentFragment = { id: number, email?: string | undefined, timezone: string, firstname: string, lastname: string, displayName: string, phone?: string | undefined, isAdmin: boolean, language: { code: string, localizedLabel: string, translatedLabel: string }, attributes: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }> };

export const SkinnyUserFragmentFragmentDoc = gql`
    fragment SkinnyUserFragment on User {
  id
  firstname: first_name
  lastname: last_name
  displayName: display_name
  email
  phone: phone_number
  isAdmin: is_admin
  language {
    code
    localizedLabel: localized_label
    translatedLabel: translated_label
  }
  timezone
  attributes {
    ...SkinnyUserAttributeValueFragment
  }
}
    ${SkinnyUserAttributeValueFragmentFragmentDoc}`;