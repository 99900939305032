import { AttributeValueProperties, USER_ATTRIBUTE_VALUE } from "./UserAttributeValue"

export interface AttributeProperties {
  id: number,
  name: string,
  parentAttributeId?: number,
  childrenAttributeIds: number[],
  isMainHierarchyHead?: boolean,
  values: AttributeValueProperties[],
}

export class USER_ATTRIBUTE implements AttributeProperties {
  constructor(properties: AttributeProperties) {
    this.id = properties.id
    this.name = properties.name

    if (properties.values) {
      this.values =
        properties.values.map(values => new USER_ATTRIBUTE_VALUE({ ...values, attributeId: properties.id }))
    }
    else {
      this.values = Array<USER_ATTRIBUTE_VALUE>()
    }

    this.parentAttributeId = properties.parentAttributeId
    this.childrenAttributeIds = properties.childrenAttributeIds
    this.isMainHierarchyHead = properties.isMainHierarchyHead
  }

  id: number
  name: string
  parentAttributeId?: number
  childrenAttributeIds: number[]
  isMainHierarchyHead?: boolean
  values: USER_ATTRIBUTE_VALUE[]

  /**
   * True if the attribute is part of a hierarchy
   */
  get isHierarchical(): boolean {
    return !!(this.parentAttributeId || this.childrenAttributeIds.length > 0 || this.isMainHierarchyHead)
  }

  /**
   * Recursively get a collection of all descendants in the specified collection
   * @param allAttributes Collection of attributes amongst which to search for descendants
   */
  descendants(allAttributes: USER_ATTRIBUTE[]): USER_ATTRIBUTE[] {
    const children = allAttributes.filter(attr => attr.parentAttributeId === this.id)

    return [
      ...children,
      ...children.map(child => child.descendants(allAttributes)).flat(),
    ]
  }

  /**
   * Recursively get a collection of all parents in the specified collection
   * Parents are ordered from the nearest to the furthest
   * @param allAttributes Collection of attributes amongst which to search for parents
   */
  parents(allAttributes: USER_ATTRIBUTE[]): USER_ATTRIBUTE[] {
    const parent = allAttributes.find(attribute => attribute.id === this.parentAttributeId)

    if (parent) {
      return [
        parent,
        ...parent.parents(allAttributes),
      ]
    }

    return []
  }
}
