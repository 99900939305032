/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnyThemeFragmentFragment = { id: number, name: string, color: string, icon: string };

export const SkinnyThemeFragmentFragmentDoc = gql`
    fragment SkinnyThemeFragment on Theme {
  id
  name
  color
  icon
}
    `;