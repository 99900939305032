import styled from "styled-components/macro"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"

export const Heading = styled.div`
  text-align: center;  
`

export const SwiperContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;
  
  .swiper {
    width: 100%;
  }
`

export const Logo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  margin: 0 auto;
  width: fit-content;
  background: #0A8D12;
  border-radius: 100em;
  padding: .3rem 1.2rem;
  font-size: 1rem;
  line-height: 1;
  color: white;
`

export const HotLineIcon = styled(Icon).attrs({
  color: "#0A8D12",
  name: "call",
  size: 20,
})`
  background: white;
  position: relative;
  border-radius: 1em;
  padding: 0.1em;
  
  ${({ theme }) => theme.mixins.reducedLandscape} {
    display: none
  }
`

export const NavigationIcon = styled(Icon).attrs({
  size: 20,
})`
  background: ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.sizes.borderRadiusRounded};
  padding: 0.4em;
  transition: all .2s ease-in-out;
  
  i {
    color: white;
  }
`

export const SwiperNavigation = styled.div`
  width: 100% !important;
  height: 4px !important;
  left: 0 !important;
  top: initial !important;
  bottom: 0 !important;

  .swiper-pagination-progressbar-fill {
    background: ${props => props.theme.colors.primary} !important;
  }
`

export const NavigationButton = styled.button`
  color: white;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  
  &[disabled] {
    cursor: initial;
    ${NavigationIcon} {
      background: ${props => props.theme.colors.blackDisabled};
      i {
        color: ${props => props.theme.colors.blackLight};
      }
    }
  }
`
