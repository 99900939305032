import businessConfig from "../config/business"
import { STATIC_SESSION } from "./StaticSession"
import { DateIntervalInput } from "models/generated"
import moment, { Moment } from "moment"

export function customerFromUrl(): string | undefined {
  const customerInUrlRegex
  // eslint-disable-next-line no-useless-escape
    = new RegExp("(([a-zA-Z0-9-_]*)\.)" + STATIC_SESSION.applicationHostname.replace(".", "\."))

  const regexResult = customerInUrlRegex.exec(window.location.hostname)

  if (regexResult) {
    return regexResult[2]
  }

  else {
    return undefined
  }
}

export function browserLocale(): string {
  return navigator.language.split("-")[0]
}

export function requestFile(endpoint: string, method: string = "GET"): XMLHttpRequest {
  const xhr = new XMLHttpRequest()
  xhr.open(method, `${STATIC_SESSION.backendHost}${endpoint}`)
  xhr.setRequestHeader("x-tenant-name", STATIC_SESSION.customer)
  xhr.setRequestHeader("Authorization", "Bearer " + STATIC_SESSION.authenticationToken)
  xhr.responseType = "blob"
  return xhr
}

/**
 * Get the number of pages for the specified number of items and page size
 * @param itemsTotalCount Total number of items
 * @param itemsPerPage Number of items in a single page
 */
export function numberOfPages(itemsTotalCount: number, itemsPerPage: number): number {
  return Math.ceil(itemsTotalCount / itemsPerPage)
}

/**
 * Split a collection into two collections according to a given predicate
 * @param collection Collection to split
 * @param predicate Predicate to run against each element
 */
export function splitArray<T>(collection: T[], predicate: (element: T) => boolean): {truthy: T[], falsy: T[]} {
  return collection.reduce(({ truthy, falsy }, element) => {
    if (predicate(element)) {
      truthy.push(element)
    }
    else {
      falsy.push(element)
    }

    return {
      falsy,
      truthy,
    }
  }, { falsy: Array<T>(), truthy: Array<T>() })
}

/**
 * Capitalize a given string
 * @param word the word we want to capitalize
 */
export function capitalizedString(word: string) {
  return word.charAt(0).toUpperCase()
      + word.slice(1)
}

/**
 * Get a Map of arrays, grouped by a property value of the objects in the given source array
 * @param source Array to group
 * @param groupByProperty Property to use as group key
 */
export function arrayGroupedBy<T, K extends keyof T>(source: T[], groupByProperty: K): Map<T[K], T[]> {
  return source.reduce((groupedArray, item) => {
    const group = groupedArray.get(item[groupByProperty])

    if (group) {
      group.push(item)
    }
    else {
      groupedArray.set(item[groupByProperty], [ item ])
    }

    return groupedArray
  }, new Map<T[K], T[]>())
}

/**
 * Whether the given string is a valid email
 * @param email Email string to test
 */
export function isEmailValid(email: string): boolean {
  return businessConfig.patterns.email.test(email)
}

/**
 * Whether the given string is a valid password
 * @param password Password string to test
 */
export function isPasswordValid(password: string): boolean {
  return businessConfig.patterns.password.test(password)
}

/**
 * Get the outer height of an element, including padding, margin and border width
 */
export function DOMElementOuterHeight(element: HTMLElement): number {
  const style = getComputedStyle(element)

  const height = element.clientHeight
    + parseInt(style.marginTop, 10)
    + parseInt(style.marginBottom, 10)
    + parseInt(style.paddingTop, 10)
    + parseInt(style.paddingBottom, 10)
    + parseInt(style.borderTopWidth, 10)
    + parseInt(style.borderBottomWidth, 10)

  if (isNaN(height)) {
    throw new Error("DOMElementOuterHeight cannot be calculated")
  }

  return height
}

export function formatPhoneNumber(phoneNumber: string | undefined): string {
  if (phoneNumber === undefined) {
    return ""
  }
  let phoneNumberformatted = phoneNumber[0]
  for (let i = 1; i < phoneNumber.length; i = i + 3) {
    phoneNumberformatted += " " + phoneNumber.slice(i, i + 3)
  }
  return phoneNumberformatted
}

export function formatDateInterval(from?: Moment, to?: Moment): DateIntervalInput | undefined {
  if (!from || !to) {
    return undefined
  }

  return {
    from: from.format("YYYY-MM-DD HH:mm:ss"),
    to: to.format("YYYY-MM-DD HH:mm:ss"),
  }
}

export function weekOfMonth(day: string) {
  const date = moment(day)
  return date.week() - moment(date).startOf("month").week() + 1
}
