import React from "react"

import { UserGroupsReducerAction, userGroupsReducerActions } from "./actions"
import { UserGroupsState, initialUserGroupState } from "./state"
import { userGroupsReducer } from "./reducer"

const UserGroupsContext =
  React.createContext<[ UserGroupsState, React.Dispatch<UserGroupsReducerAction> ] | undefined>(undefined)

export const UserGroupsProvider: React.FC = ({ children }) => (
  <UserGroupsContext.Provider value={React.useReducer(userGroupsReducer, initialUserGroupState)}>
    {children}
  </UserGroupsContext.Provider>
)

export const useUserGroups = () => {
  const context = React.useContext(UserGroupsContext)

  if (context === undefined) {
    throw new Error("useUserGroups must be used within a UserGroupsProvider")
  }

  const [ state, dispatch ] = context

  const actions = userGroupsReducerActions(dispatch)

  return {
    actions,
    state,
  }
}
