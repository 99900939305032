/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveyQuestionsQueryVariables = Types.Exact<{
  surveyId: Types.Scalars['Int'];
}>;


export type SurveyQuestionsQuery = { survey: { id: number, questions: Array<{ id: number, name: string, choices: Array<{ id: number, name: string, satisfaction_value: number }> }> } };


export const SurveyQuestionsDocument = gql`
    query SurveyQuestions($surveyId: Int!) {
  survey(id: $surveyId) {
    id
    questions {
      id
      name
      choices {
        id
        name
        satisfaction_value
      }
    }
  }
}
    `;

/**
 * __useSurveyQuestionsQuery__
 *
 * To run a query within a React component, call `useSurveyQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuestionsQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveyQuestionsQuery(baseOptions: Apollo.QueryHookOptions<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>(SurveyQuestionsDocument, options);
      }
export function useSurveyQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>(SurveyQuestionsDocument, options);
        }
export type SurveyQuestionsQueryHookResult = ReturnType<typeof useSurveyQuestionsQuery>;
export type SurveyQuestionsLazyQueryHookResult = ReturnType<typeof useSurveyQuestionsLazyQuery>;
export type SurveyQuestionsQueryResult = Apollo.QueryResult<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>;