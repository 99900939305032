import { SkinnyQuestionChoice } from "models/SkinnyQuestionChoice"

export enum AnswerSatisfaction {
  Best = "best",
  Worst = "worst",
  Neutral = "neutral",
}

export function getAnswerSatisfaction(choice?: SkinnyQuestionChoice): AnswerSatisfaction | null {
  if (!choice) {
    return null
  }

  if (choice.satisfactionValue === choice.maxSatisfactionValue)  {
    return AnswerSatisfaction.Best
  }
  if (choice.satisfactionValue === 0) {
    return AnswerSatisfaction.Worst
  }

  return AnswerSatisfaction.Neutral
}
