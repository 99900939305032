import styled from "styled-components/macro"
import { RadioGroup } from "@material-ui/core"

export const Form = styled.div`
  display: grid;
  row-gap: 48px;

  margin-top: 48px;
`

export const TypeRadioGroup = styled(RadioGroup)`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`

export const AttributesListing = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
`

export const AttributesListingHeader = styled.div`
  margin-top: 8px;
`

export const AttributesRadioGroup = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`
