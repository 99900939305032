/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnySurveyFragmentFragment = { id: number, name: string };

export const SkinnySurveyFragmentFragmentDoc = gql`
    fragment SkinnySurveyFragment on Survey {
  id
  name
}
    `;