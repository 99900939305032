import styled from "styled-components/macro"

import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const CardWrapper = styled.div`
  width: 100%;
  padding: 10px 50px;
    
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    padding: 24px;
    animation: padding 0.25s;
  }
`

export const Title = styled.h1`
    font-size: ${props => props.theme.typography.fontSizeText};
    font-weight: ${props => props.theme.typography.fontWeightBold};
    color: ${props => props.theme.colors.black};
    margin-bottom: 50px;
`

export const Action = styled(PrimaryButton)`
    margin-top: 24px;
`

export const Instructions = styled.p`
  font-size: ${props => props.theme.typography.fontSizeText};
`

