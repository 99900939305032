/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CheckInMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckInMutation = { checkIn: boolean };


export const CheckInDocument = gql`
    mutation CheckIn {
  checkIn
}
    `;

/**
 * __useCheckInMutation__
 *
 * To run a mutation, you first call `useCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInMutation, { data, loading, error }] = useCheckInMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckInMutation(baseOptions?: Apollo.MutationHookOptions<CheckInMutation, CheckInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInMutation, CheckInMutationVariables>(CheckInDocument, options);
      }
export type CheckInMutationHookResult = ReturnType<typeof useCheckInMutation>;
export type CheckInMutationResult = Apollo.MutationResult<CheckInMutation>;
export type CheckInMutationOptions = Apollo.BaseMutationOptions<CheckInMutation, CheckInMutationVariables>;