import styled, { css } from "styled-components/macro"

import { Dialog, DialogTitle as muiDialogTitle } from "@material-ui/core"
import { Icon, Input, Select, Title } from "@humanpredictiveintelligence/myqvt-library"

export const DialogContainer = styled(Dialog)<{ $isLarge?: boolean, $zIndex: number }>`
  z-index: ${({ $zIndex }) => $zIndex};
  
  &[data-tutorial="true"] {
    pointer-events: none;
  }
  &[data-tutorial="true"] > .DialogContainer__body {
    text-align: unset !important;
  }
  
  .Dialog__body {
    pointer-events: all;
  }
  
  ${({ $isLarge }) => $isLarge && css`
    .Dialog__body {
      min-width: 800px;
    }
    
    ${UpperSection} {
      display: grid;
      grid-column-gap: 72px;
      grid-template-columns: repeat(2, calc(50% - 72px / 2));

      padding: 0 5px;
    }
  `}
`

export const TitleContainer = styled(muiDialogTitle)`
  padding: 0;
  margin: 0;
  border: none;
`

export const DialogTitle = styled(Title)`
  margin: 0;
  margin-top: 16px;
  text-align: center;
`

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const SectionTitle = styled(Title)`
  margin-top: 16px;
  margin-bottom: 12px;
`

export const UpperSection = styled.div``

const FieldStyle = css`
  margin-bottom: 18px;
`

export const InputField = styled(Input)`
  ${FieldStyle};
`

export const SelectField = styled(Select)`
  ${FieldStyle};
`

export const TimeZoneField = styled.div`
  ${FieldStyle};
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(2, calc(50% - 4px));
`

export const Attributes = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(2, calc(50% - 12px));

  padding: 0 5px;
`

