import styled, { css } from "styled-components/macro"

import { UncontextualizedCommentsTableRow } from "features/Comments/UncontextualizedCommentsTableRow"

export const Container = styled.div<{ $hasNoResponse?: boolean }>`
  width: 100%;
  align-self: flex-start;
  padding: 16px 0 48px 0;
  
  ${({ $hasNoResponse }) => $hasNoResponse && css`
    ${Header} {
      grid-template-columns: 2fr minmax(100px, .3fr);
    }
  `}
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: 0 minmax(315px, 1fr) 150px 100px;
  column-gap: 3.5%;
  align-items: center;
  text-align: center;

  padding-left: 36px;
  padding-right: 102px;
  margin-bottom: 16px;
`

export const Row = styled(UncontextualizedCommentsTableRow)`
  &:last-child {
    border-bottom: none;
  }
`

