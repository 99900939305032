import moment from "moment-timezone"
import * as React from "react"
import { msgid, ngettext, t } from "ttag"
import { CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"

import { SurveyScheduler } from "../SurveyScheduling/SurveyScheduler"
import { useSession } from "features/Session"
import { DigitalInfo } from "features/Shared/DigitalInfo"
import { withRouteGuard } from "features/Navigation/withRouteGuard"
import { APPLICATION_URL } from "features/Navigation"
import { useUserPermissions } from "features/Permissions"

import { Scheduled_Survey_Status as ScheduledSurveyStatus, UserPermissionCode } from "models/generated"
import * as Styles from "./ScheduledSurveyDetails.styles"

import { useScheduledSurveyWithStatisticsLazyQuery } from "graphql/queries/generated/ScheduledSurveyWithStatistics"
import { useScheduledSurveyWithoutStatisticsLazyQuery } from "graphql/queries/generated/ScheduledSurveyWithoutStatistics"

export const ScheduledSurveyDetailsComponent: React.FC<ScheduledSurveyDetailsProps> = (props) => {
  const session = useSession()
  const isUserAllowed = useUserPermissions()

  const [
    loadScheduledSurveyWithStatsData,
    { data: scheduledSurveyWithStatsData, loading: isLoadingScheduledSurveyWithStatsData },
  ] = useScheduledSurveyWithStatisticsLazyQuery({
    variables: {
      scheduledSurveyId: props.scheduledSurveyId || 0,
    },
  })

  const [
    loadScheduledSurvey,
    { data: scheduledSurveyData, loading: isLoadingScheduledSurveyData },
  ] = useScheduledSurveyWithoutStatisticsLazyQuery({
    variables: {
      scheduledSurveyId: props.scheduledSurveyId || 0,
    },
  })

  const isOngoingSurvey = scheduledSurveyData?.scheduledSurvey?.status.absolute === ScheduledSurveyStatus.Ongoing
  const remainingTime = scheduledSurveyRemainingTime(scheduledSurveyData?.scheduledSurvey?.endAt?.latest)

  React.useEffect(() => {
    if (props.scheduledSurveyId) {
      loadScheduledSurvey()
    }
  }, [ props.scheduledSurveyId, loadScheduledSurvey ])

  React.useEffect(() => {
    if (isOngoingSurvey) {
      loadScheduledSurveyWithStatsData()
    }
  }, [ isOngoingSurvey, loadScheduledSurveyWithStatsData ])

  if (isLoadingScheduledSurveyData || isLoadingScheduledSurveyWithStatsData) {
    return <CenteredLoader isTransparent/>
  }

  if (props.editingMode === "edit") {
    return (
      <Styles.Container>
        {!props.scheduledSurveyId && <Styles.Heading>{t`Schedule a new survey`}</Styles.Heading>}
        {props.scheduledSurveyId && <Styles.Heading>{t`Scheduled survey`}</Styles.Heading>}
        <SurveyScheduler
          scheduledSurvey={scheduledSurveyData?.scheduledSurvey}
          editingMode={"edit"}
          onChangeEditingMode={props.onChangeEditingMode}
          onScheduledSurveyCreated={props.onScheduledSurveyCreated}
          onScheduledSurveyEdited={props.onScheduledSurveyEdited}
          onDeleteScheduledSurvey={props.onDeleteScheduledSurvey}
        />
      </Styles.Container>
    )
  }
  else {
    // Declare in a variable because ttag doesn't support logical expressions in messages
    const submittedParticipationsCount
      = (scheduledSurveyWithStatsData?.scheduledSurvey?.statistics?.submittedParticipationsCount) || 0
    const recipientsCount = scheduledSurveyWithStatsData?.scheduledSurvey?.statistics.recipientsCount || 0

    return (
      <Styles.Container className="ScheduledSurveyDetails">
        <Styles.Header>
          <Styles.ScheduledSurveyStatus>
            {isOngoingSurvey
              ? t`Ongoing survey`
              : t`Scheduled survey`
            }
          </Styles.ScheduledSurveyStatus>

          {isOngoingSurvey && scheduledSurveyWithStatsData?.scheduledSurvey?.statistics && (
            <Styles.DigitalInfoContainer>
              <DigitalInfo
                value={
                  Math.ceil(scheduledSurveyWithStatsData?.scheduledSurvey?.statistics.participationsPercentage)
                  || 0
                }
                unit="%"
                label={t`Actual participation`}
                icon="reply"
                tooltip={ngettext(
                  // eslint-disable-next-line max-len
                  msgid`${submittedParticipationsCount} participant of the survey out of all of the users (${recipientsCount}).`,
                  // eslint-disable-next-line max-len
                  `${submittedParticipationsCount} participants of the survey out of all of the users (${recipientsCount}).`,
                  submittedParticipationsCount,
                )}
              />
              <DigitalInfo
                value={
                  Math.ceil(scheduledSurveyWithStatsData?.scheduledSurvey?.statistics.adjustedParticipationsPercentage)
                  || 0
                }
                unit="%"
                label={t`Adjusted participation`}
                icon="people"
                /* eslint-disable-next-line max-len */
                tooltip={t`${scheduledSurveyWithStatsData.scheduledSurvey.statistics.submittedParticipationsCount} participants of the survey out of the number of recipients that have accepted the TOS (${scheduledSurveyWithStatsData.scheduledSurvey.statistics.termsAcceptedRecipientsCount})`}

              />
              <DigitalInfo
                value={remainingTime.value}
                unit={remainingTime.unitShort}
                label={t`Remaining time`}
                icon="access_alarm"
              />
            </Styles.DigitalInfoContainer>
          )}
        </Styles.Header>

        <SurveyScheduler
          scheduledSurvey={scheduledSurveyData?.scheduledSurvey}
          editingMode="read"
          onChangeEditingMode={props.onChangeEditingMode}
          onScheduledSurveyEdited={props.onScheduledSurveyEdited}
          onDeleteScheduledSurvey={props.onDeleteScheduledSurvey}
        />

        {(isOngoingSurvey && scheduledSurveyData?.scheduledSurvey)
        && isUserAllowed(UserPermissionCode.CommentsRead)
        && (
          <Styles.UnprocessedCommentsContainer
            scheduledSurveyId={scheduledSurveyData?.scheduledSurvey?.id}
          />
        )}
      </Styles.Container>
    )
  }

  /**
   * Remaining time of the scheduled survey, relative to the latest end time
   * @param endDate End date & time of the scheduled survey
   */
  function scheduledSurveyRemainingTime(endDate: string | undefined): {
    unitLong: string,
    unitShort: string,
    value: number,
  } {
    if (endDate) {
      const userLocalDateTime = moment.tz(session!.user!.timezone)
      const userLocalAbsoluteBeginAt = moment(endDate).tz(session!.user!.timezone)

      const remaining = moment.duration(userLocalAbsoluteBeginAt.diff(userLocalDateTime))

      if (remaining.days() > 0) {
        return {
          unitLong: t`days`,
          unitShort: t`d`,
          value: remaining.days(),
        }
      }
      else if (remaining.hours() > 0) {
        return {
          unitLong: t`hours`,
          unitShort: t`h`,
          value: remaining.hours(),
        }
      }

      return {
        unitLong: t`minutes`,
        unitShort: t`m`,
        value: remaining.minutes(),
      }
    }

    return {
      unitLong: "",
      unitShort: "",
      value: 0,
    }
  }
}

interface ScheduledSurveyDetailsProps {
  /** Scheduled survey to show */
  scheduledSurveyId?: number,

  /** Editing mode of the Scheduled Survey */
  editingMode: "read" | "edit",

  /** Fired when the user switches the editing mode */
  onChangeEditingMode: (mode: "read" | "edit") => void,

  /** Fired when a scheduled survey is created */
  onScheduledSurveyCreated?: (scheduledSurveyId: number) => void,

  /** Fired when a scheduled survey is edited */
  onScheduledSurveyEdited?: () => void,

  /** Fired when a scheduled survey is deletes */
  onDeleteScheduledSurvey?: () => void,
}

export const ScheduledSurveyDetails = withRouteGuard(
  ScheduledSurveyDetailsComponent,
  APPLICATION_URL.speakupScheduledSurveys(),
  (props, isUserAllowed) => {
    // It redirect to /surveys if the user try to access /new without permission
    return props?.editingMode === "edit" && !isUserAllowed(UserPermissionCode.ScheduledSurveyManage)
  },
)
