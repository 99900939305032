/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { ScheduledSurveyInListFragmentFragmentDoc } from '../../../models/generated/ScheduledSurveyInListFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduledSurveysListFromPeriodQueryVariables = Types.Exact<{
  period: Types.DateTime_Period;
}>;


export type ScheduledSurveysListFromPeriodQuery = { scheduledSurveys: Array<{ id: number, isManualPublication: boolean, schedulingId: number, survey: { id: number, name: string, theme: { id: number, name: string, color: string, icon: string } }, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, scheduled_reminders: Array<{ remind_hours_before_end: number, is_editable: boolean }>, userGroups: Array<{ name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined }> }> };


export const ScheduledSurveysListFromPeriodDocument = gql`
    query ScheduledSurveysListFromPeriod($period: DateTime_period!) {
  scheduledSurveys: scheduled_surveys(period: $period, order: ascending) {
    ...ScheduledSurveyInListFragment
  }
}
    ${ScheduledSurveyInListFragmentFragmentDoc}`;

/**
 * __useScheduledSurveysListFromPeriodQuery__
 *
 * To run a query within a React component, call `useScheduledSurveysListFromPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledSurveysListFromPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledSurveysListFromPeriodQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useScheduledSurveysListFromPeriodQuery(baseOptions: Apollo.QueryHookOptions<ScheduledSurveysListFromPeriodQuery, ScheduledSurveysListFromPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledSurveysListFromPeriodQuery, ScheduledSurveysListFromPeriodQueryVariables>(ScheduledSurveysListFromPeriodDocument, options);
      }
export function useScheduledSurveysListFromPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledSurveysListFromPeriodQuery, ScheduledSurveysListFromPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledSurveysListFromPeriodQuery, ScheduledSurveysListFromPeriodQueryVariables>(ScheduledSurveysListFromPeriodDocument, options);
        }
export type ScheduledSurveysListFromPeriodQueryHookResult = ReturnType<typeof useScheduledSurveysListFromPeriodQuery>;
export type ScheduledSurveysListFromPeriodLazyQueryHookResult = ReturnType<typeof useScheduledSurveysListFromPeriodLazyQuery>;
export type ScheduledSurveysListFromPeriodQueryResult = Apollo.QueryResult<ScheduledSurveysListFromPeriodQuery, ScheduledSurveysListFromPeriodQueryVariables>;