import { Dialog } from "@material-ui/core"
import styled from "styled-components/macro"

export const Container = styled(Dialog)`
  .Container {
    width: 30rem;
    height: auto;
    
    display: grid;
    grid-template-rows: min-content 1fr min-content;
  }
`

export const Header = styled.div`
  display: flex;
  margin: 0.4rem 1.6rem 1.4rem 1.6rem;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.fontSizeTitleMedium};
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 1.6rem 0.5rem 1.6rem;

  & > * {
    margin-right: 1rem;
  }
`
