import React from "react"
import { useTheme } from "styled-components"

import * as Styles from "features/RecipientsImport/RecipientImportErrorLog/RecipientImportErrorLog.styles"
import { ProcedureError } from "models/generated"

export const RecipientImportErrorLog = (props: RecipientImportErrorLogProps) => {
  const theme = useTheme()

  return (
    <Styles.AnimatedContainer
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={{
        collapsed: { height: "0px", opacity: 0 },
        open: { height: props.size, opacity: 1 },
      }}
      style={{ overflow: "auto" }}
      transition={{ duration: 1 }}
    >

      <Styles.ErrorsContainer>
        {props.errors.map((error, index) => (
          // TODO: replace key on backend implementation
          <Styles.ErrorMessage key={error.step}>
            <Styles.ErrorIcon
              name="error_outlined"
              size={13}
              color={theme.colors.danger}
            >
              {error.message}
            </Styles.ErrorIcon>
          </Styles.ErrorMessage>
        ))}
      </Styles.ErrorsContainer>

    </Styles.AnimatedContainer>
  )
}

export interface RecipientImportErrorLogProps {
  /** A list of errors to show in the log */
  errors: ProcedureError[],

  size: string,
}

RecipientImportErrorLog.defaultProps = {
  size : "300px",
}
