import moment from "moment"
import * as React from "react"
import { t } from "ttag"

import { ScheduledReminder, ScheduledReminderPreset, ScheduledTimeReminder } from "features/ScheduledReminder/models"
import ActionableSelect from "library/Select/ActionableSelect"
import { SelectOptionProps } from "library/Select"
import { myQvtTheme } from "@humanpredictiveintelligence/myqvt-library"
import { Survey_Scheduling as SurveyScheduling } from "models/generated"

const ScheduledReminderScheduler: React.FC<ScheduledReminderSchedulerProps> = (props) => {
  const isExecuted = props.scheduledReminder?.isExecuted
  const timeReminder = props.scheduledReminder?.timeReminder?.value
  const [ isEditable, setIsEditable ] = React.useState<boolean>(!props.scheduledReminder?.timeReminder)

  /**
   * Return true if the given option is present in the list of disable choices.
   * @param option
   */
  const isOptionDisabled = React.useCallback((option: SelectOptionProps): boolean => {
    return !!(props.disabledChoices?.find((disabledChoice) => option.value === disabledChoice) ?? option.isDisabled)
  }, [ props.disabledChoices ])

  const customTimeReminderValue = React.useCallback(() => {
    if (props.scheduledReminder?.timeReminder?.value
      && (
        props.defaultPreset.timeReminder.value !== props.scheduledReminder?.timeReminder?.value
        && !props.presets?.find(preset => preset.timeReminder.value === props.scheduledReminder?.timeReminder?.value)
      )) {
      return props.scheduledReminder?.timeReminder?.value
    }

    return 0
  }, [ props.defaultPreset.timeReminder.value, props.presets, props.scheduledReminder ])

  const getDefaultOption = (): SelectOptionProps => ({
    group: t`Default`,
    isDisabled: props.isReminderDisabled?.(props.defaultPreset.timeReminder),
    value: props.defaultPreset.timeReminder.value,
    wording: props.defaultPreset.name,
  })

  /**
   * Prepare the preset options list
   */
  const presetOptions: SelectOptionProps[] = props.presets?.map(
    (preset): SelectOptionProps => ({
      group: t`Presets`,
      isDisabled: props.isReminderDisabled?.(preset.timeReminder),
      value: preset.timeReminder.value,
      wording: preset.name,
    }),
  ) || []

  const options: SelectOptionProps[] = flagOptionsAsDisabledIfNeeded([
    getDefaultOption(),
    ...presetOptions,
    {
      group: t`custom`,
      icon: "settings",
      surveyDateRange: {
        disabledHours: props.disabledChoices || [],
        from: moment().isBefore(moment(props.surveyScheduling.begin_at.local))
          ? moment(props.surveyScheduling.begin_at.local)
          : moment(),
        to: moment(props.surveyScheduling.end_at.local),
      },
      value: customTimeReminderValue(),
      wording: t`Select a date`,
    },
  ])

  return (
    <ActionableSelect
      actionButton={{
        icon: "tune",
        label: t`Settings`,
      }}
      actions={[
        {
          icon: "edit",
          iconBackgroundColor: myQvtTheme.colors.secondary,
          label: t`Edit`,
          onClick: onEditAction,
        },
        {
          icon: "delete",
          iconBackgroundColor: myQvtTheme.colors.blackMedium,
          isDisabled: !props.canDeleteReminder,
          label: t`Delete`,
          onClick: onDeleteAction,
        },
      ]}
      isOpen={isEditable}
      isActionable={!!timeReminder && !isExecuted}
      label={props.label}
      value={timeReminder}
      disabled={isExecuted || (!!timeReminder && !isEditable)}
      options={options}
      onChange={onChange}
      placeholder={isExecuted ? t`This reminder was already executed` : t`Select`}
      isFullWidth
    />
  )

  /**
   * Triggered when the delete action button is clicked
   */
  function onDeleteAction() {
    props.onDeleteAction?.()
  }

  /**
   * Triggered when the edit action button is clicked
   * Make the scheduler as editable
   */
  function onEditAction() {
    setIsEditable(true)
    props.onEditAction?.()
  }

  /**
   * Triggered when a value is selected in the dropdown
   * Mark the scheduled as non-editable
   * @param value
   */
  function onChange(value: SelectOptionProps | undefined) {
    setIsEditable(false)
    props.onChange?.(value)
  }

  /**
   * Set the given options as disabled (used in the dropdown)
   * @param options
   */
  function flagOptionsAsDisabledIfNeeded(options: SelectOptionProps[]): SelectOptionProps[] {
    return options.map((option): SelectOptionProps => {
      return {
        ...option,
        isDisabled: option.surveyDateRange ? false : isOptionDisabled(option),
      }
    })
  }
}

ScheduledReminderScheduler.defaultProps = {
  canDeleteReminder: true,
}

export interface ScheduledReminderSchedulerProps {
  /**
   * If false, it will disable the delete action in the dropdown actions
   * @default true
   * */
  canDeleteReminder?: boolean,

  /** Disabled choices (i.e. when some reminders values are already in use other scheduler) */
  disabledChoices?: number[],

  /** Default preset for the scheduler */
  defaultPreset: ScheduledReminderPreset,

  /**
   * Callback to check if the given reminder is disabled
   * @param reminder
   */
  isReminderDisabled?: (reminder: ScheduledTimeReminder) => boolean,

  /** Available schedule presets */
  presets?: ScheduledReminderPreset[],

  /** Scheduled reminder to be manipulated */
  scheduledReminder: ScheduledReminder | undefined,

  /** Callback to be executed when a value in the dropdown is selected */
  onChange?: (value: SelectOptionProps | undefined) => void,

  /** Callback to be executed when the edit action is triggered */
  onEditAction?: () => void,

  /** Callback to be executed when the delete action is triggered */
  onDeleteAction?: () => void,

  /** The survey's schedule */
  surveyScheduling: SurveyScheduling,

  /** Label to display on top of the select */
  label?: string,
}

export default ScheduledReminderScheduler
