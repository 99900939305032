import * as React from "react"
import { t } from "ttag"

import { UsersTable } from "features/UserGroups/UsersGroupsSections"
import { useUserGroups } from "features/UserGroups/UserGroupsContext"
import * as Styles from "./UsersSection.styles"

export const UsersSection: React.FC = () => {
  const { state, actions } = useUserGroups()

  return (
    <Styles.Container>

      <Styles.TitleContainer>
        <Styles.SectionTitle level="section">
          {t`Users list`}
        </Styles.SectionTitle>
      </Styles.TitleContainer>

      <UsersTable
        tableSide="users"
        onSelectUser={(selectedId) => selectUser(Number(selectedId))}
        onSelectAllUsersInPage={(ids, checked) => selectAllUsersInPage(ids.map(Number), checked)}
        selectedIds={state.selectedUsersIds.map(String)}
        areActionsDisabled={state.areUsersSelectedInGroup}
      />

    </Styles.Container>
  )

  function selectUser(selectedId: number) {
    if (selectedId) {
      const selectedUserIdIndex = state.selectedUsersIds.indexOf(selectedId)

      if (selectedUserIdIndex !== -1) {
        const newSelectedIds = [ ...state.selectedUsersIds ]
        newSelectedIds.splice(selectedUserIdIndex, 1)
        actions.setSelectedUsersIds(newSelectedIds)
      }
      else {
        actions.setSelectedUsersIds([ ...state.selectedUsersIds, selectedId ])
      }
    }
  }

  function selectAllUsersInPage(
    ids: number[],
    checked: boolean,
  ) {
    if (!checked || state.selectedUsersIds.length === ids.length) {
      return actions.setSelectedUsersIds([])
    }

    actions.setSelectedUsersIds(ids)
  }
}
