import { useApolloClient } from "@apollo/client"

import { Admin } from "features/Permissions/models"
import { SkinnyUser } from "models/SkinnyUser"

import { MyAssigneesDocument, MyAssigneesQuery } from "graphql/queries/generated/MyAssignees"
import { AdminsDocument, AdminsQuery } from "graphql/queries/generated/Admins"

export enum EmailState {
  Free = "free",
  FromAdmin = "fromAdmin",
  FromRecipient = "fromRecipient",
  FromAdminAndRecipient = "fromAdminAndRecipient"
}

export type EmailStatus =
  { state: EmailState.Free, email: string }
  | { state: EmailState.FromRecipient, recipient: Admin | SkinnyUser }
  | { state: EmailState.FromAdmin, admin: Admin }
  | { state: EmailState.FromAdminAndRecipient }

/**
 * Get a email eligibility verification function
 */
export function useEmailVerificationService(): typeof getEmailStatus {
  const apolloClient = useApolloClient()

  /**
   * Get the email status (from admin, from recipient, from both or free)
   * @param email The email to check
   * */
  async function getEmailStatus(email: string): Promise<EmailStatus> {
    const usersQuery = await apolloClient.query<AdminsQuery>({
      fetchPolicy: "network-only",
      query: AdminsDocument,
      variables: {
        email,
      },
    })
    const assigneesQuery = await apolloClient.query<MyAssigneesQuery>({
      fetchPolicy: "network-only",
      query: MyAssigneesDocument,
      variables: {
        email,
        limit: 1,
      },
    })

    const assignee = assigneesQuery.data.me.users?.list[0]
    const admin = usersQuery.data.admins[0]
    switch (true) {
      case !!admin && !!assignee:
        return  {
          state: EmailState.FromAdminAndRecipient,
        }
      case !!admin:
        return {
          admin,
          state: EmailState.FromAdmin,
        }
      case !!assignee:
        return {
          recipient: assignee,
          state: EmailState.FromRecipient,
        }
      default:
        return {
          email,
          state: EmailState.Free,
        }
    }
  }

  return getEmailStatus
}
