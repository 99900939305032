import styled, { css } from "styled-components/macro"

export const StatisticsModules = styled.div<{ $numberOfChartRows: number }>`
  ${({ $numberOfChartRows }) => css`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 120px repeat(${$numberOfChartRows}, 400px);
    grid-gap: 16px;
  `}
`
