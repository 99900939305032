import * as React from "react"
import business from "config/business"
import { LanguageConfigurator } from "@humanpredictiveintelligence/myqvt-library"
import { browserLocale } from "utilities/helpers"
import { STATIC_SESSION } from "utilities/StaticSession"

import { useSession } from "features/Session"

import localeFr from "i18n/fr.po.json"

export const LanguageProvider: React.FC = (props) => {
  const session = useSession()

  let locale = browserLocale()
  if (session.user) {
    locale = session.user.language.code
    STATIC_SESSION.language = locale
  }
  else if (STATIC_SESSION.language) {
    locale = STATIC_SESSION.language
  }
  else {
    // Standardize locale code to use underscore as separator instead of dash
    locale = locale.replace("-", "_")

    // If there is no territory part in the locale code, use a default one
    if (!locale.includes("_") && business.languages.defaultTerritory.hasOwnProperty(locale)) {
      locale = (business.languages.defaultTerritory as { [key: string]: string })[locale]
      STATIC_SESSION.language = locale
    }
  }

  return (
    <LanguageConfigurator initialLanguage={locale} localeFr={localeFr}>
      {props.children}
    </LanguageConfigurator>
  )
}
