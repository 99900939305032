import { TutorialTarget } from "features/Tutorial/TutorialContext"
import { TutorialState } from "features/Tutorial/TutorialState"

export type TutorialReducerAction =
  | { type: "START", startingState: Pick<TutorialState, "steps" | "partsCount" | "stepIndex"> }
  | { type: "STOP" }
  | { type: "RUN", isRunning?: boolean }
  | { type: "NEXT", stepIndex: number, nextTarget?: TutorialTarget, isRunning?: boolean}
  | { type: "SET_STEP_DATA", stepId: string, data: unknown}
  | { type: "SET_CURRENT_TARGET", target: TutorialTarget}
  | { type: "TOGGLE_CONFIRMATION_DIALOG" }
  | { type: "TOGGLE_SKIP_STEP_CONFIRMATION_DIALOG" }
  | { type: "SET_STEP_TARGET", stepId: string, target: TutorialTarget }

const TUTORIAL_REDUCER_ACTIONS = {
  /**
   * Update the current step index
   * @param stepIndex the value to update from
   * @param nextTarget Target to be use by the next step
   * @param isRunning
   */
  next: (stepIndex: number, nextTarget?: TutorialTarget, isRunning?: boolean): TutorialReducerAction => ({
    isRunning,
    nextTarget,
    stepIndex,
    type: "NEXT",
  }),

  /** Run the tour */
  run: (isRunning?: boolean): TutorialReducerAction => ({
    isRunning,
    type: "RUN",
  }),

  /**
   * Update the current target
   * @param target Target to be use
   */
  setCurrentTarget: (target: TutorialTarget): TutorialReducerAction => ({
    target,
    type: "SET_CURRENT_TARGET",
  }),

  /**
   * Set the data for the given step
   * @param stepId
   * @param data Active step data
   */
  setStepData: (stepId: string, data: unknown): TutorialReducerAction => ({
    data,
    stepId,
    type: "SET_STEP_DATA",
  }),

  /** Set the target for the given step */
  setStepTarget: (stepId: string, target: TutorialTarget): TutorialReducerAction => ({
    stepId,
    target,
    type: "SET_STEP_TARGET",
  }),

  /**
   * Start the tutorial
   * @param startingState initial parameters to start the tutorial with
   */
  start: (
    startingState: Pick<TutorialState, "tutorial" | "steps" | "partsCount" | "stepIndex" | "id">,
  ): TutorialReducerAction => ({
    startingState,
    type: "START",
  }),

  /** Stop the tour */
  stop: (): TutorialReducerAction => ({
    type: "STOP",
  }),

  /** Toggle the quit confirmation dialog */
  toggleQuitConfirmationDialog: (): TutorialReducerAction => ({
    type: "TOGGLE_CONFIRMATION_DIALOG",
  }),

  /** Toggle the skip step confirmation dialog */
  toggleSkipStepConfirmationDialog: (): TutorialReducerAction => ({
    type: "TOGGLE_SKIP_STEP_CONFIRMATION_DIALOG",
  }),
}

export default TUTORIAL_REDUCER_ACTIONS
