import * as React from "react"
import { t } from "ttag"

import AccountCircle from "assets/images/account_circle.svg"
import { AppSelector } from "../AppSelector"
import { useAppsConfig } from "../config"
import * as Styles from "./ApplicationHubRedirection.styles"

import { GetAvailableAppsQuery } from "graphql/queries/generated/GetAvailableApps"

export const ApplicationHubRedirection: React.FC<ApplicationHubRedirectionProps> = ({ apps }) => {
  const appsConfig = useAppsConfig()

  return (
    <Styles.Container>
      <Styles.CardHeader>
        <Styles.AccountLogo src={AccountCircle} />
      </Styles.CardHeader>
      <Styles.CardContent>
        <Styles.Title>{t`APPLICATION CHOICE`}</Styles.Title>

        {apps?.map(app => {
          const { iconSrc, appLabel } = appsConfig[app.name]

          return (
            <AppSelector key={app.name} app={app}>
              <Styles.AppIconContainer>
                <Styles.AppIcon src={iconSrc} alt="" />
              </Styles.AppIconContainer>
              <Styles.AppName>{appLabel}</Styles.AppName>
            </AppSelector>
          )
        })}
      </Styles.CardContent>
    </Styles.Container>
  )
}

interface ApplicationHubRedirectionProps {
  apps: GetAvailableAppsQuery["getAvailableApps"] | undefined,
}
