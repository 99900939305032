import moment from "moment"
import React from "react"
import { t } from "ttag"

import { ScheduledReminder, ScheduledTimeReminderUnit } from "features/ScheduledReminder/models"
import SurveyScheduledReminderScheduler from "features/ScheduledReminder/ScheduledReminderScheduler/SurveyScheduledReminderScheduler"
import { BaseProps, SelectOptionProps } from "@humanpredictiveintelligence/myqvt-library"
import business from "config/business"
import { Survey_Scheduling as SurveyScheduling } from "models/generated"
import * as Styles from "./ScheduledRemindersSection.styles"

import { useGetAssigneesTimeInformationQuery } from "graphql/queries/generated/GetAssigneesTimeInformation"

export const ScheduledRemindersSection: React.FC<ScheduledRemindersSectionProps> = (props) => {
  const [ reminders, setReminders ] = React.useState<ScheduledReminder[]>(props.scheduledReminders || [])

  const { data: assigneesTimeInformationResult } = useGetAssigneesTimeInformationQuery()

  const assigneesInformation = assigneesTimeInformationResult?.me.assigneesTimeInformation

  React.useEffect(() => {
    if (props.scheduledReminders) {
      setReminders(props.scheduledReminders)
    }
  }, [ props.scheduledReminders ])

  /** If no reminders is present, forcefully add a default one */
  React.useEffect(() => {
    if (reminders.length === 0 && props.scheduledReminders?.length === 0) {
      setReminders((oldReminders) => [ ...oldReminders, business.scheduledReminders.default() ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ reminders ])
  
  const disabledChoices = React.useCallback(() => {
    return reminders.reduce((disabledValues, reminder) => {
      if (reminder.timeReminder?.value) {
        disabledValues.push(reminder.timeReminder.value)
      }
      return disabledValues
    }, Array<number>())
  }, [ reminders ])

  return (
    <Styles.Container className={props.className}>
      <Styles.SectionTitle level="section">{t`Schedule reminders`}</Styles.SectionTitle>
      <Styles.Paragraph>
        {
          t`By default a reminder is sent to the concerned collaborators 6 hours before the end of the survey,
          you can edit or add reminders in this section`
        }
      </Styles.Paragraph>
      <Styles.Reminders>
        {reminders.map((reminder, index) => (
          <SurveyScheduledReminderScheduler
            key={index}
            currentTimeInEarliestTimezone={moment().tz(assigneesInformation?.earliest || "utc")}
            surveyScheduling={props.surveyScheduling}
            defaultPreset={business.scheduledReminders.default()}
            presets={business.scheduledReminders.presets()}
            scheduledReminder={reminder}
            canDeleteReminder={reminders.length > 1}
            label={`${t`reminder`} ${index + 1}`}
            onChange={(reminder) => onReminderChange(reminder, index)}
            disabledChoices={disabledChoices()}
            onDeleteAction={() => removeReminder(index)}
          />
        ))}
        {
          reminders.length < 3 && (
            <Styles.AddReminderButton onClick={addNewReminder}>{t`Add a new reminder`}</Styles.AddReminderButton>
          )
        }
      </Styles.Reminders>
    </Styles.Container>
  )

  function addNewReminder() {
    setReminders((oldReminders) => [
      ...oldReminders,
      { isExecuted: false },
    ])
  }

  function onReminderChange(newReminder: SelectOptionProps | undefined, index: number) {
    setReminders((oldReminders) => {
      const updatedReminders = [ ...reminders ]
      const timeReminder = newReminder?.value
        ? { unit: ScheduledTimeReminderUnit.Hours, value: newReminder?.value as number }
        : undefined

      updatedReminders[index] = { isExecuted: false, timeReminder }
      props.onChange?.(updatedReminders)
      return updatedReminders
    })
  }

  function removeReminder(index: number) {
    const updatedReminders = [ ...reminders ]
    updatedReminders.splice(index, 1)
    props.onChange?.(updatedReminders)
    setReminders(updatedReminders)
  }
}

export interface ScheduledRemindersSectionProps extends BaseProps {
  /** The list of reminders to display */
  scheduledReminders?: ScheduledReminder[],

  /** The schedule of the survey */
  surveyScheduling: SurveyScheduling,

  /** Callback on reminders change */
  onChange?: (reminders: ScheduledReminder[]) => void,
}
