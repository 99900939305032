import styled from "styled-components/macro"

export const Container = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 80px;
  margin: 0 auto;

  color: ${({ theme }) => theme.colors.blackMedium};
  text-align: center;
  font-size: ${({ theme }) => theme.typography.fontSizeCaption};
  line-height: 14px;
`

export const Title = styled.span`
  margin-top: 8px;
`
