import styled from "styled-components/macro"
import { Title as LibraryTitle } from "@humanpredictiveintelligence/myqvt-library"
import { Input } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const Header  = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
`

export const Title = styled(LibraryTitle)`
  margin: 0;
  padding: 0;
  border: none;
`

export const SearchField = styled(Input)`
  margin-top: 24px;
  max-width: 100%;
`

export const List = styled.ul`
  flex: 1 1 auto;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

