import { InformationMessage, SearchableSelect } from "@humanpredictiveintelligence/myqvt-library"
import styled, { css } from "styled-components/macro"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
`

export const InnerContainer = styled.div<{ $isReadonly: boolean }>`
  position: relative;
  width: 100%;
  height: fit-content;
  
  border-radius: 8px;
  border: 2px dashed ${({ theme }) => theme.colors.blackDisabled};
  padding: 15px  10px;
  margin: 15px 0;

  ${({ $isReadonly }) => $isReadonly && css`
    pointer-events: none;
  `}
`

export const PermissionsPresetSelect = styled(SearchableSelect)`
  width: 30%;
  
  position: absolute;
  top: 16px;
  right: 16px;
`

export const RemoveUserPermissionsButton = styled(PrimaryButton)`
  margin-right: 42px;
`

export const InformationMessageBox = styled(InformationMessage)`
  width: 100%;
  margin: 15px 0;
`
