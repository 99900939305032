import styled, { css } from "styled-components/macro"
import { Card as LibraryCard } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div``

export const Card = styled(LibraryCard)<{ $isEmpty?: boolean }>`
  min-height: 450px;
  padding: 0;

  ${({ $isEmpty }) => $isEmpty && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export const TableEmptyMessage = styled.span`
  color: ${({ theme }) => theme.colors.blackMedium}
`

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
`
