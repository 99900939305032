import styled from "styled-components/macro"
import helpOutline from "assets/images/help_outline.svg"

export const HelpText = styled.p`
  color: ${props => props.theme.colors.blackLight};
  display: inline-flex;
  align-items: center;
  margin: 0;
  
  &:before {
    content: "";
    padding: 0 3px;
    background: url(${helpOutline}) no-repeat;
    width: 24px;
    height: 24px;
  }
`
