import { Comment, CommentObject } from "../models/Comment"
import { QuestionStatisticsHighlights } from "../models/QuestionStatisticsHighlights"
import { SkinnyScheduledQuestionStatistics } from "../models/SkinnyScheduledQuestionStatistics"
import { AttributeGrade } from "../models/AttributeGrade"

export class SCHEDULED_SURVEY_RESULTS_SERVICE {
  /**
   * Get comments written from recipients among all response comments
   * @param allComments Collection of all response comments to filter
   */
  static commentsFromRecipients<T extends Comment & CommentObject>(allComments: T[]): T[] {
    const filteredComments = allComments.reduce((recipientsComments, comment) => {
      if (!recipientsComments.has(comment.objectId)) {
        recipientsComments.set(comment.objectId, comment)
      }

      return recipientsComments
    }, new Map<number, T>())

    return Array.from(filteredComments.values())
  }

  /**
   * Get comments written from administrators among all response comments
   * @param allComments Collection of all response comments to filter
   */
  static commentsFromAdministrators<T extends Comment & CommentObject>(allComments: T[]): T[] {
    const filteredComments = allComments.reduce((administratorsComments, comment) => {
      // Override the comment of each thread to keep only the last one
      return administratorsComments.set(comment.objectId, comment)
    }, new Map<number, T>())

    return Array.from(filteredComments.values())
  }

  /**
   * Get the percentage of comments with answers among comments
   * @param comments Comments of this question with responses
   */
  static addressedCommentsPercentage<T extends Comment & CommentObject>(comments: T[]): number {
    if (comments.length) {
      /*
       * Get the number of comments related to the same object (discussion thread)
       * Then count only discussion threads where there are at least 2 comments
       * (this is because a thread can be opened only by a recipient and the
       * second comment can only be one of an administrator)
       * This is the number of comments that have been addressed
       */

      const commentsCountByObjectId = new Map<number, number>()
      comments.forEach(comment => {
        commentsCountByObjectId.set(comment.objectId, (commentsCountByObjectId.get(comment.objectId) || 0) + 1)
      })

      const commentsWithResponsesCount = Array.from(commentsCountByObjectId.values()).reduce(
        (count, value) => value > 1 ? count += 1 : count,
        0,
      )

      return commentsWithResponsesCount / commentsCountByObjectId.size * 100
    }

    return 0
  }

  /**
   * Sort a collection of answers based on grades per attribute
   * @param questionStatistics Collection of answers to sort
   */
  static questionStatisticsWithSortedAttributeGrades(
    questionStatistics: SkinnyScheduledQuestionStatistics,
  ): SkinnyScheduledQuestionStatistics {
    if (!questionStatistics.attributeGrades || questionStatistics.attributeGrades.length === 0) {
      return questionStatistics
    }

    return {
      ...questionStatistics,
      attributeGrades: questionStatistics.attributeGrades.sort((attributeA, attributeB) => {
        if (attributeA.grade && attributeB.grade) {
          return attributeA.grade > attributeB.grade ? 1 : -1
        }

        return !attributeA.grade ? -1 : 1
      }),
    }
  }

  /**
   * Get the highlights of a question's statistics
   * @param questionStatistics Question statistics from which to get the highlights
   */
  static questionStatisticsHighlights(
    questionStatistics: SkinnyScheduledQuestionStatistics,
  ): QuestionStatisticsHighlights | undefined {
    if (!questionStatistics.attributeGrades || questionStatistics.attributeGrades.length === 0) {
      return undefined
    }

    const highlights: QuestionStatisticsHighlights = {
      hierarchy: questionStatistics.attributeGrades[0].attribute.name,
    }

    const attributeGrades = questionStatistics.attributeGrades
      .filter(attributeGrade => attributeGrade.grade !== null || attributeGrade.isAllSkipped)
    // The API sorts the grades from highest to lowest
    const highestAttributeGrade = attributeGrades.length ? attributeGrades[0] : undefined

    if (highestAttributeGrade) {
      highlights.highestGrade = {
        grade: highestAttributeGrade.grade !== undefined ? highestAttributeGrade.grade : undefined,
        hierarchy: highestAttributeGrade.attribute.value,
        isAllSkipped: highestAttributeGrade.isAllSkipped,
      }
    }

    const lowestAttributeElement = this.lowestAttributeGrade(attributeGrades)
    if (lowestAttributeElement) {
      highlights.lowestGrade = {
        grade: lowestAttributeElement.grade !== undefined ? lowestAttributeElement.grade : undefined,
        hierarchy: lowestAttributeElement.attribute.value,
        isAllSkipped: lowestAttributeElement.isAllSkipped,
      }
    }
    else {
      highlights.lowestGrade = highlights.highestGrade
    }

    return highlights
  }

  /**
   * Return the lowest attribute grade in attribute grade values.
   *
   * The following priority rules apply:
   *
   *  1. The lowest number (including 0) value
   *  2. the '-' value if no lowest number is found
   *  3. nothing if no allSkippedValue is found
   *
   * @param attributeGrades The list of attributeGrades whose we want the get the lowest attribute grade from
   */
  static lowestAttributeGrade(attributeGrades: AttributeGrade[]): AttributeGrade | undefined {
    if (attributeGrades.length > 0) {
      if (attributeGrades[attributeGrades.length - 1].isClusterViolated) {
        attributeGrades.pop()
        return this.lowestAttributeGrade(attributeGrades)
      }
      else if (attributeGrades[attributeGrades.length - 1].isAllSkipped) {
        if (attributeGrades.length === 2) {
          return attributeGrades[attributeGrades.length - 1]
        }
        else {
          attributeGrades.pop()
          return this.lowestAttributeGrade(attributeGrades)
        }
      }
      else {
        return attributeGrades[attributeGrades.length - 1]
      }
    }
    else {
      return undefined
    }
  }

  /**
   * Sort the attributes grades by values
   * @param attributeGrades Collection of attributes to sort
   */
  static sortedAttributesGrades(
    attributeGrades: AttributeGrade[] | undefined,
  ): AttributeGrade[] {
    return [ ...(attributeGrades || []) ].sort((a, b) => {
      if (b.isClusterViolated) {
        return -1
      }
      else if (a.isClusterViolated) {
        return 1
      }
      else if (b.isAllSkipped) {
        return -1
      }
      else if (a.isAllSkipped) {
        return 1
      }
      else {
        // We assume that grade is defined because backend will provide defined values if above conditions are false
        return b.grade! - a.grade!
      }
    }) || []
  }
}
