import styled from "styled-components/macro"
import { Table } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: column;
`

export const TablePagination = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2rem;
`

export const DiscussionsTable = styled(Table)`
  flex: 1;
`

