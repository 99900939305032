import { motion } from "framer-motion"
import styled, { css } from "styled-components/macro"
import { Card, ThemeIcon as LibraryThemeIcon } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div<{ $isCompact?: boolean, $isMini?: boolean }>`
  display: flex;
  align-items: center;
  
  ${({ theme, $isCompact, $isMini }) => css `
    ${$isCompact && css `
      ${Title} {
        font-size: ${theme.typography.fontSizeButton};
        max-height: 36px;
        line-height: 16px;
      }
      
      ${ActivePeriod} {
        font-size: 11px;
        line-height: 11px;
        white-space: pre;
      }
      
      ${ThemeContainer} {
        margin: 0 12px 0 0;
        align-self: initial;
      }
      
      ${ThemeIcon} {
        height: 32px;
        width: 32px;
      }
    `}
    
    ${$isMini && css`
      flex-direction: column;
      height: 100%;
      justify-content: space-evenly;
      
      ${ThemeContainer} {
        margin: 0;
        align-self: unset;
      }
      
      .StatusContainer {
        background: #FFF;
        padding: 5px;
        border-radius: 50%;
        height: auto;
        box-shadow: ${theme.shadows.below};
      }
      
      ${ThemeIcon} {
        height: 32px;
        width: 32px;
      }
      
      ${ActivePeriod} {
        font-size: 10px;
        color: ${theme.colors.blackMedium};
        line-height: 10px;
        white-space: pre;
      }
    `}
  `}
`

export const ThemeContainer = styled.div`
  margin-right: 34px;
  align-self: flex-start;
`

export const ThemeName = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  text-transform: uppercase;

  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: 0.73px;
`

export const ThemeIcon = styled(LibraryThemeIcon)`
  height: 40px;
  width: 40px;
`

export const Content = styled(motion.div)<{
  $isCompact?: boolean,
  $withUserGroupsSection?: boolean,
  $isScrollbarVisible?: boolean, }
>`
  ${({ $isCompact, $withUserGroupsSection, $isScrollbarVisible }) => $isCompact && css `
    padding-left: 2px;
    display: grid;
    grid-template-columns: 150px ${$isScrollbarVisible ? "80px" : "100px"} ${$withUserGroupsSection && "150px"};
    align-items: center;
  `}
  
  overflow: hidden;
`

export const Title = styled.div`
  margin-bottom: 8px;
  width: 100%;
  font-size: ${({ theme }) => theme.typography.fontSizeTitleSmall};
  color: ${({ theme }) => theme.colors.black};

  max-height: 48px;
  line-height: 24px;
  ${({ theme }) => theme.mixins.webkitClampText(2)}
`
export const ActivePeriod = styled.div`
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: 12px;
`

export const ScheduledSurveyGroupsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  border-radius: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 0 8px 8px;
  height: 80px;
`

export const ScheduledSurveyGroupsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 140px
`

export const ScheduledSurveyGroupItem = styled.div<ScheduledSurveyGroupItemProps>`
  color: ${({ theme, isDeleted }) => isDeleted ? theme.colors.danger : theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};
`

interface ScheduledSurveyGroupItemProps {
  isDeleted?: boolean,
}
