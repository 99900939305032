import React from "react"
import {
  Skeletonable,
  Tooltip,
} from "@humanpredictiveintelligence/myqvt-library"

import * as Styles from "./PieChartLegend.styles"

export const PieChartLegend: React.FC<Skeletonable<PieChartLegendProps>> = (props) => {
  const percentage = Math.round(props.chart.value / props.totalValue * 100)

  return (
    <Styles.LegendContainer key={props.chart.value}>
      <Styles.LegendBlock $backgroundColor={props.chart.color}/>
      <Styles.Content>
        <Styles.ChartText>
          <Styles.ChartValue>{props.chart.value}</Styles.ChartValue>
          <Styles.SymbolePercent>(~{percentage}%)</Styles.SymbolePercent>
        </Styles.ChartText>
        <Tooltip title={props.chart.nameValue}>
          <Styles.ChartText>{props.chart.nameValue}</Styles.ChartText>
        </Tooltip>
      </Styles.Content>
    </Styles.LegendContainer>
  )
}

export interface PieChartLegendProps {
  /** Data statistic to display */
  chart: {color: string, nameValue: string, value: number},

  /** Sum of recipients to display */
  totalValue: number,
}
