/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetUserScopesMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int'];
  scopes: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type SetUserScopesMutation = { set_user_scopes: Array<{ id: number }> };


export const SetUserScopesDocument = gql`
    mutation SetUserScopes($userId: Int!, $scopes: [Int!]!) {
  set_user_scopes(user_id: $userId, scopes: $scopes) {
    id
  }
}
    `;

/**
 * __useSetUserScopesMutation__
 *
 * To run a mutation, you first call `useSetUserScopesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserScopesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserScopesMutation, { data, loading, error }] = useSetUserScopesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      scopes: // value for 'scopes'
 *   },
 * });
 */
export function useSetUserScopesMutation(baseOptions?: Apollo.MutationHookOptions<SetUserScopesMutation, SetUserScopesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserScopesMutation, SetUserScopesMutationVariables>(SetUserScopesDocument, options);
      }
export type SetUserScopesMutationHookResult = ReturnType<typeof useSetUserScopesMutation>;
export type SetUserScopesMutationResult = Apollo.MutationResult<SetUserScopesMutation>;
export type SetUserScopesMutationOptions = Apollo.BaseMutationOptions<SetUserScopesMutation, SetUserScopesMutationVariables>;