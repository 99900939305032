import moment from "moment"
import { t } from "ttag"
import React from "react"

import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { Ghost } from "@humanpredictiveintelligence/myqvt-library"
import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { Tooltip } from "@humanpredictiveintelligence/myqvt-library"
import { ThemeProgress } from "models/ThemeProgress"
import { ThemeProgressionListItem } from "./ThemeProgressionListItem"
import { SkeletonWrapper, Skeletonable, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ThemeProgressionsModule.styles"

const ThemeProgressionsModuleComponent: React.FC<ThemeProgressionsModuleProps> = (props) => (
  <section
    className={props.className}
    aria-labelledby="ThemeProgressionsModules__header"
  >
    <MetricsCard title={
      <MetricsCardHeader firstSurveySentAt={props.firstSurveySentAt} surveysSentCount={props.surveysSentCount}/>
    }>
      <Styles.Table
        role="table"
        aria-labelledby="ThemeProgressionsModules__header"
      >
        <Ghost role="row">
          <div role="columnheader">{t`Themes`}</div>
          <div role="columnheader">{t`Progression`}</div>
          <div role="columnheader">{t`Current rating`}</div>
        </Ghost>
        {props.themeProgressions.map((themeProgression) =>
          <ThemeProgressionListItem key={themeProgression.theme.id} {...themeProgression} />,
        )}
      </Styles.Table>
    </MetricsCard>
  </section>
)

const ThemeProgressionsModuleSkeleton: React.FC<ThemeProgressionsModuleSkeletonProps> = (props) => (
  <section
    className={props.className}
    style={props.style}
    aria-labelledby="ThemeProgressionsModules__header"
  >
    <MetricsCard title={<MetricsCardHeader isLoading/>}>
      <Styles.Table
        role="table"
        aria-labelledby="ThemeProgressionsModules__header"
      >
        <Ghost role="row">
          <div role="columnheader">{t`Themes`}</div>
          <div role="columnheader">{t`Progression`}</div>
          <div role="columnheader">{t`Current rating`}</div>
        </Ghost>
        <SkeletonWrapper
          isLoading={props.isLoading}
          component={ThemeProgressionListSkeleton}
          componentProps={{ skeletonDisplayedCount: 14 }}
        />
      </Styles.Table>
    </MetricsCard>
  </section>
)

const MetricsCardHeader: React.FC<MetricsCardHeaderProps> = (props) => {
  const firstSurveySentAt = props.firstSurveySentAt && moment(props.firstSurveySentAt).format("LL").toString()

  return (
    <Styles.CardHeader role="heading">
      <span>{t`Progression`}</span>
      <Tooltip
        title={
          firstSurveySentAt
            ? t`The first survey was sent on` + " " + firstSurveySentAt
            : ""
        }
      >
        <Styles.SurveySentCount>
          <SkeletonWrapper isLoading={props.isLoading}>{props.surveysSentCount}</SkeletonWrapper>
        </Styles.SurveySentCount>
      </Tooltip>
      <Styles.SurveySentCaption>
        <SkeletonWrapper isLoading={props.isLoading}>{t`surveys\n sent`}</SkeletonWrapper>
      </Styles.SurveySentCaption>
    </Styles.CardHeader>
  )
}
const ThemeProgressionListSkeleton = (props: ThemeProgressionListSkeletonProps) => (
  <>
    {Array(props.skeletonDisplayedCount).fill({}).map((comment, index) => {
      return (
        <ThemeProgressionListItem isLoading={true} key={index}/>
      )
    })}
  </>
)

const ThemeProgressionsModule = withSkeleton(ThemeProgressionsModuleComponent, ThemeProgressionsModuleSkeleton)

type MetricsCardHeaderProps =
  Skeletonable<Partial<Pick<ThemeProgressionsModuleProps, "firstSurveySentAt" | "surveysSentCount">>>;

type ThemeProgressionsModuleSkeletonProps = Skeletonable<Pick<ThemeProgressionsModuleProps, "style" | "className">>;
type ThemeProgressionListSkeletonProps = { skeletonDisplayedCount: number };

export interface ThemeProgressionsModuleProps extends BaseProps {
  /**  The date on which the first survey of the customer was sent */
  firstSurveySentAt: string | undefined,

  /** The total number of surveys sent */
  surveysSentCount: number,

  /** List of theme progressions to display */
  themeProgressions: ThemeProgress[],
}

export {
  ThemeProgressionsModule,
}
