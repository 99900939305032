import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { useTheme } from "styled-components"
import { t } from "ttag"

import { BaseProps, Icon } from "@humanpredictiveintelligence/myqvt-library"
import { defaultTransition } from "features/RecipientsImport/RecipientsImportPanel/RecipientsImportPanel.styles"
import { ImportStatusIcon } from "features/RecipientsImport/ImportStatusIcon/ImportStatusIcon"
import { useStatusIconsTheme } from "features/RecipientsImport/useStatusIconsTheme"
import * as Styles from "features/RecipientsImport/RecipientImportStep/RecipientsImportStep.styles"

import { ProcedureError } from "models/generated"

export enum ImportStepStatus {
  pending,
  ongoing,
  failed,
  done,
}

export const RecipientsImportStep: React.FC<RecipientsImportStepProps> = (props) => {
  const statusIconsTheme = useStatusIconsTheme()
  const theme = useTheme()

  return (
    <Styles.Wrapper
      className={props.className}
      $isActive={props.isActive}
    >

      <AnimatePresence>
        {props.isErrorLogOpen && (
          <Styles.AnimatedBackButton
            onClick={props.onBackButtonClick}
            key="backButton"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              collapsed: { opacity: 0, x: 100 },
              open: { opacity: 1, x: 16 },
            }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <Icon size={13} name="arrow_back" color={theme.colors.blackMedium}>
              {t` Back`}
            </Icon>
          </Styles.AnimatedBackButton>
        )}
      </AnimatePresence>

      <Styles.Header>
        {!props.isErrorLogOpen && (
          <ImportStatusIcon status={props.status}/>
        )}

        <div>

          <motion.div
            key="title"
            animate={props.isErrorLogOpen ? "open" : "collapsed"}
            variants={{
              collapsed: { x: 0 },
              open: { x: 16 },
            }}
          >
            <Styles.StepTitle level="section">{props.title}</Styles.StepTitle>
          </motion.div>

          {!props.isErrorLogOpen && (
            <motion.div
              key="subtitle"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                collapsed: { opacity: 0, x: -100 },
                open: { opacity: 1, x: 0 },
              }}
              transition={defaultTransition}
            >
              <Styles.SubTitle $color={statusIconsTheme[props.status].color}>{props.subtitle}</Styles.SubTitle>
            </motion.div>
          )}
          {props.isActive && props.errors && props.errors.length !== 0 && !props.isErrorLogOpen && (
            <motion.div
              key="showDetails"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                collapsed: { opacity: 0, x: -100 },
                open: { opacity: 1, x: 0 },
              }}
              transition={defaultTransition}
            >
              <Styles.ShowDetailsButton
                $color={statusIconsTheme[props.status].color}
                onClick={props.onShowDetailsClick}
              >
                {t`Show details`}
              </Styles.ShowDetailsButton>
            </motion.div>
          )}

        </div>

      </Styles.Header>
      {props.children}
    </Styles.Wrapper>
  )
}

export interface RecipientsImportStepProps extends BaseProps {
  /** The status of the step which give the step a different icon and color theme */
  status: ImportStepStatus,

  /** Title of the step */
  title: string,

  /** Subtitle of the step */
  subtitle?: string,

  /** A list of errors to show in the log */
  errors?: ProcedureError[],

  /** Return a callback whenever the show details button is clicked */
  onShowDetailsClick?: () => void,

  /** Return a callback whenever the back button is clicked */
  onBackButtonClick?: () => void,

  /**  Whether the error log should be open */
  isErrorLogOpen?: boolean,

  /** Whether the step should be brighten or not */
  isActive?: boolean,

}
