/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyScheduledSurveyFragmentFragmentDoc } from '../../../models/generated/SkinnyScheduledSurveyFragment';
import { SkinnyThemeFragmentFragmentDoc } from '../../../models/generated/SkinnyThemeFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerGlobalStatisticsQueryVariables = Types.Exact<{
  isUserAllowedToReadComments: Types.Scalars['Boolean'];
  isUserAllowedToReadResults: Types.Scalars['Boolean'];
  isUserAllowedToReadRecipients: Types.Scalars['Boolean'];
}>;


export type CustomerGlobalStatisticsQuery = { resultsStatistics: { id: string, overallRealParticipationPercentage: number, latestRealParticipationPercentage?: number | undefined, latestRealParticipationDelta?: number | undefined, overallAdjustedParticipationPercentage: number, latestAdjustedParticipationPercentage?: number | undefined, latestAdjustedParticipationDelta?: number | undefined, surveysWithGlobalCommentsCount: number, surveysWithoutGlobalCommentsCount: number, scheduledSurveysCount: number, firstScheduledSurvey?: { id: number, isManualPublication: boolean, schedulingId: number, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, scheduled_reminders: Array<{ remind_hours_before_end: number, is_editable: boolean }>, userGroups: Array<{ name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined }> } | undefined, themeProgressions: Array<{ grade?: number | undefined, surveysCount: number, usedSurveysCount: number, scheduledSurveysCount: number, theme: { id: number, name: string, color: string, icon: string } }> }, commentsStatistics: { id: string, overallCommentsCount: number, overallCommentsCountDelta: number, averageCommentsCountPerQuestion: number, averageCommentsCountPerQuestionDelta?: number | undefined, responsesToCommentsRatio: number, responsesToCommentsRatioDelta: number, overallResponsesCount: number }, recipientsStatistics: { id: string, acceptedTermsCount?: number | undefined, refusedTermsCount?: number | undefined, recipientsUpdatedAt?: string | undefined, recipientsCount: number } };

export type ResultsStatisticsFragment = { id: string, overallRealParticipationPercentage: number, latestRealParticipationPercentage?: number | undefined, latestRealParticipationDelta?: number | undefined, overallAdjustedParticipationPercentage: number, latestAdjustedParticipationPercentage?: number | undefined, latestAdjustedParticipationDelta?: number | undefined, surveysWithGlobalCommentsCount: number, surveysWithoutGlobalCommentsCount: number, scheduledSurveysCount: number, firstScheduledSurvey?: { id: number, isManualPublication: boolean, schedulingId: number, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, scheduled_reminders: Array<{ remind_hours_before_end: number, is_editable: boolean }>, userGroups: Array<{ name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined }> } | undefined, themeProgressions: Array<{ grade?: number | undefined, surveysCount: number, usedSurveysCount: number, scheduledSurveysCount: number, theme: { id: number, name: string, color: string, icon: string } }> };

export type RecipientsStatisticsFragment = { id: string, acceptedTermsCount?: number | undefined, refusedTermsCount?: number | undefined, recipientsUpdatedAt?: string | undefined, recipientsCount: number };

export type CommentsStatisticsFragment = { id: string, overallCommentsCount: number, overallCommentsCountDelta: number, averageCommentsCountPerQuestion: number, averageCommentsCountPerQuestionDelta?: number | undefined, responsesToCommentsRatio: number, responsesToCommentsRatioDelta: number, overallResponsesCount: number };

export const ResultsStatisticsFragmentDoc = gql`
    fragment ResultsStatistics on Statistics_global {
  id
  overallRealParticipationPercentage: overall_real_participation_percentage
  latestRealParticipationPercentage: latest_scheduled_survey_real_participation_percentage
  latestRealParticipationDelta: real_percentage_latest_delta
  overallAdjustedParticipationPercentage: overall_adjusted_participation_percentage
  latestAdjustedParticipationPercentage: latest_scheduled_survey_adjusted_participation_percentage
  latestAdjustedParticipationDelta: adjusted_participation_percentage_latest_delta
  surveysWithGlobalCommentsCount: number_of_scheduled_surveys_with_global_comments
  surveysWithoutGlobalCommentsCount: number_of_scheduled_surveys_without_global_comments
  scheduledSurveysCount: number_of_scheduled_surveys
  firstScheduledSurvey: first_scheduled_survey {
    ...SkinnyScheduledSurveyFragment
  }
  themeProgressions: themes_progress {
    theme {
      ...SkinnyThemeFragment
    }
    surveysCount: number_of_surveys
    usedSurveysCount: number_of_used_surveys
    scheduledSurveysCount: number_of_scheduled_surveys
    grade
  }
}
    ${SkinnyScheduledSurveyFragmentFragmentDoc}
${SkinnyThemeFragmentFragmentDoc}`;
export const RecipientsStatisticsFragmentDoc = gql`
    fragment RecipientsStatistics on Statistics_global {
  id
  acceptedTermsCount: number_of_recipients_who_accepted_tos
  refusedTermsCount: number_of_recipients_who_refused_tos
  recipientsUpdatedAt: recipients_updated_at
  recipientsCount: number_of_recipients
}
    `;
export const CommentsStatisticsFragmentDoc = gql`
    fragment CommentsStatistics on Statistics_global {
  id
  overallCommentsCount: number_of_recipient_comments
  overallCommentsCountDelta: number_of_recipient_comments_delta
  averageCommentsCountPerQuestion: average_recipient_comments_per_question
  averageCommentsCountPerQuestionDelta: average_recipient_comments_per_question_delta
  responsesToCommentsRatio: percentage_treated_recipient_comment
  responsesToCommentsRatioDelta: treated_recipient_comment_delta
  overallResponsesCount: number_of_treated_recipient_comment
}
    `;
export const CustomerGlobalStatisticsDocument = gql`
    query CustomerGlobalStatistics($isUserAllowedToReadComments: Boolean!, $isUserAllowedToReadResults: Boolean!, $isUserAllowedToReadRecipients: Boolean!) {
  resultsStatistics: statistics_global @include(if: $isUserAllowedToReadResults) {
    ...ResultsStatistics
  }
  commentsStatistics: statistics_global @include(if: $isUserAllowedToReadComments) {
    ...CommentsStatistics @include(if: $isUserAllowedToReadComments)
  }
  recipientsStatistics: statistics_global @include(if: $isUserAllowedToReadRecipients) {
    ...RecipientsStatistics @include(if: $isUserAllowedToReadRecipients)
  }
}
    ${ResultsStatisticsFragmentDoc}
${CommentsStatisticsFragmentDoc}
${RecipientsStatisticsFragmentDoc}`;

/**
 * __useCustomerGlobalStatisticsQuery__
 *
 * To run a query within a React component, call `useCustomerGlobalStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerGlobalStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerGlobalStatisticsQuery({
 *   variables: {
 *      isUserAllowedToReadComments: // value for 'isUserAllowedToReadComments'
 *      isUserAllowedToReadResults: // value for 'isUserAllowedToReadResults'
 *      isUserAllowedToReadRecipients: // value for 'isUserAllowedToReadRecipients'
 *   },
 * });
 */
export function useCustomerGlobalStatisticsQuery(baseOptions: Apollo.QueryHookOptions<CustomerGlobalStatisticsQuery, CustomerGlobalStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerGlobalStatisticsQuery, CustomerGlobalStatisticsQueryVariables>(CustomerGlobalStatisticsDocument, options);
      }
export function useCustomerGlobalStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerGlobalStatisticsQuery, CustomerGlobalStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerGlobalStatisticsQuery, CustomerGlobalStatisticsQueryVariables>(CustomerGlobalStatisticsDocument, options);
        }
export type CustomerGlobalStatisticsQueryHookResult = ReturnType<typeof useCustomerGlobalStatisticsQuery>;
export type CustomerGlobalStatisticsLazyQueryHookResult = ReturnType<typeof useCustomerGlobalStatisticsLazyQuery>;
export type CustomerGlobalStatisticsQueryResult = Apollo.QueryResult<CustomerGlobalStatisticsQuery, CustomerGlobalStatisticsQueryVariables>;