import React from "react"
import { isUserAllowedFunction, useUserPermissions } from "features/Permissions"
import { Redirect } from "react-router"

export function withRouteGuard<Props>(
  Component: React.ComponentType<Props>,
  redirectionUrl: string,
  shouldRedirect: (props: Props, isUserAllowed: isUserAllowedFunction) => boolean,
): React.ComponentType<Props> {
  return (props) => {
    const isUserAllowed = useUserPermissions()

    return (
      shouldRedirect(props, isUserAllowed)
        ? <Redirect to={redirectionUrl}/>
        : <Component {...props as Props} />
    )
  }
}
