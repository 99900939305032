import * as React from "react"
import {
  PagedNavigation,
  Select,
  TableRowData,
  Title,
} from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"

import * as Styles from "./LedDiscussionsTable.styles"

import business from "config/business"
import { numberOfPages } from "utilities/helpers"

export const LedDiscussionsTable: React.FC<LedDiscussionsTableProps> = (props) => {
  const [ selectedConversationNanoId, setSelectedConversationNanoId ] = React.useState<string | null>(null)

  React.useEffect(() => {
    if (selectedConversationNanoId != null) {
      props.onRowSelected?.(selectedConversationNanoId)
    }
  }, [ props, props.onRowSelected, selectedConversationNanoId ])

  const discussionsColumnDisplayNames = new Map([
    [ "date", t`Date` ],
    [ "issue", t`Issue` ],
  ])

  return (
    <Styles.Container>
      <Title level="metricCard">
        {t`Discussions`}
      </Title>
      <Styles.DiscussionsTable
        isLoading={props.isLoading}
        withShadow
        skeletonCountRow={props.pagination.pageSize}
        skeletonCountColumn={2}
        skeletonSize="small"
        dataList={props.discussionsList}
        rowIdsToHighlight={selectedConversationNanoId ? [ selectedConversationNanoId ] : []}
        highlightRowColor={"#e0ebff"}
        onRowClicked={setSelectedConversationNanoId}
        columns={[
          "date",
          "issue",
        ]}
        columnDisplayNames={discussionsColumnDisplayNames}
        areActionsDisabled
        selectedIds={selectedConversationNanoId ? [ selectedConversationNanoId ] : []}
      />
      <Styles.TablePagination>
        <Select
          options={
            business.led.discussions.tablePageSizes.map(size => ({
              value: size,
              wording: size.toString(),
            }))
          }
          onChange={(option) => props.onPageSizeChange(option?.value as number)}
          value={props.pagination.pageSize}
          defaultItem={false}
          disabled={props.isLoading}
        />
        <PagedNavigation
          isLoadingData={props.isLoading}
          page={props.pagination.currentPage}
          numberOfPages={numberOfPages(
            props.totalDiscussionsCount,
            props.pagination.pageSize,
          )}
          onNavigate={(page, numberOfPages) => props.onNavigate(page, numberOfPages)}
        />
      </Styles.TablePagination>
    </Styles.Container>
  )
}

interface TablePagination {
  currentPage: number,
  pageSize: number,
  loadingOffset: number,
}

export interface LedDiscussionsTableProps {
  isLoading: boolean,
  discussionsList: TableRowData[],
  totalDiscussionsCount: number,
  onRowSelected: (id: string) => void,
  pagination: TablePagination,
  onNavigate: (page: number, numberOfPages: number) => void,
  onPageSizeChange: (size: number) => void,
}
