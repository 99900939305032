import styled, { css } from "styled-components/macro"
import { IconButton, PrimaryButton, SearchableSelect } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  position: relative;
  padding: 32px 32px 0 32px;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.main};
  
  @media print {
    display: none;
  }
`

export const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TopSectionFilters = styled.div`
  flex-shrink: 1;
`

export const AttributeSelect = styled(SearchableSelect)`
  width: 225px;
  margin-bottom: 32px;

  &:not(:last-child) {
    margin-right: 32px;
  }
`

export const FiltersActions = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: calc(${({ theme }) => theme.typography.fontSizeLabelSmaller} + 8px);
`

export const FiltersAction = styled(IconButton)`
  &:not(:last-of-type) {
    margin-right: 16px;
  }
`

export const BottomSectionToggle = styled(PrimaryButton)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`

export const BottomSection = styled.div<{ $isHidden: boolean }>`
  ${({ $isHidden }) => $isHidden && css`
    display: none;
  `}
`
