import styled, { css } from "styled-components/macro"
import { DialogActions } from "@material-ui/core"
import { Card, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { TutorialPopupProps } from "features/Tutorial/TutorialPopup/TutorialPopup"

export const CardContainer = styled(Card)<ActionsProps>`
  display: grid;
  grid-gap: 16px;
  padding: 16px;
  
  ${({ $direction, $callToActionPosition }) => css`
    ${$direction === "horizontal" && css`
      grid-auto-flow: column;
      
      ${CallToAction} {
        align-self: ${$callToActionPosition};
      }
    `}
    
    ${$direction === "vertical" && css`
      ${CallToAction} {
        justify-self: ${$callToActionPosition};
      }
    `}
  `}
`

interface ActionsProps {
  $direction: TutorialPopupProps["popupDirection"],
  $callToActionPosition: TutorialPopupProps["callToActionPosition"],
}

export const Content = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
`

export const Actions = styled(DialogActions)<{ $callToActionPosition: string }>`
  && {
    margin: 0;
    justify-content: ${(props) => props.$callToActionPosition === "center"
    ? "space-between" 
    : props.$callToActionPosition }
  }
`

export const CallToAction = styled(PrimaryButton)``

export const SkipButton = styled(CallToAction)`
  margin: 0;
  font-size: ${props => props.theme.typography.fontSizeTextSmall};
  color: #CCC;
  cursor: pointer;
`
