import {
  ApolloCache, ApolloClient, ApolloLink, ApolloProvider, InMemoryCache,
  NextLink, NormalizedCacheObject, Operation,
} from "@apollo/client"
import { Observable } from "@apollo/client/utilities"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
import * as React from "react"
import { t } from "ttag"
import { Subscription } from "zen-observable-ts"

import { ErrorDialog } from "features/Error"
import { ApplicationPath } from "features/Navigation"
import { STATIC_SESSION } from "utilities/StaticSession"

// Create a custom Apollo request link to add custom header and token
export const requestLink = (): ApolloLink => {
  return new ApolloLink((operation: Operation, forward: NextLink) => new Observable(observer => {
    let handle: Subscription

    Promise.resolve(operation)
      .then(async op => {
        const { isUserVisitor } = operation.getContext()

        const token = STATIC_SESSION.authenticationToken
        const authorizationHeader: { [header: string]: string } = {}

        if (token && !operation.getContext().shouldUseAuthSchema) {
          authorizationHeader.Authorization = `Bearer ${token}`
        }

        if (!isUserVisitor) {
          authorizationHeader["x-tenant-name"] = STATIC_SESSION.customer
        }

        op.setContext({
          headers: {
            ...authorizationHeader,
            "accept": "application/json",
          },
          uri: `${STATIC_SESSION.backendGraphql}`,
        })
      })
      .then(() => {
        handle = forward(operation).subscribe({
          complete: observer.complete.bind(observer),
          error: observer.error.bind(observer),
          next: observer.next.bind(observer),
        })
      })
      .catch(observer.error.bind(observer))

    return () => {
      if (handle) {
        handle.unsubscribe()
      }
    }
  }))
}

const createBackendProviderLink = (setErrorDialogVisible: (isVisible: boolean) => void): ApolloLink => {
  return ApolloLink.from([
    onError(errorResponse => {
      if (errorResponse.graphQLErrors) {
        errorResponse.graphQLErrors.forEach(graphqlError => {
          // eslint-disable-next-line: no-console
          console.error("[GraphQL error]", graphqlError)

          if (
            graphqlError.message === "Requester model is not allowed to perform this action"
            || graphqlError.message === "Unauthorized action"
          ) {
            STATIC_SESSION.authenticationToken = ""
            if (!errorResponse.operation.getContext().shouldNotShowErrorIfUnauthorized) {
              setErrorDialogVisible(true)
            }
          }
        })
      }
    }),
    requestLink(),
    createUploadLink({
      credentials: "include",
    }),
  ])
}

const createBackendCache = (): ApolloCache<NormalizedCacheObject> => {
  return new InMemoryCache()
}

/**
 * Provide a fully configured client for making GraphQL requests
 * @param props
 */
const BackendProvider: React.FunctionComponent = (props) => {
  const [ isErrorDialogVisible, setErrorDialogVisible ] = React.useState(false)
  const [ apolloClient, setApolloClient ] = React.useState<ApolloClient<NormalizedCacheObject> | undefined>()

  // We use an effect to avoid instantiating the client on each render
  React.useEffect(() => {
    // We build our ApolloClient links chain to process GQL operations
    // https://www.apollographql.com/docs/react/advanced/boost-migration/
    setApolloClient(new ApolloClient({
      cache: createBackendCache(),
      link: createBackendProviderLink(setErrorDialogVisible),
    }))
    // eslint-disable-next-line
  }, [])

  if (!apolloClient) {
    return null
  }
  else {
    return (
      <ApolloProvider client={apolloClient}>
        {isErrorDialogVisible && (
          <ErrorDialog
            title={t`Disconnected`}
            // eslint-disable-next-line max-len
            message={t`You were disconnected. Please log back in to continue using the application. You will now be redirected to the login screen.`}
            confirmText={t`Go to login screen`}
            onConfirm={gotoLogin}
          />
        )}
        {props.children}
      </ApolloProvider>
    )
  }

  /**
   * Go to the login screen.
   *
   * Remove token from the session before navigating.
   */
  function gotoLogin() {
    STATIC_SESSION.authenticationToken = ""
    setErrorDialogVisible(false)
    window.location.assign(ApplicationPath.Login)
  }
}

export {
  BackendProvider,
  createBackendCache,
  createBackendProviderLink,
}
