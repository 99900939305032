import { ApolloError } from "@apollo/client/errors"
import React from "react"
import { t } from "ttag"

import { EmailState, EmailStatus, useEmailVerificationService } from "features/Permissions/useEmailVerification"
import { NotificationContext } from "features/Notification"
import { PrimaryButton, useForm } from "@humanpredictiveintelligence/myqvt-library"
import business from "config/business"
import * as Styles from "./EmailVerificationDialog.styles"

export const EmailVerificationDialog: React.FC<NewAdminDialogProps> = props => {
  const notification = React.useContext(NotificationContext)
  const getEmailStatus = useEmailVerificationService()

  type FormFields = { email: string }
  const form = useForm<FormFields>({ mode: "onChange" })

  const [ isAdminExists, setIsAdminExists ] = React.useState(false)

  return (
    <Styles.DialogBody
      open={props.isOpen}
      classes={{
        paperScrollBody: "Dialog__body",
        scrollBody: "DialogContainer__body",
      }}
      scroll="body"
    >
      <Styles.DialogTitle level="metricCard">{t`Add an administrator`}</Styles.DialogTitle>
      <Styles.Form onSubmit={form.handleSubmit(onEmailSubmit)}>
        <Styles.Content>
          <Styles.EmailInput
            label={t`email`}
            name="email"
            innerRef={form.register({
              pattern: {
                message: t`The email format is invalid`,
                value: business.patterns.email,
              },
              required: true,
            })}
            hint={form.errors.email?.message}
            isHintErroneous
            isDisabled={form.status === "processing"}
            isErroneous={form.errors.email !== undefined}
          />

          {isAdminExists && (
            <Styles.EmailUsedWarning>
              {t`This email address is already used by another administrator profile`}
            </Styles.EmailUsedWarning>
          )}
        </Styles.Content>
        <Styles.Actions>
          <PrimaryButton
            disabled={form.status === "processing"}
            onClick={props.onBack}
            isInverted
          >
            {t`Back`}
          </PrimaryButton>
          <PrimaryButton
            submit
            disabled={!form.isValid || form.status === "processing"}
          >
            {t`Next`}
          </PrimaryButton>
        </Styles.Actions>
      </Styles.Form>
    </Styles.DialogBody>
  )

  /**
   *  on email from admin, show an error message
   *  on email from user or free, call onVerificationDone prop
   * */
  async function onEmailSubmit({ email }: FormFields) {
    form.setStatus("processing")
    try {
      const emailStatus = await getEmailStatus(email)

      if (emailStatus.state === EmailState.FromAdmin) {
        setIsAdminExists(true)
      }
      else {
        props.onVerificationDone(emailStatus)
      }
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }

    form.reset({
      email: "",
    })
    form.setStatus("submitted")
  }
}

interface NewAdminDialogProps {
  isOpen: boolean,
  onVerificationDone: (emailStatus: EmailStatus) => void,
  onBack: () => void,
}
