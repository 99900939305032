import * as React from "react"

import { ScheduledSurveyResultSectionSkeleton } from "./ScheduledSurveyResultSectionSkeleton"
import { BaseProps, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ScheduledSurveyResultSection.styles"

const ScheduledSurveyResultSectionComponent: React.FC<ScheduledSurveyResultSectionProps> = (props) => (
  <Styles.Container className={props.className} aria-label={props.title}>
    <Styles.ResultHeader
      title={props.title}
      hasError={props.hasError}
      icon={props.icon}
      sectionNumber={props.sectionNumber}
      progress={props.progress && parseFloat(props.progress.value.toFixed(2))}
      progressLabel={props.progress && props.progress.label}
      onRetry={props.onRetry}
    />
    {props.children}
  </Styles.Container>
)

export const ScheduledSurveyResultSection = withSkeleton(
  ScheduledSurveyResultSectionComponent,
  ScheduledSurveyResultSectionSkeleton,
)

interface ScheduledSurveyResultSectionProps extends BaseProps {
  /** Title of the section */
  title: string,

  /** Icon of the section */
  icon?: string,

  /** Section number */
  sectionNumber?: number,

  /** Progress to show */
  progress?: {
    label: string,
    value: number,
  },

  hasError?: boolean,

  onRetry?: () => void,
}
