import styled, { css } from "styled-components/macro"
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core"

import { AnswerStatusIcon } from "features/Comments/AnswerStatusIcon"
import { SkeletonWrapper } from "@humanpredictiveintelligence/myqvt-library"

export const AccordionContainer = styled(Accordion)<AccordionContainerProps>`
  ${({ theme, expanded, $isHoverReactive, $hasCallToAction, $hasNoResponse }) => css`
    padding: 16px 24px;
    border-bottom: 1px solid ${theme.colors.blackDisabled};
    border-radius: ${theme.sizes.borderRadius};
    background: transparent;
    transition: background-color 250ms;

    ${expanded && css`
      z-index: 10;
      margin: 0;
      box-shadow: ${theme.shadows.selected};
    `}

    ${$isHoverReactive && css`
      &:hover {
        background-color: ${theme.colors.primaryLight};
      }
    `}

    ${$hasCallToAction && css`
      ${Summary} {
        grid-template-columns: 1fr 120px 100px 40px;
        margin-right: 0;
      }
    `}
    
    ${$hasNoResponse && css`
      ${Summary} {
        grid-template-columns: 2fr minmax(100px, .25fr);
      }
    `}
  `}
`

interface AccordionContainerProps {
  $hasNoResponse?: boolean,
  $hasCallToAction?: boolean,
  $isHoverReactive?: boolean,
}

export const SummaryContainer = styled(AccordionSummary)`
  padding: 0;

  .summary__content {
    margin: 0;
  }
`

export const Summary = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(450px, 1fr) 120px minmax(15px, 100px);
  column-gap: 2%;
  align-items: center;
  margin-right: 45px;
`

export const Details = styled(AccordionDetails)`
  display: block;
  padding: 0;
`

export const StatusIcon = styled(AnswerStatusIcon)``

export const ItemSkeleton = styled(SkeletonWrapper)`
  margin: 0 auto;
  display: block !important;
`
