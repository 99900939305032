/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
import { SkinnyUserAttributeValueFragmentFragmentDoc } from './SkinnyUserAttributeValueFragment';
import { SkinnyUserFragmentFragmentDoc } from './SkinnyUserFragment';
export type SkinnyCommentFragmentFragment = { id: number, comment: string, isAnonymous: boolean, objectId: number, objectType: Types.CommentObjectType, validatedAt?: string | undefined, publishedAt?: string | undefined, commentTranslations: Array<{ wording: string, language: { code: string, localizedLabel: string, translatedLabel: string } }>, attribute?: { name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> } | undefined, parentComment?: { id: number } | undefined, creator?: { id: number, email?: string | undefined, timezone: string, firstname: string, lastname: string, displayName: string, phone?: string | undefined, isAdmin: boolean, language: { code: string, localizedLabel: string, translatedLabel: string }, attributes: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }> } | undefined };

export const SkinnyCommentFragmentFragmentDoc = gql`
    fragment SkinnyCommentFragment on Comment {
  id
  comment
  commentTranslations: comment_translations {
    wording
    language {
      code
      localizedLabel: localized_label
      translatedLabel: translated_label
    }
  }
  attribute {
    ...SkinnyUserAttributeValueFragment
  }
  isAnonymous: is_anonymous
  objectId: object_id
  objectType: object_type
  validatedAt: validated_at
  publishedAt: published_at
  parentComment: parent_comment {
    id
  }
  creator {
    ...SkinnyUserFragment
  }
}
    ${SkinnyUserAttributeValueFragmentFragmentDoc}
${SkinnyUserFragmentFragmentDoc}`;