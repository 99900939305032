/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserFragmentFragmentDoc } from '../../../models/generated/SkinnyUserFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddUserMutationVariables = Types.Exact<{
  firstname: Types.Scalars['String'];
  lastname: Types.Scalars['String'];
  attributesIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
  language: Types.Scalars['String'];
  timezone: Types.Scalars['String'];
  email?: Types.InputMaybe<Types.Scalars['String']>;
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']>;
  permissions?: Types.InputMaybe<Array<Types.UserPermissionInput> | Types.UserPermissionInput>;
}>;


export type AddUserMutation = { add_user: { id: number, email?: string | undefined, timezone: string, firstname: string, lastname: string, displayName: string, phone?: string | undefined, isAdmin: boolean, language: { code: string, localizedLabel: string, translatedLabel: string }, attributes: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }> } };


export const AddUserDocument = gql`
    mutation AddUser($firstname: String!, $lastname: String!, $attributesIds: [Int!], $language: String!, $timezone: String!, $email: String, $phoneNumber: String, $permissions: [UserPermissionInput!]) {
  add_user(firstName: $firstname, lastName: $lastname, email: $email, attributeIds: $attributesIds, language: $language, timezone: $timezone, phoneNumber: $phoneNumber, permissions: $permissions) {
    ...SkinnyUserFragment
  }
}
    ${SkinnyUserFragmentFragmentDoc}`;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      attributesIds: // value for 'attributesIds'
 *      language: // value for 'language'
 *      timezone: // value for 'timezone'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, options);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;