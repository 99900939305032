import styled from "styled-components/macro"

export const CardWrapper = styled.div`
  padding: 10px 50px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    padding: 24px;
    animation: padding 0.25s;
  }
`

