import classNames from "classnames"
import * as React from "react"
import { t } from "ttag"

import { ErroredMetricsCard } from "@humanpredictiveintelligence/myqvt-library"

export const ErroredParticipationMetrics: React.FC<ErroredParticipationMetricsProps> = (props) => {
  let title = ""
  let hint = ""

  switch (props.participationType) {
    case "adjusted":
      title = t`Adjusted participation`
      hint = t`Number of participants / number of participants who accepted the TOS`
      break
    case "real":
      title = t`Real participation`
      hint = t`Participants of the surveys out of the number of surveys sent`
      break
  }

  return (
    <div className={classNames("ErroredParticipationMetrics", props.className)}>
      <ErroredMetricsCard
        hint={hint}
        hasReducedPadding
        title={title}
      >
        {t`An error has occurred.`}
      </ErroredMetricsCard>
    </div>
  )
}

export interface ErroredParticipationMetricsProps {
  /** Extra CSS classes */
  className?: string,

  /** Type of participation shown */
  participationType: "real" | "adjusted",
}
