import moment from "moment"
import { t } from "ttag"

import { AnswerStatus } from "models/AnswerStatus"
import { Comment } from "models/Comment"

export class COMMENT_SERVICE {
  /**
   * Get the label of an comment editor. This label adapts to the reply status and author.
   */
  static answerInputLabel(answer: Comment | undefined, currentUserId: number | undefined): string {
    const isCreatedByUser = (answer?.creator && currentUserId && answer.creator.id === currentUserId) || false
    const responseAuthorName = answer?.creator?.displayName
    let answerInputLabel = t`Write your answer`

    if (answer?.id !== undefined && answer?.creator) {
      const validationDate = moment.parseZone(answer.validatedAt).format("L")

      if (answer.publishedAt) {
        answerInputLabel = isCreatedByUser
          ? t`Your answer, sent on ${validationDate}`
          : t`Answer from ${responseAuthorName}, sent on ${validationDate}`
      } else if (!isCreatedByUser) {
        answerInputLabel = t`Draft from ${responseAuthorName}`
      } else if (isCreatedByUser) {
        answerInputLabel = t`Your draft`
      }
    }

    return answerInputLabel
  }

  /**
   * Get the status of a comment answer
   * @param answer Answer for which to get the status
   */
  static status(answer?: Pick<Comment, "id" | "publishedAt" | "validatedAt">): AnswerStatus {
    if (answer?.publishedAt) {
      return AnswerStatus.Done
    }

    if (answer?.validatedAt) {
      return AnswerStatus.AwaitingPublishing
    }

    if (answer?.id !== undefined) {
      return AnswerStatus.Drafted
    }

    return AnswerStatus.Pending
  }
}
