import styled from "styled-components/macro"
import { DropdownButton } from "@humanpredictiveintelligence/myqvt-library"
import { UserGroupsHeadersDetails } from "features/UserGroups/UserGroupsDetails/UserGroupsHeadersDetails"

export const Container = styled.div`
  min-height: 140px;

  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-auto-columns: 1fr;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.main};

  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  padding: 32px 32px 16px 32px;
  
  @media print {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 24px;

    box-shadow: none;
  }
`

export const SkeletonContainer = styled(Container)`
  display: block;
  min-height: 264px;
  // If we don't provide any height, child element cannot use height:100%
  // https://stackoverflow.com/a/21836870/3494812
  height: 1px;
  margin-bottom: 40px;
`

export const Statistics = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  
  @media print {
    text-align: right;
    align-self: center;
  }
`

export const ActionsDropdown = styled(DropdownButton)`
  @media print {
    display: none;
  }
`

export const UserGroupsList = styled(UserGroupsHeadersDetails)`
  // Calculated from the width of the theme icon area
  margin: 8px 74px;
  grid-area: 2 / span 3;
  justify-self: flex-start;
`

