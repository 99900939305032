import styled, { css } from "styled-components/macro"
import { Link as ReactLink } from "react-router-dom"
import { IconButton, InformationMessage, Select, Title } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  padding: 32px 64px;
  overflow: auto;
`

export const HeaderTitle = styled(Title)`
  margin: 0;
`

export const LogoutButton = styled(IconButton)`
  margin-left: 24px;
`
export const Section = styled.div`
  margin-bottom: 64px;

  p {
    font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  }
`
export const SectionTitle = styled(Title)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.blackLight};
  width: 250px;
  padding-bottom: 8px;
  margin-bottom: 8px;
`
export const UserName = styled(Title)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  margin-top: 32px;
`

export const Email = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  
  .EmailIcon {
    margin-right: 8px;
  }
`

export const Link = styled(ReactLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryDark};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
`

export const EmailLink = styled.a`
  ${Link}
`

export const Information = styled(InformationMessage)`
  width: 100%;
  margin-top: 8px;
`

export const Label = styled.label`
${({ theme }) => css`
  display: block;
  margin-bottom: 8px;

  color: ${theme.colors.blackMedium};
  font-size: ${theme.typography.fontSizeLabelSmaller};
  font-weight: ${theme.typography.fontWeightMedium};

  text-transform: uppercase;
  letter-spacing: ${theme.typography.letterSpacingLabelSmall};
`}
`

export const CommentLanguageLabel = styled(Label)`
  margin-top: 32px;
`

export const LanguagesSelect = styled(Select)`
  margin-right: 32px;
`

export const PatchnotesLink = styled.p`
  color: ${({ theme }) => theme.colors.primaryDark};
  text-decoration: underline;
  cursor: pointer;
`
