import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import React from "react"
import { t } from "ttag"
import { $enum } from "ts-enum-util"

import { useSession } from "features/Session"
import { useUserPermissions } from "features/Permissions"
import { Label, PrimaryButton, Select, Tooltip } from "@humanpredictiveintelligence/myqvt-library"
import { ExcelExportType } from "models/ExcelExportType"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"
import { arrayGroupedBy } from "utilities/helpers"
import * as Styles from "./ExcelExportDialog.styles"
import { UserPermissionCode } from "models/generated"

export const ExcelExportDialog: React.FC<ExcelExportDialogProps> = (props) => {
  const session = useSession()

  const isUserAllowed = useUserPermissions()
  const isUserAllowedToReadComments = isUserAllowed(UserPermissionCode.CommentsRead)

  const [ formState, setFormState ] = React.useState<Partial<ExcelExportDialogForm>>({
    exportType: undefined,
    language: session.user!.language.code,
    pivot: undefined,
  })

  const [ hierarchicalAttributes, simpleAttributes ] =
    (props.attributes || []).reduce(([ hierarchicalAttributes, simpleAttributes ], attribute) => {
      if (attribute.isHierarchical) {
        hierarchicalAttributes.push(attribute)
      }
      else {
        simpleAttributes.push(attribute)
      }

      return [ hierarchicalAttributes, simpleAttributes ]
    }, [ new Array<USER_ATTRIBUTE>(), new Array<USER_ATTRIBUTE>() ])

  return (
    <Dialog open maxWidth="md">
      <DialogTitle disableTypography>
        {t`Excel export`}
      </DialogTitle>
      <DialogContent>
        {props.status === "form" && (
          <>
            <div>
              {props.attributes && props.attributes.length > 0
                // eslint-disable-next-line max-len
                ? t`Please select the content language and the type of data you wish to export. You can also select an attribute on which you want a detailed export. Results will be filtered with the following filtering options:`
                // eslint-disable-next-line max-len
                : t`Please select the content language and the type of data you wish to export.`
              }
              <br/>
              {props.attributes && props.attributes.length > 0 && formattedFiltersList(props.filters)}
            </div>
            <Styles.Form>
              <Select
                label={t`Content language`}
                options={session.languages.map(
                  language => ({ value: language.code, wording: language.translatedLabel }),
                )}
                value={formState.language}
                onChange={(value) => setFormValue("language", value!.value as string)}
                defaultItem={false}
              />

              <div>
                <Label text={t`Export type`}/>
                <Styles.TypeRadioGroup
                  value={formState.exportType}
                  onChange={(_, value) => setFormValue("exportType", $enum(ExcelExportType).asValueOrDefault(value))}
                  row
                >
                  <Tooltip
                    title={!isUserAllowedToReadComments && t`You do not have permission to select this field`}
                    placement="top-start"
                  >
                    <FormControlLabel
                      value={ExcelExportType.Comments}
                      control={<Radio/>}
                      disabled={!isUserAllowedToReadComments}
                      label={t`Comments`}
                    />
                  </Tooltip>
                  <FormControlLabel
                    value={ExcelExportType.Satisfaction}
                    control={<Radio/>}
                    label={t`Answers distribution`}
                  />
                  <FormControlLabel
                    value={ExcelExportType.Participation}
                    control={<Radio/>}
                    label={t`Participation`}
                  />
                  <FormControlLabel
                    value={ExcelExportType.Ranking}
                    control={<Radio/>}
                    label={t`Ranking`}
                  />
                </Styles.TypeRadioGroup>
              </div>

              {props.attributes && props.attributes.length > 0 && (
                <div>
                  <Label text={t`Attribute`}/>

                  <Styles.AttributesListing>
                    {hierarchicalAttributes.length > 0 && (
                      <div>
                        <Styles.AttributesListingHeader>
                          {t`Organizational attributes:`}
                        </Styles.AttributesListingHeader>
                        <RadioGroup
                          value={(formState.pivot && formState.pivot.id.toString()) || ""}
                          onChange={(_, value) => {
                            setFormValue(
                              "pivot",
                              hierarchicalAttributes.find(attribute => attribute.id.toString() === value),
                            )
                          }}
                        >
                          {hierarchicalAttributes.map(attribute => (
                            <FormControlLabel
                              label={attribute.name}
                              key={attribute.id}
                              value={attribute.id.toString()}
                              control={<Radio/>}
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    )}

                    {simpleAttributes.length > 0 && (
                      <div>
                        <Styles.AttributesListingHeader>{t`Secondary attributes:`}</Styles.AttributesListingHeader>
                        <Styles.AttributesRadioGroup
                          value={(formState.pivot && formState.pivot.id.toString()) || ""}
                          onChange={(_, value) => {
                            setFormValue(
                              "pivot",
                              simpleAttributes.find(attribute => attribute.id.toString() === value),
                            )
                          }}
                        >
                          {simpleAttributes.map(attribute => (
                            <FormControlLabel
                              label={attribute.name}
                              key={attribute.id}
                              value={attribute.id.toString()}
                              control={<Radio/>}
                            />
                          ))}
                        </Styles.AttributesRadioGroup>
                      </div>
                    )}
                  </Styles.AttributesListing>
                </div>
              )}
            </Styles.Form>
          </>
        )}
        {props.status === "confirmation" && (
          <div>{t`Your Excel export is being generated. You will receive an email when it is ready.`}</div>
        )}
      </DialogContent>
      <DialogActions>
        {props.status === "form" && <PrimaryButton isInverted onClick={props.onCancel}>{t`Cancel`}</PrimaryButton>}
        <PrimaryButton onClick={confirm} disabled={!isFormValid(formState)}>
          {t`Confirm`}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  )

  /**
   * Set a form field value
   * @param field Form field to set
   * @param value Value to assign
   */
  function setFormValue<TField extends keyof ExcelExportDialogForm>(
    field: TField,
    value: ExcelExportDialogForm[TField] | undefined,
  ) {
    setFormState({
      ...formState,
      [field]: value,
    })
  }

  /**
   * Get whether the form is valid
   */
  function isFormValid(state: Partial<ExcelExportDialogForm>): state is ExcelExportDialogForm {
    if (state.language && state.exportType && $enum(ExcelExportType).isValue(state.exportType)) {
      // BR : when selecting the "ranking" export, user must select a pivot attribute.
      return state.exportType === ExcelExportType.Ranking ? state.pivot !== undefined : true
    }

    return false
  }

  /**
   * Confirm the dialog
   */
  function confirm() {
    if (props.status === "form" && isFormValid(formState) && props.onValidate) {
      props.onValidate(formState)
    }
    else if (props.status === "confirmation" && props.onValidate) {
      props.onValidate()
    }
  }

  /**
   * Get a formatted list to display the active filters
   * @param filters Filters to list
   */
  function formattedFiltersList(filters?: ExcelExportDialogProps["filters"]): string {
    let displayText = t`No filter selected.`

    if (filters && filters.length > 0) {
      displayText = Array.from(arrayGroupedBy(filters, "attributeLabel"))
        .map(([ attributeLabel, values ]) => {
          const attributeName = attributeLabel.toLocaleUpperCase()
          const attributeValuesList = values.map(val => val.label).join(", ")

          return t`${attributeName}: ${attributeValuesList}`
        })
        .join(" - ")
    }

    return displayText
  }
}

export interface ExcelExportDialogForm {
  language: string,
  exportType: ExcelExportType,
  pivot?: USER_ATTRIBUTE | undefined,
}

export interface ExcelExportDialogProps {
  /** Applied filters to list in the dialog */
  filters: USER_ATTRIBUTE_VALUE[],

  /** List of available pivot attributes for the export */
  attributes?: USER_ATTRIBUTE[],

  /** Fired when the user validates the form */
  onValidate?: (exportRequest?: ExcelExportDialogForm) => void,

  /** Fired when the user cancels the form */
  onCancel?: () => void,

  /** Whether the dialog shows the form or the confirmation message */
  status?: "form" | "confirmation",
}

ExcelExportDialog.defaultProps = {
  status: "form",
}
