import styled, { css } from "styled-components/macro"
import {
  ScheduledSurveyDetailsStylesProps,
} from "features/Surveys/ScheduledSurveyDetails/ScheduledSurveyDetails.styles"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: span 3;

  p {
    font-size: ${({ theme }) => theme.typography.fontSizeLabelBig};
    color: ${(theme) => theme.theme.colors.black};
    margin: .6rem 0;
  }
`

export const UsersGroupLink = styled.span<ScheduledSurveyDetailsStylesProps>`
  ${({ theme, $isDeleted, $isDisabled }) => css`
    display: flex;
    align-items: center;
    gap: .6rem;
    padding: 5px 8px;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.blackUltraLight};
    transition: background-color .2s ease-in-out;
    cursor: pointer;
    
    ${$isDeleted && css`
      color: ${theme.colors.blackUltraLight};
      background-color: ${theme.colors.blackLight};
    `}
    
    &:hover {
      ${!$isDisabled && css`
        background-color: ${({ theme }) => theme.colors.primaryDark};
        color: white;
        cursor: pointer;
      `}

      ${$isDeleted && css`
        color: ${theme.colors.blackUltraLight};
        background-color: ${theme.colors.blackLight};
      `}
    }
  `}
`

export const UserGroups = styled.div`
  display: flex;
  flex-direction:row;
  gap: 1rem;
  flex-wrap: wrap;
`

export const UserGroupLabel = styled.div`
  line-height: 1;
  font-size: ${({ theme }) => theme.typography.fontSizeButtonSmall};
`

export const UserGroupCount = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeButtonSmall};
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 50px;
  padding: 5px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  color: ${({ theme }) => theme.colors.black};
`
