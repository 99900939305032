import { motion } from "framer-motion"
import styled, { css } from "styled-components/macro"
import { Title } from "@humanpredictiveintelligence/myqvt-library"

export const Wrapper = styled.div<{ $isActive?: boolean }>`
  height: 100%; 
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  ${({ $isActive }) => !$isActive && css`
    background-color: #B0B8B8;
    
    ${StepTitle} {
      color: #556A6A;
    }
  `}
`

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  
`

export const StepTitle = styled(Title)`
  font-size: ${({ theme }) => theme.typography.fontSizeText};
  border: none;
  margin: 0;
  padding: 0;
`

export const SubTitle = styled.p<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-size: ${({ theme }) => theme.typography.fontSizeLabelMedium};
  margin: 0;
  padding: 0;
`
export const ShowDetailsButton = styled(SubTitle)`
  cursor: pointer;
  text-decoration: underline;
`

export const AnimatedBackButton = styled(motion.div)`
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeLabelSmaller};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  text-transform: uppercase;
  padding-top: 6px;  
  cursor: pointer;
`
