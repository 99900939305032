import styled from "styled-components/macro"
import { CSSProperties } from "react"

export const ChartText = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.7rem;
`

export const SymbolePercent = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeLabelSmall};
`

export const LegendBlock = styled.span<{ $backgroundColor: CSSProperties["backgroundColor"] }>`
  height: 100%;
  min-width: 1rem;

  background-color: ${({ $backgroundColor }) => $backgroundColor};
`

export const LegendContainer = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const ChartValue = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeText};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.colors.black};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: .3rem;
`

