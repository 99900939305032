import styled, { css } from "styled-components/macro"

export const Report = styled.a<ReportProps>`
  display: grid;
  padding: 1rem;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;
  border-radius: ${props => props.theme.sizes.borderRadius};
  background-color: ${props => props.theme.colors.blackUltraLight};
  text-decoration: none;
  transition: all .2s ease-in-out;
  border: 1px solid transparent;
  
  // if interactive
  ${props => !props.$disabled && css`
    &:hover {
      border: 1px solid ${props => props.theme.colors.blackLight};
    }
  `}
`

interface ReportProps {
  $disabled?: boolean,
}

export  const ReportIcon = styled.img`
  max-width: 100%;
`

export const ReportInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`

export const ReportTitle = styled.h5`
  font-weight: 600;
  color: ${props => props.theme.colors.black};
  margin:0;
`

export const ReportDate = styled.p`
  font-size: .8rem;
  color: ${props => props.theme.colors.blackMedium};
  margin: 0;
`

