import { SearchableSelectOption } from "@humanpredictiveintelligence/myqvt-library"
import { Comment } from "models/Comment"
import { CommentTranslation } from "models/CommentTranslation"
import { Language } from "models/Language"
import { COMMENT_TRANSLATIONS_SERVICE } from "services/CommentTranslationsService"

export type ReducerAction =
  | { type: "EmptyStoredComment" }
  | { type: "ResetAllTranslations", isEditing: boolean, storedComment?: Comment }
  | { type: "SelectExtraLanguages", selection: SearchableSelectOption[] }
  | { type: "ToggleEditing" }
  | { type: "UpdateTranslation", languageKind: "default" | "user" | "extra", languageCode: string, newValue: string }

export interface ScheduledSurveyFeedbackState {
  extraLanguagesSelectOptions: SearchableSelectOption[],
  isDirty?: boolean,
  isEditing?: boolean,
  languages: {
    app: Language[],
    customer: Language,
    user: Language[],
  },
  selectedExtraLanguages: Language[],
  storedComment?: Comment,
  translations: {
    default: CommentTranslation,
    user: CommentTranslation[],
    extra: CommentTranslation[],
  },
}

export function multiLanguageFeedbackReducer(
  state: ScheduledSurveyFeedbackState,
  action: ReducerAction,
): ScheduledSurveyFeedbackState {
  let newState = state

  switch (action.type) {
    case "SelectExtraLanguages":
      newState = selectExtraLanguage(state, action.selection)
      break
    case "UpdateTranslation":
      newState = updateTranslation(
        state,
        action.languageKind,
        action.languageCode,
        action.newValue,
      )
      break
    case "EmptyStoredComment":
      newState = emptyStoreComment(state)
      break
    case "ResetAllTranslations":
      newState = resetAllTranslations(state, action.storedComment, action.isEditing)
      break
    case "ToggleEditing":
      newState = toggleEditing(state)
      break
  }

  return { ...newState }
}

/**
 * Update the extra translations and selected extra languages according to an extra languages options selection
 *
 * @param currentState Current reducer state
 * @param selection Selected extra languages options
 */
function selectExtraLanguage(
  currentState: ScheduledSurveyFeedbackState,
  selection: SearchableSelectOption[],
): ScheduledSurveyFeedbackState {
  const newExtraLanguagesSelection = selection.map(language => ({
    code: language.value,
    localizedLabel: language.label as string,
    translatedLabel: language.label as string,
  }))

  const extraTranslations = COMMENT_TRANSLATIONS_SERVICE.extraTranslationsFromSource(
    currentState.storedComment,
    currentState.languages.customer,
    currentState.languages.user,
    newExtraLanguagesSelection,
  )

  currentState.selectedExtraLanguages = newExtraLanguagesSelection
  currentState.translations.extra = extraTranslations.map((newTranslation) => {
    const existingTranslation = currentState.translations.extra
      .find(translation => translation.language.code === newTranslation.language.code)
    return existingTranslation ?? newTranslation
  })

  return currentState
}

/**
 * Toggle the editing state
 * @param currentState Current reducer state
 */
function toggleEditing(currentState: ScheduledSurveyFeedbackState): ScheduledSurveyFeedbackState {
  return {
    ...currentState,
    isEditing: !currentState.isEditing,
  }
}

/**
 *
 * @param currentState Current reducer state
 * @param languageKind Whether the language is the default one, one of the user's or an extra one
 * @param languageCode Code of the translation's language
 * @param newValue New translations wording
 */
function updateTranslation(
  currentState: ScheduledSurveyFeedbackState,
  languageKind: "default" | "user" | "extra",
  languageCode: string,
  newValue: string,
): ScheduledSurveyFeedbackState {
  let translation

  if (languageKind === "default") {
    translation = currentState.translations.default
  }
  else {
    translation = currentState.translations[languageKind].find(
      tran => tran.language.code === languageCode,
    )
  }

  if (translation) {
    const storedTranslation = currentState.storedComment && currentState.storedComment.commentTranslations.find(
      tran => tran.language.code === languageCode,
    )

    const isDirty = storedTranslation ? storedTranslation.wording !== newValue : newValue !== ""

    translation.wording = newValue
    translation.isDirty = isDirty

    // Update state.isDirty
    if (isDirty) {
      currentState.isDirty = true
    }
    else {
      currentState.isDirty =
        currentState.translations.default.isDirty
        || currentState.translations.user.some(tran => !!tran.isDirty)
        || currentState.translations.extra.some(tran => !!tran.isDirty)
    }
  }

  return currentState
}

/**
 * Empty the storedComment without updating any translation
 * @param currentState Current state of the reducer
 */
function emptyStoreComment(currentState: ScheduledSurveyFeedbackState): ScheduledSurveyFeedbackState {
  return {
    ...currentState,
    isDirty: true,
    storedComment: undefined,
  }
}

/**
 * Reset storedComment and its translations
 *
 * @param currentState Current state of the reducer
 * @param storedComment New storedComment to set
 * @param isEditing Whether the comment is in edit mode
 */
function resetAllTranslations(
  currentState: ScheduledSurveyFeedbackState,
  storedComment: Comment | undefined,
  isEditing?: boolean,
): ScheduledSurveyFeedbackState {
  const extraLanguagesSelectOptions = COMMENT_TRANSLATIONS_SERVICE.availableExtraLanguagesOptions(
    currentState.languages.app,
    currentState.languages.customer,
    currentState.languages.user,
    storedComment,
  )

  return {
    ...currentState,
    extraLanguagesSelectOptions,
    isDirty: false,
    isEditing,
    selectedExtraLanguages: [],
    storedComment: storedComment ? Object.assign({}, storedComment) : undefined,
    translations: Object.assign(
      {},
      COMMENT_TRANSLATIONS_SERVICE.allTranslationsFromSource(
        storedComment,
        currentState.languages.customer,
        currentState.languages.user,
      ),
    ),
  }
}
