import styled from "styled-components/macro"

export const AppSelector = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
    height: auto;
    width: 100%;

    background: ${({ theme }) => theme.colors.white};
    border: 1px solid white;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(112, 144, 176, 0.15);
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    transition: all 250ms;
    
    &:hover {
        border: 1px solid #e8e8e8;
    }

    &:active {
        box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
    }
`
