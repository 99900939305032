import styled from "styled-components/macro"
import { Link } from "react-router-dom"
import * as React from "react"

export const Nav = styled.nav<{ $backgroundColor: React.CSSProperties["background"] }>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
  height: 100%;
  width: ${({ theme }) => theme.sizes.verticalMenuWidth};
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  background: ${({ $backgroundColor }) => $backgroundColor};
  
  @media print {
    display: none;
  }
`
export const MenuLink = styled(Link)`
  display: block;

  text-decoration: none;
  outline: 0;
`

export const Top = styled.div`
  margin-top: 32px;
  margin-bottom: 64px;
  text-align: center;
`

export const BrandLogo = styled.img`
  width: 48px;
  height: auto;
`

export const Center = styled.div`
  ${MenuLink} {
    padding: 12px 0;
  }
`

export const Bottom = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
  text-align: center;
`
