import styled, { css } from "styled-components/macro"
import { IconButton } from "@humanpredictiveintelligence/myqvt-library"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
`

export const Toggle = styled(IconButton)`
  outline: none;
  display: block;
  text-align: center;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  bottom: 5px;
  z-index: 999;
`

export const CardContainer = styled(Card)<{ $isHidden: boolean }>`
  position: absolute;
  overflow: visible;
  min-width: 200px;
  height: auto;

  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  box-shadow: ${({ theme }) => theme.shadows.below};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6px 16px 20px;
  transition: bottom, padding-bottom ease-in-out 300ms;

  ${({ $isHidden }) => $isHidden && css`
    border-radius: 5px 5px 0 0;
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
    height: 20px;
    bottom: 0;
    
    ${StepperContainer} {
      display: none;
    }
  `}
`

export const StepperContainer = styled.div``

export const QuitButton = styled.div`
  padding: 4px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  cursor: pointer;
`
