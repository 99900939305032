import styled from "styled-components/macro"

import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Title = styled.h1`
  font-size: ${props => props.theme.typography.fontSizeText};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  color: ${props => props.theme.colors.black};
  margin-bottom: 20px;
`

export const Content = styled.div`
  display: grid;
  row-gap: 16px;
    
  ${props => props.theme.mixins.reducedLandscapeAndPortrait} {
    width: 100%;
    margin: auto;
  }
`

export const Form = styled.form`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Instructions = styled.div`
  font-size: ${props => props.theme.typography.fontSizeTextMedium};
  color: ${props => props.theme.colors.grey};
  text-align: left;
  font-weight: ${props => props.theme.typography.fontWeight};
`

export const UndrawLogin = styled.img`
    `

export const SubmitAction = styled(PrimaryButton)`
  margin-top: 8px;
`

