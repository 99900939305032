import React from "react"
import { t } from "ttag"

import { withCenteredLayout } from "features/Navigation"
import * as Styles from "./MaintenancePage.styles"
import maintenanceIllustration from "assets/images/maintenance_illustration.png"

export const MaintenancePage = withCenteredLayout(() => (
  <Styles.CardContainer>
    <Styles.PageTitle level="big">{t`Maintenance`}</Styles.PageTitle>
    <Styles.Paragraph>
      {t`SpeakUP Admin is currently unavailable as a new version of the application is being deployed.
         Please try again in a few hours.
         We apologize for the inconvenience, thank you for your understanding.`
      }
    </Styles.Paragraph>
    <Styles.Illustration src={maintenanceIllustration} alt="maintenance illustration"/>
  </Styles.CardContainer>
))
