import moment from "moment"
import React from "react"
import { t } from "ttag"

import * as Styles from "./Steps.styles"
import * as StepContentStyles from "../StepContent.styles"
import { ScheduledSurveyCard, ScheduledSurveyCardProps } from "features/Surveys/ScheduledSurveyList/ScheduledSurveyCard"
import { Scheduled_Survey_Status as ScheduledSurveyStatus } from "models/generated/index"
import { SurveyScheduling } from "models/Survey/index"

const surveys = () => [ {
  color: "#FF4126",
  icon: "local_hospital",
  name: t`Physical safety and working conditions`,
  theme: t`Health and safety at work`,
},
{
  color: "#FFD600",
  icon: "business",
  name: t`The company structure`,
  theme: t`My company`,
},
{
  color: "#E91E63",
  icon: "work",
  name: t`Emotions at work`,
  theme: t`My work`,
} ]

export const SurveysPageLink = () =>
  <Styles.SurveysTutorialPopupContent>
    <p>{t`Let's move on to the surveys administration space.`}</p>
  </Styles.SurveysTutorialPopupContent>

export const ScheduledSurveysList = () => {
  return (
    <Styles.SurveysTutorialPopupContent>
      <p>{t`This column contains all surveys that you have scheduled. Surveys can hold different status.`}</p>

      <Styles.Step1.ListItem>
        <Styles.Step1.ListItemCell>
          <ScheduledSurveyCard {...getFakeSchedulingProps(ScheduledSurveyStatus.Todo, 0)}/>
        </Styles.Step1.ListItemCell>
        <Styles.Step1.ListItemCell>
          {t`Scheduled: this survey has not started yet. It will be send when it is scheduled. 
          Click on this survey will display a screen which allow to update or cancel it.`}
        </Styles.Step1.ListItemCell>
      </Styles.Step1.ListItem>
      <Styles.Step1.ListItem>
        <Styles.Step1.ListItemCell>
          <ScheduledSurveyCard {...getFakeSchedulingProps(ScheduledSurveyStatus.Ongoing, 1)}/>
        </Styles.Step1.ListItemCell>
        <Styles.Step1.ListItemCell>
          {t`In progress: this survey has been sent to participants. It will end when it is scheduled. 
          Click on this survey will display a screen which allow to see the current participation rate or cancel it.`}
        </Styles.Step1.ListItemCell>
      </Styles.Step1.ListItem>
      <Styles.Step1.ListItem>
        <Styles.Step1.ListItemCell>
          <ScheduledSurveyCard  {...getFakeSchedulingProps(ScheduledSurveyStatus.Done, 2)} />
        </Styles.Step1.ListItemCell>
        <Styles.Step1.ListItemCell>
          {t`Done: this survey has been sent and is over. Click on this survey will display a screen 
            which allow to see results if the participation rate was high enough.`}
        </Styles.Step1.ListItemCell>
      </Styles.Step1.ListItem>
    </Styles.SurveysTutorialPopupContent>
  )
  
  function getFakeSchedulingProps(status: ScheduledSurveyStatus, surveyIndex: number): ScheduledSurveyCardProps {
    return {
      isActive: false,
      isInteractive: false,
      scheduling: getFakeScheduling(status),
      survey: {
        id: 0,
        name: surveys()[surveyIndex].name,
      },
      theme: {
        color: surveys()[surveyIndex].color,
        icon: surveys()[surveyIndex].icon,
        id: 0,
        name: surveys()[surveyIndex].theme,
      },
    }
  }

  function getFakeScheduling(status: ScheduledSurveyStatus): SurveyScheduling {
    return {
      beginAt: {
        earliest: moment().toISOString(),
        latest: moment().toISOString(),
        local: moment().toISOString(),
      },
      endAt: {
        earliest: moment().toISOString(),
        latest: moment().toISOString(),
        local: moment().toISOString(),
      },
      schedulingId: 0,
      status: {
        absolute: status,
        local: status,
      },
    }
  }
}

export const ScheduledSurveyCreateLink = () =>
  <Styles.SurveysTutorialPopupContent>
    <p>{t`Let's schedule a new survey.`}</p>
  </Styles.SurveysTutorialPopupContent>

export const ScheduledSurveyCreationFields = () =>
  <Styles.SurveysTutorialPopupContent>
    <p>{t`In order to schedule a survey, you need to choose a theme, a survey and the periodicity for which this survey 
    will be available. We recommend, for the comfort of your recipients, to make the survey available for a minimum of
    2 days and to not run more than one survey on the same period.`}</p>
    <StepContentStyles.HelpText>
      {t`You can reach us out directly if you wish to have a custom survey.`}
    </StepContentStyles.HelpText>
  </Styles.SurveysTutorialPopupContent>

export const ScheduledSurveyPendingCreate = () =>
  <Styles.SurveysTutorialPopupContent>
    <p>{t`You only need to validate the inputs to schedule the survey`}</p>
  </Styles.SurveysTutorialPopupContent>

export const EmptyStep = () => <Styles.SurveysTutorialPopupContent><p/></Styles.SurveysTutorialPopupContent>

export const ScheduledSurveyCreated = () =>
  <Styles.ScheduledSurveyCreatedContent>
    <p>{t`The scheduled survey is now visible in the surveys list. It will start on the day and time it was scheduled.
    You can still update it or cancel it before it begins.`}</p>
  </Styles.ScheduledSurveyCreatedContent>

export const HubspotHighlight = () =>
  <Styles.SurveysTutorialPopupContent>
    {/* eslint-disable-next-line max-len */}
    <p>{t`If you wish to contact us (need help, import multiple recipients, create custom survey, change anonymity threshold,
    you can do it through this contact button. You are now ready to use SpeakUP !`}</p>
  </Styles.SurveysTutorialPopupContent>
