/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
import { SkinnyUserAttributeValueFragmentFragmentDoc } from './SkinnyUserAttributeValueFragment';
export type SkinnyUserAttributeStatisticsFragmentFragment = { isClusterUnviolated: boolean, usersCount: number, attribute: { name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> } };

export const SkinnyUserAttributeStatisticsFragmentFragmentDoc = gql`
    fragment SkinnyUserAttributeStatisticsFragment on Statistics_attribute {
  isClusterUnviolated: are_recipients_above_cluster
  usersCount: participationSubmittedNumber
  attribute {
    ...SkinnyUserAttributeValueFragment
  }
}
    ${SkinnyUserAttributeValueFragmentFragmentDoc}`;