import { useTheme } from "styled-components"

import { ImportStepStatus } from "features/RecipientsImport/RecipientImportStep"

export const useStatusIconsTheme = () => {
  const theme = useTheme()

  return {
    [ImportStepStatus.pending]: { color: theme.colors.blackMedium, iconName: "more_horiz" },
    [ImportStepStatus.ongoing]: { color: theme.colors.blackMedium, iconName: "ongoing" },
    [ImportStepStatus.done]: { color: theme.colors.success, iconName: "done" },
    [ImportStepStatus.failed]: { color: theme.colors.danger, iconName: "close" },
  }
}
