import React from "react"
import { jt, t } from "ttag"

import { useSession } from "features/Session"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { muiMyQvtVariables } from "@humanpredictiveintelligence/myqvt-library"

import * as Styles from "./Steps.styles"
import { myQvtTheme } from "@humanpredictiveintelligence/myqvt-library"
import * as StepContentStyles from "features/Tutorial/contents/StepContent.styles"
import { useTutorial } from "features/Tutorial/TutorialContext"
import { RecipientsCreateStepData } from "features/Tutorial/contents/RecipientsPage"
import { isRecipientsCreateStepData } from "../../utils"

export const TutorialIntroduction = () => (
  <Styles.RecipientsTutorialPopupContent>
    <p>{t`Welcome to your SpeakUP administration interface, it is in this area that you will be able to:`}</p>
    <Styles.Step1.List>
      <Icon name="alarm" color={muiMyQvtVariables.palette.primary.main} size={32}/>
      {t`Schedule surveys`}

      <Icon name="assessment" size={32} color={muiMyQvtVariables.palette.primary.main}/>
      {t`See results and give feedback`}

      <Icon name="group" size={32} color={muiMyQvtVariables.palette.primary.main}/>
      {t`Edit your users list`}
    </Styles.Step1.List>
    <p>{t`Before we begin, let's take a quick tour.`}</p>
  </Styles.RecipientsTutorialPopupContent>
)

export const RecipientsPageLink = () =>
  <Styles.RecipientsTutorialPopupContent>
    <p>{t`
  You are currently in the "Users" area.
  This is where you can add, edit
  and delete the users who will participate in your next surveys.
    `}</p>
  </Styles.RecipientsTutorialPopupContent>

export const RecipientsList = () => {
  const clusterSize = useSession().customer?.clusterSize
  const participantsCount = <strong key="participation-count">{clusterSize}</strong>

  return <Styles.RecipientsTutorialPopupContent>
    {<p>
      {jt`You are, for the moment, the only user on the list.
        This means that only you will receive the scheduled surveys.
        In order to preserve participation anonymity,
        you need at least ${participantsCount} participations in a completed survey to display the results.
        We therefore advise you to add more users.
      `}
    </p>}
    <Styles.Step3.Warning>
      <Icon name="help_outline" size={24}/>
      {t`This limit of ${clusterSize} participations can be modified on request`}
    </Styles.Step3.Warning>
  </Styles.RecipientsTutorialPopupContent>
}

export const RecipientsCreateLink = () => <Styles.RecipientsTutorialPopupContent>
  <Styles.ClusterParagraph>{t`Let's add users.`}</Styles.ClusterParagraph>
</Styles.RecipientsTutorialPopupContent>

export const RecipientsCreate = () => {
  const clusterSize = useSession().customer?.clusterSize ?? 0
  const clusterSize2 = clusterSize
  const recipientsCount = useSession().customer?.recipientsCount ?? 0
  const { getStepData, setStepData } = useTutorial()
  const stepData = getStepData<RecipientsCreateStepData>("recipients-create")
  const canContinueState = isRecipientsCreateStepData(stepData) ? stepData.canContinue : false
  const status = getRecipientsStatus(clusterSize, recipientsCount)
  const canContinue = [ "warning", "valid" ].indexOf(status) !== -1

  const statusColorMap = {
    "error": myQvtTheme.colors.danger,
    "valid": myQvtTheme.colors.green,
    "warning": myQvtTheme.colors.warning,
  }

  /**
   * Updates the active step data so other components can know what to do.
   * In this situation, the "Continue" button will be either enabled or disabled depending on the status of canContinue
   */
  React.useEffect(() => {
    if (canContinueState !== canContinue) {
      setStepData<RecipientsCreateStepData>("recipients-create", { canContinue })
    }
  }, [ setStepData, canContinue, canContinueState ])

  function getRecipientsStatus(clusterSize: number, recipientsCount: number) {
    switch (true) {
      case clusterSize * 2 <= recipientsCount:
        return "valid"
      case clusterSize <= recipientsCount:
        return "warning"
    }
    return "error"
  }

  return <>
    <Styles.RecipientsTutorialPopupContent>
      <Styles.ClusterParagraph>
        {/* eslint-disable-next-line max-len */}
        {t`There is a minimum of ${clusterSize} participations to a survey to display its results, hence we invite you 
        to add a minimum of ${clusterSize2} people.`}
      </Styles.ClusterParagraph>

      <Styles.RecipientsStatus.Wrapper>
        <Styles.RecipientsStatus.Paragraph
          $color={statusColorMap[status]}
        >
          {t`There is at the moment ${recipientsCount} user(s) in the list.`}
        </Styles.RecipientsStatus.Paragraph>
        {status !== "valid" &&
        <Styles.RecipientsStatus.Hint>
          {/* eslint-disable-next-line max-len */}
          {t`We advise you to add more in order to increase your chances to see the results of a survey.`}
        </Styles.RecipientsStatus.Hint>}
      </Styles.RecipientsStatus.Wrapper>

      <StepContentStyles.HelpText>
        {t`You can import multiple users at once by getting in touch with us.`}
      </StepContentStyles.HelpText>
    </Styles.RecipientsTutorialPopupContent>
  </>
}

export const RecipientsCreated = () =>
  <Styles.RecipientsTutorialPopupContent>
    {/* eslint-disable-next-line max-len */}
    <p>{t`New users are added, they will also receive the next surveys when they start.`}</p>
  </Styles.RecipientsTutorialPopupContent>
