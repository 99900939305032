import styled from "styled-components/macro"

export const Container = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 450px;
  padding: 16px;
  margin-top: 12px;
  background: ${({ theme }) => theme.colors.blackUltraLight};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  animation: blink 1s linear;
`

export const Text = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
  color: ${({ theme }) => theme.colors.blackMedium};
  overflow-y: auto;
  word-wrap: break-word;
`

export const NoDiscussions = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
  color: ${({ theme }) => theme.colors.blackMedium};

  p {
    max-width: 25rem;
  }
`

export const NoDiscussionsImage = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 1rem;
`

