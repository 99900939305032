import React, { ChangeEvent, MouseEvent } from "react"
import { t } from "ttag"

import * as Styles from "features/RecipientsImport/RecipientsImportDropzone/RecipientsImportDropzone.styles"

export const RecipientsImportDropzone = (props: RecipientsImportDropzoneProps) => {
  const dropZoneRef = React.useRef<HTMLLabelElement>(null)

  const [ isDragging, setDragging ] = React.useState(false)
  const [ file, setFile ] = React.useState<File | undefined>(undefined)
  const [ isFileUploaded, setFileUploaded ] = React.useState(false)
  const dragEnterCount = React.useRef(0)
  const { onFileSelected, isDisabled } = props

  /**
   * Listen to the input event and update the dropzone state and current file
   * If a file is already set, ignore every event.
   **/
  const memoizedOnUserAction = React.useCallback((event: DragEvent | ChangeEvent | MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    if (file && props.isDisabled) {
      return
    }

    switch (event.type) {
      case "dragover":
        // Do nothing but we need to listen to it to make the drop event work
        break

      case "dragenter":
        setDragging(true)
        dragEnterCount.current++

        break
      case "dragleave":
        dragEnterCount.current--
        if (dragEnterCount.current === 0) {
          setDragging(false)
        }

        break
      case "drop":
        setFile(undefined)
        setFileUploaded(false)
        setDragging(false)
        setFile((event as DragEvent)?.dataTransfer?.files[0])

        break

      case "change":
        setFile(undefined)
        setFileUploaded(false)
        setDragging(false)
        setFile((event as ChangeEvent<HTMLInputElement>)?.target?.files?.[0])
        break

      default:
        setDragging(false)
    }
  }, [ file, props.isDisabled ])

  React.useEffect(() => {
    if (file && !isFileUploaded && !isDisabled) {
      onFileSelected?.(file)
      setFileUploaded(true)
    }
  }, [ onFileSelected, file, isFileUploaded, isDisabled ])

  React.useEffect(() => {
    let dropZone: HTMLLabelElement
    if (dropZoneRef.current) {
      dropZone = dropZoneRef.current

      window.addEventListener("dragenter", memoizedOnUserAction)
      window.addEventListener("dragleave", memoizedOnUserAction)
      dropZone.addEventListener("dragover", memoizedOnUserAction)
      dropZone.addEventListener("drop", memoizedOnUserAction)

      return () => {
        window.removeEventListener("dragenter", memoizedOnUserAction)
        window.removeEventListener("dragleave", memoizedOnUserAction)
        dropZone.removeEventListener("dragover", memoizedOnUserAction)
        dropZone.removeEventListener("drop", memoizedOnUserAction)
      }
    }
  }, [ memoizedOnUserAction ])

  const removeFileInput = React.useCallback((event) => {
    event.target.value = null
  }, [])

  return (

    <Styles.DropZone
      ref={dropZoneRef}
      onMouseEnter={memoizedOnUserAction}
      onMouseLeave={memoizedOnUserAction}
      htmlFor="image-event" id="image-event-label"
      $isDragging={isDragging}
      $isDisabled={props.isDisabled}
    >
      <Styles.FileInput
        type="file"
        disabled={props.isDisabled}
        onClick={removeFileInput}
        onChange={memoizedOnUserAction}
        name="image-event"
        id="image-event"
      />
      <Styles.ImportIcon
        name="insert_drive_file"
        isOutlined
        size={48}
      >
        .xlsx
      </Styles.ImportIcon>
      {file && props.isDisabled ? (
        <Styles.FileName>{file.name}</Styles.FileName>
      ) : <Styles.Label size="big">{t`Import file`}</Styles.Label>}
    </Styles.DropZone>

  )
}

export interface RecipientsImportDropzoneProps {
  /**  CallBack that return the file selected on drag or on click */
  onFileSelected?: (file: File) => void,

  isDisabled: boolean,
}

RecipientsImportDropzone.defaultProps = {
  isDisabled: false,
}
