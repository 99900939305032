/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportParticipationsQueryVariables = Types.Exact<{
  scheduledSurveyId: Types.Scalars['Int'];
  language?: Types.InputMaybe<Types.Supported_Language_Code>;
  groupAttributeId?: Types.InputMaybe<Types.Scalars['Int']>;
  attributeValueIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type ExportParticipationsQuery = { exportParticipations: string };


export const ExportParticipationsDocument = gql`
    query ExportParticipations($scheduledSurveyId: Int!, $language: Supported_language_code, $groupAttributeId: Int, $attributeValueIds: [Int!]!) {
  exportParticipations: export_participations(scheduled_survey_id: $scheduledSurveyId, language: $language, group_attribute_id: $groupAttributeId, attribute_value_ids: $attributeValueIds)
}
    `;

/**
 * __useExportParticipationsQuery__
 *
 * To run a query within a React component, call `useExportParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportParticipationsQuery({
 *   variables: {
 *      scheduledSurveyId: // value for 'scheduledSurveyId'
 *      language: // value for 'language'
 *      groupAttributeId: // value for 'groupAttributeId'
 *      attributeValueIds: // value for 'attributeValueIds'
 *   },
 * });
 */
export function useExportParticipationsQuery(baseOptions: Apollo.QueryHookOptions<ExportParticipationsQuery, ExportParticipationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportParticipationsQuery, ExportParticipationsQueryVariables>(ExportParticipationsDocument, options);
      }
export function useExportParticipationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportParticipationsQuery, ExportParticipationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportParticipationsQuery, ExportParticipationsQueryVariables>(ExportParticipationsDocument, options);
        }
export type ExportParticipationsQueryHookResult = ReturnType<typeof useExportParticipationsQuery>;
export type ExportParticipationsLazyQueryHookResult = ReturnType<typeof useExportParticipationsLazyQuery>;
export type ExportParticipationsQueryResult = Apollo.QueryResult<ExportParticipationsQuery, ExportParticipationsQueryVariables>;