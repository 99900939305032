import { ApolloError } from "@apollo/client"
import { CenteredLoader, IconButton } from "@humanpredictiveintelligence/myqvt-library"
import * as React from "react"
import { t } from "ttag"

import * as Styles from "./GroupsSection.styles"
import { NotificationContext } from "features/Notification"
import { UsersTable } from "features/UserGroups/UsersGroupsSections/UsersTable"
import { GroupsListItem } from "features/UserGroups/GroupsListItem"
import { AddGroupDialog, DeleteGroupDialog, EditGroupDialog } from "features/UserGroups/UserGroupDialog"
import { UserGroup } from "features/UserGroups/groups.model"
import { useUserGroups } from "features/UserGroups/UserGroupsContext"
import { AuthenticationContext } from "features/Authentication"

import { useGetUserGroupsQuery } from "graphql/queries/generated/GetUserGroups"
import { useUpdateUserGroupMutation } from "graphql/mutations/generated/UpdateUserGroup"
import { useDeleteUserGroupMutation } from "graphql/mutations/generated/DeleteUserGroup"
import { useAddUserGroupMutation } from "graphql/mutations/generated/AddUserGroup"

import arrowLeftIllustration from "assets/images/arrow-left.png"

export const GroupsSection: React.FC = () => {
  const { actions, state } = useUserGroups()
  const { getUserSession } = React.useContext(AuthenticationContext)
  const notification = React.useContext(NotificationContext)

  const [ userGroups, setUserGroups ] = React.useState<UserGroup[]>([])
  const [ isAddUserGroupDialogOpen, setIsAddUserGroupDialogOpen ] = React.useState(false)
  const [ userGroupBeingEdited, setUserGroupBeingEdited ] = React.useState<UserGroup | undefined>()
  const [ userGroupBeingDeleted, setUserGroupBeingDeleted ] = React.useState<UserGroup | undefined>()

  const [ awaitingScroll, setAwaitingScroll ] = React.useState(false)
  const userGroupsListRef = React.createRef<HTMLDivElement>()
  const userGroupItemRef = React.createRef<HTMLDivElement>()

  const {
    data: userGroupsData,
    loading: areUserGroupsLoading,
    refetch: refetchUserGroups,
  } = useGetUserGroupsQuery()

  const [ addUserGroup, { loading: loadingAddUserGroup } ] = useAddUserGroupMutation()
  const [ updateUserGroup, { loading: loadingEditUserGroup } ] = useUpdateUserGroupMutation()
  const [ deleteUserGroup, { loading: loadingDeleteUserGroup } ] = useDeleteUserGroupMutation()

  React.useEffect(() => {
    if (userGroupsData?.getUserGroups?.list) {
      setUserGroups(userGroupsData.getUserGroups.list)
    }
  }, [ userGroupsData ])

  React.useEffect(() => {
    if (awaitingScroll && userGroupItemRef.current && userGroupsListRef.current) {
      userGroupsListRef.current?.scrollTo({
        behavior: "smooth",
        top: userGroupItemRef.current.offsetTop - userGroupsListRef.current.offsetTop,
      })
      setAwaitingScroll(false)
    }
  }, [ userGroupItemRef, userGroupsListRef, awaitingScroll ])

  return (
    <Styles.Container>
      <Styles.GroupsListSection>
        <Styles.AddGroupAction onClick={() => setIsAddUserGroupDialogOpen(true)}>
          <IconButton icon="add"/>
          <label>{t`Add a group`}</label>
        </Styles.AddGroupAction>
        <Styles.ListContainer ref={userGroupsListRef}>
          {areUserGroupsLoading && <CenteredLoader isTransparent/>}
          <Styles.GroupsList>
            {userGroups?.map(group => (
              <div
                key={group.name}
                ref={state.selectedGroupUuid === group.uuid ? userGroupItemRef : undefined}
              >
                <GroupsListItem
                  group={group}
                  isSelected={state.selectedGroupUuid === group.uuid}
                  onClick={() => actions.setSelectedGroupUuid(group.uuid)}
                  onUserGroupRename={() => setUserGroupBeingEdited(group)}
                  onDeleteUserGroup={() => setUserGroupBeingDeleted(group)}
                />
              </div>
            ))}
          </Styles.GroupsList>
        </Styles.ListContainer>
      </Styles.GroupsListSection>

      <Styles.UsersAndPackagesSection>
        <Styles.Heading>
          <Styles.HeadingTitle level={"section"}>{t`Users in group`}</Styles.HeadingTitle>
        </Styles.Heading>
        {!state.selectedGroupUuid && (
          <Styles.NoGroupSelectedMessage>
            <p>{t`No group selected`}</p>
            <p>{t`Select one from the list`}</p>
            <img src={arrowLeftIllustration} alt=""/>
          </Styles.NoGroupSelectedMessage>
        )}
        {state.selectedGroupUuid && (
          <UsersTable
            tableSide="usersInGroup"
            onSelectUser={(userId) => selectUser(Number(userId))}
            selectedIds={state.selectedUsersInGroupIds.map(String)}
            onSelectAllUsersInPage={(ids, checked) => selectAllUsersInPage(ids.map(Number), checked)}
            areActionsDisabled={state.areUsersSelected}
          />
        )}
      </Styles.UsersAndPackagesSection>

      <AddGroupDialog
        isOpen={isAddUserGroupDialogOpen}
        isLoading={loadingAddUserGroup}
        onAdd={addGroup}
        onCancel={() => setIsAddUserGroupDialogOpen(false)}
      />
      {userGroupBeingEdited !== undefined && (
        <EditGroupDialog
          isOpen
          isLoading={loadingEditUserGroup}
          onEdit={updateGroupName}
          onCancel={() => setUserGroupBeingEdited(undefined)}
          defaultGroup={userGroupBeingEdited}
        />
      )}
      {userGroupBeingDeleted !== undefined && (
        <DeleteGroupDialog
          isOpen
          isLoading={loadingDeleteUserGroup}
          onDelete={deleteGroup}
          onCancel={() => setUserGroupBeingDeleted(undefined)}
          defaultGroup={userGroupBeingDeleted}
        />
      )}
    </Styles.Container>
  )

  function selectAllUsersInPage(
    ids: number[],
    checked: boolean,
  ) {
    if (!checked || state.selectedUsersInGroupIds.length === ids.length) {
      actions.setSelectedUsersInGroupIds([])
    }
    else {
      actions.setSelectedUsersInGroupIds(ids)
    }
  }

  function selectUser(selectedId: number) {
    if (selectedId) {
      const selectedIdIndex = state.selectedUsersInGroupIds.indexOf(selectedId)

      if (selectedIdIndex !== -1) {
        const newSelectedIds = [ ...state.selectedUsersInGroupIds ]
        newSelectedIds.splice(selectedIdIndex, 1)

        actions.setSelectedUsersInGroupIds(newSelectedIds)
      }
      else {
        actions.setSelectedUsersInGroupIds([ ...state.selectedUsersInGroupIds, selectedId ])
      }
    }
  }

  async function addGroup(userGroupName: UserGroup["name"]) {
    try {
      const addUserGroupResponse = await addUserGroup({
        variables: {
          name: userGroupName,
        },
      })
      await refetchUserGroups()
      actions.setSelectedGroupUuid(addUserGroupResponse.data?.addUserGroup.uuid ?? "")
      setAwaitingScroll(true)
      notification.show(t`Success`, t`The group has been successfully added`)
      getUserSession()
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
    finally {
      setIsAddUserGroupDialogOpen(false)
    }
  }

  async function updateGroupName(userGroup: UserGroup) {
    try {
      await updateUserGroup({
        variables: {
          name: userGroup.name,
          uuid: userGroup.uuid,
        },
      })
      setUserGroupBeingEdited(undefined)
      notification.show(t`Success`, t`The group name has been successfully updated`)
      await refetchUserGroups()
      getUserSession()
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
  }

  async function deleteGroup(userGroup: UserGroup) {
    try {
      await deleteUserGroup({
        variables: {
          uuid: userGroup.uuid,
        },
      })
      setUserGroupBeingDeleted(undefined)
      notification.show(t`Success`, t`The group has been successfully deleted`)
      await refetchUserGroups()
      getUserSession()
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
  }
}
