import React from "react"
import { t } from "ttag"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"

import { Admin } from "features/Permissions"
import { usePermissions } from "features/Permissions/PermissionsContext"
import { AdminCreationProcessContainer } from "features/Permissions/AdminCreationProcess"
import { AdminListItem } from "./AdminListItem"
import { BaseProps, CenteredLoader, IconButton, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./AdminsSection.styles"

export const AdminsSection: React.FC<BaseProps> = props => {
  const { admins, selectedAdmin, actions, areAdminsLoading, fetchAdmins, hasChanges } = usePermissions()
  const [ isAdminCreationProcessOngoing, setIsAdminCreationProcessOngoing ] = React.useState(false)
  const [ adminToSelect, setAdminToSelect ] = React.useState<Admin | undefined>()
  const [ filter, setFilter ] = React.useState<string>()

  /** Filter the admins by their first and last name */
  const filteredAdmins = React.useCallback((admins: Admin[], filter?: string) => {
    if (!filter) {
      return admins
    }

    const lowerCasedFilter = filter.toLowerCase()

    return admins.filter(admin =>
      admin.firstName.toLowerCase().includes(lowerCasedFilter)
      || admin.lastName.toLowerCase().includes(lowerCasedFilter),
    )
  }, [])

  return (
    <Styles.Container className={props.className}>
      {isAdminCreationProcessOngoing && (
        <AdminCreationProcessContainer
          onAdminCreationProcessStop={() => setIsAdminCreationProcessOngoing(false)}
          onAdminCreationProcessDone={() => {
            fetchAdmins()
            setIsAdminCreationProcessOngoing(false)
          }}
        />
      )}

      {adminToSelect && (
        <Dialog open={!!adminToSelect}>
          <DialogTitle>{t`Switch admin ?`}</DialogTitle>
          <DialogContent>
            {t`The changes you made will be lost.`}
          </DialogContent>
          <DialogActions>
            <PrimaryButton onClick={() => setAdminToSelect(undefined)} isInverted>{t`Cancel`}</PrimaryButton>
            <PrimaryButton
              destructive
              onClick={() => {
                actions.selectAdmin(adminToSelect)
                setAdminToSelect(undefined)
              }}
            >
              {t`Confirm`}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )}

      <Styles.Header>
        <Styles.Title level="section">{t`Administrators`}</Styles.Title>
        <IconButton icon="add" size="big" onClick={() => setIsAdminCreationProcessOngoing(true)}/>
        <Styles.SearchField onChange={setFilter} placeholder={t`Search by name`}/>
      </Styles.Header>

      {areAdminsLoading && (
        <CenteredLoader isTransparent size="m"/>
      )}

      {!areAdminsLoading && admins && (
        <Styles.List>
          {filteredAdmins(admins, filter).map(admin => (
            <AdminListItem
              admin={admin}
              key={admin.id}
              onAdminSelect={onAdminSelect}
              isSelected={selectedAdmin?.id === admin.id}
            />
          ))}
        </Styles.List>
      )}
    </Styles.Container>
  )

  function onAdminSelect(admin: Admin) {
    if (hasChanges) {
      setAdminToSelect(admin)
    }
    else {
      actions.selectAdmin(admin)
    }
  }
}
