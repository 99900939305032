import * as React from "react"

import { TutorialReducerAction } from "features/Tutorial/TutorialReducerActions"
import { useTutorial } from "features/Tutorial/TutorialContext"
import useTutorialController from "features/Tutorial/useTutorialController"

/**
 * Handle imperative step
 * An imperative step kind of describes HOW it works to the Tutorial component
 * and gives instructions to progress through the step (e.g. setTarget, etc..).
 *
 * JoyRide requires the `target` to be mounted to work correctly. Some target does not exist and will appear as
 * the user progress in the tutorial and are most of time bound to async process.
 * Hence, components needs to be able to trigger step imperatively,
 * To solve this problem, the Tutorial component start the step but pauses JoyRide execution.
 * It waits for the components in the application to resume its execution.
 * In order to do that, components communicate to JoyRide which target is associated to the current step.
 * If a target is defined for the step, then the Tutorial assumes that the target is mounted and resumes
 * JoyRide execution with the provided `target`.
 */
const useImperativeTutorialStepHandler = (dispatch: React.Dispatch<TutorialReducerAction>) => {
  const { tutorialState, activeStep } = useTutorial()
  const { pauseTutorial, runTutorial, changeStepTarget, isImperative } = useTutorialController(dispatch)

  React.useEffect(() => {
    if (activeStep === undefined) {
      return
    }
    // We consider imperative step, step with an `isImperative` property defined
    if (isImperative(activeStep) && activeStep.id) {
      const runtimeTargetElement = tutorialState.stepTargets[activeStep.id]

      // The target for the step is not defined yet which means that the component defining it did not set it yet
      if (runtimeTargetElement === undefined) {
        // We pause joyride execution until the target is ready (other tutorial components are still running)
        pauseTutorial()
      } else {
        if (tutorialState.currentTarget !== runtimeTargetElement) {
          if (!tutorialState.runJoyRide) {
            // If tutorial is paused, then we can change the target
            // If the current target in the tutorial state is different from the defined at runtime target, we set it
            changeStepTarget(runtimeTargetElement)
          } else {
            // If tutorial is running, we must pause the tutorial before changing target
            pauseTutorial()
          }
        } else if (!tutorialState.runJoyRide) {
          // Otherwise, it means the step is ready to be run
          runTutorial()
        }
      }
    } else {
      // This is not an imperative step, joyride can stay open
      runTutorial()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tutorialState.runJoyRide,
    tutorialState.run,
    activeStep,
    tutorialState.currentTarget,
    tutorialState.stepIndex,
    tutorialState.stepTargets,
  ])
}

export default useImperativeTutorialStepHandler
