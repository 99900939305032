import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import * as React from "react"

import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const ErrorDialog: React.FunctionComponent<ErrorDialogProps> = (props) => {
  return (
    <Dialog
      open
      disableBackdropClick={!props.isDismissable}
      disableEscapeKeyDown={!props.isDismissable}
    >
      {props.title && <DialogTitle disableTypography>{props.title}</DialogTitle>}
      <DialogContent>
        {props.message}
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={props.onConfirm}>{props.confirmText}</PrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

interface ErrorDialogProps {
  /** Title of the dialog */
  title?: string,

  /** Message in the dialog body */
  message: string,

  /** Text on the button */
  confirmText: string,

  /** Called when the user confirms the dialog */
  onConfirm?: () => void,

  /** Whether the user can dismiss the dialog */
  isDismissable?: boolean,
}
