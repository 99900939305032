import * as React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"

import { useSession } from "features/Session"
import { DemoSurveyPage } from "features/Surveys/DemoSurveyPage"
import { PackageCode } from "models/generated"
import { ApplicationPath } from "./ApplicationNavigation"

export const SpeakupPackageNotActivatedRoute: React.FunctionComponent<RouteProps> = (props) => {
  const session = useSession()

  const isSpeakUpPackageEnabled = session.customer.packages.some(
    (_package) => _package.codeName === PackageCode.Speakup && _package.isEnabled,
  )

  return (
    <Route
      {...props}
      render={() => isSpeakUpPackageEnabled
        ? <Redirect to={ApplicationPath.SpeakUpDashboard} />
        : <DemoSurveyPage/>
      }
    />
  )
}
