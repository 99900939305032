import * as React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"
import { t } from "ttag"

import { APPLICATION_URL } from "./ApplicationNavigation"

export const PageNotFoundRoute: React.FunctionComponent<RouteProps> = (props) => (
  <Route
    {...props}
    render={() => (
      <Redirect
        to={{
          pathname: APPLICATION_URL.error(),
          state: {
            message: t`The requested page cannot be found`,
            redirect: APPLICATION_URL.base(),
            redirectLabel: t`Sign in`,
          },
        }}
      />
    )}
  />
)
