/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnySurveySchedulingFragmentFragmentDoc } from '../../../models/generated/SkinnySurveySchedulingFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SurveySchedulingQueryVariables = Types.Exact<{
  beginAt: Types.Scalars['DateTime'];
  endAt: Types.Scalars['DateTime'];
  surveyId: Types.Scalars['Int'];
}>;


export type SurveySchedulingQuery = { surveyScheduling: { survey_id: number, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status, utc: Types.Scheduled_Survey_Status }, begin_at: { local: string, earliest?: string | undefined, latest?: string | undefined }, end_at: { local: string, earliest?: string | undefined, latest?: string | undefined } } };


export const SurveySchedulingDocument = gql`
    query SurveyScheduling($beginAt: DateTime!, $endAt: DateTime!, $surveyId: Int!) {
  surveyScheduling: survey_scheduling(begin_at: $beginAt, end_at: $endAt, survey_id: $surveyId) {
    ...SkinnySurveySchedulingFragment
  }
}
    ${SkinnySurveySchedulingFragmentFragmentDoc}`;

/**
 * __useSurveySchedulingQuery__
 *
 * To run a query within a React component, call `useSurveySchedulingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveySchedulingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveySchedulingQuery({
 *   variables: {
 *      beginAt: // value for 'beginAt'
 *      endAt: // value for 'endAt'
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveySchedulingQuery(baseOptions: Apollo.QueryHookOptions<SurveySchedulingQuery, SurveySchedulingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveySchedulingQuery, SurveySchedulingQueryVariables>(SurveySchedulingDocument, options);
      }
export function useSurveySchedulingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveySchedulingQuery, SurveySchedulingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveySchedulingQuery, SurveySchedulingQueryVariables>(SurveySchedulingDocument, options);
        }
export type SurveySchedulingQueryHookResult = ReturnType<typeof useSurveySchedulingQuery>;
export type SurveySchedulingLazyQueryHookResult = ReturnType<typeof useSurveySchedulingLazyQuery>;
export type SurveySchedulingQueryResult = Apollo.QueryResult<SurveySchedulingQuery, SurveySchedulingQueryVariables>;