import { ApolloError } from "@apollo/client/errors"
import React from "react"
import { t } from "ttag"

import { Admin } from "features/Permissions/models"
import { NotificationContext } from "features/Notification"

import { useSetUserScopesMutation } from "graphql/mutations/generated/SetUserScopes"

export const useScopeManager = () => {
  const notification = React.useContext(NotificationContext)
  const [ areScopesLoading, setAreScopesLoading ] = React.useState(false)

  const [ setUserScopes ] = useSetUserScopesMutation()

  /**
   * Send the selected admin scopes to the API
   */
  async function saveAdminScopes(admin: Admin, scopeIds: number[]) {
    try {
      setAreScopesLoading(true)
      await setUserScopes({
        variables: {
          scopes: scopeIds,
          userId: admin.id,
        },
      })
      notification.show(t`Success`, t`The administrator's scope has been updated.`, "success")
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
      }
    }

    setAreScopesLoading(false)
  }

  return {
    areScopesLoading,
    saveAdminScopes,
  }
}

export default useScopeManager
