import styled, { css } from "styled-components/macro"
import { Caption } from "@humanpredictiveintelligence/myqvt-library"
import { CSSProperties } from "react"

export const CardHeader = styled.header`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto auto;
  grid-column-gap: 16px;
`

export const SurveySentCount = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.typography.fontSizeMetricsFigure};
`
export const SurveySentCaption = styled(Caption)`
  text-align: left;
  align-self: center;
`

export const Table = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 16px;
  align-items: center;
  justify-items: center;

  margin-top: 40px;
  font-size: ${({ theme }) => theme.typography.fontSizeLabelMedium};
  color: ${({ theme }) => theme.colors.blackMedium};
`

export const ThemeGrade = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
`

export const ThemeGradeValue = styled.div<{ $color?: CSSProperties["color"] }>`
  ${({ $color }) => $color && css`
    color: ${$color};
  `}
`
