import React from "react"
import { DialogContent } from "@material-ui/core"

import { RecipientImportIntroduction } from "features/RecipientsImport/RecipientImportIntroduction"
import { RecipientsImportPanel } from "features/RecipientsImport/RecipientsImportPanel"

import * as Styles from "./RecipientsImportDialog.styles"

export const RecipientsImportDialog = (props: RecipientsImportDialogProps) => {
  const [ hasStarted, setStarted ] = React.useState(false)

  return <Styles.ImportDialog  open classes={{ paper: "Container" }}>
    <Styles.CloseIcon name="close" onClick={props.onDialogClose}/>

    <DialogContent classes={{ root: "Content" }}>
      {
        hasStarted
          ? <RecipientsImportPanel close={props.onDialogClose} refetchRecipientList={props.refetchRecipientList}/>
          : <RecipientImportIntroduction onStartButtonClick={() => setStarted(true)} />
      }
    </DialogContent>
  </Styles.ImportDialog>
}

export interface RecipientsImportDialogProps {
  onDialogClose: () => void,

  refetchRecipientList: () => void,
}
