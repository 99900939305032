import { ApolloError } from "@apollo/client"
import * as React from "react"
import { t } from "ttag"
import { useTheme } from "styled-components"

import config from "config/business"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { Caption } from "@humanpredictiveintelligence/myqvt-library"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { ErroredMetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { PagedNavigation } from "@humanpredictiveintelligence/myqvt-library"
import { Select, SelectOptionProps } from "@humanpredictiveintelligence/myqvt-library"
import { DOMElementOuterHeight } from "utilities/helpers"
import { ContextualizedCommentsTableRowProps } from "features/Comments/ContextualizedCommentsTableRow"
import { Comment, CommentAttribute, CommentReplies } from "models/Comment"
import { Processing_Status as ProcessingStatus } from "models/generated"
import { SkeletonWrapper } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./UnprocessedRecipientComments.styles"

export const UnprocessedRecipientComments: React.FC<UnprocessedRecipientCommentsProps> = (props) => {
  const theme = useTheme()

  const moduleTitle = t`Comments to confirm or awaiting response`

  const [ pagingInfo, setPagingInfo ] = React.useState({
    currentPage: 1,
    pageSize: config.homepage.unprocessedComments.pageSizes[0],
  })
  const [ filters, setFilters ] = React.useState<ProcessingStatus[]>([])
  const [ loadedComments, setLoadedComments ] = React.useState<UnprocessedRecipientCommentsObject>()
  const { onPagedNavigationSizeChange } = props

  React.useEffect(() => {
    if (props.unprocessedComments) {
      setLoadedComments(props.unprocessedComments)
    }
  }, [ props.unprocessedComments ])

  const pageComments = loadedComments?.recipientComments ? {
    comments: loadedComments?.recipientComments,
    isGlobalComments: props.isGlobalComments,
  } : { comments: [] }

  const pagedNavigationRef = React.createRef<HTMLDivElement>()
  const pagesCount = Math.ceil(
    (loadedComments?.recipientCommentsWithCurrentFiltersCount ?? 0) / pagingInfo.pageSize,
  ) || 1 // We don't want 0 pages

  React.useEffect(() => {
    // We communicate the PagedNavigation height to the parent so they can use it
    // to adapt other components, because it is designed to float under the content
    if (pagedNavigationRef.current) {
      const height = DOMElementOuterHeight(pagedNavigationRef.current)
      onPagedNavigationSizeChange?.(height)
    }
  }, [ pagedNavigationRef, onPagedNavigationSizeChange ])

  React.useEffect(() => {
    props.onPaginationChange?.(pagingInfo.currentPage, pagingInfo.pageSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pagingInfo ])

  /* Calculate the height of the card depending on the number of items per page
   * We use it to reduce resizings an animate transitions
   * 172 is the height of all content without comments
   * 112 is the height of one comment line
   * -1 because the last comment doesn't have the 1px-width border-bottom
   */
  const cardHeight = 172 + (112 * (loadedComments?.recipientComments?.length ?? 0)) - 1

  const hasNoComments = loadedComments && loadedComments.recipientComments.length === 0

  return (
    <div className={props.className}>
      {!loadedComments && props.errors && (
        <ErroredMetricsCard title={moduleTitle}>
          {t`An error has occurred.`}
        </ErroredMetricsCard>
      )}
      {!props.errors && (
        <>
          <Styles.Card
            title={moduleTitle}
            hasReducedPadding
            $minHeight={cardHeight}
          >
            <Styles.Content>
              <Caption size="big">{t`Filter on:`}</Caption>
              <SkeletonWrapper height={20} width={400} isLoading={props.areCommentsLoading}>
                {loadedComments && (
                  <Styles.FilterPills
                    isEmptySelectionAllowed
                    values={filters}
                    onChange={updateFilters}
                    options={[
                      {
                        labelRender: (isSelected) => filterPill(
                          ProcessingStatus.Draft,
                          loadedComments?.recipientCommentsWithDraftedAnswerCount,
                          isSelected,
                        ),
                        value: ProcessingStatus.Draft,
                      },
                      {
                        labelRender: (isSelected) => filterPill(
                          ProcessingStatus.Awaiting,
                          loadedComments?.recipientCommentsAwaitingAnswerCount,
                          isSelected,
                        ),
                        value: ProcessingStatus.Awaiting,
                      },
                    ]}
                  />
                )}
              </SkeletonWrapper>
              {hasNoComments && (
                <Styles.NoContentMessage>
                  <Icon name="comment" size={48}/>
                  <p>{t`We don't have anything to show you right now.`}</p>
                </Styles.NoContentMessage>
              )}
              {(props.areCommentsLoading || !hasNoComments) && (
                <Styles.CommentsTable
                  isLoading={props.areCommentsLoading}
                  comments={pageComments}
                  onSaved={props.onCommentsSaved}
                />
              )}
            </Styles.Content>
          </Styles.Card>
          <Styles.Pagination ref={pagedNavigationRef}>
            <Select
              defaultItem={false}
              onChange={updatePageSize}
              options={config.homepage.unprocessedComments.pageSizes.map(pageSize => ({
                value: pageSize,
                wording: pageSize.toString(),
              }))}
              value={pagingInfo.pageSize}
              aria-label="recipients-comments-pagination"
            />
            <PagedNavigation
              isLoadingData={props.areCommentsLoading}
              numberOfPages={pagesCount}
              onNavigate={updateCurrentPage}
              page={pagingInfo.currentPage}
            />
          </Styles.Pagination>
        </>
      )}
    </div>
  )

  /**
   * Update the page size in the paging info
   */
  function updatePageSize(option: SelectOptionProps | undefined) {
    const newPageSize = option?.value as number
    const newPagesCount = (loadedComments?.recipientCommentsWithCurrentFiltersCount ?? 0) / newPageSize

    setPagingInfo((currentPagingInfo) => ({
      currentPage: Math.ceil(newPagesCount * (currentPagingInfo.currentPage / pagesCount)),
      pageSize: newPageSize,
    }))
  }

  /**
   * Update the current page in the paging info
   */
  function updateCurrentPage(newPage: number) {
    setPagingInfo(currentPagingInfo => ({
      ...currentPagingInfo,
      currentPage: newPage,
    }))
  }

  /**
   * Update the filter state
   */
  function updateFilters(filters: string[]) {
    setFilters(filters as ProcessingStatus[])
    props.onFiltersChange?.(
      filters.length === 0
        ? [ ProcessingStatus.Awaiting, ProcessingStatus.Draft ]
        : filters as ProcessingStatus[],
    )
    updateCurrentPage(1)
  }

  /** Pill with an icon to represent a filter */
  function filterPill(filter: ProcessingStatus, count: number, isSelected: boolean) {
    let label = t`Awaiting answer`
    let icon = "create"
    let color = theme.colors.blackMedium

    if (filter === ProcessingStatus.Draft) {
      label = t`Draft`
      icon = "autorenew"
      color = theme.colors.secondary
    }
    if (isSelected) {
      color = theme.colors.white
    }

    return <Styles.FilterPillIcon name={icon} size={16} color={color}>{`${label} (${count})`}</Styles.FilterPillIcon>
  }
}

export type UnprocessedRecipientCommentsObject = {
  recipientComments: Array<ContextualizedCommentsTableRowProps["comment"]
    & CommentAttribute
    & CommentReplies<Comment>>,
  recipientCommentsAwaitingAnswerCount: number,
  recipientCommentsWithCurrentFiltersCount: number,
  recipientCommentsWithDraftedAnswerCount: number,
}

export interface UnprocessedRecipientCommentsProps extends BaseProps {
  unprocessedComments?: UnprocessedRecipientCommentsObject,
  /** Fired when the height of the paged navigation height change */
  onPagedNavigationSizeChange?: (height: number) => void,

  /**  Fired when the table pagination change */
  onPaginationChange?: (currentPage: number, pageSize: number) => void,

  /** Fired when the filters change */
  onFiltersChange?: (filters: ProcessingStatus[]) => void,

  /** Fired on comment saved (drafter or published) */
  onCommentsSaved?: () => void,

  /** Whether to render the component's squeleton */
  areCommentsLoading?: boolean,

  /** Whether to show the redirection button */
  isGlobalComments?: boolean,

  /** shows an error message if not undefined  */
  errors?: ApolloError,
}

