import * as React from "react"
import {
  SkeletonWrapper,
  withSkeleton,
} from "@humanpredictiveintelligence/myqvt-library"
import { CustomerContact } from "models/generated"

import * as Styles from "./CustomerContactCard.styles"

const CustomerContactSkeleton: React.FC = () => {
  return (
    <Styles.Card>
      <SkeletonWrapper width={100} height={100} isLoading circle/>
      <SkeletonWrapper width={80} height={21} isLoading />
      <SkeletonWrapper width={160} height={21} isLoading />
    </Styles.Card>
  )
}

export const CustomerContactComponent: React.FC<ReferrerCardProps> = ({ customerContact }) => {
  return (
    <Styles.Card>
      <Styles.Image src={customerContact.imageUrl}/>
      <Styles.Name>{customerContact.identity}</Styles.Name>
      <Styles.Mail href={"mailto:" + customerContact.email}>{customerContact.email}</Styles.Mail>
    </Styles.Card>
  )
}

export const CustomerContactCard = withSkeleton(
  CustomerContactComponent,
  CustomerContactSkeleton,
)

interface ReferrerCardProps {
  customerContact: CustomerContact,
}
