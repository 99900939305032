import React from "react"
import * as Styles from "./TutorialPopup.styles"
import { TutorialPopupProps } from "./TutorialPopup"

const TutorialPopupActions: React.FC<TutorialPopupActionsProps> = (props) => {
  const shouldHideButtons = props.hideSkipButton && props.hideNextButton

  if (shouldHideButtons) {
    return null
  }

  return (
    <Styles.Actions $callToActionPosition={props.callToActionPosition ?? "center"}>
      {!props.hideSkipButton && (
        <Styles.SkipButton
          as="span"
          {...props.skipProps}
        >
          {props.skipProps.title}
        </Styles.SkipButton>
      )}

      {!props.hideNextButton && (
        <Styles.CallToAction
          {...props.primaryProps}
          disabled={props.disableNextButton}
        >
          {props.primaryProps.title}
        </Styles.CallToAction>
      )}
    </Styles.Actions>
  )
}

type TutorialPopupActionsProps = Pick<TutorialPopupProps,
  "skipProps" | "primaryProps" | "disableNextButton" | "hideNextButton" | "hideSkipButton" | "callToActionPosition">

export default TutorialPopupActions
