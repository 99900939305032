import styled from "styled-components/macro"

export const DiscussionsModules = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 120px 1fr;
  grid-gap: 16px;
`

export const DiscussionsContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 2rem;
`
