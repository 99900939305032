import styled, { css } from "styled-components/macro"

import { Card } from "@humanpredictiveintelligence/myqvt-library"
import { Collapsible } from "@humanpredictiveintelligence/myqvt-library"

export const Form = styled.div<{ $isLoading?: boolean }>`
  width: 100%;
  
  ${({ $isLoading }) => $isLoading && css`
    ${Body} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`

export const Header = styled(Card)`
  display: grid;
  grid-template-columns: repeat(3, calc(33.333% - 48px / 3));
  grid-gap: 24px;
  padding: 24px 52px;
  background-color:white;
  overflow-y: visible;
  height: auto;
`

export const Body = styled(Card)`
  min-height: 420px;
  height: auto;
  margin: 16px 0;
  padding: 24px 52px;
`

export const Question = {
  Choice: styled.div`
    margin: 8px 0;
  `,
  Choices: styled(Collapsible)`
    margin-bottom: 16px;
  `,
  Wording: styled.div`
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  `,
}

export const FormWarnings = styled.div`
  grid-column: span 3;

  &:not(:empty) {
    margin-top: 32px;
  }

  & > *:not(:first-child) {
    margin-top: 8px;
  }
`

export const FormActions = styled.div`
  text-align: right;

  & > * {
    margin: 0 8px;
  }
`

export const UserGroups = styled.div`
  display: flex;
  flex-direction:row;
  gap: 1rem;
  flex-wrap: wrap;
  grid-column: span 3;
`

export const UserGroup = styled.div`
  display: flex;
  align-items: center;
  gap: .6rem;
  padding: 5px 8px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.colors.blackUltraLight};
  transition: background-color .2s ease-in-out;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    color: white;
  }
`

export const ManualPublicationContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ManualPublicationContent = styled.div<ManualPublicationProps>`
  display: flex;
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.blackMedium};

  ${({ $isChecked }) => $isChecked && css`
      color: ${({ theme }) => theme.colors.primary};
  `}
`

export const Title = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;
`

export interface ManualPublicationProps {
  $isChecked: boolean,
}
