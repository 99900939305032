import React from "react"
import { t } from "ttag"
import { ApolloError } from "@apollo/client/errors"

import { NotificationContext } from "features/Notification"
import { useSession } from "features/Session"
import { AdminChoiceSelectionDialog } from "../../AdminCreationProcess/AdminProcessSelectionDialog"
import { AdminCreationFormDialog } from "../AdminCreationFormDialog"
import { EmailVerificationDialog } from "../EmailVerificationDialog"
import { EmailState, EmailStatus } from "../../useEmailVerification"
import { RecipientsSelectionDialog } from "../../AdminCreationProcess/RecipientsSelectionDialog"
import { Admin, UpsertAdminInput, isUpdateAdminInput } from "../../models"
import { UserPermissionCode } from "models/generated"
import { SkinnyUser, SkinnyUserCharacteristic } from "models/SkinnyUser"

import { useAddUserMutation } from "graphql/mutations/generated/AddUser"
import { useUpdateUserMutation } from "graphql/mutations/generated/UpdateUser"

export enum Step {
  Choice,
  Email,
  Form,
  List
}

export const AdminCreationProcessContainer: React.FC<AdminCreationProcessContainerProps> = props => {
  const session = useSession()
  const notification = React.useContext(NotificationContext)

  const [ email, setEmail ] = React.useState("")
  const [ existingRecipient, setExistingRecipient ] = React.useState<Admin | SkinnyUser | undefined>()
  const [ isAdminBeingAdded, setIsAdminBeingAdded ] = React.useState(false)

  const [ addUser ] = useAddUserMutation()
  const [ updateUser ] = useUpdateUserMutation()

  const [ step, setStep ] = React.useState(Step.Choice)

  return (
    <>
      <AdminChoiceSelectionDialog
        isOpen={step === Step.Choice}
        onStepSelection={(step) => setStep(step)}
        onClose={props.onAdminCreationProcessStop}
      />

      <EmailVerificationDialog
        isOpen={step === Step.Email}
        onBack={() => setStep(Step.Choice)}
        onVerificationDone={onVerificationDone}
      />

      <AdminCreationFormDialog
        isOpen={step === Step.Form}
        isLoading={isAdminBeingAdded}
        defaultRecipient={existingRecipient}
        defaultEmail={email}
        onValidate={upsertAdministrator}
        onBackButtonClicked={() => setStep(Step.Email)}
        onCancel={() => props.onAdminCreationProcessStop?.()}
      />

      <RecipientsSelectionDialog
        isOpen={step === Step.List}
        onDialogClose={() => props.onAdminCreationProcessStop?.()}
      />
    </>
  )

  function onVerificationDone(emailStatus: EmailStatus) {
    /** Reset verification state */
    setExistingRecipient(undefined)
    setEmail("")

    switch (emailStatus.state) {
      case EmailState.FromRecipient:
        setExistingRecipient(emailStatus.recipient)
        break
      case EmailState.Free:
        setEmail(emailStatus.email)
        break
    }

    setStep(Step.Form)
  }

  /**
   * Create a new user or edit an existing recipient in the backend and enable his permissions
   * @param userData Administrator to create
   */
  async function upsertAdministrator(userData: UpsertAdminInput) {
    setIsAdminBeingAdded(true)

    try {
      if (isUpdateAdminInput(userData)) {
        await updateUser({
          variables: {
            email: userData[SkinnyUserCharacteristic.email],
            firstname: userData[SkinnyUserCharacteristic.firstname],
            language: userData.language?.code,
            lastname: userData[SkinnyUserCharacteristic.lastname],
            permissions: [ { code: UserPermissionCode.BackofficeView, "isGranted": true } ],
            phoneNumber: session.customer!.canSendSMS
              ? userData[SkinnyUserCharacteristic.phone]
              : undefined,
            timezone: userData.timezone,
            userId: userData.id,
          },
        })

        notification.show(t`Success`, t`The user has been successfully updated`)
      }
      else {
        await addUser({
          variables: {
            email: userData[SkinnyUserCharacteristic.email],
            firstname: userData[SkinnyUserCharacteristic.firstname],
            language: userData.language?.code,
            lastname: userData[SkinnyUserCharacteristic.lastname],
            permissions: [ { code: UserPermissionCode.BackofficeView, "isGranted": true } ],
            phoneNumber: userData[SkinnyUserCharacteristic.phone],
            timezone: userData.timezone ?? "",
          },
        })

        notification.show(t`Success`, t`The administrator has been successfully added`)
      }

      props.onAdminCreationProcessDone?.()
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }

    setIsAdminBeingAdded(false)
  }
}

interface AdminCreationProcessContainerProps {
  onAdminCreationProcessStop?: () => void,
  onAdminCreationProcessDone?: () => void,
}
