import React from "react"

import { PermissionsProvider, usePermissions } from "features/Permissions/PermissionsContext"
import { useUncommittedChanges } from "features/UncommitedChanges"
import * as Styles from "./PermissionsPage.styles"

const PermissionsPageContainer = () => {
  const { fetchAdmins, hasChanges } = usePermissions()
  const { setUncommittedChange, removeUncommittedChange } = useUncommittedChanges<boolean>("has_permissions_changes")

  React.useEffect(() => {
    fetchAdmins()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (hasChanges) {
      setUncommittedChange(hasChanges)
    }
    else {
      removeUncommittedChange()
    }
  }, [ hasChanges, removeUncommittedChange, setUncommittedChange ])

  return (
    <Styles.Container>
      <Styles.AdminsSectionContainer/>
      <Styles.PermissionsSectionContainer />
    </Styles.Container>
  )
}

export const PermissionsPage = () => <PermissionsProvider><PermissionsPageContainer/></PermissionsProvider>
