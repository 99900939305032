import { DialogTitle } from "@material-ui/core"
import moment from "moment"
import * as React from "react"
import { jt, t } from "ttag"

import illustrationConsulting from "assets/images/illustration_consulting.png"
import { useUserPermissions } from "features/Permissions"
import { BaseProps, Countdown, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { UserPermissionCode } from "models/generated"
import * as Styles from "./ScheduledSurveyResultsPublishingStatus.styles"

/**
 * Displays info about the publish status of the survey.
 * Has 2 main display config : Published, and unpublished
 */
export const ScheduledSurveyResultsPublishingStatus: React.FC<ScheduledSurveyResultsPublishingStatusProps> = props => {
  const isUserAllowed = useUserPermissions()

  const isAllowedToPublishNow = isUserAllowed(UserPermissionCode.ResultsPublish)
  const isPublished = props.publishingDate && moment().isSameOrAfter(props.publishingDate, "minute")

  const formattedPublishingDate = moment(props.publishingDate).format("LLLL")

  const [ isConfirmationModalOpen, setConfirmationModalOpen ] = React.useState(false)

  return (
    <Styles.Container className={props.className}>
      {publishActionConfirmationDialog()}
      {!isPublished && (
        <>
          {props.isManualPublication ? (
            <Styles.CountdownSection>
              <Styles.Hint $isPublished>
                {t`The manual publication option has been activated for this scheduled survey. 
                      In order to make the results available to recipients, you must use the "Publish now" button.`}
              </Styles.Hint>
            </Styles.CountdownSection>
          ) : (
            <>
              {props.publishingDate ? (
                <Styles.CountdownSection>
                  <Countdown
                    date={props.publishingDate}
                    label={t`Automatic publishing in:`}
                    onTimeout={props.onTimeout}
                  />
                  <Styles.Hint>
                    {t`The results will be automatically published when the next scheduled survey starts`}
                  </Styles.Hint>
                </Styles.CountdownSection>
              ) : (
                <Styles.Hint $hasNoPublishingDate>
                  {
                    t`There is no upcoming scheduled survey, you must manually publish the results to make them 
                      accessible to users.`
                  }
                </Styles.Hint>
              )}
            </>
          )}

          <Styles.PublishButton
            height="big"
            onClick={() => setConfirmationModalOpen(true)}
            isAllowed={isAllowedToPublishNow}
            tooltip={!isAllowedToPublishNow ? t`You don't have sufficient rights to perform this action` : undefined}
            tooltipPlacement={"top"}
          >
            {t`Publish now`}
          </Styles.PublishButton>
        </>
      )}
      {isPublished && (
        <Styles.Hint $isPublished>
          {// eslint-disable-next-line max-len
            jt`The results have been published on ${formattedPublishingDate}, you can still answer comments and add feedbacks.`}
        </Styles.Hint>
      )}
    </Styles.Container>
  )

  function publishResults() {
    setConfirmationModalOpen(false)
    props.onPublish()
  }

  function publishActionConfirmationDialog() {
    return (
      <Styles.PublishDialog
        fullWidth
        maxWidth="md"
        open={isConfirmationModalOpen}
        classes={{ paper: "PublishDialog" }}
      >
        <DialogTitle disableTypography>
          {t`Results publishing`}
        </DialogTitle>
        <Styles.PublishDialogContent>
          {t`You are about to grant access to results to all users: `}
          <Styles.PublishDialogRecipientsCount>
            {t`${props.numberOfRecipients} users`}
          </Styles.PublishDialogRecipientsCount>
          <br/>
          {t`Only feedbacks with status "Ready for publishing" will be published`}
        </Styles.PublishDialogContent>
        <Styles.PublishDialogFooter>
          <PrimaryButton
            isInverted
            onClick={() => setConfirmationModalOpen(false)}
          >
            {t`Cancel`}
          </PrimaryButton>
          <PrimaryButton
            onClick={publishResults}
          >
            {t`Publish`}
          </PrimaryButton>
        </Styles.PublishDialogFooter>
        <Styles.PublishDialogIllustration src={illustrationConsulting} alt=""/>
      </Styles.PublishDialog>
    )
  }
}

interface ScheduledSurveyResultsPublishingStatusProps extends BaseProps {
  /** The given date of publication of the results */
  publishingDate: string | undefined,

  /** Trigger once the timer reaches 0 */
  onTimeout: () => void,

  /** Trigger when the user validates the publishing */
  onPublish: () => void,

  /** The number of recipients to display in the confirmation dialog */
  numberOfRecipients: number,

  /** Boolean to know if done survey requires manual publication */
  isManualPublication: boolean,
}
