import { PrimaryButton, SecondaryButton } from "@humanpredictiveintelligence/myqvt-library"
import * as React from "react"
import { t } from "ttag"

import { UserGroup } from "features/UserGroups/groups.model"
import * as Styles from "../UserGroupDialog.styles"

export const DeleteGroupDialog: React.FC<DeleteGroupDialogProps> = (props) => {
  return (
    <Styles.Container
      classes={{ paper: "Container" }}
      open={props.isOpen}
    >
      <Styles.Header>{t`Delete the group`}</Styles.Header>
      <Styles.Actions>
        <SecondaryButton onClick={props.onCancel}>{t`Cancel`}</SecondaryButton>
        <PrimaryButton
          onClick={() => onValidate()}
          isLoading={props.isLoading}
          destructive
        >
          {t`Confirm`}
        </PrimaryButton>
      </Styles.Actions>
    </Styles.Container>
  )

  function onValidate() {
    props.onDelete({
      ...props.defaultGroup,
    })
  }
}

export interface DeleteGroupDialogProps {
  isOpen: boolean,
  isLoading: boolean,
  onDelete: (group: UserGroup) => void,
  onCancel: () => void,
  defaultGroup: UserGroup,
}
