import * as React from "react"
import moment from "moment"

import { useSession } from "features/Session"
import { PatchNotesList } from "features/PatchNotes"

import { useGetPatchnoteListLazyQuery } from "graphql/queries/generated/GetPatchnoteList"

export const PatchNoteProvider: React.FC = () => {
  const session = useSession()
  const [ isOpen, setIsOpen ] = React.useState(false)

  const [ loadPatchNotes, { data: patchNotesData, loading } ] = useGetPatchnoteListLazyQuery({
    variables: {
      limit: 1,
    },
  })

  React.useEffect(() => {
    if (session.user && !patchNotesData) {
      loadPatchNotes()
    }
  }, [ session.user, loadPatchNotes, patchNotesData ])

  React.useEffect(() => {
    if (session.user && patchNotesData && patchNotesData.getPatchnoteList.length > 0) {
      const publishedAt = moment(patchNotesData?.getPatchnoteList[0].publishedAt).utc(true)
      const lastLoginAt = moment(session.user?.lastLoginAt?.local).utc(true)
      const currentDate = moment()

      /** It set the dialog open if the last login is before the publishedAt date
       **  and the publication date is in the past.
       */
      setIsOpen(publishedAt.isAfter(lastLoginAt) && publishedAt.isBefore(currentDate))
    }
  }, [ patchNotesData, session.user ])

  return (
    <PatchNotesList
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      isLoading={loading}
      patchNotes={patchNotesData?.getPatchnoteList}
    />
  )
}
