/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserAttributeValueFragmentFragmentDoc } from '../../../models/generated/SkinnyUserAttributeValueFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserAttributesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserAttributesQuery = { attributeValues: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }> };


export const UserAttributesDocument = gql`
    query UserAttributes {
  attributeValues: attributes {
    ...SkinnyUserAttributeValueFragment
  }
}
    ${SkinnyUserAttributeValueFragmentFragmentDoc}`;

/**
 * __useUserAttributesQuery__
 *
 * To run a query within a React component, call `useUserAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAttributesQuery(baseOptions?: Apollo.QueryHookOptions<UserAttributesQuery, UserAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAttributesQuery, UserAttributesQueryVariables>(UserAttributesDocument, options);
      }
export function useUserAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAttributesQuery, UserAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAttributesQuery, UserAttributesQueryVariables>(UserAttributesDocument, options);
        }
export type UserAttributesQueryHookResult = ReturnType<typeof useUserAttributesQuery>;
export type UserAttributesLazyQueryHookResult = ReturnType<typeof useUserAttributesLazyQuery>;
export type UserAttributesQueryResult = Apollo.QueryResult<UserAttributesQuery, UserAttributesQueryVariables>;