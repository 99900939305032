/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportSatisfactionQueryVariables = Types.Exact<{
  scheduledSurveyId: Types.Scalars['Int'];
  language?: Types.InputMaybe<Types.Supported_Language_Code>;
  groupAttributeId?: Types.InputMaybe<Types.Scalars['Int']>;
  attributeValueIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type ExportSatisfactionQuery = { exportSatisfaction: string };


export const ExportSatisfactionDocument = gql`
    query ExportSatisfaction($scheduledSurveyId: Int!, $language: Supported_language_code, $groupAttributeId: Int, $attributeValueIds: [Int!]!) {
  exportSatisfaction: export_satisfaction(scheduled_survey_id: $scheduledSurveyId, language: $language, group_attribute_id: $groupAttributeId, attribute_value_ids: $attributeValueIds)
}
    `;

/**
 * __useExportSatisfactionQuery__
 *
 * To run a query within a React component, call `useExportSatisfactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSatisfactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSatisfactionQuery({
 *   variables: {
 *      scheduledSurveyId: // value for 'scheduledSurveyId'
 *      language: // value for 'language'
 *      groupAttributeId: // value for 'groupAttributeId'
 *      attributeValueIds: // value for 'attributeValueIds'
 *   },
 * });
 */
export function useExportSatisfactionQuery(baseOptions: Apollo.QueryHookOptions<ExportSatisfactionQuery, ExportSatisfactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSatisfactionQuery, ExportSatisfactionQueryVariables>(ExportSatisfactionDocument, options);
      }
export function useExportSatisfactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSatisfactionQuery, ExportSatisfactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSatisfactionQuery, ExportSatisfactionQueryVariables>(ExportSatisfactionDocument, options);
        }
export type ExportSatisfactionQueryHookResult = ReturnType<typeof useExportSatisfactionQuery>;
export type ExportSatisfactionLazyQueryHookResult = ReturnType<typeof useExportSatisfactionLazyQuery>;
export type ExportSatisfactionQueryResult = Apollo.QueryResult<ExportSatisfactionQuery, ExportSatisfactionQueryVariables>;