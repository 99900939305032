import styled, { css } from "styled-components/macro"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { NotificationProps } from "features/Notification/Notification/Notification"

export const OuterContainer = styled.div`
  width: 400px;

  @media print {
    display: none;
  }
`

export const Container = styled.div<{ $variant: NotificationProps["variant"]}>`
  ${({ theme, $variant }) => css`
    pointer-events: auto;
    background-color: ${theme.colors.primaryLight};
    color: ${theme.colors.primaryDark};
    width: 400px;
    border-radius: ${theme.sizes.borderRadius};
    padding: 8px 24px;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: ${theme.shadows.notification};
    z-index: 1500;
    
    ${$variant === "danger" && css`
      background-color: ${theme.colors.danger};
      color: ${theme.colors.white};
      
      ${Action} {
        color: ${theme.colors.white};
      }
    `}
  `}
`

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 8px 0;
`

export const Content = styled.div`
  flex: 1;
  margin: 0 16px;
  min-height: 64px;
`

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-bottom: 8px;
`

export const Action = styled.span`
  align-self: flex-start;
  cursor: pointer;
  font-size: 16px;
`

export const CloseIcon = styled(Icon)`
  display: flex;
  align-items: flex-start;
`
