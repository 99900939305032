import { SkinnyUserAttributeValue } from "./SkinnyUserAttributeValue"

export interface AttributeValueProperties {
  id: number,
  label: string,
  attributeId: number,
  attributeLabel: string,
  parentValueId?: number,
  isClusterUnviolated?: boolean,
  usersCount?: number,
}

export class USER_ATTRIBUTE_VALUE implements AttributeValueProperties {
  static fromSkinnyUserAttributeValue(
    attributeValue: SkinnyUserAttributeValue,
    usersCount?: number,
  ): USER_ATTRIBUTE_VALUE {
    return new USER_ATTRIBUTE_VALUE({
      attributeId: attributeValue.attributeId,
      attributeLabel: attributeValue.name,
      id: attributeValue.valueId,
      label: attributeValue.value,
      parentValueId: attributeValue.parent?.valueId,
      usersCount: usersCount,
    })
  }

  constructor(properties: AttributeValueProperties) {
    this.id = properties.id
    this.label = properties.label
    this.attributeId = properties.attributeId
    this.attributeLabel = properties.attributeLabel
    this.parentValueId = properties.parentValueId
    this.isClusterUnviolated = properties.isClusterUnviolated
    this.usersCount = properties.usersCount
  }

  id: number
  label: string
  attributeId: number
  attributeLabel: string
  parentValueId?: number
  isClusterUnviolated?: boolean
  usersCount?: number
}
