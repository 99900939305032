import styled from "styled-components/macro"
import { CSSProperties } from "react"

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${(props) => props.justify};
  margin: 16px 0;

  & > * {
    margin-right: 1rem;
  }
`

interface ContainerProps {
  justify: CSSProperties["justifyContent"],
}
