import { TutorialEvent } from "features/Tutorial/TutorialContext"

export const RecipientsCreateInitialized = "recipients_create_initialized"

export const TUTORIAL_RECIPIENTS_EVENTS = {
  recipientsCreateInitialized:  (): TutorialEvent => {
    return {
      name: RecipientsCreateInitialized,
    }
  },
}
