/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportRankingQueryVariables = Types.Exact<{
  scheduledSurveyId: Types.Scalars['Int'];
  language?: Types.InputMaybe<Types.Supported_Language_Code>;
  groupAttributeId: Types.Scalars['Int'];
  attributeValueIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type ExportRankingQuery = { exportRanking: string };


export const ExportRankingDocument = gql`
    query ExportRanking($scheduledSurveyId: Int!, $language: Supported_language_code, $groupAttributeId: Int!, $attributeValueIds: [Int!]!) {
  exportRanking: export_ranking(scheduled_survey_id: $scheduledSurveyId, language: $language, group_attribute_id: $groupAttributeId, attribute_value_ids: $attributeValueIds)
}
    `;

/**
 * __useExportRankingQuery__
 *
 * To run a query within a React component, call `useExportRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportRankingQuery({
 *   variables: {
 *      scheduledSurveyId: // value for 'scheduledSurveyId'
 *      language: // value for 'language'
 *      groupAttributeId: // value for 'groupAttributeId'
 *      attributeValueIds: // value for 'attributeValueIds'
 *   },
 * });
 */
export function useExportRankingQuery(baseOptions: Apollo.QueryHookOptions<ExportRankingQuery, ExportRankingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportRankingQuery, ExportRankingQueryVariables>(ExportRankingDocument, options);
      }
export function useExportRankingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportRankingQuery, ExportRankingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportRankingQuery, ExportRankingQueryVariables>(ExportRankingDocument, options);
        }
export type ExportRankingQueryHookResult = ReturnType<typeof useExportRankingQuery>;
export type ExportRankingLazyQueryHookResult = ReturnType<typeof useExportRankingLazyQuery>;
export type ExportRankingQueryResult = Apollo.QueryResult<ExportRankingQuery, ExportRankingQueryVariables>;