/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnyCustomerFragmentFragment = { id: number, name: string, code_name: string, isClusterViolated: boolean, recipientsCount: number, timezone: string, canSendSMS: boolean, clusterSize: number, freemiumCreatorEmail?: string | undefined, paidPackages?: Array<string> | undefined, language: { code: string, localizedLabel: string, translatedLabel: string }, packages: Array<{ id: number, description: string, icon: string, label: string, isEnabled: boolean, codeName: Types.PackageCode }>, leds: Array<{ type: Types.CustomerLedType, phoneContact: { phones?: Array<{ phone: string }> | undefined } }>, customerContacts?: Array<{ nanoId: string, email: string, identity: string, imageUrl: string }> | undefined, psyCompany?: { psyDispositifs: Array<{ name: string, nanoId: string }> } | undefined };

export const SkinnyCustomerFragmentFragmentDoc = gql`
    fragment SkinnyCustomerFragment on Customer {
  id
  name
  code_name
  timezone: default_timezone
  language: default_language {
    code
    localizedLabel: localized_label
    translatedLabel: translated_label
  }
  canSendSMS: can_send_sms
  clusterSize: cluster_size
  isClusterViolated
  recipientsCount
  freemiumCreatorEmail: freemium_creator_email
  paidPackages: speakup_content
  packages {
    id
    codeName: code_name
    description
    icon
    label
    isEnabled
  }
  leds {
    type
    phoneContact {
      phones {
        phone
      }
    }
  }
  customerContacts {
    nanoId
    email
    identity
    imageUrl
  }
  psyCompany {
    psyDispositifs {
      name
      nanoId
    }
  }
}
    `;