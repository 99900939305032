/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnyScheduledReminderFragmentFragment = { remind_hours_before_end: number, is_editable: boolean };

export const SkinnyScheduledReminderFragmentFragmentDoc = gql`
    fragment SkinnyScheduledReminderFragment on Scheduled_reminder {
  remind_hours_before_end
  is_editable
}
    `;