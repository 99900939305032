import * as React from "react"
import { jt, t } from "ttag"
import UndrawLogin from "assets/images/undraw-login.png"

import business from "config/business"
import { CenteredLoader, Input, SecondaryButton } from "@humanpredictiveintelligence/myqvt-library"
import useForm from "react-hook-form"

import * as Styles from "./ChangePasswordRequestForm.styles"

export const ChangePasswordRequestForm: React.FC<ChangePasswordRequestFormProp> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitted, isSubmitting, isValid },
    errors,
  } = useForm<ChangePasswordRequestFields>({ mode: "onChange" })

  const [ email, setEmail ] = React.useState<string>("")

  const supportLink = <a href="mailto:devs@groupe-jlo.com" key="supportLink">devs@groupe-jlo.com</a>
  const selectedEmail = <strong>{email}</strong>

  return (
    <>
      <Styles.Form onSubmit={handleSubmit(submitForm)}>
        <Styles.Title>
          {t`Password reset`}
        </Styles.Title>
        {!isSubmitted && (
          <>
            <Styles.Content>
              <Input
                name="email"
                innerRef={register({
                  pattern: {
                    message: t`The email format is invalid`,
                    value: business.patterns.email,
                  },
                  required: true,
                })}
                label={t`email address`}
                isDisabled={isSubmitting}
                placeholder={t`Enter your email address`}
                hint={errors.email?.message}
                isErroneous={!!errors.email}
                isHintErroneous={!!errors.email}
              />
              <Styles.Instructions>
                {t`Enter the email address associated with your account. You will receive an email which will allow you
                 to reset your password.`}
              </Styles.Instructions>
            </Styles.Content>
            <CenteredLoader isTransparent isHidden={!isSubmitting}/>
            <Styles.SubmitAction
              submit
              disabled={!isValid}
            >
              {t`Reset my password`}
            </Styles.SubmitAction>
          </>
        )}

        {isSubmitted && (
          <>
            <Styles.Instructions>
              {jt`An email has been sent to ${selectedEmail} to reset your password.`}
            </Styles.Instructions>
            <Styles.UndrawLogin
              src={UndrawLogin}
              alt=""
            />
            <Styles.Instructions>
              {jt`If you do not receive this email, please contact ${supportLink}.`}
            </Styles.Instructions>
            <Styles.SubmitAction
              onClick={props.onGoLogin && props.onGoLogin}
            >
              {t`Sign in`}
            </Styles.SubmitAction>
          </>
        )}
      </Styles.Form>
      {props.onGoBack && !isSubmitted && (
        <SecondaryButton
          onClick={props.onGoBack}
        >
          {t`Back`}
        </SecondaryButton>
      )}
    </>
  )

  /**
   * Send the form content to the onSubmit method while managing the form submission state
   * @param values the values of the form
   */
  async function submitForm(values: ChangePasswordRequestFields) {
    if (isValid) {
      setEmail(values.email)
      props.onSubmit(values.email)
    }
  }
}

interface ChangePasswordRequestFormProp {
  /** The method called when the GoBack button is clicked */
  onGoBack?: () => void,

  /** The method called when the GoLogin button is clicked */
  onGoLogin?: () => void,

  /**
   * The method called when the form is submitted
   * @param email the email to send
   */
  onSubmit: (email: string) => void,
}

export type ChangePasswordRequestFields = {
  email: string,
}
