import React from "react"

export const UncommittedChangesContext = React.createContext<Map<string, unknown>>(new Map())

export const UncommittedChangesProvider: React.FC = ({ children }) => {
  const [ uncommittedChanges ] = React.useState(new Map())

  return (
    <UncommittedChangesContext.Provider value={uncommittedChanges}>
      {children}
    </UncommittedChangesContext.Provider>
  )
}
