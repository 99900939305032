import React from "react"
import { ErrorBoundary, Provider } from "@rollbar/react"

export const RollbarProvider: React.FC = (props) => {
  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    enabled: process.env.NODE_ENV === "production",
    environment: "prod",
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {props.children}
      </ErrorBoundary>
    </Provider>
  )
}
