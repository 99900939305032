import * as React from "react"
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from "@material-ui/core"
import { BackgroundColorProperty } from "csstype"

import { SelectProps } from "library/Select"
import { IconButton } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ActionableSelect.styles"

const ActionableSelect: React.FC<ActionableSelectProps> = (props) => {
  const ref = React.createRef<HTMLDivElement>()

  const IconComp = () => {
    const [ isMenuOpen, setIsMenuOpen ] = React.useState<boolean>(false)

    return (
      <Styles.ActionButton>
        <div ref={ref}>
          <IconButton
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            tooltip={props.actionButton.label}
            icon={props.actionButton.icon}
          />
        </div>
        <Popper
          open={isMenuOpen}
          anchorEl={ref.current}
          role="menu"
          transition
          disablePortal
          style={{ zIndex: 9000 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
                  <MenuList disablePadding>
                    {props.actions.map(action => (
                      <Styles.ListItem
                        key={action.label}
                        disabled={action.isDisabled}
                        onClick={action.onClick}
                        disableGutters
                      >
                        {action.label}
                        <Styles.ActionItem
                          iconWrapperClassName="ActionItemIconWrapper"
                          iconClassName="ActionItemIcon"
                          $backgroundColor={action.iconBackgroundColor ?? undefined}
                          icon={action.icon}
                        />
                      </Styles.ListItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Styles.ActionButton>
    )
  }

  return <Styles.ActionableSelect iconComponent={props.isActionable ? IconComp : undefined}  {...props} />
}

ActionableSelect.defaultProps = {
  isActionable: true,
}

export interface SelectAction {
  icon: string,

  iconBackgroundColor?: BackgroundColorProperty,

  /** Whether or not the action should appear as disabled */
  isDisabled?: boolean,

  label: string,

  onClick?: () => void,
}

export interface ActionableSelectProps extends SelectProps {
  /**
   * The action button which display actions list on click
   */
  actionButton: SelectAction,

  /**
   * List of actions which should appear in the dropdown
   */
  actions: SelectAction[],

  /**
   * True if the actions dropdown should appear
   * @default true
   */
  isActionable?: boolean,
}

export default ActionableSelect
