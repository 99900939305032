import { UserGroupsState } from "./state"
import { UserGroupsReducerAction } from "./actions"

export function userGroupsReducer(state: UserGroupsState, action: UserGroupsReducerAction) {
  switch (action.type) {
    case "setSelectedUsersIds":
      return {
        ...state,
        areUsersSelected: action.selectedUsersIds.length > 0,
        selectedUsersIds: action.selectedUsersIds,
      }
    case "setSelectedUsersInGroupIds":
      return {
        ...state,
        areUsersSelectedInGroup: action.selectedUserInGroupIds.length > 0,
        selectedUsersInGroupIds: action.selectedUserInGroupIds,
      }
    case "setSelectedGroupUuid":
      return {
        ...state,
        areUsersSelected: false,
        areUsersSelectedInGroup: false,
        selectedGroupUuid: action.selectedGroupUuid,
        selectedUsersIds: [],
        selectedUsersInGroupIds: [],
      }
    case "setShouldRefetchUsers":
      return {
        ...state,
        shouldRefetchUsers: action.shouldRefetchUsers,
      }
    case "setShouldGoToPreviousUserPage":
      return {
        ...state,
        shouldGoToPreviousUserPage: action.shouldGoToPreviousUserPage,
      }
    case "setShouldGoToPreviousUserGroupPage":
      return {
        ...state,
        shouldGoToPreviousUserGroupPage: action.shouldGoToPreviousUserGroupPage,
      }
    case "setDisplayedNumberOfUsers":
      return {
        ...state,
        displayedNumberOfUsers: action.displayedNumberOfUsers,
      }
    case "setDisplayedNumberOfUsersInGroup":
      return {
        ...state,
        displayedNumberOfUsersInGroup: action.displayedNumberOfUsersInGroup,
      }
  }
}
