import React from "react"
import { t } from "ttag"
import { ApolloError } from "@apollo/client/errors"

import { ScheduledSurveyForm } from "../ScheduledSurveyForm"
import { NotificationContext } from "features/Notification"
import { SurveyScheduling } from "models/ScheduledSurveyCreation"
import { SCHEDULED_SURVEY_SERVICE } from "services/ScheduledSurveyService"
import { ScheduledSurvey } from "models/Scheduler"

import { useAddScheduledSurveyMutation } from "graphql/mutations/generated/AddScheduledSurvey"
import { useDeleteScheduledSurveyMutation } from "graphql/mutations/generated/DeleteScheduledSurvey"
import { useEditScheduledSurveyMutation } from "graphql/mutations/generated/EditScheduledSurvey"
import { useOngoingAndTodoScheduledSurveysQuery } from "graphql/queries/generated/OngoingAndTodoScheduledSurveys"
import { useSurveysOfThemeLazyQuery } from "graphql/queries/generated/SurveysOfTheme"
import { useSurveyQuestionsLazyQuery } from "graphql/queries/generated/SurveyQuestions"
import { useThemesLazyQuery } from "graphql/queries/generated/Themes"
import { ScheduledReminder } from "features/ScheduledReminder/models"

export const SurveyScheduler: React.FC<SurveySchedulerProps> = (props) => {
  const notification = React.useContext(NotificationContext)

  const { loading: areDatesLoading, data: ongoingAndTodoScheduledSurveys, refetch: refetchDates }
    = useOngoingAndTodoScheduledSurveysQuery({
      variables: {
        limit: 365,
        skipOngoing: 0,
        skipTodo: 0,
      }, 
    })

  const [ loadThemes, { data: themesData, loading: isLoadingThemes } ]
    = useThemesLazyQuery({ variables: { limit: 100 } })

  const [ loadThemeSurveys, { data: themeSurveysData, loading: isLoadingSurveys } ]
    = useSurveysOfThemeLazyQuery()

  const [ loadSurveyQuestions, { data: surveyQuestionsData, loading: isLoadingQuestions } ]
    = useSurveyQuestionsLazyQuery()

  /** All days on which there is a scheduled survey */
  const daysWithScheduledSurveys = SCHEDULED_SURVEY_SERVICE.allParticipationDates([
    ...ongoingAndTodoScheduledSurveys?.ongoingScheduledSurveys || [],
    ...ongoingAndTodoScheduledSurveys?.todoScheduledSurveys || [],
  ].filter(scheduledSurvey => (
    // Filter the current survey out of the list of dates
    scheduledSurvey.scheduledId !== props.scheduledSurvey?.scheduledId
  )),
  )

  const [ commitScheduledSurveyCreation ] = useAddScheduledSurveyMutation()
  const [ commitScheduledSurveyUpdate ] = useEditScheduledSurveyMutation()
  const [ commitScheduledSurveyDeletion ] = useDeleteScheduledSurveyMutation()

  React.useEffect(() => {
    if (props.editingMode === "edit") {
      loadThemes()

      // Load surveys only in edit mode to delay that loading as long as possible
      if (props.scheduledSurvey) {
        loadThemeSurveys({ variables: { themeId: props.scheduledSurvey.survey.theme.id } })
      }
    }

    if (props.scheduledSurvey) {
      loadSurveyQuestions({ variables: { surveyId: props.scheduledSurvey.survey.id } })
    }
  }, [ props.editingMode, props.scheduledSurvey, loadThemes, loadThemeSurveys, loadSurveyQuestions ])

  /* This is to directly load the questions of the selected survey when
   * the parent provides us with a ScheduledSurvey. Otherwise, we would wait for the
   * user to make a change in the survey select control to load them.
   */
  React.useEffect(() => {
    if (props.scheduledSurvey) {
      loadSurveyQuestions({ variables: { surveyId: props.scheduledSurvey.survey.id } })
    }
  }, [ themeSurveysData, props.scheduledSurvey, loadSurveyQuestions ])

  return (
    <ScheduledSurveyForm
      scheduledSurvey={props.scheduledSurvey}
      availableThemes={themesData?.themes}
      isLoadingThemes={isLoadingThemes}
      availableSurveys={themeSurveysData?.theme.surveys}
      isLoadingSurveys={isLoadingSurveys}
      surveyQuestions={surveyQuestionsData?.survey.questions}
      isLoadingQuestions={isLoadingQuestions}
      areDatesLoading={areDatesLoading}
      daysWithScheduledSurveys={daysWithScheduledSurveys}
      isReadonly={props.editingMode === "read"}
      editingMode={props.editingMode}
      onThemeSelected={(themeId) => themeId !== undefined && loadThemeSurveys({ variables: { themeId } })}
      onSurveySelected={(surveyId) => surveyId !== undefined && loadSurveyQuestions({ variables: { surveyId } })}
      onScheduledRemindersChange={saveScheduledReminders}
      onSave={saveScheduling}
      onDeleteScheduledSurvey={deleteScheduledSurvey}
      onSwitchToEdit={() => props.onChangeEditingMode("edit")}
      onCancelEdit={() => props.onChangeEditingMode("read")}
    />
  )

  /**
   * Save the scheduled reminders only when this is an update and if the scheduler is not in "edit" mode
   * Create operation will has its own logic for creating scheduled reminders
   * @param changedScheduledReminders
   */
  function saveScheduledReminders(changedScheduledReminders: ScheduledReminder[]) {
    if (props.scheduledSurvey && changedScheduledReminders.length > 0 && props.editingMode !== "edit") {
      updateScheduledReminders(changedScheduledReminders)
    }
  }

  /**
   * Create or update a scheduled survey, depending on whether it exists or not
   * @param surveyScheduling Scheduling information to save
   */
  function saveScheduling(surveyScheduling: SurveyScheduling) {
    if (props.scheduledSurvey) {
      updateScheduledSurvey(surveyScheduling)
    }
    else {
      createScheduledSurvey(surveyScheduling)
    }
  }

  /**
   * Create a new ScheduledSurvey
   * @param newSurveyScheduling ScheduledSurvey to save
   */
  async function createScheduledSurvey(newSurveyScheduling: SurveyScheduling) {
    try {
      const { data } = await commitScheduledSurveyCreation({
        // Remove timezone info from the begin and end dates because the
        // backend needs to treat them as local dates
        variables: {
          beginAt: newSurveyScheduling.beginAt.clone().utcOffset(0, true).toISOString(),
          endAt: newSurveyScheduling.endAt.clone().utcOffset(0, true).toISOString(),
          isManualPublication: newSurveyScheduling.isManualPublication,
          scheduledReminders: getRemindersValueFromScheduledReminders(newSurveyScheduling.scheduledReminders ?? []),
          surveyId: newSurveyScheduling.surveyId,
          userGroupsUuids: newSurveyScheduling.userGroupUuids,
        },
      })

      const scheduledSurveyId = data?.addScheduledSurvey.id

      notification.show(
        t`Success`,
        t`The survey has successfully been scheduled.`,
        "success",
      )
      props.onScheduledSurveyCreated?.(scheduledSurveyId!)
      refetchDates()
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
      }
    }
  }

  /**
   * Update the ScheduledReminders of the current ScheduledSurvey
   */
  async function updateScheduledReminders(scheduledReminders: ScheduledReminder[]) {
    if (!props.scheduledSurvey) {
      throw new Error("Failed to update scheduled survey")
    }

    try {
      await commitScheduledSurveyUpdate({
        // Remove timezone info from the begin and end dates because the
        // backend needs to treat them as local dates
        variables: {
          scheduledReminders: getRemindersValueFromScheduledReminders(scheduledReminders),
          scheduledSurveyId: props.scheduledSurvey.scheduledId,
        },
      })

      notification.show(
        t`Success`,
        t`The scheduled reminders attached to the current scheduled survey have successfully been edited.`,
        "success",
      )
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
      }
    }
    props.onScheduledSurveyEdited?.()
  }

  /**
   * Update a ScheduledSurvey
   * @param surveyScheduling ScheduledSurvey to update
   */
  async function updateScheduledSurvey(surveyScheduling: SurveyScheduling) {
    if (!props.scheduledSurvey) {
      throw new Error("Failed to update scheduled survey")
    }

    try {
      await commitScheduledSurveyUpdate({
        // Remove timezone info from the begin and end dates because the
        // backend needs to treat them as local dates
        variables: {
          beginAt: surveyScheduling.beginAt.clone().utcOffset(0, true).toISOString(),
          endAt: surveyScheduling.endAt.clone().utcOffset(0, true).toISOString(),
          isManualPublication: surveyScheduling.isManualPublication,
          scheduledReminders: getRemindersValueFromScheduledReminders(surveyScheduling.scheduledReminders ?? []),
          scheduledSurveyId: props.scheduledSurvey.scheduledId,
          surveyId: surveyScheduling.surveyId,
          userGroupsUuids: surveyScheduling.userGroupUuids,
        },
      })

      notification.show(
        t`Success`,
        t`The scheduled survey has successfully been edited.`,
        "success",
      )
      props.onScheduledSurveyEdited?.()
      refetchDates()
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
      }
    }

    props.onChangeEditingMode("read")
  }

  /**
   * Delete the scheduled survey
   */
  async function deleteScheduledSurvey() {
    if (props.scheduledSurvey) {
      try {
        await commitScheduledSurveyDeletion({
          variables: {
            surveyId: props.scheduledSurvey.scheduledId,
          },
        })

        notification.show(
          t`Success`,
          t`The scheduled survey has successfully been deleted.`,
          "success",
        )
        props.onDeleteScheduledSurvey?.()
        refetchDates()
      } catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
          notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
        }
      }
    }
  }

  /**
   * Return only the value (i.e. time when execute) of the scheduled reminder and not undefined
   * @param scheduledReminders
   */
  function getRemindersValueFromScheduledReminders(scheduledReminders: ScheduledReminder[]): number[] {
    return scheduledReminders
      .filter(reminder => reminder.timeReminder !== undefined)
      .map(reminder => reminder.timeReminder?.value ?? 0) ?? []
  }
}

export interface SurveySchedulerProps {
  /** Scheduled Survey to edit */
  scheduledSurvey?: ScheduledSurvey,

  /** Editing mode of the scheduler */
  editingMode: "read" | "edit",

  /** Called when the edit mode changes */
  onChangeEditingMode: (mode: "read" | "edit") => void,

  /** Fired when a scheduled survey is deleted */
  onDeleteScheduledSurvey?: () => void,

  /** Fired when a scheduled survey is created */
  onScheduledSurveyCreated?: (scheduledSurveyId: number) => void,

  /** Fired when a scheduled survey is edited */
  onScheduledSurveyEdited?: () => void,
}
