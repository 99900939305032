import React from "react"
import { t } from "ttag"

import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./RecipientImportIntroduction.styles"

export const RecipientImportIntroduction = (props: RecipientImportIntroductionProps) => (
  <Styles.Wrapper>
    <Styles.IntroTitle level="section">{t`File import`}</Styles.IntroTitle>

    <Styles.Paragraph>
      {t`
        Here you can send an Excel file to update your users base. 
        Your current list will be deleted and replaced by the one contained in your file. 
        You can have a maximum of 50 users.
      `}
    </Styles.Paragraph>

    <Styles.FileSpecsParagraph>
      <span>{t`The expected file must not exceed 5mb.`}</span>
      <span>{t`The file must be in .xlsx format.`}</span>
      <span>{t`The file must include the following headers: last name, first name, email`}</span>
    </Styles.FileSpecsParagraph>

    <Styles.EvalutationWarning>
      {t`Evaluation version: the file must not exceed 50 recipients`}
    </Styles.EvalutationWarning>

    <PrimaryButton onClick={props.onStartButtonClick}>{t`start`}</PrimaryButton>

  </Styles.Wrapper>
)

export interface RecipientImportIntroductionProps {
  onStartButtonClick: () => void,
}
