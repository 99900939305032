export interface ScheduledReminderPreset {
  name: string,
  timeReminder: ScheduledTimeReminder,
}

export type ScheduledTimeReminder = {
  unit: ScheduledTimeReminderUnit,
  value: number,
}

export enum ScheduledTimeReminderUnit {
  Hours,
}

export interface ScheduledReminder {
  /** Whether or not the scheduled reminder was executed */
  isExecuted?: boolean,

  /** When this scheduled reminder should be executed */
  timeReminder?: Partial<ScheduledTimeReminder>,
}
