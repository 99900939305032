/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { UserPermissionFragmentFragmentDoc } from '../../../models/generated/UserPermissionFragment';
import { SkinnyUserAttributeValueFragmentFragmentDoc } from '../../../models/generated/SkinnyUserAttributeValueFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminsQueryVariables = Types.Exact<{
  email?: Types.InputMaybe<Types.Scalars['String']>;
  filterGroup?: Types.InputMaybe<Types.FilterGroups>;
}>;


export type AdminsQuery = { admins: Array<{ id: number, email?: string | undefined, timezone: string, firstName: string, lastName: string, isAdmin: boolean, language: { code: string, localizedLabel: string, translatedLabel: string }, permissions: Array<{ isGranted: boolean, permission: { code: Types.UserPermissionCode, wording: string, isScopable?: boolean | undefined, group: { code: string, wording: string }, parents?: Array<{ code: Types.UserPermissionCode }> | undefined } }>, attributes: Array<{ name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> }>, scopes: Array<{ id: number, name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { name: string, value: string, attributeId: number, valueId: number, isMainHierarchyHead: boolean, parent?: { name: string, value: string, attributeId: number, valueId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> } | undefined, children: Array<{ attributeId: number, valueId: number }> } | undefined, children: Array<{ attributeId: number, valueId: number }> }> }> };


export const AdminsDocument = gql`
    query Admins($email: String, $filterGroup: FilterGroups) {
  admins(email: $email, filterGroup: $filterGroup, limit: 0) {
    id
    email
    firstName: first_name
    lastName: last_name
    isAdmin: is_admin
    timezone
    language {
      code
      localizedLabel: localized_label
      translatedLabel: translated_label
    }
    permissions {
      ...UserPermissionFragment
    }
    attributes {
      ...SkinnyUserAttributeValueFragment
    }
    scopes {
      ...SkinnyUserAttributeValueFragment
      parent {
        ...SkinnyUserAttributeValueFragment
        parent {
          ...SkinnyUserAttributeValueFragment
        }
      }
      id
    }
  }
}
    ${UserPermissionFragmentFragmentDoc}
${SkinnyUserAttributeValueFragmentFragmentDoc}`;

/**
 * __useAdminsQuery__
 *
 * To run a query within a React component, call `useAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      filterGroup: // value for 'filterGroup'
 *   },
 * });
 */
export function useAdminsQuery(baseOptions?: Apollo.QueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
      }
export function useAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
        }
export type AdminsQueryHookResult = ReturnType<typeof useAdminsQuery>;
export type AdminsLazyQueryHookResult = ReturnType<typeof useAdminsLazyQuery>;
export type AdminsQueryResult = Apollo.QueryResult<AdminsQuery, AdminsQueryVariables>;