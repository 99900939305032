/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditScheduledSurveyMutationVariables = Types.Exact<{
  scheduledSurveyId: Types.Scalars['Int'];
  surveyId?: Types.InputMaybe<Types.Scalars['Int']>;
  beginAt?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endAt?: Types.InputMaybe<Types.Scalars['DateTime']>;
  scheduledReminders: Array<Types.Scalars['Float']> | Types.Scalars['Float'];
  userGroupsUuids?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  isManualPublication?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EditScheduledSurveyMutation = { scheduled_survey: { id: number } };


export const EditScheduledSurveyDocument = gql`
    mutation EditScheduledSurvey($scheduledSurveyId: Int!, $surveyId: Int, $beginAt: DateTime, $endAt: DateTime, $scheduledReminders: [Float!]!, $userGroupsUuids: [String!], $isManualPublication: Boolean) {
  scheduled_survey(id: $scheduledSurveyId, survey_id: $surveyId, begin_at: $beginAt, end_at: $endAt, scheduled_reminders: $scheduledReminders, userGroupUuids: $userGroupsUuids, isManualPublication: $isManualPublication) {
    id
  }
}
    `;

/**
 * __useEditScheduledSurveyMutation__
 *
 * To run a mutation, you first call `useEditScheduledSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditScheduledSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editScheduledSurveyMutation, { data, loading, error }] = useEditScheduledSurveyMutation({
 *   variables: {
 *      scheduledSurveyId: // value for 'scheduledSurveyId'
 *      surveyId: // value for 'surveyId'
 *      beginAt: // value for 'beginAt'
 *      endAt: // value for 'endAt'
 *      scheduledReminders: // value for 'scheduledReminders'
 *      userGroupsUuids: // value for 'userGroupsUuids'
 *      isManualPublication: // value for 'isManualPublication'
 *   },
 * });
 */
export function useEditScheduledSurveyMutation(baseOptions?: Apollo.MutationHookOptions<EditScheduledSurveyMutation, EditScheduledSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditScheduledSurveyMutation, EditScheduledSurveyMutationVariables>(EditScheduledSurveyDocument, options);
      }
export type EditScheduledSurveyMutationHookResult = ReturnType<typeof useEditScheduledSurveyMutation>;
export type EditScheduledSurveyMutationResult = Apollo.MutationResult<EditScheduledSurveyMutation>;
export type EditScheduledSurveyMutationOptions = Apollo.BaseMutationOptions<EditScheduledSurveyMutation, EditScheduledSurveyMutationVariables>;