import React from "react"
import styled from "styled-components/macro"
import { Caption } from "@humanpredictiveintelligence/myqvt-library"
import { ContextualizedCommentsTableRow } from "features/Comments/ContextualizedCommentsTableRow"

export const Table = styled.div`
  width: 100%;
  align-self: flex-start;
`

export const Headers = styled.div`
  display: grid;
  grid-template-columns: minmax(450px, 1fr) 120px minmax(65px,180px) 40px;
  align-items: center;
  text-align: center;

  padding: 0 24px;
  margin-bottom: 16px;`

export const SurveyAndCommentHeaders = styled.div`
  display: flex;
`

export const SurveyHeader = styled(Caption)`
  width: 220px;
  margin-right: 16px;
`

export const Row = styled(props => <ContextualizedCommentsTableRow {...props} />)`
  border-bottom: none;
`
