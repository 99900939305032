import styled, { css } from "styled-components/macro"
import { Dialog, DialogActions, DialogContent } from "@material-ui/core"
import { Card, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled(Card)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: initial;
  
  @media print {
    display: none;
  }
`

export const CountdownSection = styled.div`
  display: flex;
  align-items: center;
`

export const Hint = styled.div<{ $isPublished?: boolean, $hasNoPublishingDate?: boolean }>`
   margin: 32px 40px;   
   max-width: 40%;
   color: ${({ theme }) => theme.colors.blackMedium};
   font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
   
   ${({ $isPublished }) => $isPublished && css`
     max-width: initial;
  `}
   
   ${({ $hasNoPublishingDate }) => $hasNoPublishingDate && css`
     max-width: 60%;
  `}
`

export const PublishButton = styled(PrimaryButton)`
  padding: 16px 48px;
  white-space: nowrap;
  min-width: initial;
`

export const PublishDialog = styled(Dialog)`
  .PublishDialog {
    max-width: 760px;
    overflow-y: hidden;
  }
`

export const PublishDialogContent = styled(DialogContent)`
  margin-bottom: 176px;
`

export const PublishDialogRecipientsCount = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};

`

export const PublishDialogFooter = styled(DialogActions)`
  justify-content: flex-end;
  align-items: flex-end;
`

export const PublishDialogIllustration = styled.img`
  width: 260px;
  position: absolute;
  bottom: 0;
`
