import styled from "styled-components/macro"

import { Card } from "@humanpredictiveintelligence/myqvt-library"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { Title } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled(Card)`
  height: min-content;
  display:flex;
  flex-direction: column;
  padding: 24px 52px;
  margin: 16px 0;
`

export const SectionTitle = styled(Title)`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.typography.fontSizeText};
  border: none;
  font-weight: bold;
  margin: 0;
  padding: 0;
`

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  color: ${({ theme }) => theme.colors.blackMedium};
`

export const Reminders = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  align-items: flex-end;  
`

export const AddReminderButton = styled(PrimaryButton)`
  justify-self: flex-start;
`
