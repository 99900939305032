import { motion } from "framer-motion"
import styled from "styled-components/macro"
import { CalendarView } from "@humanpredictiveintelligence/myqvt-library"
import { Card } from "@humanpredictiveintelligence/myqvt-library"
import { Label } from "@humanpredictiveintelligence/myqvt-library"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const AnimatedContainer = styled(motion.div)<AnimatedContainerProps>`
  position: fixed;
  // The component need to be behind the select while animating
  // The component need to be on top to prevent the select from closing
  z-index: ${({ $isAnimating }) => $isAnimating ? 100 : 9000};
  left: ${({ $left }) => $left}px;
  top: ${({ $top }) => $top}px;
  
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
`

export interface AnimatedContainerProps {
  $width: number,
  $height: number,
  $top?: number,
  $left?: number,
  $isAnimating?: boolean,
}

export const DrawerCard = styled(Card)`
  overflow: visible;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-shadow: ${({ theme }) => theme.shadows.main};
`

export const DrawerActions = styled.div`
 display: flex;
 align-items: center;
`

export const ActionLabel = styled(Label)`
  margin-right: 16px;
`

export const SubmitButton = styled(PrimaryButton)`
  margin-left: auto;
`

export const DateReminderPicker = styled(CalendarView)`
 & .DayPicker {
   &-Month {
     margin-top: 0.5em;
     border-collapse: unset;
     border-spacing: 1em 0;
   }
 
   &-Day {
     font-size: 1em;
     
     &--selected {
       background-color: ${({ theme }) => theme.colors.primary} !important;
       color: ${({ theme }) => theme.colors.white} !important;
       border-radius: 50% !important;
     }
   }
   
   &-Caption {
      margin-bottom: 16px;
   }
 }
`
