import React from "react"
import { UserGroupsState } from "features/UserGroups/UserGroupsContext/state"

export type UserGroupsReducerAction =
  | { type: "setDisplayedNumberOfUsers", displayedNumberOfUsers: UserGroupsState["displayedNumberOfUsers"]}
  // eslint-disable-next-line max-len
  | { type: "setDisplayedNumberOfUsersInGroup", displayedNumberOfUsersInGroup: UserGroupsState["displayedNumberOfUsersInGroup"]}
  | { type: "setSelectedUsersIds", selectedUsersIds: number[] }
  | { type: "setSelectedUsersInGroupIds", selectedUserInGroupIds: number[] }
  | { type: "setSelectedGroupUuid", selectedGroupUuid: string }
  | { type: "setShouldRefetchUsers", shouldRefetchUsers: UserGroupsState["shouldRefetchUsers"]}
  | { type: "setShouldGoToPreviousUserPage", shouldGoToPreviousUserPage: UserGroupsState["shouldGoToPreviousUserPage"]}
  // eslint-disable-next-line max-len
  | { type: "setShouldGoToPreviousUserGroupPage", shouldGoToPreviousUserGroupPage: UserGroupsState["shouldGoToPreviousUserGroupPage"]}

export const userGroupsReducerActions = (dispatch: React.Dispatch<UserGroupsReducerAction>) => ({
  setDisplayedNumberOfUsers: (
    displayedNumberOfUsers: UserGroupsState["displayedNumberOfUsers"],
  ) => dispatch({
    displayedNumberOfUsers,
    type: "setDisplayedNumberOfUsers",
  }),
  setDisplayedNumberOfUsersInGroup: (
    displayedNumberOfUsersInGroup: UserGroupsState["displayedNumberOfUsersInGroup"],
  ) => dispatch({
    displayedNumberOfUsersInGroup,
    type: "setDisplayedNumberOfUsersInGroup",
  }),
  setSelectedGroupUuid: (selectedGroupUuid: string) => dispatch({
    selectedGroupUuid,
    type: "setSelectedGroupUuid",
  }),
  setSelectedUsersIds: (selectedUsersIds: number[]) => dispatch({
    selectedUsersIds,
    type: "setSelectedUsersIds",
  }),
  setSelectedUsersInGroupIds: (selectedUserInGroupIds: number[]) => dispatch({
    selectedUserInGroupIds,
    type: "setSelectedUsersInGroupIds",
  }),
  setShouldGoToPreviousUserGroupPage: (
    shouldGoToPreviousUserGroupPage: UserGroupsState["shouldGoToPreviousUserGroupPage"],
  ) => dispatch({
    shouldGoToPreviousUserGroupPage: shouldGoToPreviousUserGroupPage,
    type: "setShouldGoToPreviousUserGroupPage",
  }),
  setShouldGoToPreviousUserPage: (
    shouldGoToPreviousUserPage: UserGroupsState["shouldGoToPreviousUserPage"],
  ) => dispatch({
    shouldGoToPreviousUserPage: shouldGoToPreviousUserPage,
    type: "setShouldGoToPreviousUserPage",
  }),
  setShouldRefetchUsers: (shouldRefetchUsers: UserGroupsState["shouldRefetchUsers"]) => dispatch({
    shouldRefetchUsers,
    type: "setShouldRefetchUsers",
  }),
})
