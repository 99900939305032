import styled, { css } from "styled-components/macro"
import { MenuItem } from "@material-ui/core"
import { BackgroundColorProperty } from "csstype"

import { IconButton } from "@humanpredictiveintelligence/myqvt-library"
import { Select } from "library/Select/Select"

export const ActionableSelect = styled(Select)`
  ${({ theme, disabled }) => disabled && css`
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.button};
  `}
`

export const ActionButton = styled.div`
  margin-right: 4px;
`

export const ListItem = styled(MenuItem)`
  display: flex;
  justify-content: flex-end;
  padding: 4px 8px 4px;
`

export const ActionItem = styled(IconButton)<{ $backgroundColor: BackgroundColorProperty | undefined }>`
  ${({ $backgroundColor }) => css`
    margin-left: 8px;
    background-color: ${$backgroundColor};

    &:hover {
      background-color: ${$backgroundColor};
    }

    .ActionItemIconWrapper {
      background-color: ${$backgroundColor};

      &:hover {
        background-color: ${$backgroundColor};
      }
    }

    .ActionItemIcon {
      background-color: ${$backgroundColor};

      &:hover {
        background-color: ${$backgroundColor};
      }
    }
  `};
`
