/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddUsersToGroupMutationVariables = Types.Exact<{
  userIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  userGroupUuid: Types.Scalars['String'];
}>;


export type AddUsersToGroupMutation = { addUsersToUserGroup: { usersCount?: number | undefined, name: string, uuid: string, users: Array<{ id: number }> } };


export const AddUsersToGroupDocument = gql`
    mutation AddUsersToGroup($userIds: [Int!]!, $userGroupUuid: String!) {
  addUsersToUserGroup(userIds: $userIds, userGroupUuid: $userGroupUuid) {
    usersCount
    users {
      id
    }
    name
    uuid
  }
}
    `;

/**
 * __useAddUsersToGroupMutation__
 *
 * To run a mutation, you first call `useAddUsersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToGroupMutation, { data, loading, error }] = useAddUsersToGroupMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      userGroupUuid: // value for 'userGroupUuid'
 *   },
 * });
 */
export function useAddUsersToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>(AddUsersToGroupDocument, options);
      }
export type AddUsersToGroupMutationHookResult = ReturnType<typeof useAddUsersToGroupMutation>;
export type AddUsersToGroupMutationResult = Apollo.MutationResult<AddUsersToGroupMutation>;
export type AddUsersToGroupMutationOptions = Apollo.BaseMutationOptions<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>;