import { AgeGroup, Gender } from "models/generated"

import { AttributeCode } from "./AttributeCode"

export enum ChartType {
  Pie = "pie",
  Bar = "bar",
}

/**
 * The origin of the attribute.
 * It determines which data is represented on the graph (the psyReportsCount or the psyRecipientsCount)
 * */
export enum Origin {
  Report = "report",
  Recipient = "recipient",
}

type Attribute = {
  chartType: ChartType,
  origin: Origin,
  label: string,
}

type AttributeWithValues = Attribute & {
  valueLabels: Record<string, string>,
}

export class AttributesConfig {
  static readonly config: Record<string, Attribute | AttributeWithValues> = {
    [AttributeCode.AgeGroup.toString()]: {
      chartType: ChartType.Pie,
      label: "Groupes d'âge",
      origin: Origin.Recipient,
      valueLabels: {
        [AgeGroup.AgeGroup_25]: "Moins de 25 ans",
        [AgeGroup.AgeGroup_26_35]: "Entre 26 et 35 ans",
        [AgeGroup.AgeGroup_36_45]: "Entre 36 et 45 ans",
        [AgeGroup.AgeGroup_46_55]: "Entre 46 et 55 ans",
        [AgeGroup.AgeGroup_55]: "plus de 55 ans",
      },
    },
    [AttributeCode.Gender.toString()]: {
      chartType: ChartType.Pie,
      label: "Genres",
      origin: Origin.Recipient,
      valueLabels: {
        [Gender.Man]: "Homme",
        [Gender.Woman]: "Femme",
      },
    },
    [AttributeCode.HierarchicalLevel.toString()]: {
      chartType: ChartType.Pie,
      label: "Niveaux hiérarchiques",
      origin: Origin.Recipient,
    },
    [AttributeCode.Job.toString()]: {
      chartType: ChartType.Pie,
      label: "Métiers",
      origin: Origin.Recipient,
    },
    [AttributeCode.InterviewModality.toString()]: {
      chartType: ChartType.Pie,
      label: "Modalités des entretiens",
      origin: Origin.Report,
    },
    [AttributeCode.PrimaryProblematic.toString()]: {
      chartType: ChartType.Bar,
      label: "Problématiques primaires",
      origin: Origin.Report,
    },
    [AttributeCode.Direction.toString()]: {
      chartType: ChartType.Pie,
      label: "Directions",
      origin: Origin.Recipient,
    },
    [AttributeCode.Site.toString()]: {
      chartType: ChartType.Pie,
      label: "Sites",
      origin: Origin.Recipient,
    },
  }

  static getAttributeCodeFromLabel(label: string): AttributeCode|undefined {
    try {
      return AttributeCode.fromString(label)
    }
    catch {
      return undefined
    }
  }

  static getChartTypeFor(attributeCode: AttributeCode): ChartType {
    return this.config[attributeCode.value].chartType
  }

  static getLabelFor(attributeCode: AttributeCode): string {
    return this.config[attributeCode.value].label
  }

  static getOriginFor(attributeCode: AttributeCode): Origin {
    return this.config[attributeCode.value].origin
  }

  static getLabelForValue(attributeLabel: string, attributeValue: string): string {
    const attributeCode = this.getAttributeCodeFromLabel(attributeLabel)

    const attribute = attributeCode ? this.config[attributeCode.value] : undefined

    if (attribute && this.isAttributeWithValues(attribute)) {
      return attribute.valueLabels[attributeValue] ?? "NC"
    }

    return attributeValue
  }

  private static isAttributeWithValues(attribute: Attribute | AttributeWithValues): attribute is AttributeWithValues {
    return (attribute as AttributeWithValues).valueLabels !== undefined
  }
}
