import * as React from "react"
import { t } from "ttag"

import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { NumberMetric } from "@humanpredictiveintelligence/myqvt-library"
import { Skeletonable } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./GlobalFeedbacksMetrics.styles"

export const GlobalFeedbacksMetrics: React.FC<Skeletonable<GlobalFeedbacksMetricsProps>> = (props) => {
  const surveyWithoutFeedbackCount = props.surveysWithoutFeedbackCount ? props.surveysWithoutFeedbackCount : 0

  return (
    <Styles.Container className={props.className}>
      <MetricsCard title={t`Global feedbacks`} hasReducedPadding>
        <NumberMetric isLoading={props.isLoading} value={props.surveysWithFeedbackCount}
          isNegative={props.surveysWithFeedbackCount === 0}/>
        {t`Surveys with feedbacks`}

        <NumberMetric isLoading={props.isLoading} value={surveyWithoutFeedbackCount}
          isNegative={surveyWithoutFeedbackCount > 0}/>
        {t`Surveys without feedbacks`}
      </MetricsCard>
    </Styles.Container>
  )
}

export interface GlobalFeedbacksMetricsProps extends BaseProps {
  /** Number of surveys with a feedback */
  surveysWithFeedbackCount?: number,

  /** Number of surveys without any feedback */
  surveysWithoutFeedbackCount?: number,
}
