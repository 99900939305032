import styled from "styled-components/macro"
import { Grade, HorizontalChartBar } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  display: grid;
  // Proportions are loosely taken from the columns alignment in the design mockup
  // https://projects.invisionapp.com/d/main#/console/16222561/336780773/inspect
  grid-template-columns: calc(4/7 * 100% - 16px) calc(3/7 * 100% - 16px);
  grid-column-gap: 24px;
  align-items: center;

  width: 100%;
  margin: 32px 64px 32px 80px;
  
  @media print {
    margin: 0;
  }
`

export const Chart = styled.div`
  max-width: ${({ theme }) => theme.sizes.chartMaxWidth};
  
  @media print {
    margin-right: 24px;
  }
`

export const StatisticsBar = styled(HorizontalChartBar)`
  margin: 24px 0;
`

export const StatisticsSkip = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};
  color: ${({ theme }) => theme.colors.blackMedium};
  text-align: right;
  margin-top: 16px;
`

export const StatisticsHighlights = styled.div`
  justify-self: center;

  display: grid;
  grid-template-columns: 40px auto;
  grid-column-gap: 24px;
  grid-row-gap: 48px;
  
  @media print {
    align-self: flex-start;
    justify-self: flex-end;
  }
`

export const HierarchyLabel = styled.div`
  grid-column: 2;

  font-size: ${({ theme }) => theme.typography.fontSizeLabelMedium};
  color: ${({ theme }) => theme.colors.blackMedium};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  text-transform: uppercase;
  letter-spacing: 1.06px;
`

export const HierarchyGrade = styled(Grade)`
  grid-column: 1/span 2;
`
