import React from "react"

import { GroupsSection, UserTransferActions, UsersSection } from "features/UserGroups/UsersGroupsSections"
import { UserGroupsProvider } from "features/UserGroups/UserGroupsContext"
import * as Styles from "./UserGroupsPage.styles"

export const UserGroupsPage: React.FC = () => {
  return (
    <Styles.Container>

      <UserGroupsProvider>
        <UsersSection/>
        <UserTransferActions/>
        <GroupsSection/>
      </UserGroupsProvider>

    </Styles.Container>
  )
}
