import styled, { css } from "styled-components/macro"
import { UnprocessedSurveyRecipientCommentsContainer } from "features/Comments"
import { Title } from "@humanpredictiveintelligence/myqvt-library"

export const Header = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  
  ${({ theme: { mixins } }) => css`
    ${mixins.reducedLandscapeAndPortrait} {
      flex-wrap: wrap;
    }
  `}
`

export const ScheduledSurveyStatus = styled(Title)`
  min-width: fit-content;
  margin: 0;
`

export const DigitalInfoContainer = styled.div`
  flex: 1 0 auto;
  margin: 0;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto !important;
  padding: 40px;
`

export const UnprocessedCommentsContainer = styled(UnprocessedSurveyRecipientCommentsContainer)`
  padding-bottom: 128px;
  min-width: 700px;
`

export const Heading = styled(Title)`
  margin-top: 0;
`

export interface ScheduledSurveyDetailsStylesProps {
  $isDeleted?: boolean,
  $isDisabled?: boolean,
}
