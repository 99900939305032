/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyAssigneesPermissionsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  filterAttributeIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type MyAssigneesPermissionsQuery = { me: { id: number, users: { assigneesCount: number, list: Array<{ id: number, firstName: string, lastName: string }> } } };


export const MyAssigneesPermissionsDocument = gql`
    query MyAssigneesPermissions($limit: Int!, $search: String, $filterAttributeIds: [Int!]) {
  me {
    id
    users(limit: $limit, search: $search, attribute_value_ids: $filterAttributeIds, isPrivileged: false) {
      list {
        id
        firstName: first_name
        lastName: last_name
      }
      assigneesCount: count
    }
  }
}
    `;

/**
 * __useMyAssigneesPermissionsQuery__
 *
 * To run a query within a React component, call `useMyAssigneesPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAssigneesPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAssigneesPermissionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      filterAttributeIds: // value for 'filterAttributeIds'
 *   },
 * });
 */
export function useMyAssigneesPermissionsQuery(baseOptions: Apollo.QueryHookOptions<MyAssigneesPermissionsQuery, MyAssigneesPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAssigneesPermissionsQuery, MyAssigneesPermissionsQueryVariables>(MyAssigneesPermissionsDocument, options);
      }
export function useMyAssigneesPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAssigneesPermissionsQuery, MyAssigneesPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAssigneesPermissionsQuery, MyAssigneesPermissionsQueryVariables>(MyAssigneesPermissionsDocument, options);
        }
export type MyAssigneesPermissionsQueryHookResult = ReturnType<typeof useMyAssigneesPermissionsQuery>;
export type MyAssigneesPermissionsLazyQueryHookResult = ReturnType<typeof useMyAssigneesPermissionsLazyQuery>;
export type MyAssigneesPermissionsQueryResult = Apollo.QueryResult<MyAssigneesPermissionsQuery, MyAssigneesPermissionsQueryVariables>;