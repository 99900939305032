import styled from "styled-components/macro"

export const Container = styled.div`
  width: 100%;

  .CommentEditor__input {
    width: 100%;
  }
`

export const Status = styled.p`
  margin: 16px 0;

  text-align: right;
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  line-height: 20px;`

export const Creator = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 16px;
`

export const Actions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`

