import { FrontApp, FrontAppName } from "models/generated"
import { Link } from "react-router-dom"
import * as Styles from "./AppSelector.styles"
import React, { memo } from "react"
import { ApplicationPath } from "features/Navigation"
import { STATIC_SESSION } from "../../../utilities/StaticSession"

export const AppSelector: React.FC<AppSelectorProps> = memo(({ app, children }) => {
  if (app.name === FrontAppName.PimeoBo) {
    return <Styles.AppSelector as={Link} to={ApplicationPath.Base}>{children}</Styles.AppSelector>
  }

  return <Styles.AppSelector href={getUrlWithToken(app.url)}>{children}</Styles.AppSelector>

  function getUrlWithToken(baseUrl: string): string {
    const url = new URL(baseUrl)

    if (STATIC_SESSION.authenticationToken) {
      url.searchParams.set("t", STATIC_SESSION.authenticationToken)
    }

    return url.toString()
  }
})

interface AppSelectorProps {
  app: FrontApp,
}
