import styled from "styled-components/macro"
import { DialogActions, DialogContent } from "@material-ui/core"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const Content = styled(DialogContent)`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

export const DialogAction = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: stretch;
`

export const ActionCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.button};
  margin: 16px;
  padding: 16px 32px;
  max-width: 275px;
  height: inherit;
  cursor: pointer;
`

export const ActionLabel = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeTitleSmall};
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`
