import * as React from "react"
import { t } from "ttag"

import { CompletionChart } from "@humanpredictiveintelligence/myqvt-library"
import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { Skeletonable } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ParticipationMetrics.styles"

export const ParticipationMetrics: React.FC<Skeletonable<ParticipationMetricsProps>> = (props) => {
  let title = ""
  let hint = ""

  const overallPercentage = props.overallParticipationPercentage ? props.overallParticipationPercentage : 0
  const latestParticipationPercentage = props.latestParticipationPercentage ? props.latestParticipationPercentage : 0

  switch (props.participationType) {
    case "adjusted":
      title = t`Adjusted participation`
      hint = t`Number of participants / number of participants who accepted the TOS`
      break
    case "real":
      title = t`Real participation`
      hint = t`Participants of the surveys out of the number of surveys sent`
      break
  }

  return (
    <Styles.Container className={props.className}>
      <MetricsCard
        hint={hint}
        hasReducedPadding
        title={title}
      >
        <CompletionChart isLoading={props.isLoading} completionPercentage={overallPercentage} />
        {t`All surveys`}

        <CompletionChart
          isLoading={props.isLoading}
          completionPercentage={latestParticipationPercentage}
          delta={props.latestParticipationDelta}
          deltaHint={t`Variation since previous survey`}
          isDeltaFloating
          isSecondaryColor
        />
        {t`Latest survey`}
      </MetricsCard>
    </Styles.Container>
  )
}

export interface ParticipationMetricsProps extends BaseProps {
  /** Delta between the latest survey's participation percentage
   * and the previous survey's participation percentage */
  latestParticipationDelta?: number,

  /** Participation percentage of the latest survey */
  latestParticipationPercentage?: number,

  /** Overall participation percentage for all the surveys */
  overallParticipationPercentage?: number,

  /** Type of participation shown */
  participationType: "real" | "adjusted",
}
