import { Point, PointTooltipProps, ResponsiveLine } from "@nivo/line"
import moment from "moment"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { msgid, ngettext, t } from "ttag"
import AutoSizer  from "react-virtualized-auto-sizer"

import { APPLICATION_URL } from "features/Navigation"
import { ScheduledSurveySatisfaction, Survey, SurveyScheduling } from "models/Survey/index"
import { muiMyQvtVariables } from "@humanpredictiveintelligence/myqvt-library"
import { nivoMyQvtTheme } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./SatisfactionEvolutionChart.styles"

import smiley1 from "assets/images/smiley_1.svg"
import smiley2 from "assets/images/smiley_2.svg"
import smiley3 from "assets/images/smiley_3.svg"
import smiley4 from "assets/images/smiley_4.svg"
import smiley5 from "assets/images/smiley_5.svg"
import smiley6 from "assets/images/smiley_6.svg"

export const SatisfactionEvolutionChart: React.FC<SatisfactionEvolutionChartProps> = (props) => {
  const navigationHistory = useHistory()

  const sanitizedSerie1 = React.useMemo(() => {
    return seriesWithoutNullGrades(props.serie1)
  }, [ props.serie1 ])

  const sanitizedSerie2 = React.useMemo(() => {
    return seriesWithoutNullGrades(props.serie2 || [])
  }, [ props.serie2 ])

  if (sanitizedSerie1.length === 0) {
    return null
  }

  const chartData = [
    {
      color: muiMyQvtVariables.palette.primary.main,
      data: sanitizedSerie1.map(datum => ({ x: new Date(datum.date), y: datum.grade })),
      id: "serie1",
    },
  ]

  if (sanitizedSerie2.length) {
    chartData.push({
      color: muiMyQvtVariables.palette.secondary.main,
      data: sanitizedSerie2.map(datum => ({ x: new Date(datum.date), y: datum.grade })),
      id: "serie2",
    })
  }

  const bottomAxisTicks = bottomAxisTickValues(props.serie1)

  return (
    <AutoSizer>
      {({ width, height }: {height: number, width: number}) => (
        <Styles.Container $width={width} $height={height}>
          <ResponsiveLine
            axisBottom={{
              format: (tick: number | string | Date) => formattedBottomTick(tick, bottomAxisTicks[0]),
              tickPadding: 8,
              tickSize: 5,
              tickValues: bottomAxisTicks,
            }}
            axisLeft={{
              renderTick: leftAxisTickWithSmiley,
              tickPadding: 8,
              tickSize: 0,
              tickValues: [ 0, 20, 40, 60, 80, 100 ],
            }}
            colors={{ datum: "color" }}
            data={chartData}
            enableGridX={false}
            gridYValues={[ 0, 20, 40, 60, 80, 100 ]}
            margin={{
              bottom: 25,
              left: 65,
              right: 6,
              top: 10,
            }}

            onClick={navigateToScheduledSurveyOfPoint}
            pointBorderColor={{ from: "serieColor" }}
            pointBorderWidth={2}
            pointColor="white"
            pointSize={8}
            theme={nivoMyQvtTheme}
            tooltip={pointTooltip}
            useMesh
            xScale={{
              format: "native",
              precision: "day",
              type: "time",
            }}
            yScale={{
              max: 100,
              min: 0,
              type: "linear",
            }}
          />
        </Styles.Container>
      )}</AutoSizer>
  )

  /**
   * Get a list of dates to show in the bottom axis
   * BR: show the 1st and 15th of each month from the first data month to the last
   * BR: Don't show the first 2 ticks if they are before the first data point's date
   * @param data Data of a line chart's serie with a time value for x
   */
  function bottomAxisTickValues(data: SatisfactionEvolutionChartSerieDate[]) {
    const tickValues: Date[] = []
    const dataOrderedByDate = [ ...data ].sort((datum1, datum2) => {
      return datum1.date >= datum2.date ? 1 : -1
    })

    const firstDatumDate = moment(dataOrderedByDate[0].date)
    const endOfLastDatumMonth = moment(dataOrderedByDate[dataOrderedByDate.length - 1].date).endOf("month")

    for (
      const i = moment(firstDatumDate).startOf("month");
      i.isSameOrBefore(endOfLastDatumMonth);
      i.startOf("month").add(1, "month")
    ) {
      tickValues.push(i.toDate())
      tickValues.push(i.add(2, "weeks").toDate())
    }

    // Remove the first ticks if they are before the first data point's date
    // Because they would be displayed outside the axis
    if (firstDatumDate.date() > 1) {
      tickValues.shift()
    }

    if (firstDatumDate.date() > 15) {
      tickValues.shift()
    }

    return tickValues
  }

  /**
   * Get the custom point Tooltip component to display
   * @param param0 Tooltip props
   */
  function pointTooltip({ point }: PointTooltipProps) {
    const pointDisplayDate = moment(point.data.x).format("L")
    const pointData = seriePointData(point)

    if (!pointData) {
      return null
    }

    // BR: Display the first 2 surveys in the tooltip and the number of remaining surveys
    const remainingSurveys = pointData.scheduledSurveys.length - 2

    return (
      <Styles.ToolTip.Card shadow="button" isReducedPadding>
        <Styles.ToolTip.Title>{pointDisplayDate}</Styles.ToolTip.Title>
        <Styles.ToolTip.SurveyList>
          {pointData.scheduledSurveys.slice(0, 2).map((scheduledSurvey, index) => {
            return (
              <Styles.ToolTip.SurveyItem key={scheduledSurvey.survey.name + index}>
                <div>{scheduledSurvey.survey.name}</div>
                <div>{t`Satisfaction: ${scheduledSurvey.grade}/100`}</div>
              </Styles.ToolTip.SurveyItem>
            )
          })}
          {remainingSurveys > 0
            ? <Styles.ToolTip.RemainingSurveys>
              {ngettext(
                msgid`+${remainingSurveys} other survey`,
                `+${remainingSurveys} other surveys`,
                remainingSurveys,
              )}
            </Styles.ToolTip.RemainingSurveys>
            : null
          }
        </Styles.ToolTip.SurveyList>
        <Styles.ToolTip.CallToAction>
          {t`Click to go to the results`}
        </Styles.ToolTip.CallToAction>
      </Styles.ToolTip.Card>
    )
  }

  /**
   * Return a formatted tick if it's a date, else returns it untouched
   * @param tick Tick to format
   */
  function formattedBottomTick(tick: number | string | Date, firstBottomAxisTickValue: Date) {
    /*
     * The formatted string is the 2-digit day of the month with the full month name
     * if it's in the first 7 days of the month. If it's not, the formatted string
     * is just the 2-digit day of the month.
     */

    // eslint-disable-next-line
    if (tick instanceof Date) {
      const date = moment(tick)

      if (date.isValid()) {
        return date.date() === 1 || date.isSame(firstBottomAxisTickValue) ? date.format("DD MMMM") : date.date()
      }

      // Date is incorrect, we don't really know what will be displayed
      // But we can't really do anything about it
      return tick.toLocaleDateString()
    }

    return tick
  }

  /**
   * Get the left axis tick node for the given datum
   * @param datum Datum to display
   */
  function leftAxisTickWithSmiley(datum: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    return (
      <g transform={`translate(${datum.x},${datum.y})`}>
        <image
          href={smileyForSatisfaction(datum.value)}
          x="-65"
          y="-10"
          width="20"
          height="20"
        />
        {datum.value !== 0 && (
          <text
            dominantBaseline={datum.textBaseline}
            textAnchor={datum.textAnchor}
            transform={`translate(${datum.textX}, ${datum.textY}) rotate(${datum.rotate})`}
            style={nivoMyQvtTheme.axis!.ticks!.text}
          >
            {datum.value}
          </text>
        )}
      </g>
    )
  }

  /**
   * Get the smiley associated to a satisfaction value
   * @param satisfaction Satisfaction grade for which to get the smiley
   */
  function smileyForSatisfaction(satisfaction: number) {
    if (satisfaction < 10) {
      return smiley1
    }

    if (satisfaction < 30) {
      return smiley2
    }

    if (satisfaction < 50) {
      return smiley3
    }

    if (satisfaction < 70) {
      return smiley4
    }

    if (satisfaction < 90) {
      return smiley5
    }

    return smiley6
  }

  /**
   * Navigate to the first survey in the given point data
   * @param point Point of the survey to navigate to
   */
  function navigateToScheduledSurveyOfPoint(point: Point) {
    const targetScheduledSurvey = seriePointData(point)?.scheduledSurveys?.[0]

    if (targetScheduledSurvey) {
      navigationHistory.push(APPLICATION_URL.scheduledSurvey(targetScheduledSurvey.scheduling.schedulingId))
    }
  }

  /**
   * Get the serie of the given point from the props
   * @param point Point for which to get the serie
   */
  function serieOfPoint(point: Point) {
    if (point.serieId === "serie2" && sanitizedSerie2) {
      return sanitizedSerie2
    }

    return sanitizedSerie1
  }

  /**
   * Get the data of the point in its serie
   * @param point The point whose data is to be returned
   */
  function seriePointData(point: Point) {
    const pointFilterDate = moment(point.data.x).utc(true).startOf("day")
    return serieOfPoint(point).find(satisfactionData => pointFilterDate.isSame(satisfactionData.date))
  }

  /**
   * Sanitize a chart serie by removing any null-graded scheduled survey for each serie point and any
   * null-graded serie point itsef.
   * @param serie Serie to sanitize
   */
  function seriesWithoutNullGrades(serie: SatisfactionEvolutionChartSerieDate[]): SanitizedEvolutionChartSerieDate[] {
    return serie.reduce((newSerie, currentSerieDate) => {
      const nonNullGradedSurveys = currentSerieDate.scheduledSurveys.filter(survey => survey.grade !== null)

      if (nonNullGradedSurveys.length > 0 && currentSerieDate.grade !== null) {
        const currentSerieDateWithoutNullGradedSurveys = Object.assign(
          {},
          currentSerieDate,
          { scheduledSurveys: nonNullGradedSurveys },
        )

        newSerie.push(currentSerieDateWithoutNullGradedSurveys as SanitizedEvolutionChartSerieDate)
      }

      return newSerie
    }, new Array<SanitizedEvolutionChartSerieDate>())
  }
}

export type SatisfactionEvolutionChartSerieDate = {
  date: string,
  grade?: number,
  scheduledSurveys: Array<{
    survey: Survey,
    scheduling: SurveyScheduling,
  } & ScheduledSurveySatisfaction>,
}

type SanitizedEvolutionChartSerieDate = {
  date: string,
  grade: number,
  scheduledSurveys: Array<{
    survey: Survey,
    scheduling: SurveyScheduling,
  } & Required<ScheduledSurveySatisfaction>>,
}

export interface SatisfactionEvolutionChartProps {
  /** First serie of values */
  serie1: SatisfactionEvolutionChartSerieDate[],

  /** Second serie of values */
  serie2?: SatisfactionEvolutionChartSerieDate[],

  /** Chart width */
  width?: number,

  /** Chart height */
  height?: number,
}
