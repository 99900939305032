import { t } from "ttag"
import React from "react"

import * as Steps from "./Steps"
import { Tutorial } from "../../models/Tutorial"
import { APPLICATION_URL } from "features/Navigation"
import {
  RecipientsCreateInitialized,
} from "features/Tutorial/contents/RecipientsPage/Events"
import { isRecipientsCreateStepData, isRecipientsCreatedStepData } from "../../utils"
import { zIndexSpeakup } from "@humanpredictiveintelligence/myqvt-library"

export const recipientsTutorial = (): Tutorial => ({
  id: "recipients-page",
  parts: [
    [
      {
        // Introduce recipients page
        activePage: APPLICATION_URL.recipients(),
        callToActionPosition: "flex-end",
        content: <Steps.TutorialIntroduction/>,
        disableBeacon: true,
        id: "tutorial-introduction",
        partIndex: 0,
        partTitle: t`Recipients part presentation`,
        placement: "center",
        placementBeacon: "left",
        popupDirection: "vertical",
        target: "body",
      },
      {
        // Highlight recipients page link
        activePage: APPLICATION_URL.recipients(),
        callToActionPosition: "center",
        content: <Steps.RecipientsPageLink/>,
        disableBeacon: true,
        id: "recipients-page-link",
        partIndex: 0,
        partTitle: t`Recipients part presentation`,
        placement: "right",
        spotlightPadding: 0,
        target: `[role=link][href="${APPLICATION_URL.recipients()}"]`,
      },
      {
        // Highlight recipients row
        activePage: APPLICATION_URL.recipients(),
        content: <Steps.RecipientsList/>,
        disableBeacon: true,
        id: "recipients-list",
        isImperative: true,
        partIndex: 0,
        partTitle: t`Recipients part presentation`,
        spotlightPadding: 5,
        target: "[role=row]:first-child",
      },
      {
        // Highlight add recipient button
        activePage: APPLICATION_URL.recipients(),
        content: <Steps.RecipientsCreateLink/>,
        disableBeacon: true,
        hideNextButton: true,
        id: "recipients-create-link",
        nextActionEvent: RecipientsCreateInitialized,
        partIndex: 0,
        partTitle: t`Recipients part presentation`,
        popupDirection: "vertical",
        spotlightClicks: true,
        target: `[aria-label="${t`Add a user`}"]`,
      },
    ],
    [
      {
        // Recipients create form
        activePage: APPLICATION_URL.recipients(),
        centerContent: true,
        content: <Steps.RecipientsCreate/>,
        disableBeacon: true,
        disableNextButton: stepData => isRecipientsCreateStepData(stepData) ? !stepData.canContinue : true,
        floaterProps: {
          disableFlip: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any,
        forceRerender: true,
        hideSkipButton: false,
        id: "recipients-create",
        isImperative: true,
        offset: 50,
        partIndex: 1,
        partTitle: t`Addition of recipients`,
        placement: "right",
        popupDirection: "vertical",
        // eslint-disable-next-line max-len
        skipMessage: t`If you do not have a minimum of 5 participants, completed surveys won't be able to display any results. Are you sure you wish to skip this step ?`,
        spotlightClicks: true,
        spotlightPadding: 10,
        stepDependencyWithPrevious: true,
        styles: {
          options: {
            zIndex: zIndexSpeakup.tutorial,
          },
        },
        target: `body`,
      },
      {
        // Recipients added
        activePage: APPLICATION_URL.recipients(),
        callToActionPosition: "center",
        content: <Steps.RecipientsCreated/>,
        disableBeacon: true,
        disableScrolling: true,
        id: "recipients-created",
        offset: 0,
        partIndex: 1,
        partTitle: t`Addition of recipients`,
        placement: "top",
        popupDirection: "horizontal",
        skip: stepData => isRecipientsCreatedStepData(stepData) ? stepData.recipients.length === 0 : true,
        spotlightClicks: true,
        spotlightPadding: 0,
        stepDependencyWithPrevious: true,
        target: `[role=table]`,
      },
    ],
  ],
})

export interface RecipientsCreatedStepData {
  recipients: unknown[],
}

export interface RecipientsCreateStepData {
  canContinue: boolean,
}
