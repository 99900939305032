/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnyUserGroupFragmentFragment = { name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined };

export const SkinnyUserGroupFragmentFragmentDoc = gql`
    fragment SkinnyUserGroupFragment on UserGroup {
  name
  usersCount
  uuid
  deletedAt
}
    `;