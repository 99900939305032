import React from "react"
import { useTheme } from "styled-components"

import { BaseProps, Tooltip } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./DigitalInfo.styles"

export const DigitalInfo: React.FC<DigitalInfoProps> = (props) => {
  const theme = useTheme()

  const label = (
    <Styles.Label>
      {props.label}
    </Styles.Label>
  )

  return (
    <Styles.Container className={props.className}>
      <Styles.Value>
        {props.icon && <Styles.InfoIcon name={props.icon} color={theme.colors.primaryDark}/>}
        {props.value}
        <Styles.Unit>{props.unit}</Styles.Unit>
      </Styles.Value>
      {props.tooltip && (
        <Tooltip title={props.tooltip} placement="bottom">
          {label}
        </Tooltip>
      )}
      {!props.tooltip && label}
    </Styles.Container>
  )
}

interface DigitalInfoProps extends BaseProps {
  /** The data to display */
  value: string | number,

  /** Explaination of the data displayed */
  label: string,

  /** Content of the tooltip display when hovering the label */
  tooltip?: string,

  /** The unit of the value */
  unit?: string,

  /** Additionnal icon name for icon display */
  icon?: string,
}
