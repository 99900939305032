import { splitArray } from "../utilities/helpers"

import { SkinnyUserAttributeValue } from "../models/SkinnyUserAttributeValue"
import { SkinnyUserAttributeValueStatistics } from "../models/SkinnyUserAttributeValueStatistics"
import { USER_ATTRIBUTE } from "../models/UserAttribute"
import { AttributeValueProperties, USER_ATTRIBUTE_VALUE } from "../models/UserAttributeValue"

export class USER_ATTRIBUTE_SERVICE {
  /**
   * Return a new array of attributes that excludes the descendants or the specified attribute
   * @param collection Collection of attributes to edit
   * @param attribute Parent attribute whose descendants must be removed from collection
   */
  static attributesWithoutDescendantsOf(
    collection: USER_ATTRIBUTE[],
    attribute: USER_ATTRIBUTE,
  ): USER_ATTRIBUTE[]
  {
    const descendantIds = attribute.descendants(collection).map(attr => attr.id)

    return collection.filter(attr => !descendantIds.includes(attr.id))
  }

  /**
   * Get a collection of user attributes from a flat list of attribute values
   * @param attributeValues Array of attribute values to parse
   * @param shouldShakeHierarchyTree Whether the collection should be shaken of non-primary hierarchical attributes
   */
  static attributesFromAttributeValues(
    attributeValues: SkinnyUserAttributeValue[],
    shouldShakeHierarchyTree: boolean = false,
  ): USER_ATTRIBUTE[] {
    const attributes = Array.from(attributeValues.reduce((accumulator, attribute) => {
      const attributeOfValue = accumulator.get(attribute.attributeId)
      const childrenAttributeIds =
        Array.from(new Set(attribute.children.map(child => child.attributeId)))

      if (attributeOfValue) {
        childrenAttributeIds.forEach((id) => {
          if (!attributeOfValue.childrenAttributeIds.includes(id)) {
            attributeOfValue.childrenAttributeIds.push(id)
          }
        })

        attributeOfValue.values.push(USER_ATTRIBUTE_VALUE.fromSkinnyUserAttributeValue(attribute))
      }
      else {
        accumulator.set(attribute.attributeId, new USER_ATTRIBUTE({
          childrenAttributeIds,
          id: attribute.attributeId,
          isMainHierarchyHead: attribute.isMainHierarchyHead,
          name: attribute.name,
          parentAttributeId: attribute.parent && attribute.parent.attributeId,
          values: Array<AttributeValueProperties>({
            attributeId: attribute.attributeId,
            attributeLabel: attribute.name,
            id: attribute.valueId,
            label: attribute.value,
            parentValueId: attribute.parent && attribute.parent.valueId,
          }),
        }))
      }

      return accumulator
    }, new Map<number, USER_ATTRIBUTE>()).values())

    return shouldShakeHierarchyTree
      ? USER_ATTRIBUTE_SERVICE.attributesWithoutNonPrimaryHierarchy(attributes)
      : attributes
  }

  /**
   * Get a collection of user attributes from a flat list of attribute values with statistics
   * @param attributeValues Array of attribute values to parse
   * @param shouldShakeHierarchyTree Whether the collection should be shaken of orphan hierarchical attributes
   */
  static attributesFromAttributeValueStatistics(
    attributeValues: SkinnyUserAttributeValueStatistics[],
    shouldShakeHierarchyTree: boolean = false,
  ): USER_ATTRIBUTE[] {
    const attributes = Array.from(attributeValues.reduce((attributesAcc, attributeStats) => {
      const attributeOfValue = attributesAcc.get(attributeStats.attribute.attributeId)
      const childrenAttributeIds =
        Array.from(new Set(attributeStats.attribute.children.map(child => child.attributeId)))

      if (attributeOfValue) {
        attributeOfValue.values.push(new USER_ATTRIBUTE_VALUE({
          attributeId: attributeStats.attribute.attributeId,
          attributeLabel: attributeStats.attribute.name,
          id: attributeStats.attribute.valueId,
          isClusterUnviolated: attributeStats.isClusterUnviolated,
          label: attributeStats.attribute.value,
          parentValueId: attributeStats.attribute.parent && attributeStats.attribute.parent.valueId,
          usersCount: attributeStats.usersCount,
        }))

        childrenAttributeIds.forEach((id) => {
          if (!attributeOfValue.childrenAttributeIds.includes(id)) {
            attributeOfValue.childrenAttributeIds.push(id)
          }
        })
      }
      else {
        attributesAcc.set(attributeStats.attribute.attributeId, new USER_ATTRIBUTE({
          childrenAttributeIds,
          id: attributeStats.attribute.attributeId,
          isMainHierarchyHead: attributeStats.attribute.isMainHierarchyHead,
          name: attributeStats.attribute.name,
          parentAttributeId: attributeStats.attribute.parent && attributeStats.attribute.parent.attributeId,
          values: Array<AttributeValueProperties>({
            attributeId: attributeStats.attribute.attributeId,
            attributeLabel: attributeStats.attribute.name,
            id: attributeStats.attribute.valueId,
            isClusterUnviolated: attributeStats.isClusterUnviolated,
            label: attributeStats.attribute.value,
            parentValueId: attributeStats.attribute.parent && attributeStats.attribute.parent.valueId,
            usersCount: attributeStats.usersCount,
          }),
        }))
      }

      return attributesAcc
    }, new Map<number, USER_ATTRIBUTE>()).values())

    return shouldShakeHierarchyTree
      ? USER_ATTRIBUTE_SERVICE.attributesWithoutNonPrimaryHierarchy(attributes)
      : attributes
  }

  /**
   * Return a new collection of attributes that excludes all non-primary hierarchies
   * @param attributes Collection of attributes to filter
   */
  static attributesWithoutNonPrimaryHierarchy(attributes: USER_ATTRIBUTE[]): USER_ATTRIBUTE[] {
    const secondaryHierarchyHeads = attributes
      .filter(attr => attr.isHierarchical && attr.parentAttributeId === null && !attr.isMainHierarchyHead)

    const secondaryHierarchyAttributeIds = secondaryHierarchyHeads.reduce(
      (ids, head) => [ ...ids, ...head.descendants(attributes).map(d => d.id) ],
      secondaryHierarchyHeads.map(attr => attr.id),
    )

    return attributes.filter(attribute => {
      return !attribute.isHierarchical || !secondaryHierarchyAttributeIds.includes(attribute.id)
    })
  }

  /**
   * Get the selected value of the specified attribute
   * @param attribute Attribute whose value must be returned
   * @param selection Selected attributes with their selected values
   */
  static selectedAttributeValueOf(
    attribute: USER_ATTRIBUTE,
    selection: USER_ATTRIBUTE[],
  ): USER_ATTRIBUTE_VALUE | undefined
  {
    const attributeSelection = selection.find(a => a.id === attribute.id)

    if (attributeSelection && attributeSelection.values.length) {
      return attributeSelection.values[0]
    }

    return undefined
  }

  /**
   * Check that the provided attribute selection is valid
   * @param selection Collection of attributes with their selection
   * @param reference Collection of all attributes
   */
  static isAttributeSelectionValid(
    selection: USER_ATTRIBUTE[],
    reference: USER_ATTRIBUTE[],
  ): boolean {
    for (const referenceAttribute of reference) {
      const attributeInSelection = selection.find(a => a.id === referenceAttribute.id)

      if (!attributeInSelection || attributeInSelection.values.length === 0) {
        return false
      }

      const selectedValue = attributeInSelection.values[0]

      if (!referenceAttribute.values.find(referenceValue => referenceValue.id === selectedValue.id)) {
        return false
      }
    }

    return true
  }

  /**
   * Get a collection of hierarchically sorted attributes
   * @throws If the given collection doesn't contain a main hierarchy head attribute
   *
   * Hierarchical attributes are guaranteed to be contiguous and sorted according to their hierarchy.
   * Non hierarchical attributes will be pushed to the end of the array with preserved order
   * @param attributes Collection of attributes to sort
   * @param compareFn A comparer function to sort simple attributes and attributes of the same hierarchy level
   */
  static hierarchicallySortedAttributes(
    attributes: USER_ATTRIBUTE[],
    compareFn?: (left: USER_ATTRIBUTE, right: USER_ATTRIBUTE) => number,
  ): USER_ATTRIBUTE[] {
    if (attributes.length === 0) {
      return []
    }

    const sortedAttributes = Array<USER_ATTRIBUTE[]>()

    // eslint-disable-next-line prefer-const
    let { truthy: unsortedAttributes, falsy: simpleAttributes }
      = splitArray(attributes, attribute => attribute.isHierarchical)

    if (unsortedAttributes.length !== 0) {
      let hierarchyHeads: USER_ATTRIBUTE[]
      ({ truthy: hierarchyHeads, falsy: unsortedAttributes }
        = splitArray(unsortedAttributes, attribute => !!attribute.isMainHierarchyHead))

      if (hierarchyHeads.length === 0) {
        throw new Error("The given collection of attributes doesn't have any hierarchy head.")
      }

      sortedAttributes[0] = hierarchyHeads

      for (let i = 1; unsortedAttributes.length > 0; i++) {
        let children: USER_ATTRIBUTE[]
        ({ truthy: children, falsy: unsortedAttributes } = splitArray(
          unsortedAttributes,
          attribute =>
            attribute.parentAttributeId !== undefined
            && sortedAttributes[i - 1]
              .map(parent => parent.id)
              .includes(attribute.parentAttributeId),
        ))

        sortedAttributes[i] = typeof compareFn === "function" ? children.sort(compareFn) : children
      }
    }
    return [
      ...sortedAttributes,
      ...(typeof compareFn === "function" ? simpleAttributes.sort(compareFn) : simpleAttributes),
    ].flat(2)
  }
}
