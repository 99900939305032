import * as React from "react"
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom"

import { APPLICATION_URL } from "./ApplicationNavigation"
import { useSession } from "features/Session"

export const WorkspacedRoute: React.FunctionComponent<WorkspacedRouteProps>
  = ({ component: Component, location,  ...rest }) =>
  {
    const session = useSession()

    return (
      <Route
        {...rest}
        render={(routeProps) => {
          if (!session.customer) {
            return (
              <Redirect
                to={{
                  pathname: APPLICATION_URL.base(),
                  state: { redirectUrlAfterLogin: location && location.pathname },
                }}
              />
            )
          }

          return <Component {...routeProps}/>
        }}
      />
    )
  }

interface WorkspacedRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps> | React.ComponentType,
}
