/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPsyReportsQueryVariables = Types.Exact<{
  psyDispositifNanoId: Types.Scalars['String'];
  dateInterval?: Types.InputMaybe<Types.DateIntervalInput>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetPsyReportsQuery = { getPsyReports: { count: number, list: Array<{ comment: string, date: string, issue: string, nanoId: string }> } };


export const GetPsyReportsDocument = gql`
    query GetPsyReports($psyDispositifNanoId: String!, $dateInterval: DateIntervalInput, $limit: Int, $skip: Int) {
  getPsyReports(psyDispositifNanoId: $psyDispositifNanoId, dateInterval: $dateInterval, limit: $limit, skip: $skip) {
    count
    list {
      comment
      date
      issue
      nanoId
    }
  }
}
    `;

/**
 * __useGetPsyReportsQuery__
 *
 * To run a query within a React component, call `useGetPsyReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPsyReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPsyReportsQuery({
 *   variables: {
 *      psyDispositifNanoId: // value for 'psyDispositifNanoId'
 *      dateInterval: // value for 'dateInterval'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPsyReportsQuery(baseOptions: Apollo.QueryHookOptions<GetPsyReportsQuery, GetPsyReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPsyReportsQuery, GetPsyReportsQueryVariables>(GetPsyReportsDocument, options);
      }
export function useGetPsyReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPsyReportsQuery, GetPsyReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPsyReportsQuery, GetPsyReportsQueryVariables>(GetPsyReportsDocument, options);
        }
export type GetPsyReportsQueryHookResult = ReturnType<typeof useGetPsyReportsQuery>;
export type GetPsyReportsLazyQueryHookResult = ReturnType<typeof useGetPsyReportsLazyQuery>;
export type GetPsyReportsQueryResult = Apollo.QueryResult<GetPsyReportsQuery, GetPsyReportsQueryVariables>;