import { Moment } from "moment"
import moment from "moment-timezone"

/**
 * Get a list of all timezones split by their area
 */
export function timezonesByArea(): Map<string, string[]> {
  return moment.tz.names().reduce((tzByArea, timezoneName) => {
    const { area, location } = splitTimezoneName(timezoneName)
    const timezonesInArea = tzByArea.get(area)

    if (timezonesInArea) {
      timezonesInArea.push(location)
    }
    else if (location.length) {
      tzByArea.set(area, [ location ])
    }

    return tzByArea
  }, new Map<string, string[]>())
}

/**
 * Separate the timezone area from the timezone location in a full timezone name like Europe/Paris
 * @param timezoneName Full name of the timezone (e.g. Europe/Paris or America/Argentina/Rio_Gallegos)
 */
export function splitTimezoneName(fullTimezone: string): {area: string, location: string} {
  const [ area, ...splitLocation ] = fullTimezone.split("/")

  return {
    area,
    location: splitLocation.join("/"),
  }
}

/**
 * Get the given date in the browser timezone without converting it
 * @param date The timezoned date to parse
 */
export function localDateWithoutConversion(date: string | Moment): Date {
  const regex = new RegExp(/([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(?:.[0-9]+)?)[+-][0-9]{2}:[0-9]{2}/i)
  const parsedDate = moment.parseZone(date).toISOString(true)

  return moment(regex.exec(parsedDate)?.[1]).toDate()
}
