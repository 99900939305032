/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAvailableAppsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAvailableAppsQuery = { getAvailableApps?: Array<{ name: Types.FrontAppName, url: string }> | undefined };


export const GetAvailableAppsDocument = gql`
    query GetAvailableApps {
  getAvailableApps {
    name
    url
  }
}
    `;

/**
 * __useGetAvailableAppsQuery__
 *
 * To run a query within a React component, call `useGetAvailableAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailableAppsQuery, GetAvailableAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableAppsQuery, GetAvailableAppsQueryVariables>(GetAvailableAppsDocument, options);
      }
export function useGetAvailableAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableAppsQuery, GetAvailableAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableAppsQuery, GetAvailableAppsQueryVariables>(GetAvailableAppsDocument, options);
        }
export type GetAvailableAppsQueryHookResult = ReturnType<typeof useGetAvailableAppsQuery>;
export type GetAvailableAppsLazyQueryHookResult = ReturnType<typeof useGetAvailableAppsLazyQuery>;
export type GetAvailableAppsQueryResult = Apollo.QueryResult<GetAvailableAppsQuery, GetAvailableAppsQueryVariables>;