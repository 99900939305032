import lodash from "lodash"
import Moment from "moment"
import React from "react"
import { t } from "ttag"
import { Badge } from "@material-ui/core"
import { motion } from "framer-motion"

import { useSession } from "features/Session"
import ScheduledSurveyStatus from "features/Surveys/ScheduledSurveyList/ScheduledSurveyCard/ScheduledSurveyStatus"
import { BaseProps, SkeletonWrapper, Tooltip, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import { Survey, SurveyScheduling, Theme } from "models/Survey/index"
import { Scheduled_Survey_Status } from "models/generated"
import * as Styles from "./ScheduledSurveyInfo.styles"

const ScheduledSurveyInfoComponent: React.FC<ScheduledSurveyInfoProps> = (props) => {
  const session = useSession()

  const isCompact = props.isMini || props.isCompact

  const dateFormat = props.isMini ? "L" : props.isCompact ? "L LT" : "LLLL"
  const formattedStartDate = Moment.parseZone(props.scheduling?.beginAt.local).format(dateFormat)
  const formattedEndDate = Moment.parseZone(props.scheduling?.endAt.local).format(dateFormat)

  const isScheduledSurveyTodo = props.scheduling.status.absolute === Scheduled_Survey_Status.Todo

  const scheduledSurveyGroups = props.scheduling?.userGroups?.map((userGroup) => userGroup.uuid)
  const scheduledSurveyGroupsCount = props.scheduling?.userGroups?.length

  const availableUserGroups = session.users?.userGroups.map((userGroup) => userGroup.uuid)
  const areGroupsEqual = lodash.isEqual(scheduledSurveyGroups, availableUserGroups)

  const themeIcon = <Styles.ThemeIcon
    icon={props.surveyTheme?.icon}
    name={!props.isMini ? props.surveyTheme?.name : undefined}
    color={props.surveyTheme?.color}
    size={props.isMini ? "small" : isCompact ? "medium" : "large"}
  />

  return (
    <Styles.Container $isCompact={props.isCompact} $isMini={props.isMini} className={props.className}>
      <Styles.ThemeContainer>
        {props.isMini && (
          <Badge
            classes={{ badge: "StatusContainer" }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            badgeContent={<ScheduledSurveyStatus isCompact status={props.scheduling.status.absolute}/>}
            showZero
          >
            {themeIcon}
          </Badge>
        )}
        {!props.isMini && themeIcon}
      </Styles.ThemeContainer>
      <div style={{ overflow: "hidden" }}>
        <Styles.Content
          $isCompact={props.isCompact && !props.isMini}
          $withUserGroupsSection={!!props.withUserGroups}
          $isScrollbarVisible={props.isScrollbarVisible}
          whileHover={props.withUserGroups && !props.isMini
            ? {
              transition: { duration: .7 },
              x: -160,
            }
            : undefined
          }
        >
          <motion.div
            whileHover={props.withUserGroups && !props.isMini
              ? { opacity: 0, transition: { duration: .7 } }
              : undefined
            }
          >
            {!isCompact && (
              <Styles.ThemeName>
                {props.surveyTheme?.name}
              </Styles.ThemeName>
            )}
            {!props.isMini && (
              <Tooltip title={props.survey?.name} enterDelay={1500}>
                <Styles.Title>
                  {props.survey?.name}
                </Styles.Title>
              </Tooltip>
            )}
            <Styles.ActivePeriod>
              {isCompact
                ? t`from ${formattedStartDate}\n to ${formattedEndDate}`
                : t`Sent on ${formattedStartDate}  -  Closed on ${formattedEndDate}`
              }
            </Styles.ActivePeriod>
          </motion.div>

          {props.isCompact && props.withStatus && (
            <ScheduledSurveyStatus status={props.scheduling.status.absolute}/>
          )}

          {props.withUserGroups &&
            <Styles.ScheduledSurveyGroupsCard>
              <Styles.ScheduledSurveyGroupsList>
                <Styles.Title>{!isScheduledSurveyTodo ? t`Sent to:` : t`Send to:`}</Styles.Title>
                {areGroupsEqual ? (
                  <Styles.ScheduledSurveyGroupItem>
                    {t`All groups`}
                  </Styles.ScheduledSurveyGroupItem>
                ) : (
                  props.scheduling.userGroups?.map((userGroup, index) => (
                    <Styles.ScheduledSurveyGroupItem isDeleted={!!userGroup.deletedAt} key={userGroup.uuid}>
                      {userGroup.name}
                      {scheduledSurveyGroupsCount && index !== scheduledSurveyGroupsCount - 1 && ","}
                    </Styles.ScheduledSurveyGroupItem>
                  ))
                )}
              </Styles.ScheduledSurveyGroupsList>
            </Styles.ScheduledSurveyGroupsCard>
          }
        </Styles.Content>
      </div>

    </Styles.Container>
  )
}

const ScheduledSurveyInfoSkeleton: React.FC<ScheduledSurveyInfoSkeletonProps> = (props) => (
  <Styles.Container $isCompact={props.isCompact} className={props.className}>

    <Styles.ThemeContainer>
      <SkeletonWrapper isLoading circle={true} height={35} width={35}/>
    </Styles.ThemeContainer>

    <Styles.Content>
      {!props.isCompact && (
        <Styles.ThemeName>
          <SkeletonWrapper isLoading/>
        </Styles.ThemeName>
      )}
      <SkeletonWrapper isLoading width={100}/>
      <Styles.ActivePeriod>
        <SkeletonWrapper isLoading/>
      </Styles.ActivePeriod>
    </Styles.Content>

  </Styles.Container>
)

export const ScheduledSurveyInfo = withSkeleton(ScheduledSurveyInfoComponent, ScheduledSurveyInfoSkeleton)

type ScheduledSurveyInfoSkeletonProps = Pick<ScheduledSurveyInfoProps, "isCompact" | "className">

interface ScheduledSurveyInfoProps extends BaseProps {
  /** Scheduled survey information to display  */
  scheduling: SurveyScheduling,

  /** Theme reference for this scheduled survey */
  surveyTheme: Theme,

  /** Survey reference for this scheduled survey */
  survey: Survey,

  /** Whether to show the userGroups section */
  withUserGroups?: boolean,

  /** Whether to show the status section  */
  withStatus?: boolean,

  /** If true reduce the component size and wordings */
  isCompact?: boolean,

  /** If true reduce the component size and wordings as much as possible */
  isMini?: boolean,

  isScrollbarVisible?: boolean,
}

ScheduledSurveyInfo.defaultProps = {
  withStatus: true,
}
