import { create } from "zustand"

interface PsyDispositifNanoIdState {
  psyDispositifNanoId: string,
  setPsyDispositifNanoId: (nanoId: string) => void,
}

export const usePsyDispositifNanoId = create<PsyDispositifNanoIdState>()((set) => ({
  psyDispositifNanoId: "",
  setPsyDispositifNanoId: (newNanoId) => set({ psyDispositifNanoId: newNanoId }),
}))

