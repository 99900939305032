import React from "react"
import { t } from "ttag"
import { ApolloError } from "@apollo/client/errors"

import { CenteredLoader, PrimaryButton, Select, Table, TableRowData, Title } from "@humanpredictiveintelligence/myqvt-library"
import { NotificationContext } from "features/Notification"
import { usePermissions } from "features/Permissions/PermissionsContext"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"
import { SkinnyUserCharacteristic } from "models/SkinnyUser"
import { SkinnyUserAttributeValue } from "models/SkinnyUserAttributeValue"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import * as Types from "models/generated"
import * as Styles from "./RecipientsSelectionDialog.styles"

import { useMyAssigneesPermissionsQuery } from "graphql/queries/generated/MyAssigneesPermissions"
import { useUserAttributesQuery } from "graphql/queries/generated/UserAttributeValues"
import { useEnableUserPermissionsMutation } from "graphql/mutations/generated/EnableUserPermissions"

export const RecipientsSelectionDialog: React.FC<RecipientsSelectionDialogProps> = (props) => {
  const notification = React.useContext(NotificationContext)
  const { fetchAdmins } = usePermissions()

  const tableLimitOffset = 30
  const [ tableLimit, setTableLimit ] = React.useState(tableLimitOffset)

  const [ nameInputValue, setNameInputValue ] = React.useState<string>("")
  const [ nameFilter, setNameFilter ] = React.useState<string>("")
  const [ attributeSelected, setAttributeSelected ] = React.useState(Array<USER_ATTRIBUTE>())
  const [ attributeFilter, setAttributeFilter ] = React.useState(Array<number>())

  const {
    loading: isLoadingAssignees,
    data: assigneesData,
    refetch: refetchAssignees,
  } = useMyAssigneesPermissionsQuery({
    variables: {
      filterAttributeIds: attributeFilter,
      limit: tableLimit,
      search: nameFilter,
    },
  })

  const { data: userAttributesData } = useUserAttributesQuery()

  const [ enableUserPermissions ] = useEnableUserPermissionsMutation()

  const userAttributes = USER_ATTRIBUTE_SERVICE.attributesFromAttributeValues(
    (userAttributesData && userAttributesData.attributeValues) || Array<SkinnyUserAttributeValue>(),
    true,
  )

  const userHierarchy = userAttributes?.filter(attribute => attribute.isHierarchical)

  React.useEffect(() => {
    const timeOutId = setTimeout(() => setNameFilter(nameInputValue), 500)

    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
    }
  }, [ nameInputValue ])

  React.useEffect(() => {
    setAttributeFilter(attributeSelected.map((attribute) => attribute.values[0].id))
  }, [ attributeSelected ])

  const recipientRows = recipientRowsData(assigneesData?.me?.users?.list ?? [])

  const totalAssigneesCount = assigneesData?.me?.users?.assigneesCount || 0
  const currentAssigneesCount = assigneesData?.me?.users?.list?.length || 0
  const remainingAssigneesCount = totalAssigneesCount - currentAssigneesCount

  return (
    <Styles.Dialog
      classes={{ paper: "Container" }}
      open={!!props.isOpen}
    >
      <Styles.Header>
        <Title level="metricCard">{t`Recipients list`}</Title>
        <Styles.Filters>
          <Styles.NameFilter
            placeholder={t`Filter by name`}
            onChange={(value) => setNameInputValue(value ?? "")}
            value={nameInputValue}
          />
          <Styles.AttributesFilters>
            {userHierarchy?.map((attribute) => {
              let currentlySelectedValue: number | undefined
              const attributeSelection = attributeSelected.find(a => a.id === attribute.id)

              if (attributeSelection && attributeSelection.values.length) {
                currentlySelectedValue = attributeSelection.values[0].id
              }

              let selectableValues = attribute.values
              if (attribute.isHierarchical) {
                const parentAttributeSelection
                  = attributeSelected.find(a => a.id === attribute.parentAttributeId)

                if (parentAttributeSelection && parentAttributeSelection.values.length) {
                  selectableValues
                    = selectableValues
                      .filter(value => value.parentValueId === parentAttributeSelection.values[0].id)
                }
              }

              return (<Select
                key={attribute.id}
                value={currentlySelectedValue}
                options={selectableValues.map(value => ({ value: value.id, wording: value.label }))}
                onChange={
                  (selection) => selection && updateFiltersAttributeValues(attribute, selection.value as number)
                }
                placeholder={attribute.name}
                isFullWidth
              />)
            })}
          </Styles.AttributesFilters>
          <Styles.ClearFiltersButton
            icon="delete_sweep"
            size="big"
            isDisabled={!nameInputValue && !attributeFilter}
            onClick={removeFilters} tooltip={t`Clear filters`}
          />
        </Styles.Filters>
      </Styles.Header>

      {isLoadingAssignees
        ? <CenteredLoader isTransparent/>
        : (
          <Table
            columns={[
              SkinnyUserCharacteristic.lastname,
              SkinnyUserCharacteristic.firstname,
            ]}
            columnDisplayNames={new Map([
              [ "lastname", t`Last Name` ],
              [ "firstname", t`First Name` ],
            ])}
            dataList={recipientRows}
            selectedIds={[]}
            onActionClicked={(selectedId) => handleActionClicked(Number(selectedId))}
            actionIcon="add"
            onShowMore={() => setTableLimit((tableLimit) => tableLimit + tableLimitOffset)}
            remainingCount={remainingAssigneesCount}
          />
        )
      }

      <Styles.Actions>
        <PrimaryButton isInverted onClick={props.onDialogClose}>{t`Close`}</PrimaryButton>
      </Styles.Actions>

    </Styles.Dialog>
  )

  function updateFiltersAttributeValues(attribute : USER_ATTRIBUTE, attributeValueId: number): void {
    let selectedAttributes = attributeSelected
    let attributeSelection = selectedAttributes.find(attr => attr.id === attribute.id)

    if (!attributeSelection) {
      attributeSelection = new USER_ATTRIBUTE(attribute)
      attributeSelection.values = []

      selectedAttributes.push(attributeSelection)
    }

    const attributeValueSelected =
      attribute.values.find(value => !!attributeValueId && value.id === attributeValueId)

    if (attributeValueSelected) {
      attributeSelection.values = [ attributeValueSelected ]
    } else {
      attributeSelection.values = []
    }

    // Remove children attributes from selection
    if (attribute.isHierarchical) {
      selectedAttributes = USER_ATTRIBUTE_SERVICE.attributesWithoutDescendantsOf(selectedAttributes, attribute)
    }

    setAttributeSelected([ ...selectedAttributes ])
  }

  function handleActionClicked(selectedId: number) {
    try {
      enableUserPermissions({ variables: { userId: selectedId } })
        .then(() => {
          notification.show(
            t`Success`,
            t`Permission has been granted.`,
            "success",
          )
        })
        .then(() => {
          refetchAssignees()
          fetchAdmins()
          props.onUserPermissionsEnabled?.()
        })
    } catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
      }
    }
  }

  /**
   * Get an array of TableRowData, one for each recipient given
   * @param recipientsList Recipients for which to create rows
   */
  function recipientRowsData(recipientsList: Array<(
      Pick<Types.User, "id">
      & { firstName: Types.User["first_name"], lastName: Types.User["last_name"] }
    )>,
  ): TableRowData[] {
    return recipientsList.map(user => {
      const userValues = new Map<string, string>()
      userValues.set(SkinnyUserCharacteristic.lastname, user.lastName)
      userValues.set(SkinnyUserCharacteristic.firstname, user.firstName)

      return {
        id: user.id.toString(),
        values: userValues,
      }
    })
  }

  function removeFilters() {
    setAttributeFilter([])
    setAttributeSelected([])
    setNameInputValue("")
    setNameFilter("")
  }
}

interface RecipientsSelectionDialogProps {
  isOpen?: boolean,
  onDialogClose: () => void,
  onUserPermissionsEnabled?: () => void,
}
