import styled from "styled-components/macro"
import { Card, Title } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled(Card)`
  display: grid;
  grid-template-rows: 60px auto;
  row-gap: 1rem;
  padding: 0;
  overflow: hidden;
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    height: 30rem;
    grid-template-rows: 60px 30% auto;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.below};
  padding-left: 2.4rem;
`

export const SectionTitle = styled(Title)`
  border: none;
  margin: 0;
  padding: 0;
`
