/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type UserPermissionFragmentFragment = { isGranted: boolean, permission: { code: Types.UserPermissionCode, wording: string, isScopable?: boolean | undefined, group: { code: string, wording: string }, parents?: Array<{ code: Types.UserPermissionCode }> | undefined } };

export const UserPermissionFragmentFragmentDoc = gql`
    fragment UserPermissionFragment on UserPermission {
  isGranted: isGranted
  permission {
    code
    wording
    isScopable: is_scopable
    group {
      code
      wording
    }
    parents {
      code
    }
  }
}
    `;