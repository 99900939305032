import React from "react"
import { t } from "ttag"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { Menu, MenuItem } from "@material-ui/core"

import { UserGroup } from "features/UserGroups/groups.model"
import * as Styles from "./GroupsListItem.styles"

export const GroupsListItem: React.FC<GroupsListItemProps> = (props) => {
  const [ menuAnchorEl, setMenuAnchorEl ] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(menuAnchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setMenuAnchorEl(null)
  }

  const options: MenuOption[] = [
    {
      action: props.onUserGroupRename,
      label: t`Rename`,
    },
    {
      action: props.onDeleteUserGroup,
      label: t`Delete`,
    },
  ]

  return (
    <Styles.Container
      $isSelected={props.isSelected}
      title={props.group.name}
      onClick={() => onItemClick(props.group.uuid)}
    >
      <Styles.GroupName>
        {props.group.name}
      </Styles.GroupName>

      <div>
        <Styles.MenuToggle onClick={handleClick}>
          <Icon
            name="more_vert"
            isInverted
          />
        </Styles.MenuToggle>
      </div>
      <Menu
        open={isMenuOpen}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={(event) => onMenuActionClick(event, option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Styles.Container>
  )

  function onItemClick(groupUuid: UserGroup["uuid"]) {
    props.onClick()
    return groupUuid
  }

  function onMenuActionClick(event: React.MouseEvent<HTMLLIElement>, option: MenuOption) {
    event.stopPropagation()
    option.action()
    handleClose(event)
  }
}

interface MenuOption {
  action: () => void,
  label: string,
}

export interface GroupsListItemProps {
  group: UserGroup,
  isSelected: boolean,
  onClick: () => void,
  onUserGroupRename: () => void,
  onDeleteUserGroup: () => void,
}
