import styled from "styled-components/macro"
import { CalendarView } from "@humanpredictiveintelligence/myqvt-library"

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CalendarContainer = styled.div`
  height: 100%;
  position: relative;

  display: grid;
  grid-template-rows: 1fr 96px;
`

export const Calendar = styled(CalendarView)`
  margin-top: 4px;
  
  .DayPicker {
    &-wrapper {
      position: relative;
      outline: none;
      padding-bottom: .5em;
    }
    
    &-Body {
      
    }

    &-NavButton {
      top: 0;
      z-index: 100;
    }

    &-Caption {
      margin-bottom: 12px;
    }

    &-Day {
      padding: 10px;
      position: relative;
      z-index: unset;

      &:not(.DayPicker-Day--outside) {
        &[class*="--survey"] {
          border-radius: 0;
          color: white;
        }

        &[class*="--scheduling-start"] {
          border-radius: 500px 0 0 500px;
        }

        &[class*="--scheduling-end"] {
          border-radius: 0 500px 500px 0;
        }
      }

      &--outside {
        background: white !important;
      }

      &--today:not(.DayPicker-Day--outside) {
        &:before {
          content: "";
          width: 36px;
          height: 36px;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          box-sizing: border-box;
          border-radius: 50%;
          border: 3px solid ${({ theme }) => theme.colors.primary};
        }
      }
    }

    &-Weekday {
      padding: 8px 0 8px 0;
    }

    &-Month {
      width: 100%;
      border-collapse: inherit;
      margin: 0.5em 0 0;
      z-index: 0;
    }
  }
`

export const DetailedSurvey = styled.div`
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};

  align-self: center;
`
