import { customerFromUrl } from "./helpers"

enum sessionStorageKey {
  customer = "customer",
  token = "token",
  bypassMaintenanceToken = "bypassMaintenanceToken"
}

enum localStorageKey {
  language = "hpi-language",
}

export abstract class STATIC_SESSION {
  /** Backend endpoint */
  static get applicationHostname(): string {
    if (!process.env.REACT_APP_APPLICATION_HOSTNAME) {
      throw new Error("Missing configuration: key 'REACT_APP_APPLICATION_HOSTNAME' is missing in environment variables")
    }

    return process.env.REACT_APP_APPLICATION_HOSTNAME
  }

  /** Backend endpoint */
  static get backendGraphql(): string {
    if (!process.env.REACT_APP_BACKEND_GQL) {
      throw new Error("Missing configuration: key 'REACT_APP_BACKEND_GQL' is missing in environment variables")
    }

    return process.env.REACT_APP_BACKEND_GQL
  }

  /** Backend authentication path */
  static get backendAuthPath(): string {
    if (!process.env.REACT_APP_BACKEND_AUTH_PATH) {
      throw new Error("Missing configuration: key 'REACT_APP_BACKEND_AUTH_PATH' is missing in environment variables")
    }

    return process.env.REACT_APP_BACKEND_AUTH_PATH
  }

  /** Backend raw endpoint (pathless) */
  static get backendHost(): string {
    if (!process.env.REACT_APP_BACKEND_HOST) {
      throw new Error("Missing configuration: key 'REACT_APP_BACKEND_HOST' is missing in environment variables")
    }

    return process.env.REACT_APP_BACKEND_HOST
  }

  /** Customer name to use for queries */
  static get customer(): string {
    let customer = localStorage.getItem(sessionStorageKey.customer)

    if (!customer) {
      const urlSubdomain = customerFromUrl()

      if (!urlSubdomain) {
        throw new Error("Incomplete URL: customer name is missing in URL (customer.domain.tld)")
      }

      customer = urlSubdomain
      STATIC_SESSION.customer = customer
    }

    return customer
  }

  static set customer(value: string) {
    localStorage.setItem(sessionStorageKey.customer, value)
  }

  /** Authentication token for backend queries */
  static get authenticationToken(): string | undefined {
    return localStorage.getItem(sessionStorageKey.token) || undefined
  }

  static set authenticationToken(token: string | undefined) {
    if (token) {
      localStorage.setItem(sessionStorageKey.token, token)
    }
    else {
      localStorage.removeItem(sessionStorageKey.token)
    }
  }

  /** Language of the user */
  static get language(): string | undefined {
    return localStorage.getItem(localStorageKey.language) || undefined
  }

  static set language(language: string | undefined) {
    if (language) {
      localStorage.setItem(localStorageKey.language, language)
    }
    else {
      localStorage.removeItem(localStorageKey.language)
    }
  }

  /** Token used to bypass maintenance mode */
  static get bypassMaintenanceToken(): string | undefined {
    return localStorage.getItem(sessionStorageKey.bypassMaintenanceToken) || undefined
  }

  static set bypassMaintenanceToken(bypassToken: string | undefined) {
    if (bypassToken) {
      localStorage.setItem(sessionStorageKey.bypassMaintenanceToken, bypassToken)
    }
    else {
      localStorage.removeItem(sessionStorageKey.bypassMaintenanceToken)
    }
  }

  static get contactEmail(): string {
    if (!process.env.REACT_APP_CONTACT_EMAIL) {
      throw new Error("Missing configuration: key 'REACT_APP_CONTACT_EMAIL' is missing in environment variables")
    }

    return process.env.REACT_APP_CONTACT_EMAIL
  }

  static get contactEmailVL(): string {
    if (!process.env.REACT_APP_CONTACT_EMAIL_VL) {
      throw new Error("Missing configuration: key 'REACT_APP_CONTACT_EMAIL_VL' is missing in environment variables")
    }

    return process.env.REACT_APP_CONTACT_EMAIL_VL
  }
}
