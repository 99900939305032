import styled from "styled-components/macro"
import { AnimationProps } from "framer-motion/types/motion/types"

export const RecipientImportPanel = styled.div`
  width: 100%;
  height: 360px;
  display: grid;
  grid-template-columns: 1fr 2fr; 
`

export const FileImportSection = styled.div`
  height: 360px;
  display:flex;
  justify-content: center;
  align-items: center;
  
  background-color: ${({ theme }) => theme.colors.blackUltraLight};
  border-right: 1px solid ${({ theme }) => theme.colors.blackMedium};
`

export const ProcessImportSection = styled.div`
  & > *:nth-child(2) {
    border-top: 1px solid ${({ theme }) => theme.colors.blackMedium};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blackMedium};
  }
`

export const ConfirmationSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;

  height: 100%;
  padding: 0 24px;
`

export const ConfirmationButtonRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width : 80%;
`

export const defaultTransition: AnimationProps["transition"] = { duration: 0.5, ease: [ 0.04, 0.62, 0.23, 0.98 ] }
export const delayedTransition: AnimationProps["transition"] = {
  delay: 1,
  duration: 1,
  ease: [ 0.04, 0.62, 0.23, 0.98 ],
}
