/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPsyAttributeStatisticsQueryVariables = Types.Exact<{
  psyDispositifNanoId: Types.Scalars['String'];
  psyAttributeCodes: Array<Types.Scalars['String']> | Types.Scalars['String'];
  dateInterval?: Types.InputMaybe<Types.DateIntervalInput>;
}>;


export type GetPsyAttributeStatisticsQuery = { getPsyAttributeStatistics: Array<{ label: string, psyReportsCount: number, psyRecipientsCount: number, psyAttributeValueStatistics: Array<{ value: string, psyReportsCount: number, psyRecipientsCount: number }> }> };


export const GetPsyAttributeStatisticsDocument = gql`
    query GetPsyAttributeStatistics($psyDispositifNanoId: String!, $psyAttributeCodes: [String!]!, $dateInterval: DateIntervalInput) {
  getPsyAttributeStatistics(psyDispositifNanoId: $psyDispositifNanoId, psyAttributeCodes: $psyAttributeCodes, dateInterval: $dateInterval) {
    label
    psyReportsCount
    psyRecipientsCount
    psyAttributeValueStatistics {
      value
      psyReportsCount
      psyRecipientsCount
    }
  }
}
    `;

/**
 * __useGetPsyAttributeStatisticsQuery__
 *
 * To run a query within a React component, call `useGetPsyAttributeStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPsyAttributeStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPsyAttributeStatisticsQuery({
 *   variables: {
 *      psyDispositifNanoId: // value for 'psyDispositifNanoId'
 *      psyAttributeCodes: // value for 'psyAttributeCodes'
 *      dateInterval: // value for 'dateInterval'
 *   },
 * });
 */
export function useGetPsyAttributeStatisticsQuery(baseOptions: Apollo.QueryHookOptions<GetPsyAttributeStatisticsQuery, GetPsyAttributeStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPsyAttributeStatisticsQuery, GetPsyAttributeStatisticsQueryVariables>(GetPsyAttributeStatisticsDocument, options);
      }
export function useGetPsyAttributeStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPsyAttributeStatisticsQuery, GetPsyAttributeStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPsyAttributeStatisticsQuery, GetPsyAttributeStatisticsQueryVariables>(GetPsyAttributeStatisticsDocument, options);
        }
export type GetPsyAttributeStatisticsQueryHookResult = ReturnType<typeof useGetPsyAttributeStatisticsQuery>;
export type GetPsyAttributeStatisticsLazyQueryHookResult = ReturnType<typeof useGetPsyAttributeStatisticsLazyQuery>;
export type GetPsyAttributeStatisticsQueryResult = Apollo.QueryResult<GetPsyAttributeStatisticsQuery, GetPsyAttributeStatisticsQueryVariables>;