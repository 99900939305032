import { AdminPermission, AdminPermissionMap, PermissionsGroup, PermissionsRelationsMap } from "features/Permissions/models"
import { UserPermissionCode } from "models/generated"

/** Create an array of permission groups composed of admin permissions from the given collection of user permissions */
export function createPermissionsGroups(userPermissions: AdminPermission[]): PermissionsGroup[] {
  return userPermissions.reduce((accUserPermissionsGroup, userPermission) => {
    const groupIndex = accUserPermissionsGroup.findIndex(group => group.code === userPermission.permission.group.code)

    /** If the permission's group is already set, it push it else it create the group */
    if (groupIndex >= 0) {
      accUserPermissionsGroup[groupIndex].permissions.push(userPermission)
    }
    else {
      accUserPermissionsGroup.push({
        code: userPermission.permission.group.code,
        permissions: [ userPermission ],
        wording: userPermission.permission.group.wording,
      })
    }

    return accUserPermissionsGroup
  }, new Array<PermissionsGroup>())
}

/** Create a Map of user permissions Map<userPermissionCode, userPermission>
 * and a Map of all parent to children relations, Map<parentCode, childCode[]>
 * from the given collection of user permissions */
export function initializePermissions(
  userPermissions: AdminPermission[],
): [ AdminPermissionMap, PermissionsRelationsMap ] {
  const userPermissionsMap: AdminPermissionMap = new Map()
  const permissionsRelations: PermissionsRelationsMap = new Map()

  userPermissions.forEach(userPermission => {
    let areAllParentsGranted = false
    /** Remove backoffice_view from all parents */
    const filteredParents =
      userPermission.permission.parents?.filter(parent => parent.code !== UserPermissionCode.BackofficeView) ?? []

    /** Save all userPermissions children code in permissionsRelations  */
    filteredParents.forEach(parent => {
      const userPermissionParent =
        userPermissions.find(userPermission => userPermission.permission.code === parent.code)
      areAllParentsGranted = !!userPermissionParent?.isGranted

      if (!permissionsRelations.get(parent.code)) {
        permissionsRelations.set(parent.code, [])
      }

      permissionsRelations.get(parent.code)?.push(userPermission.permission.code)
    })

    userPermissionsMap.set(
      userPermission.permission.code,
      Object.assign({
        ...userPermission,
        permissions: {
          ...userPermission.permission,
          parents: filteredParents,
        },
      }, {
        /** disable the permission if it does not have all of its parents granted */
        isDisabled: !!filteredParents.length && !userPermission.isGranted && !areAllParentsGranted,
      }),
    )
  })

  return [ userPermissionsMap, permissionsRelations ]
}

/** Update all children recursively starting from the given array of userPermissions */
export function updatePermissionsState(
  userPermissionsToUpdateFrom: AdminPermission[],
  userPermissions: AdminPermissionMap,
  savedUserPermissions: AdminPermissionMap,
  permissionsRelations: PermissionsRelationsMap,
): AdminPermissionMap {
  /** Return the userPermissions if there are no more permissions tu update */
  if (!userPermissionsToUpdateFrom.length) {
    return userPermissions
  }

  const userPermissionsChildrenToUpdateFrom: AdminPermission[] = []

  userPermissionsToUpdateFrom.forEach(userPermissionToUpdateFrom => {
    const childrenToUpdateCode = permissionsRelations.get(userPermissionToUpdateFrom.permission.code)

    childrenToUpdateCode?.forEach(childCode => {
      const userPermissionChild = userPermissions.get(childCode)

      if (userPermissionChild) {
        if (userPermissionToUpdateFrom.isGranted) {
          const parentsCode = userPermissionChild.permission.parents?.map(parent => parent.code)

          userPermissionChild.isDisabled =
            !!parentsCode?.find(parentCode => !userPermissions.get(parentCode)?.isGranted)
        }
        else {
          userPermissionChild.isGranted = false
          userPermissionChild.isDisabled = true

          userPermissionsChildrenToUpdateFrom.push(userPermissionChild)
        }
      }
    })
  })

  return updatePermissionsState(
    userPermissionsChildrenToUpdateFrom,
    userPermissions,
    savedUserPermissions,
    permissionsRelations,
  )
}
