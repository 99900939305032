import styled, { css } from "styled-components/macro"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div<{ $width?: number, $height?: number }>`
  ${({ $width, $height }) => css`
    width: ${$width ? $width + "px" : "100%"};
    height: ${$height ? $height + "px" : "100%"};
  `}
`

export const Title = styled.div`
  margin-top: 2rem;
  font-family: ${({ theme }) => theme.typography.fontSizeTitleBig};
`

export const ChartLabel = styled.tspan`
  max-width: 180px;
  overflow: auto;
  text-overflow: ellipsis;
`

export const Tooltip = styled(Card)`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  max-width: 250px;
  `

export const TooltipLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.fontSizeLabelSmall};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  margin: 0;
`

export const TooltipValue = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeTitleSmall};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0 0.5rem 0;
`
