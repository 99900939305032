import { useSession } from "features/Session"
import { UserPermissionCode } from "models/generated"

/**
 * Get a permission-check function
 * @returns
 *  a function to check a permission against the user's permissions set.
 */
export function useUserPermissions(): typeof isUserAllowed {
  const { user } = useSession()

  /**
   * Get whether a user is allowed the given permissions
   * @param permissionsName a list of permission from the permissions config file
   *
   * @example
   * isUserAllowed(ScheduledSurvey.Read)
   * isUserAllowed(Recipients.Read, Recipients.Manage)
   */
  function isUserAllowed(...permissionsName: UserPermissionCode[]): boolean {
    return permissionsName.every(permissionName => {
      return !!user?.permissions?.find(userPermission => {
        return userPermission.permission.code === permissionName
      })?.isGranted
    })
  }

  return isUserAllowed
}

export type isUserAllowedFunction = ReturnType<typeof useUserPermissions>
