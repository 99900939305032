import React from "react"

import { DataAnonymizationIllustration } from "../DataAnonymizationIllustration"
import { SkeletonProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ScheduledSurveyResultsHeader.styles"

export const ScheduledSurveyResultsHeaderSkeleton: React.FC<SkeletonProps> = () => (
  <Styles.SkeletonContainer>
    <DataAnonymizationIllustration/>
  </Styles.SkeletonContainer>
)
