import { ApolloError } from "@apollo/client/errors"
import { DialogActions, DialogContent } from "@material-ui/core"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import * as React from "react"
import { t } from "ttag"
import { ValidationError } from "yup"

import {
  AdminOverwriteConfirmationDialog,
  fieldValuesComparison,
} from "./AdminOverwriteConfirmationDialog"
import { EmailState, useEmailVerificationService } from "features/Permissions/useEmailVerification"
import { recipientFormSchema } from "./formValidationSchema"
import { useSession } from "features/Session"
import { NotificationContext } from "features/Notification"
import { useTutorial } from "features/Tutorial"
import { PrimaryButton, zIndexSpeakup } from "@humanpredictiveintelligence/myqvt-library"
import { SkinnyAttributedUser, SkinnyUser, SkinnyUserCharacteristic } from "models/SkinnyUser"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import { SkinnyUserAttributeValue } from "models/SkinnyUserAttributeValue"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"
import { splitTimezoneName, timezonesByArea } from "utilities/timezone"
import * as Styles from "./RecipientFormDialog.styles"

export const RecipientFormDialog: React.FC<RecipientFormDialogProps> = (props) => {
  const session = useSession()
  const { whenTutorialActiveRun, isTutorialActive } = useTutorial()
  const verifyEmailEligibility = useEmailVerificationService()
  const notification = React.useContext(NotificationContext)

  const [ recipient, setRecipient ] = React.useState<Partial<SkinnyUser>>({
    language: session.customer?.language,
    timezone: session.customer?.timezone,
  })
  const [ recipientHasChanged, setRecipientHasChanged ] = React.useState<boolean>(false)
  const [ recipientAttributes, setRecipientAttributes ] = React.useState(Array<USER_ATTRIBUTE>())
  const [ isEmailBeingVerified, setIsEmailBeingVerified ] = React.useState(false)
  const [ buttonToLoad, setButtonToLoad ] = React.useState<"add" | "addAnother">()

  const [ overWrittenFields, setOverWrittenFields ] = React.useState<fieldValuesComparison[]>()

  const hasUserHierarchy = props.userHierarchy.length > 0

  const timezonesMap = timezonesByArea()
  const [ timezoneArea, setTimezoneArea ] = React.useState(splitTimezoneName(recipient.timezone || "").area)

  const formValidationErrors = new Map<string, ValidationError>()
  const formErrorsDisplayed = new Map<string, string>()

  try {
    recipientFormSchema.validateSync(
      userWithSelectedAttributes(recipient as SkinnyUser, recipientAttributes),
      {
        abortEarly: false,
        context:
          {
            canSendSMS: session.customer!.canSendSMS,
            userAttributes: [ ...props.userAttributes, ...props.userHierarchy ],
          },
      },
    )
  } catch (error) {
    (error as ValidationError).inner.reduce((formErrors, fieldError) => {
      if (fieldError.type !== "required") {
        formErrorsDisplayed.set(fieldError.path, fieldError.message)
      }

      return formErrors.set(fieldError.path, fieldError)
    }, formValidationErrors)
  }

  // Update the values with the props.defaultRecipient
  React.useEffect(() => {
    if (props.defaultRecipient) {
      const recipientCopy = JSON.parse(JSON.stringify(props.defaultRecipient))

      setRecipient(recipientCopy)
      setRecipientAttributes(USER_ATTRIBUTE_SERVICE.attributesFromAttributeValues(recipientCopy.attributes))
      setTimezoneArea(recipientCopy.timezone.split("/")[0] || "")
    } else {
      setRecipient({
        language: session.customer?.language,
        timezone: session.customer?.timezone,
      })
      setRecipientAttributes([])
    }
  }, [ props.defaultRecipient, session.customer ])

  function updateLanguage(value?: string) {
    if (value) {
      const language = {
        code: value,
        localizedLabel: value,
        translatedLabel: value,
      }

      updateRecipient("language", language)
    }
  }

  return (
    <>
      <Styles.DialogContainer
        open
        data-tutorial={isTutorialActive()}
        maxWidth={hasUserHierarchy ? "md" : "sm"}
        PaperProps={{ innerRef: props.innerRef }}
        BackdropProps={{ open: !isTutorialActive() }}
        $zIndex={zIndexSpeakup.dialog}
        $isLarge={hasUserHierarchy}
        classes={{
          paperScrollBody: "Dialog__body",
          scrollBody: "DialogContainer__body",
        }}
        scroll="body"
      >

        <Styles.TitleContainer disableTypography>
          <Styles.DialogTitle>
            {props.defaultRecipient
              ? t`Update information`
              : t`Add a user`
            }
          </Styles.DialogTitle>
          <Styles.CloseIcon name="close" onClick={props.onCancel}/>
        </Styles.TitleContainer>
        <DialogContent>
          <Styles.UpperSection>
            <div>
              <Styles.SectionTitle level="section">{t`Identity`}</Styles.SectionTitle>
              <Styles.InputField
                label={t`Lastname`}
                value={(recipient && recipient.lastname) || ""}
                onChange={(value) => updateRecipient(SkinnyUserCharacteristic.lastname, value)}
                isDisabled={props.isLoading}
              />
              <Styles.InputField
                value={(recipient && recipient[SkinnyUserCharacteristic.firstname]) || ""}
                label={t`Firstname`}
                onChange={(value) => updateRecipient(SkinnyUserCharacteristic.firstname, value)}
                isDisabled={props.isLoading}
              />
              <Styles.InputField
                value={(recipient && recipient[SkinnyUserCharacteristic.email]) || ""}
                label={t`Email`}
                onChange={(value) => updateRecipient(SkinnyUserCharacteristic.email, value)}
                isErroneous={formErrorsDisplayed.has("email")}
                isHintErroneous
                hint={formErrorsDisplayed.get("email")}
                isDisabled={props.isLoading}
              />
              {session.customer!.canSendSMS &&
              <Styles.InputField
                value={(recipient.phone && parsePhoneNumber(recipient.phone)) || ""}
                label={t`Phone number`}
                onChange={(value) => updateRecipient(SkinnyUserCharacteristic.phone, value)}
                isErroneous={formErrorsDisplayed.has("phone")}
                isHintErroneous
                hint={formErrorsDisplayed.get("phone")}
                isDisabled={props.isLoading}
              />
              }
              <Styles.SelectField
                label={t`Language`}
                value={recipient?.language?.code}
                options={session.languages.map(
                  language => ({ value: language.code, wording: language.translatedLabel }),
                )}
                onChange={(value) => {
                  updateLanguage(value && value.value as string)
                }}
                placeholder={t`Select`}
                isFullWidth
                disabled={props.isLoading}
              />
              <Styles.TimeZoneField>
                <Styles.SelectField
                  label={t`Timezone area`}
                  value={timezoneArea}
                  options={Array.from(timezonesMap.keys()).map(tz => ({ value: tz, wording: tz }))}
                  onChange={(value) => {
                    updateRecipient("timezone", undefined)
                    setTimezoneArea(value?.wording as string || "")
                  }}
                  placeholder={t`Select`}
                  isFullWidth
                  disabled={props.isLoading}
                />
                <Styles.SelectField
                  label={t`Timezone location`}
                  value={(recipient && recipient.timezone) || session.customer?.timezone || ""}
                  disabled={!timezonesMap.has(timezoneArea) || props.isLoading}
                  options={
                    (timezonesMap.has(timezoneArea) && timezonesMap.get(timezoneArea)!.map(
                      location => ({ value: `${timezoneArea}/${location}`, wording: location }),
                    )) || []
                  }
                  onChange={
                    (value) => updateRecipient("timezone", value && value.value as string)
                  }
                  placeholder={t`Select`}
                  isFullWidth
                />
              </Styles.TimeZoneField>
            </div>
            {props.userHierarchy.length > 0 && (
              <div>
                <Styles.SectionTitle level="section">{t`Hierarchy`}</Styles.SectionTitle>
                {
                  props.userHierarchy.map(attribute => {
                    let currentlySelectedValue: number | undefined
                    const attributeSelection = recipientAttributes.find(a => a.id === attribute.id)

                    if (attributeSelection && attributeSelection.values.length) {
                      currentlySelectedValue = attributeSelection.values[0].id
                    }

                    let selectableValues = attribute.values
                    if (attribute.isHierarchical) {
                      const parentAttributeSelection
                        = recipientAttributes.find(a => a.id === attribute.parentAttributeId)

                      if (parentAttributeSelection && parentAttributeSelection.values.length) {
                        selectableValues
                          = selectableValues
                            .filter(value => value.parentValueId === parentAttributeSelection.values[0].id)
                      }
                    }

                    return (
                      <Styles.SelectField
                        key={attribute.id}
                        label={attribute.name}
                        options={selectableValues.map(value => ({ value: value.id, wording: value.label }))}
                        value={currentlySelectedValue}
                        onChange={
                          selection => updateRecipientAttribute(attribute, selection && selection.value as number)
                        }
                        isFullWidth
                        placeholder={t`Select`}
                        disabled={props.isLoading}
                      />
                    )
                  })
                }
              </div>
            )}
          </Styles.UpperSection>
          {props.userAttributes.length > 0 && (
            <>
              <Styles.SectionTitle level="section">{t`Attributes`}</Styles.SectionTitle>
              <Styles.Attributes>
                {props.userAttributes.map(attribute => {
                  const currentlySelectedValue
                    = USER_ATTRIBUTE_SERVICE.selectedAttributeValueOf(attribute, recipientAttributes)

                  return (
                    <Styles.SelectField
                      key={attribute.id}
                      label={attribute.name}
                      disabled={props.isLoading}
                      options={attribute.values.map(value => ({ value: value.id, wording: value.label }))}
                      value={currentlySelectedValue && currentlySelectedValue.id}
                      onChange={
                        selection => updateRecipientAttribute(attribute, selection && selection.value as number)
                      }
                      isFullWidth
                      placeholder={t`Select`}
                    />
                  )
                })}
              </Styles.Attributes>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            onClick={() => onValidate(userWithSelectedAttributes(recipient as SkinnyUser, recipientAttributes))}
            disabled={formValidationErrors.size > 0 || props.isLoading || !recipientHasChanged}
            isLoading={(props.isLoading || isEmailBeingVerified) && buttonToLoad === "add"}
          >
            {props.defaultRecipient ? t`Update` : t`Add`}
          </PrimaryButton>
          {!props.defaultRecipient &&
          <PrimaryButton
            isLoading={(props.isLoading || isEmailBeingVerified) && buttonToLoad === "addAnother"}
            onClick={
              () => onValidate(
                userWithSelectedAttributes(recipient as SkinnyUser, recipientAttributes),
                true,
              )
            }
            disabled={formValidationErrors.size > 0 || props.isLoading}
          >
            {t`Create and add another`}
          </PrimaryButton>
          }
        </DialogActions>
      </Styles.DialogContainer>

      {overWrittenFields && (
        <AdminOverwriteConfirmationDialog
          overWrittenFields={overWrittenFields}
          onCancel={() => setOverWrittenFields(undefined)}
          onSubmit={onAdminOverwriteConfirmation}
        />
      )}
    </>
  )

  /**
   * Add attributes to a user
   * @param user Base user
   * @param attributes Attributes to add to the user
   */
  function userWithSelectedAttributes<TUser extends SkinnyUser>(
    user: TUser,
    attributes: USER_ATTRIBUTE[],
  ): SkinnyAttributedUser<USER_ATTRIBUTE> {
    return Object.assign(user, {
      attributes,
    })
  }

  /**
   * Update a characteristic on the Recipient
   * @param characteristic Characteristic to update on the Recipient
   * @param value Value to set
   */
  function updateRecipient<Characteristic extends keyof SkinnyUser>(
    characteristic: Characteristic,
    value?: SkinnyUser[Characteristic],
  ) {
    setRecipientHasChanged(true)
    setRecipient(Object.assign({}, recipient, {
      [characteristic]: value,
    }))
  }

  /**
   * Update an attribute of the recipient
   * @param attribute Attribute to update
   * @param attributeValueId Value to select
   */
  function updateRecipientAttribute(attribute: USER_ATTRIBUTE, attributeValueId?: number) {
    let selectedAttributes = recipientAttributes
    let attributeSelection = selectedAttributes.find(attr => attr.id === attribute.id)

    if (!attributeSelection) {
      attributeSelection = new USER_ATTRIBUTE(attribute)
      attributeSelection.values = []

      selectedAttributes.push(attributeSelection)
    }

    const attributeValueSelected =
      attribute.values.find(value => !!attributeValueId && value.id === attributeValueId)

    if (attributeValueSelected) {
      attributeSelection.values = [ attributeValueSelected ]
    } else {
      attributeSelection.values = []
    }

    // Remove children attributes from selection
    if (attribute.isHierarchical) {
      selectedAttributes = USER_ATTRIBUTE_SERVICE.attributesWithoutDescendantsOf(selectedAttributes, attribute)
    }

    setRecipientHasChanged(true)
    setRecipientAttributes([ ...selectedAttributes ])
  }

  /**
   * Parse international phone number from input value
   * @param value Phone input value
   */
  function parsePhoneNumber(value: string): string {
    const phoneNumber = parsePhoneNumberFromString(value)

    return phoneNumber ? phoneNumber.number.toString() : value
  }

  /** Check email status before validation
   * If the email belongs to an admin and some fields are different
   * a modal is displayed for overwrite confirmation
   * @param recipient Recipient to create or edit
   * @param isAddAnotherButtonClicked Whether the add another button is clicked
   *
   * */
  async function onValidate(recipient: SkinnyAttributedUser<USER_ATTRIBUTE>, isAddAnotherButtonClicked = false) {
    setButtonToLoad(isAddAnotherButtonClicked ? "addAnother" : "add")
    setIsEmailBeingVerified(true)

    let overwrittenFields: fieldValuesComparison[] = []
    try {
      const emailStatus = await verifyEmailEligibility(recipient.email!)

      if (emailStatus.state === EmailState.FromAdmin) {
        const admin = emailStatus.admin
        overwrittenFields = checkForOverwrittenFields([
          { adminValue: admin.firstName, label: t`Firstname`, recipientValue: recipient.firstname },
          { adminValue: admin.lastName, label: t`Lastname`, recipientValue: recipient.lastname },
          { adminValue: admin.language.code, label: t`Language`, recipientValue: recipient.language.code },
          { adminValue: admin.timezone, label: t`Timezone area`, recipientValue: recipient.timezone },
        ])
      }
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
    setIsEmailBeingVerified(false)

    if (overwrittenFields.length > 0) {
      setOverWrittenFields(overwrittenFields)
    }
    else {
      props.onValidate(recipient, whenTutorialActiveRun(true, isAddAnotherButtonClicked))
    }
  }

  function checkForOverwrittenFields(fields: fieldValuesComparison[]) {
    return fields.filter(field => field.adminValue !== field.recipientValue)
  }

  async function onAdminOverwriteConfirmation() {
    if (overWrittenFields) {
      props.onValidate(
        userWithSelectedAttributes(recipient as SkinnyUser, recipientAttributes),
        whenTutorialActiveRun(true, buttonToLoad === "addAnother"),
        true,
      )
    }
  }
}

export interface RecipientFormDialogProps {
  /** Ref to the dialog */
  innerRef?: React.RefObject<HTMLDivElement>,

  /** Fired when the user validates the form */
  onValidate: (
    recipient: SkinnyAttributedUser<USER_ATTRIBUTE>,
    shouldEditAnotherRecipient?: boolean, isAdmin?: boolean
  ) => void,

  /** Fired when the user cancels the form */
  onCancel: () => void,

  /** Default value to fill the form */
  defaultRecipient?: SkinnyAttributedUser<SkinnyUserAttributeValue>,

  /** List of simple user attributes with their possible values */
  userAttributes: USER_ATTRIBUTE[],

  /** Ordered list of hierarchical attributes with their possible values */
  userHierarchy: USER_ATTRIBUTE[],

  /** Whether to disable inputs and show button loader */
  isLoading?: boolean,
}
