import moment from "moment"

import business from "../config/business"

export class STATISTICS_SERVICE {
  /**
   * Get a rounded and corrected detail of TOS acceptance percentages
   * @param acceptedCount Number of recipients who accepted the TOS
   * @param refusedCount Number of recipients who refused the TOS
   */
  static termsOfServiceAcceptancePercentages(
    recipientsCount?: number,
    acceptedCount: number = 0,
    refusedCount: number = 0,
  ) {
    if (recipientsCount === undefined || recipientsCount === 0) {
      return {
        accepted: 0,
        refused: 0,
        waiting: 0,
      }
    }

    const acceptedPercentage = Math.round((acceptedCount / recipientsCount) * 100)
    const refusedPercentage = Math.round((refusedCount / recipientsCount) * 100)

    let waitingPercentage = 100 - acceptedPercentage - refusedPercentage

    const totalPercentage = acceptedPercentage + refusedPercentage + waitingPercentage

    /* BR: The total of the rounded percentage must be 100.
     * If not, we alter the waiting percentage. */
    if (totalPercentage > 100) {
      waitingPercentage--
    }
    else if (totalPercentage < 100) {
      waitingPercentage++
    }

    return {
      accepted: acceptedPercentage,
      refused: refusedPercentage,
      waiting: waitingPercentage,
    }
  }

  /**
   * Get whether the recipients data is considered expired
   * @param latestRecipientsUpdateDate Date of the latest recipient update
   */
  static isRecipientsDataExpired(latestRecipientsUpdateDate: string | undefined) {
    if (!latestRecipientsUpdateDate) {
      return false
    }

    const latestUpdate = moment(latestRecipientsUpdateDate)
    const earliestPossibleUpdate = moment().subtract(...business.recipients.lastUpdateExpirationPeriod)

    return latestUpdate.isBefore(earliestPossibleUpdate)
  }
}
