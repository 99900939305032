import { Session } from "./Session"
import { SessionReducerAction } from "./SessionReducerAction"

/**
 * Reduce the current state according to the specified action and returns the new state
 * @param state Current session state
 * @param action Action to apply to the state
 */
export function SessionReducer(state: Session, action: SessionReducerAction): Session {
  switch (action.type) {
    case "SetUser":
      return ({
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      })
    case "SetUsers":
      return ({
        ...state,
        users: action.users,
      })
    case "SetCustomer":
      return ({
        ...state,
        customer: action.customer,
      })
    case "SetAuthentication":
      return ({
        ...state,
        authentication: action.authentication,
      })
    case "Unauthenticate":
      return ({
        ...state,
        authentication: {
          hasFailed: false,
          isAuthenticated: false,
          lastFailedAttempt: undefined,
        },
        user: undefined,
      })
    case "SetLanguages":
      return ({
        ...state,
        languages: [
          ...action.languages,
        ],
      })

    case "SetTutorialProgression":
      if (state.user) {
        return ({
          ...state,
          user: {
            ...state.user,
            settings: {
              ...state.user.settings,
              tutorialsProgression: [ action.tutorialProgression ],
            },
          },
        })
      }
      return state
  }
}
