import * as React from "react"
import { t } from "ttag"

import { Caption } from "@humanpredictiveintelligence/myqvt-library"
import { CompletionChart } from "@humanpredictiveintelligence/myqvt-library"
import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { NumberMetric } from "@humanpredictiveintelligence/myqvt-library"
import { Skeletonable } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"

export const CommentsMetrics: React.FC<Skeletonable<CommentsMetricsProps>> = (props) => {
  const overallCommentsCountDelta
    = (props.overallCommentsCountDelta && "+" + props.overallCommentsCountDelta) || undefined

  let averageCommentsCountPerQuestionDelta
  if (props.averageCommentsCountPerQuestionTrend) {
    averageCommentsCountPerQuestionDelta
      = props.averageCommentsCountPerQuestionTrend === "positive"
        ? t`increasing`
        : t`decreasing`
  }

  const responseToCommentRatio = props.responsesToCommentsRatio ? Math.round(props.responsesToCommentsRatio) : 0

  return (
    <div className={props.className}>
      <MetricsCard title={t`Comments`} hasReducedPadding>
        <NumberMetric
          isLoading={props.isLoading}
          value={props.overallCommentsCount}
          delta={overallCommentsCountDelta}
          deltaHint={t`Variation since previous survey`}
          deltaPolarity="positive"
        />
        <Caption>{t`Received comments`}</Caption>

        <NumberMetric
          isLoading={props.isLoading}
          value={props.averageCommentsCountPerQuestion}
          delta={averageCommentsCountPerQuestionDelta}
          deltaHint={t`Variation since previous survey`}
          deltaPolarity={props.averageCommentsCountPerQuestionTrend}
        />
        <Caption
          hint={t`${props.averageCommentsCountPerQuestionForLatestSurvey} in the latest survey`}
        >
          {t`Average comments count per question`}
        </Caption>

        <CompletionChart
          isLoading={props.isLoading}
          completionPercentage={responseToCommentRatio}
          delta={props.responsesToCommentsRatioDelta && Math.round(props.responsesToCommentsRatioDelta)}
          deltaHint={t`Variation since previous survey`}
          isDeltaFloating
          isSecondaryColor
        />
        <Caption
          hint={
            // Line break intended
            t`${props.overallCommentsCount} comments received,
            ${props.overallResponsesCount} responses sent`}
        >
          {t`Average response rate`}
        </Caption>
      </MetricsCard>
    </div>
  )
}

export interface CommentsMetricsProps extends BaseProps {
  /** Average number of comments per question */
  averageCommentsCountPerQuestion: number,

  /** Average number of comments for the latest survey */
  averageCommentsCountPerQuestionForLatestSurvey: number,

  /** Trend of the average number of comments per question
   * between the last survey and the previous one */
  averageCommentsCountPerQuestionTrend?: "positive" | "negative",

  /** Total number of comments */
  overallCommentsCount?: number,

  /** Delta between the total number of comments of
   * the latest survey and the previous one */
  overallCommentsCountDelta?: number,

  /** Total number of responses */
  overallResponsesCount?: number,

  /** Ratio of comments with responses over the total
   * number of comments of the latest survey */
  responsesToCommentsRatio?: number,

  /** Delta bewteen the responses to comments ratio of
   * the latest survey and the previous one */
  responsesToCommentsRatioDelta?: number,
}
