import styled, { css } from "styled-components/macro"
import MuiSelect from "@material-ui/core/Select"

import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { Option as LibraryOption } from "@humanpredictiveintelligence/myqvt-library"
import MenuItem from "@material-ui/core/MenuItem"
import { ListSubheader } from "@material-ui/core"

export const Select = styled(MuiSelect)<{ $isEmpty: boolean, $isSmall?: boolean }>`
  ${({ theme, disabled, $isEmpty, $isSmall }) => css`
            height: 40px;
            min-width: 36px;
        
            cursor: pointer;
        
            font-size: ${theme.typography.fontSizeButton};
            font-weight: ${theme.typography.fontWeightMedium};
            color: ${theme.colors.primary};
        
            border: none;
            border-radius: ${theme.sizes.borderRadiusRounded};
            background-color: ${theme.colors.white};
            box-shadow: ${theme.shadows.button};
        
            transition: background-color, box-shadow 0.2s;
            
            ${$isSmall && css`
              height: 32px;
              font-size: ${theme.typography.fontSizeTextSmaller};
              
              .MuiSelect-selectMenu {
                height: 32px;
                padding: 0 36px 0 16px;
              }
              
              ${InputText} { 
                line-height: 32px;
              }
            `}
            
            ${disabled && css`
              cursor: auto;
              color: ${theme.colors.blackLight};
              background-color: ${theme.colors.blackDisabled};
              box-shadow: none;
      
              // bump up css complexity to make rules take precedence over mui's
              ${!$isEmpty && css`
                && {
                  color: ${theme.colors.black};
                  font-weight: ${theme.typography.fontWeight};
                }
              `}
      
              &:hover {
                ${LabelText} {
                  color: ${theme.colors.black};
                }
              }
            `}
            
            ${!disabled && css`
              &:hover {
                color: ${theme.colors.black};
                box-shadow: ${theme.shadows.buttonHover};
              }
            `}
            
            ${$isEmpty && css`
              ${DropdownIcon} {
                color: ${theme.colors.blackMedium}
              }
            `}

            .MuiSelect-selectMenu {
              width: 100%;
              height: 40px;
              padding: 0 36px 0 16px;
      
              border: none;
              border-radius: ${theme.sizes.borderRadiusRounded};
              outline: 0;
            }
          `}
`

export const Label = styled.label`
  position: relative;
`

export const LabelText = styled.div<{ $isSelectFocused?: boolean }>`
  ${({ theme, $isSelectFocused }) => css`
    margin-bottom: 8px;

    color: ${theme.colors.blackMedium};
    font-size: ${theme.typography.fontSizeLabelSmaller};
    font-weight: ${theme.typography.fontWeightMedium};
    text-transform: uppercase;
    letter-spacing: 0.88px;

    transition: color 200ms;

    ${$isSelectFocused && css`
      color: ${theme.colors.primary};
    `}
  `}
`

export const InputText = styled.div`
  margin-left: 2px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const InputPlaceholder = styled(InputText)`
  color: ${({ theme }) => theme.colors.blackMedium};
  font-weight: ${({ theme }) => theme.typography.fontWeight};
`

export const DropdownIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 12px);
  right: 12px;
  pointer-events: none;
`

export const OptionGroup = styled(ListSubheader)`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.typography.fontSizeLabelSmaller};
    font-weight: ${theme.typography.fontWeightMedium};
    color: ${theme.colors.primary};

    line-height: inherit;
    margin-left: 26px;
    padding-top: 16px;
    padding-bottom: 8px;
  `}
`

export const OptionContainer = styled(MenuItem)`
  display: flex;
  flex-grow: 1;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 36px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }

  &:focus {
    background-color: transparent;
  }

  &.selected,
  &.selected:hover,
  &.selected:focus{
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }
`

export const Option = styled(LibraryOption)`
  margin-left: 8px;
`

export const DefaultOption = styled(Option)`
  color: ${({ theme }) => theme.colors.blackLight};
`

export const OptionIcon = styled.div`
  display: inline-flex;
  width: 16px;
  margin-right: 8px;
`
