import styled from "styled-components/macro"

export const Container = styled.div`
  min-height: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;

  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    height: 30rem;
    grid-template-rows: 8% 30% auto;
  }
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  gap: 12px;
  
  table {
    table-layout: auto !important;
    
    // Fixed width to prevent visual bug when displaying skeleton rows
    td, th {
      width: 20%;
      
      &:first-of-type {
        width: 12%;
      }
      
      &:last-of-type {
        width: auto;
      }
    }
  }
`

export const NavigationContainer = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
`
