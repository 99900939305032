/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProceduresQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProceduresQuery = { procedures: Array<{ id: number, step?: string | undefined, isDone: boolean, isErrored: boolean, isTerminated: boolean, isNotStartedYet: boolean, isInProgress: boolean, errors?: Array<{ step?: string | undefined, message: string }> | undefined } | { id: number, step?: string | undefined, isDone: boolean, isErrored: boolean, isTerminated: boolean, isNotStartedYet: boolean, isInProgress: boolean, errors?: Array<{ step?: string | undefined, message: string }> | undefined }> };


export const ProceduresDocument = gql`
    query Procedures($id: Int) {
  procedures(id: $id) {
    ... on ProcedureImportUsersAnalyse {
      id
      step
      isDone
      isErrored
      isTerminated
      isNotStartedYet
      isInProgress
      errors {
        step
        message
      }
    }
    ... on ProcedureImportUsersExecute {
      id
      step
      isDone
      isErrored
      isTerminated
      isNotStartedYet
      isInProgress
      errors {
        step
        message
      }
    }
  }
}
    `;

/**
 * __useProceduresQuery__
 *
 * To run a query within a React component, call `useProceduresQuery` and pass it any options that fit your needs.
 * When your component renders, `useProceduresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProceduresQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProceduresQuery(baseOptions?: Apollo.QueryHookOptions<ProceduresQuery, ProceduresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProceduresQuery, ProceduresQueryVariables>(ProceduresDocument, options);
      }
export function useProceduresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProceduresQuery, ProceduresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProceduresQuery, ProceduresQueryVariables>(ProceduresDocument, options);
        }
export type ProceduresQueryHookResult = ReturnType<typeof useProceduresQuery>;
export type ProceduresLazyQueryHookResult = ReturnType<typeof useProceduresLazyQuery>;
export type ProceduresQueryResult = Apollo.QueryResult<ProceduresQuery, ProceduresQueryVariables>;