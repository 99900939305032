/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyScheduledReminderFragmentFragmentDoc } from '../../../models/generated/SkinnyScheduledReminderFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduledSurveyWithStatisticsQueryVariables = Types.Exact<{
  scheduledSurveyId: Types.Scalars['Int'];
}>;


export type ScheduledSurveyWithStatisticsQuery = { scheduledSurvey?: { id: number, scheduledId: number, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, survey: { id: number, name: string, theme: { id: number, icon: string, name: string, description: string, color: string } }, scheduledReminders: Array<{ remind_hours_before_end: number, is_editable: boolean }>, statistics: { id: string, grade?: number | undefined, scheduledSurveyId?: number | undefined, submittedParticipationsCount: number, participationsPercentage: number, termsAcceptedRecipientsCount: number, adjustedParticipationsPercentage: number, recipientsCount: number } } | undefined };


export const ScheduledSurveyWithStatisticsDocument = gql`
    query ScheduledSurveyWithStatistics($scheduledSurveyId: Int!) {
  scheduledSurvey: scheduled_survey(scheduled_survey_id: $scheduledSurveyId) {
    id
    scheduledId: id
    status {
      absolute
      local
    }
    beginAt: begin_at {
      local
      earliest
      latest
    }
    endAt: end_at {
      local
      earliest
      latest
    }
    survey {
      id
      name
      theme {
        id
        icon
        name
        description
        color
      }
    }
    scheduledReminders: scheduled_reminders {
      ...SkinnyScheduledReminderFragment
    }
    statistics: detailedStatistics {
      id
      scheduledSurveyId: scheduled_survey_id
      submittedParticipationsCount: participation_submitted_number
      participationsPercentage: real_participation_percentage
      termsAcceptedRecipientsCount: recipient_who_accepted_tos_number
      adjustedParticipationsPercentage: adjust_participation_percentage
      recipientsCount: recipient_number
      grade
    }
  }
}
    ${SkinnyScheduledReminderFragmentFragmentDoc}`;

/**
 * __useScheduledSurveyWithStatisticsQuery__
 *
 * To run a query within a React component, call `useScheduledSurveyWithStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledSurveyWithStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledSurveyWithStatisticsQuery({
 *   variables: {
 *      scheduledSurveyId: // value for 'scheduledSurveyId'
 *   },
 * });
 */
export function useScheduledSurveyWithStatisticsQuery(baseOptions: Apollo.QueryHookOptions<ScheduledSurveyWithStatisticsQuery, ScheduledSurveyWithStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledSurveyWithStatisticsQuery, ScheduledSurveyWithStatisticsQueryVariables>(ScheduledSurveyWithStatisticsDocument, options);
      }
export function useScheduledSurveyWithStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledSurveyWithStatisticsQuery, ScheduledSurveyWithStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledSurveyWithStatisticsQuery, ScheduledSurveyWithStatisticsQueryVariables>(ScheduledSurveyWithStatisticsDocument, options);
        }
export type ScheduledSurveyWithStatisticsQueryHookResult = ReturnType<typeof useScheduledSurveyWithStatisticsQuery>;
export type ScheduledSurveyWithStatisticsLazyQueryHookResult = ReturnType<typeof useScheduledSurveyWithStatisticsLazyQuery>;
export type ScheduledSurveyWithStatisticsQueryResult = Apollo.QueryResult<ScheduledSurveyWithStatisticsQuery, ScheduledSurveyWithStatisticsQueryVariables>;