/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExecuteImportUsersQueryVariables = Types.Exact<{
  import_users_analyse_id: Types.Scalars['Int'];
}>;


export type ExecuteImportUsersQuery = { procedure_import_users_execute: { id: number, step?: string | undefined, isDone: boolean, isErrored: boolean, isTerminated: boolean, isNotStartedYet: boolean, isInProgress: boolean, errors?: Array<{ step?: string | undefined, message: string }> | undefined } };


export const ExecuteImportUsersDocument = gql`
    query ExecuteImportUsers($import_users_analyse_id: Int!) {
  procedure_import_users_execute(import_users_analyse_id: $import_users_analyse_id) {
    id
    step
    isDone
    isErrored
    isTerminated
    isNotStartedYet
    isInProgress
    errors {
      step
      message
    }
  }
}
    `;

/**
 * __useExecuteImportUsersQuery__
 *
 * To run a query within a React component, call `useExecuteImportUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExecuteImportUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExecuteImportUsersQuery({
 *   variables: {
 *      import_users_analyse_id: // value for 'import_users_analyse_id'
 *   },
 * });
 */
export function useExecuteImportUsersQuery(baseOptions: Apollo.QueryHookOptions<ExecuteImportUsersQuery, ExecuteImportUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExecuteImportUsersQuery, ExecuteImportUsersQueryVariables>(ExecuteImportUsersDocument, options);
      }
export function useExecuteImportUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExecuteImportUsersQuery, ExecuteImportUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExecuteImportUsersQuery, ExecuteImportUsersQueryVariables>(ExecuteImportUsersDocument, options);
        }
export type ExecuteImportUsersQueryHookResult = ReturnType<typeof useExecuteImportUsersQuery>;
export type ExecuteImportUsersLazyQueryHookResult = ReturnType<typeof useExecuteImportUsersLazyQuery>;
export type ExecuteImportUsersQueryResult = Apollo.QueryResult<ExecuteImportUsersQuery, ExecuteImportUsersQueryVariables>;