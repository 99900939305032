/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OngoingAndTodoScheduledSurveysQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
  skipTodo?: Types.InputMaybe<Types.Scalars['Int']>;
  skipOngoing?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type OngoingAndTodoScheduledSurveysQuery = { todoScheduledSurveys: Array<{ id: number, scheduledId: number, beginAt: { earliest?: string | undefined, local: string, latest?: string | undefined }, endAt: { earliest?: string | undefined, local: string, latest?: string | undefined } }>, ongoingScheduledSurveys: Array<{ id: number, scheduledId: number, beginAt: { earliest?: string | undefined, local: string, latest?: string | undefined }, endAt: { earliest?: string | undefined, local: string, latest?: string | undefined } }> };

export type ScheduledSurveyDatesFragment = { beginAt: { earliest?: string | undefined, local: string, latest?: string | undefined }, endAt: { earliest?: string | undefined, local: string, latest?: string | undefined } };

export const ScheduledSurveyDatesFragmentDoc = gql`
    fragment scheduledSurveyDates on Scheduled_survey {
  beginAt: begin_at {
    earliest
    local
    latest
  }
  endAt: end_at {
    earliest
    local
    latest
  }
}
    `;
export const OngoingAndTodoScheduledSurveysDocument = gql`
    query OngoingAndTodoScheduledSurveys($limit: Int!, $skipTodo: Int, $skipOngoing: Int) {
  todoScheduledSurveys: scheduled_surveys(status: {absolute: todo}, limit: $limit, skip: $skipTodo, order: ascending) {
    id
    scheduledId: id
    ...scheduledSurveyDates
  }
  ongoingScheduledSurveys: scheduled_surveys(status: {absolute: ongoing}, limit: $limit, skip: $skipOngoing) {
    id
    scheduledId: id
    ...scheduledSurveyDates
  }
}
    ${ScheduledSurveyDatesFragmentDoc}`;

/**
 * __useOngoingAndTodoScheduledSurveysQuery__
 *
 * To run a query within a React component, call `useOngoingAndTodoScheduledSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useOngoingAndTodoScheduledSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOngoingAndTodoScheduledSurveysQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skipTodo: // value for 'skipTodo'
 *      skipOngoing: // value for 'skipOngoing'
 *   },
 * });
 */
export function useOngoingAndTodoScheduledSurveysQuery(baseOptions: Apollo.QueryHookOptions<OngoingAndTodoScheduledSurveysQuery, OngoingAndTodoScheduledSurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OngoingAndTodoScheduledSurveysQuery, OngoingAndTodoScheduledSurveysQueryVariables>(OngoingAndTodoScheduledSurveysDocument, options);
      }
export function useOngoingAndTodoScheduledSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OngoingAndTodoScheduledSurveysQuery, OngoingAndTodoScheduledSurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OngoingAndTodoScheduledSurveysQuery, OngoingAndTodoScheduledSurveysQueryVariables>(OngoingAndTodoScheduledSurveysDocument, options);
        }
export type OngoingAndTodoScheduledSurveysQueryHookResult = ReturnType<typeof useOngoingAndTodoScheduledSurveysQuery>;
export type OngoingAndTodoScheduledSurveysLazyQueryHookResult = ReturnType<typeof useOngoingAndTodoScheduledSurveysLazyQuery>;
export type OngoingAndTodoScheduledSurveysQueryResult = Apollo.QueryResult<OngoingAndTodoScheduledSurveysQuery, OngoingAndTodoScheduledSurveysQueryVariables>;