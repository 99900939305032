import styled, { css } from "styled-components/macro"
import { CenteredLoader, IconButton } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;


  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    margin: 3rem;
    display: flex;
    justify-content: center;
      flex-direction: row;
      align-items: center;
  }
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;

  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    flex-direction: row;
  }
`

export const ActionLabel = styled.label`
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};
  color: ${({ theme }) => theme.colors.blackMedium};
  text-align: center;
`

export const TransferLoader = styled(CenteredLoader)`
  ${({ theme }) => css`
    background: ${theme.colors.primary};
    border-radius: ${theme.sizes.borderRadiusRounded};
    box-shadow: ${theme.shadows.button};
  `}
 && svg {
    width: 32px;
    height: 32px;
 }
`

export const TransferButton = styled(IconButton)`
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    transform: rotateZ(90deg);
  }
`
