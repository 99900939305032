import styled from "styled-components/macro"
import { DialogActions, Dialog as MuiDialog } from "@material-ui/core"
import { Title as TitleComponent } from "@humanpredictiveintelligence/myqvt-library"

export const Dialog = styled(MuiDialog)`
  .Dialog {
    width: 700px;
    max-height: 80vh;
  }
`

export const DialogAction = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: stretch;
`

export const Title = styled(TitleComponent)`
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 32px;
`

export const PatchNote = styled.div`
  margin: 0 0 32px;
`

export const PatchNoteContent = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
  line-height: 1.5;
`

export const PatchNoteTitle = styled(TitleComponent)`
  font-weight: bold;
`
