import React from "react"
import { TooltipRenderProps } from "react-joyride"

import { TutorialCommonProps } from "features/Tutorial/models"
import TutorialPopupActions from "./TutorialPopupActions"
import * as Styles from "./TutorialPopup.styles"

export const TutorialPopup = (props: TutorialPopupProps) => (
  <Styles.CardContainer
    {...props.tooltipProps}
    $direction={props.popupDirection}
    $callToActionPosition={props.callToActionPosition}
  >
    <Styles.Content>{props.children}</Styles.Content>
    <TutorialPopupActions
      skipProps={props.skipProps}
      primaryProps={props.primaryProps}
      hideSkipButton={props.hideSkipButton}
      hideNextButton={props.hideNextButton}
      disableNextButton={props.disableNextButton}
      callToActionPosition={props.callToActionPosition}
    />
  </Styles.CardContainer>
)

TutorialPopup.defaultProps = {
  callToActionPosition: "center",
  disableNextButton: false,
  hideSkipButton: true,
  popupDirection: "horizontal",
}

export interface TutorialPopupProps extends TooltipRenderProps, TutorialCommonProps {
  /** Content of the tooltip */
  children?: React.ReactNode,

  disableNextButton: boolean,
}
