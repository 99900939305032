import { Input, PrimaryButton, SecondaryButton } from "@humanpredictiveintelligence/myqvt-library"
import * as React from "react"
import { t } from "ttag"

import { UserGroup } from "models/generated"
import * as Styles from "../UserGroupDialog.styles"

export const AddGroupDialog: React.FC<AddGroupDialogProps> = (props) => {
  const [ userGroupName, setUserGroupName ] = React.useState<UserGroup["name"]>("")

  return (
    <Styles.Container
      classes={{ paper: "Container" }}
      open={props.isOpen}
    >
      <form onSubmit={onValidate}>
        <Styles.Header>{t`Add a group`}</Styles.Header>
        <Input
          label={t`Name`}
          placeholder={t`Enter your group name`}
          value={userGroupName}
          onChange={setUserGroupName}
          isDisabled={props.isLoading}
        />
        <Styles.Actions>
          <SecondaryButton
            onClick={onCancel}
          >
            {t`Cancel`}
          </SecondaryButton>
          <PrimaryButton
            submit
            disabled={!userGroupName}
            isLoading={props.isLoading}
          >
            {t`Add`}
          </PrimaryButton>
        </Styles.Actions>
      </form>
    </Styles.Container>
  )

  function onValidate(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    props.onAdd(userGroupName)
    setUserGroupName("")
  }

  function onCancel() {
    props.onCancel()
    setUserGroupName("")
  }
}

export interface AddGroupDialogProps {
  isOpen: boolean,
  isLoading: boolean,
  onAdd: (recipient: UserGroup["name"]) => void,
  onCancel: () => void,
}
