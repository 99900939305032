import styled from "styled-components/macro"
import { AnswerSatisfactionIcon } from "features/Comments"

export const Container = styled.div`
  display: flex;

  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  line-height: 18px;
  white-space: pre-line;
  
  p {
    margin: 0;  
  }
`

export const Author = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  user-select: text;
  cursor: text;
`

export const IconSpacer = styled.div`
  min-width: 20px;
  margin-right: 16px;
`

export const SatisfactionIcon = styled(AnswerSatisfactionIcon)`
  min-width: 20px;
  margin-right: 16px;
`
