import { SearchableSelectOption } from "@humanpredictiveintelligence/myqvt-library"
import { Comment } from "../models/Comment"
import { CommentTranslation } from "../models/CommentTranslation"
import { Language } from "../models/Language"

export class COMMENT_TRANSLATIONS_SERVICE {
  /**
   * Get the default translations from a specified source
   *
   * @param source Source object in which to retrieve the translations
   * @param customerLanguage Language of the customer
   */
  static defaultTranslationFromSource(
    source: Comment | undefined,
    customerLanguage: Language,
  ): CommentTranslation {
    let defaultTranslation = source && Object.assign({}, source.commentTranslations.find(
      tran => tran.language.code === customerLanguage.code,
    ))

    if (!defaultTranslation) {
      defaultTranslation = {
        isEditable: source ? !source.publishedAt : true,
        language: customerLanguage,
        wording: "",
      }
    }
    else {
      defaultTranslation.isEditable = source ? !source.publishedAt : true
    }

    return defaultTranslation
  }

  /**
   * Get a list of user translations from a specified source
   *
   * @param source Source object in which to retrieve the translations
   * @param userCommentLanguages Comment languages of the user
   */
  static userTranslationsFromSource(
    source: Comment | undefined,
    userCommentLanguages: Language[],
  ): CommentTranslation[] {
    return userCommentLanguages.map(language => {
      const existingTranslation =
        source && source.commentTranslations.find(tran => tran.language.code === language.code)

      return {
        isEditable: source ? !source.publishedAt : true,
        language,
        wording: (existingTranslation && existingTranslation.wording) || "",
      }
    })
  }

  /**
   * Get a list of extra translations from a specified source
   *
   * @param source Source object in which to retrieve the translations
   * @param customerLanguage Language of the customer
   * @param userCommentLanguages Comment languages of the user
   * @param selectedExtraLanguages Extra languages selected by the user
   */
  static extraTranslationsFromSource(
    source: Comment | undefined,
    customerLanguage: Language,
    userCommentLanguages: Language[],
    selectedExtraLanguages: Language[] = [],
  ): CommentTranslation[] {
    const existingExtraTranslations = !source ? [] : source.commentTranslations.reduce(
      (accumulatedExtraTranslations, translation) => {
        if (
          translation.language.code !== customerLanguage.code
          && !userCommentLanguages.find(userLanguage => userLanguage.code === translation.language.code)
        ) {
          accumulatedExtraTranslations.push({
            ...translation,
            isEditable: source ? !source.publishedAt : true,
          })
        }

        return accumulatedExtraTranslations
      }, new Array<CommentTranslation>(),
    )

    selectedExtraLanguages.forEach(language => {
      if (
        language.code !== customerLanguage.code
        && !userCommentLanguages.find(userLanguage => userLanguage.code === language.code)
      ) {
        existingExtraTranslations.push({
          isEditable: source ? !source.publishedAt : true,
          language,
          wording: "",
        })
      }
    })

    return existingExtraTranslations
  }

  /**
   * Get all translations from a specified source object
   * @param source Source object from which to retrieve the translations
   */
  static allTranslationsFromSource(
    source: Comment | undefined,
    customerLanguage: Language,
    userLanguages: Language[],
  ): {
    default: CommentTranslation,
    extra: CommentTranslation[],
    user: CommentTranslation[],
  } {
    return {
      default: COMMENT_TRANSLATIONS_SERVICE.defaultTranslationFromSource(
        source,
        customerLanguage,
      ),
      extra: COMMENT_TRANSLATIONS_SERVICE.extraTranslationsFromSource(
        source,
        customerLanguage,
        userLanguages,
      ),
      user: COMMENT_TRANSLATIONS_SERVICE.userTranslationsFromSource(
        source,
        userLanguages,
      ),
    }
  }

  /**
   * Get a list of extra languages options for a SearchableSelect input
   *
   * @param appLanguages Languages available in the app
   * @param customerLanguage Default language of the customer
   * @param userLanguages Comment languages of the user
   * @param comment Comment used to define disabled options
   */
  static availableExtraLanguagesOptions(
    appLanguages: Language[],
    customerLanguage: Language,
    userLanguages: Language[],
    comment: Comment | undefined,
  ): SearchableSelectOption[] {
    return appLanguages.reduce((availableOptions, language) => {
      if (
        language.code !== customerLanguage.code
        && !userLanguages.find(commentLanguage => commentLanguage.code === language.code)
      ) {
        availableOptions.push({
          isDisabled:
            comment && !!comment.commentTranslations.find(translation => translation.language.code === language.code),
          label: language.translatedLabel,
          value: language.code,
        })
      }

      return availableOptions
    }, new Array<SearchableSelectOption>())
  }
}
