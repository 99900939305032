import { TutorialEvent } from "features/Tutorial/TutorialContext"

export const ScheduledSurveyCreateCanceled = "scheduled_survey_create_canceled"
export const ScheduledSurveyCreateConfirmed = "scheduled_survey_create_confirmed"
export const ScheduledSurveyCreateInProgress = "scheduled_survey_create_inprogress"
export const ScheduledSurveyCreateInitialized = "scheduled_survey_create_initialized"
export const ScheduledSurveyPageLinkClicked = "scheduled_survey_page_link_clicked"
export const ScheduledSurveyCreatePending = "scheduled_survey_create_pending"

export const TUTORIAL_SURVEYS_EVENTS = {
  scheduledSurveyCreateCanceled:  (): TutorialEvent => {
    return {
      name: ScheduledSurveyCreateCanceled,
    }
  },
  scheduledSurveyCreateConfirmed:  (target: TutorialEvent["nextTarget"]): TutorialEvent => {
    return {
      name: ScheduledSurveyCreateConfirmed,
      nextTarget: target,
    }
  },
  scheduledSurveyCreateInProgress:  (): TutorialEvent => {
    return {
      name: ScheduledSurveyCreateInProgress,
    }
  },
  scheduledSurveyCreateInitialized:  (): TutorialEvent => {
    return {
      name: ScheduledSurveyCreateInitialized,
    }
  },
  scheduledSurveyCreatePending:  (): TutorialEvent => {
    return {
      name: ScheduledSurveyCreatePending,
    }
  },
  scheduledSurveyPageLinkClicked:  (): TutorialEvent => {
    return {
      name: ScheduledSurveyPageLinkClicked,
    }
  },
}
