import React from "react"
import { jt, t } from "ttag"
import { BaseProps, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

import ScopeConfiguratorDialog from "features/Permissions/PermissionsManager/ScopeConfiguratorDialog"
import { DeleteScopeConfirmationDialog } from "features/Permissions/PermissionsManager/ConfirmationDialogs"
import useScopeManager from "features/Permissions/PermissionsManager/useScopeManager"
import { usePermissions } from "features/Permissions/PermissionsContext"
import { Admin } from "features/Permissions/models"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"
import { USER_ATTRIBUTE } from "models/UserAttribute"

import * as Styles from "./PermissionsManager.styles"
import * as PermissionStyles from "features/Permissions/Permissions.styles"
import * as ConfirmationActionsStyles from "features/Permissions/PermissionsManager/ConfirmationDialogs/ConfirmationActions.styles"

import { useUserAttributesQuery } from "graphql/queries/generated/UserAttributeValues"

export const ScopeManager: React.FC<ScopeManagerProps> = (props) => {
  const { areScopesLoading, saveAdminScopes } = useScopeManager()
  const { fetchAdmins, isAdminLoading, actions } = usePermissions()

  const [ isScopeConfiguratorOpened, setIsScopeConfiguratorOpen ] = React.useState<boolean>(false)
  const [ isScopeDeletionDialogOpened, setIsScopeDeletionDialogOpened ] = React.useState<boolean>(false)
  const [ attributes, setAttributes ] = React.useState<USER_ATTRIBUTE[]>([])
  const scopeIcon = <PermissionStyles.ScopeIcon key="scopeIcon"/>

  // Fetch all available attributes (which are in fact AttributeValues in backend)
  const { loading: areAttributesLoading } = useUserAttributesQuery({
    onCompleted: (data) => {
      setAttributes(USER_ATTRIBUTE_SERVICE.attributesFromAttributeValues(data.attributeValues))
    },
  })

  return (
    <Styles.ScopeManagerContainer className="ScopeManager">
      <Styles.Title level="section">{t`Configure a scope`}</Styles.Title>
      <Styles.Paragraph>
        {
          jt`In order to improve permission management accuracy, you can restrict permissions on a scope.
         However, permissions flagged with the icon ${scopeIcon} wont be available.`
        }
      </Styles.Paragraph>

      {!!(attributes.length) && isScopeConfiguratorOpened && (
        <ScopeConfiguratorDialog
          userAttributes={attributes}
          admin={props.admin}
          isOpen={isScopeConfiguratorOpened}
          onScopesSaved={onScopesSaved}
          onDialogClose={closeScopeConfiguratorDialog}
        />
      )}
      <ConfirmationActionsStyles.Container justify={"flex-start"}>
        <PrimaryButton
          isLoading={areAttributesLoading || isAdminLoading}
          onClick={() => !isAdminLoading && openScopeConfiguratorDialog()}
          isInverted={!props.admin.scopes.length}
          height="small"
          disabled={!attributes.length || props.admin.isAdmin}
          tooltip={!attributes.length ? t`There is no hierarchy set up in your environment` : undefined}
        >
          {props.admin.scopes.length ? t`Configure` : t`Add`}
        </PrimaryButton>

        {!!props.admin.scopes.length && (
          <PrimaryButton
            isLoading={areAttributesLoading}
            onClick={() => setIsScopeDeletionDialogOpened(true)}
            disabled={isAdminLoading}
            destructive
            height="small">
            {t`Delete`}
          </PrimaryButton>
        )}

      </ConfirmationActionsStyles.Container>
      <DeleteScopeConfirmationDialog
        isOpen={isScopeDeletionDialogOpened}
        isLoading={areScopesLoading}
        onConfirmButtonClick={confirmScopeDeletion}
        onCancelButtonClick={() => setIsScopeDeletionDialogOpened(false)}
      />
    </Styles.ScopeManagerContainer>
  )

  async function confirmScopeDeletion() {
    await saveAdminScopes(props.admin, [])
    setIsScopeDeletionDialogOpened(false)

    actions.setAdminLoading(true)
    fetchAdmins()
  }

  function onScopesSaved() {
    actions.setAdminLoading(true)
    fetchAdmins()
  }

  function openScopeConfiguratorDialog() {
    setIsScopeConfiguratorOpen(true)
  }

  function closeScopeConfiguratorDialog() {
    setIsScopeConfiguratorOpen(false)
  }
}

interface ScopeManagerProps extends BaseProps {
  admin: Admin,
}

export default ScopeManager
