/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserGroupFragmentFragmentDoc } from '../../../models/generated/SkinnyUserGroupFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SessionUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SessionUsersQuery = { me: { users: { count: number, usersInGroupCount: number, userGroups: Array<{ name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined }> } } };


export const SessionUsersDocument = gql`
    query SessionUsers {
  me {
    users {
      count
      usersInGroupCount
      userGroups {
        ...SkinnyUserGroupFragment
      }
    }
  }
}
    ${SkinnyUserGroupFragmentFragmentDoc}`;

/**
 * __useSessionUsersQuery__
 *
 * To run a query within a React component, call `useSessionUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionUsersQuery(baseOptions?: Apollo.QueryHookOptions<SessionUsersQuery, SessionUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionUsersQuery, SessionUsersQueryVariables>(SessionUsersDocument, options);
      }
export function useSessionUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionUsersQuery, SessionUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionUsersQuery, SessionUsersQueryVariables>(SessionUsersDocument, options);
        }
export type SessionUsersQueryHookResult = ReturnType<typeof useSessionUsersQuery>;
export type SessionUsersLazyQueryHookResult = ReturnType<typeof useSessionUsersLazyQuery>;
export type SessionUsersQueryResult = Apollo.QueryResult<SessionUsersQuery, SessionUsersQueryVariables>;