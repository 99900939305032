/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnyUserAssigneesTimeInformationFragmentFragment = { assigneesTimeInformation: { earliest: string, latest: string, haveDifferentTimezones: boolean } };

export const SkinnyUserAssigneesTimeInformationFragmentFragmentDoc = gql`
    fragment SkinnyUserAssigneesTimeInformationFragment on User {
  assigneesTimeInformation: usersTimezones {
    earliest
    latest
    haveDifferentTimezones: are_on_different_timezones
  }
}
    `;