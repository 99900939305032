import * as React from "react"
import { RouteComponentProps } from "react-router"

import { ChangePasswordRequestForm } from "../ChangePasswordRequestForm"
import { ApplicationPath } from "features/Navigation"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"

import * as Styles from "./ChangePasswordRequestPage.styles"

import {
  useRequestPasswordResetMutation,
} from "graphql/mutations/generated/PasswordReset"

export const ChangePasswordRequestPage = (props: RouteComponentProps) => {
  const [ getPasswordReset ] = useRequestPasswordResetMutation()

  return (
    <ContentWithSpeakupLogo>
      <Styles.CardWrapper>
        <ChangePasswordRequestForm onSubmit={sendReinitializationMail} onGoBack={goBack} onGoLogin={goLogin} />
      </Styles.CardWrapper>
    </ContentWithSpeakupLogo>
  )

  async function sendReinitializationMail(email: string) {
    await getPasswordReset({
      context: {
        shouldUseAuthSchema: true,
      },
      variables: {
        email,
      },
    })
  }

  function goLogin() {
    props.history.push(ApplicationPath.Login)
  }

  function goBack() {
    props.history.goBack()
  }
}
