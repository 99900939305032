import styled, { css } from "styled-components/macro"
import { Card } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div<{ $width: number, $height: number }>`
  ${({ $width, $height }) => css`
    width: ${$width ? $width + "px" : "100%"};
    height: ${$height ? $height + "px" : "100%"};
  `}
`
export const ToolTip = {
  CallToAction: styled.p`
    margin-bottom: 0;

    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.95em;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    letter-spacing: ${({ theme }) => theme.typography.letterSpacingButton};
    text-transform: uppercase;
  `,

  Card: styled(Card)`
    width: 200px;

    text-align: center;
    font-size: ${({ theme }) => theme.typography.fontSizeCaption};
  `,

  RemainingSurveys: styled.div`
    margin-top: 8px;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  `,

  SurveyItem: styled.li`
    margin: 0;
    padding: 0;

    &:first-child {
      margin-bottom: 8px;
    }

    &:not(:first-child) {
      padding-top: 8px;
      border-top: 1px solid ${({ theme }) => theme.colors.blackMedium};;
    }
  `,

  SurveyList: styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
  `,

  Title: styled.h4`
    margin: 0 0 8px 0;
  `,
}
