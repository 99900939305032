import { useSnackbar } from "notistack"
import * as React from "react"

import { Notification } from "./Notification"

interface NotificationApi {
  show: (title: string, message: string, variant?: "success" | "danger") => void,
}

const defaultNotificationApi: NotificationApi = {
  show: (title: string, message: string, variant?: "success" | "danger") => { /* empty */ },
}

const notificationContext = React.createContext<NotificationApi>(defaultNotificationApi)

const NotificationProvider = (props: { children: React.ReactChild }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  /** Displays the notification with queue management */
  function show(title: string, message: string, variant?: "success" | "danger") {
    enqueueSnackbar(
      "", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
        children: (key: number) => (
          <Notification
            key={key}
            onClose={() => closeSnackbar(key)}
            title={title} message={message}
            variant={variant ?? "success"}
          />
        ),
      },
    )
  }

  return (
    <notificationContext.Provider value={{ show }}>{props.children}</notificationContext.Provider>
  )
}

export {
  notificationContext as NotificationContext,
  NotificationProvider,
}
