import { UserPermissionCode } from "models/generated"

export const permissionsLayout: PermissionsLayout = {
  "manage-speakup": {
    [UserPermissionCode.ScheduledSurveyManage]: [ 1, 1 ],
    [UserPermissionCode.RecipientsRead]: [ 1, 2 ],
    [UserPermissionCode.RecipientsManage]: [ 1, 3 ],
    [UserPermissionCode.PermissionsManage]: [ 1, 4 ],
    [UserPermissionCode.UserGroupsManage]: [ 2, 4 ],
  },
  "manage-surveys": {
    [UserPermissionCode.ResultsRead]: [ 4, 1 ],
    [UserPermissionCode.ResultsPublish]: [ 5, 5 ],
    [UserPermissionCode.CommentsRead]: [ 1, 2 ],
    [UserPermissionCode.CommentFeedbacksWrite]: [ 1, 3 ],
    [UserPermissionCode.QuestionFeedbacksRead]: [ 3, 2 ],
    [UserPermissionCode.QuestionFeedbacksWrite]: [ 3, 3 ],
    [UserPermissionCode.QuestionFeedbacksPublish]: [ 3, 4 ],
    [UserPermissionCode.LocalQuestionFeedbacksRead]: [ 4, 2 ],
    [UserPermissionCode.LocalQuestionFeedbacksWrite]: [ 4, 3 ],
    [UserPermissionCode.LocalQuestionFeedbacksPublish]: [ 4, 4 ],
    [UserPermissionCode.SurveyFeedbacksRead]: [ 6, 2 ],
    [UserPermissionCode.SurveyFeedbacksWrite]: [ 6, 3 ],
    [UserPermissionCode.SurveyFeedbacksPublish]: [ 6, 4 ],
    [UserPermissionCode.LocalSurveyFeedbacksRead]: [ 7, 2 ],
    [UserPermissionCode.LocalSurveyFeedbacksWrite]: [ 7, 3 ],
    [UserPermissionCode.LocalSurveyFeedbacksPublish]: [ 7, 4 ],
  },
}

type PermissionsLayout = Record<string, Record<string, [ number, number ]>>
