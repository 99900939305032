import moment from "moment"

import businessConfig from "../config/business"
import { MomentRange } from "../models/DateTime/MomentRange"
import { RangeValidity as DateTimeRangeValidity } from "../models/DateTime/RangeValidity"
import { Scheduled_Survey_Status as ScheduledSurveyStatus } from "models/generated/index"
import { SurveyScheduling } from "../models/Survey/index"

/** Service dedicated to handling business logic related to scheduled surveys */
export class SCHEDULED_SURVEY_SERVICE {
  /**
   * Truncates an array of ScheduledSurvey to get only the initial list of surveys we need to display.
   *
   * The rules to get the initial list are :
   * - Take X "ongoing" scheduled surveys
   * - If there are not enough, add extra scheduled surveys so that the total number of scheduled surveys is X.
   * The remainder to X is made up by half of "done" surveys and half of "todo" surveys
   * - If we need to add extra "done" and "todo" scheduled surveys but the count is odd, add more "todo" surveys.
   * @param scheduledSurveys Array of ScheduledSurveys to truncate
   */
  static truncateInitialList<T extends SurveyScheduling>(scheduledSurveys: T[]): T[] {
    let ongoingSurveysToDisplay = 0
    let notOngoingSurveysToDisplay = 0
    let extraTodoSurveyToDisplay = 0

    const ongoingSurveysCount = scheduledSurveys.reduce(
      (count, survey) => count += survey.status.local === ScheduledSurveyStatus.Ongoing ? 1 : 0,
      0,
    )

    if (ongoingSurveysCount < businessConfig.scheduledSurvey.initialNumberOfOngoingSurveysDisplayed) {
      ongoingSurveysToDisplay = ongoingSurveysCount
      notOngoingSurveysToDisplay =
        (businessConfig.scheduledSurvey.initialNumberOfOngoingSurveysDisplayed - ongoingSurveysCount) / 2

      // When the number of ongoing surveys is odd, we load one more todo survey (to get a total of 20 surveys)
      if (notOngoingSurveysToDisplay % 2) {
        extraTodoSurveyToDisplay = 1
      }

      notOngoingSurveysToDisplay = Math.floor(notOngoingSurveysToDisplay)
    }
    else {
      ongoingSurveysToDisplay = businessConfig.scheduledSurvey.initialNumberOfOngoingSurveysDisplayed
    }

    return SCHEDULED_SURVEY_SERVICE.sort([
      ...scheduledSurveys
        .filter(survey => survey.status.local === ScheduledSurveyStatus.Ongoing)
        .slice(0, ongoingSurveysToDisplay),
      ...scheduledSurveys
        .filter(survey => survey.status.local === ScheduledSurveyStatus.Done)
        .slice(0, notOngoingSurveysToDisplay),
      ...scheduledSurveys
        .filter(survey => survey.status.local === ScheduledSurveyStatus.Todo)
        .slice(0, notOngoingSurveysToDisplay + extraTodoSurveyToDisplay),
    ])
  }

  /**
   * Truncates an array of ScheduledSurvey to get only a limited number of surveys, intended to merge into an existing
   * array of ScheduledSurvey.
   */
  static truncateExtraBatch<T extends SurveyScheduling>(
    scheduledSurveys: T[],
    direction: "next" | "previous",
    lastStatusBeforeBatch: ScheduledSurveyStatus,
  ): T[] {
    const desiredBatchSize = businessConfig.scheduledSurvey.numberOfLoadMore

    const todoSurveys = scheduledSurveys.filter(survey => survey.status.local === ScheduledSurveyStatus.Todo)
    const ongoingSurveys = scheduledSurveys.filter(survey => survey.status.local === ScheduledSurveyStatus.Ongoing)
    const doneSurveys = scheduledSurveys.filter(survey => survey.status.local === ScheduledSurveyStatus.Done)

    let numberOfTodoToInclude = 0
    let numberOfOngoingToInclude = 0
    let numberOfDoneToInclude = 0

    if (direction === "previous") {
      switch (lastStatusBeforeBatch) {
        case ScheduledSurveyStatus.Todo:
          numberOfTodoToInclude = todoSurveys.length
          numberOfOngoingToInclude = desiredBatchSize - todoSurveys.length
          // May be negative if there are too much ongoing surveys
          numberOfDoneToInclude = desiredBatchSize - todoSurveys.length - ongoingSurveys.length
          break
        case ScheduledSurveyStatus.Ongoing:
          numberOfOngoingToInclude = ongoingSurveys.length
          numberOfDoneToInclude = desiredBatchSize - ongoingSurveys.length
          break
        case ScheduledSurveyStatus.Done:
          numberOfDoneToInclude = doneSurveys.length
      }
    }
    else {
      switch (lastStatusBeforeBatch) {
        case ScheduledSurveyStatus.Done:
          numberOfDoneToInclude = doneSurveys.length
          numberOfOngoingToInclude = desiredBatchSize - doneSurveys.length
          // May be negative
          numberOfTodoToInclude = desiredBatchSize - doneSurveys.length - ongoingSurveys.length
          break
        case ScheduledSurveyStatus.Ongoing:
          numberOfOngoingToInclude = ongoingSurveys.length
          numberOfTodoToInclude = desiredBatchSize - ongoingSurveys.length
          break
        case ScheduledSurveyStatus.Todo:
          numberOfTodoToInclude = todoSurveys.length
      }
    }

    return SCHEDULED_SURVEY_SERVICE.sort([
      ...numberOfTodoToInclude > 0 ? todoSurveys.slice(0, numberOfTodoToInclude) : [],
      ...numberOfOngoingToInclude > 0 ? ongoingSurveys.slice(0, numberOfOngoingToInclude) : [],
      ...numberOfDoneToInclude > 0 ? doneSurveys.slice(0, numberOfDoneToInclude) : [],
    ])
  }

  /** Get the ID of the ScheduledSurvey that should be initially focused in the list.
   *
   * The rules are:
   * - If there is at least one ongoing survey, it is the target
   * - Else, the target is the first done survey.
   * - If there is neither an ongoing survey nor a done survey, the target is the oldest survey
   */
  static initiallyFocusedSurveyId<T extends SurveyScheduling>(scheduledSurveys: T[]): number {
    let firstOngoingSurvey: T | undefined
    let firstDoneSurvey: T | undefined

    for (const survey of scheduledSurveys) {
      if (survey.status.local === ScheduledSurveyStatus.Ongoing && !firstOngoingSurvey) {
        firstOngoingSurvey = survey
      }
      else if (survey.status.local === ScheduledSurveyStatus.Done && !firstDoneSurvey) {
        firstDoneSurvey = survey
      }

      if (firstOngoingSurvey || firstDoneSurvey) {
        break
      }
    }

    if (firstOngoingSurvey) { return firstOngoingSurvey.schedulingId }
    else if (firstDoneSurvey) { return firstDoneSurvey.schedulingId }
    else { return scheduledSurveys.slice(-1)[0].schedulingId }
  }

  /**
   * Sort an array of ScheduledSurvey according to their begin date
   * @param scheduledSurveys Target array
   * @param direction Sort direction (defaults to antichronological)
   */
  static sort<T extends SurveyScheduling>(
    scheduledSurveys: T[],
    direction: "chronological" | "antichronological" = "antichronological",
  ): T[] {
    return scheduledSurveys.sort(
      (surveyLeft, surveyRight) => {
        if (moment(surveyLeft.beginAt.local).isBefore(surveyRight.beginAt.local)) {
          return direction === "chronological" ? -1 : 1
        }
        else {
          return direction === "chronological" ? 1 : -1
        }
      })
  }

  /**
   * Check a ScheduledSurvey participation range validity
   * @param range range to check
   */
  static participationRangeValidity(range: MomentRange): DateTimeRangeValidity {
    if (!range.from && !range.to) {
      return DateTimeRangeValidity.Empty
    }
    else if (range.from) {
      if (range.from.isBefore(moment(), "day")) {
        return DateTimeRangeValidity.StartInPast
      }

      if (range.from.isBefore(moment(), "minutes")) {
        return DateTimeRangeValidity.ValidAtLaterHour
      }

      if (range.to) {
        if (range.from.isSame(range.to, "day")) {
          return DateTimeRangeValidity.SameDay
        }

        if (range.from.isAfter(range.to)) {
          return DateTimeRangeValidity.StartAfterEnd
        }

        const rangeDuration = moment.duration(range.to.diff(range.from))
        if (rangeDuration.asHours() < businessConfig.scheduledSurvey.minimumDurationInHours) {
          return DateTimeRangeValidity.DurationTooShort
        }
      }
    }
    else {
      return DateTimeRangeValidity.EndWithoutStart
    }

    return DateTimeRangeValidity.Valid
  }

  /**
   * Get an array of individual dates covered by the given array of ScheduledSurveys
   * @param scheduledSurveys Array of ScheduledSurvey-like objects to parse
   */
  static allParticipationDates<T extends Pick<SurveyScheduling, "beginAt" | "endAt">>(
    scheduledSurveys: T[],
  ): moment.Moment[] {
    return Array.from(scheduledSurveys.reduce((dates, survey) => {
      // Get all dates in the range
      // Subtract one to compensate the first loop iteration
      const currentDate = moment.parseZone(survey.beginAt.local).startOf("day").subtract(1, "day")
      const lastDate = moment.parseZone(survey.endAt.local).startOf("day")
      while (currentDate.add(1, "day").diff(lastDate) <= 0) {
        if (!dates.find(date => date.isSame(currentDate.clone(), "day"))) {
          dates.push(currentDate.clone())
        }
      }

      return dates
    }, new Array<moment.Moment>()))
  }

  /**
   * Get a map of dates with a list of scheduled surveys for each.
   * @param scheduledSurveys Array of ScheduledSurvey-like objects to parse
   */
  static allParticipationDatesBySurvey<T extends Pick<SurveyScheduling, "beginAt" | "endAt">>(
    scheduledSurveys: T[],
  ): Map<string, T[]> {
    return [ ...scheduledSurveys ]
      .sort((a, b) => {
        return moment(b.beginAt.local).diff(a.beginAt.local)
      })
      .reduce((scheduledDays, scheduledSurvey) => {
        //Get all dates in the range
        //Subtract one to compensate the first loop iteration
        const currentDate = moment(scheduledSurvey.beginAt.local).startOf("day").subtract(1, "day")
        const lastDate = moment(scheduledSurvey.endAt.local).startOf("day")

        while (currentDate.add(1, "day").diff(lastDate) <= 0) {
          const scheduledSurveysOnDay = scheduledDays.get(currentDate.toString())

          if (scheduledSurveysOnDay) {
            scheduledSurveysOnDay.push(scheduledSurvey)
          }
          else {
            scheduledDays.set(currentDate.toString(), [ scheduledSurvey ])
          }
        }

        return scheduledDays
      }, new Map<string, T[]>())
  }
}
