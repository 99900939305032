import { JsDateRange } from "models/DateTime/JsDateRange"
import { MomentRange } from "models/DateTime/MomentRange"
import moment, { Moment } from "moment"
import React from "react"
import { DayModifiers } from "react-day-picker"
import { t } from "ttag"

import * as Styles from "./SelectSideDrawer.styles"
import business from "config/business"
import { SearchableSelect, SearchableSelectOption } from "@humanpredictiveintelligence/myqvt-library"

export const SelectSideDrawer: React.FC<SelectSideDrawerProps> = (props) => {
  const [ hoursOption, setHoursOptions ] = React.useState<SearchableSelectOption[]>([])

  /**  Directly subtract the limit to display the proper allowed range on the calendar
   *    example: if end_at is on the 16th at 2am, the day has to be removed from the calendar
   * */
  const [ calendarRange, setCalendarRange ] = React.useState({
    from: props.dateRange?.from?.toDate(),
    to: props.dateRange?.to?.clone()
      .subtract(business.scheduledReminders.timeLimitBeforeSurveyEnd.value, "hours").toDate(),
  })
  const [ selectedDay, setSelectedDay ] = React.useState<Date | undefined>(undefined)
  const [ selectedHour, setSelectedHour ] = React.useState<string[]>([])

  const [ isAnimating, setAnimating ] = React.useState(true)
  const [ drawerPosition, setDrawerPosition ] = React.useState({
    left: props.anchorRef.offsetLeft,
    top: props.anchorRef.offsetTop,
  })
  // Width of the drawer, extracted from style to determine on which side of the select it need to appear
  const drawerWidth = 400
  const canFitLeft = drawerPosition.left + props.anchorRef.offsetWidth + drawerWidth < window.innerWidth
  const translationX = (canFitLeft ? props.anchorRef.offsetWidth : -drawerWidth)

  /** Update the drawer position on every anchorRef change */
  React.useEffect(() => {
    setDrawerPosition({ left: props.anchorRef.offsetLeft, top: props.anchorRef.offsetTop })
  }, [ props.anchorRef ])

  /** Update hour options on calendarRange and selectedDay change */
  React.useEffect(() => {
    if (selectedDay) {
      timeSelectHoursOptions(calendarRange, selectedDay)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ calendarRange, selectedDay ])

  /** Update date range state on date range props change */
  React.useEffect(() => {
    setCalendarRange({
      from: props.dateRange?.from?.toDate(),
      to: props.dateRange?.to?.clone()
        .subtract(business.scheduledReminders.timeLimitBeforeSurveyEnd.value, "hours").toDate(),
    })
  }, [ props.dateRange ])

  /** Display the default value if passed and not equal to 0 */
  React.useEffect(() => {
    const reminderDay = props.dateRange?.to?.clone().subtract(props.defaultValue, "hours")

    if (props.defaultValue !== "0" && reminderDay) {
      setSelectedDay(reminderDay.toDate())
      setSelectedHour([ reminderDay.hours().toString() ])
    }
  }, [ props.dateRange, props.defaultValue ])

  return (
    <Styles.AnimatedContainer
      $top={drawerPosition.top}
      $left={drawerPosition.left}
      $width={drawerWidth}
      $height={props.anchorRef.offsetHeight}
      $isAnimating={isAnimating}

      initial={{ x: 0 }}
      animate={{ x: translationX }}
      onAnimationComplete={() => setAnimating(false)}
    >
      <Styles.DrawerCard>
        <Styles.DateReminderPicker
          fromMonth={calendarRange.from}
          toMonth={calendarRange.to}
          initialMonth={calendarRange.from}
          disabledDays={calendarRange.to && calendarRange.from && {
            after: calendarRange.to, before: calendarRange.from,
          }}
          selectedDays={selectedDay}
          onDayClick={handleDayClick}
        />
        <Styles.DrawerActions>
          <Styles.ActionLabel text={t`At what time?`}/>
          <SearchableSelect
            menuPlacement={"top"}
            options={hoursOption}
            onChange={handleHourClick}
            defaultValues={selectedHour}
            isSearchable={false}
            height="m"
            selectPlaceholder="--"
          />
          <Styles.SubmitButton
            disabled={!selectedDay}
            onClick={handleValidateDate}
            height="small"
          >
            {t`Validate`}
          </Styles.SubmitButton>
        </Styles.DrawerActions>
      </Styles.DrawerCard>
    </Styles.AnimatedContainer>
  )

  /** Update the selected date and reset selected hour */
  function handleDayClick(day: Date, modifiers: DayModifiers) {
    if (!modifiers.disabled) {
      setSelectedDay(day)
      setSelectedHour([])
    }
  }

  /** Update the selected hour */
  function handleHourClick(selectedOptions: SearchableSelectOption[]) {
    if (selectedOptions[0]) {
      setSelectedHour([ selectedOptions[0].value ])
    }
  }

  /** Send on DateValidated Callback on validate button click */
  function handleValidateDate() {
    const selectedDayWithHour = moment(selectedDay).hours(parseInt(selectedHour[0]))
    const convertedDateToEndAtOffset = props.dateRange?.to?.diff(selectedDayWithHour, "hours", true) || 0

    props.onDateValidated?.(convertedDateToEndAtOffset, selectedDayWithHour)
  }

  /** Get the options of the Hour Select */
  function timeSelectHoursOptions(selectedRange: JsDateRange, selectedDate: Date) {
    let firstAvailableHour = 0
    let lastAvailableHour = 24

    const selectedDay = moment(selectedDate)
    const surveyBeginAt = moment(selectedRange?.from)
    const surveyEndAt = moment(selectedRange?.to)

    if (surveyBeginAt.isSame(selectedDay, "day")) {
      firstAvailableHour = surveyBeginAt.add(1, "hour").hours()
    }
    else if (surveyEndAt.isSame(selectedDay, "day")) {
      lastAvailableHour = surveyEndAt.hours()
    }

    const disabledHoursOnSelectedDay = props.dateRange?.disabledHours.reduce((disabledHours, disabledHour) => {
      const date = props.dateRange?.to?.clone().subtract(disabledHour, "hours")

      if (date?.isSame(selectedDay, "day")) {
        disabledHours.push(date.hours())
      }
      return disabledHours
    }, new Array<number>()) || []

    const options = Array.from(
      new Array(lastAvailableHour - firstAvailableHour), (value, index) => ({
        isDisabled: disabledHoursOnSelectedDay.includes(index + firstAvailableHour),
        label: (index + firstAvailableHour).toString().padStart(2, "0"),
        value: (index + firstAvailableHour).toString(),
      }),
    )

    setHoursOptions(options)
    if (selectedHour.length === 0) {
      setSelectedHour([ options[0].value ])
    }
  }
}

export interface SelectDrawerOptionProps {
  /** Default value to show  */
  defaultValue?: string,

  /**  Date range of the calendar  */
  dateRange?: MomentRange & { disabledHours: number[] },
}

export interface SelectSideDrawerProps extends SelectDrawerOptionProps {
  /** The ref used to determine the position in the window and the height of the container */
  anchorRef: HTMLDivElement,

  /** Callback on validate button click
   * @param selectedOffset The selectedDate converted has offset from the scheduled end_at
   * @param selectedDate The selectedDate before conversion
   * */
  onDateValidated?: (selectedOffset: number, selectedDate: Moment) => void,
}
