import * as React from "react"
import { Redirect, Route, RouteProps } from "react-router-dom"

import { useSession } from "features/Session"
import { usePackages } from "features/Packages"
import { PackageCode } from "models/generated"
import { APPLICATION_URL } from "./ApplicationNavigation"

export const HomeDisambiguationRoute: React.FunctionComponent<RouteProps> = (props) => {
  const session = useSession()
  const { hasPackage } = usePackages()

  return (
    <Route
      {...props}
      render={() => {
        if (!session.customer) {
          return <Redirect to={{ pathname: APPLICATION_URL.base() }}/>
        }

        if (!session.authentication.isAuthenticated) {
          return <Redirect to={{ pathname: APPLICATION_URL.login() }}/>
        }

        return <Redirect to={{ pathname: getHomePage() }}/>
      }}
    />
  )

  function getHomePage() {
    if (hasPackage(PackageCode.Speakup) || !hasPackage(PackageCode.Led)) {
      return APPLICATION_URL.speakupDashboard()
    }

    return APPLICATION_URL.ledDashboard()
  }
}
