import styled, { css } from "styled-components/macro"
import { SearchableSelect } from "@humanpredictiveintelligence/myqvt-library"
import { motion } from "framer-motion"

export const Container = styled(motion.div)`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderDark};
`

export const TopSectionFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
`

export const AttributeSelect = styled(SearchableSelect)`
  width: 100%;
`

export const ShowAllFilters = styled(motion.div)`
  grid-column: 4 / span 1;
  margin-top: calc(${({ theme }) => theme.typography.fontSizeLabelSmaller} + 8px);
`

export const BottomSectionFilters = styled.div<{ $isHidden: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1rem;
  grid-gap: 1rem;
  ${({ $isHidden }) => $isHidden && css`
    display: none;
  `}
`
