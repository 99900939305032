import * as React from "react"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

import * as Styles from "./LedNumbersCarousel.styles"

import { formatPhoneNumber } from "utilities/helpers"

import { SessionQuery } from "graphql/queries/generated/Session"

export const LedNumbersCarousel: React.FC<LedMetricProps> = (props) => {
  const slides = React.useMemo(() => {
    return props.phoneNumbers?.map((phoneNumber, index) => (
      <SwiperSlide key={index}>
        <Styles.Logo>
          <Styles.HotLineIcon/>
          {formatPhoneNumber(phoneNumber.phone)}
        </Styles.Logo>
      </SwiperSlide>
    ))
  }, [ props.phoneNumbers ])

  return (
    <>
      <Styles.Heading>
        <Title level="metricCard">
          {t`Toll-free numbers available`}
        </Title>
      </Styles.Heading>
      <Styles.SwiperContainer>
        <Styles.NavigationButton id="back">
          <Styles.NavigationIcon name="arrow_back"/>
        </Styles.NavigationButton>
        <Swiper
          modules={[ Pagination, Navigation ]}
          spaceBetween={20}
          navigation={{ nextEl: "#next", prevEl: "#back" }}
          pagination={{ clickable: true, el: ".swiper-pagination", type: "progressbar" }}
          slidesPerView={4}
        >
          {slides}
        </Swiper>
        <Styles.NavigationButton id="next">
          <Styles.NavigationIcon name="arrow_forward"/>
        </Styles.NavigationButton>
      </Styles.SwiperContainer>
      <Styles.SwiperNavigation className="swiper-pagination"/>
    </>
  )
}

interface LedMetricProps {
  phoneNumbers: SessionQuery["me"]["customer"]["leds"][0]["phoneContact"]["phones"],
}
