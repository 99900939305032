import * as React from "react"
import { t } from "ttag"

import { CommentEditorContainer } from "../CommentEditorContainer"
import { UncontextualizedCommentsTableRowProps } from "../UncontextualizedCommentsTableRow"
import { Caption } from "@humanpredictiveintelligence/myqvt-library"
import { CommentAttribute, CommentReplies } from "models/Comment"
import { COMMENT_SERVICE } from "services/CommentService"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./CommentsTable.styles"
import { useUserPermissions } from "features/Permissions"
import { CommentObjectType, UserPermissionCode } from "models/generated"

export const CommentsTable = (props: CommentsTableProps) => {
  const isUserAllowed = useUserPermissions()
  const isAllowedToWriteResponseComments = isUserAllowed(UserPermissionCode.CommentFeedbacksWrite)

  return (
    <Styles.Container className={props.className} $hasNoResponse={!props.isResponseDisplayed}>
      <Styles.Header>
        {props.isResponseDisplayed && <div/>}

        <Caption size="big" textAlign="left">{t`Name`}</Caption>
        {props.isResponseDisplayed && <Caption size="big">{t`Answer`}</Caption>}
        {<Caption size="big">{t`Status`}</Caption>}
      </Styles.Header>
      <div>
        {props.comments.map((comment) => (
          <Styles.Row
            key={comment.id}
            comment={comment}
            answerStatus={COMMENT_SERVICE.status(comment.answers?.[0])}
            responseDisplay={props.isResponseDisplayed ? "display" : "hide"}
            extendedContent={(
              <CommentEditorContainer
                isAllowedToWrite={isAllowedToWriteResponseComments}
                isAllowedToPublish={isAllowedToWriteResponseComments}
                comment={comment.answers?.[0]}
                commentMetadata={{
                  ...comment,
                  attributeValueId: comment.attribute?.valueId,
                  parentComment: { id: comment.id },
                }}
                isImmediatePublishing
                onSaved={() => props.onSaved?.()}
              />
            )}
          />
        ))
        }
      </div>
    </Styles.Container>
  )
}

export interface CommentsTableProps extends BaseProps {
  /** Collection of comments to display */
  comments: Array<UncontextualizedCommentsTableRowProps["comment"]
    & CommentAttribute
    & CommentReplies>,

  type: CommentObjectType,

  /** If true show the answer header column  */
  isResponseDisplayed?: boolean,

  /** Called when a comment is saved */
  onSaved?: () => void,

  areCommentsLoading: boolean,
}
