import * as React from "react"
import { t } from "ttag"

import { BaseProps, HorizontalChartBar, HorizontalChartBarProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ScheduledSurveyQuestionRanking.styles"

/** Display a ranking of results */
export const ScheduledSurveyQuestionRanking: React.FC<ScheduledSurveyQuestionRankingProps> = (props) => (
  <Styles.Container className={props.className}>
    <Styles.HierarchyTitle>{t`${props.hierarchyTitle}:`}</Styles.HierarchyTitle>
    <Styles.Chart>
      {props.rankingValues.map(rankingValue => {
        let value: HorizontalChartBarProps["value"] = rankingValue.value

        if (!rankingValue.hasEnoughResults) {
          value = t`Not enough results`
        } else if (rankingValue.isAllSkipped) {
          value = "-"
        }

        return (
          <HorizontalChartBar
            key={rankingValue.order}
            value={value}
            label={`${rankingValue.label} ${rankingValue.usersCount ? "(" + rankingValue.usersCount + ")" : ""}`}
            index={rankingValue.order}
          />
        )
      })}
    </Styles.Chart>
  </Styles.Container>
)

interface ScheduledSurveyQuestionRankingProps extends BaseProps {
  /** Values of the ranking chart */
  rankingValues: RankingValue[],

  /** Hierarchy title of the ranking */
  hierarchyTitle: string,
}

interface RankingValue {
  label: string,
  value?: number,
  order: number,
  hasEnoughResults: boolean,
  isAllSkipped?: boolean,
  usersCount?: number,
}
