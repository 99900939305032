import styled from "styled-components/macro"
import { t } from "ttag"
import { MessageOverlay } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  height: auto;
  padding: 40px;
  min-width: ${({ theme }) => (
    parseInt(theme.sizes.verticalLayoutMinWidth, 10) - parseInt(theme.sizes.verticalMenuWidth, 10)
  )}px;
`

export const Modules = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(146px, auto) 457px minmax(277px, auto);
  grid-gap: 16px;
  
  .Module {
    &--span2 {
      grid-column: span 2;
    }

    &--span3 {
      grid-column: span 3;
    }

    &--span9 {
      grid-column: span 9;
    }
  }
`

export const UnauthorisedMessage = styled(MessageOverlay).attrs(props => ({
  icon: "lock",
  text: t`You do not have permission to view this content`,
}))`
  overflow: hidden;
  padding: 0;
`

export const Image = styled.img`
  width: 100%;
`
