import * as React from "react"
import { t } from "ttag"

import { ScheduledSurveyInfo } from "features/Surveys"
import { APPLICATION_URL } from "features/Navigation"
import { Tooltip } from "@humanpredictiveintelligence/myqvt-library"
import { Survey, SurveyScheduling, Theme } from "models/Survey/index"
import { UserGroup } from "models/generated"
import * as Styles from "./ScheduledSurveyCard.styles"

export const ScheduledSurveyCard: React.FunctionComponent<ScheduledSurveyCardProps> = (props) => {
  const scheduledSurveyCard = (
    <Styles.ScheduledSurveyCard
      $isActive={props.isActive}
      $isMini={props.isMini}
      $isInteractive={props.isInteractive}
      $isDisabled={props.isDisabled}
      shadow="below"
    >

      <ScheduledSurveyInfo
        scheduling={props.scheduling}
        withUserGroups={!!props.withUserGroupsSection}
        survey={props.survey}
        surveyTheme={props.theme}
        isCompact
        isMini={props.isMini}
        isScrollbarVisible={props.isScrollbarVisible}
      />
    </Styles.ScheduledSurveyCard>
  )

  if (props.shouldNavigate && props.isInteractive && !props.isDisabled) {
    return <Styles.CardLink
      to={APPLICATION_URL.scheduledSurvey(props.scheduling.schedulingId)}
      className="ScheduledSurveyCard__link"
      aria-label={"SurveyCard--" + props.scheduling.status.absolute}
    >
      {scheduledSurveyCard}
    </Styles.CardLink>
  }
  if (props.isDisabled && props.isInteractive) {
    return <Tooltip placement="right" title={t`You don't have sufficient rights to access this survey`}>
      <div>{scheduledSurveyCard}</div>
    </Tooltip>
  }

  return scheduledSurveyCard
}

ScheduledSurveyCard.defaultProps = {
  shouldNavigate: true,
}

export interface ScheduledSurveyCardProps {
  /** Scheduled survey information to display  */
  scheduling: SurveyScheduling,

  /** Theme reference for this scheduled survey */
  theme: Theme,

  /** Survey reference for this scheduled survey */
  survey: Survey,

  /** Whether this card is selected in a list */
  isActive?: boolean,

  /** Whether this card should be disabled */
  isDisabled?: boolean,

  /** Whether the card should appear interactive (hover effect and interaction cursor) */
  isInteractive?: boolean,

  /** Whether the card should appear in its minified version */
  isMini?: boolean,

  /** Whether to navigate to the scheduled survey on click */
  shouldNavigate?: boolean,

  /** Whether to show the user groups section  */
  withUserGroupsSection?: boolean,

  /** Current total count of groups available */
  availableUserGroups?: UserGroup[],

  /** We need to adapt content width depending of the visibility of the scrollbar */
  isScrollbarVisible? : boolean,
}
