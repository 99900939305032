import React from "react"
import { UncommittedChangesContext } from "features/UncommitedChanges/UncommitedChangesContext"

/** Accept a unique key and an initialState
 * @param valueKey A unique key to store the state
 * @param initialState Initialize the state with the given value
 * */
export function useUncommittedChanges<T>(
  valueKey: string,
  initialState?: T,
): useUncommittedChangesData<T> {
  const uncommittedChanges = React.useContext(UncommittedChangesContext)

  /** If the state does not exits and an initialState was given, we add it */
  if (!uncommittedChanges.has(valueKey) && initialState) {
    uncommittedChanges.set(valueKey, initialState)
    handleOnbeforeunload()
  }

  /** Update the current state */
  function setUncommittedChange(nextState: T) {
    uncommittedChanges.set(valueKey, nextState)
    handleOnbeforeunload()
  }

  /** Remove the state from the Map */
  function removeUncommittedChange() {
    uncommittedChanges.delete(valueKey)
    handleOnbeforeunload()
  }

  /** Handle the browser dialog confirmation */
  function handleOnbeforeunload() {
    /** Add the dialog if there is changes */
    if (!window.onbeforeunload && uncommittedChanges.size !== 0) {
      window.onbeforeunload = (event: BeforeUnloadEvent) => {
        event.returnValue = "show dialog"
      }
    }
    /** Remove it if there are none */
    else if (uncommittedChanges.size === 0) {
      window.onbeforeunload = null
    }
  }

  return {
    hasUncommittedChanges: uncommittedChanges.size !== 0,
    removeUncommittedChange,
    setUncommittedChange,
    uncommittedChange: uncommittedChanges.get(valueKey) as T,
  }
}

interface useUncommittedChangesData<T> {
  /** The stored state */
  uncommittedChange: T | undefined,

  /** Set or update the state */
  setUncommittedChange: (uncommittedChange: T) => void,

  /** Remove the stored state */
  removeUncommittedChange: () => void,

  /** Whether there are uncommitted changes from all stored states */
  hasUncommittedChanges: boolean,
}
