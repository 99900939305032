import { ScheduledTimeReminderUnit } from "features/ScheduledReminder/models"
import { reminderPresetWording } from "features/ScheduledReminder/utils"

const business = {
  "homepage": {
    "unprocessedComments": {
      "pageSizes": [ 5, 10, 15, 20 ],
    },
  },
  "languages": {
    "defaultTerritory": {
      "en": "en_US",
      "fr": "fr_FR",
    },
  },
  "led": {
    "discussions": {
      "minimumPeriodLenghtInHours": 1,
      "tablePageSizes": [ 10, 20, 30, 40, 50 ],
    },
    "reports": {
      "tablePageSizes": [ 10, 20, 30, 40, 50 ],
    },
  },
  "patterns": {
    // eslint-disable-next-line max-len, no-useless-escape
    "email": new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    // List of supported special characters : @$!%*-?&.()+,:<=>^_{}~/ #"'`,;\|[]
    // eslint-disable-next-line max-len, no-useless-escape
    "password": new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%.*\-?&()\+,:<=>^_{}~\/ #"'`,;\\|[\]])[A-Za-z\d@$!%*\-?&\.()\+,:<=>^_{}~\/ #"'`,;\\|[\]]{8,}$/),
  },
  "recipients": {
    "lastUpdateExpirationPeriod": [ 1,  "month" ], // Amount and unit of time
    "tablePageSizes": [ 10, 20, 30, 40, 50 ],
  },
  "results": {
    "commentsPageSizes": [ 4, 10, 15, 20, 30, 40, 50 ],
    "questionsNumberLimitToLoadConcurrently": 15,
  },
  "scheduledReminders": {
    "default": () => ({
      "name": reminderPresetWording(6, "hours"),
      "timeReminder": {
        "unit": ScheduledTimeReminderUnit.Hours,
        "value": 6,
      },
    }),
    "presets": () => ([
      {
        "name": reminderPresetWording(12, "hours"),
        "timeReminder": {
          "unit": ScheduledTimeReminderUnit.Hours,
          "value": 12,
        },
      },
      {
        "name": reminderPresetWording(1, "day"),
        "timeReminder": {
          "unit": ScheduledTimeReminderUnit.Hours,
          "value": 24,
        },
      },
      {
        "name": reminderPresetWording(2, "days"),
        "timeReminder": {
          "unit": ScheduledTimeReminderUnit.Hours,
          "value": 48,
        },
      },
    ]),
    "timeLimitBeforeSurveyEnd": {
      "unit": ScheduledTimeReminderUnit.Hours,
      "value": 6,
    },
  },
  "scheduledSurvey": {
    "initialNumberOfOngoingSurveysDisplayed": 20,
    "minimumDurationInHours": 24,
    "numberOfLoadMore": 10,
  },
  "statistics": {
    "global": {
      "maxSatisfactionEvolutionPoints": 20,
      "satisfactionEvolutionDuration": "6 months",
      "satisfactionEvolutionLatestDate": "last done scheduled_survey end_at",
    },
  },
}

export default business
