import * as React from "react"
import { msgid, ngettext, t } from "ttag"

import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { SkinnyScheduledChoiceStatistics } from "models/SkinnyScheduledChoiceStatistics"
import { SkinnyScheduledQuestionStatistics } from "models/SkinnyScheduledQuestionStatistics"
import { SCHEDULED_SURVEY_RESULTS_SERVICE } from "services/ScheduledSurveyResultsService"
import * as Styles from "./ScheduledSurveyQuestionGeneralResults.styles"

/** Displays general results of the question ( first tab to see )  */
export const ScheduledSurveyQuestionGeneralResults: React.FC<ScheduledSurveyQuestionGeneralResultsProps> = (props) => {
  const statisticsHighlights = SCHEDULED_SURVEY_RESULTS_SERVICE.questionStatisticsHighlights(props.statistics)
  const areAllAttributesSkipped = props.statistics.attributeGrades?.find(
    (attributeGrade) => !attributeGrade.isAllSkipped,
  ) === undefined
  return (
    <Styles.Container className={props.className}>
      <Styles.Chart>
        {props.choicesWithStatistics?.map(({ choice, selectionPercentage }) => (
          <Styles.StatisticsBar
            key={choice.id}
            {...props.statistics.reference.isKillerQuestion
              ? { image: choice.icon }
              : { label: choice.name }
            }
            value={selectionPercentage}
            unit="%"
          />
        ))}
        <Styles.StatisticsSkip>
          {props.statistics.skippedCount
            ? ngettext(
              msgid`${props.statistics.skippedCount} participant chose to skip this question`,
              `${props.statistics.skippedCount} participants chose to skip this question`,
              props.statistics.skippedCount,
            )
            : t`All the participants answered this question`
          }
        </Styles.StatisticsSkip>
      </Styles.Chart>
      {props.statistics.grade !== undefined && (
        <Styles.StatisticsHighlights>
          {statisticsHighlights?.hierarchy ? (
            <>
              <Styles.HierarchyLabel>
                {t`${statisticsHighlights.hierarchy}:`}
              </Styles.HierarchyLabel>
              <Styles.HierarchyGrade
                icon="timeline"
                label={t`General score`}
                value={areAllAttributesSkipped ? undefined : props.statistics.grade}
                total={100}
              />
            </>
          ) : (
            <Styles.HierarchyGrade
              icon="timeline"
              label={t`General score`}
              value={props.statistics.grade}
              total={100}
            />
          )}
          {statisticsHighlights?.highestGrade && (
            <Styles.HierarchyGrade
              icon="thumb_up"
              label={t`Highest score`}
              value={
                statisticsHighlights.highestGrade.isAllSkipped
                  ? undefined
                  : statisticsHighlights.highestGrade.grade
              }
              total={100}
              valueWording={statisticsHighlights.highestGrade.hierarchy}
            />
          )}
          {statisticsHighlights?.lowestGrade && (
            <Styles.HierarchyGrade
              icon="thumb_down"
              label={t`Lowest score`}
              value={
                statisticsHighlights.lowestGrade.isAllSkipped
                  ? undefined
                  : statisticsHighlights.lowestGrade.grade
              }
              total={100}
              valueWording={statisticsHighlights.lowestGrade.hierarchy}
            />
          )}
        </Styles.StatisticsHighlights>
      )}
    </Styles.Container>
  )
}

interface ScheduledSurveyQuestionGeneralResultsProps extends BaseProps {
  /** General statistics of this question */
  statistics: SkinnyScheduledQuestionStatistics,

  /** Statistics of this question's choices */
  choicesWithStatistics: SkinnyScheduledChoiceStatistics[],
}
