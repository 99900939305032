import moment from "moment"
import * as React from "react"
import { t } from "ttag"

import { useUncommittedChanges } from "features/UncommitedChanges"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { InformationMessage } from "@humanpredictiveintelligence/myqvt-library"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { MultilineInput } from "@humanpredictiveintelligence/myqvt-library"
import { Comment } from "models/Comment"
import * as Styles from "./CommentEditor.styles"

export const CommentEditor: React.FC<CommentEditorProps> = (props) => {
  const {
    uncommittedChange,
    setUncommittedChange,
    removeUncommittedChange,
  } = useUncommittedChanges<string>(props.commentKey)
  const [ value, setValue ] = React.useState(uncommittedChange ?? props.comment?.comment)
  const isDirty = value !== props.comment?.comment

  React.useEffect(() => {
    if (value === undefined && !isDirty) {
      setValue(props.comment?.comment)
    }
    else if (value === "") {
      setValue(undefined)
    }

    if (!isDirty) {
      uncommittedChange !== undefined &&  removeUncommittedChange()
    }
    else if (value !== undefined) {
      setUncommittedChange(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ value, props.comment ])

  return (
    <Styles.Container>
      <MultilineInput
        fieldClassNames="CommentEditor__input"
        label={props.label}
        value={value}
        onChange={setValue}
        isDisabled={props.isReadOnly || props.isUpdating}
      />
      {props.isStatusDisplayed && statusText()}
      <Styles.Footer>
        {!props.comment?.publishedAt && props.publishingHint && (
          <InformationMessage text={props.publishingHint} isSmall/>
        )}
        <Styles.Actions>
          {props.isReadOnly && props.isDeleteButtonShown && (
            <PrimaryButton
              isAllowed={props.isAllowedToWrite}
              disabled={!props.comment?.validatedAt}
              tooltip={!props.isAllowedToWrite
                ? t`You don't have sufficient rights to perform this action`
                : undefined
              }
              tooltipPlacement={"top"}
              onClick={props.onDelete}
              height="small"
              destructive
              isLoading={props.isUpdating}
            >
              {t`Delete`}
            </PrimaryButton>
          )}
          {(!props.isReadOnly || !props.isAllowedToWrite) && (
            <>
              <PrimaryButton
                isInverted
                disabled={!isDirty || props.isUpdating}
                isLoading={props.isUpdating}
                isAllowed={props.isAllowedToWrite}
                tooltip={!props.isAllowedToWrite
                  ? t`You don't have sufficient rights to perform this action`
                  : undefined
                }
                tooltipPlacement={"top"}
                onClick={() => props.onSave?.(value || "", "draft")}
                height="small"
              >
                {t`Save as draft`}
              </PrimaryButton>
              <PrimaryButton
                disabled={!value?.length || props.isUpdating}
                isAllowed={props.isAllowedToPublish}
                isLoading={props.isUpdating}
                tooltip={!props.isAllowedToPublish
                  ? t`You don't have sufficient rights to perform this action`
                  : undefined
                }
                tooltipPlacement={"top"}
                onClick={() => props.onSave?.(value || "", "published")}
                height="small"
              >
                {props.publishButtonLabel}
              </PrimaryButton>
            </>
          )}
        </Styles.Actions>
      </Styles.Footer>
    </Styles.Container>
  )

  /**
   * Get the comment status to display
   */
  function statusText(): React.ReactNode {
    let publicationStatus: React.ReactNode

    if (props.comment?.publishedAt) {
      const publishingDate = moment.parseZone(props.comment?.publishedAt).format("L")
      publicationStatus = (
        <>
          {t`Published on ${publishingDate}`}
          {props.comment?.creator && (
            <Styles.Creator>
              {t` by ${props.comment.creator.displayName}`}
            </Styles.Creator>
          )}
        </>
      )
    }
    else if (!props.isImmediatePublishing && props.comment?.validatedAt) {
      publicationStatus = isDirty ? t`Unsaved` : t`Ready to be published`
    }
    else if (!props.comment?.publishedAt) {
      if (isDirty) {
        publicationStatus = t`Unsaved`
      }
      else if (props.comment?.comment?.length) {
        publicationStatus = t`Draft saved`
      }
    }

    return (
      <Styles.Status>
        {publicationStatus}
      </Styles.Status>
    )
  }
}

export interface CommentEditorProps extends BaseProps {
  /** Whether the comment is read-only */
  isReadOnly?: boolean,

  /** Whether to show a button to delete the comment */
  isDeleteButtonShown?: boolean,

  /** Whether the user is allowed to edit the comment */
  isAllowedToWrite?: boolean,

  /** Whether the user is allowed to publish the comment */
  isAllowedToPublish?: boolean,

  /** Label of the input field */
  label: string,

  /** Whether to show the publishing status of the comment */
  isStatusDisplayed?: boolean,

  /** Whether to enable actions loaders (on draft and comments, save and delete actions) */
  isUpdating?: boolean,

  /** Information message to show underneath the field as long as the comment is not published */
  publishingHint?: string,

  /** Label for the publishing save button */
  publishButtonLabel: string,

  /** Comment to manage */
  comment?: Comment,

  /**
   * Key used to store the comment
   * Should only be given on comments that need to be saved after component unmount
   */
  commentKey: string,

  /** Whether the publication is immediate or delayed */
  isImmediatePublishing?: boolean,

  /** Called when the comment is saved */
  onSave?: (content: string, status: "draft" | "published") => void,

  /** Called when the user clicks the Delete button */
  onDelete?: () => void,
}
