import React from "react"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"

const useAttributes = (
  attributes: USER_ATTRIBUTE[],
  defaultSelection?: Map<number, USER_ATTRIBUTE_VALUE[]>,
  firstAppliedHierarchicalAttributeId?: number,

) => {
  const [ allAttributes, [ hierarchicalAttributes, simpleAttributes ] ]
    = React.useMemo((): [USER_ATTRIBUTE[], USER_ATTRIBUTE[][]] => {
      const parsedAttributes = USER_ATTRIBUTE_SERVICE.hierarchicallySortedAttributes(
        attributes,
        (left: USER_ATTRIBUTE, right: USER_ATTRIBUTE) => left.name.localeCompare(right.name),
      )
      return [
        parsedAttributes,
        hierarchicalAndSimpleAttributes(parsedAttributes),
      ]
    },
    [ attributes ],
    )

  const [ firstInteractedHierarchicalAttributeId, setFirstInteractedHierarchicalAttributeId ]
    = React.useState<number | undefined>(firstAppliedHierarchicalAttributeId)

  // Map of attribute IDs to their collection of selected values
  const [ selectedAttributeValues, setSelectedAttributeValues ] = React.useState<Map<number, USER_ATTRIBUTE_VALUE[]>>(
    new Map(),
  )

  function initializeAttributeSelection(attributeSelection: Map<number, USER_ATTRIBUTE_VALUE[]>) {
    attributeSelection.forEach((attributeValues, attributeId) => {
      const attribute = allAttributes.find(attr => attr.id === attributeId)
      if (attribute) {
        selectAttributeValue(attribute, attributeValues.map(attributeValue => attributeValue.id))
      }
    })
  }

  React.useEffect(() => {
    const attributeSelection = initialAttributeValueSelection(allAttributes)
    initializeAttributeSelection(attributeSelection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const availableAttributeValues = initialAvailableAttributeValues(allAttributes, selectedAttributeValues)

  const selectedAttributesId = selectedAttributeValueIds(selectedAttributeValues)
  const filteredAttributesId = filteredAttributesValueIds(
    selectedAttributesId, selectedAttributeValues, hierarchicalAttributes[0] ?? undefined,
  )

  React.useEffect(() => {
    if (firstInteractedHierarchicalAttributeId && selectedAttributesId.length === 0) {
      setFirstInteractedHierarchicalAttributeId(undefined)
    }
  }, [ selectedAttributesId, firstInteractedHierarchicalAttributeId ])

  function getAttributeValuesForAttribute(attributeToQueryFrom: USER_ATTRIBUTE): USER_ATTRIBUTE_VALUE[] {
    return allAttributes.find(attribute => attribute.id === attributeToQueryFrom.id)?.values ?? []
  }

  function getAvailableAttributeValuesForAttribute(attribute: USER_ATTRIBUTE): USER_ATTRIBUTE_VALUE[] {
    return availableAttributeValues.get(attribute.id) || []
  }

  /**
   * Empty selections of all attribute values
   */
  function emptyAllAttributeValueSelections() {
    setSelectedAttributeValues(initialAttributeValueSelection(allAttributes, false))
    setFirstInteractedHierarchicalAttributeId(undefined)
  }

  /**
   * Update the selected values of an attribute
   * @param attribute
   * @param selectedUserAttributeValueIds Selected options
   */
  function selectAttributeValue(
    attribute: USER_ATTRIBUTE,
    selectedUserAttributeValueIds: number[],
  ) {
    const type = hierarchicalAttributes.find(attr => attr.id === attribute.id) === undefined ? "simple" : "hierarchical"

    const selectedAttribute = ((type === "simple"
      ? simpleAttributes
      : hierarchicalAttributes).find(attr => attr.id === attribute.id)
    )

    if (!selectedAttribute) {
      return
    }

    const selectedValues = selectedAttribute.values.filter(value => selectedUserAttributeValueIds.includes(value.id))

    if (selectedUserAttributeValueIds.length && !firstInteractedHierarchicalAttributeId) {
      setFirstInteractedHierarchicalAttributeId(attribute.id)
    }

    autoDeselectChildAttributeValue(selectedAttribute, selectedUserAttributeValueIds, selectedAttributeValues)

    autoSelectParentAttributeValue(selectedAttribute, selectedUserAttributeValueIds, selectedAttributeValues)

    setSelectedAttributeValues(new Map(selectedAttributeValues.set(attribute.id, selectedValues)))
  }

  /**
   * Set the selection of the attribute parents to the given attribute, recursively
   * @param attribute Attribute whose parents must be selected
   * @param attributeValueIds IDs of the selected values for that attribute
   * @param allAttributesSelection All selected attribute values
   */
  function autoSelectParentAttributeValue(
    attribute: USER_ATTRIBUTE,
    attributeValueIds: number[],
    allAttributesSelection: Map<number, USER_ATTRIBUTE_VALUE[]>,
  ) {
    const selection = attribute.values.filter(value => attributeValueIds.includes(value.id))
    const parent = allAttributes.find(attr => attr.id === attribute.parentAttributeId)

    if (parent && selection) {
      const parentValueIdsOfSelectedValues = selection.map(value => value.parentValueId)
      const valuesInParent = parent.values.filter(value => parentValueIdsOfSelectedValues.includes(value.id))
      const parentSelection = allAttributesSelection.get(parent.id)

      if (parentSelection) {
        if (valuesInParent) {
          valuesInParent.forEach(({ id }) => {
            if (!parentSelection.map(attributeSelected => attributeSelected.id).includes(id)) {
              parentSelection.push(...valuesInParent)
            }
          })
        }

        autoSelectParentAttributeValue(parent, parentSelection.map(value => value.id), allAttributesSelection)
      }
    }
  }

  /**
   * Deselection of the attribute children to the given attribute, recursively
   * @param attribute Attribute whose parents must be selected
   * @param attributeValueIds IDs of the selected values for that attribute
   * @param allAttributesSelection All selected attribute values
   */
  function autoDeselectChildAttributeValue(
    attribute: USER_ATTRIBUTE,
    attributeValueIds: number[],
    allAttributesSelection: Map<number, USER_ATTRIBUTE_VALUE[]>,
  ) {
    const child = allAttributes.find(attr => attr.id === attribute.childrenAttributeIds[0])

    if (child && attributeValueIds) {
      const valuesIdToUnselectInChild =
        child.values
          .filter(value => value.parentValueId && !attributeValueIds.includes(value.parentValueId))
          .map(value => value.id)

      const childSelection = allAttributesSelection.get(child.id)

      if (childSelection && childSelection.length > 0) {
        const newChildSelection = childSelection.reduce((selectionAccumulator, value) => {
          if (!valuesIdToUnselectInChild.includes(value.id)) {
            selectionAccumulator.push(value)
          }

          return selectionAccumulator
        }, Array<USER_ATTRIBUTE_VALUE>())

        allAttributesSelection.set(child.id, newChildSelection)

        autoDeselectChildAttributeValue(child, newChildSelection.map(value => value.id), allAttributesSelection)
      }
    }
  }

  /**
   * Get a map of an attribute initial values selection
   * @param userAttributes Collection of attributes whose values selection is to be initialized
   * @param useDefaultSelection Whether props.defaultSelection should be merged in
   */
  function initialAttributeValueSelection(userAttributes: USER_ATTRIBUTE[], useDefaultSelection: boolean = true) {
    const map = new Map<number, USER_ATTRIBUTE_VALUE[]>()
    userAttributes.forEach(attribute => {
      map.set(
        attribute.id,
        (useDefaultSelection && defaultSelection && defaultSelection.get(attribute.id))
        || Array<USER_ATTRIBUTE_VALUE>(),
      )
    })

    return map
  }

  /**
   * Get a map of available attributes values
   * @param userAttributes Collection of attributes whose values is to be initialized
   * @param selectedAttributes Map of currently selected attributes values
   */
  function initialAvailableAttributeValues(
    userAttributes: USER_ATTRIBUTE[],
    selectedAttributes: Map<number, USER_ATTRIBUTE_VALUE[]>,
  ): Map<number, USER_ATTRIBUTE_VALUE[]> {
    const availableAttributesValues = new Map<number, USER_ATTRIBUTE_VALUE[]>()
    const availableAttributes = [ ...userAttributes ]
    availableAttributes.forEach((attribute, index) => {
      attribute = new USER_ATTRIBUTE(attribute) // Clone the user attribute to avoid mutating

      if (
        !attribute.isMainHierarchyHead
        && attribute.isHierarchical
        && attribute.id !== firstInteractedHierarchicalAttributeId
      ) {
        const parentAttribute = availableAttributes[index - 1]
        const parentSelectedValues = selectedAttributes.get(parentAttribute.id)
        const parentValuesIds = parentAttribute.values.map(value => value.id)
        const parentSelectedValuesIds = (parentSelectedValues && parentSelectedValues.map(value => value.id)) || []
        let availableValues: USER_ATTRIBUTE_VALUE[] = []
        if (parentSelectedValuesIds.length) {
          availableValues = attribute.values.filter(value => (
            value.parentValueId && parentSelectedValuesIds.includes(value.parentValueId)
          ))
        } else {
          availableValues = attribute.values.filter(value => (
            value.parentValueId && parentValuesIds.includes(value.parentValueId)
          ))
        }
        attribute.values = availableValues
      }
      availableAttributesValues.set(
        attribute.id,
        attribute.values,
      )
    })

    return availableAttributesValues
  }

  /**
   * Return a list of IDS of attribute values selected for the given attribute
   * @param attribute
   * @param selectedAttributesValuesId
   */
  function getSelectedValueIdsForAttribute(attribute: USER_ATTRIBUTE, selectedAttributesValuesId: number[])
  {
    return attribute.values
      .filter(value => selectedAttributesValuesId.includes(value.id))
      .map(value => value.id)
  }

  /**
   * Get an array of attribute values Ids to filter
   * @param attribute Attribute to filter
   * @param selectedAttributesValuesId IDs of the selected values for that attribute
   * @param allAttributesSelection All selected attribute values
   */
  function filteredAttributesValueIds(
    selectedAttributesValuesId: number[],
    allAttributesSelection: Map<number, USER_ATTRIBUTE_VALUE[]>,
    attribute?: USER_ATTRIBUTE,
  ): number[] {
    const selectedValuesId = attribute ? getSelectedValueIdsForAttribute(attribute, selectedAttributesValuesId) : []

    const child = attribute ? allAttributes.find(attr => attr.id === attribute.childrenAttributeIds[0]) : undefined
    const filteredAttributesValuesIdAccumulator: number[] = []

    // Filter children of hierarchical attribute
    if (child && selectedValuesId.length) {
      const valuesInChild =
        child.values.filter(value => value.parentValueId && selectedValuesId.includes(value.parentValueId))
      const parentIdsOfChild = new Set(valuesInChild.map(value => value.parentValueId))

      if (valuesInChild) {
        selectedValuesId.forEach(selectedValueId => {
          const selectedValueInChild = valuesInChild.filter(value => selectedAttributesValuesId.includes(value.id))
          if (
            !selectedValueInChild.length
            || (selectedValueInChild.length && !parentIdsOfChild.has(selectedValueId))
          ) {
            filteredAttributesValuesIdAccumulator.push(selectedValueId)
          }
        })
      }

      return [
        ...filteredAttributesValueIds(selectedAttributesValuesId, allAttributesSelection, child),
        ...filteredAttributesValuesIdAccumulator,
      ]
    } else {
      const simpleAttributesValues =
        simpleAttributes
          .flatMap(attr => attr.values)
          .map(value => value.id)

      const selectedSimpleAttributesId = simpleAttributesValues.filter(id => selectedAttributesValuesId.includes(id))

      filteredAttributesValuesIdAccumulator.push(...selectedValuesId, ...selectedSimpleAttributesId)

      return [ ...filteredAttributesValuesIdAccumulator ]
    }
  }

  /**
   * Get an array of selected attribute value IDs from the selection map
   * @param selectedAttributes Attribute values selection
   */
  function selectedAttributeValueIds(selectedAttributes: Map<number, USER_ATTRIBUTE_VALUE[]>): number[] {
    return Array.from(selectedAttributes.values()).flatMap(values => values.map(value => value.id))
  }

  /**
   * Get two separate collections of hierarchical and simple attributes from their statistics
   * @param mixedAttributes Collection of all attribute value statistics
   */
  function hierarchicalAndSimpleAttributes(mixedAttributes: USER_ATTRIBUTE[]): USER_ATTRIBUTE[][] {
    return mixedAttributes
      .reduce(([ hierarchicalAttributesAcc, simpleAttributesAcc ], attribute) => {
        if (attribute.isHierarchical) {
          hierarchicalAttributesAcc.push(attribute)
        } else {
          simpleAttributesAcc.push(attribute)
        }

        return [ hierarchicalAttributesAcc, simpleAttributesAcc ]
      }, [ Array<USER_ATTRIBUTE>(), Array<USER_ATTRIBUTE>() ])
  }

  return {
    allAttributes,
    emptyAllAttributeValueSelections,
    filteredAttributesId,
    firstInteractedHierarchicalAttributeId,
    getAttributeValuesForAttribute,
    getAvailableAttributeValuesForAttribute,
    getSelectedValueIdsForAttribute,
    hierarchicalAttributes,
    selectAttributeValue,
    selectedAttributeValueIds,
    selectedAttributeValues,
    simpleAttributes,
  }
}

export default useAttributes
