import React from "react"
import { t } from "ttag"
import { ApolloError } from "@apollo/client/errors"

import * as Styles from "./PermissionsManager.styles"

import { usePermissions } from "features/Permissions/PermissionsContext"
import { createPermissionsGroups } from "features/Permissions/services"
import { PermissionsGroupSection } from "features/Permissions/PermissionsManager/PermissionsGroupSection/PermissionsGroupSection"
import { NotificationContext } from "features/Notification"
import { BaseProps, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const PermissionsManager: React.FC<BaseProps> = () => {
  const {
    permissions, selectedAdmin, actions, hasChanges,
    fetchAdmins, isAdminLoading, setUserPermissions,
  } = usePermissions()
  const notification = React.useContext(NotificationContext)

  const groups = createPermissionsGroups(Array.from(permissions.values()) ?? [])

  return (
    <Styles.Container className="PermissionsManager">
      <Styles.Title level="section">{t`Administrator permissions`}</Styles.Title>
      <Styles.Paragraph>{t`Manage administrator permissions individually`}</Styles.Paragraph>
      {groups.map(group => <PermissionsGroupSection key={group.code} permissionsGroup={group}/>)}

      <Styles.ActionContainer>
        <PrimaryButton
          onClick={saveAdminPermissions}
          disabled={!hasChanges}
          isLoading={isAdminLoading}
        >
          {t`Validate`}
        </PrimaryButton>
      </Styles.ActionContainer>
    </Styles.Container>
  )

  /** Call setAdminPermissions, refetch the admins and show a notification on result */
  async function saveAdminPermissions() {
    if (selectedAdmin) {
      try {
        actions.setAdminLoading(true)

        await setUserPermissions({
          variables: {
            permissions: Array.from(permissions.values()).map(permission => ({
              code: permission.permission.code,
              isGranted: permission.isGranted,
            })),
            userId: selectedAdmin.id,
          },
        })
        notification.show(t`Success`, t`Permissions have been updated.`, "success")
        fetchAdmins()
      }
      catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
          notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
        }
      }
    }
  }
}
