import React, { useMemo } from "react"
import { t } from "ttag"

import { formatDateInterval } from "utilities/helpers"

import { MomentRange } from "models/DateTime/MomentRange"
import { DiscussionsChart, PieChartStatistic, TopicChart, getColor, getPeriodWording } from "../StatisticCharts"
import { AttributeCode, AttributesConfig, ChartType, Origin } from "../AttributesConfig"
import { LedDatePicker } from "../LedDatePicker"
import { LedGlobalStatisticsMetrics } from "../LedGlobalStatisticsMetrics"
import { LedNavigationTabs } from "../LedNavigationTabs"
import { usePsyDispositifNanoId } from "../psyDispositifStore"

import * as Styles from "./LedStatisticsPage.styles"
import * as Layout from "../layout.styles"

import {
  GetPsyAttributeStatisticsQuery,
  useGetPsyAttributeStatisticsQuery,
} from "graphql/queries/generated/GetPsyAttributeStatistics"
import { useGetPsyReportStatisticsByPeriodQuery } from "graphql/queries/generated/GetPsyReportStatisticsByPeriod"

export const LedStatisticsPage: React.FC = () => {
  const { psyDispositifNanoId } = usePsyDispositifNanoId()

  // selectedDate comes from LedDatePicker component, need to implement the logic when api is done
  const [ filterRange, setFilterRange ] = React.useState<MomentRange>()

  const attributesCodes = AttributeCode.getAllValues()

  const { data: psyReportStatistics, loading: arePsyReportStatisticsLoading } = useGetPsyReportStatisticsByPeriodQuery({
    variables: {
      dateInterval: formatDateInterval(filterRange?.from, filterRange?.to),
      psyDispositifNanoId,
    },
  })

  const {
    data: psyAttributeStatistics,
    loading: arePsyAttributeStatisticsLoading,
  } = useGetPsyAttributeStatisticsQuery({
    variables: {
      dateInterval: formatDateInterval(filterRange?.from, filterRange?.to),
      psyAttributeCodes: attributesCodes.map((attributeCode) => attributeCode.toString()),
      psyDispositifNanoId,
    },
  })

  const getChartDataFromStatistics = React.useCallback((
    attributeStatistics: GetPsyAttributeStatisticsQuery["getPsyAttributeStatistics"][0],
    origin: Origin = Origin.Report,
  ) => attributeStatistics.psyAttributeValueStatistics.map((attributeValueStat, index) => ({
    color: getColor(index),
    nameValue: AttributesConfig.getLabelForValue(attributeStatistics.label, attributeValueStat.value),
    value: origin === Origin.Report ? attributeValueStat.psyReportsCount : attributeValueStat.psyRecipientsCount,
  })), [])

  const discussionsChartData = React.useMemo(() => {
    const reportsStatistics = psyReportStatistics?.getPsyReportStatistics

    return reportsStatistics?.psyReportStatisticsByPeriod.list.map((attributeValueStat, index) => ({
      color: getColor(index),
      date: getPeriodWording(attributeValueStat.period, reportsStatistics.psyReportStatisticsByPeriod.interval),
      discussions: attributeValueStat.psyReportsCount,
      recipients: attributeValueStat.psyRecipientsCount,
    })) ?? []
  },
  [ psyReportStatistics ],
  )

  const psyAttributesStatistics = React.useMemo(
    () => psyAttributeStatistics?.getPsyAttributeStatistics.filter(
      (attributeStat) => attributeStat.psyAttributeValueStatistics.length > 0,
    ) ?? [],
    [ psyAttributeStatistics?.getPsyAttributeStatistics ],
  )

  /**
   * Number of rows for the statistics modules grid
   * The first one is for the discussions chart.
   * The rest is the number of attributes divided by 2 (since we have 2 attributes per row).
   */
  const numberOfChartRows = useMemo(
    () => 1 + Math.ceil(
      (arePsyAttributeStatisticsLoading
        ? attributesCodes.length + 2
        : psyAttributesStatistics.length ?? 0) / 2,
    ),
    [ arePsyAttributeStatisticsLoading, attributesCodes.length, psyAttributesStatistics.length ],
  )

  return (
    <LedNavigationTabs>
      <Layout.Container>
        <Styles.StatisticsModules $numberOfChartRows={numberOfChartRows}>
          <Layout.DatePickerContainer $span={6}>
            <LedDatePicker
              isFullWidth
              label={t`Select a period`}
              pickerAlignment="left"
              onValidate={setFilterRange}
              onReset={() => setFilterRange(undefined)}
              placeholder={t`All periods included`}
              value={filterRange}
            />
          </Layout.DatePickerContainer>

          <LedGlobalStatisticsMetrics
            filterRange={filterRange}
            psyDispositifNanoId={psyDispositifNanoId}
          />

          <Layout.Module $span={10}>
            <DiscussionsChart
              isLoading={arePsyReportStatisticsLoading}
              chartData={discussionsChartData}
            />
          </Layout.Module>

          {arePsyAttributeStatisticsLoading && <>
            <Layout.Module $span={10}>
              <PieChartStatistic isLoading />
            </Layout.Module>
            {attributesCodes.map((attributeCode) => (
              <Layout.Module $span={5} key={attributeCode.toString()}>
                <PieChartStatistic isLoading />
              </Layout.Module>
            ))}
          </>}

          {!arePsyAttributeStatisticsLoading && psyAttributesStatistics.map(
            (psyAttributeStat) => getChartComponent(psyAttributeStat),
          )}
        </Styles.StatisticsModules>
      </Layout.Container>
    </LedNavigationTabs>
  )

  function getChartComponent(psyAttributeStat: GetPsyAttributeStatisticsQuery["getPsyAttributeStatistics"][0]) {
    const attributeCode = AttributesConfig.getAttributeCodeFromLabel(psyAttributeStat.label)

    // If there is no attribute code defined, we assume it's a custom attribute
    if (!attributeCode) {
      return (
        <Layout.Module $span={5} key={psyAttributeStat.label}>
          <PieChartStatistic
            title={psyAttributeStat.label}
            chartData={getChartDataFromStatistics(psyAttributeStat)}
            attributeStatistics={psyAttributeStat}
          />
        </Layout.Module>
      )
    }

    const origin = AttributesConfig.getOriginFor(attributeCode)

    if (AttributesConfig.getChartTypeFor(attributeCode) === ChartType.Pie) {
      return (
        <Layout.Module $span={5} key={psyAttributeStat.label}>
          <PieChartStatistic
            title={AttributesConfig.getLabelFor(attributeCode)}
            chartData={getChartDataFromStatistics(psyAttributeStat, origin)}
            attributeStatistics={psyAttributeStat}
          />
        </Layout.Module>
      )
    }

    return (
      <Layout.Module $span={5} $withScroll key={psyAttributeStat.label}>
        <TopicChart
          title={AttributesConfig.getLabelFor(attributeCode)}
          chartData={getChartDataFromStatistics(psyAttributeStat, origin)}
        />
      </Layout.Module>
    )
  }
}
