import moment from "moment"
import * as React from "react"
import { SkeletonWrapper, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"

import { t } from "ttag"
import { Report } from "../LedReports"

import * as Styles from "./LedReportCard.styles"
import pdfIcon from "assets/images/pdf_icon.png"
import docIcon from "assets/images/doc_icon.png"
import videoIcon from "assets/images/video_icon.png"
import imageIcon from "assets/images/image_icon.png"
import excelIcon from "assets/images/excel_icon.png"
import fileIcon from "assets/images/file_icon.png"

interface FileMatch {
  icon: string,
  match: RegExp,
}

const fileIconsMatchers: FileMatch[] = [
  {
    icon: pdfIcon,
    match: /application\/(pdf)/,
  },
  {
    icon: videoIcon,
    match: /video\/(mp4|webm|mov)/,
  },
  {
    icon: excelIcon,
    match: /text\/(csv|xls|xlsx)/,
  },
  {
    icon: imageIcon,
    match: /image\/(png|jpg|jpeg)/,
  },
  {
    icon: docIcon,
    match: /application\/(vnd.openxmlformats-officedocument.wordprocessingml.document|msword)/,
  },
]
const LedReportCardSkeleton: React.FC = () => (
  <Styles.Report $disabled>
    <SkeletonWrapper width={40} height={45} isLoading/>
    <Styles.ReportInfos>
      <SkeletonWrapper width={100} height={18} isLoading/>
      <SkeletonWrapper width={130} height={15} isLoading/>
    </Styles.ReportInfos>
  </Styles.Report>
)

export const LedReportCardComponent: React.FC<LedReportCardProps> = ({ report }) => {
  const iconFile = React.useMemo(() => {
    const fileMatcher = fileIconsMatchers.find(fileMatcher => report.contentType?.match(fileMatcher.match))
    return fileMatcher?.icon || fileIcon
  }, [ report.contentType ])

  return (
    <Styles.Report href={report.url} target="_blank">
      <Styles.ReportIcon src={iconFile}/>
      <Styles.ReportInfos>
        <Styles.ReportTitle>
          {report.title}
        </Styles.ReportTitle>
        <Styles.ReportDate>
          {t`Published on : ` + moment(report.createdAt).format("DD/MM/YYYY")}
        </Styles.ReportDate>
      </Styles.ReportInfos>
    </Styles.Report>
  )
}

export const LedReportCard = withSkeleton(
  LedReportCardComponent,
  LedReportCardSkeleton,
)

export interface LedReportCardProps {
  report: Report,
}
