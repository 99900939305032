import { DialogContent } from "@material-ui/core"
import { t } from "ttag"
import * as Styles from "features/Tutorial/TutorialDialog.styles"
import React from "react"

const TutorialDialog: React.FC<TutorialDialogProps> = (props) => {
  return (
    <Styles.Dialog style={{ zIndex: 2500 }} open={props.open}>
      <DialogContent>{props.message}</DialogContent>
      <Styles.Actions>
        <Styles.ActionButton height="small" onClick={props.onCancel}>{t`Return`}</Styles.ActionButton>
        <Styles.ActionButton height="small" destructive onClick={props.onConfirm}>{t`Confirm`}</Styles.ActionButton>
      </Styles.Actions>
    </Styles.Dialog>
  )
}

type TutorialDialogProps = {
  open: boolean,
  onCancel: () => void,
  onConfirm: () => void,
  message: string,
}

export default TutorialDialog
