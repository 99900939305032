import React from "react"
import { jt, t } from "ttag"

import { Admin } from "features/Permissions/models"
import { ApplyScopeConfirmationDialog } from "features/Permissions/PermissionsManager/ConfirmationDialogs"
import useScopeManager from "features/Permissions/PermissionsManager/useScopeManager"
import { BaseProps, PrimaryButton, TableProps, TableRowData, Title } from "@humanpredictiveintelligence/myqvt-library"
import useAttributes from "services/useAttributes"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"

import * as Styles from "./ScopeConfiguratorDialog.styles"
import * as PermissionStyles from "../Permissions.styles"

/**
 * Display a column for each hierarchical attribute ordered by hierarchy position with all their attributes values as
 * rows.
 *
 * @param props
 * @constructor
 */
export const ScopeConfiguratorDialog: React.FC<ScopeConfiguratorDialogProps> = (props) => {
  const { saveAdminScopes, areScopesLoading } = useScopeManager()

  const [ isConfirmationOpened, setIsConfirmationOpened ] = React.useState<boolean>(false)
  const [ currentAttributeValuesOpened, setCurrentAttributesOpened ] = React.useState<Map<number, number>>(new Map())
  const scopeIcon = <PermissionStyles.ScopeIcon key="scopeIcon"/>

  const {
    allAttributes,
    hierarchicalAttributes,
    getAttributeValuesForAttribute,
    selectAttributeValue,
    selectedAttributeValues,
    getSelectedValueIdsForAttribute,
    selectedAttributeValueIds,
  } = useAttributes(props.userAttributes, getActiveUserScopesAsAttributeHookDefaultSelectionMap())

  return (
    <>
      <Styles.Dialog
        classes={{ paper: "Container" }}
        open={props.isOpen}
      >

        <Styles.Header>
          <Title level="metricCard">{t`Choose a scope`}</Title>
          <Styles.Paragraph>
            {/* eslint-disable-next-line max-len */}
            {jt`In order to improve permission management accuracy, you can restrict permissions on a scope. However, permissions flagged with the icon ${scopeIcon} wont be available.`}
          </Styles.Paragraph>
        </Styles.Header>

        <Styles.ScopeConfiguratorContainerCard>
          <Styles.ScopeGridContainer container>
            {hierarchicalAttributes.map(attribute => (
              <Styles.ScopeGridColumn
                item
                xs={4}
                key={attribute.id}
              >
                <Styles.AttributeTable
                  columnDisplayNames={new Map([
                    [ "label", attribute.name ],
                  ])}
                  columns={[
                    "label",
                  ]}
                  headerClassName="AttributeTableHeader"
                  dataList={getAttributeValuesRowsDataForAttribute(attribute)}
                  selectedIds={getSelectedAttributeValueIds(attribute, selectedAttributeValues).map(String)}
                  selectedIdCheckBoxStyles={getCheckboxStyles(selectedAttributeValues)}
                  onSelect={(selectedId) => selectAttribute(attribute, Number(selectedId))}
                  actionIcon="play_arrow"
                  actionIconPosition="end"
                  highlightRowColor="#E1E9F8"
                  onRowClicked={(selectedId) => openDirectories(attribute, Number(selectedId))}
                  rowIdsToHighlight={currentAttributeValuesOpened.get(attribute.id)
                    ? [ currentAttributeValuesOpened.get(attribute.id)! ].map(String)
                    : undefined
                  }
                  isActionVisible={(selectedId) => getAttributeValueHasChildren(Number(selectedId))}
                  onActionClicked={(selectedId) => openDirectories(attribute, Number(selectedId))}
                />
              </Styles.ScopeGridColumn>
            ))}
          </Styles.ScopeGridContainer>
        </Styles.ScopeConfiguratorContainerCard>
        <Styles.Actions>
          <PrimaryButton destructive onClick={props.onDialogClose}>{t`Cancel`}</PrimaryButton>
          <PrimaryButton onClick={() => setIsConfirmationOpened(true)}>
            {t`Validate`}
          </PrimaryButton>
        </Styles.Actions>
      </Styles.Dialog>
      <ApplyScopeConfirmationDialog
        isOpen={isConfirmationOpened}
        isLoading={areScopesLoading}
        onConfirmButtonClick={confirmScopesSaving}
        onCancelButtonClick={() => setIsConfirmationOpened(false)}
      />
    </>
  )

  /**
   * Save the selected user scopes, close the confirmation dialog and the scope configurator dialog
   */
  async function confirmScopesSaving() {
    await saveAdminScopes(props.admin, selectedAttributeValueIds(selectedAttributeValues))

    setIsConfirmationOpened(false)
    props.onScopesSaved?.()
    props.onDialogClose?.()
  }

  /**
   * Return true if the attribute value with the given `attributeValueId` has children attribute values
   * @param attributeValueId
   */
  function getAttributeValueHasChildren(attributeValueId: number): boolean {
    return !!new Array<USER_ATTRIBUTE_VALUE>()
      .concat(...allAttributes.map(attribute => attribute.values))
      .find(attributeValue => attributeValue.parentValueId === attributeValueId)
  }

  /**
   * Return a map of the active user scopes to be used with `useAttributes` hook.
   * @see useAttributes
   */
  function getActiveUserScopesAsAttributeHookDefaultSelectionMap(): Map<number, USER_ATTRIBUTE_VALUE[]> {
    const activeUserScopes = new Map<number, USER_ATTRIBUTE_VALUE[]>()
    props.admin.scopes.forEach((scope) => {
      const activeUserScope = activeUserScopes.get(scope.attributeId) ?? []
      activeUserScope.push(USER_ATTRIBUTE_VALUE.fromSkinnyUserAttributeValue(scope))

      activeUserScopes.set(scope.attributeId, activeUserScope)
    })
    return activeUserScopes
  }

  /**
   * Add the given `selectedAttributeValueId` to the list of selected attribute values
   * @see useAttributes
   * @param attribute
   * @param selectedAttributeValueId
   */
  function selectAttribute(attribute: USER_ATTRIBUTE, selectedAttributeValueId: number) {
    /**  If "All" selection is clicked */
    if (selectedAttributeValueId < 0) {
      /** Close all children currently opened */
      let AttributeValueIndexToClose = Math.abs(selectedAttributeValueId)
      for (
        AttributeValueIndexToClose;
        AttributeValueIndexToClose <= currentAttributeValuesOpened.size + 1;
        AttributeValueIndexToClose++
      ) {
        currentAttributeValuesOpened.delete(AttributeValueIndexToClose)
      }

      /** If attribute has selectedValues we remove them and exit the function */
      if (selectedAttributeValues.get(attribute.id)?.length) {
        return selectAttributeValue(attribute, [])
      }
      /** If attribute has no selected values but has a parent */
      else if (attribute.parentAttributeId) {
        const parentAttributeValueId = currentAttributeValuesOpened.get(attribute.parentAttributeId)

        const currentlySelectedAttributesValues =
          selectedAttributeValues.get(attribute.parentAttributeId)
            ?.map(selectedAttributeValues => selectedAttributeValues.id)
            ?? []

        /** Toggle the parentAttributeValue */
        const parentAttributeValueSelectedIndex = currentlySelectedAttributesValues
          .findIndex((selectedValuesId) => selectedValuesId === parentAttributeValueId)

        /** If selected, it's removed */
        if (parentAttributeValueSelectedIndex >= 0) {
          currentlySelectedAttributesValues.splice(parentAttributeValueSelectedIndex, 1)
        }
        /** If unselected, it's added */
        else if (parentAttributeValueId) {
          currentlySelectedAttributesValues.push(Number(parentAttributeValueId))
        }

        /** Return the new selection */
        return selectAttributeValue(attribute.parents(hierarchicalAttributes)[0], currentlySelectedAttributesValues)
      }
    }

    const selectedValues = selectedAttributeValues
      .get(attribute.id)?.reduce((accSelectedAttributesValues, currentAttributeValueSelected) => {
        if (currentAttributeValueSelected.id === selectedAttributeValueId) {
          accSelectedAttributesValues.splice(0, 1)
        }
        else {
          accSelectedAttributesValues.push(currentAttributeValueSelected.id)
        }
        return accSelectedAttributesValues
      }, [ selectedAttributeValueId ]) ?? []

    selectAttributeValue(attribute, selectedValues)
  }

  /**
   *
   * @param attribute
   */
  function getAttributeHeadRow(attribute: USER_ATTRIBUTE) {
    const label = !attribute.parentAttributeId ? t`Global (default)` : t`All`
    return {
      attributeValueId: -attribute.id,
      label,
    }
  }

  function getSelectedAttributeValueIds(
    attribute: USER_ATTRIBUTE,
    userAttributeValueMap: Map<number, USER_ATTRIBUTE_VALUE[]>,
  ): number[] {
    const selectedIds: number[] = []
    userAttributeValueMap.forEach((selectedAttributeValues, attributeId) => {
      const activeAttribute = allAttributes.find(attr => attr.id === attributeId)
      if (activeAttribute) {
        if (attributeId === attribute.id) {
          // Only if the attribute is active
          const hasChildSelected = getSelectedValueIdsForAttribute(
            attribute,
            selectedAttributeValues.map(attributeValue => attributeValue.id),
          ).length > 0

          const currentAttributeValueOpenedId = activeAttribute.parentAttributeId
            && currentAttributeValuesOpened.get(activeAttribute.parentAttributeId)

          const parents = userAttributeValueMap.get(activeAttribute.parentAttributeId ?? 0)
            ?.find(attributesValues => attributesValues.id === currentAttributeValueOpenedId)

          if (!hasChildSelected && (parents || !activeAttribute.parentAttributeId)) {
            selectedIds.push(-attribute.id)
          }
        }
      }

      selectedAttributeValues.forEach(selectedAttributeValue => {
        selectedIds.push(selectedAttributeValue.id)
      })
    })
    return selectedIds.length ? selectedIds : [ 0 ]
  }

  /**
   * From the given list of selected user attribute values, go through each attribute value and
   * add a specific style for the parent of the attribute value if the attribute value has a parent attribute value
   */
  function getCheckboxStyles(
    userAttributeValueMap: Map<number, USER_ATTRIBUTE_VALUE[]>,
  ): TableProps["selectedIdCheckBoxStyles"] {
    const userAttributeCheckboxStyles: TableProps["selectedIdCheckBoxStyles"] = {}

    userAttributeValueMap.forEach(userAttributeValues => {
      userAttributeValues.forEach(userAttributeValue => {
        if (userAttributeValue.parentValueId) {
          userAttributeCheckboxStyles[userAttributeValue.parentValueId] = "indeterminate"
        }
      })
    })

    return userAttributeCheckboxStyles
  }

  /**
   * Get an array of TableRowData, one for each attribute value given
   * Display the list only if the parent attribute is selected
   *
   * @param attribute
   */
  function getAttributeValuesRowsDataForAttribute(attribute: USER_ATTRIBUTE): TableRowData[] {
    const attributeValuesList = getAttributeValuesForAttribute(attribute)
    const headAttributeRow = getAttributeHeadRow(attribute)
    const attributeValuesRowData = attributeValuesList.reduce((accRowsData, attributeValue) => {
      if (
        !attributeValue.parentValueId
        || (
          attribute.parentAttributeId
          && attributeValue.parentValueId
          === currentAttributeValuesOpened.get(attribute.parentAttributeId)
        )
      ) {
        const userValues = new Map<string, string>()
        userValues.set("label", attributeValue.label)

        accRowsData.push({
          id: attributeValue.id.toString(),
          values: userValues,
        })
      }

      return accRowsData
    }, Array<{ id: string, values: Map<string, string> }>())

    if (attributeValuesRowData.length !== 0) {
      attributeValuesRowData.unshift({
        id: headAttributeRow.attributeValueId.toString(),
        values: new Map().set("label", headAttributeRow.label),
      })
    }

    return attributeValuesRowData
  }

  function openDirectories(attribute: USER_ATTRIBUTE, selectedId: number) {
    if (getAttributeValueHasChildren(selectedId)) {
      const AttributeDescendants = attribute.descendants(allAttributes)
      currentAttributeValuesOpened.set(attribute.id, Number(selectedId))

      if (AttributeDescendants.length > 1) {
        currentAttributeValuesOpened.delete(AttributeDescendants[0].id)
      }
      setCurrentAttributesOpened(new Map(currentAttributeValuesOpened))
    }
  }
}

interface ScopeConfiguratorDialogProps extends BaseProps {
  isOpen: boolean,
  onScopesSaved: () => void,
  onDialogClose?: () => void,
  admin: Admin,
  userAttributes: USER_ATTRIBUTE[],
}

export default ScopeConfiguratorDialog
