import styled from "styled-components/macro"
import { Card, Title } from "@humanpredictiveintelligence/myqvt-library"

export const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: 100%;`

export const PageTitle = styled(Title)`
  text-align: center;
`

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeTitleSmall};
  white-space: pre-line;
  text-align: center;
`

export const Illustration = styled.img`
  width: 50%;
`
