import { ApolloError, useApolloClient } from "@apollo/client"
import * as React from "react"
import { useHistory, useParams } from "react-router-dom"
import { t } from "ttag"
import * as Mui from "@material-ui/core"

import { ScheduledSurveyDetails } from "../ScheduledSurveyDetails"
import { ScheduledSurveyInList, ScheduledSurveyList } from "../ScheduledSurveyList"
import { ScheduledSurveyResultsPage } from "../ScheduledSurveyResults/ScheduledSurveyResultsPage"
import { useUserPermissions } from "features/Permissions"
import { APPLICATION_URL } from "features/Navigation"
import { useTutorial } from "features/Tutorial"
import { NotificationContext } from "features/Notification"
import { TUTORIAL_SURVEYS_EVENTS } from "features/Tutorial/contents/SurveysPage/Events"
import { IconButton, Illustration } from "@humanpredictiveintelligence/myqvt-library"
import { Order, Scheduled_Survey_Status as ScheduledSurveyStatus, UserPermissionCode } from "models/generated"
import { SCHEDULED_SURVEY_SERVICE } from "services/ScheduledSurveyService"
import businessConfig from "config/business"
import * as Styles from "./ScheduledSurveysPage.styles"

import {
  AllStatusesScheduledSurveysDocument,
  AllStatusesScheduledSurveysQuery,
  AllStatusesScheduledSurveysQueryVariables,
} from "graphql/queries/generated/AllStatusesScheduledSurveys"

import {
  ScheduledSurveysListAroundTargetDocument,
  ScheduledSurveysListAroundTargetQuery,
  ScheduledSurveysListAroundTargetQueryVariables,
} from "graphql/queries/generated/ScheduledSurveysListAroundTarget"

import {
  ScheduledSurveysListFromTargetDocument,
  ScheduledSurveysListFromTargetQuery,
  ScheduledSurveysListFromTargetQueryVariables,
} from "graphql/queries/generated/ScheduledSurveysListFromTarget"

import resultsIllustration from "assets/images/illustration_results.png"

export const ScheduledSurveysPage = () => {
  const theme = Mui.useTheme()
  const isCollapsed = !Mui.useMediaQuery(theme.breakpoints.up("landscape"))
  const notification = React.useContext(NotificationContext)
  const isUserAllowed = useUserPermissions()
  const apolloClient = useApolloClient()
  const history = useHistory()
  const params = useParams<ScheduledSurveysPageParams>()
  const { triggerTutorialEvent } = useTutorial()

  const scheduledSurveysListRef = React.useRef<HTMLDivElement>(null)

  const isAllowedToScheduleSurveys = isUserAllowed(UserPermissionCode.ScheduledSurveyManage)

  const action = params.action
  const selectedSurveyId = params.surveyId
    ? parseInt(params.surveyId, 10)
    : undefined
  const [ isInitialFetchDone, setInitialFetchDone ] = React.useState(false)
  const [ isScheduledSurveysLoading, setScheduledSurveysLoading ] = React.useState(true)
  const [ extraScheduledSurveyLoading, setExtraScheduledSurveyLoading ]
    = React.useState<"previous" | "next" | "none">("none")
  const [ scheduledSurveys, setScheduledSurveys ] = React.useState<ScheduledSurveyInList[]>([])
  const [ initialScrollToSurveyId, setInitialScrollToSurveyId ] = React.useState<number | undefined>(undefined)
  const [ isSurveyEdited, setIsSurveyEdited ] = React.useState(false)
  const selectedScheduledSurvey
    = (scheduledSurveys?.find(survey => survey.schedulingId === selectedSurveyId)) || undefined

  if (!isInitialFetchDone) {
    loadInitialScheduledSurveysList()
  }

  return (
    <Styles.Container data-testid="ScheduledSurveysPage">
      <Styles.SurveysList $isCollapsed={isCollapsed}>
        <Styles.SurveysListHeaderContainer>
          <div>
            <Styles.SurveysListTitle>{t`Surveys`}</Styles.SurveysListTitle>
            <IconButton
              onClick={() => triggerTutorialEvent(TUTORIAL_SURVEYS_EVENTS.scheduledSurveyCreateInitialized())}
              icon={"date_range"}
              size={isCollapsed ? "extrabig" : "big"}
              link={APPLICATION_URL.newScheduledSurvey()}
              isDisabled={!isAllowedToScheduleSurveys}
              isAllowed={isAllowedToScheduleSurveys}
              tooltip={!isAllowedToScheduleSurveys
                ? t`You don't have sufficient rights to perform this action`
                : isCollapsed ? t`Schedule a survey` : undefined
              }
              tooltipPlacement={"top"}
            >
              {t`Schedule a survey`}
            </IconButton>
          </div>
        </Styles.SurveysListHeaderContainer>
        <ScheduledSurveyList
          isCollapsed={isCollapsed}
          isLoading={isScheduledSurveysLoading}
          listContainerReference={scheduledSurveysListRef}
          surveys={scheduledSurveys}
          activeSurveyId={selectedScheduledSurvey && selectedScheduledSurvey.schedulingId}
          initialScrollToSurveyId={initialScrollToSurveyId}
          onScrollToTop={() => loadMoreScheduledSurveys("next")}
          onScrollToBottom={() => loadMoreScheduledSurveys("previous")}
          isTopLoaderDisplayed={extraScheduledSurveyLoading === "next"}
          isBottomLoaderDisplayed={extraScheduledSurveyLoading === "previous"}
          isSurveyEdited={isSurveyEdited}
        />

      </Styles.SurveysList>
      <Styles.SurveyDetails $isEmpty={!selectedScheduledSurvey && action !== "new"}>
        {(() => {
          if (isScheduledSurveysLoading) {
            return undefined
          }
          else if (selectedScheduledSurvey?.status.absolute === ScheduledSurveyStatus.Done) {
            return (
              <ScheduledSurveyResultsPage
                key={selectedScheduledSurvey.schedulingId}
                id={selectedScheduledSurvey.schedulingId}
              />
            )
          }
          else if (action === "new" || selectedScheduledSurvey) {
            return (
              <ScheduledSurveyDetails
                scheduledSurveyId={selectedScheduledSurvey?.schedulingId}
                editingMode={action === "edit" || action === "new" ? "edit" : "read"}
                onChangeEditingMode={navigateToEditingMode}
                onScheduledSurveyCreated={(scheduledSurveyId) => {
                  loadInitialScheduledSurveysList(scheduledSurveyId).then(() => {
                    const target = `[data-cy=ScheduledSurveyList__survey--${scheduledSurveyId}]`
                    setInitialScrollToSurveyId(scheduledSurveyId)
                    triggerTutorialEvent(TUTORIAL_SURVEYS_EVENTS.scheduledSurveyCreateConfirmed(target))
                  })
                }}
                onScheduledSurveyEdited={() => {
                  setIsSurveyEdited(true)
                  loadInitialScheduledSurveysList()
                  history.push(APPLICATION_URL.scheduledSurvey(selectedSurveyId!))
                }}
                onDeleteScheduledSurvey={() => {
                  history.push(APPLICATION_URL.speakupScheduledSurveys())
                  loadInitialScheduledSurveysList(-1)
                }}
              />
            )
          }
          else {
            return (
              <Illustration
                illustration={resultsIllustration}
                title={t`You have not selected any survey yet...`}
              >
                {t`You can select a survey to view or create a new one.`}
              </Illustration>
            )
          }
        })()}
      </Styles.SurveyDetails>
    </Styles.Container>
  )

  /**
   * Load the initial collection of scheduled survey
   */
  async function loadInitialScheduledSurveysList(surveyId?: number) {
    const targetId = surveyId || parseInt(params.surveyId || "", 10)
    let isTargetNotFound = false
    let flattenedScheduledSurveysList: ScheduledSurveyInList[] = []
    if (!isNaN(targetId) && targetId !== -1) {
      try {
        const fetchedScheduledSurveys = await apolloClient.query<ScheduledSurveysListAroundTargetQuery,
          ScheduledSurveysListAroundTargetQueryVariables>({
            fetchPolicy: "network-only",
            query: ScheduledSurveysListAroundTargetDocument,
            variables: {
              fromId: targetId,
            },
          })

        if (
          fetchedScheduledSurveys.data?.afterScheduledSurveys.length === 0
          && fetchedScheduledSurveys.data.beforeScheduledSurveys.length === 0
        ) {
          isTargetNotFound = true
        } else {
          flattenedScheduledSurveysList = [
            ...[
              ...(fetchedScheduledSurveys.data?.afterScheduledSurveys || []),
            ].reverse(),
          ]

          if (fetchedScheduledSurveys.data?.targetScheduledSurvey) {
            flattenedScheduledSurveysList.push(fetchedScheduledSurveys.data.targetScheduledSurvey)
          }

          flattenedScheduledSurveysList.push(...(fetchedScheduledSurveys.data?.beforeScheduledSurveys || []))
        }
      } catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
          notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
        }
      }
    }

    if (isNaN(targetId) || isTargetNotFound || targetId === -1) {
      try {
        const fetchedScheduledSurveys = await apolloClient.query<AllStatusesScheduledSurveysQuery,
          AllStatusesScheduledSurveysQueryVariables>({
            fetchPolicy: "network-only",
            query: AllStatusesScheduledSurveysDocument,
            variables: {
              limit: businessConfig.scheduledSurvey.initialNumberOfOngoingSurveysDisplayed,
            },
          })

        fetchedScheduledSurveys.data!.todoScheduledSurveys
          .forEach(survey => flattenedScheduledSurveysList.push(survey))
        fetchedScheduledSurveys.data!.ongoingScheduledSurveys
          .forEach(survey => flattenedScheduledSurveysList.push(survey))
        fetchedScheduledSurveys.data!.doneScheduledSurveys
          .forEach(survey => flattenedScheduledSurveysList.push(survey))

        flattenedScheduledSurveysList = SCHEDULED_SURVEY_SERVICE.truncateInitialList(flattenedScheduledSurveysList)
      } catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
          notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
        }
      }
    }

    if (flattenedScheduledSurveysList.length > 0) {
      setInitialScrollToSurveyId(
        selectedSurveyId || SCHEDULED_SURVEY_SERVICE.initiallyFocusedSurveyId(flattenedScheduledSurveysList),
      )
    }

    setInitialFetchDone(true)
    setScheduledSurveysLoading(false)
    setScheduledSurveys(dedupedScheduledSurveyList(flattenedScheduledSurveysList))
    setIsSurveyEdited(false)
  }

  /** Load more scheduled surveys, either previous ones or next ones */
  async function loadMoreScheduledSurveys(direction: "previous" | "next") {
    try {
      setExtraScheduledSurveyLoading(direction)

      const fetchedScheduledSurveys = await apolloClient.query<ScheduledSurveysListFromTargetQuery,
        ScheduledSurveysListFromTargetQueryVariables>({
          fetchPolicy: "network-only",
          query: ScheduledSurveysListFromTargetDocument,
          variables: {
            fromId: direction === "previous"
              ? scheduledSurveys[scheduledSurveys.length - 1].schedulingId
              : scheduledSurveys[0].schedulingId,
            limit: businessConfig.scheduledSurvey.numberOfLoadMore,
            order: direction === "previous" ? Order.Descending : Order.Ascending,
          },
        })

      setScheduledSurveys(
        dedupedScheduledSurveyList(direction === "previous"
          ? [
            ...scheduledSurveys,
            ...fetchedScheduledSurveys.data!.scheduledSurveys,
          ]
          : [
            ...fetchedScheduledSurveys.data!.scheduledSurveys.reverse(),
            ...scheduledSurveys,
          ],
        ),
      )

      setExtraScheduledSurveyLoading("none")
    } catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
      }
    }
  }

  /**
   * Filter a list of scheduledSurvey to remove duplicates
   * @param scheduledSurveyList The list of scheduledSurveys to filter
   */
  function dedupedScheduledSurveyList(scheduledSurveyList: ScheduledSurveyInList[]): ScheduledSurveyInList[] {
    const dedupedScheduledSurveyMap = new Map()

    scheduledSurveyList.forEach(
      scheduledSurvey => dedupedScheduledSurveyMap.set(scheduledSurvey.schedulingId, scheduledSurvey),
    )

    return Array.from(dedupedScheduledSurveyMap.values())
  }

  /**
   * Navigate to the specified editing mode.
   * If no Scheduled Survey is selected, navigates to the list of Scheduled Surveys
   */
  function navigateToEditingMode(mode: "edit" | "read") {
    if (!selectedSurveyId) {
      history.replace(APPLICATION_URL.speakupScheduledSurveys())
    } else if (mode === "edit") {
      history.push(APPLICATION_URL.editScheduledSurvey(selectedSurveyId))
    } else {
      history.replace(APPLICATION_URL.scheduledSurvey(selectedSurveyId))
    }
  }
}

interface ScheduledSurveysPageParams {
  surveyId?: string,
  action?: string,
}
