import moment from "moment"
import React from "react"

import { ErroredScheduledSurveysCalendar, ScheduledSurveysCalendar } from "../ScheduledSurveysCalendar"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { SCHEDULED_SURVEY_SERVICE } from "services/ScheduledSurveyService"

import { useScheduledSurveysListFromPeriodQuery } from "graphql/queries/generated/ScheduledSurveysListFromPeriod"

export const ScheduledSurveysCalendarContainer: React.FC<BaseProps> = (props) => {
  const [ monthInView, setMonthInView ] = React.useState(moment())
  const beginningOfPreviousMonth = monthInView.clone().subtract(1, "month").startOf("month").toString()
  const endOfNextMonth = monthInView.clone().add(1, "month").endOf("month").toString()

  const {
    data: scheduledSurveysList,
    loading: isScheduledSurveysListLoading,
    error: scheduledSurveysListError,
  } = useScheduledSurveysListFromPeriodQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      period: {
        ending_from: beginningOfPreviousMonth,
        ending_to: endOfNextMonth,
      },
    },
  })

  const daysWithScheduledSurveys =
        SCHEDULED_SURVEY_SERVICE.allParticipationDatesBySurvey(scheduledSurveysList?.scheduledSurveys || [])

  if (!isScheduledSurveysListLoading && scheduledSurveysListError) {
    return <ErroredScheduledSurveysCalendar className={props.className}/>
  }

  return (
    <div className={props.className}>
      <ScheduledSurveysCalendar
        scheduledDays={daysWithScheduledSurveys}
        onMonthChange={(month) => setMonthInView(moment(month))}
      />
    </div>
  )
}
