import * as React from "react"
import { Skeletonable, Title } from "@humanpredictiveintelligence/myqvt-library"

import * as Styles from "./LedMetric.styles"

export const LedMetric: React.FC<Skeletonable<LedMetricProps>> = (props) => {
  return (
    <>
      <Styles.Heading>
        <Title level="metricCard">
          {props.title}
        </Title>
      </Styles.Heading>
      {props.isLoading ? (
        <Styles.ValueSkeleton width={30} height={30} isLoading/>
      ) : (
        <Styles.Value $for={props.for}>{props.value}</Styles.Value>
      )}
    </>
  )
}

interface LedMetricProps {
  title: string,
  value: number,
  for: "meetings" | "beneficiary",
}
