import * as React from "react"
import { t } from "ttag"

import business from "config/business"

import { useUserPermissions } from "features/Permissions"
import { CommentsTable } from "features/Comments"
import { MultiLanguageFeedback } from "../MultiLanguageFeedback"
import { AttributableFeedback } from "../AttributableFeedback"
import { ScheduledSurveyQuestionRanking } from "../ScheduledSurveyQuestionRanking"
import {
  PagedNavigation,
  Select,
  SelectOptionProps,
  useIsElementOnScreen,
} from "@humanpredictiveintelligence/myqvt-library"
import { SCHEDULED_SURVEY_RESULTS_SERVICE } from "services/ScheduledSurveyResultsService"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"
import {
  CommentObjectType,
  Comment_Translation as CommentTranslation,
  CommentWrittenAs,
  UserPermissionCode,
} from "models/generated"
import * as Styles from "./ScheduledSurveyQuestionResults.styles"

import {
  ResponseCommentFragmentFragment,
  useScheduledSurveyQuestionResponsesQuery,
} from "graphql/queries/generated/ScheduledSurveyQuestionResponses"
import { useAddQuestionFeedbackMutation } from "graphql/mutations/generated/AddQuestionFeedback"
import { useUpdateQuestionFeedbackMutation } from "graphql/mutations/generated/UpdateQuestionFeedback"

enum ResultTab {
  Results,
  Comments,
  Ranking,
  GlobalFeedback,
  LocalFeedback
}

export const ScheduledSurveyQuestionResults: React.FC<ScheduledSurveyQuestionResultsProps> = (props) => {
  const isUserAllowed = useUserPermissions()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ questionStatistics, setQuestionStatistics ] = React.useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ questionInformation, setQuestionInformation ] = React.useState<any>()

  const [ recipientsComments, setRecipientsComments ] = React.useState<Array<ResponseCommentFragmentFragment>>()

  const [ recipientsCommentsNumber, setRecipientsCommentsNumber ] = React.useState<number>()

  const [ areResultsInitiallyFetched, setAreResultsInitiallyFetched ] = React.useState(false)
  const [ resultsFetchingError, setResultsFetchingError ] = React.useState(false)
  const [ retryingFetchingResults, setRetryingFetchingResults ] = React.useState(false)

  const isAllowedToReadResponseComments = isUserAllowed(UserPermissionCode.CommentsRead)
  const isAllowedToReadQuestionFeedback = isUserAllowed(UserPermissionCode.QuestionFeedbacksRead)
  const isAllowedToReadLocalQuestionFeedback = isUserAllowed(UserPermissionCode.LocalQuestionFeedbacksRead)

  const isAllowedToWriteLocalFeedback = () => isUserAllowed(UserPermissionCode.LocalQuestionFeedbacksWrite)
  const isAllowedToPublishLocalFeedback = () => isUserAllowed(UserPermissionCode.LocalQuestionFeedbacksPublish)
  const isAllowedToPublishFeedback = () => isUserAllowed(UserPermissionCode.QuestionFeedbacksPublish)
  const isAllowedToWriteFeedback = () => isUserAllowed(UserPermissionCode.QuestionFeedbacksWrite)

  const questionRef = React.useRef<HTMLDivElement>(null)
  const isQuestionOnScreen = useIsElementOnScreen(questionRef)

  const [ activeTab, setActiveTab ] =
    React.useState<ResultTab>(props.focusCommentId ? ResultTab.Comments : ResultTab.Results)

  const [ commentsPagination, setCommentsPagination ] = React.useState({
    currentPage: 1,
    limit: business.results.commentsPageSizes[0],
    loadingOffset: 0,
  })

  const selectedAttributesIds = props.userAttributesValues
    .filter((attributeValue) => attributeValue.isSelected)
    .map(attributeValue => attributeValue.id)

  const mainHierarchyHeadAttributes = props.userAttributesValues.filter(
    attributeValue => attributeValue.isMainHierarchyHead,
  )

  const isWarmUpRequest = React.useRef(true)

  const onError = () => {
    if (isWarmUpRequest.current) {
      isWarmUpRequest.current = false
      setRetryingFetchingResults(true)
      refetchQuestionResponses().catch(() => {
        onError()
      })
    } else {
      setRetryingFetchingResults(false)
      setResultsFetchingError(true)
    }
  }

  const { refetch: refetchQuestionResponses, loading: areResultsLoading } = useScheduledSurveyQuestionResponsesQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    onCompleted: (results) => {
      if (results && results.scheduledSurvey && results.scheduledSurvey.statistics.questions) {
        setQuestionStatistics(results.scheduledSurvey.statistics.questions[0])
        setRecipientsComments(results.scheduledSurvey.statistics.questions[0].responseComments)
        setRecipientsCommentsNumber(results.scheduledSurvey.statistics.questions[0].responseCommentsCount)
        setQuestionInformation(results.scheduledSurvey.scheduledQuestions[0])
      }
      setAreResultsInitiallyFetched(true)
      setResultsFetchingError(false)
      setRetryingFetchingResults(false)
    },
    onError: onError,
    variables: {
      filterAttributeIds: selectedAttributesIds || undefined,
      isUserAllowedToSeeComments: isUserAllowed(UserPermissionCode.CommentsRead),
      questionIds: [ props.referenceQuestionId ],
      questionResponseCommentsLimit: commentsPagination.limit,
      questionResponseCommentsOffset: commentsPagination.loadingOffset,
      scheduledSurveyId: props.scheduledSurveyId,
      userGroupsUuids: props.userGroupsUuids || undefined,
      writtenAs: CommentWrittenAs.Admin,
    },
  })

  const [ addComment ] = useAddQuestionFeedbackMutation()
  const [ updateComment ] = useUpdateQuestionFeedbackMutation()

  React.useEffect(() => {
    const questionFromMap = props.questionRefetchMap?.get(props.questionNumber)
    if ((!props.isLazyLoaded || isQuestionOnScreen) && questionFromMap) {
      props.onQuestionResponsesRefetched()
      refetchQuestionResponses()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isQuestionOnScreen,
    props.isLazyLoaded,
    refetchQuestionResponses,
    props.questionRefetchMap,
    props.userGroupsUuids,
    props.referenceQuestionId,
    props.onQuestionResponsesRefetched,
    props.questionNumber,
  ])

  const sortedAttributesGrades = SCHEDULED_SURVEY_RESULTS_SERVICE.sortedAttributesGrades(
    questionStatistics?.attributeGrades,
  )

  const rankingValues = React.useMemo(() => {
    return sortedAttributesGrades?.map((rankedAttribute, index) => {
      const attributeValue = props.userAttributesValues.find(
        (attribute: QuestionResultsUserAttributeValue) => {
          return attribute.id === rankedAttribute.attribute.valueId
        })

      return {
        hasEnoughResults: !rankedAttribute.isClusterViolated,
        isAllSkipped: rankedAttribute.isAllSkipped,
        label: rankedAttribute.attribute.value,
        order: index + 1,
        usersCount: attributeValue?.usersCount,
        value: rankedAttribute.grade !== null && rankedAttribute.grade !== undefined
          ? rankedAttribute.grade
          : undefined,
      }
    }) || []
  }, [ props.userAttributesValues, sortedAttributesGrades ])

  const maxPage = recipientsCommentsNumber ? Math.ceil(recipientsCommentsNumber / commentsPagination.limit) : 0

  return (
    <div ref={questionRef}>
      <Styles.Container
        isLoading={!areResultsInitiallyFetched || (!isWarmUpRequest.current && retryingFetchingResults)}
        title={questionInformation?.reference.name}
        sectionNumber={props.questionNumber}
        hasError={resultsFetchingError}
        progress={(questionStatistics
          && questionStatistics.treatedCommentsPercentage !== undefined
          && recipientsComments
          && recipientsComments.length > 0 && {
          label: t`Addressed comments`,
          value: questionStatistics.treatedCommentsPercentage,
        }) || undefined}
        onRetry={refetchQuestionResponses}
      >
        {(!resultsFetchingError && questionStatistics && questionInformation) && (
          <>
            <Styles.Tab
              label={t`General`}
              aria-label={t`General tab`}
              isActive={activeTab === ResultTab.Results}
              onClick={() => setActiveTab(ResultTab.Results)}
              badgeProps={{
                color: "blackLight",
                icon: "question_mark",
                tooltip: t`The choices' sum may not be equal to 100 as rounding was applied.`,
              }}
            />
            {isAllowedToReadResponseComments && recipientsComments && (
              <Styles.Tab
                label={t`Comments (${recipientsCommentsNumber})`}
                aria-label={t`Comments tab`}
                isActive={activeTab === ResultTab.Comments}
                isDisabled={recipientsComments && recipientsComments.length === 0}
                onClick={() => setActiveTab(ResultTab.Comments)}
                badgeProps={
                  questionStatistics.negativeResponseCommentsCount
                    ? {
                      color: "danger",
                      icon: "priority_high",
                      tooltip: t`Comment(s) have a negative response assigned`,
                    }
                    : undefined
                }
              />
            )}
            <Styles.Tab
              label={t`Ranking`}
              aria-label={t`Ranking tab`}
              isActive={activeTab === ResultTab.Ranking}
              isDisabled={!questionStatistics.attributeGrades || questionStatistics.attributeGrades.length === 0}
              onClick={() => setActiveTab(ResultTab.Ranking)}
            />
            {isAllowedToReadQuestionFeedback && (
              <Styles.Tab
                label={t`Write a global feedback`}
                aria-label={t`Feedback tab`}
                isActive={activeTab === ResultTab.GlobalFeedback}
                onClick={() => setActiveTab(ResultTab.GlobalFeedback)}
                badgeProps={questionInformation.comments?.[0]?.validatedAt
                  ? { icon: "check", tooltip: t`Feedback was made` }
                  : undefined
                }
              />
            )}
            {isAllowedToReadLocalQuestionFeedback && (mainHierarchyHeadAttributes?.length || 0) > 0 && (
              <Styles.Tab
                label={t`Write a local feedback`}
                aria-label={t`Local feedback tab`}
                isActive={activeTab === ResultTab.LocalFeedback}
                onClick={() => setActiveTab(ResultTab.LocalFeedback)}
              />
            )}
            <Styles.TabContent>
              {activeTab === ResultTab.Results && questionStatistics.choicesStatistics && (
                <Styles.GeneralResults
                  statistics={questionStatistics}
                  choicesWithStatistics={questionStatistics.choicesStatistics}
                  $isHidden={activeTab !== ResultTab.Results}
                />
              )}
              {isAllowedToReadResponseComments && activeTab === ResultTab.Comments && (
                <CommentsTable
                  type={CommentObjectType.QuestionScheduledSurvey}
                  comments={recipientsComments ?? []}
                  isResponseDisplayed
                  className="Tab__not-general-results"
                  onSaved={refetchQuestionResponses}
                  areCommentsLoading={areResultsLoading}
                />
              )}
              {activeTab === ResultTab.Ranking && sortedAttributesGrades?.length > 0 && (
                <ScheduledSurveyQuestionRanking
                  hierarchyTitle={sortedAttributesGrades[0].attribute.name}
                  rankingValues={rankingValues}
                  className="Tab__not-general-results"
                />
              )}
              {isAllowedToReadQuestionFeedback && activeTab === ResultTab.GlobalFeedback && (
                <MultiLanguageFeedback
                  parentId={questionInformation.scheduledId}
                  comment={questionInformation.comments?.[0]}
                  commentObjectType={CommentObjectType.QuestionScheduledSurvey}
                  className="Tab__not-general-results"
                  areResultsAlreadyPublished={props.areResultsPublished}
                  publishingDate={props.publishingDate}
                  onSave={refetchQuestionResponses}
                  isAllowedToWrite={isAllowedToWriteFeedback}
                  isAllowedToPublish={isAllowedToPublishFeedback}
                  add={addFeedback}
                  update={updateFeedback}
                />
              )}
              {isAllowedToReadLocalQuestionFeedback && activeTab === ResultTab.LocalFeedback && (
                <AttributableFeedback
                  type={CommentObjectType.QuestionScheduledSurvey}
                  attributeValues={mainHierarchyHeadAttributes}
                  objectId={questionInformation.scheduledId}
                  comments={questionInformation.scopedComments}
                  className="Tab__not-general-results"
                  areResultsAlreadyPublished={props.areResultsPublished}
                  publishingDate={props.publishingDate}
                  onSaved={refetchQuestionResponses}
                  onDeleted={refetchQuestionResponses}
                  isAllowedToPublish={isAllowedToPublishLocalFeedback}
                  isAllowedToWrite={isAllowedToWriteLocalFeedback}
                />
              )}
            </Styles.TabContent>
            {activeTab === ResultTab.Comments &&
              <Styles.CommentsPagination className="Tab__not-general-results">
                <Select
                  options={commentsOptions()}
                  value={commentsPagination.limit}
                  onChange={updateCommentsLimit}
                  defaultItem={false}
                />
                <PagedNavigation
                  isLoadingData={areResultsLoading}
                  page={commentsPagination.currentPage}
                  numberOfPages={maxPage}
                  onNavigate={(page) => updateCommentsPage(page)}
                />
              </Styles.CommentsPagination>
            }
          </>
        )}
      </Styles.Container>
    </div>
  )

  async function updateFeedback(id: number, isDraft: boolean, translations: CommentTranslation[]) {
    const query = await updateComment({
      variables: {
        commentId: id,
        isDraft,
        translations: translations,
      },
    })

    return query.data?.comment
  }

  async function addFeedback(id: number, isDraft: boolean, translations: CommentTranslation[]) {
    const query = await addComment({
      variables: {
        isDraft,
        objectId: id,
        translations: translations,
      },
    })

    return query.data?.comment
  }

  /**
   * Get the options for the pagination select
   */
  function commentsOptions(): SelectOptionProps[] {
    const options: SelectOptionProps[] = []
    business.results.commentsPageSizes.forEach((size) => {
      options.push({
        value: size,
        wording: size.toString(),
      })
    })
    return options
  }

  /**
   * Set the new selected page and update the offset
   * @param page Page number selected
   */
  function updateCommentsPage(page: number) {
    if (!(page === 0 || page === maxPage + 1)) {
      setCommentsPagination((prevPagination) => {
        return {
          ...prevPagination,
          currentPage: page,
          loadingOffset: prevPagination.limit * (page - 1),
        }
      })
    }
  }

  /** Set the comments paging limit and reset the current page and offset
   *  @param newPaging limit value
   */
  function updateCommentsLimit(newPaging: SelectOptionProps | undefined) {
    if (newPaging) {
      setCommentsPagination((prevPagination) => {
        return {
          ...prevPagination,
          currentPage: 1,
          limit: newPaging.value as number,
          loadingOffset: 0,
        }
      })
    }
  }
}

interface ScheduledSurveyQuestionResultsProps {
  /** ID of the scheduled survey containing the question */
  scheduledSurveyId: number,

  /** ID of the question to display */
  referenceQuestionId: number,

  /** Number of the question in the survey (0 if killer question) */
  questionNumber: number,

  /** If true, disable the edit feature */
  areResultsPublished?: boolean,

  /** The publishing date of the consulted survey */
  publishingDate?: string,

  /** Attribute values used for reference */
  userAttributesValues: QuestionResultsUserAttributeValue[],

  userGroupsUuids: string[],

  /** ID of the comment to focus in the view  */
  focusCommentId?: number,

  /** Whether to load the question only when it's visible on screen */
  isLazyLoaded?: boolean,

  questionRefetchMap?: Map<number, boolean>,
  onQuestionResponsesRefetched: () => void,
}

export type QuestionResultsUserAttributeValue = USER_ATTRIBUTE_VALUE & {
  isSelected?: boolean, isMainHierarchyHead?: boolean,

}
