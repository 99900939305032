import React from "react"
import { t } from "ttag"
import { Dialog } from "@material-ui/core"

import * as ConfirmationActionsStyles from "./ConfirmationActions.styles"

import { SecondaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const DeleteScopeConfirmationDialog: React.FC<DeleteScopeConfirmationDialogProps> = (props) => (
  <Dialog open={!!props.isOpen}>
    <Title level="metricCard">{t`Delete the scope?`}</Title>
    <p>
      {t`Are you sure you want to delete the scope of this admin?`}
    </p>

    <ConfirmationActionsStyles.Container justify={"center"}>
      <SecondaryButton onClick={() => !props.isLoading && props.onCancelButtonClick?.()}>{t`Cancel`}</SecondaryButton>
      <PrimaryButton
        onClick={() => props.onConfirmButtonClick?.()}
        isLoading={props.isLoading}
        destructive
      >
        {t`Delete`}
      </PrimaryButton>
    </ConfirmationActionsStyles.Container>

  </Dialog>
)

export interface DeleteScopeConfirmationDialogProps {
  onConfirmButtonClick?: () => void,
  onCancelButtonClick?: () => void,
  isOpen?: boolean,
  isLoading?: boolean,
}
