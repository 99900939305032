import React from "react"
import moment from "moment"
import { CenteredLoader, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { DialogContent } from "@material-ui/core"
import { t } from "ttag"

import * as Styles from "features/PatchNotes/PatchNotesList/PatchNotesList.styles"

import { GetPatchnoteListQuery } from "graphql/queries/generated/GetPatchnoteList"

export const PatchNotesList: React.FC<PatchNotesListProps> = (props) => (
  <Styles.Dialog
    open={props.isOpen}
    classes={{ paper: "Dialog" }}
  >
    <Styles.Title level="metricCard">{t`Update notes`}</Styles.Title>
    {props.isLoading && <CenteredLoader isTransparent/>}
    <DialogContent>
      {props.patchNotes?.map((patchNote) => (
        <Styles.PatchNote key={patchNote.id}>
          <Styles.PatchNoteTitle level="metricCard">
            {moment(patchNote.publishedAt).format("L")} - {patchNote.title}
          </Styles.PatchNoteTitle>

          <Styles.PatchNoteContent>
            {patchNote.content}
          </Styles.PatchNoteContent>
        </Styles.PatchNote>
      ))}
    </DialogContent>
    <Styles.DialogAction>
      <PrimaryButton onClick={props.onClose}>{t`Close`}</PrimaryButton>
    </Styles.DialogAction>
  </Styles.Dialog>
)

export interface PatchNotesListProps {
  isOpen: boolean,
  isLoading: boolean,
  onClose?: () => void,
  patchNotes: GetPatchnoteListQuery["getPatchnoteList"] | undefined,
}

