import moment from "moment"
import * as React from "react"
import "moment/locale/fr"
import { t } from "ttag"
import { ClickAwayListener } from "@material-ui/core"
import "react-day-picker/lib/style.css"
import { BaseProps, CalendarView, PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

import * as Styles from "./LedDatePicker.styles"
import { DATE_TIME_HELPER } from "features/Surveys/SurveyScheduling/ScheduledSurveyDateRangePicker/DateTimeHelper"

import { JsDateRange } from "models/DateTime/JsDateRange"
import { MomentRange } from "models/DateTime/MomentRange"
import { RangeValidity as DateTimeRangeValidity } from "models/DateTime/RangeValidity"
import { TimeRange } from "models/DateTime/TimeRange"

export const LedDatePicker: React.FC<DateTimeRangePickerProps> = (props) => {
  const [ isPickerVisible, setPickerVisible ] = React.useState(false)
  const [ selectedDateRange, setSelectedDateRange ] = React.useState<JsDateRange>({
    from: undefined,
    to: undefined,
  })

  const [ selectedTimeRange ] = React.useState<TimeRange>({
    from: { hours: 0, minutes: 0 },
    to: { hours: 23, minutes: 59 },
  })

  const isSelectedDateRangeEmpty = (!selectedDateRange.from && !selectedDateRange.to) || props.isDisabled

  const memoizedDismissPicker
    = React.useCallback(dismissPicker, [ setPickerVisible, setSelectedDateRange, props.value ])

  React.useEffect(() => {
    setSelectedDateRange(DATE_TIME_HELPER.jsDateRangeFromMomentRange(props.value))
  }, [ props.value ])

  return (
    <Styles.Container className={props.className}>
      <Styles.Dropdown
        placeholder={props.placeholder}
        value={humanFormattedDateTimeRange({ range: props.value })}
        label={props.label}
        isActive={isPickerVisible}
        onClick={showDatePicker}
        isDisabled={props.isDisabled}
        isFullWidth={true}
        data-cy="DateTimeRangePicker__dropdown-trigger"
      />
      <Styles.FiltersActions>
        <Styles.FiltersAction
          icon="delete_sweep"
          isInverted
          isDisabled={isSelectedDateRangeEmpty}
          size="big"
          onClick={props.onReset}
        />
      </Styles.FiltersActions>
      {isPickerVisible && (
        <ClickAwayListener onClickAway={memoizedDismissPicker}>
          <Styles.CalendarContainer
            onClick={capturePickerClick}
            $alignment={props.pickerAlignment}
            className={props.pickerClassName}
          >
            <CalendarView
              enableOutsideDaysClick={false}
              numberOfMonths={2}
              canChangeMonth
              toMonth={new Date()}
              onDayClick={handleDayClick}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              selectedDays={[ selectedDateRange.from, selectedDateRange ] as any}
              disabledDays={{ after: new Date() }}
              modifiers={{
                "selection-end": selectedDateRange.to,
                "selection-start": selectedDateRange.from,
              }}
            />
            <Styles.PickerActions>
              <PrimaryButton isInverted onClick={memoizedDismissPicker} height="small">{t`Cancel`}</PrimaryButton>
              <PrimaryButton
                onClick={handleUserValidate}
                disabled={!canUserValidate()}
                height="small"
                data-cy="DateTimeRangePicker__validate-button"
              >
                {t`Validate`}
              </PrimaryButton>
            </Styles.PickerActions>
          </Styles.CalendarContainer>
        </ClickAwayListener>
      )}
    </Styles.Container>
  )

  /** Update the selected date range */
  function handleDayClick(day: Date) {
    let tentativeDateRange: JsDateRange = Object.assign({}, selectedDateRange)

    if (!selectedDateRange.from || (selectedDateRange.from && selectedDateRange.to)) {
      tentativeDateRange = {
        from: day,
        to: undefined,
      }
    } else if (!selectedDateRange.to) {
      if (day.valueOf() > selectedDateRange.from.valueOf()) {
        tentativeDateRange.to = day
      }
      else {
        tentativeDateRange.to = selectedDateRange.from
        tentativeDateRange.from = day
      }
    }

    const tentativeMomentRange = DATE_TIME_HELPER.momentRange(tentativeDateRange, selectedTimeRange)
    const rangeValidity = participationRangeValidity(tentativeMomentRange)

    switch (rangeValidity) {
      case DateTimeRangeValidity.Valid:
        setSelectedDateRange(tentativeDateRange)
        break
      case DateTimeRangeValidity.ValidAtLaterHour:
        setSelectedDateRange(tentativeDateRange)
        break
    }
  }

  /** Fire the parent's onValidate callback with the selected date and time range */
  function handleUserValidate() {
    if (props.onValidate) {
      setPickerVisible(false)
      props.onValidate(selectedMomentRange())
    }
  }

  /** Whether the user selection is valid */
  function canUserValidate(): boolean {
    return !!(selectedDateRange && selectedDateRange.from && selectedDateRange.to)
  }

  /** Set the picker visible */
  function showDatePicker() {
    if (!props.isDisabled) {
      setPickerVisible(true)
    }
  }

  /** Hide the picker and empty the selection */
  function dismissPicker() {
    setPickerVisible(false)
    setSelectedDateRange(DATE_TIME_HELPER.jsDateRangeFromMomentRange(props.value))
  }

  function capturePickerClick(event: React.MouseEvent<HTMLDivElement>) {
    if (isPickerVisible) {
      event.stopPropagation()
    }
  }

  /** Merge the selected date and time range */
  function selectedMomentRange(): MomentRange {
    return DATE_TIME_HELPER.momentRange(selectedDateRange, selectedTimeRange)
  }

  /** Get a human-readable string to display the current date and time selection */
  function humanFormattedDateTimeRange(props: {range?: MomentRange}) {
    if (!props.range) {
      return null
    }

    if (props.range.from && props.range.to && props.range.from.isSame(props.range.to, "day")) {
      return <span>{props.range.from.format("L")}</span>
    }

    return (
      <>
        {props.range.from && <span>{props.range.from.format("L")}</span>}
        {props.range.to && <span>&nbsp;- {props.range.to.format("L")}</span>}
      </>
    )
  }

  /**
   * Check a ScheduledSurvey participation range validity
   * @param range range to check
   */
  function participationRangeValidity(range: MomentRange): DateTimeRangeValidity {
    if (!range.from && !range.to) {
      return DateTimeRangeValidity.Empty
    }
    else if (range.from) {
      if (range.from.isAfter(moment(), "day")) {
        return DateTimeRangeValidity.StartInFuture
      }

      if (range.to) {
        if (range.to.isAfter(moment(), "day")) {
          return DateTimeRangeValidity.StartInFuture
        }
        
        if (range.from.isSame(range.to, "day")) {
          return DateTimeRangeValidity.Valid
        }

        if (range.from.isAfter(range.to)) {
          return DateTimeRangeValidity.StartAfterEnd
        }
      }
    }
    else {
      return DateTimeRangeValidity.EndWithoutStart
    }

    return DateTimeRangeValidity.Valid
  }
}

export interface DateTimeRangePickerProps extends BaseProps {
  /** Label displayed alongside the control */
  label?: string,

  /** Days that should be marked in the calendar to denote an active survey */
  /** Fired when the user clicks the Validate button in the picker */
  onValidate?: (selectedRange: MomentRange) => void,

  /** Default selected range */
  value?: MomentRange,

  /** Placeholder text to display when there is no value */
  placeholder?: string,

  /** Alignment of the picker in regards to the input field */
  pickerAlignment?: "left" | "center" | "right",

  /** Take the whole available width */
  isFullWidth?: boolean,

  /** Additional classname applied to the root element */
  className?: string,

  /** Additional classname applied to the popup picker element */
  pickerClassName?: string,

  /** Disable the component */
  isDisabled?: boolean,

  onReset?: () => void,
}

LedDatePicker.defaultProps = {
  pickerAlignment: "left",
}
