import React from "react"
import { t } from "ttag"
import { Prompt } from "react-router"

/** A message shown by the browser on router change */
export const UncommittedChangesPrompt: React.FC<UncommittedChangesPromptProps> = props => (
  <Prompt
    message={props.message!}
    when={props.isActive}
  />
)

UncommittedChangesPrompt.defaultProps = {
  message: t`The changes you made may not be saved. Leave the page anyway?`,
}

interface UncommittedChangesPromptProps {
  /** The message shown by the browser
   * @default The changes you made may not be saved. Leave the page anyway?
   * */
  message?: string,
  /** Whether the browser should trigger the prompt */
  isActive: boolean,
}
