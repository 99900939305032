import React from "react"
import { t } from "ttag"
import { useTheme } from "styled-components"
import { Dialog } from "@material-ui/core"

import { Icon, InformationMessage, PrimaryButton, Title } from "@humanpredictiveintelligence/myqvt-library"
import { Step } from "../AdminCreationProcessContainer"
import * as Styles from "./AdminChoiceSelectionDialog.styles"

export const AdminChoiceSelectionDialog: React.FC<AdminProcessSelectionDialogProps> = props => {
  const theme = useTheme()

  return (
    <Dialog open={props.isOpen}>
      <Title level="metricCard">
        {t`Add a new administrator`}
      </Title>
      <InformationMessage
        variant="standard"
        text={t`Depending on your subscription, adding new administrators may change your billing.`}
      />

      <Styles.Content>
        <Styles.ActionCard onClick={() => props.onStepSelection(Step.Email)}>
          <Icon name="add" size={36} color={theme.colors.primary}/>
          <Styles.ActionLabel>{t`Enter a new administrator`}</Styles.ActionLabel>
        </Styles.ActionCard>

        <Styles.ActionCard onClick={() => props.onStepSelection(Step.List)}>
          <Icon name="group" size={36} color={theme.colors.primary}/>
          <Styles.ActionLabel>{t`Select from the recipients list`}</Styles.ActionLabel>
        </Styles.ActionCard>
      </Styles.Content>

      <Styles.DialogAction>
        <PrimaryButton onClick={props.onClose}>{t`Close`}</PrimaryButton>
      </Styles.DialogAction>

    </Dialog>
  )
}

interface AdminProcessSelectionDialogProps {
  isOpen: boolean,
  onStepSelection: (step: Step.Email | Step.List) => void,
  onClose?: () => void,
}
