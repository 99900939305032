import styled, { css } from "styled-components/macro"
import { ScheduledSurveyResultSection } from "features/Surveys/ScheduledSurveyResults/ScheduledSurveyResultSection"
import {
  Tab as LibraryTab,
  TabContent as LibraryTabContent,
} from "@humanpredictiveintelligence/myqvt-library"
import { ScheduledSurveyQuestionGeneralResults } from "features/Surveys/ScheduledSurveyResults/ScheduledSurveyQuestionGeneralResults"

export const Container = styled(ScheduledSurveyResultSection)`
  ${() => css`
    @media print {
      ${Tab}, .Tab__not-general-results {
        display: none;
      }
      
      ${TabContent} {
        min-height: 0;
        padding: 24px;
        box-shadow: none;
      }
    }
  `}
`

export const Tab = styled(LibraryTab)``
export const TabContent = styled(LibraryTabContent)`
  min-height: 450px;
  display: flex;
  align-items: center;
`

export const GeneralResults = styled(ScheduledSurveyQuestionGeneralResults)<{ $isHidden?: boolean }>`
  ${({ $isHidden }) => $isHidden && css`
    display: none;
  `}
`

export const CommentsPagination = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
`

