import * as React from "react"
import { t } from "ttag"

import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { ErroredMetricsCard } from "@humanpredictiveintelligence/myqvt-library"

export const ErroredCommentsMetrics: React.FC<BaseProps> = (props) => {
  return (
    <div className={props.className}>
      <ErroredMetricsCard title={t`Comments`} hasReducedPadding>
        {t`An error has occurred.`}
      </ErroredMetricsCard>
    </div>
  )
}
