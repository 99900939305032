import styled, { css } from "styled-components/macro"
import { Dialog, DialogTitle as muiDialogTitle } from "@material-ui/core"

import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { Input } from "@humanpredictiveintelligence/myqvt-library"
import { Select } from "@humanpredictiveintelligence/myqvt-library"

export const DialogContainer = styled(Dialog)`
  &[data-tutorial="true"] {
    pointer-events: none;
  }
  &[data-tutorial="true"] > .DialogContainer__body {
    text-align: unset !important;
  }
  
  .Dialog__body {
    pointer-events: all;
    min-width: 475px;
  }
`

export const TitleContainer = styled(muiDialogTitle)`
  padding: 0;
  margin: 0;
  border: none;
`

export const DialogTitle = styled(Title)`
  margin: 0;
  margin-top: 16px;
  text-align: center;
`

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const SectionTitle = styled(Title)`
  margin-top: 16px;
  margin-bottom: 12px;
`

const FieldStyle = css`
  margin-bottom: 18px;
`

export const InputField = styled(Input)`
  ${FieldStyle};
`

export const SelectField = styled(Select)`
  ${FieldStyle};
`

export const TimeZoneField = styled.div`
  ${FieldStyle};
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(2, calc(50% - 4px));
`

export const Attributes = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(2, calc(50% - 12px));

  padding: 0 5px;
`

export const EditRecipientMessage = styled.p`
  white-space: pre-line;
  text-align: center;
  line-height: 1.2em;
`

