import styled from "styled-components/macro"

import { AdminsSection } from "features/Permissions/AdminsSection"
import { PermissionsSection } from "features/Permissions/PermissionsSection"

export const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 232px 1fr;
  box-shadow: ${({ theme }) => theme.shadows.main};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  overflow: hidden;
  padding: 40px;
  gap: 2rem;
`

export const AdminsSectionContainer = styled(AdminsSection)`
  height: 100%;
  background: ${({ theme }) => theme.colors.cardLight};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.main};
`

export const PermissionsSectionContainer = styled(PermissionsSection)`
  height: 100%;
  padding: 14px 26px;
  background: ${({ theme }) => theme.colors.white};
  overflow-y: auto;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.main};
`
