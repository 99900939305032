/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnyUserAttributeValueFragmentFragment = { name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> };

export const SkinnyUserAttributeValueFragmentFragmentDoc = gql`
    fragment SkinnyUserAttributeValueFragment on Attribute {
  valueId: id
  attributeId: name_id
  name
  value
  isMainHierarchyHead: is_hierarchy_head
  parent {
    attributeId: name_id
    valueId: id
  }
  children {
    attributeId: name_id
    valueId: id
  }
}
    `;