import styled from "styled-components/macro"

export const CardContainer = styled.div`
    width: 474px;
    padding: 20px 10px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    padding: 24px;
    animation: padding 0.25s;
  }
`
