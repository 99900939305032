import { IntervalEnum } from "models/generated"
import moment from "moment"
import { capitalizedString, weekOfMonth } from "utilities/helpers"

export function getPeriodWording(period: string, interval: string): string {
  const monthAndYear = capitalizedString(moment(period).format("MMMM YYYY"))

  if (interval === IntervalEnum.Monthly) {
    return monthAndYear
  }

  return `${monthAndYear} (semaine ${weekOfMonth(period)})`
}
