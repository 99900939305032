import styled from "styled-components/macro"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  `

export const CardHeader = styled.div`
`

export const Title = styled.h1`
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black};
`

export const AccountLogo = styled.img`
`

export const CardContent = styled.div`
    display: flex;
    padding: 10px 50px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
`

export const AppIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #EEF1F4;
  width: 70px;
  height: 70px;
  `

export const AppIcon = styled.img`
  width: 50px;
  object-fit: cover;
`

export const AppName = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #545F71;
`
