import { parsePhoneNumberFromString } from "libphonenumber-js"
import { t } from "ttag"
import * as yup from "yup"

import { SkinnyAttributedUser } from "models/SkinnyUser"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"

type OverriddenTestContext = yup.TestContext &
  { options: yup.ValidateOptions & { context?: { userAttributes?: USER_ATTRIBUTE[], canSendSMS?: boolean } } }

/**
 * Yup validation schema for recipient form
 *
 * Use `yup.lazy` because of ttag
 */
export const recipientFormSchema = yup.lazy(v => yup.object<SkinnyAttributedUser<USER_ATTRIBUTE>>().shape({
  attributes: yup.array<USER_ATTRIBUTE>().ensure().test(
    "attributes-mandatory-and-valid",
    t`All attributes are mandatory`,
    areAttributesValid,
  ),
  email: yup.string()
    .when(
      "$canSendSMS",
      (canSendSms: boolean, schema: yup.StringSchema) => canSendSms ? schema.nullable() : schema.required(),
    )
    .test("email-or-phone-mandatory", t`You must provide at least an email or a phone number`, isEmailOrPhoneProvided)
    .email(t`The email address you provided is invalid`),
  firstname: yup.string().required(),
  language: yup.string().required(),
  lastname: yup.string().required(),
  phone: yup.string()
    .nullable()
    .test("email-or-phone-mandatory", t`You must provide at least an email or a phone number`, isEmailOrPhoneProvided)
    .test(
      "is-phone-valid",
      t`The phone number provided is invalid, please use the international format`,
      isPhoneValid,
    ),
  timezone: yup.string().required(),
}))

/*
 * Check that the attribute selection is valid
 * @param this Yup's test context
 * @param value Attribute selection
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function areAttributesValid(this: OverriddenTestContext, value: any): boolean {
  if (this.options.context && this.options.context.userAttributes) {
    return USER_ATTRIBUTE_SERVICE.isAttributeSelectionValid(
      value,
      this.options.context.userAttributes,
    )
  }
  else {
    return true
  }
}

/**
 * Check that either the mail or the phone is provided
 */
function isEmailOrPhoneProvided(this: OverriddenTestContext): boolean {
  const typedParent = this.parent as SkinnyAttributedUser<USER_ATTRIBUTE>

  if (this.options.context && this.options.context.canSendSMS) {
    return (!!typedParent.email || !!typedParent.phone)
  }
  else {
    return true
  }
}

/**
 * Check that the phone number is correctly formatted
 */
function isPhoneValid(this: OverriddenTestContext): boolean {
  if (this.parent.phone) {
    const phoneNumber = parsePhoneNumberFromString(this.parent.phone)
    return phoneNumber ? phoneNumber.isValid() : false
  }
  // Return true if no phone number was passed
  return true
}
