import { ServerParseError, useApolloClient } from "@apollo/client"
import { ApolloError } from "@apollo/client/errors"
import * as React from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useRollbar } from "@rollbar/react"

import { useSession } from "features/Session"
import { SplashScreen } from "features/SplashScreen"
import { STATIC_SESSION } from "utilities/StaticSession"
import { UserPermissionCode } from "models/generated"

import { SessionDocument, SessionQuery } from "graphql/queries/generated/Session"
import { SessionUsersDocument, SessionUsersQuery } from "graphql/queries/generated/SessionUsers"
import { LoginDocument, LoginMutation, LoginMutationVariables } from "graphql/mutations/generated/Login"
import { CheckInDocument, CheckInMutation } from "graphql/mutations/generated/CheckIn"

interface AuthenticationApi {
  authenticate: (email: string, password: string, customerCode?: string) => Promise<boolean>,
  logout: () => void,
  getUserSession: () => Promise<void>,
}

const defaultAuthenticationApi: AuthenticationApi = {
  authenticate: () => new Promise<boolean>(resolve => resolve(false)),
  getUserSession: () => new Promise<void>(resolve => resolve()),
  logout: () => { /* empty */ },
}

const authenticationContext = React.createContext<AuthenticationApi>(defaultAuthenticationApi)

// Create a wrapper for the provider so we can manage context state
const AuthenticationProvider = (props: { children: React.ReactChild }) => {
  const session = useSession()
  const apolloClient = useApolloClient()
  const location = useLocation()
  const history = useHistory()
  const rollbar = useRollbar()

  const searchParams = new URLSearchParams(location.search)
  const tokenFromUrl = searchParams.get("t") || undefined
  const [ isTokenRecoveryDone, setTokenRecoveryDone ] = React.useState(false)
  const memoizedTryRecoveryingAuthenticationFromToken = React.useCallback(
    tryRecoveringAuthenticationFromToken,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ tokenFromUrl ],
  )

  React.useEffect(() => {
    memoizedTryRecoveryingAuthenticationFromToken()
  }, [ memoizedTryRecoveryingAuthenticationFromToken ])

  if (tokenFromUrl) {
    STATIC_SESSION.authenticationToken = tokenFromUrl
    searchParams.delete("t")
    history.replace(`${location.pathname}?${searchParams.toString()}`)

    return null
  }
  else {
    return (
      <authenticationContext.Provider
        value={{ authenticate, getUserSession, logout }}
      >
        {isTokenRecoveryDone ? props.children : <SplashScreen/>}
      </authenticationContext.Provider>
    )
  }

  /**
   * Try recovering the authentication state if there is a token available.
   * This will update the context state with authentication state if recovered.
   */
  function tryRecoveringAuthenticationFromToken() {
    if (!isTokenRecoveryDone) {
      new Promise<void>(async (resolve) => {
        if (!STATIC_SESSION.authenticationToken) {
          resolve()
          return
        }
        try {
          await getUserSession()

          session.dispatch(session.actions.setAuthentication({
            hasFailed: false,
            isAuthenticated: true,
            lastFailedAttempt: undefined,
          }))
        }
        catch (error) {
          if (error instanceof ApolloError && error.networkError && error.networkError.name === "ServerParseError") {
            const serverError = error.networkError as ServerParseError

            if (serverError.statusCode === 401) {
              logout()
            }
          }
        }
        finally {
          resolve()
        }
      }).then(() => { setTokenRecoveryDone(true) })
    }
  }

  // These methods update the context state
  // They are included in the context as an API
  // ---
  async function authenticate(email: string, password: string, customerCode?: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const loginResponse = await apolloClient.mutate<LoginMutation, LoginMutationVariables>({
          context: {
            shouldUseAuthSchema: true,
          },
          mutation: LoginDocument,
          variables: {
            customerCode,
            email,
            password,
          },
        })

        if (loginResponse.data?.authenticate.token === null) {
          session.dispatch(session.actions.setAuthentication({
            hasFailed: true,
            isAuthenticated: false,
            lastFailedAttempt: (new Date().toString()),
          }))

          reject()
        }
        else if (loginResponse.data) {
          STATIC_SESSION.authenticationToken = loginResponse.data.authenticate.token

          await getUserSession()

          session.dispatch(session.actions.setAuthentication({
            hasFailed: false,
            isAuthenticated: true,
            lastFailedAttempt: undefined,
          }))

          await apolloClient.mutate<CheckInMutation>({ mutation: CheckInDocument })

          resolve(true)
        }
      } catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors) {
          session.dispatch(session.actions.setAuthentication({
            hasFailed: true,
            isAuthenticated: false,
            lastFailedAttempt: (new Date().toString()),
            reason: error.graphQLErrors?.[0]?.message,
          }))
        }
        reject()
      }
    })
  }

  async function getUserSession() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const sessionQuery = await apolloClient.query<SessionQuery>({
          context: {
            shouldNotShowErrorIfUnauthorized: true,
          },
          query: SessionDocument,
        })

        if (sessionQuery.data) {
          session.dispatch(session.actions.setUser(sessionQuery.data.me))
          session.dispatch(session.actions.setCustomer(sessionQuery.data.me.customer))
          session.dispatch(session.actions.setLanguages(sessionQuery.data.languages))

          rollbar.configure({
            payload: {
              person: {
                id: sessionQuery.data.me.id,
              },
            },
          })

          const recipientReadPermission = sessionQuery.data.me.permissions.find(
            ({ permission }) => permission.code === UserPermissionCode.RecipientsRead,
          )

          if (recipientReadPermission?.isGranted) {
            const sessionUsersQuery = await apolloClient.query<SessionUsersQuery>({
              context: {
                shouldNotShowErrorIfUnauthorized: true,
              },
              query: SessionUsersDocument,
            })
            session.dispatch(session.actions.setUsers(sessionUsersQuery.data.me.users))
          }

          resolve()
        }
      }
      catch {
        logout()
        reject()
      }
    })
  }

  function logout() {
    // Flush the token from the session to avoid auto-login on next page refresh/navigation
    STATIC_SESSION.authenticationToken = undefined

    session.dispatch(session.actions.unauthenticate())
    history.push("/login")

    rollbar.configure({
      payload: {
        person: undefined,
      },
    })
  }
}

export {
  authenticationContext as AuthenticationContext,
  AuthenticationProvider,
}
