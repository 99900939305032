import { unitOfTime } from "moment"
import moment from "moment-timezone"
import * as React from "react"

import { ScheduledTimeReminder, ScheduledTimeReminderUnit } from "features/ScheduledReminder/models"
import ScheduledReminderScheduler, { ScheduledReminderSchedulerProps } from "features/ScheduledReminder/ScheduledReminderScheduler/ScheduledReminderScheduler"
import { Scheduled_Survey_Status as ScheduledSurveyStatus } from "models/generated"

import business from "config/business"

const SurveyScheduledReminderScheduler: React.FC<SurveyScheduledReminderSchedulerProps> = (props) => {
  const currentTimeInEarliestTimezone = props.currentTimeInEarliestTimezone

  const isReminderDisabled = (reminder: ScheduledTimeReminder) => {
    let reminderUnit: unitOfTime.Base
    switch (reminder.unit) {
      case ScheduledTimeReminderUnit.Hours:
        reminderUnit = "hours"
        break
      default:
        reminderUnit = "hours"
    }

    // We use the earliest execution date of the scheduled survey to determine if the reminder value is allowed
    const scheduledSurveyBeginAt = moment(props.surveyScheduling.begin_at.earliest)
    const scheduledSurveyEndAt = moment(props.surveyScheduling.end_at.earliest)

    // Check if the reminder:
    //   - is not in the past (i.e. is greater than today)
    //   - is not after the survey time limit
    //   - can fit in the survey (ex: if the survey last 24 hours, presets of 24h+ are disabled)
    const remaining = moment.duration(scheduledSurveyEndAt.diff(currentTimeInEarliestTimezone))
    const timeBeforeExecution = reminder.value + remaining.as(reminderUnit)
    const surveyDuration = moment.duration(scheduledSurveyEndAt.diff(scheduledSurveyBeginAt)).as(reminderUnit)

    const isPastReminder = timeBeforeExecution < 0
    const hasMinimumInterval = timeBeforeExecution > business.scheduledReminders.timeLimitBeforeSurveyEnd.value
    const canFitInSurvey = reminder.value < surveyDuration - business.scheduledReminders.timeLimitBeforeSurveyEnd.value

    return (props.surveyScheduling.status.absolute === ScheduledSurveyStatus.Done)
      || isPastReminder
      || !hasMinimumInterval
      || !canFitInSurvey
  }

  return (
    <ScheduledReminderScheduler
      {...props}
      onDeleteAction={onDeleteAction}
      onEditAction={onEditAction}
      onChange={props.onChange}
      isReminderDisabled={isReminderDisabled}/>
  )

  /**
   * Triggered when the delete action button is clicked
   */
  function onDeleteAction() {
    props.onDeleteAction?.()
  }

  /**
   * Triggered when the edit action button is clicked
   * Make the scheduler as editable
   */
  function onEditAction() {
    props.onEditAction?.()
  }
}

interface SurveyScheduledReminderSchedulerProps extends ScheduledReminderSchedulerProps {
  /**
   * The current datetime in the earliest possible timezone
   */
  currentTimeInEarliestTimezone: moment.Moment,
}

export default SurveyScheduledReminderScheduler
