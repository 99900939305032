/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTutorialProgressionMutationVariables = Types.Exact<{
  tutorialName: Types.Scalars['String'];
  value: Types.Scalars['String'];
}>;


export type UpdateTutorialProgressionMutation = { update_tutorial_progression: string };


export const UpdateTutorialProgressionDocument = gql`
    mutation UpdateTutorialProgression($tutorialName: String!, $value: String!) {
  update_tutorial_progression(name: $tutorialName, value: $value)
}
    `;

/**
 * __useUpdateTutorialProgressionMutation__
 *
 * To run a mutation, you first call `useUpdateTutorialProgressionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTutorialProgressionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTutorialProgressionMutation, { data, loading, error }] = useUpdateTutorialProgressionMutation({
 *   variables: {
 *      tutorialName: // value for 'tutorialName'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateTutorialProgressionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTutorialProgressionMutation, UpdateTutorialProgressionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTutorialProgressionMutation, UpdateTutorialProgressionMutationVariables>(UpdateTutorialProgressionDocument, options);
      }
export type UpdateTutorialProgressionMutationHookResult = ReturnType<typeof useUpdateTutorialProgressionMutation>;
export type UpdateTutorialProgressionMutationResult = Apollo.MutationResult<UpdateTutorialProgressionMutation>;
export type UpdateTutorialProgressionMutationOptions = Apollo.BaseMutationOptions<UpdateTutorialProgressionMutation, UpdateTutorialProgressionMutationVariables>;