import { Tutorial } from "../../models/Tutorial"
import { recipientsTutorial } from "features/Tutorial/contents/RecipientsPage"
import { scheduledSurveysTutorial } from "features/Tutorial/contents/SurveysPage"

export const firstLoginTutorial = (): Tutorial => ({
  id: "first-login-tutorial",
  parts: [
    ...recipientsTutorial().parts,
    ...scheduledSurveysTutorial().parts,
  ],
})
