import styled, { css } from "styled-components/macro"
import { Popper } from "@material-ui/core"
import { CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div<{ $isListEmpty: boolean, $isCollapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow-y: auto;
  padding: 48px;
  
  ${({ $isListEmpty }) => $isListEmpty && css`
       display: flex;
       justify-content: center;
       align-items: center;
       text-align: center;
  `}
  
  ${({ $isCollapsed }) => $isCollapsed && css`
    padding: 0 5px;
    
    ${ListItem} {
      min-width: unset;
      margin-bottom: 16px;
      position: relative;
    }
          
    ${MonthSeparator} {
      margin-top: 0;
      font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};
      line-height: 12px;
      text-align: center;      
    }
  `}
`

export const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`

export const ListItem = styled.li`
  min-width: 256px;
  margin-bottom: 16px;
`

export const MonthSeparator = styled.h3`
  margin-top: 24px;
  margin-bottom: 8px;

  font-size: ${({ theme }) => theme.typography.fontSizeListSeparatorTitle};
  font-weight: ${({ theme }) => theme.typography.fontWeight};
  line-height: 24px;
`

export const ListItemFloater = styled(Popper)`
  z-index: 99;
`

export const Loader = styled(CenteredLoader)<{ $isVisible?: boolean }>`
  opacity: 0;
  height: auto;
  transition: opacity 200ms;
  
  ${({ $isVisible }) => $isVisible && css`
    opacity: 1;
  `}
`
