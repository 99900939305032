import styled, { css } from "styled-components"
import { Link } from "react-router-dom"

export const TabsNavigation = styled.nav`
  ${({ theme }) => css`
    height: ${theme.sizes.tabsNavigationHeight};
    display: flex;
    padding: 0 40px;
    gap: 2rem;
    position: relative;
    z-index: 99;
    box-shadow: ${theme.shadows.below};
    background: ${theme.colors.white};
    align-items: center;
  `}
`

export const TabLink = styled(Link)<{ $isCurrent?: boolean, $isDisabled?: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  padding: 1rem 0;
  
  color: inherit;
  font-size: ${({ theme }) => theme.typography.fontSizeText};
  text-decoration: none;
  border-bottom: 3px solid transparent;
  transition: all 0.2s ease-in-out;
  
  ${({ theme, $isCurrent }) => $isCurrent && css`
    color: ${theme.colors.primary};
    border-bottom: 3px solid ${theme.colors.primaryDark};
    
  `}
  
  ${({ $isCurrent, $isDisabled }) => !$isCurrent && !$isDisabled && css`
    &:visited {
      color: inherit;
    }
  `}

  ${({ theme, $isDisabled }) => $isDisabled && css`
    pointer-events: none;
    color: ${theme.colors.blackDisabled}
  `}
`
export const Divider = styled.div`
  width: 1px;
  margin: 5px 0;
  height: 80%;
  background: ${({ theme }) => theme.colors.blackDisabled};
`

export const ComponentContainer = styled.div`
  height: calc(100% - ${({ theme }) => theme.sizes.tabsNavigationHeight});
`
