import styled from "styled-components/macro"
import { IconButton } from "@humanpredictiveintelligence/myqvt-library"
import { Select } from "@humanpredictiveintelligence/myqvt-library"
import { Table } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
`

export const HeaderActions = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  margin-bottom: 16px;
  
  & > * {
    margin-right: 16px;
  }
`

export const HeaderPrimaryActions = styled.div`
  margin-left: auto;
  margin-right: 0;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin: 0;
    }
  }
`

export const ExportAction = styled(IconButton)`
  .ExportAction__icon {
    transform: rotateZ(180deg);
  }
`

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin: 0;
    }
  }
`

export const EmptyListMessage = {
  Container: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Content: styled.p`
    font-size: ${({ theme }) => theme.typography.fontSizeText};
  `,
  Title: styled.p`
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: ${({ theme }) => theme.typography.fontSizeTitleSmall};
  `,
}

export const FilterSelect = styled(Select)`
  width: auto;
`

export const RecipientsTable = styled(Table)``

export const TablePagination = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 65px; // Size of the loading GIF
  margin: 24px 0;
`
