import React from "react"
import { t } from "ttag"

import { BaseProps, IconButton, SearchableSelectOption } from "@humanpredictiveintelligence/myqvt-library"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"
import * as Styles from "./UsersTableAttributesFilters.styles"

import useAttributes from "services/useAttributes"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import { motion } from "framer-motion"

export const UsersTableAttributesFilters: React.FC<UserTableAttributesFiltersProps> = (props) => {
  const [ isBottomSectionVisible, setBottomSectionVisible ] = React.useState(false)
  const {
    hierarchicalAttributes,
    simpleAttributes,
    selectAttributeValue,
    selectedAttributeValueIds,
    selectedAttributeValues,
    getAvailableAttributeValuesForAttribute,
  } = useAttributes(props.attributes)

  React.useEffect(() => {
    props.onFilter?.(selectedAttributeValueIds(selectedAttributeValues))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedAttributeValues ])

  return (
    <Styles.Container className={props.className}>
      <Styles.TopSectionFilters>
        {(hierarchicalAttributes.length > 0 ? hierarchicalAttributes : simpleAttributes).map(attribute => (
          <Styles.AttributeSelect
            key={attribute.id}
            options={
              attributeValuesAsSearchableSelectOptions(getAvailableAttributeValuesForAttribute(attribute))
            }
            defaultValues={
              (
                selectedAttributeValues.has(attribute.id)
                && selectedAttributeValues.get(attribute.id)!.map(value => value.id.toString())
              )
              || undefined
            }
            label={attribute.name}
            noOptionText={t`No filter`}
            selectPlaceholder={t`All`}
            onChange={
              selection => selectAttributeValue(
                attribute,
                selection.map(searchableSelectOption => parseInt(searchableSelectOption.value, 10)),
              )
            }
            isMultiselect
          />
        ))}
        <Styles.ShowAllFilters
          animate={isBottomSectionVisible ? "open" : "closed"}
          initial="closed"
          transition={{ stiffness: 100, type: "spring" }}
          variants={{
            closed: { rotateZ: 180 },
            open: { rotateZ: 0 },
          }}
        >
          <IconButton
            icon={isBottomSectionVisible ? "remove" : "add"}
            onClick={() => setBottomSectionVisible(!isBottomSectionVisible)}
            size="standard"
          />
        </Styles.ShowAllFilters>
      </Styles.TopSectionFilters>
      <motion.div layout>
        {hierarchicalAttributes.length > 0 && simpleAttributes.length > 0 && (
          <Styles.BottomSectionFilters $isHidden={!isBottomSectionVisible}>
            {simpleAttributes.map(attribute => (
              <Styles.AttributeSelect
                key={attribute.id}
                options={
                  attributeValuesAsSearchableSelectOptions(getAvailableAttributeValuesForAttribute(attribute))
                }
                defaultValues={
                  (
                    selectedAttributeValues.has(attribute.id)
                    && selectedAttributeValues.get(attribute.id)!.map(value => value.id.toString())
                  )
                  || undefined
                }
                label={attribute.name}
                noOptionText={t`No filter`}
                selectPlaceholder={t`All`}
                onChange={selection => selectAttributeValue(
                  attribute,
                  selection.map(searchableSelectOption => parseInt(searchableSelectOption.value, 10)),
                )}
                isMultiselect
              />
            ))}
          </Styles.BottomSectionFilters>
        )}
      </motion.div>

    </Styles.Container>
  )

  /**
   * Get an array of available options to offer for the specified attribute
   * @param attributesValues available attributes values whose options should be returned
   */
  function attributeValuesAsSearchableSelectOptions(
    attributesValues: USER_ATTRIBUTE_VALUE[],
  ): SearchableSelectOption[] {
    return attributesValues.map(value => ({
      label: value.label,
      value: value.id.toString(),
    }))
  }
}

interface UserTableAttributesFiltersProps extends BaseProps {
  attributes: USER_ATTRIBUTE[],

  /**
   * Triggered when the user applies the filters
   * The parameter is a map of attributes IDs to the selected values
   */
  onFilter?: (selectedAttributesValuesIds: number[]) => void,
}
