import * as React from "react"
import memoizeOne from "memoize-one"

import { TutorialPopup } from "features/Tutorial/TutorialPopup"
import { Tutorial, TutorialStep } from "features/Tutorial/models"
import { TutorialState } from "features/Tutorial/TutorialState"
import { Step, TooltipRenderProps } from "react-joyride"
import { RecipientsCreateStepData, RecipientsCreatedStepData } from "./contents/RecipientsPage"
import { ReferenceObject } from "popper.js"

/**
 * User defined type-guard for RecipientsCreateStepData
 * @param stepData
 */
function isRecipientsCreateStepData(stepData: unknown): stepData is RecipientsCreateStepData {
  return stepData !== undefined && (stepData as RecipientsCreateStepData).canContinue !== undefined
}

/**
 * User defined type-guard for RecipientsCreatedStepData
 * @param stepData
 */
function isRecipientsCreatedStepData(stepData: unknown): stepData is RecipientsCreatedStepData {
  return stepData !== undefined && (stepData as RecipientsCreatedStepData).recipients !== undefined
}

/**
 * Combine properties from the given tutorialState with the given tutorialStep properties to create step props
 * Choose currentTarget defined at runtime, over nextTarget defined by event,  ver default target defined
 * in steps definition.
 * @param tutorialState
 * @param popupProps
 * @param stepData
 */
const tutorialStateStepPropsMapper = (
  tutorialState: TutorialState,
  popupProps: PopupProps,
  stepData: unknown,
) => (step: TutorialStep): Step => {
  return {
    disableCloseOnEsc: true,
    disableNextButton: step.disableNextButton instanceof Function
      ? step.disableNextButton(stepData)
      : step.disableNextButton,
    disableOverlayClose: true,
    tooltipComponent: tooltipComponentFactory(step, tutorialState, popupProps, stepData),
    ...step,
    target: tutorialState.currentTarget ?? tutorialState.nextTarget ?? step.target,
  }
}

const tooltipComponentFactory = (
  stepProps: TutorialStep,
  tutorialState: TutorialState,
  popupProps: PopupProps,
  stepData: unknown,
) => (props: TooltipRenderProps) => {
  const Popup = stepProps.popupComponent ?? TutorialPopup

  const disableNext = stepProps.disableNextButton instanceof Function
    ? stepProps.disableNextButton(stepData)
    : !!stepProps.disableNextButton

  return <Popup
    {...props}
    hideSkipButton={stepProps.hideSkipButton}
    hideNextButton={stepProps.hideNextButton}
    disableNextButton={disableNext}
    popupDirection={stepProps.popupDirection}
    callToActionPosition={stepProps.callToActionPosition}
    skipProps={{
      ...props.skipProps,
      onClick: popupProps.onSkip,
    }}
  >{stepProps.content}</Popup>
}

/**
 * Find the  index of given step in an array of steps
 * @param tutorial
 * @param id
 */
const findStepIndex = memoizeOne((tutorial: Tutorial, id: TutorialStep["id"]) => {
  const index = getTutorialSteps(tutorial).findIndex((step => step.id === id))
  return index !== -1 ? index : 0
})

const getTutorialSteps = memoizeOne((tutorial: Tutorial): TutorialStep[] => {
  return tutorial.parts.flat()
})

const setCenteredMarginOnReference = (reference: Element | ReferenceObject, popper: Element) => {
  const popperWidth = popper.getBoundingClientRect().width
  const elementWidth = reference.getBoundingClientRect().width
  const tutorialElementsWidth = popperWidth + elementWidth
  const margin = window.innerWidth - tutorialElementsWidth
  const horizontalMargin = margin / 2
  if (isElement(reference)) {
    reference.style.marginLeft = horizontalMargin + "px"
    reference.style.marginRight = horizontalMargin + "px"
    window.dispatchEvent(new CustomEvent("scroll"))
  }
}

const unsetCenteredMarginOnReference = (reference: Element | ReferenceObject) => {
  if (isElement(reference)) {
    reference.style.removeProperty("margin-left")
    reference.style.removeProperty("margin-right")
  }
}

function isElement(ref: ReferenceObject | Element | HTMLElement): ref is HTMLElement {
  return (ref as HTMLElement).style !== undefined
}

interface PopupProps {
  onSkip: () => void,
}

export {
  setCenteredMarginOnReference,
  unsetCenteredMarginOnReference,
  isRecipientsCreatedStepData,
  isRecipientsCreateStepData,
  getTutorialSteps,
  findStepIndex,
  tooltipComponentFactory,
  tutorialStateStepPropsMapper,
}
