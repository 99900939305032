import React from "react"
import { jt, t } from "ttag"
import { Dialog } from "@material-ui/core"

import * as PermissionStyles from "features/Permissions/Permissions.styles"
import * as ConfirmationActionsStyles from "./ConfirmationActions.styles"

import { SecondaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

export const ApplyScopeConfirmationDialog: React.FC<ApplyScopeConfirmationDialogProps> = (props) => {
  const scopeIcon = <PermissionStyles.ScopeIcon key="scopeIcon"  />

  return (
    <Dialog open={!!props.isOpen}>
      <Title level="metricCard">{t`Apply the scope?`}</Title>
      <p>
        {jt`Applying a scope will block access to some permissions. If they were activated, 
        permissions with the icon ${scopeIcon} will be disabled after scope is applied.`}
      </p>
      <ConfirmationActionsStyles.Container justify={"center"}>
        <SecondaryButton onClick={() => !props.isLoading && props.onCancelButtonClick?.()}>{t`Cancel`}</SecondaryButton>
        <PrimaryButton
          onClick={() => props.onConfirmButtonClick?.()}
          isLoading={props.isLoading}
        >
          {t`Confirm`}
        </PrimaryButton>
      </ConfirmationActionsStyles.Container>

    </Dialog>
  )
}

export interface ApplyScopeConfirmationDialogProps {
  onConfirmButtonClick?: () => void,
  onCancelButtonClick?: () => void,
  isOpen?: boolean,
  isLoading?: boolean,
}
