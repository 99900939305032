import * as React from "react"

import { ApplicationHubRedirection } from "../ApplicationHubRedirection"
import * as Styles from "./ApplicationHubPage.styles"

import { useGetAvailableAppsQuery } from "graphql/queries/generated/GetAvailableApps"
import { CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"

export const ApplicationHubPage: React.FC = () => {
  const { data: getAvailableApps, loading: areAppsLoading } = useGetAvailableAppsQuery()

  return (
    <Styles.CardContainer>
      {areAppsLoading
        ?  <CenteredLoader isTransparent size="m" color="primary" />
        : <ApplicationHubRedirection apps={getAvailableApps?.getAvailableApps}/>
      }
    </Styles.CardContainer>
  )
}
