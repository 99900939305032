import * as React from "react"
import Linkifier from "react-linkifier"
import { t } from "ttag"

import { EllipsedText } from "@humanpredictiveintelligence/myqvt-library"
import { AnswerSatisfaction, getAnswerSatisfaction } from "features/Comments/utils"
import { BaseCommentsTableRow, BaseCommentsTableRowProps } from "../BaseCommentsTableRow"
import * as Styles from "./UncontextualizedCommentsTableRow.styles"

export const UncontextualizedCommentsTableRow: React.FC<UncontextualizedCommentsTableRowProps> = (props) => {
  const answerSatisfaction = getAnswerSatisfaction(props.comment.context?.response?.choice)

  let creatorTitle: string | undefined
  if (props.comment.creatorAttributeValue) {
    creatorTitle = ` – ${props.comment.creatorAttributeValue.value}`
  }

  return (
    <BaseCommentsTableRow {...props}>
      {(isExpanded) => {
        return (
          <Styles.Container>
            {answerSatisfaction === AnswerSatisfaction.Best || answerSatisfaction === AnswerSatisfaction.Worst
              ? <Styles.SatisfactionIcon size={16} choice={props.comment.context?.response?.choice}/>
              : <Styles.IconSpacer />
            }
            <div>
              <Styles.Author>
                {props.comment.creator?.displayName || t`Anonymous`}{creatorTitle}
              </Styles.Author>
              <Linkifier renderer={clickableUrl}>
                <EllipsedText isEllipsed={!isExpanded}>{props.comment.comment}</EllipsedText>
              </Linkifier>
            </div>
          </Styles.Container>
        )
      }}
    </BaseCommentsTableRow>
  )

  function clickableUrl(link: { href: string, children: React.ReactNode }) {
    return (
      <a href={link.href} target="_blank" rel="noopener noreferrer">
        {link.children}
      </a>
    )
  }
}

export type UncontextualizedCommentsTableRowProps = Omit<BaseCommentsTableRowProps, "children">
