import * as React from "react"

import { SatisfactionEvolutionMetrics } from "../SatisfactionEvolutionMetrics"
import businessConfig from "config/business"
import { SkinnyUserAttributeValue } from "models/SkinnyUserAttributeValue"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"

import {
  useKillerQuestionGradeEvolutionLazyQuery,
  useKillerQuestionGradeEvolutionQuery,
} from "graphql/queries/generated/KillerQuestionGradeEvolution"
import { useUserAttributesQuery } from "graphql/queries/generated/UserAttributeValues"

export const SatisfactionEvolutionMetricsContainer: React.FC<SatisfactionEvolutionMetricsContainerProps> = (props) => {
  const [ comparisonAttribute, setComparisonAttribute ] = React.useState<USER_ATTRIBUTE_VALUE | undefined>()

  const { data: userAttributesData, loading: isLoadingUserAttributes } = useUserAttributesQuery()
  const allUserAttributeValues = userAttributesData?.attributeValues

  const {
    data: killerQuestionGrades,
    loading: isLoadingKillerQuestionGrades }
    = useKillerQuestionGradeEvolutionQuery({
      fetchPolicy: "cache-and-network",
      variables: {
        duration: businessConfig.statistics.global.satisfactionEvolutionDuration,
        endAtTo: businessConfig.statistics.global.satisfactionEvolutionLatestDate,
        limit: businessConfig.statistics.global.maxSatisfactionEvolutionPoints,
      },
    })

  const  [
    fetchComparisonGrades,
    {
      data: killerQuestionComparisonGrades,
      error: killerQuestionComparisonGradesError,
      loading: isLoadingKillerQuestionComparisonGrades,
    },
  ] = useKillerQuestionGradeEvolutionLazyQuery({
    fetchPolicy: "cache-and-network",
  })

  const comparisonAttributeKillerQuestionGrades
    = (comparisonAttribute && killerQuestionComparisonGrades?.global.evolution) || []

  const userAttributeValues = React.useMemo(
    () => firstHeriarchyLevelAttributeValues(allUserAttributeValues),
    [ allUserAttributeValues ],
  )

  // Load comparison grades when the attribute value changes
  React.useEffect(() => {
    if (comparisonAttribute) {
      fetchComparisonGrades({
        variables: {
          attributeValueId: comparisonAttribute?.id,
          duration: businessConfig.statistics.global.satisfactionEvolutionDuration,
          endAtTo: businessConfig.statistics.global.satisfactionEvolutionLatestDate,
          limit: businessConfig.statistics.global.maxSatisfactionEvolutionPoints,
        },
      })
    }
  }, [ comparisonAttribute, fetchComparisonGrades ])

  // Unset the comparison grades when there is an error with the comparison grades query
  React.useEffect(() => {
    if (killerQuestionComparisonGradesError) {
      setComparisonAttribute(undefined)
    }
  }, [ killerQuestionComparisonGradesError ])

  return (
    <SatisfactionEvolutionMetrics
      isLoading={(isLoadingKillerQuestionGrades && !killerQuestionGrades)
      || (isLoadingUserAttributes && !userAttributesData)}
      className={props.className}
      comparisonAttributes={userAttributeValues}
      comparisonAttributeSatisfactionEvolution={comparisonAttributeKillerQuestionGrades}
      defaultComparisonAttribute={comparisonAttribute}
      globalSatisfactionEvolution={killerQuestionGrades?.global.evolution || []}
      isLoadingComparisonAttributes={isLoadingKillerQuestionComparisonGrades}
      onComparisonAttributeChange={setComparisonAttribute}
    />
  )

  /** Get a list of first level attribute values */
  function firstHeriarchyLevelAttributeValues(userAttributeValues: SkinnyUserAttributeValue[] | undefined) {
    return userAttributeValues?.reduce((firstLevelAttributeValues, userAttributeValue) => {
      if (userAttributeValue.isMainHierarchyHead) {
        firstLevelAttributeValues.push(new USER_ATTRIBUTE_VALUE({
          ...userAttributeValue,
          attributeLabel: userAttributeValue.name,
          id: userAttributeValue.valueId,
          label: userAttributeValue.value,
        }))
      }

      return firstLevelAttributeValues
    }, new Array<USER_ATTRIBUTE_VALUE>())
  }
}

export interface SatisfactionEvolutionMetricsContainerProps {
  /** Extra class names */
  className?: string,
}
