/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyScheduledSurveyFragmentFragmentDoc } from '../../../models/generated/SkinnyScheduledSurveyFragment';
import { SkinnySurveyFragmentFragmentDoc } from '../../../models/generated/SkinnySurveyFragment';
import { SkinnyThemeFragmentFragmentDoc } from '../../../models/generated/SkinnyThemeFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddScheduledSurveyMutationVariables = Types.Exact<{
  beginAt: Types.Scalars['DateTime'];
  endAt: Types.Scalars['DateTime'];
  surveyId: Types.Scalars['Int'];
  scheduledReminders: Array<Types.Scalars['Float']> | Types.Scalars['Float'];
  userGroupsUuids: Array<Types.Scalars['String']> | Types.Scalars['String'];
  isManualPublication: Types.Scalars['Boolean'];
}>;


export type AddScheduledSurveyMutation = { addScheduledSurvey: { id: number, isManualPublication: boolean, schedulingId: number, survey: { id: number, name: string, theme: { id: number, name: string, color: string, icon: string } }, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, scheduled_reminders: Array<{ remind_hours_before_end: number, is_editable: boolean }>, userGroups: Array<{ name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined }> } };


export const AddScheduledSurveyDocument = gql`
    mutation AddScheduledSurvey($beginAt: DateTime!, $endAt: DateTime!, $surveyId: Int!, $scheduledReminders: [Float!]!, $userGroupsUuids: [String!]!, $isManualPublication: Boolean!) {
  addScheduledSurvey(survey_id: $surveyId, begin_at: $beginAt, end_at: $endAt, scheduled_reminders: $scheduledReminders, userGroupUuids: $userGroupsUuids, isManualPublication: $isManualPublication) {
    ...SkinnyScheduledSurveyFragment
    survey {
      ...SkinnySurveyFragment
      theme {
        ...SkinnyThemeFragment
      }
    }
  }
}
    ${SkinnyScheduledSurveyFragmentFragmentDoc}
${SkinnySurveyFragmentFragmentDoc}
${SkinnyThemeFragmentFragmentDoc}`;

/**
 * __useAddScheduledSurveyMutation__
 *
 * To run a mutation, you first call `useAddScheduledSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddScheduledSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addScheduledSurveyMutation, { data, loading, error }] = useAddScheduledSurveyMutation({
 *   variables: {
 *      beginAt: // value for 'beginAt'
 *      endAt: // value for 'endAt'
 *      surveyId: // value for 'surveyId'
 *      scheduledReminders: // value for 'scheduledReminders'
 *      userGroupsUuids: // value for 'userGroupsUuids'
 *      isManualPublication: // value for 'isManualPublication'
 *   },
 * });
 */
export function useAddScheduledSurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddScheduledSurveyMutation, AddScheduledSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddScheduledSurveyMutation, AddScheduledSurveyMutationVariables>(AddScheduledSurveyDocument, options);
      }
export type AddScheduledSurveyMutationHookResult = ReturnType<typeof useAddScheduledSurveyMutation>;
export type AddScheduledSurveyMutationResult = Apollo.MutationResult<AddScheduledSurveyMutation>;
export type AddScheduledSurveyMutationOptions = Apollo.BaseMutationOptions<AddScheduledSurveyMutation, AddScheduledSurveyMutationVariables>;