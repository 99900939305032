import { Input, PrimaryButton, SecondaryButton } from "@humanpredictiveintelligence/myqvt-library"
import * as React from "react"
import { t } from "ttag"

import { UserGroup } from "features/UserGroups/groups.model"
import * as Styles from "../UserGroupDialog.styles"

export const EditGroupDialog: React.FC<EditGroupDialogProps> = (props) => {
  const [ userGroupName, setUserGroupName ] = React.useState<UserGroup["name"]>(props.defaultGroup.name)

  return (
    <Styles.Container
      classes={{ paper: "Container" }}
      open={props.isOpen}
    >
      <Styles.Header>{t`Rename the group`}</Styles.Header>
      <Input
        label={t`Name`}
        placeholder={t`Enter your Group name`}
        value={userGroupName}
        onChange={setUserGroupName}
        isDisabled={props.isLoading}
      />
      <Styles.Actions>
        <SecondaryButton
          onClick={props.onCancel}
        >
          {t`Cancel`}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => onValidate()}
          isLoading={props.isLoading}
          disabled={userGroupName.trim() === ""}
        >
          {t`Rename`}
        </PrimaryButton>
      </Styles.Actions>
    </Styles.Container>
  )

  function onValidate() {
    props.onEdit({
      ...props.defaultGroup,
      name: userGroupName.trim(),
    })
  }
}

export interface EditGroupDialogProps {
  isOpen: boolean,
  isLoading: boolean,
  onEdit: (group: UserGroup) => void,
  onCancel: () => void,
  defaultGroup: UserGroup,
}
