import * as React from "react"
import JoyRide, { ACTIONS, CallBackProps, EVENTS, FloaterProps, STATUS, StoreHelpers } from "react-joyride"
import { t } from "ttag"
import { RouteComponentProps, withRouter } from "react-router"
import { Data, ReferenceObject } from "popper.js"

import {
  setCenteredMarginOnReference,
  tutorialStateStepPropsMapper,
  unsetCenteredMarginOnReference,
} from "features/Tutorial/utils"
import { TutorialProgression } from "features/Tutorial/TutorialProgression"
import TutorialDialog from "features/Tutorial/TutorialDialog"
import TutorialRedirect from "features/Tutorial/TutorialRedirect"
import { TutorialContext, useTutorial } from "features/Tutorial/TutorialContext"
import useImperativeTutorialStepHandler from "features/Tutorial/useImperativeTutorialStepHandler"
import useTutorialController from "features/Tutorial/useTutorialController"

const Tutorial = withRouter((props: TutorialProps) => {
  const { dispatch } = React.useContext(TutorialContext)
  const { tutorialState, activeStep, getStepData } = useTutorial()
  const {
    changeStep,
    confirmSkipStep,
    getTutorialStepsCount,
    isTutorialFinished,
    stopTutorial,
    toggleQuitTutorialConfirmationDialog,
    toggleSkipStepConfirmationDialog,
  } = useTutorialController(dispatch)
  useImperativeTutorialStepHandler(dispatch)

  const openJoyride = React.useRef<() => void>()
  const targetRef = React.useRef<Element | ReferenceObject>()
  const popperRef = React.useRef<Element>()
  const tutorialPartNumber = activeStep ? activeStep.partIndex + 1 : 0

  // -- allow to disable the tooltip: start --
  const disabledTooltip = activeStep ? activeStep.disabledTooltip : false
  const joyRideStyles = disabledTooltip ? { beacon: { display: "none" } } : {}
  const joyRideFloaterProps = (
    (disabledTooltip ? { open: !disabledTooltip } as unknown : {})
  ) as FloaterProps
  // -- allow to disable the tooltip: end --

  const setPopper = (popper: Data, origin: "floater" | "wrapper") => {
    if (origin === "floater") {
      if (popper.instance.popper !== popperRef.current) {
        unsetPopper()
      }
      targetRef.current = popper.instance.reference
      popperRef.current = popper.instance.popper
    }
  }

  const unsetPopper = () => {
    if (targetRef.current !== undefined) {
      unsetCenteredMarginOnReference(targetRef.current)
    }
    targetRef.current = undefined
    popperRef.current = undefined
  }

  // That's the best fix I can think of right now... force the display of the overlay not displayed for some
  // steps on Chrome. This is due to the fact that the LIFECYCLE.TOOLTIP event is not triggered
  // in react-joyride for an unknown reason in Google Chrome while it is in Firefox
  if (activeStep && activeStep.forceRerender && openJoyride.current !== undefined) {
    openJoyride.current()
  }

  // Keep the element centered when target is changed dynamically for step with centerContent = 1
  React.useEffect(() => {
    if (activeStep && activeStep?.centerContent && tutorialState.stepTargets[activeStep?.id] !== targetRef.current) {
      const target = tutorialState.stepTargets[activeStep?.id]

      if (target) {
        const element = (typeof target === "string") ? document.querySelector(target) : target

        if (element !== null && popperRef.current !== undefined) {
          setCenteredMarginOnReference(element, popperRef.current)
        }
      }
    }
  }, [ tutorialState.stepTargets, activeStep ])

  React.useEffect(() => {
    if (activeStep?.centerContent) {
      const centerTargetAndTooltip = () => {
        if (targetRef.current !== undefined && popperRef.current !== undefined) {
          setCenteredMarginOnReference(targetRef.current, popperRef.current)
        }
      }
      window.addEventListener("resize", centerTargetAndTooltip)
      centerTargetAndTooltip()
      return () => {
        window.removeEventListener("resize", centerTargetAndTooltip)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ targetRef.current, popperRef.current, activeStep ])

  const displayTutorialProgression = (activeStep
    && tutorialState.run
    && (tutorialState.tutorial && !isTutorialFinished(tutorialState.tutorial, tutorialState.stepIndex)))

  /**
   * Dispatch tutorial actions depending on the current state returned by Joyride
   */
  const onTutorialStateChange = ({ action, index, type, status, step, lifecycle }: CallBackProps) => {
    switch (true) {
      case action === ACTIONS.CLOSE:
        toggleQuitTutorialConfirmationDialog()
        break
      case action === ACTIONS.SKIP:
        toggleSkipStepConfirmationDialog()
        break
      case (status === STATUS.FINISHED || action === ACTIONS.STOP) && status !== STATUS.PAUSED:
        // Triggered when the user click confirm in the quit tutorial dialog
        if (!tutorialState.tutorial) {
          throw new Error("No tutorial was started")
        }
        const maximumStepIndex = getTutorialStepsCount(tutorialState.tutorial) + 1
        changeStep(maximumStepIndex, tutorialState)
        break
      case type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND:
        // Triggered when the user clicks the next button or when the stepIndex is manually changed
        const increment = (action === ACTIONS.PREV ? -1 : 1)
        changeStep(index + increment, tutorialState)
        break
    }
  }

  return (
    <TutorialRedirect currentLocation={props.location.pathname} tutorialState={tutorialState}>
      <JoyRide
        floaterProps={joyRideFloaterProps}
        styles={joyRideStyles}
        {...tutorialState}
        run={tutorialState.runJoyRide}
        steps={tutorialState.steps.map(
          tutorialStateStepPropsMapper(
            tutorialState,
            {
              onSkip: toggleSkipStepConfirmationDialog,
            },
            activeStep ? getStepData(activeStep?.id) : undefined,
          ),
        )}
        setPopper={setPopper}
        disableScrolling
        getHelpers={(helpers: StoreHelpers) => {
          openJoyride.current = helpers.open
        }}
        callback={onTutorialStateChange}
        locale={{
          last: t`Last`,
          next: t`Continue`,
          skip: t`Skip this step`,
        }}
      />
      {displayTutorialProgression && <TutorialProgression
        stepsCount={tutorialState.partsCount}
        tutorialPartNumber={tutorialPartNumber}
        currentStepTitle={activeStep?.partTitle ?? ""}
        onTutorialQuit={toggleQuitTutorialConfirmationDialog}
      />}
      {tutorialState.tutorial && <TutorialDialog
        open={tutorialState.isClosingTutorial}
        onCancel={toggleQuitTutorialConfirmationDialog}
        onConfirm={stopTutorial}
        message={t`Are you sure you want to quit the tutorial?`}
      />}
      {tutorialState.tutorial && <TutorialDialog
        open={tutorialState.isSkippingStep}
        onCancel={toggleSkipStepConfirmationDialog}
        onConfirm={() => confirmSkipStep(tutorialState)}
        message={activeStep?.skipMessage ?? t`Are you sure you want to skip this step?`}
      />}
    </TutorialRedirect>
  )
})

type TutorialProps = React.PropsWithChildren<RouteComponentProps>

export default Tutorial
