import styled, { css } from "styled-components/macro"
import { IconButton } from "@material-ui/core"

export const Container = styled.li<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) => css`
    width: 100%;
    display: grid;
    align-items: center;
    vertical-align: center;
    z-index: 1;
    color: ${theme.colors.grey};
    font-size: ${theme.typography.fontSizeLabelBig};
    grid-template-columns: auto fit-content(50px);
    background: ${theme.colors.blackUltraLight};  
    padding: 0.6rem;
    border-radius: ${theme.sizes.borderRadius};
    list-style: none;
    transition: all .25s ease-in-out;
    
    ${$isSelected && "&,"} &:hover {
      box-sizing: border-box;
      color: ${theme.colors.white};
      background: ${theme.colors.primary};
      box-shadow: ${theme.shadows.buttonHover};
      
      ${MenuToggle} {
        display: flex;
      }
    }
  `}
`

export const GroupName = styled.span`
  ${({ theme }) => theme.mixins.webkitClampText(1)}
  word-break: break-all;
  vertical-align: center;
  align-items: center;
  
  &:first-child {
    margin: .6rem;
  }
`

export const MenuToggle = styled(IconButton)`
  background-color: transparent;
  display: none;
  z-index: 200;
  padding: 5px !important;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`
