import styled, { css } from "styled-components/macro"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { ScheduledSurveyResultsHeader } from "features/Surveys/ScheduledSurveyResults/ScheduledSurveyResultsHeader"
import { UserAttributesFilters } from "features/Surveys/ScheduledSurveyResults/UserAttributesFilters"
import { ScheduledSurveyCollaboratorsFeedback } from "features/Surveys/ScheduledSurveyResults/ScheduledSurveyCollaboratorsFeedBack"
import { Separator } from "@humanpredictiveintelligence/myqvt-library"
import { ScheduledSurveyFeedbackSection } from "features/Surveys/ScheduledSurveyResults/ScheduledSurveyFeedbackSection"
import { ScheduledSurveyResultsPublishingStatus } from "features/Surveys/ScheduledSurveyResults/ScheduledSurveyResultsPublishingStatus"

export const Container = styled.div<{ $isClusterViolated?: boolean }>`
  ${({ $isClusterViolated }) => css`
    height: 100%;
    padding: 40px;
    overflow-x: auto;

    ${$isClusterViolated && css`
      display: grid;
      grid-template-rows: auto 1fr;
      justify-items: center;
      align-items: center;

      ${Header} {
        justify-self: stretch;
      }

      ${Hint} {
        display: flex;
        align-items: flex-start;
      }

      ${HintIcon} {
        margin-right: 4px;
      }
    `} 
    @media print {
      ${SectionSeparator}, ${CollaboratorsFeedbackSection}, ${FeedbackSection}, ${PublishingStatus}, ${Footer} {
        display: none;
      }
      
      height: auto;
    }
  `}
`

export const Loader = styled.div`
  height: 100%;
  width: 100%;
`

export const Header = styled(ScheduledSurveyResultsHeader)<{ $withRoundedBorders?: boolean }>`
  ${({ $withRoundedBorders }) => !$withRoundedBorders && css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`

export const Hint = styled.p``
export const HintIcon = styled(Icon)``

export const AttributesFilters = styled(UserAttributesFilters)`
  border-top: 1px solid ${({ theme }) => theme.colors.blackDisabled};
`

export const Questions = styled.div`
  padding-top: 40px;

  @media print {
    padding-top: 0;

    & > * {
      margin: 5vw 0;
    }

    & > *:first-child {
      margin-top: 10vw;
    }

    & > *:nth-child(2n) {
      break-before: page;
      
      // Add space only in firefox
      @supports (-moz-appearance:none) {
        margin-top: 22vh;
      }
    }
  }
`

export const CollaboratorsFeedbackSection = styled(ScheduledSurveyCollaboratorsFeedback)``
export const SectionSeparator = styled(Separator)``
export const FeedbackSection = styled(ScheduledSurveyFeedbackSection)``
export const PublishingStatus = styled(ScheduledSurveyResultsPublishingStatus)``

export const Footer = styled.footer`
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: right;
`

