/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishScheduledSurveyResultsMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type PublishScheduledSurveyResultsMutation = { publish_results: boolean };


export const PublishScheduledSurveyResultsDocument = gql`
    mutation PublishScheduledSurveyResults($id: Int!) {
  publish_results(scheduledSurveyId: $id)
}
    `;

/**
 * __usePublishScheduledSurveyResultsMutation__
 *
 * To run a mutation, you first call `usePublishScheduledSurveyResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishScheduledSurveyResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishScheduledSurveyResultsMutation, { data, loading, error }] = usePublishScheduledSurveyResultsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishScheduledSurveyResultsMutation(baseOptions?: Apollo.MutationHookOptions<PublishScheduledSurveyResultsMutation, PublishScheduledSurveyResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishScheduledSurveyResultsMutation, PublishScheduledSurveyResultsMutationVariables>(PublishScheduledSurveyResultsDocument, options);
      }
export type PublishScheduledSurveyResultsMutationHookResult = ReturnType<typeof usePublishScheduledSurveyResultsMutation>;
export type PublishScheduledSurveyResultsMutationResult = Apollo.MutationResult<PublishScheduledSurveyResultsMutation>;
export type PublishScheduledSurveyResultsMutationOptions = Apollo.BaseMutationOptions<PublishScheduledSurveyResultsMutation, PublishScheduledSurveyResultsMutationVariables>;