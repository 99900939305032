import { ApolloError } from "@apollo/client/errors"
import * as React from "react"
import { t } from "ttag"

import { CommentEditor } from "../CommentEditor"
import { NotificationContext } from "features/Notification"
import { useSession } from "features/Session"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { Comment, CommentObject } from "models/Comment"
import { SkinnyUserAttributeValue } from "models/SkinnyUserAttributeValue"
import { COMMENT_SERVICE } from "services/CommentService"

import {
  useAddResponseFeedbackMutation,
} from "graphql/mutations/generated/AddResponseFeedback"

import {
  useAddCommentSurveyFeedbackMutation,
} from "graphql/mutations/generated/AddCommentSurveyFeedback"

import {
  useAddQuestionFeedbackMutation,
} from "graphql/mutations/generated/AddQuestionFeedback"

import {
  useAddSurveyFeedbackMutation,
} from "graphql/mutations/generated/AddSurveyFeedback"

import {
  useUpdateResponseFeedbackMutation,
} from "graphql/mutations/generated/UpdateResponseFeedback"

import {
  useUpdateCommentSurveyFeedbackMutation,
} from "graphql/mutations/generated/UpdateCommentSurveyFeedback"

import {
  useUpdateQuestionFeedbackMutation,
} from "graphql/mutations/generated/UpdateQuestionFeedback"

import {
  useUpdateSurveyFeedbackMutation,
} from "graphql/mutations/generated/UpdateSurveyFeedback"

import { CommentObjectType, Comment_Translation as CommentTranslation } from "models/generated"

export const CommentEditorContainer: React.FC<CommentEditorContainerProps> = (props) => {
  const notification = React.useContext(NotificationContext)
  const session = useSession()

  const isReadOnly = !props.isAllowedToWrite || !!props.comment?.validatedAt
  const sendOrPublishLabel = confirmActionWording()

  const [ isUpdating, setIsUpdating ] = React.useState(false)

  const  [ addResponseFeedback ] = useAddResponseFeedbackMutation()
  const [ addCommentSurveyFeedback ] = useAddCommentSurveyFeedbackMutation()
  const [ addQuestionFeedback ] = useAddQuestionFeedbackMutation()
  const [ addSurveyFeedback ] = useAddSurveyFeedbackMutation()
  const  [ updateResponseFeedback ] = useUpdateResponseFeedbackMutation()
  const [ updateCommentSurveyFeedback ] = useUpdateCommentSurveyFeedbackMutation()
  const [ updateQuestionFeedback ] = useUpdateQuestionFeedbackMutation()
  const [ updateSurveyFeedback ] = useUpdateSurveyFeedbackMutation()

  return (
    <CommentEditor
      label={props.label || COMMENT_SERVICE.answerInputLabel(props.comment, session.user?.id)}
      publishButtonLabel={sendOrPublishLabel}
      publishingHint={publishingHint()}
      isAllowedToWrite={props.isAllowedToWrite}
      isAllowedToPublish={props.isAllowedToPublish}
      isImmediatePublishing={props.isImmediatePublishing}
      comment={props.comment}
      commentKey={`comment_${props.commentMetadata.objectId}_${props.commentMetadata.attributeValueId}`}
      isStatusDisplayed={props.isStatusDisplayed}
      isReadOnly={isReadOnly}
      isUpdating={isUpdating}
      // Don't show the Delete button if the user is not allowed to save a comment
      isDeleteButtonShown={!props.isImmediatePublishing && props.isAllowedToWrite}
      onSave={saveComment}
      onDelete={deleteComment}
    />
  )

  /**
   * Get the confirm action wording
   */
  function confirmActionWording(): string {
    if (props.isImmediatePublishing) {
      // If the comment has a parent, this is an answer to a comment, so we display 'Send' instead of 'Publish'
      return props.commentMetadata?.parentComment ? t`Send` : t`Publish`
    }
    else {
      return t`Confirm`
    }
  }

  function publishingHint(): string | undefined {
    if (props.isImmediatePublishing && !isReadOnly) {
      return t`
        When you click on "${sendOrPublishLabel}",
        the user will receive an email with your answer. This action is irreversible.
      `
    }
  }

  /**
   * Delete the comment in the backend
   */
  async function deleteComment() {
    if (!props.comment) {
      throw new Error("Cannot delete a new comment without metadata")
    }

    setIsUpdating(true)

    try {
      await updateComment(
        props.comment.id,
        [ {
          comment: "",
          language: session.user!.language.code,
        } ],
        false,
      )

      await props.onDeleted?.()
      notification.show(t`Success`, t`Your comment was successfully deleted`, "success")
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
      }
    }
    finally {
      setIsUpdating(false)
    }
  }

  /**
   * Save the comment in the backend
   */
  async function saveComment(value: string, status: "draft" | "published") {
    const translations = [ { comment: value, language: session.user!.language.code } ]
    let newCommentData

    const isDraft = status === "draft"

    setIsUpdating(true)
    try {
      if (props.comment) {
        newCommentData = await updateComment(
          props.comment.id,
          translations,
          isDraft,
        )
      }
      else if (props.commentMetadata) {
        newCommentData = await addComment(translations, isDraft)
      }
      else {
        throw new Error("Cannot save a new comment without metadata")
      }

      await props.onSaved?.(newCommentData?.data?.comment)
      notification.show(
        t`Success`,
        isDraft
          ? t`Your draft was successfully saved`
          : t`Your comment was successfully published`,
        "success",
      )
    }
    catch (error) {
      if (error instanceof ApolloError) {
        if (error.graphQLErrors?.[0]) {
          notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
        }
      }
      // in case the Error "Cannot save a new comment without metadata" is thrown
      else {
        notification.show(t`Error`, error.message, "danger")
      }
    }
    setIsUpdating(false)
  }

  async function addComment(translations: CommentTranslation[], isDraft: boolean) {
    const variables = {
      isDraft,
      objectId: props.commentMetadata.objectId,
      parentCommentId: props.commentMetadata.parentComment?.id,
      translations,
    }
    switch (props.commentMetadata.objectType) {
      case CommentObjectType.Response:
        return await addResponseFeedback({
          variables,
        })
      case CommentObjectType.SurveyParticipation:
        return await addCommentSurveyFeedback({
          variables,
        })
      case CommentObjectType.ScheduledSurvey:
        return await addSurveyFeedback({
          variables: {
            ...variables,
            attributeValueId: props.commentMetadata.attributeValueId,
          },
        })
      case CommentObjectType.QuestionScheduledSurvey:
        return await addQuestionFeedback({
          variables: {
            ...variables,
            attributeValueId: props.commentMetadata.attributeValueId,
          },
        })
      default:
        throw new Error(`Cannot save comment: object type ${props.commentMetadata.objectType} is not supported`)
    }
  }

  async function updateComment(commentId: number, translations: CommentTranslation[], isDraft: boolean) {
    const data = {
      variables: {
        commentId,
        isDraft,
        translations,
      },
    }
    switch (props.commentMetadata.objectType) {
      case CommentObjectType.Response:
        return await updateResponseFeedback(data)
      case CommentObjectType.SurveyParticipation:
        return await updateCommentSurveyFeedback(data)
      case CommentObjectType.ScheduledSurvey:
        return await updateSurveyFeedback(data)
      case CommentObjectType.QuestionScheduledSurvey:
        return await updateQuestionFeedback(data)
      default:
        throw new Error(`Cannot save comment: object type ${props.commentMetadata.objectType} is not supported`)
    }
  }
}

export interface CommentEditorContainerProps extends BaseProps {
  /** Label override */
  label?: string,

  /** Whether the user is allowed to edit the comment */
  isAllowedToWrite?: boolean,

  /** Whether the user is allowed to publish the comment */
  isAllowedToPublish?: boolean,

  /** Whether to show the publishing status of the comment */
  isStatusDisplayed?: boolean,

  /** Comment to manage */
  comment?: Comment,

  /**
   * Information about the comment target.
   * Mandatory if no comment is provided, for it to be correctly created
   */
  commentMetadata: Pick<Comment, "parentComment"> & Pick<CommentObject, "objectId" | "objectType"> & {
    attributeValueId?: SkinnyUserAttributeValue["valueId"],
  },

  /** Whether the publication is immediate or delayed */
  isImmediatePublishing?: boolean,

  /** Called when the comment is saved */
  onSaved?: (comment: Comment | undefined) => void | Promise<void>,

  /** Called when the comment is deleted */
  onDeleted?: () => void,
}
