import * as React from "react"
import { t } from "ttag"

export const PasswordSecurityRules: React.FunctionComponent = () => {
  return <div dangerouslySetInnerHTML={{
    __html: t`For an optimal security, your password must be composed of:<br/>
    - 8 or more characters<br/>
    - at least one lowercase letter<br/>
    - at least one uppercase letter<br/>
    - at least one number<br/>
    - at least one special character`,
  }} />
}
