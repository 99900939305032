import * as React from "react"
import { RouteComponentProps } from "react-router"
import { useHistory } from "react-router-dom"
import { t } from "ttag"

import { ChangePasswordForm } from "../ChangePasswordForm"
import { AuthenticationContext } from "features/Authentication"
import { APPLICATION_URL, ApplicationPath } from "features/Navigation"
import { ContentWithSpeakupLogo } from "features/Shared/ContentWithSpeakupLogo"
import { useWindowSize } from "@humanpredictiveintelligence/myqvt-library"

import { useSession } from "features/Session"

import {
  useChangePasswordMutation,
} from "graphql/mutations/generated/ChangePassword"

import * as Styles from "./ChangePasswordPage.styles"

export const ChangePasswordPage = (props: RouteComponentProps) => {
  const authentication = React.useContext(AuthenticationContext)
  const session = useSession()
  const history = useHistory()

  const [ isPasswordChanged, setPasswordChanged ] = React.useState<boolean>(false)
  const [ changePasswordErrorTimestamp, setChangePasswordErrorTimestamp ] = React.useState(0)
  const [ changePassword, { loading: isChangingPassword } ]
    = useChangePasswordMutation(
      {
        onCompleted: () => {
          setPasswordChanged(true)
        },
        onError: error => {
          if (error.graphQLErrors?.[0]) {
            setChangePasswordErrorTimestamp(Date.now())
            setChangePasswordError(error.graphQLErrors?.[0].extensions?.reason ?? error.graphQLErrors?.[0].message)
          }
          else {
            props.history.push(APPLICATION_URL.error())
          }
        },
      },
    )
  const [ changePasswordError, setChangePasswordError ] = React.useState<string | undefined>(undefined)

  const windowSize = useWindowSize()

  if (!session.authentication.isAuthenticated && !isPasswordChanged) {
    history.push(APPLICATION_URL.error(), {
      message: t`This link is expired. Please make a new reset password request.`,
      redirect: ApplicationPath.Login,
      redirectLabel: t`Sign in`,
    })
  }

  return (
    <ContentWithSpeakupLogo>
      <Styles.CardWrapper>
        <Styles.Title>{t`New password`}</Styles.Title>
        {isPasswordChanged && (
          <>
            <Styles.Instructions>
              {t`Your password has been successfuly modified. Please click bellow to proceed to the login interface.`}
            </Styles.Instructions>
            <Styles.Action
              onClick={gotoLogin}
            >
              {t`Sign in`}
            </Styles.Action>
          </>
        )}
        {!isPasswordChanged && (
          <ChangePasswordForm
            onSubmit={onSubmit}
            isLoading={isChangingPassword}
            formError={changePasswordError
              ? { message: changePasswordError, timestamp: changePasswordErrorTimestamp }
              : undefined
            }
            isTooltipDisabled={windowSize.isPortrait || windowSize.isReducedLandscape}
          />
        )}
      </Styles.CardWrapper>
    </ContentWithSpeakupLogo>
  )

  function onSubmit(password: string) {
    changePassword({
      variables: {
        password,
      },
    })
  }

  function gotoLogin() {
    authentication.logout()
    props.history.push(ApplicationPath.Login)
  }
}
