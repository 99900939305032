import React from "react"
import { Admin, AdminPermission } from "features/Permissions/models"
import { UserPermissionCode } from "models/generated"

export type PermissionsReducerAction =
  { type: "setAdmins", admins: Admin[]}
  | { type: "selectAdmin", admin?: Admin }
  | { type: "setAdminsLoading", isLoading: boolean }
  | { type: "setAdminLoading", isLoading: boolean }
  | { type: "togglePermission", permissionCode: AdminPermission["permission"]["code"] }
  | { type: "setInitPasswordEmailSentForSelectedAdmin" }

export const permissionsReducerActions = (dispatch: React.Dispatch<PermissionsReducerAction>) => ({
  selectAdmin: (admin?: Admin) => dispatch({
    admin,
    type: "selectAdmin",
  }),
  setAdminLoading: (isLoading: boolean) => dispatch({
    isLoading,
    type: "setAdminLoading",
  }),
  setAdmins: (admins: Admin[]) => dispatch({
    admins,
    type: "setAdmins",
  }),
  setAdminsLoading: (isLoading: boolean) => dispatch({
    isLoading,
    type: "setAdminsLoading",
  }),
  setInitPasswordEmailSentForSelectedAdmin: () => dispatch({
    type: "setInitPasswordEmailSentForSelectedAdmin",
  }),
  togglePermission: (permissionCode: UserPermissionCode) => dispatch({
    permissionCode,
    type: "togglePermission",
  }),
})
