import { t } from "ttag"
import React from "react"

import { LedMetric } from "features/Led/LedMetric"
import { MomentRange } from "models/DateTime/MomentRange"
import { formatDateInterval } from "utilities/helpers"

import * as Layout from "../layout.styles"

import { useGetGlobalLedStatisticsQuery } from "graphql/queries/generated/GetGlobalLedStatistics"

export const LedGlobalStatisticsMetrics: React.FC<LedGlobalStatisticsMetricsProps> = (props) => {
  const { data: globalStatistics, loading: areGlobalStatisticsLoading } = useGetGlobalLedStatisticsQuery({
    variables: {
      dateInterval: formatDateInterval(props.filterRange?.from, props.filterRange?.to),
      psyDispositifNanoId: props.psyDispositifNanoId,
    },
  })

  return <>
    <Layout.Module $span={2}>
      <LedMetric for="meetings"
        title={t`Total number of discussions`}
        value={globalStatistics?.getPsyReportStatistics?.psyReportsCount ?? 0}
        isLoading={areGlobalStatisticsLoading}
      />
    </Layout.Module>
    <Layout.Module $span={2}>
      <LedMetric for="beneficiary"
        title={t`Total number of recipients`}
        value={globalStatistics?.getPsyReportStatistics?.psyRecipientsCount ?? 0}
        isLoading={areGlobalStatisticsLoading}
      />
    </Layout.Module>
  </>
}

interface LedGlobalStatisticsMetricsProps {
  filterRange?: MomentRange,
  psyDispositifNanoId: string,
}
