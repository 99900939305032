import * as React from "react"
import { ResponsiveBar } from "@nivo/bar"
import {
  Card,
  CenteredLoader,
  Skeletonable,
  Title,
  nivoMyQvtTheme,
  withSkeleton,
} from "@humanpredictiveintelligence/myqvt-library"
import AutoSizer from "react-virtualized-auto-sizer"
import { t } from "ttag"

import * as Styles from "./DiscussionsChart.styles"

const DiscussionsChartSkeleton: React.FC<DiscussionsChartProps> = (props) => (
  <AutoSizer>
    {({ width, height }: {height: number, width: number}) => (
      <Styles.Container $width={width} $height={height}>
        <Title level="metricCard">
          {t`Number of discussions and beneficiaries`}
        </Title>
        <CenteredLoader isTransparent/>
      </Styles.Container>
    )}
  </AutoSizer>
)

export const DiscussionsChartComponent: React.FC<Skeletonable<DiscussionsChartProps>> = (props) => (
  <AutoSizer>
    {({ width, height }: { height: number, width: number }) => (
      <Styles.Container $width={width} $height={height}>
        <Title level="metricCard">
          {t`Number of discussions and beneficiaries`}
        </Title>
        <ResponsiveBar
          colors={[ "#236fff", "#ffc107" ]}
          borderColor={{ theme: "background" }}
          data={props.chartData}
          keys={[ "discussions", "recipients" ]}
          indexBy="date"
          groupMode="grouped"
          theme={nivoMyQvtTheme}
          tooltip={({ data }) => (
            <Card shadow="button" isReducedPadding>
              <span>{t`Discussions : ` + data.discussions}</span><br/>
              <span>{t`Recipients : ` + data.recipients}</span>
            </Card>
          )}
          isInteractive
          layout="vertical"
          margin={{ bottom: 60, left: 30, right: 0, top: 20 }}
          padding={0.9 - (props.chartData.length / 25)}
          innerPadding={4}
          valueScale={{ type: "linear" }}
          indexScale={{ round: true, type: "band" }}
          enableGridX={false}
          animate
          enableGridY
          borderRadius={5}
          enableLabel
          labelTextColor={{
            from: "color",
            modifiers: [
              [
                "brighter",
                10,
              ],
            ],
          }}
          axisLeft={{
            format: (e) => Math.floor(e) === e && e,
          }}
        />
      </Styles.Container>
    )}
  </AutoSizer>
)

export const DiscussionsChart = withSkeleton(
  DiscussionsChartComponent,
  DiscussionsChartSkeleton,
)

type DiscussionsDatum = {
  date: string,
  discussions: number,
  recipients: number,
}

export interface DiscussionsChartProps {
  chartData: DiscussionsDatum[],
}
