import moment from "moment"
import React from "react"
import { DayPickerProps, Modifier } from "react-day-picker"
import { t } from "ttag"

import { APPLICATION_URL } from "features/Navigation"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"
import { ScheduledSurveyCard } from "../../Surveys/ScheduledSurveyList/ScheduledSurveyCard"
import { InformationMessage } from "@humanpredictiveintelligence/myqvt-library"
import { Survey, SurveyScheduling, Theme } from "models/Survey/index"
import { localDateWithoutConversion } from "utilities/timezone"
import * as Styles from "./ScheduledSurveysCalendard.styles"

export const ScheduledSurveysCalendar: React.FC<ScheduledSurveysCalendarProps> = (props) => {
  const [ hoveredSurvey, setHoveredSurvey ] = React.useState<ScheduledSurveyOnDay | undefined>()
  const [ activeSurvey, setActiveSurvey ] = React.useState<ScheduledSurveyOnDay | undefined>()
  const [ calendarModifiers, setCalendarModifiers ] = React.useState<CalendarModifiers>()

  React.useEffect(() => {
    updateCalendarModifiers(props.scheduledDays)
  }, [ props.scheduledDays ])

  const displayedSurvey = hoveredSurvey ?? activeSurvey

  return (
    <MetricsCard
      title={
        <Styles.CardTitle>
          {t`Programmation`}
          <PrimaryButton
            link={APPLICATION_URL.speakupScheduledSurveys()}
            isInverted
            height="small"
          >
            {t`See all`}
          </PrimaryButton>
        </Styles.CardTitle>
      }
      className={props.className}
      hasReducedPadding
    >
      <Styles.CalendarContainer>
        <Styles.Calendar
          onDayMouseEnter={setHoveredSurveyInformations}
          onDayMouseLeave={() => setHoveredSurvey(undefined)}
          onMonthChange={props.onMonthChange}
          {...calendarModifiers}
        />

        <Styles.DetailedSurvey aria-label="hovered-survey-details">
          {displayedSurvey && (
            <ScheduledSurveyCard
              isInteractive
              scheduling={displayedSurvey}
              survey={displayedSurvey.survey}
              theme={displayedSurvey.survey.theme}
            />
          )}
          {!displayedSurvey && (<InformationMessage text={t`There is no survey scheduled today.`}/>)}
        </Styles.DetailedSurvey>
      </Styles.CalendarContainer>
    </MetricsCard>
  )

  /**
   *  Generate modifiers and stylesModifiers for CalendarView
   * @param scheduledDays Days that includes scheduling surveys
   * */
  function updateCalendarModifiers(scheduledDays: Map<string, ScheduledSurveyOnDay[]>) {
    if (scheduledDays.size === 0) {
      return {}
    }

    const calendarModifiers: CalendarModifiers["modifiers"] = {}
    const calendarStylesModifiers: CalendarModifiers["modifiersStyles"] = {}

    scheduledDays.forEach((scheduledSurveysOnDay, scheduledDay) => {
      const scheduledSurveyId = scheduledSurveysOnDay[0].schedulingId
      const theme = scheduledSurveysOnDay[0].survey.theme

      const scheduledDate = localDateWithoutConversion(scheduledDay)

      if (moment().isSame(scheduledDate, "date")) {
        const todayOnUserTimezone = moment.parseZone(localDateWithoutConversion(scheduledDay)).startOf("day")
        setActiveSurvey(scheduledDays.get(todayOnUserTimezone.toString())?.[0])
      }

      // Add scheduling id and start modifiers
      if (!calendarModifiers[`survey-id-${scheduledSurveyId}`]) {
        calendarModifiers[`survey-id-${scheduledSurveyId}`] = [ scheduledDate ]

        // Add the scheduling-start class to the first day if the scheduled survey is not overlapping
        if (scheduledSurveysOnDay.length === 1) {
          if (calendarModifiers["scheduling-start"]) {
            (calendarModifiers["scheduling-start"] as Modifier[]).push(scheduledDate)
          }
          else {
            calendarModifiers["scheduling-start"] = [ scheduledDate ]
          }
        }
      }
      else {
        (calendarModifiers[`survey-id-${scheduledSurveyId}`] as Modifier[]).push(scheduledDate)
      }
      // Add scheduling-end modifiers
      if (
        moment.parseZone(scheduledDate).format("D") ===
        moment.parseZone(scheduledSurveysOnDay[0].endAt.local).format("D")
        && scheduledSurveysOnDay.length === 1
      ) {
        if (calendarModifiers["scheduling-end"]) {
          (calendarModifiers["scheduling-end"] as Modifier[]).push(scheduledDate)
        }
        else {
          calendarModifiers["scheduling-end"] = [ scheduledDate ]
        }
      }

      // Add theme modifier
      if (calendarModifiers[`survey-theme-${theme.id}`]) {
        (calendarModifiers[`survey-theme-${theme.id}`] as Modifier[]).push(scheduledDate)
      }
      else {
        calendarStylesModifiers[`survey-theme-${theme.id}`] = { backgroundColor: theme.color }
        calendarModifiers[`survey-theme-${theme.id}`] = [ scheduledDate ]
      }
    })

    setCalendarModifiers({ modifiers: calendarModifiers, modifiersStyles: calendarStylesModifiers })
  }

  /**
   * When a Day is hovered and a scheduled survey is present set it as hovered
   * @param day The date currently hovered
   * */
  function setHoveredSurveyInformations(day: Date) {
    const dayOnUserTimezone = moment.parseZone(day).startOf("day")

    setHoveredSurvey(props.scheduledDays.get(dayOnUserTimezone.toString())?.[0])
  }
}

type CalendarModifiers = {
  modifiers: DayPickerProps["modifiers"],
  modifiersStyles: { [className: string]: Partial<CSSStyleDeclaration> },
}

export type ScheduledSurveyOnDay = SurveyScheduling & { survey: Survey & { theme: Theme } }

export interface ScheduledSurveysCalendarProps extends BaseProps {
  /** Collection of scheduled days with their corresponding scheduled surveys */
  scheduledDays: Map<string, ScheduledSurveyOnDay[]>,

  /** Fired when the selected month is changed */
  onMonthChange?: (month: Date) => void,
}
