import styled, { css } from "styled-components/macro"
import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { Caption } from "@humanpredictiveintelligence/myqvt-library"
import { SearchableSelect } from "@humanpredictiveintelligence/myqvt-library"

export const Card = styled(MetricsCard)`
  overflow: visible;
`

export const Content = styled.div<{ $isEmpty?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ theme, $isEmpty }) => $isEmpty && css`
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${theme.colors.blackMedium};
  `}
`
export const Legend = styled.div`
  width: 100%;
  
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  column-gap: 8px;
  row-gap: 8px;

  justify-content: end;
  align-items: center;
`

export const LegendCaption = styled(Caption)`
  grid-column: auto / span 2;
  justify-self: self-start;
`

export const SerieColor = styled.div`
  display: inline-block;
  height: 12px;
  width: 12px;

  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.sizes.borderRadiusRounded};
`

export const SerieColorSecondary = styled(SerieColor)`
  background-color: ${({ theme }) => theme.colors.secondary};
`

export const SerieSelect = styled(SearchableSelect)`
  width: 118px;
`

export const ChartContainer = styled.div`
  flex-grow: 1;
  margin-top: 24px;
`
