import * as React from "react"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"

import * as Styles from "./LedDiscussionDetails.styles"
import noDiscussionsImage from "assets/images/illustration_detective.png"

export const LedDiscussionDetails: React.FC<LedDiscussionDetailsProps> = ({ content }) => {
  return (
    <Styles.Container>
      {content.length > 0
        ? <>
          <Title level="metricCard">
            {t`Comment for the company`}
          </Title>
          <Styles.Content>
            <Styles.Text>{content}</Styles.Text>
          </Styles.Content>
        </>
        : (
          <Styles.NoDiscussions>
            <Styles.NoDiscussionsImage src={noDiscussionsImage}/>
            <p>{t`Select a conversation from the left-hand list to see the comment`}</p>
          </Styles.NoDiscussions>
        )
      }
    </Styles.Container>
  )
}

interface LedDiscussionDetailsProps {
  content: string,
}
