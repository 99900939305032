/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserAssigneesTimeInformationFragmentFragmentDoc } from '../../../models/generated/SkinnyUserAssigneesTimeInformationFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssigneesTimeInformationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAssigneesTimeInformationQuery = { me: { assigneesTimeInformation: { earliest: string, latest: string, haveDifferentTimezones: boolean } } };


export const GetAssigneesTimeInformationDocument = gql`
    query GetAssigneesTimeInformation {
  me {
    ...SkinnyUserAssigneesTimeInformationFragment
  }
}
    ${SkinnyUserAssigneesTimeInformationFragmentFragmentDoc}`;

/**
 * __useGetAssigneesTimeInformationQuery__
 *
 * To run a query within a React component, call `useGetAssigneesTimeInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssigneesTimeInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssigneesTimeInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssigneesTimeInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetAssigneesTimeInformationQuery, GetAssigneesTimeInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssigneesTimeInformationQuery, GetAssigneesTimeInformationQueryVariables>(GetAssigneesTimeInformationDocument, options);
      }
export function useGetAssigneesTimeInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssigneesTimeInformationQuery, GetAssigneesTimeInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssigneesTimeInformationQuery, GetAssigneesTimeInformationQueryVariables>(GetAssigneesTimeInformationDocument, options);
        }
export type GetAssigneesTimeInformationQueryHookResult = ReturnType<typeof useGetAssigneesTimeInformationQuery>;
export type GetAssigneesTimeInformationLazyQueryHookResult = ReturnType<typeof useGetAssigneesTimeInformationLazyQuery>;
export type GetAssigneesTimeInformationQueryResult = Apollo.QueryResult<GetAssigneesTimeInformationQuery, GetAssigneesTimeInformationQueryVariables>;