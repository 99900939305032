import React from "react"
import { useTheme } from "styled-components"

import { SkeletonWrapper, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import { Ghost } from "@humanpredictiveintelligence/myqvt-library"
import { ThemeIcon } from "@humanpredictiveintelligence/myqvt-library"
import { Stepper } from "@humanpredictiveintelligence/myqvt-library"
import { ThemeProgress } from "models/ThemeProgress"
import * as Styles from "./ThemeProgressionsModule.styles"

const ThemeProgressionListItemComponent: React.FC<ThemeProgressionListItemProps> = (props) => {
  const theme = useTheme()

  const noGradeSymbol = "-"
  const themeGrade = props.grade !== undefined ? Math.round(props.grade) : noGradeSymbol
  const themeGradeColor = themeGrade !== noGradeSymbol ? gradeColor(themeGrade) : undefined

  return (
    <Ghost role="row" aria-label={props.theme.name}>
      <div role="cell">
        <ThemeIcon
          size="small"
          hasReducedPadding
          {...props.theme}
        />
      </div>
      <Ghost role="cell">
        <Stepper
          steps={props.surveysCount}
          currentStep={props.usedSurveysCount}
          arePreviousStepsColored
        />
      </Ghost>
      <Styles.ThemeGrade role="cell">
        <Styles.ThemeGradeValue $color={themeGradeColor}>
          {themeGrade}
        </Styles.ThemeGradeValue>/100
      </Styles.ThemeGrade>
    </Ghost>
  )

  /**
   * Get the class variant corresponding to the given grade
   * @param grade Grade of the theme
   * */
  function gradeColor(grade: number): string {
    if (grade >= 75) {
      return theme.colors.success
    } else if (grade >= 25) {
      return theme.colors.warning
    }

    return theme.colors.danger
  }
}

const ThemeProgressionListItemSkeleton: React.FC = () => {
  return (
    <Ghost role="row">
      <div role="cell">
        <SkeletonWrapper height={24} width={24} circle={true} isLoading/>
      </div>
      <Ghost role="cell">
        <SkeletonWrapper height={6} width={200} isLoading/>
      </Ghost>
      <div className="propssModule__theme-grade" role="cell">
        <SkeletonWrapper height={10} width={50} isLoading/>
      </div>
    </Ghost>
  )
}

export const ThemeProgressionListItem = withSkeleton(
  ThemeProgressionListItemComponent,
  ThemeProgressionListItemSkeleton,
)

type ThemeProgressionListItemProps = ThemeProgress;
