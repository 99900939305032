import * as Styles from "./DemoSurveyPage.styles"
import BluredDashboart from "assets/images/bluredDashboard.avif"
import Mockup from "assets/images/mockup-laptop.png"
import DiscoverSpeakUp from "assets/images/discoverSpeakup.png"
import leftArrow from "assets/images/leftArrow.png"
import PlayButton from "assets/images/playButton.png"

import React, { useState } from "react"
import { STATIC_SESSION } from "../../../utilities/StaticSession"

export const DemoSurveyPage: React.FC = () => {
  const [ isVideoVisible, setIsVideoVisible ] = useState(false)

  const handlePlayButtonClick = () => {
    setIsVideoVisible(true)
  }

  return (
    <Styles.Container>
      <Styles.BluredDashboard src={BluredDashboart} alt=""/>
      <Styles.DisplayModal>
        <Styles.ModalHeader>
          <Styles.ModalHeaderTitle>
            Conseil, accompagnement et solutions en faveur de la vie au travail en mieux
          </Styles.ModalHeaderTitle>
          <Styles.ModalHeaderContent>
            Le Groupe JLO, cabinet de conseil RH & QVCT, vous propose une offre de conseil et d'accompagnement
            ainsi que des solutions opérationnelles pour construire, développer et optimiser vos démarches en faveur
            de la <strong>Qualité de Vie et des Conditions de Travail</strong>
          </Styles.ModalHeaderContent>
        </Styles.ModalHeader>
        <Styles.ModalContent>
          <Styles.DiscoverSpeakUp src={DiscoverSpeakUp} alt="" />
          <Styles.VideoContainer>
            <Styles.MockupImg src={Mockup} alt=""/>
            {!isVideoVisible && (
              <Styles.PlayButtonImg
                src={PlayButton}
                alt="Play video"
                onClick={handlePlayButtonClick}
              />
            )}
            {isVideoVisible && (
              <Styles.SpeakUpVideo>
                <iframe
                  width="521"
                  height="300"
                  src="https://www.youtube.com/embed/FkCfWgwyBzg?autoplay=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
                  web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              </Styles.SpeakUpVideo>
            )}
          </Styles.VideoContainer>
          <Styles.LeftArrow src={leftArrow} alt=""/>
          <Styles.ContactButton
            as="a"
            href={`mailto:${STATIC_SESSION.contactEmailVL}`}
            aria-label="Contacter notre équipe">
            Contacter notre équipe
          </Styles.ContactButton>
        </Styles.ModalContent>
      </Styles.DisplayModal>
    </Styles.Container>
  )
}
