import * as React from "react"

import { ModuleContainer } from "../ModuleContainer"
import { ErroredGlobalFeedbacksMetrics, GlobalFeedbacksMetrics } from "../GlobalFeedbacksMetrics"
import { ErroredParticipationMetrics, ParticipationMetrics } from "../ParticipationMetrics"
import { ErroredRecipientsMetrics, RecipientsMetrics } from "../RecipientsMetrics"
import { SatisfactionEvolutionMetricsContainer } from "../SatisfactionEvolutionMetricsContainer"
import { ScheduledSurveysCalendarContainer } from "../ScheduledSurveysCalendarContainer"
import { ErroredThemeProgressionsModule, ThemeProgressionsModule } from "../ThemeProgressionsModule"
import {
  CommentsMetrics,
  CommentsMetricsProps,
  ErroredCommentsMetrics,
} from "features/Comments"
import { UnprocessedGlobalRecipientCommentsContainer }
  from "features/Comments"
import { STATISTICS_SERVICE } from "services/StatisticsService"
import { useUserPermissions } from "features/Permissions"
import { UserPermissionCode } from "models/generated"
import * as Styles from "./DashboardPage.styles"

import {
  CommentsStatisticsFragment,
  RecipientsStatisticsFragment,
  ResultsStatisticsFragment,
  useCustomerGlobalStatisticsQuery,
} from "graphql/queries/generated/CustomerGlobalStatistics"

import homepageModuleRealParticipationImage from "assets/images/homepage_module_real-participation.png"
import homepageModuleAdjustedParticipationImage from "assets/images/homepage_module_adjusted-participation.png"
import homepageModuleCommentsStatsImage from "assets/images/homepage_module_comments_stats.png"
import homepageModuleGlobalFeedbackImage from "assets/images/homepage_module_global_feedback.png"
import homepageModuleRecipientsImage from "assets/images/homepage_module_recipients.png"
import homepageModuleCommentsImage from "assets/images/homepage_module_comments.png"
import homepageModuleSchedulingImage from "assets/images/homepage_module_scheduling.png"
import homepageModuleThemeImage from "assets/images/homepage_module_themes.png"
import homepageModuleSatisfactionImage from "assets/images/homepage_module_satisfaction.png"

export const DashboardPage: React.FC = () => {
  const isUserAllowed = useUserPermissions()

  const isUserAllowedToReadComments = isUserAllowed(UserPermissionCode.CommentsRead)
  const isUserAllowedToReadResults = isUserAllowed(UserPermissionCode.ResultsRead)
  const isUserAllowedToReadRecipients = isUserAllowed(UserPermissionCode.RecipientsRead)

  const [ commentsModuleNavigationHeight, setCommentsModuleNavigationHeight ] = React.useState(0)

  const  {
    data: customerStatistics,
    loading: isCustomerStatisticsLoading,
    error: customerStatisticsError,
  } = useCustomerGlobalStatisticsQuery({
    variables: {
      isUserAllowedToReadComments,
      isUserAllowedToReadRecipients,
      isUserAllowedToReadResults,
    },
  })

  const globalStatistics: Partial<
    ResultsStatisticsFragment & CommentsStatisticsFragment & RecipientsStatisticsFragment
  > = {
    ...customerStatistics?.resultsStatistics,
    ...customerStatistics?.commentsStatistics,
    ...customerStatistics?.recipientsStatistics,
  }

  const isRecipientsDataExpired
    = STATISTICS_SERVICE.isRecipientsDataExpired(globalStatistics?.recipientsUpdatedAt)
  const termsOfServiceAcceptancePercentages = STATISTICS_SERVICE.termsOfServiceAcceptancePercentages(
    globalStatistics?.recipientsCount,
    globalStatistics?.acceptedTermsCount,
    globalStatistics?.refusedTermsCount,
  )

  const averageCommentsCountPerQuestionForLatestSurvey =
    globalStatistics?.averageCommentsCountPerQuestionDelta && globalStatistics?.averageCommentsCountPerQuestion
      ? Math.round(globalStatistics?.averageCommentsCountPerQuestion -
        globalStatistics?.averageCommentsCountPerQuestionDelta)
      : 0

  let averageCommentsCountPerQuestionTrend: CommentsMetricsProps["averageCommentsCountPerQuestionTrend"]
  if (customerStatistics && globalStatistics?.averageCommentsCountPerQuestionDelta) {
    averageCommentsCountPerQuestionTrend = globalStatistics?.averageCommentsCountPerQuestionDelta > 0
      ? "positive"
      : "negative"
  }

  const averageCommentsCountPerQuestion = !globalStatistics.averageCommentsCountPerQuestion ?
    0 : Math.round(globalStatistics.averageCommentsCountPerQuestion)

  const areMetricsErrored = !isCustomerStatisticsLoading && !!customerStatisticsError

  return (
    <Styles.Container>
      <Styles.Modules>

        {/* Real participation */}
        <ModuleContainer
          isErrored={areMetricsErrored}
          isAllowed={isUserAllowedToReadResults}
          erroredComponent={<ErroredParticipationMetrics participationType="real" className="Module--span2"/>}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span2">
              <Styles.Image src={homepageModuleRealParticipationImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <ParticipationMetrics
            isLoading={isCustomerStatisticsLoading}
            participationType="real"
            overallParticipationPercentage={globalStatistics?.overallRealParticipationPercentage}
            latestParticipationPercentage={globalStatistics?.latestRealParticipationPercentage || 0}
            latestParticipationDelta={globalStatistics?.latestRealParticipationDelta}
            className="Module--span2"
          />
        </ModuleContainer>

        {/* Adjusted participation */}
        <ModuleContainer
          isErrored={areMetricsErrored}
          isAllowed={isUserAllowedToReadResults}
          erroredComponent={<ErroredParticipationMetrics participationType="adjusted" className="Module--span2"/>}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span2">
              <Styles.Image src={homepageModuleAdjustedParticipationImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <ParticipationMetrics
            isLoading={isCustomerStatisticsLoading}
            participationType="adjusted"
            overallParticipationPercentage={globalStatistics?.overallAdjustedParticipationPercentage}
            latestParticipationPercentage={globalStatistics?.latestAdjustedParticipationPercentage || 0}
            latestParticipationDelta={globalStatistics?.latestAdjustedParticipationDelta}
            className="Module--span2"
          />
        </ModuleContainer>

        {/* Comments statistics */}
        <ModuleContainer
          isErrored={areMetricsErrored}
          isAllowed={isUserAllowedToReadComments}
          erroredComponent={<ErroredCommentsMetrics className="Module--span3"/>}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span3">
              <Styles.Image src={homepageModuleCommentsStatsImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <CommentsMetrics
            isLoading={isCustomerStatisticsLoading}
            className="Module--span3"
            averageCommentsCountPerQuestion={averageCommentsCountPerQuestion}
            averageCommentsCountPerQuestionForLatestSurvey={averageCommentsCountPerQuestionForLatestSurvey}
            averageCommentsCountPerQuestionTrend={averageCommentsCountPerQuestionTrend}
            overallCommentsCount={globalStatistics?.overallCommentsCount}
            overallCommentsCountDelta={globalStatistics?.overallCommentsCountDelta}
            overallResponsesCount={globalStatistics?.overallResponsesCount}
            responsesToCommentsRatio={globalStatistics?.responsesToCommentsRatio}
            responsesToCommentsRatioDelta={globalStatistics?.responsesToCommentsRatioDelta}
          />
        </ModuleContainer>

        {/* Global feedback statistics */}
        <ModuleContainer
          isErrored={areMetricsErrored}
          isAllowed={isUserAllowedToReadResults}
          erroredComponent={<ErroredGlobalFeedbacksMetrics className="Module--span2"/>}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span2">
              <Styles.Image src={homepageModuleGlobalFeedbackImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <GlobalFeedbacksMetrics
            isLoading={isCustomerStatisticsLoading}
            surveysWithoutFeedbackCount={globalStatistics?.surveysWithoutGlobalCommentsCount}
            surveysWithFeedbackCount={globalStatistics?.surveysWithGlobalCommentsCount}
            className="Module--span2"
          />
        </ModuleContainer>

        {/* Recipients and ToS statistics */}
        <ModuleContainer
          isErrored={areMetricsErrored}
          isAllowed={isUserAllowedToReadRecipients}
          erroredComponent={<ErroredRecipientsMetrics className="Module--span3"/>}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span3">
              <Styles.Image src={homepageModuleRecipientsImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <RecipientsMetrics
            isLoading={isCustomerStatisticsLoading}
            acceptedTermsOfServicePercentage={termsOfServiceAcceptancePercentages.accepted}
            className="Module--span3"
            isDataExpired={isRecipientsDataExpired}
            latestUpdateDate={globalStatistics?.recipientsUpdatedAt}
            recipientsCount={globalStatistics?.recipientsCount}
            refusedTermsOfServicePercentage={termsOfServiceAcceptancePercentages.refused}
            waitingTermsOfServicePercentage={termsOfServiceAcceptancePercentages.waiting}
          />
        </ModuleContainer>

        {/* ScheduledSurvey satisfaction chart */}
        <ModuleContainer
          isAllowed={isUserAllowedToReadResults}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span9">
              <Styles.Image src={homepageModuleSatisfactionImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <SatisfactionEvolutionMetricsContainer className="Module--span9"/>
        </ModuleContainer>

        {/* Scheduled surveys calendar */}
        <ModuleContainer
          isAllowed={isUserAllowedToReadResults}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span3">
              <Styles.Image src={homepageModuleSchedulingImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <ScheduledSurveysCalendarContainer className="Module--span3"/>
        </ModuleContainer>

        {/* Unprocessed comments */}
        <ModuleContainer
          isAllowed={isUserAllowedToReadComments}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span9">
              <Styles.Image src={homepageModuleCommentsImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <UnprocessedGlobalRecipientCommentsContainer
            className="Module--span9"
            onPagedNavigationSizeChange={setCommentsModuleNavigationHeight}
          />
        </ModuleContainer>

        {/* Theme progression */}
        <ModuleContainer
          isErrored={areMetricsErrored}
          isAllowed={isUserAllowedToReadResults}
          erroredComponent={<ErroredThemeProgressionsModule className="Module--span3"/>}
          disallowedComponent={
            <Styles.UnauthorisedMessage className="Module--span3">
              <Styles.Image src={homepageModuleThemeImage} alt=""/>
            </Styles.UnauthorisedMessage>
          }
        >
          <ThemeProgressionsModule
            isLoading={isCustomerStatisticsLoading}
            className="Module--span3"
            themeProgressions={globalStatistics?.themeProgressions}
            surveysSentCount={globalStatistics?.scheduledSurveysCount}
            firstSurveySentAt={globalStatistics?.firstScheduledSurvey?.beginAt.local}
            style={{ paddingBottom: commentsModuleNavigationHeight }}
          />
        </ModuleContainer>

      </Styles.Modules>
    </Styles.Container>
  )
}
