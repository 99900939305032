import styled from "styled-components/macro"
import { Dialog as MuiDialog } from "@material-ui/core"
import {
  Title as TitleComponent,
} from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div``

export const Heading = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Dialog = styled(MuiDialog)`
  .Container {
    width: 50rem;
    height: 50rem;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`

export const Title = styled(TitleComponent)`
  margin: 0;
  font-size: ${({ theme }) => theme.typography.fontSizeTitleDialog};
`

export const UsersTableContainer = styled.div`
  flex: 1;
  padding: .5rem 2rem 2rem 2rem;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.blackUltraLight};;
`

export const Footer = styled.div`
  display: grid;
  grid-template-columns: auto 25%;
  column-gap: 1.5rem;
  padding: 2rem;
`
