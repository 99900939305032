import styled from "styled-components/macro"
import { Title } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  margin: 24px 0;
`

export const GroupName = styled(Title)`
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
`

export const PermissionsTree = styled.div`
  position: relative;
  display: grid;
  grid-column-gap: 36px;
  grid-row-gap: 8px;
  grid-auto-rows: 1fr;
  width: fit-content;
`

export const Layer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &:only-child {
    flex-direction: row;
  }
  
  &:first-child > *:only-child {
    justify-self: center;
  }
`

export const ToggleContainer = styled.div<{ $gridPlacement: string}>`
  grid-area: ${({ $gridPlacement }) => $gridPlacement};
`
