import moment from "moment"
import { useState } from "react"
import * as React from "react"
import { t } from "ttag"

import { ScheduledSurveyCard } from "./ScheduledSurveyCard"
import { CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"
import { Survey, SurveyScheduling, Theme } from "models/Survey/index"
import { capitalizedString } from "utilities/helpers"
import { useUserPermissions } from "features/Permissions"
import * as Styles from "./ScheduledSurveyList.styles"
import {
  Scheduled_Survey_Status as ScheduledSurveyStatus, UserGroup,
  UserPermissionCode,
} from "models/generated"
import { useSession } from "../../Session"

export const ScheduledSurveyList: React.FC<ScheduledSurveyListProps> = props => {
  const isUserAllowed = useUserPermissions()
  const session = useSession()

  const [ overflowActive, setOverflowActive ] = useState<boolean>(false)
  const [ state, setState ] = React.useState<ScheduledSurveyListState>({
    activeSelectedSurvey: null,
    anchorEl: null,
    hasListReachedInitialScrollPosition: false,
    previouslySelectedId: undefined,
    previouslySelectedIndex: undefined,
    scrollPosition: 0,
    selectedSurveyId: (props.surveys.length && props.surveys[0].schedulingId) || undefined,
  })

  const previousSurveysRef = React.useRef<ScheduledSurveyInList[]>()
  const previousSurveys = previousSurveysRef.current

  const listContainerReference = props.listContainerReference || React.createRef<HTMLDivElement>()
  const initialScrollTargetReference = React.createRef<HTMLLIElement>()
  const previouslyFirstSurveyReference = React.createRef<HTMLSpanElement>()

  const open = Boolean(props.isCollapsed && state.anchorEl && state.activeSelectedSurvey)
  const id = open ? "transitions-popper" : undefined
  const isListEmpty = !props.surveys || !props.surveys.length

  const isScheduledSurveyCardDisabled = React.useCallback((scheduledSurveyStatus: ScheduledSurveyStatus) => {
    return !isUserAllowed(UserPermissionCode.ResultsRead) && scheduledSurveyStatus === ScheduledSurveyStatus.Done
  }, [ isUserAllowed ])

  // Check if scrollbar is visible in list, if so, we need to adapt the layout of card content
  // to it fit to the card width
  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer)
    {return (
      textContainer.offsetHeight < textContainer.scrollHeight
    )}
    return false
  }

  React.useEffect(() => {
    scrollToTargetOrMaintainPosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    previousSurveysRef.current = props.surveys
    handleListChanges()
  })

  React.useEffect(() => {
    if (checkOverflow(listContainerReference.current)) {
      setOverflowActive(true)
      return
    }

    setOverflowActive(false)
  }, [ listContainerReference, overflowActive, props.surveys ])

  return (
    <Styles.Container
      $isListEmpty={isListEmpty}
      $isCollapsed={props.isCollapsed}
      role={"feed"}
      onScroll={handleScroll}
      ref={listContainerReference}
    >
      {props.isLoading && <CenteredLoader delay={500} isTransparent/>}
      {!props.isLoading &&
      <>
        {isListEmpty && (
          <Styles.EmptyList>{t`No survey to display`}</Styles.EmptyList>
        )}
        {!isListEmpty && (
          <>
            <Styles.Loader size="s" isTransparent $isVisible={props.isTopLoaderDisplayed}/>

            {orderedSurveysByMonth(props.surveys).map(monthGroup => (
              <div key={monthGroup.key}>
                <Styles.MonthSeparator>{monthGroup.title}</Styles.MonthSeparator>

                <Styles.List>
                  {monthGroup.surveys.map((scheduledSurvey, surveyIndex) => (
                    <Styles.ListItem
                      onMouseLeave={handleMouseLeaveScheduledSurvey}
                      onMouseEnter={(event: React.MouseEvent<HTMLElement>) => {
                        setState((previousState) => ({
                          ...previousState,
                          activeSelectedSurvey: scheduledSurvey,
                        }))
                        handleMouseEnterScheduledSurvey(event)
                      }}
                      key={surveyIndex}
                      ref={(scheduledSurvey.schedulingId === props.initialScrollToSurveyId
                        && initialScrollTargetReference) || undefined
                      }
                      data-cy={"ScheduledSurveyList__survey--" + scheduledSurvey.schedulingId}
                    >
                      <span ref={(scheduledSurvey.schedulingId === state.selectedSurveyId
                        && previouslyFirstSurveyReference) || undefined}
                      />
                      <ScheduledSurveyCard
                        isInteractive
                        isActive={scheduledSurvey.schedulingId === props.activeSurveyId}
                        isDisabled={isScheduledSurveyCardDisabled(scheduledSurvey.status.absolute)}
                        scheduling={scheduledSurvey}
                        survey={scheduledSurvey.survey}
                        theme={scheduledSurvey.survey.theme}
                        isMini={props.isCollapsed}
                        availableUserGroups={session.users?.userGroups as UserGroup[]}
                        withUserGroupsSection
                        isScrollbarVisible={overflowActive}
                      />
                    </Styles.ListItem>
                  ))}
                </Styles.List>
              </div>
            ))}

            <Styles.Loader size="s" isTransparent $isVisible={props.isBottomLoaderDisplayed}/>
          </>
        )}
      </>}
      {state.activeSelectedSurvey && !isScheduledSurveyCardDisabled(state.activeSelectedSurvey.status.absolute) && (
        <Styles.ListItemFloater
          modifiers={{
            arrow: { enabled: true },
            flip: { enabled: true },
          }}
          placement={"right"}
          id={id}
          open={open}
          anchorEl={state.anchorEl}
        >
          <ScheduledSurveyCard
            isInteractive
            isActive={state.activeSelectedSurvey.schedulingId === props.activeSurveyId}
            scheduling={state.activeSelectedSurvey}
            survey={state.activeSelectedSurvey.survey}
            theme={state.activeSelectedSurvey.survey.theme}
            availableUserGroups={session.users?.userGroups as UserGroup[]}
            withUserGroupsSection
          />
        </Styles.ListItemFloater>
      )}
    </Styles.Container>
  )

  function handleListChanges() {
    scrollToTargetOrMaintainPosition()
    // Check if list is ready
    if (state.hasListReachedInitialScrollPosition && !props.isLoading) {
      props.onReady?.()
    }
    // Check if the selected survey change then store the id and index
    if (props.activeSurveyId && props.activeSurveyId !== state.previouslySelectedId) {
      setState((previousState) => ({
        ...previousState,
        previouslySelectedId: props.activeSurveyId,
        previouslySelectedIndex: previousSurveys?.findIndex(
          survey => survey.schedulingId === props.activeSurveyId,
        ),
      }))
    }
    // Check if a survey was deleted from the list
    if (
      state.previouslySelectedId !== undefined
      && state.previouslySelectedIndex !== undefined
      && !props.surveys.some(survey => survey.schedulingId === state.previouslySelectedId)
    ) {
      setState((previousState) => ({
        ...previousState,
        previouslySelectedId: undefined,
        selectedSurveyId:
          state.previouslySelectedIndex && previousSurveys?.[state.previouslySelectedIndex + 1]?.schedulingId,
      }))
    }
    // Check that some surveys were added at the top of the array
    else if (previousSurveys?.[0] !== props.surveys[0]) {
      setState((previousState) => ({
        ...previousState,
        selectedSurveyId: (previousSurveys?.length && previousSurveys?.[0].schedulingId) || undefined,
      }))
    }
    // Clean the previously first survey if more data was added at the bottom
    // to allow natural scroll positioning
    else if (state.selectedSurveyId) {
      setState((previousState) => ({
        ...previousState,
        selectedSurveyId: undefined,
      }))
    }
  }

  /**
   * Triggered when user leave the scheduled survey in list
   * @param event
   */
  function handleMouseLeaveScheduledSurvey(event: React.MouseEvent<HTMLElement>) {
    setState((previousState) => ({
      ...previousState,
      anchorEl: null,
    }))
  }

  /**
   * Triggered when user over scheduled survey in list
   * @param event
   */
  function handleMouseEnterScheduledSurvey(event: React.MouseEvent<HTMLElement>) {
    const anchorEl = state.anchorEl ? null : event.currentTarget
    setState((previousState) => ({
      ...previousState,
      anchorEl,
    }))
  }

  /** Smoothly scrolls the list to the initial target survey
   * or maintain the position if more surveys were added at the top of the list.
   */
  function scrollToTargetOrMaintainPosition() {
    const topScrollOffset = 54
    if (
      !state.hasListReachedInitialScrollPosition
      && listContainerReference.current
      && initialScrollTargetReference.current
    ) {
      listContainerReference.current.scrollTo({
        behavior: "smooth",
        top:
          initialScrollTargetReference.current.offsetTop
          - listContainerReference.current.offsetTop
          - topScrollOffset,
      })

      setState((previousState) => ({
        ...previousState,
        hasListReachedInitialScrollPosition: true,
      }))
    }
    if (props.isSurveyEdited
      && listContainerReference.current
      && initialScrollTargetReference.current
    ) {
      listContainerReference.current.scrollTo({
        behavior: "smooth",
        top:
          initialScrollTargetReference.current.offsetTop
          - listContainerReference.current.offsetTop
          - topScrollOffset,
      })
    } else if (listContainerReference.current && previouslyFirstSurveyReference.current) {
      listContainerReference.current.scrollTop
        = previouslyFirstSurveyReference.current.offsetTop - listContainerReference.current.offsetTop - 54
    }
  }

  /** Fire the onScrollToTop/onScrollToBottom callback props when scrolled to top or bottom */
  function handleScroll(event: React.UIEvent<HTMLDivElement>) {
    const surveysListElement = event.target as HTMLDivElement
    const isAtTop = surveysListElement.scrollTop === 0
    const isAtBottom = surveysListElement.clientHeight + surveysListElement.scrollTop
      === surveysListElement.scrollHeight

    /*
      A visual representation of the different sizes used here

      +--------------------+ ⬆️               ⬆️
      |                    | |               |
      |                    | | scrollTop     |
      |                    | |               |
      |                    | |               |
      |                    | ⬇️               |
      ¤====================¤ ⬆️               |
      |                    | |               |
      |     Displayed      | | clientHeight  |  scrollHeight
      |      section       | |               |
      |                    | |               |
      ¤====================¤ ⬇️               |
      |                    |                 |
      |                    |                 |
      |                    |                 |
      |                    |                 |
      +--------------------+              ️   ⬇️
    */

    if (isAtTop && typeof props.onScrollToTop === "function") {
      props.onScrollToTop()
    } else if (isAtBottom && typeof props.onScrollToBottom === "function") {
      props.onScrollToBottom()
    }
  }

  function orderedSurveysByMonth(scheduledSurveys: ScheduledSurveyInList[]) {
    return scheduledSurveys
      .reduce((
        surveyGroups: Array<{ key: number, title: string, surveys: ScheduledSurveyInList[] }>,
        survey,
      ) => {
        const date = moment.parseZone(survey.beginAt.local)
        const currentSurveyGroupKey = parseInt(`${date.year()}${date.month().toString().padStart(2, "0")}`, 10)
        const monthGroup = surveyGroups.find(group => group.key === currentSurveyGroupKey)

        if (monthGroup) {
          monthGroup.surveys.push(survey)
        } else {
          surveyGroups.push({
            key: currentSurveyGroupKey,
            surveys: [ survey ],
            title: `${capitalizedString(date.format("MMMM YYYY"))}`,
          })
        }

        return surveyGroups
      }, new Array<{ key: number, title: string, surveys: ScheduledSurveyInList[] }>())
      .sort((monthGroupLeft, monthGroupRight) => {
        return monthGroupRight.key - monthGroupLeft.key
      })
  }
}

export type ScheduledSurveyInList = SurveyScheduling & { survey: Survey & { theme: Theme } }

interface ScheduledSurveyListState {
  activeSelectedSurvey: ScheduledSurveyInList | null,
  anchorEl: null | HTMLElement,
  hasListReachedInitialScrollPosition: boolean,
  selectedSurveyId?: number,
  scrollPosition: number,
  previouslySelectedId?: number,
  previouslySelectedIndex?: number,
}

export interface ScheduledSurveyListProps {
  /** Render the collapsed version */
  isCollapsed?: boolean,

  /** Loading status */
  isLoading?: boolean,

  /** Surveys to display in the list */
  surveys: ScheduledSurveyInList[],

  /** Id of the selected ScheduledSurvey in the list */
  activeSurveyId?: number,

  /** Sets the initial scroll position to the ScheduledSurvey with the specified ID */
  initialScrollToSurveyId?: number,

  /** Display a small loading indicator at the top of the list */
  isTopLoaderDisplayed?: boolean,

  /** Display a small loading indicator at the bottom of the list */
  isBottomLoaderDisplayed?: boolean,

  /** Called when the list is ready: loaded and initial scroll has been executed */
  onReady?: () => void,

  /** Called when the user scrolls to the bottom of the list */
  onScrollToBottom?: () => void,

  /** Called when the user scrolls to the top of the list */
  onScrollToTop?: () => void,

  /** Is true when a survey was edited */
  isSurveyEdited?: boolean,

  /** Forwarded ref by parent */
  listContainerReference?: React.RefObject<HTMLDivElement>,
}
