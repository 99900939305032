export class AttributeCode {
  static readonly AgeGroup = new AttributeCode("age_group")
  static readonly Gender = new AttributeCode("gender")
  static readonly HierarchicalLevel = new AttributeCode("hierarchical_level")
  static readonly Job = new AttributeCode("job")
  static readonly InterviewModality = new AttributeCode("modalite_de_l_entretien")
  static readonly PrimaryProblematic = new AttributeCode("problematique_primaire")
  static readonly Direction = new AttributeCode("direction")
  static readonly Site = new AttributeCode("site")

  static fromString(code: string): AttributeCode {
    switch (code) {
      case "age_group":
        return AttributeCode.AgeGroup
      case "gender":
        return AttributeCode.Gender
      case "hierarchical_level":
        return AttributeCode.HierarchicalLevel
      case "job":
        return AttributeCode.Job
      case "modalite_de_l_entretien":
        return AttributeCode.InterviewModality
      case "problematique_primaire":
        return AttributeCode.PrimaryProblematic
      case "direction":
        return AttributeCode.Direction
      case "site":
        return AttributeCode.Site
      default:
        throw new Error(`Unknown attribute code: ${code}`)
    }
  }

  static getAllValues(): AttributeCode[] {
    return [
      AttributeCode.AgeGroup,
      AttributeCode.Gender,
      AttributeCode.HierarchicalLevel,
      AttributeCode.Job,
      AttributeCode.InterviewModality,
      AttributeCode.PrimaryProblematic,
      AttributeCode.Direction,
      AttributeCode.Site,
    ]
  }

  protected constructor(public readonly value: string) {}

  public toString(): string {
    return this.value
  }
}
