import { useApolloClient } from "@apollo/client"
import { ApolloError } from "@apollo/client/errors"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import * as React from "react"
import { t } from "ttag"

import {
  CenteredLoader, IconButton, PagedNavigation,
  PrimaryButton, Select, SelectOptionProps, Table, TableRowData,
} from "@humanpredictiveintelligence/myqvt-library"
import { AuthenticationContext } from "features/Authentication"
import { NotificationContext } from "features/Notification"
import { RecipientExportDialog } from "features/Recipients/RecipientExportDialog"
import { RecipientFormDialog, RecipientFormDialogProps } from "../RecipientFormDialog"
import { RecipientsCreatedStepData, useTutorial } from "features/Tutorial"
import { RecipientsImportDialog } from "features/RecipientsImport/RecipientsImportDialog"
import { TUTORIAL_RECIPIENTS_EVENTS } from "features/Tutorial/contents/RecipientsPage/Events"
import { useSession } from "features/Session"
import { useUserPermissions } from "features/Permissions"

import { SkinnyAttributedUser, SkinnyUser, SkinnyUserCharacteristic } from "models/SkinnyUser"
import { SkinnyUserAttributeValue } from "models/SkinnyUserAttributeValue"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import { UserPermissionCode } from "models/generated"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"
import { numberOfPages } from "utilities/helpers"
import business from "config/business"
import * as Styles from "./RecipientsPage.styles"

import { useAddUserMutation } from "graphql/mutations/generated/AddUser"
import {
  useDeleteUsersMutation,
} from "graphql/mutations/generated/DeleteUsers"
import { useExportUsersLazyQuery } from "graphql/queries/generated/ExportUsers"
import { ScopedUsersQuery, useScopedUsersQuery } from "graphql/queries/generated/ScopedUsers"
import { useUpdateUserMutation } from "graphql/mutations/generated/UpdateUser"
import {
  useUserAttributesQuery,
} from "graphql/queries/generated/UserAttributeValues"

export const RecipientsPage = () => {
  const notification = React.useContext(NotificationContext)
  const { getUserSession } = React.useContext(AuthenticationContext)
  const session = useSession()
  const client = useApolloClient()
  const isUserAllowed = useUserPermissions()
  const {
    setStepData,
    getStepData,
    triggerTutorialEvent,
    setStepTarget,
    tutorialState,
    activeStep,
    whenTutorialActiveRun,
    skipStep,
  } = useTutorial()

  const isAllowedToManageRecipients = isUserAllowed(UserPermissionCode.RecipientsManage)

  const [ tablePagination, setTablePagination ] = React.useState({
    currentPage: 1,
    loadingOffset: 0,
    pageSize: business.recipients.tablePageSizes[0],
  })
  const [ isFormOpen, setFormOpen ] = React.useState<boolean>(false)
  const [ isExportModelOpen, setExportModelOpen ] = React.useState<boolean>(false)
  const [ isImportModalOpen, setImportModalOpen ] = React.useState<boolean>(false)
  const [ selectedRecipientIds, setSelectedRecipientsIds ] = React.useState<number[]>([])
  const [ areAllSelected, setAllSelected ] = React.useState<boolean>(false)
  const [ recipientBeingEdited, setRecipientBeingEdited ] = React.useState<RecipientBeingEdited>(undefined)
  const [ displayedAttributes, setDisplayedAttributes ] =
    React.useState<SkinnyUserCharacteristic[]>(initiallyDisplayedAttributes())
  const userCharacteristicsDisplayNames = userCharacteristicTranslations()
  const [
    isDeleteRecipientConfirmationDisplayed,
    setDeleteRecipientConfirmationDisplayed,
  ] = React.useState<boolean>(false)
  const [ isRecipientBeingAdded, setIsRecipientBeingAdded ] = React.useState(false)

  const { loading: isLoadingUserAttributes, data: userAttributesData }
    = useUserAttributesQuery()

  const [ recipientsData, setRecipientsData ] = React.useState<ScopedUsersQuery>()

  const {
    loading: isLoadingRecipients,
    data: recipientsFetch,
    error: loadingRecipientsError,
    refetch: refetchRecipientsList,
  } = useScopedUsersQuery({
    fetchPolicy: "network-only",
    variables: { limit: tablePagination.pageSize, skip: tablePagination.loadingOffset },
  })

  const recipientFormDialogRef = React.createRef<HTMLDivElement>()

  React.useEffect(() => {
    if (!isLoadingRecipients) {
      setStepTarget("recipients-list", "[role=row]:first-child")
    }
    // eslint-disable-next-line
  }, [ isLoadingRecipients, tutorialState ])

  React.useEffect(() => {
    if (recipientsFetch) {
      setRecipientsData(recipientsFetch)
    }
  }, [ recipientsFetch ])

  /**
   * Set the target element for the tutorial step `recipient-create` dynamically
   */
  React.useEffect(() => {
    whenTutorialActiveRun(() => {
      if (recipientFormDialogRef.current !== null && isFormOpen) {
        setStepTarget("recipients-create", recipientFormDialogRef.current)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ setStepTarget, isFormOpen, recipientFormDialogRef ])

  /**
   * Close the form dialog if the active step of the tutorial is not the matching step
   */
  React.useEffect(() => {
    whenTutorialActiveRun(() => {
      if (activeStep?.id !== "recipients-create") {
        setFormOpen(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeStep ])

  const [ exportUsersQuery ] = useExportUsersLazyQuery({
    fetchPolicy: "network-only",
  })

  const [ deleteUsers ] = useDeleteUsersMutation()
  const [ updateUser ] = useUpdateUserMutation()
  const [ addUser ] = useAddUserMutation()

  const userAttributes = USER_ATTRIBUTE_SERVICE.attributesFromAttributeValues(
    (userAttributesData && userAttributesData.attributeValues) || Array<SkinnyUserAttributeValue>(),
    true,
  )

  const me = recipientsData && recipientsData.me
  const recipients: Array<SkinnyAttributedUser<SkinnyUserAttributeValue>> =
    me && me.users && me.users.list && me.users.list.length
      ? me.users.list
      : Array<SkinnyAttributedUser<SkinnyUserAttributeValue>>()

  const recipientsTotalCount = me && me.users?.count
  const recipientRows = recipientRowsData(recipients)

  return (
    <Styles.Container>
      {(isLoadingRecipients || isLoadingUserAttributes) && recipientsTotalCount === undefined && (
        // Initial data is loading
        <CenteredLoader isTransparent/>
      )}

      {!loadingRecipientsError && recipientsData && (
        <>
          {isFormOpen && (
            <RecipientFormDialog
              innerRef={recipientFormDialogRef}
              onValidate={onValidateCreateRecipient(recipientBeingEdited)}
              onCancel={() => whenTutorialActiveRun<() => void>(skipStep, closeAddRecipientForm)}
              defaultRecipient={recipientBeingEdited}
              userAttributes={
                userAttributes.filter(attribute => !attribute.isHierarchical)
              }
              userHierarchy={
                userAttributes.filter(attribute => attribute.isHierarchical)
              }
              isLoading={isRecipientBeingAdded}
            />
          )}

          {isExportModelOpen && (
            <RecipientExportDialog onClose={() => setExportModelOpen(false)}/>
          )}

          {isImportModalOpen && (
            <RecipientsImportDialog
              onDialogClose={() => setImportModalOpen(false)}
              refetchRecipientList={refetchRecipientsList}
            />
          )}

          {deleteRecipientDialog(recipients)}
          <Styles.HeaderActions>
            <IconButton
              icon="delete"
              size="big"
              isInverted
              isDisabled={selectedRecipientIds.length === 0 || !isAllowedToManageRecipients}
              onClick={() => setDeleteRecipientConfirmationDisplayed(true)}
              tooltip={
                !isAllowedToManageRecipients
                  ? t`You don't have sufficient rights to perform this action`
                  : undefined
              }
              tooltipPlacement={"top"}
              isAllowed={isAllowedToManageRecipients}
            />
            <Styles.FilterSelect
              isMultiselect
              onMultiChange={updateDisplayedAttributes}
              options={displayedAttributesSelectOptions()}
              value={displayedAttributes}
              placeholder={t`Select a column to display`}
            />
            <Styles.HeaderPrimaryActions>
              {session.customer?.freemiumCreatorEmail && (
                <IconButton
                  aria-label={t`Import users`}
                  icon={"publish"}
                  onClick={() => {
                    setImportModalOpen(true)
                  }}
                  isDisabled={!isAllowedToManageRecipients}
                  isAllowed={isAllowedToManageRecipients}
                  tooltip={
                    !isAllowedToManageRecipients
                      ? t`You don't have sufficient rights to perform this action`
                      : undefined
                  }
                  tooltipPlacement={"top"}
                >
                  {t`Import users`}
                </IconButton>
              )}
              <IconButton
                aria-label={t`Add a user`}
                className="RecipientsPage__add-recipient-action"
                icon={"person_add"}
                onClick={() => {
                  openAddRecipientForm()
                  triggerTutorialEvent(TUTORIAL_RECIPIENTS_EVENTS.recipientsCreateInitialized())
                }}
                isDisabled={!isAllowedToManageRecipients}
                isAllowed={isAllowedToManageRecipients}
                tooltip={
                  !isAllowedToManageRecipients
                    ? t`You don't have sufficient rights to perform this action`
                    : undefined
                }
                tooltipPlacement={"top"}
              >
                {t`Add a user`}
              </IconButton>
              <Styles.ExportAction
                aria-label={t`Export all`}
                iconClassName="ExportAction__icon"
                icon={"publish"}
                onClick={exportUsers}
              >
                {t`Export all`}
              </Styles.ExportAction>
            </Styles.HeaderPrimaryActions>
          </Styles.HeaderActions>

          {recipientsTotalCount !== undefined && recipientsTotalCount > 0 && (
            <>
              <Table
                isLoading={isLoadingRecipients}
                withShadow
                skeletonCountRow={tablePagination.pageSize}
                skeletonCountColumn={displayedAttributes.length + 2}
                dataList={recipientRows}
                onSelect={(selectedId) => selectRecipient(Number(selectedId))}
                onSelectAll={selectAllRecipientsInPage}
                columns={[
                  SkinnyUserCharacteristic.lastname,
                  SkinnyUserCharacteristic.firstname,
                  ...displayedAttributes,
                ]}
                columnDisplayNames={userCharacteristicsDisplayNames}
                selectedIds={selectedRecipientIds.map(String)}
                onActionClicked={(recipientId) => editRecipient(Number(recipientId))}
                areAllSelected={areAllSelected}
                areActionsDisabled={!isAllowedToManageRecipients}
              />
              <Styles.TablePagination>
                <Select
                  options={
                    business.recipients.tablePageSizes.map(size => ({
                      value: size,
                      wording: size.toString(),
                    }))
                  }
                  onChange={updateTableSize}
                  value={tablePagination.pageSize}
                  defaultItem={false}
                />
                <PagedNavigation
                  isLoadingData={isLoadingRecipients}
                  page={tablePagination.currentPage}
                  numberOfPages={numberOfPages(
                    recipientsTotalCount ?? 0,
                    tablePagination.pageSize,
                  )}
                  onNavigate={updateTablePage}
                />
              </Styles.TablePagination>
            </>
          )}
        </>
      )}
      {!isLoadingRecipients && recipientsTotalCount !== undefined && recipientsTotalCount === 0 && (
        <Styles.EmptyListMessage.Container>
          <Styles.EmptyListMessage.Title>
            {t`The recipients list is empty...`}
          </Styles.EmptyListMessage.Title>
          <Styles.EmptyListMessage.Content>
            {t`You can add recipients.`}
          </Styles.EmptyListMessage.Content>
        </Styles.EmptyListMessage.Container>
      )}
    </Styles.Container>
  )

  /**
   * Trigger a file download of the list of all users
   */
  function exportUsers() {
    exportUsersQuery()
    setExportModelOpen(true)
  }

  function onValidateCreateRecipient(
    recipientBeingEdited: RecipientBeingEdited,
  ): RecipientFormDialogProps["onValidate"] {
    return (recipient: SkinnyAttributedUser<USER_ATTRIBUTE>, keepFormOpen?: boolean) => {
      // Recipients added are used by the next step of the tutorial
      whenTutorialActiveRun(() => {
        if (activeStep?.id === "recipients-create") {
          const recipients = getStepData<RecipientsCreatedStepData>("recipients-created")?.recipients ?? []
          setStepData<RecipientsCreatedStepData>(
            "recipients-created",
            {
              recipients: [ ...recipients, recipient ],
            },
          )
        }
      })
      if (recipientBeingEdited) {
        updateRecipient(recipient)
      }
      else {
        addRecipient(recipient, keepFormOpen)
      }
    }
  }

  /**
   * Get an array of TableRowData, one for each recipient given
   * @param recipientsList Recipients for which to create rows
   */
  function recipientRowsData(recipientsList: Array<SkinnyAttributedUser<SkinnyUserAttributeValue>>): TableRowData[] {
    if (!recipientsList) {
      return []
    }

    return recipientsList.map(user => {
      const userValues = new Map<string, string>()

      Object.entries(user)
        .forEach(([ propertyName, propertyValue ]) => {
          if (propertyName === "attributes") {
            (propertyValue as SkinnyUserAttributeValue[])
              .forEach(attribute => userValues.set(
                attribute.name,
                attribute.value,
              ))
          }
          else if (propertyName !== "id") {
            userValues.set(propertyName, propertyValue)
          }
        })
      Object.entries(user).forEach(([ propertyName, propertyValue ]) => {
        if (propertyName === "attributes") {
          (propertyValue as SkinnyUserAttributeValue[])
            .forEach(attribute => userValues.set(
              attribute.name,
              attribute.value,
            ))
        }
        else if (propertyName === "language") {
          userValues.set(propertyName, propertyValue.translatedLabel)
        }
        else if (propertyName !== "id") {
          userValues.set(propertyName, propertyValue)
        }
      })

      return {
        id: user.id.toString(),
        values: userValues,
      }
    })
  }

  /**
   * Select or unselect all recipients in the page
   * @param event Click event that triggered the action
   * @param checked Whether the user selected the recipients on the page
   */
  function selectAllRecipientsInPage(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    if (!checked || selectedRecipientIds.length === recipientRows.length) {
      setSelectedRecipientsIds([])
      setAllSelected(false)
    }
    else {
      setSelectedRecipientsIds(recipientRows.map(recipient => Number(recipient.id)))
      setAllSelected(true)
    }
  }

  /**
   * Show update form
   * @param recipientId Id of the recipient to edit
   */
  function editRecipient(recipientId: number) {
    setRecipientBeingEdited(
      recipients.find(recipient => recipient.id === recipientId),
    )
    openAddRecipientForm()
  }

  /**
   * Create a new recipient in the backend
   * @param recipient Recipient to create
   * @param shouldEditAnotherRecipient Whether to keep the form open after submit
   */
  async function addRecipient(
    recipient: Exclude<SkinnyUser, "attributes"> & { attributes: USER_ATTRIBUTE[] },
    shouldEditAnotherRecipient?: boolean,
  ) {
    setIsRecipientBeingAdded(true)

    try {
      await addUser({
        variables: {
          attributesIds: recipient.attributes
            .flatMap(attribute => attribute.values.map(value => value.id)),
          email: recipient[SkinnyUserCharacteristic.email],
          firstname: recipient[SkinnyUserCharacteristic.firstname] ?? "",
          language: recipient.language.code,
          lastname: recipient[SkinnyUserCharacteristic.lastname] ?? "",
          phoneNumber: recipient[SkinnyUserCharacteristic.phone],
          timezone: recipient.timezone,
        },
      })

      notification.show(t`Success`, t`The recipient has been successfully added`)
      updateTableSize(undefined)
      closeAddRecipientForm()

      if (shouldEditAnotherRecipient) {
        openAddRecipientForm()
      }

      if (me) {
        client.cache.evict({ id: client.cache.identify(me) })
      }

      refetchRecipientsList()
      getUserSession()
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
    setIsRecipientBeingAdded(false)
  }

  /**
   * Update an existing recipient in the backend
   * @param recipient Recipient to update
   */
  async function updateRecipient(recipient: SkinnyAttributedUser<USER_ATTRIBUTE>) {
    setIsRecipientBeingAdded(true)

    try {
      await updateUser({
        variables: {
          attributesIds: recipient.attributes
            .flatMap(attribute => attribute.values.map(value => value.id)),
          email: recipient[SkinnyUserCharacteristic.email],
          firstname: recipient[SkinnyUserCharacteristic.firstname],
          language: recipient.language.code,
          lastname: recipient[SkinnyUserCharacteristic.lastname],
          phoneNumber: session.customer!.canSendSMS
            ? recipient[SkinnyUserCharacteristic.phone]
            : undefined,
          timezone: recipient.timezone,
          userId: recipient.id,
        },
      })

      closeAddRecipientForm()
      getUserSession()
      notification.show(t`Success`, t`The recipient has been successfully updated`)
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
    setIsRecipientBeingAdded(false)
  }

  /**
   * Delete a set of users from the backend
   * @param recipientIds Collection of IDs of users to delete
   */
  async function deleteRecipient(recipientIds: number[]) {
    setDeleteRecipientConfirmationDisplayed(false)

    try {
      await deleteUsers({
        variables: {
          ids: recipientIds,
        },
      })

      notification.show(t`Success`, t`The recipient has been successfully deleted`)
      updateTableSize(undefined)
      setSelectedRecipientsIds([])
      setAllSelected(false)
      if (
        recipientIds.length === recipients.length
        && tablePagination.currentPage > 1
      ) {
        const newLoadingOffset =
          tablePagination.loadingOffset - tablePagination.pageSize
        setTablePagination({
          ...tablePagination,
          currentPage: tablePagination.currentPage - 1,
          loadingOffset: newLoadingOffset,
        })
      }
      await refetchRecipientsList()
      await getUserSession()
    }
    catch (error) {
      if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      }
    }
  }

  /**
   * Dialog requesting user to confirm or cancel destructive delete action
   * @param recipientsInfo Collection of users to delete
   */
  function deleteRecipientDialog(recipientsInfo: SkinnyUser[]) {
    const singleRecipient = selectedRecipientIds.length === 1
      ? recipientsInfo.find(recipient => recipient.id === selectedRecipientIds[0])
      : undefined

    const singleRecipientFirstName = singleRecipient && singleRecipient.firstname
    const singleRecipientLastName = singleRecipient && singleRecipient.lastname

    if (isDeleteRecipientConfirmationDisplayed) {
      return (
        <Dialog open>
          <DialogTitle disableTypography>
            {singleRecipient
              ? t`Are you sure you want to delete this recipient?`
              // eslint-disable-next-line max-len
              : t`Are you sure you want to delete ${selectedRecipientIds.length} recipients?`
            }
          </DialogTitle>
          <DialogContent>
            <div>
              {singleRecipient
                // eslint-disable-next-line max-len
                ? t`After deletion, this recipient won't be able to access surveys anymore:
          ${singleRecipientFirstName} ${singleRecipientLastName}
          `
                // eslint-disable-next-line max-len
                : t`After deletion, the ${selectedRecipientIds.length} recipients you selected won't be able to access any survey anymore`
              }
            </div>
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              isInverted
              onClick={() => setDeleteRecipientConfirmationDisplayed(false)}
            >
              {t`Cancel`}
            </PrimaryButton>
            <PrimaryButton
              destructive
              onClick={() => deleteRecipient(selectedRecipientIds)}
            >
              {t`Delete`}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
      )
    }
  }

  /**
   * Return the options of the displayed attributes select
   */
  function displayedAttributesSelectOptions(): SelectOptionProps[] {
    const userCharacteristics = Object.keys(SkinnyUserCharacteristic)
      .filter(characteristic => (
        characteristic !== SkinnyUserCharacteristic.firstname
        && characteristic !== SkinnyUserCharacteristic.lastname
        && !(
          characteristic === SkinnyUserCharacteristic.phone
          && !session.customer!.canSendSMS
        )
      ))
      .map(characteristic => {
        return {
          value: characteristic,
          wording: userCharacteristicsDisplayNames.get(characteristic) || characteristic,
        }
      })

    return [
      ...userCharacteristics,
      ...userAttributes.map(attribute => ({
        value: attribute.name,
        wording: attribute.name,
      })),
    ]
  }

  /**
   * Display an empty RecipientFormDialog
   */
  function openAddRecipientForm() {
    setFormOpen(true)
  }

  /**
   * Close the RecipientFormDialog
   */
  function closeAddRecipientForm() {
    setRecipientBeingEdited(undefined)
    setFormOpen(false)
  }

  /**
   * Select or deselect a recipient
   * @param selectedId ID of the recipient to select
   */
  function selectRecipient(selectedId: number) {
    if (selectedId) {
      const selectedIdIndex = selectedRecipientIds.indexOf(selectedId)

      if (selectedIdIndex !== -1) {
        selectedRecipientIds.splice(selectedIdIndex, 1)
        setSelectedRecipientsIds([ ...selectedRecipientIds ])
      }
      else {
        setSelectedRecipientsIds([ ...selectedRecipientIds, selectedId ])
      }
    }
  }

  /**
   * Update the list of attributes to display in the table
   * @param selection Selected attributes to display
   */
  function updateDisplayedAttributes(selection: SelectOptionProps[]) {
    setDisplayedAttributes(
      selection
        ? selection.map(option => option.value as SkinnyUserCharacteristic)
        : [],
    )
  }

  /**
   * Update the current page of the table
   * @param page Selected page
   * @param pagesCount Total number of pages
   */
  function updateTablePage(page: number, pagesCount: number) {
    if (page === 0 || page === pagesCount + 1) {
      return
    }
    else {
      setTablePagination(previousPagination => ({
        ...previousPagination,
        currentPage: page,
        loadingOffset: previousPagination.pageSize * (page - 1),
      }))

      setSelectedRecipientsIds([])
      setAllSelected(false)
    }
  }

  /**
   * Update the table page size
   * @param sizeOption Page size option selected
   */
  function updateTableSize(sizeOption: SelectOptionProps | undefined) {
    if (sizeOption) {
      setTablePagination({
        currentPage: 1,
        loadingOffset: 0,
        pageSize: sizeOption.value as number,
      })
    }
  }

  /**
   * Get translations for user characteristics
   */
  function userCharacteristicTranslations() {
    const translations = new Map([
      [ "email", t`Email` ],
      [ "firstname", t`First name` ],
      [ "language", t`Language` ],
      [ "lastname", t`Last name` ],
    ])

    if (session.customer?.canSendSMS) {
      translations.set("phone", t`Phone`)
    }

    return translations
  }

  /**
   * Get the displayedAttributes select initial values
   */
  function initiallyDisplayedAttributes() {
    const characteristics = [ SkinnyUserCharacteristic.email ]
    if (session.customer?.canSendSMS) {
      characteristics.push(SkinnyUserCharacteristic.phone)
    }

    characteristics.push(SkinnyUserCharacteristic.language)

    return characteristics
  }
}

type RecipientBeingEdited = SkinnyAttributedUser<SkinnyUserAttributeValue> | undefined
