/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
export type SkinnySurveySchedulingFragmentFragment = { survey_id: number, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status, utc: Types.Scheduled_Survey_Status }, begin_at: { local: string, earliest?: string | undefined, latest?: string | undefined }, end_at: { local: string, earliest?: string | undefined, latest?: string | undefined } };

export const SkinnySurveySchedulingFragmentFragmentDoc = gql`
    fragment SkinnySurveySchedulingFragment on Survey_scheduling {
  survey_id
  status {
    absolute
    local
    utc
  }
  begin_at {
    local
    earliest
    latest
  }
  end_at {
    local
    earliest
    latest
  }
}
    `;