import styled from "styled-components/macro"

import { CenteredLoader } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div<{ $borderColor: string }>`
  width: 50px;
  height: 50px;
  margin: 0 24px;
  
  display:flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 50px;
  border: 2px solid ${({ $borderColor }) => $borderColor};
 
`

export const OngoingLoader = styled(CenteredLoader)`
  & .CenteredLoader__svg {
    width: 35px;
    height: 35px;
  }
`
