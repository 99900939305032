import styled, { css } from "styled-components/macro"
import { Title } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`

export const SurveysList = styled.div<{ $isCollapsed: boolean }>`
  ${({ theme, $isCollapsed }) => css`
    display: flex;
    flex-direction: column;
    width: ${theme.sizes.surveyListWidth};
    z-index: 1;
    background-color:white;
    box-shadow: 0px 10px 6px 0 rgba(0,0,0,0.20);
    position: fixed;
    height: calc(100% - ${theme.sizes.tabsNavigationHeight});
    
    ${$isCollapsed && css`
      padding: 0;
      justify-items: center;
      width: ${theme.sizes.surveyListWidthCollapsed};
      
      ${SurveysListTitle} {
        display: none;
      }
      
      ${SurveysListHeaderContainer} {
        padding: 10px 0;
      }
    `}
    
    @media print {
      display: none;
    }
  `}
`

export const SurveysListHeaderContainer = styled.div`
  padding: 0 40px 40px 40px;
  display: flex;
  justify-content: center;
  box-shadow: ${({ theme }) => theme.shadows.bottom};
  z-index: 2;
`

export const SurveysListTitle = styled(Title)``

export const SurveyDetails = styled.div<{ $isEmpty: boolean }>`
  ${({ theme, $isEmpty }) => css`
    width: 100%;
    overflow: auto;
    padding-left: ${theme.sizes.surveyListWidthCollapsed};

    ${$isEmpty && css`
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    `}
    
    ${theme.mixins.landscape} {
      padding-left: ${theme.sizes.surveyListWidth};
    }
    
    ${theme.mixins.reducedLandscapeAndPortrait} {
      min-width: 850px;
    }
    
    @media print {
      padding-left: 0;
      min-width: 0;
    }
  `}
`
