import styled, { css } from "styled-components/macro"
import { ScheduledSurveyInfo } from "features/Surveys/ScheduledSurveyInfo"
import { EllipsedText } from "@humanpredictiveintelligence/myqvt-library"

export const Row = styled.div<{ $isCommentNeutral?: boolean }>`
  display: flex;

  font-size: ${({ theme }) => theme.typography.fontSizeTextSmall};
  line-height: 18px;
  white-space: pre-line;
  
  p {
    margin: 0;
  }
  
  ${({ $isCommentNeutral }) => css`
    ${Question}, ${$isCommentNeutral && Comment}, ${Author} {
      grid-column: auto / span 2;
    }
  `}
`

export const SurveyInfo = styled(ScheduledSurveyInfo)`
  flex-shrink: 0;
  width: 220px;
  margin-right: 16px;
`

export const Content = styled.div`
  display: grid;
  grid-template-rows: min-content minmax(2em, auto) auto;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  row-gap: 8px;
  align-items: flex-start;
`

export const Question = styled.p`
  color: ${({ theme }) => theme.colors.primary};
`

export const Comment = styled(EllipsedText)``

export const Author = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeTextSmaller};
  color: ${({ theme }) => theme.colors.blackMedium};
  user-select: text;
  cursor: text;
`
