import moment from "moment"
import * as React from "react"
import Linkifier from "react-linkifier"
import { t } from "ttag"

import { IconButton, SkeletonWrapper, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import { AnswerSatisfaction, AnswerSatisfactionIcon, getAnswerSatisfaction } from "features/Comments"
import { APPLICATION_URL } from "features/Navigation"
import { CommentContext } from "models/Comment"
import { CommentObjectType } from "models/generated"
import { ScheduledSurveyResultsPageSection } from "models/ScheduledSurveyResultsPageSection"
import { SkinnyUserAttributeValue } from "models/SkinnyUserAttributeValue"
import { BaseCommentsTableRow, BaseCommentsTableRowProps } from "../BaseCommentsTableRow"
import * as Styles from "./ContextualizedCommentsTableRow.styles"

const ContextualizedCommentsTableRowComponent: React.FC<ContextualizedCommentsTableRowProps> = (props) => {
  const simpleProps = {
    comment: props.comment.comment,
    context: props.comment.context,
    creatorAttributeValue: props.comment.creatorAttributeValue,
    creatorDisplayName: props.comment.creator?.displayName,
    id: props.comment.id,
    objectType: props.comment.objectType,
    publishedAt: props.comment.publishedAt,
  }

  const context = simpleProps.context

  let question: string | undefined = undefined
  if (simpleProps.objectType === CommentObjectType.Response) {
    question = context?.question?.name
  }
  else if (simpleProps.objectType === CommentObjectType.SurveyParticipation) {
    question = t`End survey comment`
  }

  const responseDisplay = props.responseDisplay
    ? props.responseDisplay
    : (simpleProps.objectType === CommentObjectType.Response ? "display" : "blank")

  const answerSatisfaction = getAnswerSatisfaction(context?.response?.choice)

  const resultsPageSection
    = simpleProps.objectType === CommentObjectType.SurveyParticipation
      ? ScheduledSurveyResultsPageSection.CollaboratorsFeedback
      : ScheduledSurveyResultsPageSection.QuestionPrefix + context?.question?.id

  let linkToResults: string | undefined
  if (context?.scheduledSurvey && props.isGlobalComment) {
    linkToResults = APPLICATION_URL.scheduledSurvey(
      context?.scheduledSurvey.schedulingId,
      resultsPageSection,
      simpleProps.id,
    )
  }

  return (
    <BaseCommentsTableRow
      {...props}
      callToAction={linkToResults &&
      <IconButton icon="arrow_forward" isInverted size="big" link={linkToResults} aria-label={t`access to comment`}/>
      }
      responseDisplay={responseDisplay}
      isExpandIconHidden={!!linkToResults}
      isHoverReactive
    >
      {(isExpanded) => {
        return (
          <Styles.Row $isCommentNeutral={answerSatisfaction === AnswerSatisfaction.Neutral}>
            {context?.scheduledSurvey && (
              <Styles.SurveyInfo
                scheduling={context.scheduledSurvey}
                survey={context.scheduledSurvey.survey}
                surveyTheme={context.scheduledSurvey.survey.theme}
                isCompact
                withStatus={false}
              />
            )}
            <Styles.Content>
              <Styles.Question>{question}</Styles.Question>
              <AnswerSatisfactionIcon
                size={13}
                choice={context?.response?.choice}
              />
              <Linkifier renderer={clickableUrl}>
                <Styles.Comment isEllipsed={!isExpanded}>
                  {simpleProps.comment}
                </Styles.Comment>
              </Linkifier>
              <Styles.Author>
                {simpleProps.creatorDisplayName || t`Anonymous`}
                {creatorTitle(simpleProps.creatorAttributeValue)}
                {publishingDate(simpleProps.publishedAt)}
              </Styles.Author>
            </Styles.Content>
          </Styles.Row>
        )
      }}
    </BaseCommentsTableRow>
  )

  function clickableUrl(link: { href: string, children: React.ReactNode }) {
    return (
      <a href={link.href} target="_blank" rel="noopener noreferrer">
        {link.children}
      </a>
    )
  }

  /**
   * Get the publishing date to display, if any
   */
  function publishingDate(publishedAt?: string): string | undefined {
    if (publishedAt) {
      const publishingDate = moment(publishedAt)
      const date = publishingDate.format("L")
      const time = publishingDate.format("LT")

      return " – " + t`On ${date} at ${time}`
    }
  }

  /**
   * Get the title of the comment's creator, if any
   */
  function creatorTitle(creatorAttributeValue?: SkinnyUserAttributeValue): string | undefined {
    if (creatorAttributeValue) {
      return ` – ${creatorAttributeValue.value}`
    }
  }
}

const ContextualizedCommentsTableRowSkeleton: React.FC<ContextualizedCommentsTableRowSkeletonProps> = (props) => {
  return (
    <BaseCommentsTableRow
      {...props}
      callToAction={<SkeletonWrapper circle={true} height={50} width={50} isLoading/>}
      isLoading
      responseDisplay={"display"}
      isExpandIconHidden
      isHoverReactive
    >
      {(isExpanded) => {
        return (
          <Styles.Row>
            <Styles.SurveyInfo isLoading/>
            <Styles.Content>
              <Styles.Question>
                <SkeletonWrapper width={500} isLoading/>
              </Styles.Question>
              <Styles.Comment isEllipsed={!isExpanded}>
                <SkeletonWrapper isLoading/>
              </Styles.Comment>
              <Styles.Author>
                <SkeletonWrapper isLoading/>
              </Styles.Author>
            </Styles.Content>
          </Styles.Row>
        )
      }}
    </BaseCommentsTableRow>
  )
}

export const ContextualizedCommentsTableRow = withSkeleton(
  ContextualizedCommentsTableRowComponent,
  ContextualizedCommentsTableRowSkeleton,
)

export type ContextualizedCommentsTableRowSkeletonProps = {
  responseDisplay: BaseCommentsTableRowProps["responseDisplay"],
}

export type ContextualizedCommentsTableRowProps = Omit<BaseCommentsTableRowProps, "children"> & {
  comment: BaseCommentsTableRowProps["comment"] & CommentContext,
  isGlobalComment?: boolean,
}
