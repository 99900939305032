import outdatedBrowser from "outdated-browser-rework"
import outdatedBrowserMessages from "features/OutdatedBrowser/outdatedBrowserMessages.json"
import outdatedBrowserCompatibility from "features/OutdatedBrowser/outdatedBrowserCompatibility.json"

function OutdatedBrowserDetector(): void {
  return outdatedBrowser({
    browserSupport: outdatedBrowserCompatibility,
    language: window.navigator.language,
    messages: outdatedBrowserMessages,
  })
}

export { OutdatedBrowserDetector }
