export interface UserGroupsState {
  areUsersSelected: boolean,
  areUsersSelectedInGroup: boolean,
  displayedNumberOfUsers: number,
  displayedNumberOfUsersInGroup: number,
  selectedGroupUuid: string,
  selectedUsersIds: number[],
  selectedUsersInGroupIds: number[],
  shouldGoToPreviousUserGroupPage: boolean,
  shouldGoToPreviousUserPage: boolean,
  shouldRefetchUsers: boolean,
}

export const initialUserGroupState: UserGroupsState = {
  areUsersSelected: false,
  areUsersSelectedInGroup: false,
  displayedNumberOfUsers: 10,
  displayedNumberOfUsersInGroup: 10,
  selectedGroupUuid: "",
  selectedUsersIds: [],
  selectedUsersInGroupIds: [],
  shouldGoToPreviousUserGroupPage: false,
  shouldGoToPreviousUserPage: false,
  shouldRefetchUsers: false,
}

