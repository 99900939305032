import { Props } from "react-joyride"

import { TutorialStepData, TutorialTarget } from "features/Tutorial/TutorialContext"
import { Tutorial, TutorialStep } from "features/Tutorial/models"

export interface TutorialState extends Props {
  stepData: TutorialStepData,
  isClosingTutorial: boolean,
  isSkippingStep: boolean,
  currentTarget?: TutorialTarget,
  partsCount: number,
  stepIndex: number,
  steps: TutorialStep[],
  id: string,
  tutorial: Tutorial | undefined,
  nextTarget?: TutorialTarget,
  stepTargets: {
    [key: string]: TutorialTarget,
  },
  runJoyRide: boolean,
}

export const TUTORIAL_INITIAL_STATE: TutorialState = {
  continuous: true,
  id: "",
  isClosingTutorial: false,
  isSkippingStep: false,
  partsCount: 0,
  run: false,
  runJoyRide: false,
  stepData: {},
  stepIndex: 0,
  stepTargets: {},
  steps: [],
  tutorial: undefined,
}
