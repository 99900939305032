import * as React from "react"
import { t } from "ttag"

import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { ErroredMetricsCard } from "@humanpredictiveintelligence/myqvt-library"

export const ErroredRecipientsMetrics: React.FC<BaseProps> = (props) => (
  <div className={props.className}>
    <ErroredMetricsCard title={t`Users database`}>
      {t`An error has occurred.`}
    </ErroredMetricsCard>
  </div>
)
