import * as React from "react"
import { t } from "ttag"
import { useLocation } from "react-router-dom"

import { APPLICATION_URL, ApplicationPath } from "../ApplicationNavigation"
import { useSession } from "features/Session"
import { usePackages } from "features/Packages"
import { AvatarLetter, MenuItem } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./Menu.styles"

import pimeoLogo from "assets/images/pimeo-logo-w.png"

export const Menu = (props: MenuProps) => {
  const session = useSession()
  const location = useLocation()
  const { getMenuItems } = usePackages()
  const menuItems = getMenuItems()
  // Disable tutorial for now
  // We should find a way to attach events to MenuItems instead of padding an onCLick props across the app
  // const { triggerTutorialEvent } = useTutorial()

  let menuBackground: React.CSSProperties["background"]
  if (!props.menuBackgroundColor && (props.menuBackgroundGradientTopColor || props.menuBackgroundGradientBottomColor)) {
    const gradientFirstColor = props.menuBackgroundGradientTopColor || props.menuBackgroundGradientBottomColor
    const gradientSecondColor = props.menuBackgroundGradientBottomColor || props.menuBackgroundGradientTopColor

    menuBackground = `linear-gradient(-135deg, ${gradientFirstColor} 0%, ${gradientSecondColor} 100%)`
  }

  const menuItemList = React.useMemo(() => menuItems.map((menuItem) => (
    !menuItem.isUnauthorized &&  (
      <Styles.MenuLink
        role="link"
        aria-label={menuItem.label}
        to={menuItem.path}
        key={menuItem.path}
      >
        <MenuItem
          active={location.pathname.includes(menuItem.path.split("/")[1])}
          icon={menuItem.icon ?? ""}
          isImage={menuItem.isIconImage}
          label={menuItem.label ?? ""}
        />
      </Styles.MenuLink>
    ))), [ location.pathname, menuItems ])

  return (
    <Styles.Nav $backgroundColor={props.menuBackgroundColor ?? menuBackground}>
      <Styles.Top>
        <Styles.BrandLogo src={pimeoLogo} alt={t`SpeakUP administration`} />
      </Styles.Top>
      <Styles.Center>
        {menuItemList}
      </Styles.Center>
      <Styles.Bottom>
        {session.authentication.isAuthenticated && (
          <Styles.MenuLink
            role="link"
            aria-label={t`Settings page link`}
            to={APPLICATION_URL.settings()}
          >
            <AvatarLetter
              active={location.pathname === ApplicationPath.Settings}
              name={session.user!.displayName}
            />
          </Styles.MenuLink>
        )}
      </Styles.Bottom>
    </Styles.Nav>
  )
}

export interface MenuProps {
  menuBackgroundColor?: string,
  menuBackgroundGradientTopColor?: string,
  menuBackgroundGradientBottomColor?: string,
}
