import styled from "styled-components/macro"

export const Title = styled.p`
  max-width: 80ch;
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
  margin-bottom: 24px;
`

export const OverwrittenFieldContainer = styled.div`
  margin: 24px 0 0 16px;
`

export const OverwrittenField = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.fontSizeText};
`
