import * as React from "react"

import { SessionReducer } from "./SessionReducer"
import { SessionReducerAction } from "./SessionReducerAction"
import { SessionReducerActions } from "./SessionReducerActions"
import { defaultSession } from "./defaultSession"
import { Session } from "features/Session"

/**
 * Session context holding session data and leveraging a reducer to alter it
 */
const sessionContext = React.createContext<[Session, React.Dispatch<SessionReducerAction>]>([
  defaultSession,
  (action: SessionReducerAction) => { /* Empty */ },
])

/**
 * Component consuming the SessionContext
 */
const sessionContextConsumer = sessionContext.Consumer

/**
 * Component providing the SessionContext to its children
 * @param props
 */
const SessionContextProvider = (props: { children: React.ReactChild }) => {
  return (
    <sessionContext.Provider value={React.useReducer(SessionReducer, defaultSession)}>
      {props.children}
    </sessionContext.Provider>
  )
}

/**
 * Custom hook to use the SessionContext
 */
const useSession = () => {
  const [ state, dispatch ] = React.useContext(sessionContext)

  return {
    actions: SessionReducerActions,
    dispatch,
    ...state,
  }
}

export {
  useSession,
  sessionContext as SessionContext,
  SessionContextProvider as SessionProvider,
  sessionContextConsumer as SessionConsumer,
}
