import styled from "styled-components/macro"
import { SkeletonWrapper } from "@humanpredictiveintelligence/myqvt-library"

export const Value = styled.p <{$for: "meetings" | "beneficiary"}>`
  ${({ theme, $for }) => `
    font-size: ${theme.typography.fontSizeMetricsFigure};
    color: ${$for === "meetings" ? theme.colors.primary : theme.colors.secondary};
    text-align: center;
    width: 100%;
    margin: 0;
  `}
`

export const Heading = styled.div`
  text-align: center;  
`

export const ValueSkeleton = styled(SkeletonWrapper)`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`
