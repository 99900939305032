import * as React from "react"
import {
  BaseProps,
  PagedNavigation,
  Select,
  SelectOptionProps,
  Skeletonable,
  Title,
} from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"

import noReportsImage from "assets/images/illustration_results.png"
import business from "config/business"
import { numberOfPages } from "utilities/helpers"
import { LedReportCard } from "./LedReportCard"
import * as Styles from "./LedReports.styles"

import {
  useGetLedFilesQuery,
} from "graphql/queries/generated/GetLedFiles"

export type Report = {
  nanoId: string,
  title: string,
  createdAt: string,
  contentType: string,
  url: string,
}

export const LedReports: React.FC<Skeletonable<BaseProps>> = () => {
  // Pagination not implemented yet since the api is not ready,
  // be aware to make the changes on updateTableSize and updateTablePage
  const [ tablePagination, setTablePagination ] = React.useState({
    currentPage: 1,
    loadingOffset: 0,
    pageSize: business.led.reports.tablePageSizes[0],
  })

  const [ reports, setReports ] = React.useState({
    count: 0,
    ledFiles: [] as Report[],
  })
  
  const { loading: isLoadingReports, data: reportsData } = useGetLedFilesQuery({
    fetchPolicy: "network-only",
    variables: { limit: tablePagination.pageSize, skip: tablePagination.loadingOffset },
  })

  React.useEffect(() => {
    if (reportsData) {
      setReports({
        count: reportsData?.getLedFiles?.count ?? 0,
        ledFiles: reportsData?.getLedFiles?.ledFiles as Report[],
      })
    }
  }, [ reportsData ])

  const isReportListEmpty = reports &&
    reports?.ledFiles?.length < 1 &&
    !isLoadingReports

  const reportList = React.useMemo(() => {
    if (isLoadingReports && reports?.ledFiles?.length < 1) {
      return [ ...Array(tablePagination.pageSize) ].map((_, index) => (
        <LedReportCard key={index} isLoading/>
      ))
    }
    return reports && reports?.ledFiles?.map((report, index) => (
      <LedReportCard report={report} key={index}/>
    ))
  }, [ isLoadingReports, reports, tablePagination.pageSize ])

  return (
    <>
      <Title level="metricCard">
        {t`My reports`}
      </Title>
      {isReportListEmpty ? (
        <Styles.NoReports>
          <Styles.NoReportsImage src={noReportsImage}/>
          {t`No report has been published yet.`}
        </Styles.NoReports>
      ) : (
        <>
          <Styles.ReportListContainer>
            {reportList}
          </Styles.ReportListContainer>
          <Styles.TablePagination>
            <Select
              options={
                business.led.reports.tablePageSizes.map(size => ({
                  value: size,
                  wording: size.toString(),
                }))
              }
              onChange={updateTableSize}
              value={tablePagination.pageSize}
              defaultItem={false}
              disabled={isLoadingReports}
            />
            <PagedNavigation
              isLoadingData={isLoadingReports}
              page={tablePagination.currentPage}
              numberOfPages={numberOfPages(
                reports.count ?? 0,
                tablePagination.pageSize,
              )}
              onNavigate={updateTablePage}
            />
          </Styles.TablePagination>
        </>
      )}
    </>
  )

  /**
   * Update the table page size
   * @param sizeOption Page size option selected
   */
  function updateTableSize(sizeOption: SelectOptionProps | undefined) {
    if (sizeOption) {
      setTablePagination({
        currentPage: 1,
        loadingOffset: 0,
        pageSize: sizeOption.value as number,
      })
    }
  }

  /**
   * Update the current page of the table
   * @param page Selected page
   * @param pagesCount Total number of pages
   */
  function updateTablePage(page: number, pagesCount: number) {
    if (page === 0 || page === pagesCount + 1) {
      return
    }
    else {
      setTablePagination(previousPagination => ({
        ...previousPagination,
        currentPage: page,
        loadingOffset: previousPagination.pageSize * (page - 1),
      }))
    }
  }
}
