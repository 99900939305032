import React from "react"
import { DialogActions, DialogContent } from "@material-ui/core"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import { ValidationError } from "yup"
import { t } from "ttag"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

import { useSession } from "features/Session"
import { Admin, UpsertAdminInput } from "features/Permissions/models"
import { recipientFormSchema } from "features/Recipients/RecipientFormDialog/formValidationSchema"
import { splitTimezoneName, timezonesByArea } from "utilities/timezone"
import { SkinnyUser, SkinnyUserCharacteristic } from "models/SkinnyUser"
import * as Styles from "./AdminCreationFormDialog.styles"

export const AdminCreationFormDialog: React.FC<AdminCreationFormDialogProps> = props => {
  const session = useSession()

  const [ recipient, setRecipient ] = React.useState<Partial<SkinnyUser>>({
    language: session.customer?.language,
    timezone: session.customer?.timezone,
  })

  const timezonesMap = timezonesByArea()
  const [ timezoneArea, setTimezoneArea ] = React.useState(splitTimezoneName(recipient.timezone || "").area)

  const formValidationErrors = new Map<string, ValidationError>()
  const formErrorsDisplayed = new Map<string, string>()

  try {
    recipientFormSchema.validateSync(
      recipient,
      {
        abortEarly: false,
        context:
          {
            canSendSMS: session.customer!.canSendSMS,
          },
      },
    )
  }
  catch (error) {
    (error as ValidationError).inner.reduce((formErrors, fieldError) => {
      if (fieldError.type !== "required") {
        formErrorsDisplayed.set(fieldError.path, fieldError.message)
      }

      return formErrors.set(fieldError.path, fieldError)
    }, formValidationErrors)
  }

  // Update the values with the props.defaultRecipient
  React.useEffect(() => {
    if (props.defaultRecipient) {
      const recipientCopy = {  ...props.defaultRecipient }

      setRecipient(recipientCopy)
      setTimezoneArea(recipientCopy.timezone.split("/")[0] || "")
    }
    else {
      setRecipient({
        email: props.defaultEmail,
        language: session.customer?.language,
        timezone: session.customer?.timezone,
      })
    }
  }, [ props.defaultRecipient, props.defaultEmail, session.customer ])

  function updateLanguage(value?: string) {
    if (value) {
      const language = {
        code: value,
        localizedLabel: value,
        translatedLabel: value,
      }

      updateRecipient("language", language)
    }
  }

  return (
    <Styles.DialogContainer
      open={props.isOpen}
      classes={{
        paperScrollBody: "Dialog__body",
        scrollBody: "DialogContainer__body",
      }}
      scroll="body"
    >
      <Styles.TitleContainer disableTypography>
        <Styles.DialogTitle>{t`Add an administrator`}</Styles.DialogTitle>
        <Styles.CloseIcon name="close" onClick={props.onCancel}/>
      </Styles.TitleContainer>
      <form>
        <DialogContent>
          {props.defaultRecipient && (
            // eslint-disable-next-line max-len
            <Styles.EditRecipientMessage>
              {t`The address entered already matches the address of a user.\n
              If you edit the info below, it will be overwritten.`}
            </Styles.EditRecipientMessage>
          )}
          <div>
            <Styles.SectionTitle level="section">{t`Identity`}</Styles.SectionTitle>
            <Styles.InputField
              label={t`Lastname`}
              value={(recipient && recipient.lastname) || ""}
              onChange={(value) => updateRecipient(SkinnyUserCharacteristic.lastname, value)}
              isDisabled={props.isLoading}
            />
            <Styles.InputField
              value={(recipient && recipient[SkinnyUserCharacteristic.firstname]) || ""}
              label={t`Firstname`}
              onChange={(value) => updateRecipient(SkinnyUserCharacteristic.firstname, value)}
              isDisabled={props.isLoading}
            />
            <Styles.InputField
              value={(recipient && recipient[SkinnyUserCharacteristic.email]) || ""}
              label={t`Email`}
              onChange={(value) => updateRecipient(SkinnyUserCharacteristic.email, value)}
              isErroneous={formErrorsDisplayed.has("email")}
              isHintErroneous
              hint={formErrorsDisplayed.get("email")}
              isDisabled={props.isLoading || !!props.defaultRecipient || !!props.defaultEmail}
            />
            {session.customer!.canSendSMS && (
              <Styles.InputField
                value={(recipient.phone && parsePhoneNumber(recipient.phone)) || ""}
                label={t`Phone number`}
                onChange={(value) => updateRecipient(SkinnyUserCharacteristic.phone, value)}
                isErroneous={formErrorsDisplayed.has("phone")}
                isHintErroneous
                hint={formErrorsDisplayed.get("phone")}
                isDisabled={props.isLoading || !!props.defaultRecipient}
              />
            )}
            <Styles.SelectField
              label={t`Language`}
              value={recipient?.language?.code}
              options={session.languages.map(
                language => ({ value: language.code, wording: language.translatedLabel }),
              )}
              onChange={(value) => {
                updateLanguage(value && value.value as string)
              }}
              placeholder={t`Select`}
              isFullWidth
              disabled={props.isLoading}
            />
            <Styles.TimeZoneField>
              <Styles.SelectField
                label={t`Timezone area`}
                value={timezoneArea}
                options={Array.from(timezonesMap.keys()).map(tz => ({ value: tz, wording: tz }))}
                onChange={(value) => {
                  updateRecipient("timezone", undefined)
                  setTimezoneArea(value?.wording as string || "")
                }}
                placeholder={t`Select`}
                isFullWidth
                disabled={props.isLoading}
              />
              <Styles.SelectField
                label={t`Timezone location`}
                value={(recipient && recipient.timezone) || session.customer?.timezone || ""}
                disabled={!timezonesMap.has(timezoneArea) || props.isLoading}
                options={
                  (timezonesMap.has(timezoneArea) && timezonesMap.get(timezoneArea)!.map(
                    location => ({ value: `${timezoneArea}/${location}`, wording: location }),
                  )) || []
                }
                onChange={
                  (value) => updateRecipient("timezone", value && value.value as string)
                }
                placeholder={t`Select`}
                isFullWidth
              />
            </Styles.TimeZoneField>
          </div>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            onClick={props.onBackButtonClicked}
            disabled={props.isLoading}
            isInverted
          >
            {t`Back`}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => onValidate(recipient)}
            disabled={formValidationErrors.size > 0 || props.isLoading}
            isLoading={props.isLoading}
          >
            {t`Add`}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Styles.DialogContainer>
  )

  /**
   * Update a characteristic on the Recipient
   * @param characteristic Characteristic to update on the Recipient
   * @param value Value to set
   */
  function updateRecipient<Characteristic extends keyof SkinnyUser>(
    characteristic: Characteristic,
    value?: SkinnyUser[Characteristic],
  ) {
    setRecipient(Object.assign({}, recipient, {
      [characteristic]: value,
    }))
  }

  /**
   * Parse international phone number from input value
   * @param value Phone input value
   */
  function parsePhoneNumber(value: string): string {
    const phoneNumber = parsePhoneNumberFromString(value)

    return phoneNumber ? phoneNumber.number.toString() : value
  }

  /** Fired when the user validates the form
   * @param recipient Recipient to create or edit
   * @param isAddAnotherButtonClicked Whether the add another button is clicked
   *
   * */
  function onValidate(recipient: Partial<SkinnyUser>, isAddAnotherButtonClicked = false) {
    if (isValidInput(recipient)) {
      props.onValidate(recipient)
    }
  }

  function isValidInput(recipient: Partial<SkinnyUser>): recipient is UpsertAdminInput {
    try {
      recipientFormSchema.validateSync(
        recipient,
        { abortEarly: false, context: { canSendSMS: session.customer!.canSendSMS } },
      )

      return true
    }
    catch (error) {
      return false
    }
  }
}

export interface AdminCreationFormDialogProps {
  /** Ref to the dialog */
  innerRef?: React.RefObject<HTMLDivElement>,

  isOpen: boolean,

  /** Fired when the user cancels the form */
  onCancel: () => void,

  /** Whether to disable inputs and show button loader */
  isLoading?: boolean,

  /** Default value to fill the form */
  defaultRecipient?: Admin | SkinnyUser,

  defaultEmail?: string,

  /** Fired when the user validates the form */
  onValidate: (formInput: UpsertAdminInput) => void,

  onBackButtonClicked: () => void,
}
