import * as React from "react"
import { useHistory, useLocation } from "react-router"
import { t } from "ttag"

import { Error } from "../Error"
import { ApplicationPath } from "features/Navigation"

import * as Styles from "./ErrorPage.styles"

export const ErrorPage = () => {
  const history = useHistory()
  const location = useLocation<ErrorPageRouteState>()

  let { message, redirectLabel } = componentTranslations()
  let redirect = ApplicationPath.Login

  if (location.state) {
    ({ message, redirectLabel, redirect } = location.state)
  }

  return (
    <Styles.CardWrapper>
      <Error
        proceedLabel={redirectLabel}
        onProceed={() => {history.push(redirect)}}
        message={message}
      />
    </Styles.CardWrapper>
  )

  function componentTranslations() {
    return {
      message: t`An unexpected error has occured.`,
      redirectLabel: t`Sign in`,
    }
  }
}

export interface ErrorPageRouteState {
  /** Error message to show */
  message: string,

  /** Redirect route on the CTA button */
  redirect: ApplicationPath,

  /** CTA button text */
  redirectLabel: string,
}
