import * as React from "react"
import { useTheme } from "styled-components"
import { t } from "ttag"

import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { Scheduled_Survey_Status as ScheduledSurveyStatusModel } from "models/generated/index"
import * as Styles from "./ScheduledSurveyCard.styles"

/**
 * Get the status label and icon to display for the survey
 */
const ScheduledSurveyStatus: React.FC<ScheduledSurveyStatusProps> = (props) => {
  const theme = useTheme()

  const statusesMap = {
    [ScheduledSurveyStatusModel.Done]: {
      color: theme.colors.success,
      icon: "done",
      title: t`Closed`,
    },
    [ScheduledSurveyStatusModel.Ongoing]: {
      color: theme.colors.secondary,
      icon: "autorenew",
      title: t`Ongoing`,
    },
    [ScheduledSurveyStatusModel.Todo]: {
      color: theme.colors.blackMedium,
      icon: "date_range",
      title: t`Scheduled`,
    },
  }

  const status = statusesMap[props.status]

  return (
    <Styles.StatusWrapper>
      {!props.isCompact && <Styles.StatusTitle>{status.title}</Styles.StatusTitle>}
      <Icon
        size={props.isCompact ? 16 : undefined}
        name={status.icon}
        color={status.color}
      />
    </Styles.StatusWrapper>
  )
}

interface ScheduledSurveyStatusProps {
  status: ScheduledSurveyStatusModel,
  isCompact?: boolean,
}

export default ScheduledSurveyStatus
