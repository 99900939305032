import { Card } from "@humanpredictiveintelligence/myqvt-library"
import { Input } from "@humanpredictiveintelligence/myqvt-library"
import { Title } from "@humanpredictiveintelligence/myqvt-library"
import styled from "styled-components/macro"
import { Dialog, DialogActions, DialogContent } from "@material-ui/core"

export const DialogBody = styled(Dialog)`
  .Dialog__body {
    width: 475px;
    height: 400px;
    display: inline-flex;
    flex-direction: column;
  }
`

export const DialogTitle = styled(Title)`
  text-align: center;
`

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Content = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const EmailInput = styled(Input)`
  width: 100%;
`

export const EmailUsedWarning = styled.p`
  color: ${({ theme }) => theme.colors.danger};
`

export const Actions = styled(DialogActions)`
  justify-content: center;
`

export const Action = styled(Card)`
  display: flex;
  flex-direction: column;
`
