import React from "react"
import { t } from "ttag"

import { BaseProps, SearchableSelectOption } from "@humanpredictiveintelligence/myqvt-library"
import { SkinnyUserAttributeValueStatistics } from "models/SkinnyUserAttributeValueStatistics"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"
import useAttributes from "services/useAttributes"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"
import * as Styles from "./UserAttributesFilters.styles"

import { ScheduledSurveyGlobalResultsQuery } from "graphql/queries/generated/ScheduledSurveyGlobalResults"
import { useFilteredUserAttributesQuery } from "graphql/queries/generated/FilteredUserAttributes"

export const UserAttributesFilters: React.FC<UserAttributesFiltersProps> = (props) => {
  const [ attributes, setAttributes ] = React.useState(props.attributes)
  const [ userGroups, setUserGroups ] = React.useState(props.statisticsUserGroups)
  const [ selectedUserGroupUuids, setSelectedUserGroupUuids ] = React.useState(
    props.defaultSelection?.userGroupsUuids || [],
  )

  const [ isBottomSectionVisible, setBottomSectionVisible ] = React.useState(false)
  const {
    allAttributes,
    hierarchicalAttributes,
    simpleAttributes,
    selectAttributeValue,
    selectedAttributeValues,
    emptyAllAttributeValueSelections,
    filteredAttributesId,
    getAvailableAttributeValuesForAttribute,
    firstInteractedHierarchicalAttributeId,
  } = useAttributes(
    USER_ATTRIBUTE_SERVICE.attributesFromAttributeValueStatistics(attributes, true),
    props.defaultSelection?.userAttributesValues,
    props.firstInteractedHierarchicalAttributeId,
  )

  const { data, loading } = useFilteredUserAttributesQuery({
    fetchPolicy: "network-only",
    variables: {
      filterAttributeIds: filteredAttributesId,
      scheduledSurveyId: props.scheduledSurveyId,
      userGroupUuids: selectedUserGroupUuids,
    },
  })

  React.useEffect(() => {
    if (data && data.scheduledSurvey) {
      setAttributes(data.scheduledSurvey.filteredStatistics.attributes)
      setUserGroups(data.scheduledSurvey.filteredStatistics.userGroups)
    }
  }, [ data ])

  React.useEffect(() => {
    props.onHierarchicalFilterFirstInteraction?.(firstInteractedHierarchicalAttributeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ firstInteractedHierarchicalAttributeId ])

  if (allAttributes.length === 0 && userGroups.length > 1) {
    return null
  }

  return (
    <Styles.Container className={props.className}>
      <Styles.TopSection>
        <Styles.TopSectionFilters>
          {userGroups.length > 1 && (
            <Styles.AttributeSelect
              options={userGroupAsSearchableSelectOptions(userGroups)}
              defaultValues={selectedUserGroupUuids}
              label={t`User groups`}
              disabledOptionsTooltipText={t`There is not enough users to guarantee their anonymity`}
              noOptionText={t`No filter`}
              selectPlaceholder={t`All`}
              isMultiselect
              onChange={selections => setSelectedUserGroupUuids(selections.map(selection => selection.value))}
              isLoading={loading}
            />
          )}
          {(hierarchicalAttributes.length > 0 ? hierarchicalAttributes : simpleAttributes).map(attribute => {
            return (
              <Styles.AttributeSelect
                key={attribute.id}
                options={
                  attributeValuesAsSearchableSelectOptions(getAvailableAttributeValuesForAttribute(attribute))
                }
                defaultValues={
                  (
                    selectedAttributeValues.has(attribute.id)
                    && selectedAttributeValues.get(attribute.id)!.map(value => value.id.toString())
                  )
                  || undefined
                }
                label={attribute.name}
                disabledOptionsTooltipText={t`There is not enough users to guarantee their anonymity`}
                noOptionText={t`No filter`}
                selectPlaceholder={t`All`}
                onChange={
                  selection => {
                    selectAttributeValue(
                      attribute,
                      selection.map(searchableSelectOption => parseInt(searchableSelectOption.value, 10)),
                    )
                  }
                }
                isMultiselect
                isLoading={loading}
              />
            )
          })}
        </Styles.TopSectionFilters>
        <Styles.FiltersActions>
          <Styles.FiltersAction
            icon="delete_sweep"
            isInverted
            size="big"
            onClick={onRemoveFilters}
          />
          <Styles.FiltersAction
            icon="filter_list"
            onClick={onApplyFilters}
          >
            {t`Filter`}
          </Styles.FiltersAction>
        </Styles.FiltersActions>
      </Styles.TopSection>
      {hierarchicalAttributes.length > 0 && simpleAttributes.length > 0 && (
        <>
          <Styles.BottomSection $isHidden={!isBottomSectionVisible}>
            <div>
              {simpleAttributes.map(attribute => {
                return (
                  <Styles.AttributeSelect
                    key={attribute.id}
                    options={
                      attributeValuesAsSearchableSelectOptions(getAvailableAttributeValuesForAttribute(attribute))
                    }
                    defaultValues={
                      (
                        selectedAttributeValues.has(attribute.id)
                        && selectedAttributeValues.get(attribute.id)!.map(value => value.id.toString())
                      )
                      || undefined
                    }
                    label={attribute.name}
                    disabledOptionsTooltipText={t`There is not enough users to guarantee their anonymity`}
                    noOptionText={t`No filter`}
                    selectPlaceholder={t`All`}
                    onChange={selection => {
                      selectAttributeValue(
                        attribute,
                        selection.map(searchableSelectOption => parseInt(searchableSelectOption.value, 10)),
                      )
                    }}
                    isMultiselect
                    isLoading={loading}
                  />
                )
              })}
            </div>
          </Styles.BottomSection>
          <Styles.BottomSectionToggle
            onClick={() => setBottomSectionVisible(!isBottomSectionVisible)}
            isInverted
            height="small"
          >
            {isBottomSectionVisible ? t`Show less filters` : t`Show more filters`}
          </Styles.BottomSectionToggle>
        </>
      )}
    </Styles.Container>
  )

  /**
   * Call props.onFilter with the current selection
   */
  function onApplyFilters() {
    if (props.onFilter) {
      props.onFilter({
        userAttributesValues: selectedAttributeValues,
        userGroupsUuids: selectedUserGroupUuids,
      })
    }
  }

  function onRemoveFilters() {
    emptyAllAttributeValueSelections()
    setSelectedUserGroupUuids([])
  }

  /**
   * Get an array of available options to offer for the specified attribute
   * @param attributesValues available attributes values whose options should be returned
   */
  function attributeValuesAsSearchableSelectOptions(
    attributesValues: USER_ATTRIBUTE_VALUE[],
  ): SearchableSelectOption[] {
    return attributesValues.map(value => ({
      isDisabled: !value.isClusterUnviolated,
      label: `${value.label} (${value.usersCount})`,
      value: value.id.toString(),
    }))
  }

  function userGroupAsSearchableSelectOptions(statisticsUserGroups: StatisticUserGroup[]): SearchableSelectOption[] {
    return statisticsUserGroups.map(({ userGroup, participationSubmittedNumber, areRecipientsOverCluster }) => ({
      isDisabled: !areRecipientsOverCluster,
      label: `${userGroup.name} (${participationSubmittedNumber})`,
      value: userGroup.uuid,
    }))
  }
}

type StatisticUserGroup =
  NonNullable<ScheduledSurveyGlobalResultsQuery["scheduledSurvey"]>["statistics"]["userGroups"][0]

export type ScheduledSurveyFilters = {
  userAttributesValues: Map<number, USER_ATTRIBUTE_VALUE[]>,
  userGroupsUuids: string[],
}

interface UserAttributesFiltersProps extends BaseProps {
  /**
   * Attributes to display with their statistics
   */
  attributes: SkinnyUserAttributeValueStatistics[],

  statisticsUserGroups: StatisticUserGroup[],

  /**
   * Default selection of attributes (hierarchical and simple)
   */
  defaultSelection?: ScheduledSurveyFilters,

  /**
   * Triggered when the user applies the filters
   * The parameter is a map of attributes IDs to the selected values
   */
  onFilter?: (selectedFilters: ScheduledSurveyFilters) => void,

  /** Id of the scheduled survey */
  scheduledSurveyId: number,

  /** Id of the first hierarchical attribute being applied */
  firstInteractedHierarchicalAttributeId?: number,

  /**
   * Called whenever {firstInteractedHierarchicalAttributeId} prop changes
   * @param attributeId id of the attribute that was interacted with
   * */
  onHierarchicalFilterFirstInteraction?: (attributeId: number | undefined) => void,
}
