import React from "react"

import { DataAnonymizationIllustration } from "../DataAnonymizationIllustration"
import { SkeletonProps, SkeletonWrapper } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ScheduledSurveyResultSection.styles"

export const ScheduledSurveyResultSectionSkeleton: React.FC<SkeletonProps> = (props) => (
  <Styles.Container>
    <Styles.ResultHeader isLoading={true} title={""}/>
    <Styles.ContentSkeletonContainer>
      <SkeletonWrapper isLoading component={DataAnonymizationIllustration}>
        {props.children}
      </SkeletonWrapper>
    </Styles.ContentSkeletonContainer>
  </Styles.Container>
)
