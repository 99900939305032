/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserGroupMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  uuid: Types.Scalars['String'];
}>;


export type UpdateUserGroupMutation = { updateUserGroup: { name: string, uuid: string, createdAt: string, users: Array<{ id: number }> } };


export const UpdateUserGroupDocument = gql`
    mutation UpdateUserGroup($name: String!, $uuid: String!) {
  updateUserGroup(name: $name, uuid: $uuid) {
    name
    uuid
    createdAt
    users {
      id
    }
  }
}
    `;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(UpdateUserGroupDocument, options);
      }
export type UpdateUserGroupMutationHookResult = ReturnType<typeof useUpdateUserGroupMutation>;
export type UpdateUserGroupMutationResult = Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>;