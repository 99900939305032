import * as React from "react"
import { t } from "ttag"

import business from "config/business"

import { ScheduledSurveyResultSection } from "../ScheduledSurveyResultSection"
import { CommentsTable } from "features/Comments"
import { BaseProps, CenteredLoader, PagedNavigation, Select, SelectOptionProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ScheduledSurveyCollaboratorsFeedback.styles"

import { ParticipationsCommentsQuery, useParticipationsCommentsQuery } from "graphql/queries/generated/ParticipationsComments"
import { CommentObjectType } from "models/generated"

type ParticipationComment =
  NonNullable<ParticipationsCommentsQuery["scheduledSurvey"]>["statistics"]["participationsComments"][0]

export const ScheduledSurveyCollaboratorsFeedback: React.FC<ScheduledSurveyFeedbackProps> = (props) => {
  const [ participationComments, setParticipationComments ] = React.useState<ParticipationComment[]>([])

  const [ commentsPagination, setCommentsPagination ] = React.useState({
    currentPage: 1,
    limit: business.results.commentsPageSizes[0],
    loadingOffset: 0,
  })

  const { loading, data, refetch } = useParticipationsCommentsQuery({
    onCompleted(data) {
      setParticipationComments(data?.scheduledSurvey?.statistics.participationsComments || [])
    },
    variables: {
      filterAttributeIds: props.attributes || undefined,
      id: props.scheduledSurveyId,
      limit: commentsPagination.limit,
      offset: commentsPagination.loadingOffset,
    },
  })

  const commentsCount =
    (data && data.scheduledSurvey && data.scheduledSurvey.statistics.participationsCommentsNumber) || 0
  const treatedPercentage =
    (data && data.scheduledSurvey && data.scheduledSurvey.statistics.participationsCommentsTreatedPercentage) || 0

  const maxPage = Math.ceil(commentsCount / commentsPagination.limit)

  return (
    <div className={props.className}>
      <ScheduledSurveyResultSection
        title={t`Comments from your collaborators`}
        icon="highlight"
        progress={(commentsCount > 0 && { label: t`Addressed comments`, value: treatedPercentage }) || undefined}
      >
        <Styles.Card $isEmpty={participationComments.length === 0}>
          {participationComments.length > 0 && (
            <CommentsTable
              type={CommentObjectType.ScheduledSurvey}
              comments={participationComments}
              onSaved={() => refetch()}
              areCommentsLoading={loading}
            />
          )}
          {participationComments.length === 0 && (
            <Styles.TableEmptyMessage>
              {t`No comment to display`}
            </Styles.TableEmptyMessage>
          )}
        </Styles.Card>
      </ScheduledSurveyResultSection>
      {participationComments.length > 0 && (
        <Styles.Pagination>
          <Select
            options={commentsPagingOptions()}
            value={commentsPagination.limit}
            onChange={updateCommentsLimit}
            defaultItem={false}
          />

          {loading && <CenteredLoader isTransparent size="s"/>}
          <PagedNavigation
            page={commentsPagination.currentPage}
            numberOfPages={maxPage}
            onNavigate={(page) => updateCommentsPage(page, maxPage)}
          />
        </Styles.Pagination>
      )}
    </div>
  )

  /**
   * Get the options for the pagination select
   */
  function commentsPagingOptions(): SelectOptionProps[] {
    const options: SelectOptionProps[] = []
    business.results.commentsPageSizes.forEach((size) => {
      options.push({
        value: size,
        wording: size.toString(),
      })
    })
    return options
  }

  /**
   * Set the new selected page and update the offset
   * @param page Page number selected
   * @param maxPageNumber Maximum page number
   */
  function updateCommentsPage(page: number, maxPageNumber: number) {
    if (!(page === 0 || page === maxPageNumber + 1)) {
      setCommentsPagination((prevPagination) => {
        return {
          ...prevPagination,
          currentPage: page,
          loadingOffset: prevPagination.limit * (page - 1),
        }})
    }
  }

  /** Set the comments paging limit and reset the current page and offset
   *  @param newPaging limit value
   */
  function updateCommentsLimit(newPaging: SelectOptionProps | undefined) {
    if (newPaging) {
      setCommentsPagination((prevPagination) => {
        return {
          ...prevPagination,
          currentPage: 1,
          limit: newPaging.value as number,
          loadingOffset: 0,
        }})
    }
  }
}

interface ScheduledSurveyFeedbackProps extends BaseProps {
  /** ID of the scheduledSurvey */
  scheduledSurveyId: number,

  /** Attributes id to filter comments */
  attributes?: number[],
}
