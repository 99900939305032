import { motion } from "framer-motion"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import styled from "styled-components/macro"

export const AnimatedContainer = styled(motion.div)`
  padding: 8px 0 0 16px;
  overflow-y: hidden;
  
  display:flex;
  flex-direction: column;
`

export const ErrorsContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-right: 8px;
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  margin: 8px 0;
`

export const ErrorIcon = styled(Icon)`
  align-items: flex-start;
  
  & .Icon__symbol {
    width: 15px;
    padding-top: 2px;
  }
`
