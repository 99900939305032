import styled, { css } from "styled-components/macro"
import * as PermissionsStyles from "../Permissions.styles"

export const Button = styled.button<{ $isActive?: boolean }>`
  ${({ theme, $isActive, disabled }) => css`
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    padding: 8px 16px;
    user-select: none;
    cursor: pointer;
    
    font-size: ${theme.typography.fontSizeButtonSmall};
    line-height: 16px;
    letter-spacing: ${theme.typography.letterSpacingButton};
    color: ${theme.colors.black};

    background-color: ${theme.colors.blackUltraLight};
    border: none;
    border-radius: ${theme.sizes.borderRadiusRounded};
    box-shadow: ${theme.shadows.button};

    transition: background-color, box-shadow 0.2s;

    &:hover {
      box-shadow: ${theme.shadows.buttonHover};
    }

    ${$isActive && !disabled && css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    `}

    ${disabled && css`
      color: ${theme.colors.blackLight};
      cursor: initial;
      pointer-events: none;

      &:hover {
        box-shadow: ${theme.shadows.button};
      }
    `}
  `}
`

export const ScopeIcon = styled(PermissionsStyles.ScopeIcon)`
  margin-left: auto;
  padding-left: 4px;
`
