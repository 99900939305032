/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportUsersQueryVariables = Types.Exact<{
  language?: Types.InputMaybe<Types.Supported_Language_Code>;
}>;


export type ExportUsersQuery = { export_users: string };


export const ExportUsersDocument = gql`
    query ExportUsers($language: Supported_language_code) {
  export_users(language: $language)
}
    `;

/**
 * __useExportUsersQuery__
 *
 * To run a query within a React component, call `useExportUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportUsersQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useExportUsersQuery(baseOptions?: Apollo.QueryHookOptions<ExportUsersQuery, ExportUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportUsersQuery, ExportUsersQueryVariables>(ExportUsersDocument, options);
      }
export function useExportUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportUsersQuery, ExportUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportUsersQuery, ExportUsersQueryVariables>(ExportUsersDocument, options);
        }
export type ExportUsersQueryHookResult = ReturnType<typeof useExportUsersQuery>;
export type ExportUsersLazyQueryHookResult = ReturnType<typeof useExportUsersLazyQuery>;
export type ExportUsersQueryResult = Apollo.QueryResult<ExportUsersQuery, ExportUsersQueryVariables>;