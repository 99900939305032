import React from "react"

import { Tutorial } from "./models/Tutorial"
import { TutorialReducer } from "features/Tutorial/TutorialReducer"
import { useSession } from "features/Session"
import { TutorialProgression, TutorialStep } from "features/Tutorial/models"
import { TutorialReducerAction } from "features/Tutorial/TutorialReducerActions"
import { TUTORIAL_INITIAL_STATE, TutorialState } from "features/Tutorial/TutorialState"
import useTutorialController from "features/Tutorial/useTutorialController"
import { findStepIndex } from "./utils"

export type TutorialEvent = {
  name: string,
  nextTarget?: TutorialTarget,
};

export type TutorialTarget = string | HTMLElement

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TutorialStepData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any,
}

/**
 * Tutorial Component API : Used by the components to interact or run side effects on the tutorial
 */
interface TutorialApi {
  isTutorialActive: () => boolean,
  activeStep?: TutorialStep,
  isFeatureEnabled: boolean,
  startTutorial: (tutorial: Tutorial) => void,
  skipStep: () => void,
  quitTutorial: () => void,
  setStepTarget: (stepId: string, target: TutorialTarget) => void,
  triggerTutorialEvent: (event: TutorialEvent) => void,
  setStepData: <S>(stepId: string, data: S) => void,
  getStepData: <S>(stepId: string) => S|undefined,
}

const defaultTutorialApi: TutorialApi = {
  getStepData: <T extends unknown>() => ({} as T),
  isFeatureEnabled: false,
  isTutorialActive: () => false,
  quitTutorial: () => undefined,
  setStepData: () => undefined,
  setStepTarget: () => undefined,
  skipStep: () => undefined,
  startTutorial: () => undefined,
  triggerTutorialEvent: () => undefined,
}

export const TutorialContext = React.createContext<{
  tutorialApi: TutorialApi,
  tutorialState: TutorialState,
  dispatch: React.Dispatch<TutorialReducerAction>,
}>({
  dispatch: () => { /* Empty */ },
  tutorialApi: defaultTutorialApi,
  tutorialState: TUTORIAL_INITIAL_STATE,
})

export const TutorialProvider: React.FC = (props) => {
  const session = useSession()
  const tutorialProgressions = session.user?.settings.tutorialsProgression ?? []
  const [ tutorialState, dispatch ] = React.useReducer(TutorialReducer, TUTORIAL_INITIAL_STATE)
  const {
    getTutorialStepsCount,
    startTutorial,
    setStepData,
    setStepTarget,
    toggleQuitTutorialConfirmationDialog,
    toggleSkipStepConfirmationDialog,
    isTutorialActive,
    getStepData,
    triggerTutorialEvent,
    saveProgression,
    isTutorialFinished,
  } = useTutorialController(dispatch)
  const [ tutorialStartingState, setTutorialStartingState ] = React.useState<{
    activeTutorial: Tutorial,
    stepId: TutorialProgression["value"],
  } | undefined>(undefined)

  /**
   * Start the tutorial with some delay just to give minimum time to the page to load
   * In an ideal world all steps should be imperative so we don't have to do this
   * The timeout is a dirty workaround until we implement a proper way to mark the step as ready to be started
   * @param activeTutorial
   * @param steps
   * @param savedStepIndex
   */
  React.useEffect(() => {
    if (tutorialStartingState) {
      startTutorial(tutorialStartingState.activeTutorial, tutorialStartingState.stepId)
    }
  }, [ startTutorial, tutorialStartingState ])

  /**
   * If an ID is defined for the tutorial, that means that the tutorial is started and does need to be started
   */
  React.useEffect(() => {
    if (tutorialState.id) {
      setTutorialStartingState(undefined)
    }
  }, [ tutorialState.id ])

  /**
   * Save the progression when the step is loaded or is changed
   */
  React.useEffect(() => {
    if (tutorialState.id && tutorialState.stepIndex && tutorialState.tutorial) {
      const isFinished = !isTutorialFinished(tutorialState.tutorial, tutorialState.stepIndex)
      const stepIndex = isFinished ? tutorialState.stepIndex : getTutorialStepsCount(tutorialState.tutorial)
      saveProgression(tutorialState.steps[stepIndex].id, tutorialState.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ tutorialState.id, tutorialState.stepIndex ])

  /**
   * If no tutorial is running, start the given tutorial depending on the user progression
   * */
  const start = (
    tutorialState: TutorialState,
    tutorialsProgression: TutorialProgression[],
    activeTutorial: Tutorial,
  ) => {
    if (tutorialState.run) {
      return
    }
    const stepId = tutorialsProgression?.find(savedTutorial => savedTutorial.name === activeTutorial.id)?.value || ""

    if (!isTutorialFinished(activeTutorial, findStepIndex(activeTutorial, stepId))) {
      setTutorialStartingState({
        activeTutorial,
        stepId,
      })
    }
  }

  return (
    <TutorialContext.Provider value={{
      dispatch,
      tutorialApi: {
        activeStep: tutorialState.steps[tutorialState.stepIndex],
        getStepData: (stepId: string) => getStepData(tutorialState, stepId),
        // TODO: Remove this when the feature is used again
        isFeatureEnabled: false,
        isTutorialActive: () => isTutorialActive(tutorialState),
        quitTutorial: toggleQuitTutorialConfirmationDialog,
        setStepData,
        setStepTarget,
        skipStep: toggleSkipStepConfirmationDialog,
        startTutorial: (tutorial: Tutorial) => start(tutorialState, tutorialProgressions, tutorial),
        triggerTutorialEvent: (event: TutorialEvent) => triggerTutorialEvent(tutorialState, event),
      },
      tutorialState,
    }}>
      {props.children}
    </TutorialContext.Provider>
  )
}

/**
 * Custom hook to use the TutorialContext in components
 */
export const useTutorial = (): TutorialContextApi => {
  const { tutorialApi, tutorialState } = React.useContext(TutorialContext)

  /**
   * Simple wrapper to allow to run some effects only when the tutorial is active
   * @param whenActiveCallback
   * @param whenInactiveReturn
   */
  const whenTutorialActiveRun = <WillReturn extends unknown = undefined>(
    whenActiveCallback: WillReturn,
    whenInactiveReturn?: WillReturn,
  ): WillReturn|undefined  => {
    if (tutorialApi.isTutorialActive()) {
      return whenActiveCallback instanceof Function ? whenActiveCallback() : whenActiveCallback
    } else if (whenInactiveReturn) {
      return whenInactiveReturn instanceof Function ? whenInactiveReturn() : whenInactiveReturn
    }
    return whenInactiveReturn
  }

  return {
    ...tutorialApi,
    startTutorial: (tutorial: Tutorial) => {
      if (tutorialApi.isFeatureEnabled) {
        tutorialApi.startTutorial(tutorial)
      }
    },
    triggerTutorialEvent: (event: TutorialEvent) => {
      if (tutorialApi.isFeatureEnabled) {
        tutorialApi.triggerTutorialEvent(event)
      }
    },
    tutorialState,
    whenTutorialActiveRun,
  }
}

export type TutorialContextApi = TutorialApi & {
  startTutorial: (tutorial: Tutorial) => void,
  triggerTutorialEvent: (event: TutorialEvent) => void,
  whenTutorialActiveRun: <WillReturn extends unknown = undefined>(
    whenActiveCallback: WillReturn,
    whenInactiveReturn?: WillReturn
  ) => WillReturn|undefined,
  tutorialState: TutorialState,
}
