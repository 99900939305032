import { t } from "ttag"

import { FrontAppName } from "models/generated"
import SpeakupYellow from "assets/images/speakup_yellow.svg"
import PimeoBlue from "assets/images/pimeo_blue.svg"

export const useAppsConfig = (): Record<FrontAppName, AppConfig> => ({
  [FrontAppName.PimeoBo]: { appLabel: t`Administration space`, iconSrc: SpeakupYellow },
  [FrontAppName.PimeoFo]: { appLabel: "Piméo", iconSrc: PimeoBlue },
})

interface AppConfig {
  appLabel: string,
  iconSrc: string,
}
