import styled from "styled-components/macro"
import { MetricsCard } from "@humanpredictiveintelligence/myqvt-library"
import { PillSelect } from "@humanpredictiveintelligence/myqvt-library"
import { ContextualizedCommentsTable } from "features/Comments/ContextualizedCommentsTable"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"

export const Card = styled(MetricsCard)<{ $minHeight: number }>`
  min-height: ${({ $minHeight }) => $minHeight}px;
  overflow: hidden;
  transition: height 250ms;
`

export const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
`

export const FilterPills = styled(PillSelect)`
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.black};
`

export const FilterPillIcon = styled(Icon)`
  margin-right: 4px;
`

export const NoContentMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  flex-grow: 1;

  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.blackMedium};
`

export const CommentsTable = styled(ContextualizedCommentsTable)`
  width: calc(100% + 2 * 24px);
  margin: 40px -24px 0 -24px;
`

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 8px;
`
