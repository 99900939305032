/* tslint:disable */ /* eslint-disable */
import * as Types from './index';

import { gql } from '@apollo/client';
import { SkinnyScheduledReminderFragmentFragmentDoc } from './SkinnyScheduledReminderFragment';
import { SkinnyUserGroupFragmentFragmentDoc } from './SkinnyUserGroupFragment';
export type SkinnyScheduledSurveyFragmentFragment = { id: number, isManualPublication: boolean, schedulingId: number, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, scheduled_reminders: Array<{ remind_hours_before_end: number, is_editable: boolean }>, userGroups: Array<{ name: string, usersCount?: number | undefined, uuid: string, deletedAt?: string | undefined }> };

export const SkinnyScheduledSurveyFragmentFragmentDoc = gql`
    fragment SkinnyScheduledSurveyFragment on Scheduled_survey {
  id
  schedulingId: id
  status {
    absolute
    local
  }
  beginAt: begin_at {
    local
    earliest
    latest
  }
  endAt: end_at {
    local
    earliest
    latest
  }
  isManualPublication
  scheduled_reminders {
    ...SkinnyScheduledReminderFragment
  }
  userGroups {
    ...SkinnyUserGroupFragment
  }
}
    ${SkinnyScheduledReminderFragmentFragmentDoc}
${SkinnyUserGroupFragmentFragmentDoc}`;