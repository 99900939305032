import styled from "styled-components/macro"

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: ${props => props.theme.sizes.borderRadiusRounded};
  border: 3px solid ${props => props.theme.colors.primary};
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap:.5rem;
  align-items: center;
  text-align: center;
  max-width: 90%;
`

export const Name = styled.h4`
  font-weight: 600;
  margin:0;
`

export const Mail = styled.a`
  text-decoration: none;
  cursor: pointer;
  word-break: break-all;
  color: ${props => props.theme.colors.primary};
  transition: color .2s ease-in-out;
  
  &:hover {
    color: ${props => props.theme.colors.primaryDark};
  }
`
