import React from "react"

import { PermissionsState, initialPermissionsState } from "features/Permissions/PermissionsContext/state"
import { permissionsReducer } from "features/Permissions/PermissionsContext/reducer"
import { PermissionsReducerAction, permissionsReducerActions } from "features/Permissions/PermissionsContext/actions"

import { useAdminsLazyQuery } from "graphql/queries/generated/Admins"
import { useSetUserPermissionsMutation } from "graphql/mutations/generated/SetUserPermissions"

const PermissionsContext =
  React.createContext<[ PermissionsState, React.Dispatch<PermissionsReducerAction> ] | undefined>(undefined)

export const PermissionsProvider: React.FC = ({ children }) => (
  <PermissionsContext.Provider value={React.useReducer(permissionsReducer, initialPermissionsState)}>
    {children}
  </PermissionsContext.Provider>
)

export const usePermissions = () => {
  const context = React.useContext(PermissionsContext)

  if (context === undefined) {
    throw new Error("usePermissions must be used within a PermissionsProvider")
  }
  const [ state, dispatch ]  = context

  const actions = permissionsReducerActions(dispatch)
  const [ fetchAdmins ] = useAdminsLazyQuery(
    {
      fetchPolicy: "cache-and-network",
      onCompleted: adminsData => {
      /** Get the selected admin index or set first user in array */
        const selectedUserIndex = state.selectedAdmin?.id
          ? adminsData.admins.findIndex(admin => admin.id === state.selectedAdmin?.id) ?? 0
          : 0

        actions.setAdmins(adminsData.admins)

        if (adminsData.admins.length > 0) {
          actions.selectAdmin(adminsData.admins[selectedUserIndex])
        }
        else {
          actions.selectAdmin(undefined)
        }
      },
    })

  const [ setUserPermissions ] = useSetUserPermissionsMutation({
    update(cache, { data }) {
      if (state.selectedAdmin) {
        cache.modify({
          fields: {
            permissions() {
              return data?.set_user_permissions
            },
          },
          id: cache.identify(state.selectedAdmin),
        })
      }
    },
  })

  return {
    ...state,
    actions,
    fetchAdmins,
    setUserPermissions,
  }
}
