import styled from "styled-components/macro"
import { Title } from "@humanpredictiveintelligence/myqvt-library"

export const Wrapper = styled.div`
  height: 100%;

  display:flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  padding: 32px 64px;
`

export const IntroTitle = styled(Title)`
  border: none;
  margin-top: 0;
  margin-bottom: 24px;
`

export const Paragraph = styled.p`
  margin: 0;
`

export const FileSpecsParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.blackMedium};

  & > span {
    display: block;
  }
`

export const EvalutationWarning = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.danger};
`
