import React from "react"
import { SnackbarProvider } from "notistack"

import { MyQvtThemeProvider } from "@humanpredictiveintelligence/myqvt-library"
import { MuiMyQvtThemeProvider } from "@humanpredictiveintelligence/myqvt-library"
import { SessionProvider } from "features/Session"
import { LanguageProvider } from "features/LanguageProvider"
import { NotificationProvider } from "features/Notification"
import { TutorialProvider } from "features/Tutorial"
import { AuthenticationProvider } from "features/Authentication/AuthenticationContext"
import { UncommittedChangesProvider } from "features/UncommitedChanges"
import { RollbarProvider } from "features/Rollbar/RollbarProvider"
import { PatchNoteProvider } from "features/PatchNotes"
import { BackendProvider } from "features/Backend"

const Providers: React.FC<Record<string, unknown>> = (props) => (
  <MuiMyQvtThemeProvider>
    <MyQvtThemeProvider>
      <BackendProvider>
        <SessionProvider>
          <LanguageProvider>
            <SnackbarProvider
              maxSnack={3}
              transitionDuration={{ enter: 200, exit: 200 }}
              classes={{ root: "NotificationContainer" }}
            >
              <NotificationProvider>
                <RollbarProvider>
                  <TutorialProvider>
                    <AuthenticationProvider>
                      <UncommittedChangesProvider>
                        <>
                          <PatchNoteProvider/>
                          {props.children}
                        </>
                      </UncommittedChangesProvider>
                    </AuthenticationProvider>
                  </TutorialProvider>
                </RollbarProvider>
              </NotificationProvider>
            </SnackbarProvider>
          </LanguageProvider>
        </SessionProvider>
      </BackendProvider>
    </MyQvtThemeProvider>
  </MuiMyQvtThemeProvider>
)

export default Providers
