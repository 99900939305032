/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteUsersFromUserGroupMutationVariables = Types.Exact<{
  userIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
  userGroupUuid: Types.Scalars['String'];
}>;


export type DeleteUsersFromUserGroupMutation = { deleteUsersFromUserGroup: { uuid: string, name: string, createdAt: string, usersCount?: number | undefined, users: Array<{ id: number }> } };


export const DeleteUsersFromUserGroupDocument = gql`
    mutation DeleteUsersFromUserGroup($userIds: [Int!]!, $userGroupUuid: String!) {
  deleteUsersFromUserGroup(userIds: $userIds, userGroupUuid: $userGroupUuid) {
    uuid
    name
    createdAt
    usersCount
    users {
      id
    }
  }
}
    `;

/**
 * __useDeleteUsersFromUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUsersFromUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersFromUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersFromUserGroupMutation, { data, loading, error }] = useDeleteUsersFromUserGroupMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      userGroupUuid: // value for 'userGroupUuid'
 *   },
 * });
 */
export function useDeleteUsersFromUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersFromUserGroupMutation, DeleteUsersFromUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersFromUserGroupMutation, DeleteUsersFromUserGroupMutationVariables>(DeleteUsersFromUserGroupDocument, options);
      }
export type DeleteUsersFromUserGroupMutationHookResult = ReturnType<typeof useDeleteUsersFromUserGroupMutation>;
export type DeleteUsersFromUserGroupMutationResult = Apollo.MutationResult<DeleteUsersFromUserGroupMutation>;
export type DeleteUsersFromUserGroupMutationOptions = Apollo.BaseMutationOptions<DeleteUsersFromUserGroupMutation, DeleteUsersFromUserGroupMutationVariables>;