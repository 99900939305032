import styled, { css } from "styled-components/macro"

export const Container = styled.div<{ $width: number, $height: number }>`
  ${({ $width, $height }) => css`
    width: ${$width ? $width + "px" : "100%"};
    height: ${$height ? $height + "px" : "100%"};
  `}
`

export const Title = styled.div`
  margin-top: 2rem;
  font-family: ${({ theme }) => theme.typography.fontSizeTitleBig};
`
