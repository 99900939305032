/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyScheduledReminderFragmentFragmentDoc } from '../../../models/generated/SkinnyScheduledReminderFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduledSurveyWithoutStatisticsQueryVariables = Types.Exact<{
  scheduledSurveyId: Types.Scalars['Int'];
}>;


export type ScheduledSurveyWithoutStatisticsQuery = { scheduledSurvey?: { id: number, isManualPublication: boolean, scheduledId: number, beginAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, endAt: { local: string, earliest?: string | undefined, latest?: string | undefined }, userGroups: Array<{ uuid: string, usersCount?: number | undefined, name: string, createdAt: string, deletedAt?: string | undefined, users: Array<{ first_name: string, last_name: string, email?: string | undefined, phone_number?: string | undefined, id: number }> }>, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, survey: { id: number, name: string, theme: { id: number, icon: string, name: string, description: string, color: string, lastUsedAt?: string | undefined, nextUsedAt?: string | undefined } }, scheduledReminders: Array<{ remind_hours_before_end: number, is_editable: boolean }> } | undefined };


export const ScheduledSurveyWithoutStatisticsDocument = gql`
    query ScheduledSurveyWithoutStatistics($scheduledSurveyId: Int!) {
  scheduledSurvey: scheduled_survey(scheduled_survey_id: $scheduledSurveyId) {
    beginAt: begin_at {
      local
      earliest
      latest
    }
    endAt: end_at {
      local
      earliest
      latest
    }
    id
    isManualPublication
    userGroups {
      uuid
      usersCount
      name
      createdAt
      deletedAt
      users {
        first_name
        last_name
        email
        phone_number
        id
      }
    }
    scheduledId: id
    status {
      absolute
      local
    }
    survey {
      id
      name
      theme {
        id
        icon
        name
        description
        color
        lastUsedAt: last_used_at
        nextUsedAt: next_used_at
      }
    }
    scheduledReminders: scheduled_reminders {
      ...SkinnyScheduledReminderFragment
    }
  }
}
    ${SkinnyScheduledReminderFragmentFragmentDoc}`;

/**
 * __useScheduledSurveyWithoutStatisticsQuery__
 *
 * To run a query within a React component, call `useScheduledSurveyWithoutStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledSurveyWithoutStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledSurveyWithoutStatisticsQuery({
 *   variables: {
 *      scheduledSurveyId: // value for 'scheduledSurveyId'
 *   },
 * });
 */
export function useScheduledSurveyWithoutStatisticsQuery(baseOptions: Apollo.QueryHookOptions<ScheduledSurveyWithoutStatisticsQuery, ScheduledSurveyWithoutStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledSurveyWithoutStatisticsQuery, ScheduledSurveyWithoutStatisticsQueryVariables>(ScheduledSurveyWithoutStatisticsDocument, options);
      }
export function useScheduledSurveyWithoutStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledSurveyWithoutStatisticsQuery, ScheduledSurveyWithoutStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledSurveyWithoutStatisticsQuery, ScheduledSurveyWithoutStatisticsQueryVariables>(ScheduledSurveyWithoutStatisticsDocument, options);
        }
export type ScheduledSurveyWithoutStatisticsQueryHookResult = ReturnType<typeof useScheduledSurveyWithoutStatisticsQuery>;
export type ScheduledSurveyWithoutStatisticsLazyQueryHookResult = ReturnType<typeof useScheduledSurveyWithoutStatisticsLazyQuery>;
export type ScheduledSurveyWithoutStatisticsQueryResult = Apollo.QueryResult<ScheduledSurveyWithoutStatisticsQuery, ScheduledSurveyWithoutStatisticsQueryVariables>;