import styled from "styled-components/macro"
import { InformationMessage } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  width: 100%;
  padding: 24px 64px;
`

export const Explanation = styled(InformationMessage)`
  margin-bottom: 32px;
  white-space: pre-line;
`
