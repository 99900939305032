import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core"
import {
  CenteredLoader, InformationMessage, PrimaryButton,
  SearchableSelect, SearchableSelectOption,
  Timeline, TimelineItem, Tooltip,
} from "@humanpredictiveintelligence/myqvt-library"
import moment from "moment-timezone"
import React from "react"
import { t } from "ttag"

import { useUserPermissions } from "features/Permissions"
import { useSession } from "features/Session"
import { useTutorial } from "features/Tutorial"
import { TUTORIAL_SURVEYS_EVENTS } from "features/Tutorial/contents/SurveysPage/Events"
import { TutorialState } from "features/Tutorial/TutorialState"
import { ScheduledRemindersSection } from "features/ScheduledReminder/ScheduledRemindersSection/ScheduledRemindersSection"
import { ScheduledReminder, ScheduledTimeReminderUnit } from "features/ScheduledReminder/models"
import { ScheduledSurveyDateRangePicker } from "features/Surveys/SurveyScheduling/ScheduledSurveyDateRangePicker"
import { UserGroupsHeadersDetails } from "features/UserGroups/UserGroupsDetails/UserGroupsHeadersDetails"

import * as Styles from "./ScheduledSurveyForm.styles"

import {
  Scheduled_Reminder as ScheduledReminderModel,
  Scheduled_Survey_Status as ScheduledSurveyStatus,
  UserPermissionCode,
} from "models/generated"
import { MomentRange } from "models/DateTime/MomentRange"
import { SurveyScheduling } from "models/ScheduledSurveyCreation"
import {
  Question,
  ScheduledSurvey,
  Survey,
  Theme,
  UserGroup,
} from "models/Scheduler"

import { useSurveySchedulingLazyQuery } from "graphql/queries/generated/SurveyScheduling"
import { useGetAssigneesTimeInformationQuery } from "graphql/queries/generated/GetAssigneesTimeInformation"

const ScheduledSurveyForm: React.FC<ScheduledSurveyFormProps> = (props) => {
  const session = useSession()
  const isUserAllowed = useUserPermissions()
  const { isTutorialActive, tutorialState, triggerTutorialEvent, setStepTarget } = useTutorial()

  const isUserAllowedToManage = isUserAllowed(UserPermissionCode.ScheduledSurveyManage)

  const clusterSize = session.customer?.clusterSize
  const isClusterViolated = session.customer?.isClusterViolated
  const recipientsCount = session.customer?.recipientsCount ?? 0

  const [ selectedThemeId, setSelectedThemeId ] = React.useState<number | undefined>()
  const [ selectedSurveyId, setSelectedSurveyId ] = React.useState<number | undefined>()
  const [ selectedSurveyGroupId, setSelectedSurveyGroupId ] = React.useState<string[]>([])
  const [ selectedDate, setSelectedDate ] = React.useState<MomentRange>()
  const [ isCreatingSurvey, setIsCreatingSurvey ] = React.useState(false)
  const [ isCreationConfirmationRequired, setCreationConfirmationRequired ] = React.useState(false)
  const [ isCancelOngoingConfirmationRequired, setCancelOngoingConfirmationRequired ] = React.useState(false)
  const [ isCancelOngoingConfirmed, setCancelOngoingConfirmed ] = React.useState(false)
  const [ isManualPublication, setIsManualPublication ] = React.useState(false)
  const [ isCancelTodoConfirmationRequired, setCancelTodoConfirmationRequired ] = React.useState(false)
  const createHeaderRef = React.createRef<HTMLDivElement>()

  /**
   * When scheduled reminders are changed, they trigger an update of the scheduled survey
   */
  const [ scheduledReminders, setScheduledReminders ] = React.useState<ScheduledReminder[]>([])

  const [ getSurveyScheduling, { data: surveySchedulingData } ] = useSurveySchedulingLazyQuery()

  const { data: assigneesTimeInformationResult } = useGetAssigneesTimeInformationQuery()

  const assigneesInformation = assigneesTimeInformationResult?.me.assigneesTimeInformation

  const isSelectionOverlappingExistingSurvey = (
    selectedDate
    && props.daysWithScheduledSurveys
    && !!props.daysWithScheduledSurveys.find(day => day.isBetween(selectedDate.from, selectedDate.to))
  )

  const availableSurveyGroups = session.users?.userGroups
  const availableSurveyGroupsUuids = React.useMemo(() =>
    availableSurveyGroups?.map(userGroup => userGroup.uuid) ?? [],
  [ availableSurveyGroups ],
  )

  const themesList = React.useMemo(
    () => props.availableThemes || (props.scheduledSurvey?.survey ? [ props.scheduledSurvey.survey.theme ] : []),
    [ props.availableThemes, props.scheduledSurvey ],
  )
  const surveysList = React.useMemo(
    () => props.availableSurveys || (props.scheduledSurvey ? [ props.scheduledSurvey.survey as Survey ] : []),
    [ props.availableSurveys, props.scheduledSurvey ],
  )

  const selectedTheme = React.useMemo(
    () => themesList.find(theme => theme.id === selectedThemeId),
    [ selectedThemeId, themesList ],
  )
  const selectedSurvey = React.useMemo(
    () => surveysList.find(survey => survey.id === selectedSurveyId),
    [ selectedSurveyId, surveysList ],
  )

  const themeOptions = React.useMemo(() => themesToSelectOptions(themesList), [ themesList ])
  const surveyOptions = React.useMemo(() => surveysToSelectOptions(surveysList), [ surveysList ])
  const surveyGroupOptions = React.useMemo(
    () => surveyGroupsToSelectOptions(
      availableSurveyGroups as UserGroup[],
      session.users?.usersInGroupCount ?? 0,
    ),
    [ availableSurveyGroups, session.users?.usersInGroupCount ],
  )

  const isValidateActionDisabled = (
    selectedThemeId === undefined
    || selectedSurveyId === undefined
    || selectedDate === undefined
    || (selectedDate && selectedDate.from === undefined)
    || (selectedDate && selectedDate.to === undefined)
    || !isUserAllowedToManage
    || selectedSurveyGroupId.length === 0
  )

  // Create a ref at mount with initial state
  const tutorialStateRef = React.useRef<TutorialState | undefined>(tutorialState)

  const [ saveScheduledSurveyStatus, setSaveScheduledSurveyStatus ] = React.useState({
    canSave: !isTutorialActive(),
    saveScheduled: false,
  })

  React.useEffect(() => {
    if (tutorialState && tutorialStateRef) {
      if (tutorialState.stepIndex !== tutorialStateRef.current?.stepIndex) {
        if (saveScheduledSurveyStatus.saveScheduled) {
          setSaveScheduledSurveyStatus({
            canSave: true,
            saveScheduled: true,
          })
        }
      }
    }
  }, [ tutorialState, saveScheduledSurveyStatus ])

  React.useEffect(() => {
    if (saveScheduledSurveyStatus.saveScheduled && saveScheduledSurveyStatus.canSave) {
      setSaveScheduledSurveyStatus({
        canSave: !isTutorialActive(),
        saveScheduled: false,
      })
      saveSurvey()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ saveSurvey, saveScheduledSurveyStatus ])

  // Trigger the next step of the tutorial when the confirmation
  // of the creation is pending (i.e. the user can create or cancel)
  React.useEffect(() => {
    if (!isValidateActionDisabled && !isCreatingSurvey) {
      triggerTutorialEvent(TUTORIAL_SURVEYS_EVENTS.scheduledSurveyCreatePending())
    }
  }, [ isValidateActionDisabled, triggerTutorialEvent, isCreatingSurvey ])

  // Update selected values when the scheduled survey changes
  React.useEffect(setDefaultValues, [ props.scheduledSurvey ])

  React.useEffect(() => {
    if (createHeaderRef.current !== null) {
      setStepTarget("scheduled-survey-creation-fields", createHeaderRef.current)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ createHeaderRef.current ])

  React.useEffect(() => {
    if (selectedDate?.to !== undefined && selectedDate?.from !== undefined && selectedSurveyId !== undefined) {
      getSurveyScheduling({
        variables: {
          // Remove timezone info because backend treat them as local dates
          beginAt: selectedDate?.from.clone().utcOffset(0, true).toISOString(),
          endAt: selectedDate.to.clone().utcOffset(0, true).toISOString(),
          surveyId: selectedSurveyId,
        },
      })
    }
  }, [ getSurveyScheduling, selectedDate, selectedSurveyId ])

  React.useEffect(() => {
    if (props.scheduledSurvey?.scheduledReminders) {
      setScheduledReminders(getMappedScheduledReminders(props.scheduledSurvey?.scheduledReminders))
    }
    if (props.scheduledSurvey?.userGroups) {
      setSelectedSurveyGroupId(props.scheduledSurvey?.userGroups.map(group => group.uuid))
    }
  }, [ props.scheduledSurvey ])

  return (
    <Styles.Form $isLoading={props.isLoadingQuestions} className="ScheduledSurveyForm">
      {creationConfirmationDialog()}
      {ongoingSurveyCancellationConfirmationDialog()}
      {todoSurveyCancellationConfirmationDialog()}

      <Styles.Header ref={createHeaderRef} className="ScheduledSurveyForm__header">
        <SearchableSelect
          label={t`Theme`}
          options={themeOptions}
          onChange={updateThemeSelection}
          selectPlaceholder={t`Select a theme`}
          defaultValues={selectedThemeId !== undefined ? [ selectedThemeId.toString() ] : undefined}
          isDisabled={props.isReadonly}
          isFullWidth
          isSearchable={false}
          isLoading={props.isLoadingThemes}
          data-testid="ScheduledSurveyForm__theme-select"
        />

        <SearchableSelect
          label={t`Survey`}
          onChange={updateSurveySelection}
          options={surveyOptions}
          selectPlaceholder={t`Select a survey`}
          defaultValues={selectedSurveyId !== undefined ? [ selectedSurveyId.toString() ] : undefined}
          isDisabled={props.isReadonly || !selectedThemeId || props.isLoadingThemes}
          isFullWidth
          isSearchable={false}
          isLoading={props.isLoadingSurveys}
        />

        <ScheduledSurveyDateRangePicker
          label={t`Participation range`}
          placeholder={t`Select a range`}
          onValidate={setSelectedDate}
          value={selectedDate}
          markedDays={props.daysWithScheduledSurveys}
          pickerAlignment="right"
          isDisabled={props.isReadonly}
          isFullWidth
        />

        {!props.isReadonly && (
          <>
            <SearchableSelect
              label={t`Group`}
              onChange={updateSurveyGroupSelection}
              options={surveyGroupOptions}
              selectPlaceholder={t`Select a group`}
              defaultValues={selectedSurveyGroupId}
              isDisabled={props.isReadonly || availableSurveyGroups?.length === 0}
              isFullWidth
              isSearchable
              isMultiselect
              isLoading={!availableSurveyGroups}
            />

            <Tooltip
              title={t`If the box is checked, the results will have to be published by the admin to be visible on the 
         recipients' interface. Otherwise, results will be available at the start of the next scheduled survey.`}
              enterDelay={300}
            >
              <Styles.ManualPublicationContainer>
                <Styles.Title>{t`Results publication`}</Styles.Title>
                <Styles.ManualPublicationContent $isChecked={isManualPublication}>
                  <FormControlLabel
                    label={t`Manual publication`}
                    control={
                      <Checkbox
                        checked={isManualPublication}
                        onChange={(event, isChecked) => {
                          setIsManualPublication(isChecked)
                        }}
                        color="primary"
                      />
                    }
                  />
                </Styles.ManualPublicationContent>
              </Styles.ManualPublicationContainer>
            </Tooltip>
          </>
        )}
        {props.editingMode !== "edit" && (
          <UserGroupsHeadersDetails
            scheduledSurveyStatus={props.scheduledSurvey?.status.absolute}
            userGroups={props?.scheduledSurvey?.userGroups || []}
          />
        )}

        {(isSelectionOverlappingExistingSurvey || isClusterViolated || availableSurveyGroups?.length === 0) && (
          <Styles.FormWarnings>
            {isSelectionOverlappingExistingSurvey && !props.areDatesLoading && (
              <InformationMessage
                variant={"standard"}
                // eslint-disable-next-line max-len
                text={t`The participation range you selected overlaps another scheduled survey. To maximize the participation rate, we highly recommend that you avoid overlapping scheduled surveys.`}
              />
            )}

            {isClusterViolated && (
              <InformationMessage
                variant={"warning"}
                text={recipientsCount > 0
                  // eslint-disable-next-line max-len
                  ? t`In order to preserve anonymity, the survey results will be available if there are at least ${clusterSize} participation(s) at its closing. Your current users list contains ${recipientsCount} person(s). In order to increase participation, we suggest you to add more users.`
                  // eslint-disable-next-line max-len
                  : t`In order to preserve anonymity, the survey results will be available if there are at least ${clusterSize} participation(s) at its closing. Your current users list is empty. In order to increase participation, we suggest you to add more users.`
                }
              />
            )}

            {assigneesInformation?.haveDifferentTimezones && (
              <InformationMessage
                // eslint-disable-next-line max-len
                text={t`Some recipients are not in your timezone. The scheduled survey availability will be local to each timezone.`}
              />
            )}

            {availableSurveyGroups?.length === 0 && (
              <InformationMessage
                variant={"warning"}
                // eslint-disable-next-line max-len
                text={t`You must create at least one group and add users to it in order to send a survey.`}
              />
            )}
          </Styles.FormWarnings>
        )}
      </Styles.Header>

      {selectedSurveyId && selectedTheme && selectedSurvey && selectedSurveyGroupId.length > 0 && (
        surveySchedulingData?.surveyScheduling && !isTutorialActive() &&
        <ScheduledRemindersSection
          scheduledReminders={scheduledReminders}
          surveyScheduling={surveySchedulingData?.surveyScheduling}
          onChange={onChangeScheduledReminders}
        />
      )}

      {selectedSurveyId && selectedTheme && selectedSurvey && selectedSurveyGroupId.length > 0 && (
        <Styles.Body className="ScheduledSurveyForm__body">
          {props.isLoadingQuestions && <CenteredLoader isTransparent/>}
          {props.surveyQuestions && (
            <>
              {timeline(selectedTheme, selectedSurvey, props.surveyQuestions)}
              {props.children}
              <Styles.FormActions>
                {props.isReadonly && props.scheduledSurvey?.status.absolute === ScheduledSurveyStatus.Todo && (
                  <PrimaryButton
                    onClick={props.onSwitchToEdit}
                    disabled={!isUserAllowedToManage}
                    tooltip={!isUserAllowedToManage
                      ? t`You don't have sufficient rights to perform this action`
                      : undefined
                    }
                    tooltipPlacement={"top"}
                    data-testid="ScheduledSurveyForm__edit-button"
                  >
                    {t`Edit`}
                  </PrimaryButton>
                )}

                {props.scheduledSurvey && props.onDeleteScheduledSurvey && (
                  <PrimaryButton
                    destructive
                    onClick={tryCancelingSurvey}
                    disabled={!isUserAllowedToManage}
                    tooltip={!isUserAllowedToManage
                      ? t`You don't have sufficient rights to perform this action`
                      : undefined
                    }
                    tooltipPlacement="top"
                    data-testid="ScheduledSurveyForm__delete-button"
                  >
                    {t`Delete`}
                  </PrimaryButton>
                )}

                {!props.isReadonly && (
                  <>
                    <PrimaryButton
                      isInverted
                      onClick={cancelUserInput}
                      tooltipPlacement={"top"}
                    >
                      {t`Cancel`}
                    </PrimaryButton>
                    <PrimaryButton
                      disabled={isValidateActionDisabled}
                      onClick={tryCreatingSurvey}
                      tooltip={!isUserAllowedToManage
                        ? t`You don't have sufficient rights to perform this action`
                        : undefined
                      }
                    >
                      {t`Validate`}
                    </PrimaryButton>
                  </>
                )}
              </Styles.FormActions>
            </>
          )}
        </Styles.Body>
      )}
    </Styles.Form>
  )

  function onChangeScheduledReminders(changedScheduledReminders: ScheduledReminder[]): void {
    setScheduledReminders(changedScheduledReminders)
    if (changedScheduledReminders.length > 0 && props.isReadonly) {
      props.onScheduledRemindersChange(changedScheduledReminders)
    }
  }

  /**
   * Return the mapped scheduled reminders from backend with the one used by Scheduled Reminder section
   * TODO: should be handled by the backend, we should have the same models
   */
  function getMappedScheduledReminders(reminders: ScheduledReminderModel[]): ScheduledReminder[] {
    return reminders.map((scheduledReminder) => {
      return {
        isExecuted: !scheduledReminder.is_editable,
        timeReminder: {
          unit: ScheduledTimeReminderUnit.Hours,
          value: scheduledReminder.remind_hours_before_end,
        },
      }
    })
  }

  /**
   * Show the questions of a survey
   * @param theme Theme to display
   * @param survey Survey to display
   * @param questions Questions to display
   */
  function timeline(theme: Theme, survey: Survey, questions: Question[]) {
    return (
      <Timeline
        color={theme.color}
        icon={theme.icon}
        title={survey.name}
        coloredTitle={theme.name}
      >
        {questions.map((question, index) => (
          <TimelineItem
            borderColor={theme.color}
            label={t`Question ` + (index + 1)}
            key={question.id}
          >
            <Styles.Question.Wording>{question.name}</Styles.Question.Wording>
            <Styles.Question.Choices
              isCollapsible={!isTutorialActive()}
              invite={t`Answers`}
            >
              {question.choices.map(choice => (
                <Styles.Question.Choice key={choice.id}>
                  {choice.name}
                </Styles.Question.Choice>
              ))}
            </Styles.Question.Choices>
          </TimelineItem>
        ))}
      </Timeline>
    )
  }

  /**
   * Dialog requesting user confirmation for creating an already-started survey
   */
  function creationConfirmationDialog() {
    return (
      <Dialog open={isCreationConfirmationRequired}>
        <DialogTitle disableTypography>{t`The start date has already passed for some recipients!`}</DialogTitle>
        <DialogContent>
          { // eslint-disable-next-line max-len
            t`The starting date you selected has already passed for some recipients. This means that the survey will be sent right away for them.`
          }
        </DialogContent>
        <DialogActions>
          <PrimaryButton isInverted onClick={() => setCreationConfirmationRequired(false)}>
            {t`Cancel`}
          </PrimaryButton>
          <PrimaryButton onClick={confirmSavingSurvey}>
            {t`Schedule anyway`}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    )
  }

  /**
   * Dialog requesting user confirmation for cancelling an ongoing survey
   */
  function ongoingSurveyCancellationConfirmationDialog() {
    return (
      <Dialog open={isCancelOngoingConfirmationRequired}>
        <DialogTitle disableTypography>{t`This survey is already ongoing`}</DialogTitle>
        <DialogContent>
          { // eslint-disable-next-line max-len
            t`Answers already recorded will be deleted and recipients that do not have answered it yet will not be able to access it.`
          }<br/>

          <FormControlLabel
            label={t`Yes, I understand and still want to delete this survey.`}
            control={
              <Checkbox
                checked={isCancelOngoingConfirmed}
                onChange={(event, isChecked) => {
                  setCancelOngoingConfirmed(isChecked)
                }}
                color="primary"
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            isInverted
            onClick={() => {
              setCancelOngoingConfirmed(false)
              setCancelOngoingConfirmationRequired(false)
            }}
          >
            {t`Cancel`}
          </PrimaryButton>
          <PrimaryButton
            destructive
            disabled={!isCancelOngoingConfirmed}
            onClick={confirmSurveyCancellation}
          >
            {t`Delete`}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    )
  }

  /**
   * Dialog requesting user confirmation for cancelling a todo survey
   */
  function todoSurveyCancellationConfirmationDialog() {
    return (
      <Dialog open={isCancelTodoConfirmationRequired}>
        <DialogTitle disableTypography>{t`Are your sure you want to delete this survey scheduling?`}</DialogTitle>
        <DialogActions>
          <PrimaryButton
            isInverted
            onClick={() => setCancelTodoConfirmationRequired(false)}
          >
            {t`Cancel`}
          </PrimaryButton>
          <PrimaryButton
            destructive
            onClick={confirmSurveyCancellation}
          >
            {t`Delete`}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    )
  }

  /**
   * Update selected theme states
   * @param themeSelection New selection
   */
  function updateThemeSelection(themeSelection: SearchableSelectOption[]) {
    const selectedId = themeSelection?.[0] && parseInt(themeSelection[0].value, 10)

    if (selectedId === undefined) {
      setSelectedThemeId(undefined)
      setSelectedSurveyId(undefined)
    }
    else {
      setSelectedSurveyId(undefined)
      setSelectedThemeId(selectedId)
    }

    props.onThemeSelected?.(selectedId)
  }

  /**
   * Update selected theme state
   * @param surveySelection New selection
   */
  function updateSurveySelection(surveySelection: SearchableSelectOption[]) {
    const selectedId = surveySelection?.[0] && parseInt(surveySelection[0].value, 10)

    setSelectedSurveyId(selectedId)
    props.onSurveySelected?.(selectedId)
  }

  /**
   * Update selected group state
   * @param surveyGroupSelection New selection
   */
  function updateSurveyGroupSelection(surveyGroupSelection: SearchableSelectOption[]) {
    const surveyGroupsSelectedValue = surveyGroupSelection.map(group => group.value)
    if (
      surveyGroupsSelectedValue.includes("all")
      || (surveyGroupsSelectedValue.length === availableSurveyGroups?.length)
    ) {
      setSelectedSurveyGroupId([ "all" ])
    }
    else {
      setSelectedSurveyGroupId(surveyGroupsSelectedValue)
    }
  }

  /** Check that survey creation is allowed and do it or display a confirmation window */
  function tryCreatingSurvey() {
    setIsCreatingSurvey(true)
    const scheduledSurvey: SurveyScheduling = {
      beginAt: selectedDate!.from!,
      endAt: selectedDate!.to!,
      isManualPublication: isManualPublication,
      surveyId: selectedSurveyId!,
      userGroupUuids: selectedSurveyGroupId.includes("all")
        ? (availableSurveyGroupsUuids ?? [])
        : selectedSurveyGroupId,
    }

    // Check that the selected beginAt date has not already passed for some recipients
    const currentTimeInLatestTimezone = moment().tz(assigneesInformation?.earliest || "utc", true)
    const currentTimeInAdminTimezone = moment().tz(session.user?.timezone || "utc", true)
    const startTimeInLatestTimezone =
      scheduledSurvey.beginAt.clone().tz(assigneesInformation?.earliest || "utc", true)

    if (
      currentTimeInLatestTimezone.isAfter(startTimeInLatestTimezone) ||
      currentTimeInAdminTimezone.isAfter(startTimeInLatestTimezone)
    ) {
      setCreationConfirmationRequired(true)
    }
    else {
      confirmSavingSurvey()
    }
  }

  /** Update state to mark cancellation request */
  function tryCancelingSurvey() {
    switch (props.scheduledSurvey!.status.local) {
      case ScheduledSurveyStatus.Ongoing:
        setCancelOngoingConfirmationRequired(true)
        break
      case ScheduledSurveyStatus.Todo:
        setCancelTodoConfirmationRequired(true)
        break
    }
  }

  /**
   * Save the survey and reset the form state
   * Use an object assign because the resetForm will clean up the selectedDate state before saving is done
   */
  function saveSurvey() {
    if (props.onSave) {
      props.onSave(Object.assign<Record<string, unknown>, SurveyScheduling>({}, {
        beginAt: selectedDate!.from!,
        endAt: selectedDate!.to!,
        isManualPublication: isManualPublication,
        scheduledReminders: scheduledReminders,
        surveyId: selectedSurveyId!,
        userGroupUuids: selectedSurveyGroupId.includes("all")
          ? (availableSurveyGroupsUuids ?? [])
          : selectedSurveyGroupId,
      }))
      resetForm()
    }
  }

  /** Reset form and fire parent callback for saving */
  function confirmSavingSurvey() {
    triggerTutorialEvent(TUTORIAL_SURVEYS_EVENTS.scheduledSurveyCreateInProgress())
    setSaveScheduledSurveyStatus({
      canSave: !isTutorialActive(),
      saveScheduled: true,
    })
  }

  /** Fire parent callback for survey cancellation */
  function confirmSurveyCancellation() {
    switch (props.scheduledSurvey!.status.local) {
      case ScheduledSurveyStatus.Ongoing:
        setCancelOngoingConfirmationRequired(false)
        break
      case ScheduledSurveyStatus.Todo:
        setCancelTodoConfirmationRequired(false)
        break
    }

    props.onDeleteScheduledSurvey?.()
  }

  /** Reset the form if creating, cancel edit if editing */
  function cancelUserInput() {
    triggerTutorialEvent(TUTORIAL_SURVEYS_EVENTS.scheduledSurveyCreateCanceled())
    if (!props.scheduledSurvey) {
      resetForm()
    }
    else {
      props.onCancelEdit?.()
    }
  }

  /** Empty the fields */
  function resetForm() {
    setSelectedSurveyId(undefined)
    setSelectedThemeId(undefined)
    setSelectedDate(undefined)
    setSelectedSurveyGroupId([])
    setCreationConfirmationRequired(false)
  }

  /** Set default values in form */
  function setDefaultValues() {
    if (props.scheduledSurvey) {
      setSelectedThemeId(props.scheduledSurvey.survey.theme.id)
      setSelectedSurveyId(props.scheduledSurvey.survey.id)
      setSelectedDate({
        from: moment.parseZone(props.scheduledSurvey.beginAt.local),
        to: moment.parseZone(props.scheduledSurvey.endAt.local),
      })
    }
    else {
      setSelectedThemeId(undefined)
      setSelectedSurveyId(undefined)
      setSelectedDate({
        from: undefined,
        to: undefined,
      })
      setIsManualPublication(false)
    }
  }
}

/**
 * Get a collection of SelectOptionProps from a collection of Theme
 * @param themes Themes to convert
 */
function themesToSelectOptions(themes: Theme[] | undefined): SearchableSelectOption[] {
  const options: SearchableSelectOption[] = []

  if (!themes) {
    return options
  }

  themes.forEach(theme => {
    options.push({
      description: theme.lastUsedAt
        ? t`Last used on ` + moment.parseZone(theme.lastUsedAt).format("Do MMMM")
        : "",
      label: theme.name,
      value: theme.id.toString(),
    })
  })

  return options
}

/**
 * Get a collection of SelectOptionProps from a collection of Survey
 * @param surveys Surveys to convert
 */
function surveysToSelectOptions(surveys: Survey[] | undefined): SearchableSelectOption[] {
  const selectableSurveys: SearchableSelectOption[] = []

  if (!surveys) {
    return selectableSurveys
  }

  surveys.forEach(survey => {
    selectableSurveys.push({
      description: survey.lastUsedAt
        ? t`Last used on ` + moment.parseZone(survey.lastUsedAt).format("Do MMMM")
        : "",
      label: survey.name,
      value: survey.id.toString(),
    })
  })

  return selectableSurveys
}

/**
 * Get a collection of SelectOptionProps from a collection of SurveyGroups
 * @param surveyGroups Surveys to convert
 * @param usersCount Number of users in total
 */
function surveyGroupsToSelectOptions(
  surveyGroups: UserGroup[] | undefined,
  usersCount: number,
): SearchableSelectOption[] {
  const selectableSurveyGroups: SearchableSelectOption[] = [
    {
      label: <Tooltip
        // eslint-disable-next-line max-len
        title={t`This number can be lower than the sum of all the groups in the case where a user is present in several groups.`}
        placement={"top"}
        arrow>
        <p>{t`All groups`} ({usersCount})</p>
      </Tooltip>,
      value: "all",
    },
  ]

  if (!surveyGroups) {
    return selectableSurveyGroups
  }

  surveyGroups.forEach(surveyGroup => {
    selectableSurveyGroups.push({
      label: `${surveyGroup.name} (${surveyGroup.usersCount})`,
      value: surveyGroup.uuid,
    })
  })

  return selectableSurveyGroups
}

export interface ScheduledSurveyFormProps {
  /** Scheduled survey to show */
  scheduledSurvey?: ScheduledSurvey,

  /** List of available themes to choose from */
  availableThemes?: Theme[],

  isLoadingThemes?: boolean,

  /** List of available surveys to choose from */
  availableSurveys?: Survey[],

  isLoadingSurveys?: boolean,

  /** List of questions for the selected survey */
  surveyQuestions?: Question[],

  isLoadingQuestions?: boolean,

  /** Called when the user selects a theme */
  onThemeSelected?: (themeId?: number) => void,

  /** Called when the user selects a survey */
  onSurveySelected?: (surveyId?: number) => void,

  /** Hide warning message flag if the dates are loading */
  areDatesLoading?: boolean,

  daysWithScheduledSurveys?: moment.Moment[],

  isReadonly?: boolean,

  /** Fired when the user switches to edit mode */
  onSwitchToEdit?: () => void,

  /** Fired when the user cancels the edition */
  onCancelEdit?: () => void,

  /** Fired when the user adds a scheduled reminder */
  onScheduledRemindersChange: (scheduledReminders: ScheduledReminder[]) => void,

  /** Fired when the user validates the form */
  onSave?: (scheduledSurvey: SurveyScheduling) => void,

  /** If provided, a "cancel" action is available and fires this callback if the user confirms the cancellation  */
  onDeleteScheduledSurvey?: () => void,

  editingMode: "read" | "edit",
}

export {
  ScheduledSurveyForm,
}
