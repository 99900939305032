/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPsyReportStatisticsByPeriodQueryVariables = Types.Exact<{
  psyDispositifNanoId: Types.Scalars['String'];
  dateInterval?: Types.InputMaybe<Types.DateIntervalInput>;
}>;


export type GetPsyReportStatisticsByPeriodQuery = { getPsyReportStatistics?: { psyReportStatisticsByPeriod: { interval: Types.IntervalEnum, list: Array<{ psyReportsCount: number, psyRecipientsCount: number, period: string }> } } | undefined };


export const GetPsyReportStatisticsByPeriodDocument = gql`
    query GetPsyReportStatisticsByPeriod($psyDispositifNanoId: String!, $dateInterval: DateIntervalInput) {
  getPsyReportStatistics(psyDispositifNanoId: $psyDispositifNanoId, dateInterval: $dateInterval) {
    psyReportStatisticsByPeriod {
      interval
      list {
        psyReportsCount
        psyRecipientsCount
        period
      }
    }
  }
}
    `;

/**
 * __useGetPsyReportStatisticsByPeriodQuery__
 *
 * To run a query within a React component, call `useGetPsyReportStatisticsByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPsyReportStatisticsByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPsyReportStatisticsByPeriodQuery({
 *   variables: {
 *      psyDispositifNanoId: // value for 'psyDispositifNanoId'
 *      dateInterval: // value for 'dateInterval'
 *   },
 * });
 */
export function useGetPsyReportStatisticsByPeriodQuery(baseOptions: Apollo.QueryHookOptions<GetPsyReportStatisticsByPeriodQuery, GetPsyReportStatisticsByPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPsyReportStatisticsByPeriodQuery, GetPsyReportStatisticsByPeriodQueryVariables>(GetPsyReportStatisticsByPeriodDocument, options);
      }
export function useGetPsyReportStatisticsByPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPsyReportStatisticsByPeriodQuery, GetPsyReportStatisticsByPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPsyReportStatisticsByPeriodQuery, GetPsyReportStatisticsByPeriodQueryVariables>(GetPsyReportStatisticsByPeriodDocument, options);
        }
export type GetPsyReportStatisticsByPeriodQueryHookResult = ReturnType<typeof useGetPsyReportStatisticsByPeriodQuery>;
export type GetPsyReportStatisticsByPeriodLazyQueryHookResult = ReturnType<typeof useGetPsyReportStatisticsByPeriodLazyQuery>;
export type GetPsyReportStatisticsByPeriodQueryResult = Apollo.QueryResult<GetPsyReportStatisticsByPeriodQuery, GetPsyReportStatisticsByPeriodQueryVariables>;