import * as React from "react"
import { t } from "ttag"
import { useTheme } from "styled-components"
import { SelectDrawerOptionProps, SelectSideDrawer } from "library/Select/SelectSideDrawer/SelectSideDrawer"

import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { OptionProps } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import InputBase from "@material-ui/core/InputBase"

import { MomentRange } from "models/DateTime/MomentRange"
import * as Styles from "./Select.styles"

/** Select component */
export const Select: React.FC<SelectProps> = (props) => {
  const theme = useTheme()

  const [ isMuiSelectFocused, setMuiSelectFocused ] = React.useState(false)
  const [ isMenuOpen, setMenuOpen ] = React.useState(false)
  const [ isSideDrawerOpen, setSideDrawerOpen ] = React.useState(false)
  const [ currentDrawerProps, setCurrentDrawerProps ] = React.useState<SelectDrawerOptionProps | undefined>(undefined)

  const selectMenuRef = React.useRef<HTMLDivElement>()

  let selections: Array<number | string> = []
  if (props.value !== undefined && props.options.length !== 0) {
    if (props.isMultiselect && Array.isArray(props.value)) {
      selections = props.value
    }
    else if (!props.isMultiselect && !Array.isArray(props.value)) {
      selections = [ props.value! ]
    }
  }
  const selectValue = props.isMultiselect ? selections : selections[0]

  const prepareOptions = (options: SelectOptionProps[]): [SelectOptionGroup[], SelectOptionProps[]] => {
    const groupedOptions: { [key: string]: SelectOptionProps[] } = {}
    const ungroupedOptions: SelectOptionProps[] = [] as SelectOptionProps[]

    options.forEach(option => {
      if (option.group) {
        if (!groupedOptions[option.group]) {
          groupedOptions[option.group] = []
        }
        groupedOptions[option.group].push(option)
      }
      else {
        ungroupedOptions.push(option)
      }
    })

    const selectOptionGroups: SelectOptionGroup[] = []
    Object.keys(groupedOptions).forEach((groupName) => {
      selectOptionGroups.push({
        name: groupName,
        options: groupedOptions[groupName],
      })
    })

    return [ selectOptionGroups, ungroupedOptions ]
  }

  const [ groupedOptions, ungroupedOptions ] = prepareOptions(props.options)

  React.useEffect(() => {
    if (props.isOpen !== undefined) {
      setMenuOpen(props.isOpen)
    }
  }, [ props.isOpen ])

  return (
    <Styles.Label>
      {props.label && <Styles.LabelText $isSelectFocused={isMuiSelectFocused}>{props.label}</Styles.LabelText>}
      <Styles.Select
        open={isMenuOpen}
        onOpen={onMenuOpen}
        onClose={onMenuClose}
        $isEmpty={selections.length === 0}
        $isSmall={props.height === "small"}
        name={props.name}
        value={selectValue !== undefined ? selectValue : ""}
        multiple={props.isMultiselect}
        onChange={handleChange}
        displayEmpty
        className={props.className}
        fullWidth={props.isFullWidth}
        disabled={props.disabled}
        renderValue={renderSelection}
        input={<InputBase/>}
        aria-label={props["aria-label"]}
        style={{
          width: props.width ? props.width : undefined,
        }}

        MenuProps={{
          TransitionProps: {
            onEnter: () => setMuiSelectFocused(true),
            onExit: () => setMuiSelectFocused(false),
          },
          // `disableScrollLock` is used to avoid scroll event on the whole body (triggering ScheduledSurveyList
          // `onScroll` event hence closing the select when opened)
          disablePortal: true,
          // dirty workaround for a known bug from mui https://github.com/mui-org/material-ui/issues/19245
          disableScrollLock: true,
          getContentAnchorEl: element => {
            selectMenuRef.current = element as HTMLDivElement
            return null as unknown as HTMLDivElement
          },
        }}
        IconComponent={props.iconComponent
          ? props.iconComponent
          : () => <Styles.DropdownIcon name={"keyboard_arrow_down"} size={24}/>
        }
      >
        {[
          props.defaultItem && (
            <Styles.OptionContainer
              classes={{ selected: "selected" }}
              value=""
              key="default"
            >
              <Styles.OptionIcon />
              <Styles.DefaultOption wording={props.placeholder ? props.placeholder : ""}/>
            </Styles.OptionContainer>
          ),
          ...(groupedOptions.map(group => [
            <Styles.OptionGroup
              disableSticky
            >
              {group.name}
            </Styles.OptionGroup>,
            ...(group.options.map(option =>
              (
                <Styles.OptionContainer
                  disabled={option.isDisabled}
                  classes={{ selected: "selected" }}
                  value={option.value}
                  key={option.value}
                  onClickCapture={option.surveyDateRange
                    ? (event) => onSurveyRangeOptionClick(event, option)
                    : undefined
                  }
                >
                  <Styles.OptionIcon>
                    <Icon
                      color={theme.colors.primary}
                      name={option.icon ? option.icon : selections.includes(option.value as number) ? "done" : ""}
                      size={18}
                    />
                  </Styles.OptionIcon>
                  <Styles.Option
                    wording={option.wording}
                    subWording={option.subWording}
                  />
                </Styles.OptionContainer>
              ))),
          ])),
          ...(ungroupedOptions.map(option => (
            <Styles.OptionContainer
              disabled={option.isDisabled}
              classes={{ selected: "selected" }}
              value={option.value}
              key={option.value}
            >
              <Styles.OptionIcon>
                <Icon
                  color={theme.colors.primary}
                  name={option.icon ? option.icon : selections.includes(option.value as number) ? "done" : ""}
                  size={18}
                />
              </Styles.OptionIcon>
              <Styles.Option
                wording={option.wording}
                subWording={option.subWording}
              />
            </Styles.OptionContainer>
          ))),
        ]}
      </Styles.Select>
      {isSideDrawerOpen && selectMenuRef.current && currentDrawerProps && (
        <SelectSideDrawer
          {...currentDrawerProps}
          anchorRef={selectMenuRef.current}
          onDateValidated={onDateSelected}
        >
        </SelectSideDrawer>
      )}
    </Styles.Label>
  )

  function onMenuOpen() {
    setMenuOpen(true)
  }

  function onMenuClose() {
    setMenuOpen(false)
    setSideDrawerOpen(false)
  }

  function onDateSelected(offset: number) {
    setSideDrawerOpen(false)
    if (props.onChange) {
      props.onChange({
        value: offset,
        wording: "",
      })
    }
  }

  function renderSelection(value: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    let inputText: string | JSX.Element = ""

    if (props.options.length > 0 && value !== undefined && value !== "") {
      if (props.isMultiselect && value.length > 1) {
        inputText = t`${value.length} items`
      }
      else {
        const singleValue = props.isMultiselect ? value[0] : value
        const selectedOption = props.options.find(option => option.value === singleValue)

        if (selectedOption?.surveyDateRange?.to) {
          const endAt = selectedOption?.surveyDateRange?.to.clone()
          inputText = endAt?.subtract(selectedOption.value, "hours").format("LLL")
        }
        else {
          inputText = selectedOption ? selectedOption.wording : ""
        }
      }
    }

    if (inputText) {
      return (
        <Styles.InputText>
          {inputText}
        </Styles.InputText>
      )
    }
    else if (value !== undefined && props.isMultiselect && value.length !== 0) {
      if (value.length > 1) {
        return <Styles.InputText>{t`${value.length} items`}</Styles.InputText>
      }
      else {
        const selectedOption = props.options.find(option => option.value === value[0])
        return (
          <Styles.InputText>
            {selectedOption ? selectedOption.wording : ""}
          </Styles.InputText>
        )
      }
    }
    else {
      return (
        <Styles.InputPlaceholder>
          {props.placeholder || <>&nbsp;</>}
        </Styles.InputPlaceholder>
      )
    }
  }

  function handleChange(event: React.ChangeEvent<{ name?: string, value: unknown }>) {
    event.stopPropagation()

    if (props.isMultiselect && Array.isArray(event.target.value)) {
      const newValues = event.target.value
      const newSelection = props.options.filter(option => newValues.includes(option.value))
      if (props.onMultiChange) {
        props.onMultiChange(newSelection, event)
      }
    }
    else {
      if (props.onChange) {
        props.onChange(props.options.find(option => option.value === event.target.value), event)
      }
    }
  }

  function onSurveyRangeOptionClick(event: React.MouseEvent<HTMLLIElement>, option: SelectOptionProps) {
    event.stopPropagation()

    setCurrentDrawerProps({
      dateRange: option.surveyDateRange,
      defaultValue: option.value.toString(),
    })
    setSideDrawerOpen(true)
  }
}

export interface SelectProps extends BaseProps {
  /** Label displayed alongside the control */
  label?: string,

  /** props including all option objects */
  options: SelectOptionProps[],

  /** placeholder for default wording */
  placeholder?: string,

  /** Disables the button, rendering it inactive */
  disabled?: boolean,

  /** Function that gives back the option selected */
  onChange?: (
    value: SelectOptionProps | undefined,
    event?: React.ChangeEvent<{ name?: string, value: unknown }>
  ) => void,

  /** Function that gives back the options selected or return an empty array if none */
  onMultiChange?: (values: SelectOptionProps[], event: React.ChangeEvent<{ name?: string, value: unknown }>) => void,

  /** props to set a given width to the select min width 160px */
  width?: string,

  /** Take the whole available width */
  isFullWidth?: boolean,

  /** Boolean to control if the select should be open */
  isOpen?: boolean,

  /** optional value to preselect answer */
  value?: number | number[] | string | string[],

  /** Name attribute of the underlying select input */
  name?: string,

  /** Size of the Select */
  height?: "small" | "standard",

  /** If true, add a default item at the head of options list */
  defaultItem?: boolean,

  iconComponent?: React.ElementType,

  /** If true, onChange event will return an array of selected options */
  isMultiselect?: boolean,
}

Select.defaultProps = {
  defaultItem: true,
  height: "standard",
}

export interface SelectOptionGroup {
  name: string,

  options: SelectOptionProps[],
}

export interface SelectOptionProps extends OptionProps {
  /**
   * True if the option should appear as disabled in the list
   */
  isDisabled?: boolean,

  /**
   * Value of the option
   */
  value: number | string,

  /**
   * If provided, options will be grouped with options of the same group
   */
  group?: string,

  /** If provided, the option will open a drawer containing a calendar instead of its normal behavior */
  surveyDateRange?: MomentRange & { disabledHours: number[]},
}
