import * as React from "react"
import { useTheme } from "styled-components"
import { t } from "ttag"

import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { AnswerStatus } from "models/AnswerStatus"
import * as Styles from "./AnswerStatusIcon.styles"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"

export const AnswerStatusIcon: React.FC<AnswerStatusIconProps> = props => {
  const theme = useTheme()

  let statusIcon: { name: string, color: string, title: string }
  switch (props.status) {
    case AnswerStatus.Pending:
      statusIcon = {
        color: theme.colors.blackMedium,
        name: "create",
        title: t`Awaiting Answer`,
      }
      break
    case AnswerStatus.Drafted:
    case AnswerStatus.AwaitingPublishing:
      statusIcon = {
        color: theme.colors.secondary,
        name: "autorenew",
        title: t`Draft`,
      }
      break
    case AnswerStatus.Done:
      statusIcon = {
        color: theme.colors.success,
        name: "done",
        title: t`Answer sent`,
      }
      break
  }

  return (
    <Styles.Container className={props.className}>
      <Icon
        name={statusIcon.name}
        color={statusIcon.color}
      />
      <Styles.Title>{statusIcon.title}</Styles.Title>
    </Styles.Container>
  )
}

interface AnswerStatusIconProps extends BaseProps {
  /** Status to represent */
  status: AnswerStatus,
}
