import styled from "styled-components/macro"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 90px;
  margin: 0 16px;
`

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 4px;

  color: ${({ theme }) => theme.colors.primaryDark};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeLabelBig};
  text-transform: lowercase;
`

export const InfoIcon = styled(Icon)`
  margin-right: 4px;
`

export const Unit = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeLabelMedium};
  margin-top: 2px;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.blackMedium};
  font-size: ${({ theme }) => theme.typography.fontSizeLabelSmaller};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  
  text-transform: uppercase;
  line-height: 13px;
  white-space: pre-line;
`
