/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import { SkinnyUserAttributeValueFragmentFragmentDoc } from '../../../models/generated/SkinnyUserAttributeValueFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnprocessedRecipientCommentsQueryVariables = Types.Exact<{
  processingStatuses?: Types.InputMaybe<Array<Types.Processing_Status> | Types.Processing_Status>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UnprocessedRecipientCommentsQuery = { global: { id: string, recipientCommentsAwaitingAnswerCount: number, recipientCommentsWithDraftedAnswerCount: number, recipientCommentsWithCurrentFiltersCount: number, recipientComments: Array<{ id: number, comment: string, validatedAt?: string | undefined, publishedAt?: string | undefined, isAnonymous: boolean, objectId: number, objectType: Types.CommentObjectType, commentTranslations: Array<{ wording: string, language: { code: string, translatedLabel: string, localizedLabel: string } }>, creator?: { id: number, timezone: string, displayName: string, language: { code: string, translatedLabel: string, localizedLabel: string } } | undefined, creatorAttributeValue?: { name: string, value: string, valueId: number, attributeId: number, isMainHierarchyHead: boolean, parent?: { attributeId: number, valueId: number } | undefined, children: Array<{ attributeId: number, valueId: number }> } | undefined, context: { scheduledSurvey?: { schedulingId: number, beginAt: { local: string }, endAt: { local: string }, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status }, survey: { id: number, name: string, theme: { id: number, color: string, name: string, icon: string } }, userGroups: Array<{ uuid: string, name: string }> } | undefined, question?: { id: number, name: string, isKillerQuestion: boolean } | undefined, response?: { id: number, choice?: { id: number, name: string, icon: string, maxSatisfactionValue: number, satisfactionValue: number } | undefined } | undefined }, answers: Array<{ id: number, comment: string, validatedAt?: string | undefined, publishedAt?: string | undefined, isAnonymous: boolean, commentTranslations: Array<{ wording: string, language: { code: string, translatedLabel: string, localizedLabel: string } }>, creator?: { id: number, timezone: string, displayName: string, language: { code: string, translatedLabel: string, localizedLabel: string } } | undefined }> }> } };


export const UnprocessedRecipientCommentsDocument = gql`
    query UnprocessedRecipientComments($processingStatuses: [Processing_status!], $skip: Int, $limit: Int) {
  global: statistics_global {
    id
    recipientCommentsAwaitingAnswerCount: number_of_recipient_comments(processing_status: awaiting)
    recipientCommentsWithDraftedAnswerCount: number_of_recipient_comments(processing_status: draft)
    recipientCommentsWithCurrentFiltersCount: number_of_recipient_comments(processing_status: $processingStatuses)
    recipientComments: recipient_comments(processing_status: $processingStatuses, limit: $limit, skip: $skip) {
      id
      comment
      commentTranslations: comment_translations {
        wording
        language {
          code
          translatedLabel: translated_label
          localizedLabel: localized_label
        }
      }
      validatedAt: validated_at
      publishedAt: published_at
      isAnonymous: is_anonymous
      creator {
        id
        displayName: display_name
        timezone
        language {
          code
          translatedLabel: translated_label
          localizedLabel: localized_label
        }
      }
      creatorAttributeValue: author_hierarchal_attribute_value {
        ...SkinnyUserAttributeValueFragment
      }
      objectId: object_id
      objectType: object_type
      context {
        scheduledSurvey: scheduled_survey {
          schedulingId: id
          beginAt: begin_at {
            local
          }
          endAt: end_at {
            local
          }
          status {
            absolute
            local
          }
          survey {
            id
            name
            theme {
              id
              color
              name
              icon
            }
          }
          userGroups {
            uuid
            name
          }
        }
        question {
          id
          name
          isKillerQuestion: is_killer_question
        }
        response {
          id
          choice {
            icon: icon_url
            id
            maxSatisfactionValue: max_satisfaction_value
            name
            satisfactionValue: satisfaction_value
          }
        }
      }
      answers: responses {
        id
        validatedAt: validated_at
        publishedAt: published_at
        comment
        commentTranslations: comment_translations {
          wording
          language {
            code
            translatedLabel: translated_label
            localizedLabel: localized_label
          }
        }
        creator {
          id
          displayName: display_name
          timezone
          language {
            code
            translatedLabel: translated_label
            localizedLabel: localized_label
          }
        }
        isAnonymous: is_anonymous
      }
    }
  }
}
    ${SkinnyUserAttributeValueFragmentFragmentDoc}`;

/**
 * __useUnprocessedRecipientCommentsQuery__
 *
 * To run a query within a React component, call `useUnprocessedRecipientCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnprocessedRecipientCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnprocessedRecipientCommentsQuery({
 *   variables: {
 *      processingStatuses: // value for 'processingStatuses'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUnprocessedRecipientCommentsQuery(baseOptions?: Apollo.QueryHookOptions<UnprocessedRecipientCommentsQuery, UnprocessedRecipientCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnprocessedRecipientCommentsQuery, UnprocessedRecipientCommentsQueryVariables>(UnprocessedRecipientCommentsDocument, options);
      }
export function useUnprocessedRecipientCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnprocessedRecipientCommentsQuery, UnprocessedRecipientCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnprocessedRecipientCommentsQuery, UnprocessedRecipientCommentsQueryVariables>(UnprocessedRecipientCommentsDocument, options);
        }
export type UnprocessedRecipientCommentsQueryHookResult = ReturnType<typeof useUnprocessedRecipientCommentsQuery>;
export type UnprocessedRecipientCommentsLazyQueryHookResult = ReturnType<typeof useUnprocessedRecipientCommentsLazyQuery>;
export type UnprocessedRecipientCommentsQueryResult = Apollo.QueryResult<UnprocessedRecipientCommentsQuery, UnprocessedRecipientCommentsQueryVariables>;