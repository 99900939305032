/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPatchnoteListQueryVariables = Types.Exact<{
  limit: Types.Scalars['Int'];
}>;


export type GetPatchnoteListQuery = { getPatchnoteList: Array<{ content: string, id: number, publishedAt: string, title: string }> };


export const GetPatchnoteListDocument = gql`
    query GetPatchnoteList($limit: Int!) {
  getPatchnoteList(skip: 0, limit: $limit) {
    content
    id
    publishedAt
    title
  }
}
    `;

/**
 * __useGetPatchnoteListQuery__
 *
 * To run a query within a React component, call `useGetPatchnoteListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatchnoteListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatchnoteListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetPatchnoteListQuery(baseOptions: Apollo.QueryHookOptions<GetPatchnoteListQuery, GetPatchnoteListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatchnoteListQuery, GetPatchnoteListQueryVariables>(GetPatchnoteListDocument, options);
      }
export function useGetPatchnoteListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatchnoteListQuery, GetPatchnoteListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatchnoteListQuery, GetPatchnoteListQueryVariables>(GetPatchnoteListDocument, options);
        }
export type GetPatchnoteListQueryHookResult = ReturnType<typeof useGetPatchnoteListQuery>;
export type GetPatchnoteListLazyQueryHookResult = ReturnType<typeof useGetPatchnoteListLazyQuery>;
export type GetPatchnoteListQueryResult = Apollo.QueryResult<GetPatchnoteListQuery, GetPatchnoteListQueryVariables>;