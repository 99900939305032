import * as React from "react"
import { t } from "ttag"

import { SatisfactionEvolutionChart, SatisfactionEvolutionChartProps } from "../SatisfactionEvolutionChart"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { SearchableSelectOption } from "@humanpredictiveintelligence/myqvt-library"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"
import { SkeletonWrapper, Skeletonable, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./SatisfactionEvolutionMetrics.styles"
import { useSession } from "features/Session"

const SatisfactionEvolutionMetricsSkeleton: React.FC<SatisfactionEvolutionMetricsSkeletonProps> = (props) => (
  <div className={props.className}>
    <Styles.Card title={t`Satisfaction evolution`}>
      <Styles.Content $isEmpty>

        <Styles.Legend>
          <Styles.LegendCaption>{t`Compare with:`}</Styles.LegendCaption>
          <Styles.SerieColor/>
          <SkeletonWrapper width={100} isLoading/>
          <Styles.SerieColorSecondary/>
          <SkeletonWrapper width={100} isLoading/>
        </Styles.Legend>

        <Styles.ChartContainer>
          <SkeletonWrapper isLoading width={1100} height={260}/>
        </Styles.ChartContainer>

      </Styles.Content>
    </Styles.Card>
  </div>
)

export const SatisfactionEvolutionMetricsComponent: React.FC<Skeletonable<SatisfactionEvolutionMetricsProps>> =
  (props) => {
    const { user } = useSession()

    const chartContainerRef = React.useRef<HTMLDivElement>(null)

    const shouldHideChart = React.useMemo(
      () => props.globalSatisfactionEvolution.length === 0 ||
        props.globalSatisfactionEvolution.every((statistic) => statistic.grade === null),
      [ props.globalSatisfactionEvolution ],
    )

    if (props.isLoading) {
      return <SatisfactionEvolutionMetricsSkeleton className={props.className}/>
    }

    return (
      <div className={props.className}>
        <Styles.Card title={t`Satisfaction evolution`}>
          <Styles.Content $isEmpty={shouldHideChart}>
            <SkeletonWrapper height={375}>
              {shouldHideChart && (
                <div>
                  <Icon name="insights" size={48}/>
                  <p>{t`We don't have anything to show you right now.`}</p>
                </div>
              )}
              {!shouldHideChart && (
                <>
                  {props.comparisonAttributes && props.comparisonAttributes.length > 0 && (
                    <Styles.Legend>
                      <Styles.LegendCaption>{t`Compare with:`}</Styles.LegendCaption>
                      <Styles.SerieColor/>
                      <Styles.SerieSelect
                        defaultValues={[ "all" ]}
                        height="xs"
                        isDisabled
                        options={[ { label: t`All`, value: "all" } ]}
                      />
                      { user?.scopes?.length === 0 && <>
                        <Styles.SerieColorSecondary/>
                        <Styles.SerieSelect
                          defaultValues={
                            props.defaultComparisonAttribute && [ props.defaultComparisonAttribute.id.toString() ]
                          }
                          height="xs"
                          isLoading={props.isLoadingComparisonAttributes}
                          onChange={onComparisonAttributeChange}
                          options={props.comparisonAttributes?.map(attribute => ({
                            label: attribute.label,
                            value: attribute.id.toString(),
                          })) || []}
                          selectPlaceholder="-"
                          searchPlaceholder=""
                        />
                      </>}
                    </Styles.Legend>
                  )}
                  <Styles.ChartContainer ref={chartContainerRef}>
                    <SatisfactionEvolutionChart
                      serie1={props.globalSatisfactionEvolution}
                      serie2={props.comparisonAttributeSatisfactionEvolution}
                    />
                  </Styles.ChartContainer>
                </>
              )}
            </SkeletonWrapper>
          </Styles.Content>
        </Styles.Card>
      </div>
    )

    /** Find the USER_ATTRIBUTE_VALUE associated to the new selection and pass it to the parent component */
    function onComparisonAttributeChange(selectedOptions: SearchableSelectOption[]) {
      if (props.onComparisonAttributeChange) {
        const selectedAttribute = props.comparisonAttributes?.find(
          (attribute) => attribute.id.toString() === selectedOptions?.[0]?.value,
        )

        props.onComparisonAttributeChange(selectedAttribute)
      }
    }
  }

export interface SatisfactionEvolutionMetricsProps extends BaseProps {
  /** Serie of data points repesenting the evolution of satisfaction over time for a specific population */
  comparisonAttributeSatisfactionEvolution?: SatisfactionEvolutionChartProps["serie2"],

  /** Available list of attributes to choose from, to display a second serie of data */
  comparisonAttributes?: USER_ATTRIBUTE_VALUE[],

  /** Default value for the comparison attribute */
  defaultComparisonAttribute?: USER_ATTRIBUTE_VALUE,

  /** Serie of data points representing the evolution of satisfaction over time */
  globalSatisfactionEvolution: SatisfactionEvolutionChartProps["serie1"],

  /** Whether the list of comparison attributes is loading */
  isLoadingComparisonAttributes?: boolean,

  /** Fired when the comparison attribute selection changes */
  onComparisonAttributeChange?: (selection: USER_ATTRIBUTE_VALUE | undefined) => void,
}

export type SatisfactionEvolutionMetricsSkeletonProps = Pick<SatisfactionEvolutionMetricsProps, "className">

export const SatisfactionEvolutionMetrics = withSkeleton(
  SatisfactionEvolutionMetricsComponent,
  SatisfactionEvolutionMetricsSkeleton,
)
