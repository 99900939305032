import styled from "styled-components/macro"

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: ${props => props.theme.sizes.borderRadiusRounded};
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap:.5rem;
  align-items: center;
`

export const Name = styled.h4`
  font-weight: 600;
  margin:0;
`

export const Mail = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  transition: color .2s ease-in-out;
  
  &:hover {
    color: ${props => props.theme.colors.primaryDark};
  }
`

export const TablePagination = styled.div`
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const ReportListContainer = styled.div`
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  overflow: auto;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 2rem;
  flex-grow: 1;
  margin: 1rem 0;
  padding: 1rem 0;
`

export const NoReports = styled.div`
  margin: 5rem auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const NoReportsImage = styled.img`
  width: 150px;
  height: auto;
  margin-bottom: 1rem;
`
