import React, { PropsWithChildren } from "react"
import { Redirect, useHistory } from "react-router"
import { TutorialState } from "features/Tutorial/TutorialState"
import { useTutorial } from "features/Tutorial/TutorialContext"

const TutorialRedirect = (props: PropsWithChildren<{
  tutorialState: TutorialState,
  currentLocation: string,
}>) => {
  const { isTutorialActive } = useTutorial()
  const history = useHistory()
  const historyBlockHandle = React.useRef<() => void>()

  const { currentLocation, tutorialState } = props
  const isActive = isTutorialActive()
  const [ redirect, setRedirect ] = React.useState(false)
  const [ redirectTo, setRedirectTo ] = React.useState<string | undefined>(undefined)
  const currentStep = tutorialState.steps[tutorialState.stepIndex]

  React.useEffect(() => {
    function needsRedirect(pageToRedirect: string) {
      return pageToRedirect !== currentLocation
    }

    if (isActive && tutorialState.tutorial && currentStep) {
      // If tutorial or tutorial step has an active page defined
      const pageToRedirect = tutorialState.tutorial.activePage || currentStep.activePage

      if (!historyBlockHandle.current) {
        historyBlockHandle.current = history.block()
      }

      if (pageToRedirect) {
        if (!needsRedirect(pageToRedirect)) {
          return setRedirect(false)
        }

        // Remove historyBlock listener before Redirection
        historyBlockHandle.current?.()
        historyBlockHandle.current = undefined
        // If tutorial or tutorial step has an active page defined, choose the step over the tutorial
        setRedirectTo(currentStep.activePage ?? tutorialState?.tutorial?.activePage)
        setRedirect(true)
      }
    }
    else if (historyBlockHandle.current) {
      // Remove historyBlock listener if tutorial isn't running
      historyBlockHandle.current?.()
      historyBlockHandle.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentStep, tutorialState, currentLocation, isActive ])

  if (!isActive) {
    return <>{props.children}</>
  }
  const shouldRedirect = redirect && redirectTo

  return shouldRedirect ? <><Redirect to={{ pathname: redirectTo }}/></> : <>{props.children}</>
}

export default TutorialRedirect
