import React from "react"

import { Menu } from "./Menu"
import { CenteredLayout, TabsLayout, VerticalMenuLayout } from "@humanpredictiveintelligence/myqvt-library"
import { FeatureWithTabs, useNavigationTabs } from "./navigationTabs"

import backgroundHub from "../../assets/images/backgroundHub.avif"

export function withMenuLayout<T>(WrappedComponent: React.ComponentType<T>) {
  const menuComponent = (
    <Menu
      menuBackgroundGradientTopColor={"#034acf"}
      menuBackgroundGradientBottomColor={"#236fff"}
    />
  )

  return (props: T) => (
    <VerticalMenuLayout
      menuChildren={menuComponent}
    >
      <WrappedComponent {...props} />
    </VerticalMenuLayout>
  )
}

export function withTabsLayout<T>(WrappedComponent: React.ComponentType<T>, feature: FeatureWithTabs) {
  const menuComponent = (
    <Menu
      menuBackgroundGradientTopColor={"#034acf"}
      menuBackgroundGradientBottomColor={"#236fff"}
    />
  )

  return (props: T) => {
    const tabs = useNavigationTabs(feature)

    return (
      <VerticalMenuLayout
        menuChildren={menuComponent}
      >
        <TabsLayout tabs={tabs}>
          <WrappedComponent {...props} />
        </TabsLayout>
      </VerticalMenuLayout>
    )
  }
}

export function withCenteredLayout<T>(
  WrappedComponent: React.ComponentType<T>,
  options?: WithCenteredLayoutProps) {
  return (props: T) => (
    <CenteredLayout backgroundUrl={backgroundHub} {...options}>
      <WrappedComponent {...props} />
    </CenteredLayout>
  )
}

export interface WithCenteredLayoutProps {
  backgroundColor?: string,
  backgroundUrl?: string,
  header?: React.ReactChild,
  left?: React.ReactChild,
  right?: React.ReactChild,
  footer?: React.ReactChild,
}
