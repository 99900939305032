import * as React from "react"
import { AccordionDetails } from "@material-ui/core"
import { t } from "ttag"

import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import { Stepper } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import { AnswerSatisfaction, getAnswerSatisfaction } from "features/Comments"
import { AnswerStatus } from "models/AnswerStatus"
import { Comment, CommentContext, CommentObject } from "models/Comment"
import * as Styles from "./BaseCommentsTableRow.styles"

const BaseCommentsTableRowComponent: React.FC<BaseCommentsTableRowProps> = (props) => {
  const [ isPanelExpanded, setPanelExpanded ] = React.useState(false)

  const responseObject = props.comment.context?.response
  const responseChoice = responseObject?.choice
  const status = !props.answerStatus ? AnswerStatus.Pending : props.answerStatus

  return (
    <Styles.AccordionContainer
      elevation={0}
      onChange={updatePanelExpansionStatus}
      expanded={isPanelExpanded}
      className={props.className}
      $hasNoResponse={props.responseDisplay === "hide"}
      $hasCallToAction={props.callToAction !== undefined}
      $isHoverReactive={props.isHoverReactive}
      role="row"
    >
      <Styles.SummaryContainer
        expandIcon={!props.isExpandIconHidden && <Icon name="keyboard_arrow_down"/>}
        classes={{
          content: "summary__content",
        }}
      >
        <Styles.Summary>
          <div>
            {props.children?.(isPanelExpanded)}
          </div>
          {props.responseDisplay === "blank" && <div/>}
          {props.responseDisplay === "display" && responseChoice && (
            <Stepper
              label={responseChoice.name}
              steps={responseChoice.maxSatisfactionValue + 1}
              currentStep={responseChoice.satisfactionValue + 1}
              variant={getStepperVariant()}
            />
          )}
          <Styles.StatusIcon status={status}/>
          {props.callToAction}
        </Styles.Summary>
      </Styles.SummaryContainer>
      <Styles.Details
        aria-label={t`Row extended content`}
      >
        {props.extendedContent || <div/>}
      </Styles.Details>
    </Styles.AccordionContainer>
  )

  /**
   * Update the isPanelExpanded state
   * We won't trigger the expansion if the user is selecting a text
   * @param event Trigger of the event
   * @param isExpanded Whether the panel is now expanded
   */
  function updatePanelExpansionStatus(event: React.ChangeEvent<Record<string, unknown>>, isExpanded: boolean) {
    if (!isSelecting()) {
      setPanelExpanded(isExpanded)
    }
  }

  /**
   * Check if the user is selecting a text
   */
  function isSelecting(): boolean {
    const selection = window.getSelection()

    if (selection) {
      return selection.toString() !== ""
    }

    return false
  }

  function getStepperVariant(): "danger" | "success" | "primary" {
    const answerSatisfaction = getAnswerSatisfaction(responseChoice) ?? AnswerSatisfaction.Neutral

    const variants: Record<AnswerSatisfaction, "danger" | "success" | "primary"> = {
      [AnswerSatisfaction.Best]: "success",
      [AnswerSatisfaction.Neutral]: "primary",
      [AnswerSatisfaction.Worst]: "danger",
    }

    return variants[answerSatisfaction]
  }
}

const BaseCommentsTableRowSkeleton: React.FC<BaseCommentsTableRowSkeletonProps> = (props) => (
  <Styles.AccordionContainer
    disabled={true}
    elevation={0}
    expanded={false}
  >
    <Styles.SummaryContainer
      classes={{
        content: "summary__content",
      }}
    >
      <Styles.Summary>
        <div>
          {props.children?.(false)}
        </div>
        {/* Answer */}
        <Styles.ItemSkeleton width={50} isLoading/>
        {/* Answer status */}
        <Styles.ItemSkeleton height={30} width={30} isLoading/>
        {/* CTA */}
        {props.callToAction}
      </Styles.Summary>
    </Styles.SummaryContainer>
    <AccordionDetails
      classes={{
        root: "BaseCommentsTableRow__mui-details",
      }}
    >
      <div/>
    </AccordionDetails>
  </Styles.AccordionContainer>
)

export const BaseCommentsTableRow = withSkeleton(BaseCommentsTableRowComponent, BaseCommentsTableRowSkeleton)

type BaseCommentsTableRowSkeletonProps = Pick<BaseCommentsTableRowProps,
  "children" | "callToAction" | "isHoverReactive" | "responseDisplay">;

export interface BaseCommentsTableRowProps extends BaseProps {
  /** Status of the answer to the comment */
  answerStatus: AnswerStatus,

  /** Call to action to show */
  callToAction?: React.ReactNode,

  /** Main content to display in the row */
  children?: (isExpanded: boolean) => React.ReactNode,

  /** Comment to display */
  comment: Comment & Partial<CommentContext> & CommentObject,

  /** Content to display when the panel is extended */
  extendedContent?: React.ReactNode,

  /** Whether to show the selected response */
  responseDisplay?: "display" | "hide" | "blank",

  /** Whether to hide the Expand icon */
  isExpandIconHidden?: boolean,

  /** Whether the component is reactive to hovering */
  isHoverReactive?: boolean,
}

BaseCommentsTableRow.defaultProps = {
  responseDisplay: "hide",
}
