/* tslint:disable */ /* eslint-disable */
import * as Types from '../../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KillerQuestionGradeEvolutionQueryVariables = Types.Exact<{
  attributeValueId?: Types.InputMaybe<Types.Scalars['Int']>;
  endAtFrom?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endAtTo?: Types.InputMaybe<Types.Scalars['DateTime']>;
  duration?: Types.InputMaybe<Types.Scalars['DateInterval']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type KillerQuestionGradeEvolutionQuery = { global: { id: string, evolution: Array<{ grade?: number | undefined, date: string, scheduledSurveys: Array<{ grade?: number | undefined, scheduling: { schedulingId: number, beginAt: { local: string }, endAt: { local: string }, status: { absolute: Types.Scheduled_Survey_Status, local: Types.Scheduled_Survey_Status } }, survey: { id: number, name: string } }> }> } };


export const KillerQuestionGradeEvolutionDocument = gql`
    query KillerQuestionGradeEvolution($attributeValueId: Int, $endAtFrom: DateTime, $endAtTo: DateTime, $duration: DateInterval, $limit: Int) {
  global: statistics_global {
    id
    evolution: killer_question_grade_evolution(attribute_value_id: $attributeValueId, period: {ending_from: $endAtFrom, ending_to: $endAtTo, duration: $duration}, limit: $limit) {
      date: day
      grade
      scheduledSurveys: included_scheduled_survey {
        grade
        scheduling: scheduled_survey {
          schedulingId: id
          beginAt: begin_at {
            local
          }
          endAt: end_at {
            local
          }
          status {
            absolute
            local
          }
        }
        survey {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useKillerQuestionGradeEvolutionQuery__
 *
 * To run a query within a React component, call `useKillerQuestionGradeEvolutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useKillerQuestionGradeEvolutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKillerQuestionGradeEvolutionQuery({
 *   variables: {
 *      attributeValueId: // value for 'attributeValueId'
 *      endAtFrom: // value for 'endAtFrom'
 *      endAtTo: // value for 'endAtTo'
 *      duration: // value for 'duration'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useKillerQuestionGradeEvolutionQuery(baseOptions?: Apollo.QueryHookOptions<KillerQuestionGradeEvolutionQuery, KillerQuestionGradeEvolutionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KillerQuestionGradeEvolutionQuery, KillerQuestionGradeEvolutionQueryVariables>(KillerQuestionGradeEvolutionDocument, options);
      }
export function useKillerQuestionGradeEvolutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KillerQuestionGradeEvolutionQuery, KillerQuestionGradeEvolutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KillerQuestionGradeEvolutionQuery, KillerQuestionGradeEvolutionQueryVariables>(KillerQuestionGradeEvolutionDocument, options);
        }
export type KillerQuestionGradeEvolutionQueryHookResult = ReturnType<typeof useKillerQuestionGradeEvolutionQuery>;
export type KillerQuestionGradeEvolutionLazyQueryHookResult = ReturnType<typeof useKillerQuestionGradeEvolutionLazyQuery>;
export type KillerQuestionGradeEvolutionQueryResult = Apollo.QueryResult<KillerQuestionGradeEvolutionQuery, KillerQuestionGradeEvolutionQueryVariables>;