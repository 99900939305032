import moment from "moment"
import * as React from "react"
import { useLocation } from "react-router-dom"
import { t } from "ttag"
import { IconButton, Illustration, InformationMessage } from "@humanpredictiveintelligence/myqvt-library"

import { ExcelExportDialog, ExcelExportDialogProps } from "../ExcelExportDialog"
import { QuestionResultsUserAttributeValue, ScheduledSurveyQuestionResults } from "../ScheduledSurveyQuestionResults"
import { ScheduledSurveyResultsHeaderSkeleton } from "../ScheduledSurveyResultsHeader"
import { ScheduledSurveyResultSectionSkeleton } from "../ScheduledSurveyResultSection"
import { ScheduledSurveyFilters } from "../UserAttributesFilters"
import { useUserPermissions } from "features/Permissions"
import { usePackages } from "features/Packages"
import { NotificationContext } from "features/Notification"
import { APPLICATION_URL } from "features/Navigation"
import { withRouteGuard } from "features/Navigation/withRouteGuard"
import { ExcelExportType } from "models/ExcelExportType"
import { ScheduledSurveyResultsPageSection } from "models/ScheduledSurveyResultsPageSection"
import { USER_ATTRIBUTE } from "models/UserAttribute"
import { USER_ATTRIBUTE_VALUE } from "models/UserAttributeValue"
import {
  CommentWrittenAs,
  PackageCode,
  Supported_Language_Code as SupportedLanguageCode,
  UserPermissionCode,
} from "models/generated"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"
import { NonNullableProperties } from "utilities/types"
import business from "config/business"
import illustrationDetective from "assets/images/illustration_detective.png"
import * as Styles from "./ScheduledSurveyResultsPage.styles"

import { useExportCommentsLazyQuery } from "graphql/queries/generated/ExportComments"
import { useExportParticipationsLazyQuery } from "graphql/queries/generated/ExportParticipations"
import { useExportRankingLazyQuery } from "graphql/queries/generated/ExportRanking"
import { useExportSatisfactionLazyQuery } from "graphql/queries/generated/ExportSatisfaction"
import { usePublishScheduledSurveyResultsMutation } from "graphql/mutations/generated/PublishScheduledSurveyResults"
import { useScheduledSurveyGlobalResultsQuery } from "graphql/queries/generated/ScheduledSurveyGlobalResults"

const ScheduledSurveyResultsPageComponent: React.FC<ScheduledSurveyResultsPageProps> = props => {
  const notification = React.useContext(NotificationContext)
  const pageDivRef = React.createRef<HTMLDivElement>()
  const { hasPackage } = usePackages()

  const isUserAllowed = useUserPermissions()
  const isAllowedToReadSurveyFeedback = isUserAllowed(UserPermissionCode.SurveyFeedbacksRead)
    || isUserAllowed(UserPermissionCode.LocalSurveyFeedbacksRead)
  const isAllowedToReadSurveyComments = isUserAllowed(UserPermissionCode.CommentsRead)

  //#region Get the target section from URL
  const scrollTargetSectionRef = React.createRef<HTMLDivElement>()
  const location = useLocation()
  const urlSearch = new URLSearchParams(location.search)
  const targetSection = urlSearch.get("section")
  const commentToFocus = parseInt(urlSearch.get("comment") || "", 10)
  //#endregion

  const [ isRerenderSwitcherOn, setRerenderSwitcher ] = React.useState(false)
  const [ selectedFilters, setSelectedFilters ] = React.useState<ScheduledSurveyFilters>(
    { userAttributesValues: new Map<number, USER_ATTRIBUTE_VALUE[]>(), userGroupsUuids: [] },
  )

  const [ questionRefetchMap, setQuestionRefetchMap ] =
    React.useState(new Map<number, boolean>())

  const filteringAttributeValueIds = selectedAttributeValueIds(selectedFilters.userAttributesValues)
  const [
    firstInteractedHierarchicalAttributeId,
    setFirstInteractedHierarchicalAttributeId,
  ] = React.useState<number | undefined>(undefined)

  const [ isExcelExportDialogVisible, setExcelExportDialogVisible ] = React.useState(false)
  const [
    excelExportDialogStatus,
    setExcelExportDialogStatus,
  ] = React.useState<ExcelExportDialogProps["status"]>("form")
  const onValidateExcelExportDialog =
    (excelExportDialogStatus === "form" && requestExcelExport) || closeAndResetExportDialog

  const {
    loading: isLoadingResults,
    error: resultsQueryError,
    data: results,
    refetch: refetchResults,
  } = useScheduledSurveyGlobalResultsQuery({
    variables: {
      filterAttributeIds: filteringAttributeValueIds.length ? filteringAttributeValueIds : undefined,
      id: props.id,
      userGroupsUuids: selectedFilters.userGroupsUuids.length ? selectedFilters.userGroupsUuids : undefined,
      writtenAs: CommentWrittenAs.Admin,
    },
  })

  const [ publishResults ] = usePublishScheduledSurveyResultsMutation({
    onCompleted() {
      refetchData()
    },
    variables: {
      id: props.id,
    },
  })

  const [ exportComments ] = useExportCommentsLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
    },
  })

  const [ exportSatisfaction ] = useExportSatisfactionLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
    },
  })

  const [ exportParticipations ] = useExportParticipationsLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
    },
  })

  const [ exportRanking ] = useExportRankingLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
    },
  })

  const scheduledSurvey = Object.assign({}, results?.scheduledSurvey)
  const statistics = scheduledSurvey?.statistics
  const publishingDate = scheduledSurvey?.statistics_available_at
  const areResultsPublished = (publishingDate && moment().isSameOrAfter(publishingDate)) || false

  /** Attributes of first hierarchy level used to define local feedback attributes */
  const localFeedbacksAttributeValues = mainHierarchyHeadAttributeValues()

  React.useEffect(() => {
    setSelectedFilters({ userAttributesValues: new Map(), userGroupsUuids: [] })
  }, [ props.id ])

  React.useEffect(() => {
    if (scrollTargetSectionRef?.current && !isLoadingResults) {
      scrollTargetSectionRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [ scrollTargetSectionRef, isLoadingResults ])

  return (
    <Styles.Container
      $isClusterViolated={scheduledSurvey?.statistics?.isClusterUnviolated === false}
      ref={pageDivRef}
      aria-label={t`Survey results page`}
    >
      {isLoadingResults && (
        <Styles.Loader>
          <ScheduledSurveyResultsHeaderSkeleton/>
          <ScheduledSurveyResultSectionSkeleton isLoading/>
          <ScheduledSurveyResultSectionSkeleton isLoading/>
        </Styles.Loader>
      )}
      {!isLoadingResults && resultsQueryError && (
        <InformationMessage
          variant="warning"
          text={t`An unexpected error happened while fetching the results. Please try again or contact support.`}
        />
      )}
      {!isLoadingResults && scheduledSurvey && statistics && !scheduledSurvey?.statistics?.isClusterUnviolated && (
        <>
          {isExcelExportDialogVisible && (
            <ExcelExportDialog
              status={excelExportDialogStatus}
              filters={Array.from(selectedFilters.userAttributesValues.values()).flat()}
              onCancel={() => setExcelExportDialogVisible(false)}
              onValidate={onValidateExcelExportDialog}
            />
          )}
          <Styles.Header
            scheduling={scheduledSurvey}
            survey={scheduledSurvey.survey}
            surveyTheme={scheduledSurvey.survey.theme}
            userGroups={scheduledSurvey.userGroups}
            participationStatistics={statistics || undefined}
            onExportResults={() => window.print()}
            onExportExcel={showExcelExportDialog}
            isExportMenuHidden
            $withRoundedBorders
          />
          <Illustration
            title={t`Oops, this survey did not receive enough participations.`}
            illustration={illustrationDetective}
          >
            <p>{t`To guarantee the anonymity of your collaborators, you cannot view these results.`}</p>
            <Styles.Hint>
              (<Styles.HintIcon name="face" size={24}/>
              {t`Add collaborators to the recipients list`})
            </Styles.Hint>
          </Illustration>
        </>
      )}
      {!isLoadingResults && scheduledSurvey && statistics && statistics.isClusterUnviolated && (
        <>
          {isExcelExportDialogVisible && (
            <ExcelExportDialog
              status={excelExportDialogStatus}
              filters={Array.from(selectedFilters.userAttributesValues.values()).flat()}
              attributes={USER_ATTRIBUTE_SERVICE.attributesFromAttributeValueStatistics(statistics.attributes)}
              onCancel={() => setExcelExportDialogVisible(false)}
              onValidate={onValidateExcelExportDialog}
            />
          )}
          <Styles.Header
            scheduling={scheduledSurvey}
            survey={scheduledSurvey.survey}
            surveyTheme={scheduledSurvey.survey.theme}
            userGroups={scheduledSurvey.userGroups}
            participationStatistics={statistics || undefined}
            onExportResults={() => window.print()}
            onExportExcel={showExcelExportDialog}
          />
          {statistics.attributes.length > 0 && (
            <Styles.AttributesFilters
              attributes={statistics.attributes}
              statisticsUserGroups={statistics.userGroups}
              defaultSelection={selectedFilters}
              onFilter={(selectedAttributeFilters) => {
                const selectedAttributeFiltersMap = new Map()
                scheduledSurvey?.questions.forEach((question, index) => {
                  selectedAttributeFiltersMap.set(index + 1, true)
                })
                setQuestionRefetchMap(selectedAttributeFiltersMap)
                setSelectedFilters(selectedAttributeFilters)
              }}
              scheduledSurveyId={scheduledSurvey.schedulingId}
              firstInteractedHierarchicalAttributeId={firstInteractedHierarchicalAttributeId}
              onHierarchicalFilterFirstInteraction={setFirstInteractedHierarchicalAttributeId}
            />
          )}
          <Styles.Questions>
            {scheduledSurvey?.questions.map((question, index) => {
              const isSectionTarget
                = targetSection === ScheduledSurveyResultsPageSection.QuestionPrefix + question.reference.id

              return (
                <div
                  key={question.reference.id}
                  ref={isSectionTarget ? scrollTargetSectionRef : undefined}
                >
                  <ScheduledSurveyQuestionResults
                    scheduledSurveyId={scheduledSurvey.schedulingId}
                    questionNumber={index + 1}
                    referenceQuestionId={question.reference.id}
                    areResultsPublished={areResultsPublished}
                    publishingDate={publishingDate}
                    userAttributesValues={userAttributesValuesForQuestionResults(selectedFilters.userAttributesValues)}
                    userGroupsUuids={selectedFilters.userGroupsUuids}
                    focusCommentId={isSectionTarget ? commentToFocus : undefined}
                    isLazyLoaded={
                      business.results.questionsNumberLimitToLoadConcurrently < scheduledSurvey?.questions.length + 1
                    }
                    questionRefetchMap={questionRefetchMap}
                    onQuestionResponsesRefetched={() => {
                      const newQuestionRefetchMap = new Map(questionRefetchMap)
                      newQuestionRefetchMap.set(index + 1, false)
                      setQuestionRefetchMap(newQuestionRefetchMap)
                    }}
                  />
                </div>
              )
            })}
          </Styles.Questions>

          {isAllowedToReadSurveyComments && (
            <div
              ref={targetSection === ScheduledSurveyResultsPageSection.CollaboratorsFeedback
                ? scrollTargetSectionRef
                : undefined}
            >
              <Styles.CollaboratorsFeedbackSection
                scheduledSurveyId={scheduledSurvey.schedulingId}
                attributes={filteringAttributeValueIds.length ? filteringAttributeValueIds : undefined}
              />
            </div>
          )}

          <Styles.SectionSeparator/>

          {isAllowedToReadSurveyFeedback && (
            <Styles.FeedbackSection
              scheduledSurveyId={scheduledSurvey.schedulingId}
              globalComment={scheduledSurvey?.comments?.[0]}
              scopedComments={scheduledSurvey?.scopedComments}
              areResultsPublished={areResultsPublished}
              publishingDate={publishingDate}
              onSaved={refetchResults}
              onDeleted={refetchResults}
              firstLevelAttributes={localFeedbacksAttributeValues}
            />
          )}

          {!hasPackage(PackageCode.Lea) && (
            <Styles.PublishingStatus
              publishingDate={publishingDate}
              onTimeout={refetchData}
              numberOfRecipients={statistics ? statistics.recipientsCount : 0}
              onPublish={publishResults}
              isManualPublication={results?.scheduledSurvey?.isManualPublication || false}
            />
          )}
          <Styles.Footer>
            <IconButton size="extrabig" icon="arrow_upward" isInverted onClick={scrollToTop}/>
          </Styles.Footer>
        </>
      )}
    </Styles.Container>
  )

  /**
   * Re-fetch results
   */
  function refetchData() {
    setRerenderSwitcher(!isRerenderSwitcherOn)
    refetchResults()
  }

  /**
   * Show the Excel export dialog
   */
  function showExcelExportDialog() {
    setExcelExportDialogVisible(true)
  }

  /**
   * Hide the Excel export dialog and reset its status to "form"
   */
  function closeAndResetExportDialog() {
    setExcelExportDialogVisible(false)
    setExcelExportDialogStatus("form")
  }

  /**
   * Send request to export comments and save the result as a file
   */
  function requestExcelExport(
    exportRequest?: { language: string, exportType: ExcelExportType, pivot?: USER_ATTRIBUTE },
  ) {
    if (exportRequest) {
      const queryVariables = {
        attributeValueIds: filteringAttributeValueIds,
        groupAttributeId: exportRequest.pivot?.id,
        language: exportRequest.language as SupportedLanguageCode,
        scheduledSurveyId: scheduledSurvey.schedulingId,
      }

      switch (exportRequest.exportType) {
        case ExcelExportType.Comments:
          exportComments({ variables: queryVariables })
          break
        case ExcelExportType.Satisfaction:
          exportSatisfaction({ variables: queryVariables })
          break
        case ExcelExportType.Participation:
          exportParticipations({ variables: queryVariables })
          break
        case ExcelExportType.Ranking:
          if (queryVariables.groupAttributeId) {
            // Although we check for the property groupAttributeId not to be undefined,
            // TS doesn't narrow the type of the object queryVariables in that case
            // https://stackoverflow.com/a/57929239/3494812
            exportRanking({ variables: (queryVariables as NonNullableProperties<typeof queryVariables>) })
          }
          break
      }

      setExcelExportDialogStatus("confirmation")
    }
  }

  /**
   * Coalesce the attribute selection object to a collection of selected attribute value IDs
   * @param selectedAttributes Attribute values selection
   */
  function selectedAttributeValueIds(selectedAttributes: Map<number, USER_ATTRIBUTE_VALUE[]>): number[] {
    return Array.from(selectedAttributes.values()).flatMap(values => values.map(value => value.id))
  }

  /**
   * Convert all attributes values into USER_ATTRIBUTE_VALUE
   * And add boolean info on selected status and hierarchical status
   */
  function userAttributesValuesForQuestionResults(
    selectedAttributes: Map<number, USER_ATTRIBUTE_VALUE[]>,
  ): QuestionResultsUserAttributeValue[] {
    return statistics?.attributes.map((attributeStatistic) => {
      const convertedAttributeValue: QuestionResultsUserAttributeValue
        = USER_ATTRIBUTE_VALUE.fromSkinnyUserAttributeValue(attributeStatistic.attribute, attributeStatistic.usersCount)
      convertedAttributeValue.isSelected = selectedAttributeValueIds(selectedAttributes).find((attributeValueId) => {
        return attributeValueId === attributeStatistic.attribute.valueId
      }) !== undefined
      convertedAttributeValue.isMainHierarchyHead = attributeStatistic.attribute.isMainHierarchyHead

      return convertedAttributeValue
    }) || []
  }

  function mainHierarchyHeadAttributeValues(): USER_ATTRIBUTE_VALUE[] {
    const firstLevelAttributeValues = statistics?.attributes.reduce((accumulator, attributeStatistic) => {
      if (attributeStatistic.attribute.isMainHierarchyHead) {
        accumulator.push(USER_ATTRIBUTE_VALUE.fromSkinnyUserAttributeValue(
          attributeStatistic.attribute, attributeStatistic.usersCount,
        ))
      }

      return accumulator
    }, [] as USER_ATTRIBUTE_VALUE[]) || []

    const firstLevelAttributeId = firstLevelAttributeValues[0]?.attributeId

    const selectedFirstLevelAttributeValues = selectedFilters.userAttributesValues.get(firstLevelAttributeId)

    if (selectedFirstLevelAttributeValues?.length) {
      return selectedFilters.userAttributesValues.get(firstLevelAttributeId)!
    }

    return firstLevelAttributeValues
  }

  /**
   * Smoothly scroll to the top of the page
   */
  function scrollToTop() {
    if (pageDivRef.current) {
      pageDivRef.current.scrollTo({
        behavior: "smooth",
        top: 0,
      })
    }
  }
}

interface ScheduledSurveyResultsPageProps {
  id: number,
}

export const ScheduledSurveyResultsPage = withRouteGuard(
  ScheduledSurveyResultsPageComponent,
  APPLICATION_URL.speakupScheduledSurveys(),
  (_, isUserAllowed) => !isUserAllowed(UserPermissionCode.ResultsRead),
)
