import * as React from "react"
import { t } from "ttag"
import { BaseProps, Tooltip } from "@humanpredictiveintelligence/myqvt-library"

import {
  UserGroupsDialogDetails,
} from "features/UserGroups/UserGroupsDetails/UserGroupsDialogDetails"
import { useUserPermissions } from "features/Permissions"
import {
  Scheduled_Survey_Status as ScheduledSurveyStatus,
  Scheduled_Survey_Status,
  UserPermissionCode,
} from "models/generated"
import { UserGroup } from "models/Scheduler"
import * as Styles from "./UserGroupsHeadersDetails.styles"

export const UserGroupsHeadersDetails: React.FC<UserGroupsHeadersDetailsProps> = (props) => {
  const isUserAllowed = useUserPermissions()
  const [ selectedGroup, setSelectedGroup ] = React.useState<UserGroup | null>(null)

  return (
    <Styles.Container className={props.className}>
      <p>
        {props.scheduledSurveyStatus === ScheduledSurveyStatus.Todo
          ? t`Send to:`
          : t`Sent to:`
        }
      </p>
      <Styles.UserGroups>

        {props.userGroups.map((userGroup) => {
          const isUserAllowedToSeeUsersInGroup =
          isUserAllowed(UserPermissionCode.UserGroupsManage) && userGroup.usersCount !== 0

          return (
            <Styles.UsersGroupLink
              key={userGroup.uuid}
              $isDeleted={!!userGroup.deletedAt}
              $isDisabled={!isUserAllowedToSeeUsersInGroup}
              onClick={() => isUserAllowedToSeeUsersInGroup && setSelectedGroup(userGroup)}
            >
              <Tooltip
                title={!isUserAllowedToSeeUsersInGroup &&
                t`You cannot see the content of this group, 
                either because it is empty or because you do not have sufficient rights
             `}
              >
                <>
                  <Styles.UserGroupLabel>
                    {userGroup.name}
                  </Styles.UserGroupLabel>
                  <Styles.UserGroupCount>
                    {userGroup.usersCount}
                  </Styles.UserGroupCount>
                </>
              </Tooltip>
            </Styles.UsersGroupLink>
          )
        })}
      </Styles.UserGroups>

      {selectedGroup && (
        <UserGroupsDialogDetails
          userGroup={selectedGroup}
          onClose={() => setSelectedGroup(null)}
        />
      )}
    </Styles.Container>
  )
}

interface UserGroupsHeadersDetailsProps extends BaseProps {
  scheduledSurveyStatus?: Scheduled_Survey_Status,
  userGroups: UserGroup[],
}

