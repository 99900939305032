import * as React from "react"
import { msgid, ngettext, t } from "ttag"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"

import { ScheduledSurveyInfo } from "features/Surveys"
import { DigitalInfo } from "features/Shared/DigitalInfo"
import { SkinnyScheduledSurveyGlobalStatistics } from "models/SkinnyScheduledSurveyGlobalStatistics"
import { Survey, SurveyScheduling, Theme } from "models/Survey/index"
import * as Styles from "./ScheduledSurveyResultsHeader.styles"

import { ScheduledSurveyGlobalResultsQuery } from "graphql/queries/generated/ScheduledSurveyGlobalResults"

export const ScheduledSurveyResultsHeader: React.FC<ScheduledSurveyResultsHeaderProps> = (props) => {
  // Declare in a variable because ttag doesn't support logical expressions in messages
  const submittedParticipationsCount
    = (props.participationStatistics && props.participationStatistics.submittedParticipationsCount) || 0

  return (
    <Styles.Container className={props.className}>
      <ScheduledSurveyInfo
        scheduling={props.scheduling}
        survey={props.survey}
        surveyTheme={props.surveyTheme}
      />
      {props.participationStatistics && (
        <Styles.Statistics>
          <DigitalInfo
            value={props.participationStatistics.participationsPercentage || 0}
            label={t`Real participation`}
            // eslint-disable-next-line max-len
            tooltip={ngettext(
              // eslint-disable-next-line max-len
              msgid`${submittedParticipationsCount} participant of the survey out of all of the recipients (${props.participationStatistics.recipientsCount}).`,
              // eslint-disable-next-line max-len
              `${submittedParticipationsCount} participants of the survey out of all of the recipients (${props.participationStatistics.recipientsCount}).`,
              submittedParticipationsCount,
            )}
            icon="people"
            unit="%"
          />
          <DigitalInfo
            value={props.participationStatistics.adjustedParticipationsPercentage || 0}
            label={t`Adjusted participation`}
            // eslint-disable-next-line max-len
            tooltip={t`${props.participationStatistics.submittedParticipationsCount} participants of the survey out of the number of recipients that have accepted the TOS (${props.participationStatistics.termsAcceptedRecipientsCount})`}
            icon="people"
            unit="%"
          />
          <DigitalInfo
            value={
              props.participationStatistics.grade
                ? props.participationStatistics.grade
                : "-"
            }
            // Line break intended
            label={t`Global
            score
            `}
            icon="bar_chart"
            unit={props.participationStatistics.grade && props.participationStatistics.grade > 0 ? "/100" : ""}
          />
        </Styles.Statistics>
      )}
      {!props.isExportMenuHidden && (
        <Styles.ActionsDropdown
          buttonText={t`Export`}
          options={[
            {
              icon: "picture_as_pdf",
              label: t`Results`,
              onClick: props.onExportResults,
              tooltip: t`You can choose to print the results or save them as a PDF file`,
            },
            {
              icon: "table_chart",
              label: t`Excel`,
              onClick: props.onExportExcel,
            },
          ]}
        />
      )}
      <Styles.UserGroupsList userGroups={props.userGroups} />
    </Styles.Container>
  )
}

interface ScheduledSurveyResultsHeaderProps extends BaseProps {
  /** Scheduled survey to show */
  scheduling: SurveyScheduling,

  /** Theme reference of this scheduled survey */
  surveyTheme: Theme,

  /** Survey reference of this scheduled survey */
  survey: Survey,

  userGroups:  NonNullable<ScheduledSurveyGlobalResultsQuery["scheduledSurvey"]>["userGroups"],

  /** Global statistics on the scheduled survey */
  participationStatistics?: SkinnyScheduledSurveyGlobalStatistics,

  /** When the user clicks on Export Results */
  onExportResults: () => void,

  /** When the user clicks on Export Excel */
  onExportExcel: () => void,

  /** Whether the export menu should be hidden */
  isExportMenuHidden?: boolean,
}
