import * as React from "react"
import { TableRowData } from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"

import { formatDateInterval } from "utilities/helpers"
import { MomentRange } from "models/DateTime/MomentRange"
import { LedDiscussionsTable } from "./LedDiscussionsTable"
import { LedDiscussionDetails } from "./LedDiscussionDetails"
import { LedDatePicker } from "../LedDatePicker"
import { LedNavigationTabs } from "../LedNavigationTabs"
import { usePsyDispositifNanoId } from "../psyDispositifStore"
import { LedGlobalStatisticsMetrics } from "../LedGlobalStatisticsMetrics"

import * as Styles from "./LedDiscussionsPage.styles"
import * as Layout from "../layout.styles"

import {
  useGetPsyReportsQuery,
} from "graphql/queries/generated/GetPsyReports"
import business from "config/business"

export const LedDiscussionsPage: React.FC = () => {
  const { psyDispositifNanoId } = usePsyDispositifNanoId()

  const [ filterRange, setFilterRange ] = React.useState<MomentRange>()
  const [ selectedDiscussionNanoId, setSelectedDiscussionNanoId ] = React.useState<string | null>(null)
  const [ discussionsPagination, setDiscussionsPagination ] = React.useState({
    currentPage: 1,
    loadingOffset: 0,
    pageSize: business.led.discussions.tablePageSizes[0],
  })

  const { data: psyReportsData, loading: areReportsLoading } = useGetPsyReportsQuery({
    variables: {
      dateInterval: formatDateInterval(filterRange?.from, filterRange?.to),
      limit: discussionsPagination.pageSize,
      psyDispositifNanoId,
      skip: discussionsPagination.loadingOffset,
    },
  })

  const getConversationComment = React.useMemo(() => {
    return psyReportsData?.getPsyReports.list.find((discussion) =>
      discussion.nanoId === selectedDiscussionNanoId,
    )?.comment || ""
  }, [ psyReportsData?.getPsyReports.list, selectedDiscussionNanoId ])

  const discussionsRowsData = React.useCallback((discussionsList: DiscussionsData[]): TableRowData[] => {
    if (!discussionsList) {
      return []
    }

    return discussionsList.map((discussion: DiscussionsData) => {
      const discussionsValues = new Map<string, string>()

      Object.entries(discussion).forEach(([ propertyName, propertyValue ]) => {
        if (propertyName !== "id") {
          discussionsValues.set(propertyName, propertyValue as string)
        }
      })

      return {
        id: discussion.nanoId,
        values: discussionsValues,
      }
    })
  }, [])

  return (
    <LedNavigationTabs>
      <Layout.Container>
        <Styles.DiscussionsModules>
          <Layout.DatePickerContainer $span={6}>
            <LedDatePicker
              isFullWidth
              label={t`Select a period`}
              pickerAlignment={"left"}
              onValidate={setFilterRange}
              onReset={() => setFilterRange(undefined)}
              placeholder={t`All periods included`}
              value={filterRange}
            />
          </Layout.DatePickerContainer>

          <LedGlobalStatisticsMetrics
            filterRange={filterRange}
            psyDispositifNanoId={psyDispositifNanoId}
          />

          <Layout.Module $span={10}>
            <Styles.DiscussionsContainer>
              <LedDiscussionsTable
                pagination={discussionsPagination}
                onPageSizeChange={updateTableSize}
                onNavigate={updateTablePage}
                onRowSelected={(id) => setSelectedDiscussionNanoId(id.toString())}
                isLoading={areReportsLoading}
                discussionsList={discussionsRowsData(psyReportsData?.getPsyReports.list ?? [])}
                totalDiscussionsCount={psyReportsData?.getPsyReports.count ?? 0}
              />
              <LedDiscussionDetails content={getConversationComment}/>
            </Styles.DiscussionsContainer>
          </Layout.Module>

        </Styles.DiscussionsModules>
      </Layout.Container>
    </LedNavigationTabs>
  )

  function updateTableSize(pageSize: number) {
    setDiscussionsPagination({
      currentPage: 1,
      loadingOffset: 0,
      pageSize: pageSize,
    })
  }

  function updateTablePage(page: number, pagesCount: number) {
    if (page === 0 || page === pagesCount + 1) {
      return
    }

    setDiscussionsPagination(previousPagination => ({
      ...previousPagination,
      currentPage: page,
      loadingOffset: previousPagination.pageSize * (page - 1),
    }))
  }
}

export type DiscussionsData = {
  comment: string,
  date: string,
  nanoId: string,
  issue: string,
}
