import styled from "styled-components/macro"

export const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1.2fr;
  gap: 2rem;
  padding: 40px;
  
  ${({ theme }) => theme.mixins.reducedLandscapeAndPortrait} {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
`
