import * as React from "react"
import { t } from "ttag"

import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./DataAnonymizationIllustration.styles"
import { useTheme } from "styled-components"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"

export const DataAnonymizationIllustration: React.FC<BaseProps> = props => {
  const theme = useTheme()

  return (
    <Styles.Container className={props.className}>
      <Icon name="lock" size={48} color={theme.colors.blackLight} />
      <Styles.Label>{t`Data anonymization\n in progress`}</Styles.Label>
    </Styles.Container>
  )
}
