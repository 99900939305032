import styled from "styled-components/macro"

export const SurveysTutorialPopupContent = styled.div`
  max-width: 800px;
`
export const ScheduledSurveyCreatedContent = styled.div`
  max-width: 400px;
`

export const Step1 = {
  ListItem: styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    &:nth-child(odd) {
      margin: 20px 0;
    }
  `,
  ListItemCell: styled.div`
    flex-basis: 50%;
    padding: 0 5px;
  `,
}
