import * as React from "react"
import { ResponsiveBar } from "@nivo/bar"
import {
  CenteredLoader,
  Skeletonable,
  Title,
  Tooltip,
  nivoMyQvtTheme, withSkeleton,
} from "@humanpredictiveintelligence/myqvt-library"
import AutoSizer from "react-virtualized-auto-sizer"

import * as Styles from "./TopicChart.styles"

const TopicChartSkeleton: React.FC<TopicChartProps> = (props) => (
  <AutoSizer>
    {({ width, height }: {height: number, width: number}) => (
      <Styles.Container $width={width} $height={height}>
        <Title level="metricCard">
          {props.title}
        </Title>
        <CenteredLoader isTransparent/>
      </Styles.Container>
    )}
  </AutoSizer>
)
export const TopicChartComponent: React.FC<Skeletonable<TopicChartProps>> = (props) => {
  const height = React.useMemo(() => calculateHeight(props.chartData.length), [ props.chartData.length ])
  const padding = React.useMemo(() => calculatePadding(props.chartData.length), [ props.chartData.length ])

  return (
    <Styles.Container $height={height}>
      <Title level="metricCard">
        {props.title}
      </Title>
      <ResponsiveBar
        colors={{ datum: "data.color" }}
        borderColor={{ theme: "background" }}
        data={props.chartData}
        keys={[ "value" ]}
        indexBy="nameValue"
        theme={nivoMyQvtTheme}
        tooltip={({ data }) => (
          <Styles.Tooltip shadow="button" isReducedPadding >
            <Styles.TooltipValue>{data.value}</Styles.TooltipValue>
            <Styles.TooltipLabel>{data.nameValue}</Styles.TooltipLabel>
          </Styles.Tooltip>
        )}
        isInteractive
        layout="horizontal"
        margin={{ bottom: 60, left: 200, right: 6, top: 6 }}
        padding={padding}
        innerPadding={6}
        valueScale={{ type: "linear" }}
        indexScale={{ round: true, type: "band" }}
        enableGridX={false}
        animate
        enableGridY
        borderRadius={10}
        enableLabel
        labelTextColor={{
          from: "color",
          modifiers: [
            [
              "brighter",
              60,
            ],
          ],
        }}
        axisLeft={{
          format: (e) => getLabel(e),
        }}
        axisBottom={{
          format: e => Math.floor(e) === e && e,
        }}
      />
    </Styles.Container>
  )

  function getLabel(value: string) {
    const reducedValue = value.length > 35 ? value.substring(0, 35) + "..." : value
    return (
      <Tooltip title={value}>
        <Styles.ChartLabel>{reducedValue}</Styles.ChartLabel>
      </Tooltip>
    )
  }

  function calculatePadding(dataLength: number) {
    switch (true) {
      case dataLength > 20:
        return 0.2 - (dataLength * 0.005)
      case dataLength > 10:
        return 0.3 - (dataLength * 0.01)
      case dataLength > 5:
        return 0.4 - (dataLength * 0.01)
      default:
        return 0.8 - (dataLength * 0.030)
    }
  }

  function calculateHeight(dataLength: number) {
    let height = 200

    switch (true) {
      case dataLength > 15:
        height += (dataLength * 12)
        break
      case dataLength > 10:
        height += (dataLength * 10)
        break
      case dataLength > 5:
        height += (dataLength * 8)
        break
      default:
        height += (dataLength * 8)
    }

    return height < 330 ? 330 : height
  }
}

export const TopicChart = withSkeleton(
  TopicChartComponent,
  TopicChartSkeleton,
)

export interface TopicChartProps {
  title: string,
  /** data to display in the chart*/
  chartData: {color: string, nameValue: string, value: number}[],
}
