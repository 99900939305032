import React from "react"
import { ApolloError } from "@apollo/client/errors"
import { t } from "ttag"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"

import { Admin } from "features/Permissions/models"
import useScopeManager from "features/Permissions/PermissionsManager/useScopeManager"
import { PermissionsManager } from "features/Permissions/PermissionsManager/PermissionsManager"
import { usePermissions } from "features/Permissions/PermissionsContext"
import { NotificationContext } from "features/Notification"
import { UncommittedChangesPrompt } from "features/UncommitedChanges"
import ScopeManager from "features/Permissions/PermissionsManager/ScopeManager"
import { BaseProps, PrimaryButton, SearchableSelectOption } from "@humanpredictiveintelligence/myqvt-library"
import { UserPermissionCode } from "models/generated"
import * as Styles from "./PermissionsSection.styles"

import { useSendInitPasswordLazyQuery } from "graphql/queries/generated/SendInitPassword"
import { useSetUserPermissionsMutation } from "graphql/mutations/generated/SetUserPermissions"

export const PermissionsSection: React.FC<BaseProps> = props => {
  const {
    selectedAdmin, hasChanges, fetchAdmins,
    areAdminsLoading, actions, admins, setUserPermissions,
    adminsWithEmailPasswordInitSent,
  } = usePermissions()
  const { saveAdminScopes } = useScopeManager()
  const notification = React.useContext(NotificationContext)

  const [ isRemovalConfirmationDialogOpen, setIsRemovalConfirmationDialogOpen ] = React.useState(false)
  const [ isUserPermissionsBeingRemoved, setIsAdminPermissionsBeingRemoved ] = React.useState<boolean>(false)

  const isSuperAdmin = !!selectedAdmin?.isAdmin
  const isInitMailSent = !!adminsWithEmailPasswordInitSent.find(adminId => adminId === selectedAdmin?.id)

  const [ sendInitMail, { loading: isMailSending } ] =  useSendInitPasswordLazyQuery(
    {
      onCompleted: () => {
        actions.setInitPasswordEmailSentForSelectedAdmin()
      },
      onError: (error) => {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      },
    })

  const [ disableAdminPermission ] = useSetUserPermissionsMutation({
    onCompleted: () => {
      setIsAdminPermissionsBeingRemoved(false)
      fetchAdmins()
    },
    onError: (error) => {
      setIsAdminPermissionsBeingRemoved(false)
      notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
    },
  })

  if (!selectedAdmin) {
    return null
  }

  return (
    <Styles.Container className={props.className}>
      <UncommittedChangesPrompt isActive={hasChanges}/>
      <Dialog open={isRemovalConfirmationDialogOpen}>
        <DialogTitle>{t`Deletion confirmation`}</DialogTitle>
        <DialogContent>
          {t`Are you sure you want to remove this administrator from the permissions list?
           He will no longer be able to access the administration interface.`}
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            onClick={() => setIsRemovalConfirmationDialogOpen(false)}
            isInverted
            isLoading={isMailSending}
          >
            {t`Cancel`}
          </PrimaryButton>
          <PrimaryButton
            onClick={onAdminPermissionsRemoved}
            destructive
            isLoading={isMailSending}
          >
            {t`Confirm`}
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      {isSuperAdmin && <Styles.InformationMessageBox
        variant={"standard"}
        isSmall={false}
        icon={"star"}
        text={t`This administrator is a super administrator. 
        It is not possible to modify its permissions, send a password initialisation email or delete it. 
        Contact support if you need to modify the super administrators for this account.`}
      />}

      <Styles.InnerContainer $isReadonly={isSuperAdmin}>
        <Styles.PermissionsPresetSelect
          label={t`Configure using the settings of another administrator`}
          selectPlaceholder={t`Select an administrator`}
          searchPlaceholder={t`Type to search`}
          noOptionText={t`No profile available`}
          options={getUsersPresetOptions(admins ?? [])}
          onChange={onAdminPresetSelection}
          isDisabled={isSuperAdmin}
          isSearchable
        />
        <ScopeManager admin={selectedAdmin}/>
        <PermissionsManager/>
      </Styles.InnerContainer>

      <PrimaryButton
        onClick={() => sendInitMail({ variables: { userId: selectedAdmin?.id } })}
        isInverted={isInitMailSent}
        isLoading={isMailSending}
        disabled={isInitMailSent || isSuperAdmin}
        height="small"
      >
        {isInitMailSent ? t`Sent` : t`Send the initialisation password email`}
      </PrimaryButton>
      <Styles.RemoveUserPermissionsButton
        onClick={() => setIsRemovalConfirmationDialogOpen(true)}
        destructive
        isLoading={isUserPermissionsBeingRemoved || areAdminsLoading}
        disabled={isSuperAdmin}
        height="small"
      >
        {t`Remove this administrator from the permissions list`}
      </Styles.RemoveUserPermissionsButton>
    </Styles.Container>
  )

  function onAdminPermissionsRemoved() {
    if  (!selectedAdmin) {
      throw new Error("No admin selected")
    }

    setIsRemovalConfirmationDialogOpen(false)
    setIsAdminPermissionsBeingRemoved(true)
    actions.setAdminsLoading(true)
    disableAdminPermission({
      variables: {
        permissions: [ { code: UserPermissionCode.BackofficeView, isGranted: false } ],
        userId: selectedAdmin.id,
      },
    })
  }

  function getUsersPresetOptions(admins: Admin[]): SearchableSelectOption[] {
    return admins.reduce((adminPresetOptions, admin) => {
      // Add the admin has preset if it's not a super admin and isn't the current selected admin
      if (!admin.isAdmin && admin.id !== selectedAdmin?.id) {
        adminPresetOptions.push({
          label: `${admin.firstName} ${admin.lastName}`,
          value: admin.id.toString(),
        })
      }
      return adminPresetOptions
    }, new Array<SearchableSelectOption>())
  }

  async function onAdminPresetSelection(selectedAdminPreset: SearchableSelectOption[]) {
    const adminPreset =
      selectedAdminPreset?.length && admins?.find(admin => admin.id === parseInt(selectedAdminPreset?.[0].value, 10))

    if (selectedAdmin && adminPreset) {
      try {
        actions.setAdminLoading(true)

        await saveAdminScopes(selectedAdmin, adminPreset.scopes.map(scope => scope.id))

        await setUserPermissions({
          variables: {
            permissions: adminPreset.permissions.map(permission => ({
              code: permission.permission.code,
              isGranted: permission.isGranted,
            })),
            userId: selectedAdmin.id,
          },
        })
        notification.show(t`Success`, t`Permissions have been updated.`, "success")

        fetchAdmins()
      }
      catch (error) {
        if (error instanceof ApolloError && error.graphQLErrors?.[0]) {
          notification.show(t`Error`, error.graphQLErrors?.[0].message, "danger")
        }
      }
    }
  }
}
