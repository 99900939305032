import * as React from "react"
import { t } from "ttag"

import { CommentEditorContainer, CommentEditorContainerProps } from "../CommentEditorContainer"
import { ContextualizedCommentsTableRowProps } from "../ContextualizedCommentsTableRow"
import { useUserPermissions } from "features/Permissions"
import { Caption } from "@humanpredictiveintelligence/myqvt-library"
import { Skeletonable, withSkeleton } from "@humanpredictiveintelligence/myqvt-library"
import { BaseProps } from "@humanpredictiveintelligence/myqvt-library"
import { Comment, CommentAttribute, CommentReplies } from "models/Comment"
import { COMMENT_SERVICE } from "services/CommentService"
import * as Styles from "./ContextualizedCommentsTable.styles"
import { UserPermissionCode } from "models/generated"

const CommentTableBodyComponent = (props: ContextualizedCommentsTableProps) => {
  const isUserAllowed = useUserPermissions()
  const isAllowedToWriteResponseComments = isUserAllowed(UserPermissionCode.CommentFeedbacksWrite)

  return (
    <>
      {props.comments.comments.map((comment) => {
        const firstAnswer = comment.answers?.[0]

        return (
          <Styles.Row
            key={comment.id}
            comment={comment}
            isGlobalComment={props.comments.isGlobalComments}
            answerStatus={COMMENT_SERVICE.status(firstAnswer)}
            extendedContent={(
              <CommentEditorContainer
                isAllowedToWrite={isAllowedToWriteResponseComments}
                isAllowedToPublish={isAllowedToWriteResponseComments}
                comment={firstAnswer}
                commentMetadata={{
                  ...comment,
                  attributeValueId: comment.attribute?.valueId,
                  parentComment: { id: comment.id },
                }}
                isImmediatePublishing
                onSaved={props.onSaved}
              />
            )}
          />
        )
      })}
    </>
  )
}

const CommentsTableBodySkeleton = (props: ContextualizedCommentsTableSkeletonProps) => (
  <>
    {Array(props.skeletonCommentsDisplayedCount).fill({}).map((comment, index) => {
      return (
        <Styles.Row
          isLoading
          key={index}
          responseDisplay="display"
        />
      )
    })}
  </>
)

const CommentTableBody = withSkeleton(CommentTableBodyComponent, CommentsTableBodySkeleton)

export const ContextualizedCommentsTable = (props: Skeletonable<ContextualizedCommentsTableProps>) => (
  <Styles.Table className={props.className} role="table">
    <Styles.Headers>
      <Styles.SurveyAndCommentHeaders>
        <Styles.SurveyHeader size="big" textAlign="left">
          {t`Survey`}
        </Styles.SurveyHeader>
        <Caption size="big">{t`Question and comment`}</Caption>
      </Styles.SurveyAndCommentHeaders>
      <Caption size="big">{t`Answer`}</Caption>
      <Caption size="big">{t`Status`}</Caption>
      {props.comments.isGlobalComments && <Caption size="big">{t`Access`}</Caption>}
    </Styles.Headers>
    <div>
      <CommentTableBody
        isLoading={props.isLoading}
        onSaved={props.onSaved}
        skeletonCommentsDisplayedCount={5}
        comments={props.comments}
      />
    </div>
  </Styles.Table>
)

export type ContextualizedCommentsTableSkeletonProps = ContextualizedCommentsTableProps
  & { skeletonCommentsDisplayedCount: number };

export interface ContextualizedCommentsTableProps extends BaseProps {
  /** Collection of comments to display */
  comments: {
    comments: Array<ContextualizedCommentsTableRowProps["comment"]
      & CommentAttribute
      & CommentReplies<Comment>>,
    isGlobalComments?: boolean,
  },

  /** Called when a comment is saved */
  onSaved?: CommentEditorContainerProps["onSaved"],
}
