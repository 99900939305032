import styled, { css } from "styled-components/macro"

export const Container = styled.li<{ $isSelected: boolean }>`
  ${({ theme, $isSelected }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    background: ${theme.colors.blackUltraLight};
    gap: .5rem;
    color: ${theme.colors.grey};
    font-size: ${theme.typography.fontSizeLabelBig};

    padding: 0.6rem;
    list-style: none;
    border-radius: ${theme.sizes.borderRadius};
    
    ${$isSelected && "&,"} &:hover {
      box-sizing: border-box;
      color: ${theme.colors.white};
      background: ${theme.colors.primary};
      box-shadow: ${theme.shadows.buttonHover};
    }
  `}
`

export const Content = styled.div`
  overflow: hidden;
  `

export const UserName = styled.span`
  ${({ theme }) => theme.mixins.webkitClampText(1)}
  word-break: break-all;
  display: block;
`

export const UserEmail = styled.span.attrs({ className: "animationDefilement" })<{ $isAnimating: boolean }>`
  width: min-content;
  display: block;
  white-space: nowrap;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  font-size: 12px;
  ${({ theme }) => theme.mixins.webkitClampText(1)};
  ${({ $isAnimating }) => $isAnimating && css`
    animation-name: "animationDefilement";
    animation-timing-function: linear;
  `
}
  
@keyframes animationDefilement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
  }
}
`
