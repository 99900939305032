import styled, { css } from "styled-components/macro"

export const Container = styled.div`
  width: 100%;
  padding: 16px 104px 40px 104px;
`

export const HierarchyTitle = styled.p`
${({ theme }) => css`
  margin-left: 24px;
  margin-bottom: 24px;

  text-transform: uppercase;
  color: ${theme.colors.blackMedium};
  font-size: ${theme.typography.fontSizeTextSmaller};
  font-weight: ${theme.typography.fontWeightMedium};
  letter-spacing: ${theme.typography.letterSpacingLabel};
  line-height: 13px;
`}
`

export const Chart = styled.div`
  max-width: ${({ theme }) => theme.sizes.chartMaxWidth};

  & > * {
    margin: 24px 0;
  }
`
