import { PermissionsState } from "features/Permissions/PermissionsContext/state"
import { PermissionsReducerAction } from "features/Permissions/PermissionsContext/actions"
import { initializePermissions, updatePermissionsState } from "features/Permissions/services"

export function permissionsReducer(state: PermissionsState, action: PermissionsReducerAction) {
  switch (action.type) {
    case "setAdmins": {
      return {
        ...state,
        admins: action.admins,
        areAdminsLoading: false,
        hasChanges: false,
      }
    }
    case "selectAdmin": {
      const [ userPermissionsMap, permissionsRelations ] = initializePermissions(action.admin?.permissions ?? [])

      return {
        ...state,
        areAdminsLoading: false,
        hasChanges: false,
        isAdminLoading: false,
        permissions: new Map(userPermissionsMap),
        permissionsRelations,
        permissionsSaveState: new Map(userPermissionsMap),
        selectedAdmin: action.admin,
      }
    }
    case "setInitPasswordEmailSentForSelectedAdmin": {
      if (
        state.selectedAdmin?.id
        && !state.adminsWithEmailPasswordInitSent.find(adminId => adminId === state.selectedAdmin?.id)
      ) {
        state.adminsWithEmailPasswordInitSent.push(state.selectedAdmin?.id)
      }

      return {
        ...state,
      }
    }
    case "togglePermission": {
      const userPermissionToToggle = state.permissions.get(action.permissionCode)

      if (userPermissionToToggle) {
        const userPermissionToToggleCopy = { ...userPermissionToToggle }
        userPermissionToToggleCopy.isGranted = !userPermissionToToggleCopy.isGranted
        state.permissions.set(action.permissionCode, userPermissionToToggleCopy)

        const updatedPermissions = updatePermissionsState(
          [ userPermissionToToggleCopy ],
          state.permissions,
          state.permissionsSaveState,
          state.permissionsRelations,
        )

        let hasChanges = false
        for (const [ updatedPermissionCode, updatedPermission ] of Array.from(updatedPermissions)) {
          if (hasChanges) {
            break
          }
          hasChanges = updatedPermission.isGranted !== state.permissionsSaveState.get(updatedPermissionCode)?.isGranted
        }

        return {
          ...state,
          hasChanges,
          permissions: updatedPermissions,
        }
      }
      return state
    }
    case "setAdminsLoading": {
      return {
        ...state,
        areAdminsLoading: action.isLoading,
      }
    }
    case "setAdminLoading": {
      return {
        ...state,
        isAdminLoading: action.isLoading,
      }
    }
    default:
      throw new Error(`Unhandled action type`)
  }
}
