import * as React from "react"
import { useTheme } from "styled-components"

import { BaseProps, Icon } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./Notification.styles"

export const Notification = React.forwardRef<HTMLDivElement, NotificationProps>((props, ref) => {
  const theme = useTheme()
  const iconsColor = props.variant === "danger" ? theme.colors.white : theme.colors.blackMedium

  return (
    <Styles.OuterContainer ref={ref} role="alert">
      <Styles.Container $variant={props.variant}>
        <Styles.InnerContainer>
          <Icon color={iconsColor} name={props.variant === "danger" ? "warning" : "check"}/>
          <Styles.Content>
            <Styles.Title>
              {props.title}
            </Styles.Title>
            <div>
              {props.message}
            </div>
          </Styles.Content>
          <Styles.Action onClick={() => props.onClose(props.key)}>
            <Styles.CloseIcon color={iconsColor} name="close"/>
          </Styles.Action>
        </Styles.InnerContainer>
      </Styles.Container>
    </Styles.OuterContainer>
  )
})

export interface NotificationProps extends BaseProps {
  /** Will display a different theme depending on the variant value, success is the default */
  variant: "success" | "danger",

  /** Close the notification */
  onClose: (key: number) => void,

  /** The title appearing on the top of the Notification */
  title: string,

  /** The message to be displayed in the component */
  message: string,

  /** Unique number used to identify the notification */
  key: number,
}
