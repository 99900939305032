import styled from "styled-components/macro"
import { Grid, Dialog as MuiDialog } from "@material-ui/core"

import { Table } from "@humanpredictiveintelligence/myqvt-library"

export const Dialog = styled(MuiDialog)`
  .Container {
    width: 1100px;
    height: 600px;
    display: grid;
    grid-template-rows: min-content minmax(60%, 1fr) min-content;
    row-gap: 8px;
    padding: 0;
  }
`

export const ScopeConfiguratorContainerCard = styled.div`
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  background: ${({ theme }) => theme.colors.blackUltraLight };
`

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 24px 16px 0 16px;
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 16px 24px 16px;

  & > * {
    margin-right: 1rem;
  }
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.typography.fontSizeTextMedium};
  color: ${({ theme }) => theme.colors.blackMedium};
`

export const ScopeGridContainer = styled(Grid)`
  height: 100%;
`

export const ScopeGridColumn = styled(Grid)`
  height: 100%;

  border-right: 1px solid #e2e2e2;
  &:nth-child(3n) {
    border-right: none;
  }

`

export const AttributeTable = styled(Table)`
  .AttributeTableHeader {
    th:first-of-type {
      width: 95px;
    }
  }
`
