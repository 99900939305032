import React from "react"
import { t } from "ttag"
import { AnimatePresence, motion } from "framer-motion"
import { PrimaryButton } from "@humanpredictiveintelligence/myqvt-library"

import { NotificationContext } from "features/Notification"
import { ImportStepStatus, RecipientsImportStep } from "../RecipientImportStep"
import { RecipientsImportDropzone } from "../RecipientsImportDropzone"
import { RecipientImportErrorLog } from "../RecipientImportErrorLog"
import * as Styles from "./RecipientsImportPanel.styles"

import { useAnalyseImportUsersLazyQuery } from "graphql/queries/generated/AnalyseImportUsers"
import { useExecuteImportUsersLazyQuery } from "graphql/queries/generated/ExecuteImportUsers"
import { useProceduresLazyQuery } from "graphql/queries/generated/Procedures"
import { ProcedureError } from "models/generated"

export const RecipientsImportPanel = (props: RecipientsImportDialogProps) => {
  const notification = React.useContext(NotificationContext)
  const [ isFileSent, setFileSent ] = React.useState(false)
  const [ canUploadFile, setCanUploadFile ] = React.useState(true)
  const [ stepOnePanel, setStepOnePanel ] = React.useState("collapsed")
  const [ stepTwoPanel, setStepTwoPanel ] = React.useState("collapsed")
  const [ stepThreePanel, setStepThreePanel ] = React.useState("collapsed")

  const [ analyseImportUsersQuery, {
    data : analyseImportUsersQueryData,
  } ]
    = useAnalyseImportUsersLazyQuery({
      fetchPolicy: "no-cache",
      onError: (error) => {
        setFileSent(false)
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      },
    })
  const [ proceduresQuery, {
    data : proceduresQueryData,
    stopPolling: stopPollingProcedures,
  } ]
    = useProceduresLazyQuery
    ({
      onError: (error) => {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      },
      pollInterval: 1000,
    })
  const [ analyseStatus, setAnalyseStatus ] = React.useState<ImportStepStatus>(ImportStepStatus.pending)
  const [ analyseSubtitle, setAnalyseSubtitle ] = React.useState<string>("")
  const [ analyseErrors, setAnalyseError ] = React.useState<Array<ProcedureError>>([])
  const [ isAnalyseErrorLogsOpen, setAnalyseErrorLogsOpen ] = React.useState(false)

  const [ executeImportUsersQuery, { data : executeImportUsersQueryData } ]
    = useExecuteImportUsersLazyQuery({
      fetchPolicy: "no-cache",
      onError: (error) => {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger",
        )
      },
    })
  const [ proceduresExecuteQuery, { data : proceduresExecuteQueryData, stopPolling: stopPollingProceduresExecute } ]
    = useProceduresLazyQuery({
      onError: (error) => {
        notification.show(t`Failure`, error.graphQLErrors?.[0].message, "danger")
      },
      pollInterval: 1000,
    })
  const [ executeStatus, setExecuteStatus ] = React.useState<ImportStepStatus>(ImportStepStatus.pending)
  const [ executeErrors, setExecuteError ] = React.useState<Array<ProcedureError>>([])
  const [ isExecuteStepOpen, setExecuteStepOpen ] = React.useState(false)

  /** Analyse Step 1 **/
  React.useEffect(() => {
    if (!analyseImportUsersQueryData) {
      setAnalyseStatus(ImportStepStatus.pending)
    }
    if (analyseImportUsersQueryData?.procedure_import_users_analyse.isInProgress) {
      setAnalyseStatus(ImportStepStatus.ongoing)
      setAnalyseSubtitle(t`Treating file ...`)

      proceduresQuery({
        variables: { "id": analyseImportUsersQueryData?.procedure_import_users_analyse.id },
      })
    }

    if (analyseImportUsersQueryData?.procedure_import_users_analyse.isErrored) {
      setAnalyseStatus(ImportStepStatus.failed)
      setAnalyseError(analyseImportUsersQueryData.procedure_import_users_analyse.errors ?? [])
      setAnalyseSubtitle(t`An error has occurred ...`)
    }
    if (analyseImportUsersQueryData?.procedure_import_users_analyse.isDone) {
      setAnalyseStatus(ImportStepStatus.done)
      setAnalyseSubtitle(t`File checked.`)
      setExecuteStepOpen(true)
    }
  }, [ analyseImportUsersQueryData, proceduresQuery ])

  React.useEffect(() => {
    if (!proceduresQueryData) {
      setAnalyseStatus(ImportStepStatus.pending)
    }
    else if (proceduresQueryData?.procedures[0].isInProgress) {
      setAnalyseStatus(ImportStepStatus.ongoing)
      setAnalyseSubtitle(t`Treating file ...`)
    }
    else if (proceduresQueryData?.procedures[0].isErrored) {
      setAnalyseStatus(ImportStepStatus.failed)
      setAnalyseError(proceduresQueryData?.procedures[0].errors ?? [])
      setAnalyseSubtitle(t`An error has occurred ...`)
    }
    else if (proceduresQueryData?.procedures[0].isDone) {
      setAnalyseStatus(ImportStepStatus.done)
      setAnalyseSubtitle(t`File checked.`)
      setExecuteStepOpen(true)
    }
    if (stopPollingProcedures && proceduresQueryData?.procedures[0].isTerminated) {
      stopPollingProcedures()
    }
  }, [ proceduresQueryData, stopPollingProcedures ])

  /** Execute step 2 **/
  React.useEffect(() => {
    if (!executeImportUsersQueryData) {
      setExecuteStatus(ImportStepStatus.pending)
    }
    if (executeImportUsersQueryData?.procedure_import_users_execute.isInProgress) {
      setExecuteStatus(ImportStepStatus.ongoing)
      proceduresExecuteQuery({
        variables: { "id": executeImportUsersQueryData?.procedure_import_users_execute.id },
      })
    }
    if (executeImportUsersQueryData?.procedure_import_users_execute.isErrored) {
      setExecuteStatus(ImportStepStatus.failed)
      setExecuteError(executeImportUsersQueryData?.procedure_import_users_execute.errors ?? [])
    }
    if (executeImportUsersQueryData?.procedure_import_users_execute.isDone) {
      setExecuteStatus(ImportStepStatus.done)
    }
  }, [ executeImportUsersQueryData, proceduresExecuteQuery ])

  React.useEffect(() => {
    if (!proceduresExecuteQueryData) {
      setExecuteStatus(ImportStepStatus.pending)
    } else if (proceduresExecuteQueryData?.procedures[0].isInProgress) {
      setExecuteStatus(ImportStepStatus.ongoing)
    } else if (proceduresExecuteQueryData?.procedures[0].isErrored) {
      setExecuteStatus(ImportStepStatus.failed)
      setExecuteError(proceduresExecuteQueryData?.procedures[0].errors ?? [])
    } else if (proceduresExecuteQueryData?.procedures[0].isDone) {
      setExecuteStatus(ImportStepStatus.done)
    }

    if (stopPollingProceduresExecute && proceduresExecuteQueryData?.procedures[0].isTerminated) {
      stopPollingProceduresExecute()
    }
  }, [ proceduresExecuteQueryData, stopPollingProceduresExecute ])

  React.useEffect(() => {
    setCanUploadFile(
      !isFileSent
      || analyseStatus === ImportStepStatus.failed
      || executeStatus === ImportStepStatus.failed,
    )
  }, [ isFileSent, analyseStatus, executeStatus ])

  React.useEffect(() => {
    if (isExecuteStepOpen) {
      setStepOnePanel("exitdelay")
    } else if (isAnalyseErrorLogsOpen) {
      setStepOnePanel("closed")
    } else {
      setStepOnePanel("collapsed")
    }
  }, [ isAnalyseErrorLogsOpen, isExecuteStepOpen ])

  React.useEffect(() => {
    if (isAnalyseErrorLogsOpen) {
      setStepTwoPanel("open")
    } else if (isExecuteStepOpen) {
      setStepTwoPanel("closed")
    } else {
      setStepTwoPanel("collapsed")
    }
  }, [ isAnalyseErrorLogsOpen, isExecuteStepOpen ])

  React.useEffect(() => {
    if (analyseStatus === ImportStepStatus.done) {
      setStepThreePanel("open")
    } else if (isAnalyseErrorLogsOpen) {
      setStepThreePanel("closed")
    } else {
      setStepThreePanel("collapsed")
    }
  }, [ isAnalyseErrorLogsOpen, analyseStatus ])

  return (
    <Styles.RecipientImportPanel>

      <Styles.FileImportSection>
        <RecipientsImportDropzone
          onFileSelected={(file) => {
            setFileSent(true)
            setStepTwoPanel("collapsed")
            setAnalyseSubtitle("")
            setAnalyseErrorLogsOpen(false)
            setExecuteStepOpen(false)
            setAnalyseStatus(ImportStepStatus.pending)
            setExecuteStatus(ImportStepStatus.pending)
            setAnalyseError([])
            setExecuteError([])
            analyseImportUsersQuery({ variables : { "file" : file } })
          }}
          isDisabled={!canUploadFile}
        />
      </Styles.FileImportSection>

      <Styles.ProcessImportSection>
        <AnimatePresence>

          {(
            <motion.div
              key="step1"
              initial="open"
              animate={stepOnePanel}
              exit="closed"
              variants={{
                closed: { height: "0%", opacity: 0, position: "absolute" },
                collapsed: { height: 100 / 3 + "%", opacity: 1, position: "relative" },
                exitdelay: {
                  height: "0%",
                  opacity: 0,
                  position: "absolute",
                  transition: Styles.delayedTransition,
                },
              }}
              transition={Styles.defaultTransition}
            >
              <RecipientsImportStep
                title={t`Step 1 : Uploading file`}
                subtitle={isFileSent ? t`File sent` : t`Waiting for file...`}
                status={isFileSent ? ImportStepStatus.done : ImportStepStatus.pending}
                isActive={isFileSent}
              />
            </motion.div>
          )}

          {(
            <motion.div
              key="step2"
              initial="collapsed"
              animate={stepTwoPanel}
              exit="closed"
              style={{ overflow:"hidden" }}
              variants={{
                closed: {
                  height: "0%",
                  opacity: 0,
                  position: "absolute",
                  transition: Styles.delayedTransition,
                },
                collapsed: { height: 100 / 3 + "%", opacity: 1, position: "relative" },
                open: { height: "100%", position: "relative" },
              }}
              transition={Styles.defaultTransition}
            >
              <RecipientsImportStep
                title={t`Step 2 : File analysis`}
                subtitle={analyseSubtitle}
                status={analyseStatus}
                onShowDetailsClick={() => setAnalyseErrorLogsOpen(true)}
                onBackButtonClick={() => setAnalyseErrorLogsOpen(false)}
                errors={analyseErrors ?? null}
                isErrorLogOpen={isAnalyseErrorLogsOpen}
                isActive={analyseStatus !== ImportStepStatus.pending}
              >
                {isAnalyseErrorLogsOpen && analyseErrors && (
                  <RecipientImportErrorLog key="error-log" errors={analyseErrors}/>
                )}
              </RecipientsImportStep>
            </motion.div>
          )}

          {!isAnalyseErrorLogsOpen && (
            <motion.div
              key="step3"
              initial="open"
              animate={stepThreePanel}
              variants={{
                closed: { height: "0%", opacity: 0, position: "relative" },
                collapsed: { height: 100 / 3 + "%", opacity: 1, position: "relative" },
                open: {
                  height: "100%",
                  position: "relative",
                  transition: Styles.delayedTransition,
                },
              }}
              transition={Styles.defaultTransition}
            >
              <RecipientsImportStep
                title={t`Step 3 : Confirmation`}
                status={executeStatus}
                isActive={analyseStatus === ImportStepStatus.done}
              >
                {isExecuteStepOpen && executeStatus === ImportStepStatus.pending && (
                  <motion.div
                    initial={{ height: "0%", opacity: 0 }}
                    animate={{ height: "100%", opacity: 1 }}
                    transition={{ delay: 1, duration: 0.5 }}
                  >
                    <Styles.ConfirmationSection>
                      {t`Your file is ready to be imported, after confirmation,
                      the information in your users base will be permanently replaced.`}
                      <Styles.ConfirmationButtonRow>
                        <PrimaryButton onClick={() => {
                          setExecuteStatus(ImportStepStatus.ongoing)
                          executeImportUsersQuery({
                            variables: {
                              "import_users_analyse_id" :
                                analyseImportUsersQueryData!.procedure_import_users_analyse.id,
                            },
                          })
                        }}>
                          {t`Confirm` }
                        </PrimaryButton>
                        <PrimaryButton onClick={props.close} destructive>{t`Cancel` }</PrimaryButton>
                      </Styles.ConfirmationButtonRow>
                    </Styles.ConfirmationSection>
                  </motion.div>
                )}

                {isExecuteStepOpen && executeStatus === ImportStepStatus.ongoing && (
                  <motion.div
                    initial={{ height: "100%", opacity: 0  }}
                    animate={{ height: "100%", opacity: 1 }}
                    exit={{ transition: { delay: 2 } }}
                  >
                    <Styles.ConfirmationSection>
                      {t`Replacing users database.`}
                    </Styles.ConfirmationSection>
                  </motion.div>
                )}

                {isExecuteStepOpen && executeStatus === ImportStepStatus.failed && (
                  <Styles.ConfirmationSection>
                    {t`An error occurred while importing the file. Please contact us for more information.`}
                    <RecipientImportErrorLog size="200px" key="error-log" errors={executeErrors}/>
                    <Styles.ConfirmationButtonRow>
                      <PrimaryButton onClick={() => {props.close()}} destructive>{t`Close` }</PrimaryButton>
                    </Styles.ConfirmationButtonRow>
                  </Styles.ConfirmationSection>
                )}

                {isExecuteStepOpen && executeStatus === ImportStepStatus.done && (
                  <Styles.ConfirmationSection>
                    {t`Your new file has been imported successfully `}
                    <Styles.ConfirmationButtonRow>
                      <PrimaryButton onClick={() => {
                        props.close()
                        props.refetchRecipientList()
                      }} >{t`Ok` }</PrimaryButton>
                    </Styles.ConfirmationButtonRow>
                  </Styles.ConfirmationSection>
                )}

              </RecipientsImportStep>
            </motion.div>
          )}

        </AnimatePresence>
      </Styles.ProcessImportSection>

    </Styles.RecipientImportPanel>
  )
}

export interface RecipientsImportDialogProps {
  close: () => void,

  refetchRecipientList: () => void,
}
