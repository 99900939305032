import React from "react"

import { ImportStepStatus } from "features/RecipientsImport/RecipientImportStep"
import { useStatusIconsTheme } from "features/RecipientsImport/useStatusIconsTheme"
import { Icon } from "@humanpredictiveintelligence/myqvt-library"
import * as Styles from "./ImportStatusIcon.styles"

export const ImportStatusIcon = (props: ImportStatusIconProps) => {
  const statusIconsTheme = useStatusIconsTheme()

  return (
    <Styles.Container $borderColor={statusIconsTheme[props.status].color}>
      {props.status === ImportStepStatus.ongoing
        ? <Styles.OngoingLoader size="s" color="primary" isTransparent/>
        : <Icon
          size={24}
          color={statusIconsTheme[props.status].color}
          name={statusIconsTheme[props.status].iconName}
        />
      }

    </Styles.Container>
  )
}

export interface ImportStatusIconProps {
  /** The status of the process to know which icon and color to display */
  status: ImportStepStatus,
}
