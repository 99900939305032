import styled, { css } from "styled-components/macro"
import { Caption, Icon } from "@humanpredictiveintelligence/myqvt-library"

export const DropZone = styled.label<DropZoneProps>`
  box-sizing: border-box;
  padding: 16px;
  border-radius: 5px;
  display:flex;
  flex-direction: column;
  border: 5px dashed transparent;

  cursor: pointer;

  transition: all 0.25s;
  
  background: ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) => theme.shadows.button};
  
  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark};
    box-shadow: ${({ theme }) => theme.shadows.buttonHover};
  }
  
  ${({ $isDragging, $isDisabled }) => $isDragging && !$isDisabled && css`
    background: transparent;
    box-shadow: none;

    border: 5px dashed ${({ theme }) => theme.colors.primary};
    
    ${Label}, ${ImportIcon}, ${FileName} {
      color: ${({ theme }) => theme.colors.blackMedium};
    }
  `}
  
  ${({ $isDisabled }) => $isDisabled && css`
    background: transparent;
    box-shadow: none;
    pointer-events: none;
    
    &:hover {
      background: transparent;
      box-shadow: none;
    }
    
    ${Label}, ${ImportIcon}, ${FileName} {
      color: ${({ theme }) => theme.colors.blackMedium};
    }
  `}
`

export interface DropZoneProps {
  $isDragging: boolean,
  $isDisabled: boolean,
}

export const FileInput = styled.input`
  // We style and listen to event on the label since input cannot have children.
  width: 0;
  height: 0; 
  opacity: 0;
`

export const ImportIcon = styled(Icon)`
  display:flex;
  flex-direction: column;
  margin-bottom: 8px;
  pointer-events: none;  
  color: ${({ theme }) => theme.colors.white};

`

export const Label = styled(Caption)`
   color: ${({ theme }) => theme.colors.white};
   pointer-events: none;  
`
export const FileName = styled.span`
  max-width: 100px;
  text-align: center;
  
  ${({ theme }) => theme.mixins.webkitClampText(1)}
`

