import React from "react"
import lodash from "lodash"
import {
  CenteredLoader,
  Input,
  PagedNavigation,
  Select,
  SelectOptionProps,
  Table,
  TableRowData,
} from "@humanpredictiveintelligence/myqvt-library"
import { t } from "ttag"

import { SkinnyUserCharacteristic } from "models/SkinnyUser"
import { USER_ATTRIBUTE_SERVICE } from "services/UserAttributeService"
import { UsersTableAttributesFilters } from "features/UserGroups/UsersGroupsSections/UsersTableAttributesFilters"
import { numberOfPages } from "utilities/helpers"
import { useUserGroups } from "../../UserGroupsContext"

import * as Styles from "./UsersTable.styles"

import { ScopedUsersQuery, useScopedUsersQuery } from "graphql/queries/generated/ScopedUsers"
import { useUserAttributesQuery } from "graphql/queries/generated/UserAttributeValues"
import { WhereType } from "models/generated"
import business from "config/business"

export const UsersTable: React.FC<UsersTableProps> = (props) => {
  const { state, actions } = useUserGroups()

  const [ searchQuery, setSearchQuery ] = React.useState("")
  const [ searchValue, setSearchValue ] = React.useState("")
  const [ selectedAttributesValuesIds, setSelectedAttributesValuesIds ] = React.useState<number[]>([])
  const [ tablePagination, setTablePagination ] = React.useState({
    currentPage: 1,
    loadingOffset: 0,
    pageSize: business.recipients.tablePageSizes[0],
  })

  const {
    loading: areUsersLoading,
    data: scopedUsersData,
    refetch: refetchScopedUsers,
  } = useScopedUsersQuery({
    fetchPolicy: "no-cache",
    variables: {
      attributeValuesIds: selectedAttributesValuesIds,
      filterGroup: state.selectedGroupUuid ? {
        userGroups: [ state.selectedGroupUuid ],
        whereType: props.tableSide === "users" ? WhereType.NotIn : WhereType.In,
      } : undefined,
      limit: tablePagination.pageSize,
      search: searchQuery,
      skip: tablePagination.loadingOffset,
    },
  })

  const { loading: areAttributesLoading, data: userAttributesData } = useUserAttributesQuery()

  React.useEffect(() => {
    if (state.shouldRefetchUsers) {
      refetchScopedUsers().then((data) => {
        if (props.tableSide === "users") {
          actions.setDisplayedNumberOfUsers(data.data.me.users.list.length)
        }
        else {
          actions.setDisplayedNumberOfUsersInGroup(data.data.me.users.list.length)
        }
        actions.setShouldRefetchUsers(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ state.shouldRefetchUsers, props.tableSide, refetchScopedUsers ])

  React.useEffect(() => {
    setTablePagination({
      currentPage: 1,
      loadingOffset: 0,
      pageSize: business.recipients.tablePageSizes[0],
    })
  }, [ state.selectedGroupUuid ])

  React.useEffect(() => {
    if (
      (state.shouldGoToPreviousUserPage || state.shouldGoToPreviousUserGroupPage) && tablePagination.currentPage > 1
    ) {
      setTablePagination((previousPagination) => ({
        ...previousPagination,
        currentPage: previousPagination.currentPage - 1,
        loadingOffset: (previousPagination.currentPage - 2) * previousPagination.pageSize,
      }))
      refetchScopedUsers()
      actions.setShouldGoToPreviousUserPage(false)
      actions.setShouldGoToPreviousUserGroupPage(false)
    }
  }, [
    actions,
    refetchScopedUsers,
    state.shouldGoToPreviousUserGroupPage,
    state.shouldGoToPreviousUserPage,
    tablePagination,
    tablePagination.currentPage ],
  )

  if (areAttributesLoading && !userAttributesData) {
    return <CenteredLoader isTransparent/>
  }

  const users = !areUsersLoading ? scopedUsersData?.me?.users.list || [] : []
  const usersCount = scopedUsersData?.me?.users.count || 0
  const attributes = USER_ATTRIBUTE_SERVICE
    .attributesFromAttributeValues(userAttributesData?.attributeValues ?? [])

  return (
    <Styles.Container>
      <div>
        <Input
          value={searchValue}
          onChange={onSearch}
          placeholder={t`Search`}
        />
        {attributes.length > 0 && (
          <UsersTableAttributesFilters
            attributes={attributes}
            onFilter={setSelectedAttributesValuesIds}
          />
        )}
      </div>
      <Styles.TableContainer>
        <Table
          isLoading={areUsersLoading || state.shouldRefetchUsers}
          skeletonCountColumn={3}
          skeletonCountRow={tablePagination.pageSize}
          withShadow
          areAllSelected={users.length === props.selectedIds.length && props.selectedIds.length > 0}
          columns={[
            SkinnyUserCharacteristic.lastname,
            SkinnyUserCharacteristic.firstname,
            SkinnyUserCharacteristic.email,
          ]}
          columnDisplayNames={new Map([
            [ "lastname", t`Last Name` ],
            [ "firstname", t`First Name` ],
            [ "email", t`Contact` ],
          ])}
          dataList={userRowsData(users)}
          selectedIds={props.selectedIds}
          onSelect={props.onSelectUser}
          onSelectAll={(event, checked) => {
            props.onSelectAllUsersInPage(users.map(user => user.id.toString()) ?? [], checked)
          }}
          areActionsDisabled={props.areActionsDisabled}
        />
        <Styles.NavigationContainer>
          <Select
            options={
              business.recipients.tablePageSizes.map(size => ({
                value: size,
                wording: size.toString(),
              }))
            }
            onChange={updateTableSize}
            value={tablePagination.pageSize}
            defaultItem={false}
          />
          <PagedNavigation
            isLoadingData={areUsersLoading}
            page={tablePagination.currentPage}
            numberOfPages={numberOfPages(usersCount ?? 0, tablePagination.pageSize)}
            onNavigate={updateTablePage}
          />
        </Styles.NavigationContainer>
      </Styles.TableContainer>
    </Styles.Container>
  )

  function onSearch(query: string) {
    const setQuery = lodash.debounce((value: string) => setSearchQuery(value), 500)

    setSearchValue(query)
    setQuery(query)
  }

  function userRowsData(
    usersList: ScopedUsersQuery["me"]["users"]["list"],
  ): TableRowData[] {
    return usersList.map(user => {
      const userValues = new Map<string, string>()
      userValues.set(SkinnyUserCharacteristic.lastname, user.lastname)
      userValues.set(SkinnyUserCharacteristic.firstname, user.firstname)
      if (user.email) {
        userValues.set(SkinnyUserCharacteristic.email, user.email)
      }
      else if (user.phone) {
        userValues.set(SkinnyUserCharacteristic.email, user.phone)
      }
      return {
        id: user.id.toString(),
        values: userValues,
      }
    })
  }

  function updateTablePage(page: number, pagesCount: number) {
    if (page === 0 || page === pagesCount + 1) {
      return
    }

    setTablePagination((previousPagination) => ({
      ...previousPagination,
      currentPage: page,
      loadingOffset: previousPagination.pageSize * (page - 1),
    }))
    actions.setShouldRefetchUsers(true)

    if (props.tableSide === "users") {
      actions.setSelectedUsersIds([])
    }
    else {
      actions.setSelectedUsersInGroupIds([])
    }
  }

  /**
   * Update the table page size
   * @param sizeOption Page size option selected
   */
  function updateTableSize(sizeOption: SelectOptionProps | undefined) {
    if (sizeOption) {
      setTablePagination({
        currentPage: 1,
        loadingOffset: 0,
        pageSize: sizeOption.value as number,
      })
      if (props.tableSide === "users") {
        actions.setDisplayedNumberOfUsers(sizeOption.value as number)
      }
      else {
        actions.setDisplayedNumberOfUsersInGroup(sizeOption.value as number)
      }
    }
  }
}

interface UsersTableProps {
  tableSide: "users" | "usersInGroup",
  onSelectUser: (id: string) => void,
  selectedIds: string[],
  onSelectAllUsersInPage: (ids: string[], checked: boolean) => void,
  areActionsDisabled: boolean,
}
